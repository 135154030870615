export const pictogramOptions = {
    'lineaIcon': {
        title: 'lineaIcon',
        humanTitle: 'Linea.io'
    },
    'abstractIcon': {
        title: 'abstractIcon',
        humanTitle: 'Abstract Icons'
    },
    'emoji': {
        title: 'emoji',
        humanTitle: 'Emoji'
    },
    'uploaded': {
        title: 'uploaded',
        humanTitle: 'Upload'
    }
};