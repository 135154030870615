import React, {Component} from "react";
import {Icon} from "antd";
import './SpinnerBox.css'

interface Props {
    text?: string
}

class SpinnerBox extends Component<Props>{
    componentDidMount(): void {
    }

    displayText(){
        if(this.props.text && this.props.text.length > 0){
            return this.props.text;
        }else{
            return 'Loading...'
        }
    }

    render(){
        return (
            <div>
                <div className="spinner_box">
                    <Icon type="loading" style={{color: 'var(--ocean)', fontSize: '30px'}} />
                    <div className="spinner_box__text">
                        {this.displayText()}
                    </div>
                </div>
            </div>
        )
    }
}

export default SpinnerBox;