import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { pictogramOptions } from "../../data/pictogramOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Text08 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({ titleText: "",isDefaultEmpty })
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({ subtitleText: "",isDefaultEmpty })
      );

      this.props.saveContentInStore(
        this.generator.setUpFeatures({
          isDefaultEmpty,
          maxItems: 16,
          hasButton: false,
          items: [
            this.generator.createFeatureContentObject({
              title: "Work Faster and Better",
              text:
                "Gloss trabem! Hippotoxotas velum in nobilis avenio! Sunt amores contactus fidelis, barbatus rationees. Salvus ignigena inciviliter transferres scutum est.",
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f332.svg",
              }),
            }),
            this.generator.createFeatureContentObject({
              title: "Make Strong Connections",
              text:
                "Gratis, salvus hibridas grauiter reperire de brevis, bassus homo. Cum habena crescere, omnes terrores captis grandis, varius imberes.",
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f451.svg",
              }),
            }),
            this.generator.createFeatureContentObject({
              title: "Enjoy the Process",
              text:
                "Vox fidelis devatio est. Cum fides crescere, omnes gemnaes convertam brevis, barbatus orexises. A falsis, domina fatalis fluctui. Devirginatos unda!",
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f984.svg",
              }),
            }),

            // The last ghost item which is used to add new items.
            this.generator.createFeatureContentObject({
              title: "",
              text: "",
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "26a1.svg",
              }),
            }),
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a CTA (call to action) to the bottom of the features component to offer more to read about your project.",
            default:
              "Add a CTA (call to action) to the bottom of the features component to offer more to read about your project.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "More",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts

    let features = this.props.componentItem.componentData.features.content
      .items;

    //title and subtitleText were added to this component later. We must check them before using because they do not exist in the componentData object and getting them will cause exception
    let title = undefined;
    if (this.props.componentItem.componentData.title) {
      title = displayTitleMarkupText(
        this.props.componentItem.componentData.title.content.markup
      );
    }
    let subtitleText = undefined;
    if (this.props.componentItem.componentData.subtitleText) {
      subtitleText = displaySubtitleMarkupText(
        this.props.componentItem.componentData.subtitleText.content.markup
      );
    }

    let ctaFirstButton = this.props.componentItem.componentData.cta.content
      .buttons[0];
    let ctaFirstButtonTitle = ctaFirstButton && ctaFirstButton.title;

    return (
      <section>
        <div className="text-08">
          <TitleBox
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />

          <div className="container container--small">
            {features &&
              features.map((item: any, key: number) => {
                if (item.title && item.title !== "") {
                  return (
                    <div
                      className="text-08__item"
                      key={"features-text-08-" + key}
                    >
                      <h3
                        className={
                          "def-18_72 title-text--inner text--08__heading " +
                          (this.props.isDarkBg ? "text-white" : "")
                        }
                      >
                        {item.title}
                        <div className="text--08__icon">
                          <Pictogram
                            pictogramObject={item.pictogram}
                            className=""
                          />
                        </div>
                      </h3>
                      <div
                        className={
                          "content_box " +
                          (this.props.isDarkBg ? "text-white" : "")
                        }
                      >
                        <p
                          className={"content-text " + (this.props.isDarkBg ? "text-white" : "")}
                          dangerouslySetInnerHTML={{ __html: item.text }}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })}

            {this.props.componentItem.componentData.cta &&
              ctaFirstButton &&
              ctaFirstButtonTitle &&
              ctaFirstButtonTitle !== "" && (
                <WebsiteCTA componentItem={this.props.componentItem} />
              )}
          </div>
        </div>
      </section>
    );
  }
}

export default Text08;
