import React, {Component} from "react";
import './MyRichTextEditor.css';

import AlignCenterIcon from '../img/icons/align-center.svg';
import AlignLeftIcon from '../img/icons/align-left.svg';
import BoldIcon from '../img/icons/bold.svg';
import LinkIcon from '../img/icons/link.svg';
import OlIcon from '../img/icons/ol.svg';
import PlayIcon from '../img/icons/play-square.svg';
import SmileIcon from '../img/icons/smile.svg';
import StrikethroughIcon from '../img/icons/strikethrough.svg';
import UlIcon from '../img/icons/ul.svg';
import UndoIcon from '../img/icons/undo.svg';
import UnlinkIcon from '../img/icons/unlink.svg';

//via https://jpuri.github.io/react-draft-wysiwyg/#/docs
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import * as _ from "lodash";
import {Button, Icon, message, Popconfirm} from "antd";
import { checkIfChrome } from "../helpers/editor/checkIfChrome";

type MyState = {
    editorState: any,
};

interface Props {
    onChange?: any,
    revertToPlainHTML?: any,

    disabled?: boolean;
    placeholder?: any;
    style?: any,

    id?: any;
    maxLength?: any;
    defaultValue?: any;
    className?: any;
}

class MyRichTextEditor extends Component<Props, MyState>{

    constructor(props) {
        super(props);
        this.state = {
            editorState: '',
        };
    }

    componentWillMount(): void {
        const html = this.props.defaultValue ? this.props.defaultValue : '';
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.setState({
                editorState: editorState,
            });
        }
    }

    // constructor(props) {
    //     super(props);
    //     const html = this.props.defaultValue ? this.props.defaultValue : '';
    //     const contentBlock = htmlToDraft(html);
    //     if (contentBlock) {
    //         const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    //         const editorState = EditorState.createWithContent(contentState);
    //         this.state = {
    //             editorState,
    //         };
    //     }
    // }


    componentWillReceiveProps(nextProps) {
        if (nextProps.defaultValue !== this.props.defaultValue) {
            // Forcibly overwrite input value to new default if the default ever changes
            // this.setState({editorState: nextProps.defaultValue});

            const html = nextProps.defaultValue ? nextProps.defaultValue : '';
            const contentBlock = htmlToDraft(html);

            if (contentBlock) {
                let content = convertToRaw(this.state.editorState.getCurrentContent());
                let contentToSave: string;
                contentToSave = draftToHtml(content);

                // console.log('currentStateHtml', contentToSave);
                // console.log('nextProps.defaultValue', nextProps.defaultValue);
                // console.log('=?', contentToSave === nextProps.defaultValue);
                // console.log('nextProps', nextProps);

                if(contentToSave === nextProps.defaultValue){
                // states are the  same when we just edit text.
                //    it is the same because we send this component state to the redux store. It can not be the same.
                }else{
                // states are different when we move text up-down.
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                this.setState({
                  // editorState: EditorState.moveFocusToEnd(editorState),
                  editorState: editorState
                });
              }

            }
        }
    }

    onEditorStateChange: Function = (editorState) => {
        this.setState({
          editorState,
        });
        // console.log('editorState', editorState);
        if(this.props.onChange){
            let content = convertToRaw(editorState.getCurrentContent());
            let contentToSave: string;

            //We do not want to save <p></p> as content because in this case we will display the title box with its bottom margin but with zero content inside (empty <p></p> is not content).
            if(content['blocks'].length === 1 && content['blocks'][0]['text'] === ''){
              contentToSave = '';
            }else{
                contentToSave = draftToHtml(content);
            }
            // console.log('onchage');
            // console.log('contentToSave', contentToSave);
            let inStateContent = convertToRaw(this.state.editorState.getCurrentContent());
            let inStateContentHTML = draftToHtml(inStateContent);

            // console.log('inStateContentHTML', inStateContentHTML);
            // console.log('contentToSave', contentToSave);
            // console.log('===', contentToSave === inStateContentHTML);
            if(contentToSave === inStateContentHTML){
                // we do not want to send in Redux zero changes. It will create an unnecessary props and therefore state update and may lead to content mess
            }else{
              this.changeReduxStore(contentToSave);
            }
        }
    };
    returnDebounceTime() {
      let debounceTime = 1000;
      // if Safari or FF - return 1000 because on Safari small delay gives problems. probably on FF too.
      if(checkIfChrome()){
        debounceTime = 500;
      }
      return debounceTime
    };
    changeReduxStore = _.debounce(this.props.onChange ? this.props.onChange : () => {}, this.returnDebounceTime());
    toolbar = {
        options: ['inline', 'blockType', 'list', 'link', 'emoji', 'history'],
        inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'strikethrough'],
            bold: {
                icon: BoldIcon,
                className: undefined
            },
            strikethrough: {
                icon: StrikethroughIcon,
                className: undefined
            },
        },
        blockType: {
            inDropdown: true,
            options: ['Normal', 'H2', 'H3'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
        },
        list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
            unordered: {
                icon: UlIcon,
                className: undefined
            },
            ordered: {
                icon: OlIcon,
                className: undefined
            },
        },
        textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center'],
            left: {
                icon: AlignLeftIcon,
                className: undefined
            },
            center: {
                icon: AlignCenterIcon,
                className: undefined
            },
        },
        link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink'],
            link: {
                icon: LinkIcon,
                className: undefined
            },
            unlink: {
                icon: UnlinkIcon,
                className: undefined
            },
            linkCallback: undefined
        },
        emoji: {
            icon: SmileIcon,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            emojis: [
                '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆',
                '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                '✅', '❎', '💯', '🇮🇳',
            ],
        },
        embedded: {
            icon: PlayIcon,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            embedCallback: undefined,
            defaultSize: {
                height: 'auto',
                width: 'auto',
            },
        },
        history: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['undo'],
            undo: {
                icon: UndoIcon,
                className: undefined
            },
            redo: {
                //icon: redo,
                className: undefined
            },
        },
    };
    setToHTMLEditor = () => {
        return (
            <Popconfirm
                title={<div style={{width: 500}}>Do you want to convert this components' text fields into plain HTML fields? It will give you more flexibility but requires HTML coding skills. This action is irreversible.</div>}
                onConfirm={this.props.revertToPlainHTML ? this.props.revertToPlainHTML : () => {}}
                okText="Yes, make it plain HTML"
                cancelText="Cancel"
                placement="leftTop"
            >
                <Button
                    className="js-revert-to-html-button rdw-editor-revert-button"
                    style={{marginLeft: 3, display: 'none'}}
                    type="danger"
                    size="small"
                    icon="code"
                />
            </Popconfirm>);
    };
    handlePastedText = (text: string, html?: string) => {
      if(html !== null && html !== undefined && html.indexOf('<img') !== -1){
        message.error('Sorry but pasting images in the text editor is not supported.', 5)
        return true;
      }
      // if they try to paste something they shouldn't let's handle it
      // if (text.indexOf('text that should not be pasted') != -1) {
  
      //   // we'll add a message for the offending user to the editor state
      //   const newContent = Modifier.insertText(
      //     this.state.editorState.getCurrentContent(),
      //     this.state.editorState.getSelection(),
      //     'nice try, chump!'
      //   );
  
      //   // update our state with the new editor content
      //   this.onChange(EditorState.push(
      //     this.state.editorState,
      //     newContent,
      //     'insert-characters'
      //   ));
      //   return true;
      // } else {
      //   return false;
      // }
      // Returning true will prevent the default paste behavior.
      // https://draftjs.org/docs/api-reference-editor/#handlepastedtext
    }
    render(){
        const { editorState } = this.state;
        // https://jpuri.github.io/react-draft-wysiwyg/#/demo
        return (
            <div>
                <Editor
                    toolbarCustomButtons={[this.setToHTMLEditor()]}

                    placeholder={this.props.placeholder ? this.props.placeholder : 'Enter text'}
                    // toolbarOnFocus
                    editorClassName="demo-editor-custom content_box"
                    toolbarClassName="demo-toolbar-custom"
                    wrapperClassName="demo-wrapper"
                    toolbar={this.toolbar}
                    handlePastedText={this.handlePastedText}
                    editorState={editorState}
                    onEditorStateChange={this.onEditorStateChange}
                />
            </div>

        )
    }
}

export default MyRichTextEditor;
