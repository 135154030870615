import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import { displayImageUrl } from "../../helpers/content_displayers/displayImageUrl";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}

class Photos02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpGraphics({
                activeOption: GraphicsOptions.image,
                userCanAddItems: false,
                maxItems: 1,
                items: [
                    this.generator.createGraphicsContentObject({
                        imageUrl: DEFAULT_IMAGES_CDN.PHOTO_08,
                        width: 1539,
                        height: 500
                    })
                ]
            }));
            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: 'Together We Are Stronger',
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the photo component to offer more photos to see. It can be a link to your Instagram.",
                    default: "Add a CTA (call to action) to the bottom of the photo component to offer more photos to see. It can be a link to your Instagram.",
                },
                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'See'
                    }),
                ],
                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);
        let imageUrl = displayImageUrl(this.props.componentItem.componentData.graphics.content.items[0][GraphicsOptions.image]); //This component has exactly one item and more can not be added (see createGraphicsContentObject() above).

        return (
            <section>

                <div className="photos-02">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="photos-02__img_box">
                        <img className="photos-02__img" src={imageUrl}/>
                    </div>

                    <div className="container">
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

export default Photos02;
