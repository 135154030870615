import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {Button, Form, Input, message, Tooltip} from "antd";
import setInputStatus from "../../helpers/setInputStatus";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {indexWebsitePageDashboardUrl, projectUrl} from "../../data/constants";
import {openNotification} from "../../helpers/openNotification";
import {messages} from "../../data/messages";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {compareEditedValueWithCurrent} from "../../helpers/editor/compareEditedValueWithCurrent";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import _ from "lodash";

interface Props {
    websites: any,
    websitesPages: any,
    clearUpdatePageMetaTitle: any,
    auth: any,

    currentWebsite: any,
    currentWebsitePage: any,

    saveWebsitePageDataInStore: any,

    pageMetaTitleUpdateWaitingForServer: any,
    pageMetaTitleUpdateToggleInvalidAttempt: any,
    pageMetaTitleUpdateErrorMessage: any,
    meta_title: string,
    setMetaTitle: (meta_title: string) => void,
}
type MyState = {};
class UpdateMetaTitleForm extends Component<Props, MyState>{
    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings active website or page.
        // We want to set up an initial value of a new picked website or page.
        if((this.props.currentWebsite.subdomain !== nextProps.currentWebsite.subdomain) || (this.props.currentWebsitePage.url !== nextProps.currentWebsitePage.url)){
            this.props.setMetaTitle(nextProps.currentWebsitePage.meta_title);
            // We also clear errors when switch a website or a page.
            this.props.clearUpdatePageMetaTitle();
        }
    }

    handleMetaTitleUpdate = (e:any):void => {
        e.preventDefault();

        this.props.pageMetaTitleUpdateWaitingForServer(true);

        //We want to remove all errors from inputs on a new request.
        this.props.clearUpdatePageMetaTitle();


        // Our server API expects the 'indexWebsitePageDashboardUrl' as an URL kwarg because a URL of a home page ('') is in appropriate for API and the dashboard.
        let newMetaTitle = this.props.meta_title;

        let websitePageUrlToServer = this.props.currentWebsitePage.url;
        if(websitePageUrlToServer === ''){
            websitePageUrlToServer = indexWebsitePageDashboardUrl;
        }

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.websitePages.updateWebsitePage.prefix + this.props.currentWebsite.subdomain + '/' + websitePageUrlToServer + api.websitePages.updateWebsitePage.postfix;
        axios.patch(apiUrl, {meta_title: newMetaTitle, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(response => {
                // Server gives the object of the saved website page. Throw it in the store.
                const dataToStore = {
                  data: {
                    ...this.props.currentWebsitePage,
                    meta_title: _.get(response, "data.meta_title")
                  }
                }
                this.props.saveWebsitePageDataInStore(dataToStore, this.props.currentWebsite, this.props.websitesPages.items, this.props.currentWebsitePage.url);

                let successMessage = <span>Successfully changed the page title to <b>{newMetaTitle}</b>.</span>;
                if(newMetaTitle === ''){
                    successMessage = <span>Successfully removed the page title. Now it's blank.</span>;
                }
                
                fireAnalyticsEvent.fireCrisp(CrispEvents.changePageMetaTitle, {
                  subdomain: this.props.currentWebsite.subdomain,
                  page_url: response.data.url,
                  meta_title: response.data.meta_title,
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    handleBadWebsiteError(errorData);
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changePageMetaTitleError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          page_url: websitePageUrlToServer,
                          meta_title: newMetaTitle,
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.meta_title !== undefined){
                        let message = errorData.meta_title.join(' ');
                        this.props.pageMetaTitleUpdateErrorMessage(message);
                        this.props.pageMetaTitleUpdateToggleInvalidAttempt(true);
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.pageMetaTitleUpdateWaitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        this.props.setMetaTitle(e.target.value);
    };

    render(){
        return (
            <form onSubmit={this.handleMetaTitleUpdate} className="settings-box__form" style={{display: "flex", gap: "10px", width: "300px", alignItems: "flex-start"}}>
                <div className="settings-box__input_box" style={{margin: 0, flexGrow: 1}}>
                  
                    <Input name="meta_title"
                           id="meta_title"
                           required={false}
                           placeholder="Enter meta title"
                           type="text"
                           value={this.props.meta_title}
                           onChange={this.handleInputChange}
                           style={{width: "100%"}}
                    />
                  
                  <div className="settings-box__error_message" style={{opacity: this.props.websitesPages.pageMetaTitleUpdateErrorMessage === undefined ? 0 : 1, marginTop: !this.props.websitesPages.pageMetaTitleUpdateErrorMessage ? 0 : "3px"}}>
                      {this.props.websitesPages.pageMetaTitleUpdateErrorMessage}
                  </div>
                </div>
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.websitesPages.isWaitingForPageMetaTitleUpdateResponse}
                    htmlType="submit"
                    hidden={compareEditedValueWithCurrent(this.props.currentWebsitePage.meta_title, this.props.meta_title)}
                    style={{bottom: 0}}
                />
            </form>
        )
    }
}

export default UpdateMetaTitleForm;
