import React from "react";
import { pictogramOptions } from "../../data/pictogramOptions";
import AbstractIcon from "./AbstractIcon";
import Emoji from "./Emoji";
import UploadedIcon from "./UploadedIcon";

interface Props {
  pictogramObject: any;
  className?: string;
  alt?: string;
}

const Pictogram = (props: Props): JSX.Element => {
  const { pictogramObject, className, alt } = props;

  if (pictogramObject.type === pictogramOptions.abstractIcon.title) {
    return <AbstractIcon id={pictogramObject.abstractIconId} />;
  } else if (pictogramObject.type === pictogramOptions.lineaIcon.title) {
    return null; // todo
  } else if (pictogramObject.type === pictogramOptions.uploaded.title) {
    return (
      <UploadedIcon
        uploadedSrc={pictogramObject.uploadedSrc}
        height={pictogramObject.uploadedHeight}
        className={className}
        alt={alt}
      />
    );
  } else if (pictogramObject.type === pictogramOptions.emoji.title) {
    return (
      <Emoji
        emojiSrc={pictogramObject.emojiSrc}
        className={className}
        alt={alt}
      />
    );
  }

  return null;
};

export default Pictogram;
