import React, { Component } from "react";
import { Button, Divider, Form, Icon, Input } from "antd";
import "./JoinForm.css";
import "../../data/urls";
import { Link, Redirect } from "react-router-dom";
import AuthForm from "./AuthForm";
import "./AuthForm.css";
import "./AuthFormHeader.css";
import AuthFormHeader from "./AuthFormHeader";
import setInputStatus from "../../helpers/setInputStatus";
import { urls, api } from "../../data/urls";
import axios from "axios";
import objectToFormData from "../../helpers/objectToFormData";
import {
  getAccessTokenCookie,
  setAccessTokenCookie,
} from "../../helpers/auth/accessTokenCookie";
import { openNotification } from "../../helpers/openNotification";
import { messages } from "../../data/messages";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
// import ReCAPTCHA from "react-google-recaptcha";
import { DISCORD_COLOR_PURPLE } from "../../data/constants";
import _ from "lodash";
import { getMarketingParamsForDiscord } from "../../helpers/getMarketingParamsForDiscord";
import {
  FacebookEvents,
  GoogleTagEvents,
} from "../../enums/AnalyticsEventsEnums";
import { setUserEventsAsync } from "../../helpers/user/setUserEventsAsync";
import { UserEvents } from "../../enums/UserEvents";
import {
  SaveUserEventsInStore,
  SetUserStateData,
} from "../../store/user/types";
import { getAllQueryParams } from "../../helpers/getAllQueryParams";
import { Oauth2Apps } from "../../enums/Oauth2Apps";
import getUrlParameter from "../../helpers/getUrlParameter";
import { getFirstEmailPart } from "../../helpers/strings/getFirstEmailPart";
import { openGoogleLoginPage } from "../../helpers/openGoogleLoginPage";
import GoogleIcon from "./GoogleIcon";

type MyState = {
  // currentCaptchaValue: string
};
// https://github.com/dozoisch/react-google-recaptcha
interface Props {
  auth: any;
  form: any;

  toggleAccessTokenIsValid: any;
  saveAccessTokenInState: any;
  accessTokenToggleServerResponded: any;
  registrationToggleWaitingForServer: any;

  registrationToggleInvalidEmail: any;
  registrationToggleInvalidPassword: any;
  registrationToggleInvalidPasswordRepeat: any;

  registrationSetEmailMessage: any;
  registrationSetPasswordMessage: any;
  registrationSetPasswordRepeatMessage: any;
  validateUserAccessTokenInAuth: any;

  getCaptchaTokenAndSubmitTheForm: any;
  whereUserCameFrom: any;
  toggleNeedsOnboarding: any;

  saveUserEventsInStore: SaveUserEventsInStore;
  setUserStateData: SetUserStateData;
}
class JoinForm extends Component<Props, MyState> {
  componentWillMount(): void {
    if (!getAccessTokenCookie()) {
      this.props.toggleAccessTokenIsValid(false);
      this.props.accessTokenToggleServerResponded(true);
      return;
    }
    this.props.validateUserAccessTokenInAuth();
  }
  clearFieldsMessages() {
    this.props.registrationToggleInvalidEmail(false);
    this.props.registrationToggleInvalidPassword(false);
    this.props.registrationToggleInvalidPasswordRepeat(false);

    this.props.registrationSetEmailMessage(undefined);
    this.props.registrationSetPasswordMessage(undefined);
    this.props.registrationSetPasswordRepeatMessage(undefined);
  }
  onChange = (value) => {
    // this.setState({
    //     currentCaptchaValue: value
    // })
  };
  handleSubmit = (e: any) => {
    e.preventDefault();
    let submitForm = () => {
      // When submit the form - all error messages are cleared.
      // When submit the form - all red fields are back to normal.
      this.clearFieldsMessages();

      // let currentCaptchaValue = this.state.currentCaptchaValue;
      // let isCaptchaSolved = false;
      // if(currentCaptchaValue && currentCaptchaValue !== undefined && currentCaptchaValue !== null && currentCaptchaValue !== ''){
      //     isCaptchaSolved = true;
      // }

      this.props.form.validateFieldsAndScroll((err: object, values: object) => {
        const email = _.get(values, "email");
        if (typeof email === "string" && email.includes("+")) {
          this.props.registrationSetEmailMessage("Email can't contain '+'");
          this.props.registrationToggleInvalidEmail(true);
          return;
        }
        if (!err) {
          this.props.registrationToggleWaitingForServer(true);
          // let dataSentToServer = objectToFormData({...values, 'captchaToken': captchaToken});
          let dataSentToServer = objectToFormData({ ...values });
          axios
            .post(api.auth.registration, dataSentToServer)
            .then((response) => {
              // handle success

              const params = getMarketingParamsForDiscord();
              const name = _.get(values, "first_name");
              const email = _.get(values, "email");

              // Django returns the access token.
              let accessToken = response.data.key;

              setAccessTokenCookie(accessToken);

              // We immediately save the token in the state for further requests authorization.
              this.props.saveAccessTokenInState(accessToken);

              // Since we just received a fresh token from the server, no need to validate it. We are sure it's valid.
              // We should keep in store that the token is valid.
              this.props.toggleAccessTokenIsValid(true);

              // We also save the token in a Cookie to save the user session. When a user enter the app, we check this cookie. If the cookie valid - we fetch a user data and display it. If the cookie is invalid - we erase it and require to login/sign up.
              // console.log('accessToken', accessToken);
              // console.log(getAccessTokenCookie());
              // console.log(getAccessTokenCookie());
              // (window as any).history.push('/');
              // console.log(getAccessTokenCookie());

              openNotification(
                "Welcome! 🦄",
                "Now please confirm your email (check the spam folder).",
                "OK",
                "success",
                30
              );

              // set needsOnboarding to true to onboard the user. Since this state is only set here (after sign up) we make sure a suer does not get onboarding twice
              this.props.toggleNeedsOnboarding(true);

              fireAnalyticsEvent.fireFacebook(FacebookEvents.registration);
              fireAnalyticsEvent.fireGoogleTag(GoogleTagEvents.signUp);
              window.twq("event", "tw-oeqwf-oeqws", {
                email_address: email,
              });

              setUserEventsAsync(
                {
                  [UserEvents.SignedUp]: new Date(),
                  [UserEvents.SignedUpWebsiteCount]: new Date(),
                },
                accessToken,
                {}
              ).then((response) => {
                if (response) {
                  this.props.saveUserEventsInStore(response.data);
                }
              });

              this.props.setUserStateData({
                isSignedUp: true,
              });
              window.fpr("referral", { email });
            })
            .catch((error) => {
              // handle error
              try {
                let errorData = error.response.data;

                if (errorData.captcha !== undefined) {
                  let message = errorData.captcha.join(" ");
                  openNotification(
                    "We can not pass you because you failed to complete the captcha :(",
                    message,
                    "Close",
                    "error",
                    30
                  );
                }

                if (errorData.email !== undefined) {
                  let message = errorData.email.join(" ");
                  this.props.registrationSetEmailMessage(message);
                  this.props.registrationToggleInvalidEmail(true);
                }

                if (errorData.password1 !== undefined) {
                  let message = errorData.password1.join(" ");
                  this.props.registrationSetPasswordMessage(message);
                  this.props.registrationToggleInvalidPassword(true);
                }

                if (errorData.password2 !== undefined) {
                  let message = errorData.password2.join(" ");
                  this.props.registrationSetPasswordRepeatMessage(message);
                  this.props.registrationToggleInvalidPasswordRepeat(true);
                }

                if (errorData.non_field_errors !== undefined) {
                  // Usually "The two password fields didn't match."
                  let message = errorData.non_field_errors.join(" ");
                  this.props.registrationSetPasswordMessage(message);
                  this.props.registrationToggleInvalidPassword(true);

                  this.props.registrationSetPasswordRepeatMessage(message);
                  this.props.registrationToggleInvalidPasswordRepeat(true);
                }
              } catch (e) {
                openNotification(
                  "Server error",
                  messages.errors.other.serverUnavailable,
                  "OK",
                  "error"
                );
              }
            })
            .then((response) => {
              // always executed
              this.props.registrationToggleWaitingForServer(false);
            });
        }
      });
    };
    // this.props.getCaptchaTokenAndSubmitTheForm(submitForm);
    submitForm();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const queryString = getAllQueryParams();
    const appParam = getUrlParameter("oauth2_app") as Oauth2Apps;

    // Used to prefill form data
    const emailParam = getUrlParameter("email");
    const userName = getFirstEmailPart(emailParam);
    const userEmail = emailParam || "";

    if (
      this.props.auth.accessTokenValidated === true &&
      this.props.auth.isAccessTokenValid === true
    ) {
      if (Object.values(Oauth2Apps).includes(appParam)) {
        return <Redirect to={`/permission/${appParam}${queryString}`} />;
      }
      return <Redirect to={`/${queryString}`} />;
    }

    const formItemLayout = {
      // labelCol: {
      //     xs: { span: 24 },
      //     sm: { span: 8 },
      // },
      // wrapperCol: {
      //     xs: { span: 24 },
      //     sm: { span: 16 },
      // },
    };

    return (
      <AuthForm>
        {/*<ValidateUser auth={this.props.auth}*/}
        {/*toggleAccessTokenIsValid={this.props.toggleAccessTokenIsValid}*/}
        {/*saveAccessTokenInState={this.props.saveAccessTokenInState}*/}
        {/*redirectToHome={true}*/}
        {/*redirectToLogin={false}*/}
        {/*accessTokenToggleServerResponded={this.props.accessTokenToggleServerResponded}*/}
        {/*/>*/}
        <div className="login-form">
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="login-form__form"
          >
            <AuthFormHeader title="Register" subtitle="No credit card required">
              <Icon type="lock" title="Secure form" style={{ opacity: 0.5 }} />
            </AuthFormHeader>

            <div>
              <Button onClick={openGoogleLoginPage} size="large" block>
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 10,
                    justifyContent: "center",
                    lineHeight: 1,
                  }}
                >
                  <GoogleIcon /> Continue with Google
                </span>
              </Button>
              <Divider style={{ margin: "24px 0 16px", fontSize: 14 }}>
                Or
              </Divider>
                </div>

            {this.props.whereUserCameFrom && (
              <Form.Item label="AppSumo sales ended">
                {getFieldDecorator("appsumo_code", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your AppSumo code!",
                    },
                  ],
                })(
                  <Input
                    disabled
                    required={true}
                    value={"uni-"}
                    maxLength={8}
                    name="appsumo_code"
                    id="appsumo_code"
                    placeholder="uni-****"
                  />
                )}
              </Form.Item>
            )}

            <Form.Item
              label="Name"
              validateStatus={setInputStatus(
                this.props.auth.join.isInvalidFirstName
              )}
              help={this.props.auth.join.firstNameMessage}
            >
              {getFieldDecorator("first_name", {
                // rules: [{ required: true, message: 'Please input your first_name!' }],
                initialValue: userName,
              })(
                <Input
                  required={true}
                  name="first_name"
                  id="first_name"
                  placeholder="First name"
                />
              )}
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              {/*<Form.Item style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginRight: '8px' }} validateStatus={setInputStatus(this.props.auth.join.isInvalidFirstName)} help={this.props.auth.join.firstNameMessage}>*/}
              {/*    {getFieldDecorator('first_name', {*/}
              {/*        // rules: [{ required: true, message: 'Please input your first_name!' }],*/}
              {/*    })(*/}
              {/*        <Input required={true} name="first_name" id="first_name" placeholder="First name"/>*/}
              {/*    )}*/}
              {/*</Form.Item>*/}
              {/*<Form.Item style={{ display: 'inline-block', width: 'calc(50% - 8px)', marginLeft: '8px' }} validateStatus={setInputStatus(this.props.auth.join.isInvalidSecondName)} help={this.props.auth.join.secondNameMessage}>*/}
              {/*    {getFieldDecorator('second_name', {*/}
              {/*        // rules: [{ required: true, message: 'Please input your second_name!' }],*/}
              {/*    })(*/}
              {/*        <Input name="second_name" id="second_name" placeholder="Last name"/>*/}
              {/*    )}*/}
              {/*</Form.Item>*/}
            </Form.Item>

            <Form.Item
              label="E-mail"
              validateStatus={setInputStatus(
                this.props.auth.join.isInvalidEmail
              )}
              help={this.props.auth.join.emailMessage}
            >
              {getFieldDecorator("email", {
                // rules: [{ required: true, message: 'Please input your email!' }],
                initialValue: userEmail,
              })(
                <Input
                  name="email"
                  id="email"
                  required={true}
                  allowClear={true}
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Email"
                  type="email"
                />
              )}
            </Form.Item>
            <Form.Item
              label="Password"
              validateStatus={setInputStatus(
                this.props.auth.join.isInvalidPassword
              )}
              help={this.props.auth.join.passwordMessage}
            >
              {getFieldDecorator("password1", {
                // rules: [{ required: true, message: 'Please input your password!' }],
              })(
                <Input
                  name="password1"
                  required={true}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                  autoFocus={!!emailParam}
                />
              )}
            </Form.Item>
            <Form.Item
              label="Repeat password"
              validateStatus={setInputStatus(
                this.props.auth.join.isInvalidPasswordRepeat
              )}
              help={this.props.auth.join.passwordRepeatMessage}
            >
              {getFieldDecorator("password2", {
                // rules: [{ required: true, message: 'Please input your password!' }],
              })(
                <Input
                  name="password2"
                  required={true}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Repeat password"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                size="large"
                type="primary"
                loading={this.props.auth.join.isWaitingForServer}
                htmlType="submit"
                className="login-form__button"
                block
              >
                Create an account
              </Button>
            </Form.Item>
          </Form>
          <div className="join-form__agreements">
            By creating an account, you agree to the{" "}
            <a href={urls.tos} target="_blank">
              terms of service
            </a>{" "}
            and{" "}
            <a href={urls.privacyPolicy} target="_blank">
              privacy policy
            </a>
            . We use emails to announce updates and important news.
          </div>
          <Link
            to={`/auth/login${queryString}`}
            className="join-form__bottom_button"
          >
            <Button
              type="default"
              htmlType="button"
              className=""
              onClick={() => {
                this.clearFieldsMessages();
              }}
            >
              Login
            </Button>
          </Link>
        </div>
      </AuthForm>
    );
  }
}

export default Form.create<Props>({})(JoinForm);
