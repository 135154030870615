import React from 'react';
import { Switch } from 'antd';
import generateContentDataObject from '../../../helpers/editor/generateContentDataObject';
import { ComponentDataPaths, PopupType } from '../../../helpers/types/popupTypes';

interface Props {
    className?: string;

    popupType: PopupType;
    popupData: { isActive: boolean, popupId: string };

    contentType: any;
    currentInputData: any;
    buttonIndex?: number;

    saveContentInStore: (payload: object) => void;
    toggleNewEditionsDetected: (payload: boolean) => void;

    componentDataPath: ComponentDataPaths;
};

const PopupSwitch = (props: Props): JSX.Element => {
    const generator = new generateContentDataObject();
    const { contentType, currentInputData, componentDataPath, popupType } = props;

    const saveNewPopupDataInStore = (checked: boolean) => {
        props.saveContentInStore(generator.togglePopupActive({ [contentType]: currentInputData }, checked, componentDataPath, popupType, contentType));
    }

    const handleOnChange = (checked: boolean) => {
        saveNewPopupDataInStore(checked);
        props.toggleNewEditionsDetected(true);
    }

    return (
        <div className={props.className || ""}>
            <Switch
                checkedChildren="on"
                unCheckedChildren="off"
                defaultChecked={props.popupData.isActive}
                onChange={handleOnChange}
            />
        </div>
    );
}

export default PopupSwitch;