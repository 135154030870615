import React, {Component} from "react";


interface Props {
    currentWebsite: any,
}

class CustomColorsStyles extends Component<Props>{
    componentDidMount(): void {
    }

    render(){
        if(this.props.currentWebsite && this.props.currentWebsite.custom_color_palette && this.props.currentWebsite.custom_color_palette.isCustomPaletteActivated){

            let customPalette = this.props.currentWebsite.custom_color_palette;

            return (
                <style dangerouslySetInnerHTML={{__html: `
html .custom-colors-enabled .fill-main{
    fill: ${customPalette.accent}
}

html .custom-colors-enabled .button--accent-outline .icon svg *,
html .button--accent-outline .icon svg .custom-colors-enabled *,
html .custom-colors-enabled .button--accent-outline .button__system_icon svg *,
html .button--accent-outline .button__system_icon svg .custom-colors-enabled * {
    fill: ${customPalette.primaryButton}
}

html .custom-colors-enabled .stroke-main {
    stroke: ${customPalette.accent}
}

html .custom-colors-enabled .comps .button--accent-outline,
html .comps .custom-colors-enabled .button--accent-outline,
html  .custom-colors-enabled .store-button--white:hover{
    border-color: ${customPalette.primaryButton}
}
html .custom-colors-enabled .border-main,
html .custom-colors-enabled .text-input:focus,
html .custom-colors-enabled .form__input .chosen-container.chosen-container-active .chosen-choices,
html .custom-colors-enabled .textarea:focus,
html .custom-colors-enabled .select:focus,
html .custom-colors-enabled .pricing-02__card_box--accent,
html .custom-colors-enabled .pricing-03__hover,
html .custom-colors-enabled .pricing-04__card--accent,
html .custom-colors-enabled .pricing-04__hover,
html .custom-colors-enabled .pricing-05__hover,
html .custom-colors-enabled .bg-medium-color .cta_form-04__wrapper,
html .bg-medium-color .custom-colors-enabled .cta_form-04__wrapper,
html .custom-colors-enabled .bg-light-color .cta_form-04__wrapper,
html .bg-light-color .custom-colors-enabled .cta_form-04__wrapper,
html .custom-colors-enabled .bg-medium-color .cta_form-04__bottom,
html .bg-medium-color .custom-colors-enabled .cta_form-04__bottom,
html .custom-colors-enabled .bg-light-color .cta_form-04__bottom,
html .bg-light-color .custom-colors-enabled .cta_form-04__bottom,
html .custom-colors-enabled .bg-medium-color .cta_button-16__wrapper,
html .bg-medium-color .custom-colors-enabled .cta_button-16__wrapper,
html .custom-colors-enabled .bg-light-color .cta_button-16__wrapper,
html .bg-light-color .custom-colors-enabled .cta_button-16__wrapper,
html .custom-colors-enabled .bg-medium-color .links-02__list,
html .bg-medium-color .custom-colors-enabled .links-02__list,
html .custom-colors-enabled .bg-light-color .links-02__list,
html .bg-light-color .custom-colors-enabled .links-02__list,
html .custom-colors-enabled .faq-01__question:hover,
html .custom-colors-enabled .faq-03__question:hover,
html .custom-colors-enabled .features-09__item--new,
html .custom-colors-enabled .bg-medium-color .steps-01__number,
html .bg-medium-color .custom-colors-enabled .steps-01__number,
html .custom-colors-enabled .bg-light-color .steps-01__number,
html .bg-light-color .custom-colors-enabled .steps-01__number {
    border-color: ${customPalette.accent}
}



html .custom-colors-enabled .comps .button--accent-outline,
html .comps .custom-colors-enabled .button--accent-outline{
    color: ${customPalette.primaryButton}
}
html .custom-colors-enabled .color-main,
html .custom-colors-enabled .link,
html .custom-colors-enabled .feature__link,
html .custom-colors-enabled .testimonials-03__person_link,
html .custom-colors-enabled .footer-01__link,
html .custom-colors-enabled .faq-05__link,
html .custom-colors-enabled .features-16__link,
html .custom-colors-enabled .subheading,
html .custom-colors-enabled .content_box a,
html .content_box .custom-colors-enabled a,
html .custom-colors-enabled .tab__button.state-active-tab,
html .custom-colors-enabled .tab__button--active:focus,
html .custom-colors-enabled .score__value,
html .custom-colors-enabled .form__message_opener_box,
html .custom-colors-enabled .message__bubble_link,
html .custom-colors-enabled .team-05__person_name,
html .custom-colors-enabled .tabs-04__button.state-active-tab,
html .custom-colors-enabled .tabs-04__button--active:focus,
html .custom-colors-enabled .pricing-02__hover,
html .custom-colors-enabled .pricing-03__hover,
html .custom-colors-enabled .pricing-04__hover,
html .custom-colors-enabled .pricing-05__hover,
html .custom-colors-enabled .cta_button-25__subheading,
html .custom-colors-enabled .cta_button-31__heading,
html .custom-colors-enabled .footer-03__pages_link:hover,,
html .custom-colors-enabled .code-02__info_prefix,
html .custom-colors-enabled .features-17__item .feature__title_text,
html .features-17__item .custom-colors-enabled .feature__title_text {
    color: ${customPalette.accent}
}

html .custom-colors-enabled .color-main-hover:hover,
html .custom-colors-enabled .link:hover,
html .custom-colors-enabled .feature__link:hover,
html .custom-colors-enabled .testimonials-03__person_link:hover,
html .custom-colors-enabled .footer-01__link:hover,
html .custom-colors-enabled .faq-05__link:hover,
html .custom-colors-enabled .features-16__link:hover, html .custom-colors-enabled .message__bubble_link:hover {
    color: ${customPalette.primaryButtonHover}
}

html .custom-colors-enabled .comps .button--accent-bg,
html .comps .custom-colors-enabled .button--accent-bg,
html .custom-colors-enabled .store-button:hover,
html .custom-colors-enabled .circle-button--accent-bg,
html .custom-colors-enabled .circle-button--accent-bg .circle-button__hover_bg,
html .circle-button--accent-bg .custom-colors-enabled .circle-button__hover_bg,
html .custom-colors-enabled .circle-button--light-bg:hover,
html .custom-colors-enabled .slick-dots li.slick-active button,
html .slick-dots li.slick-active .custom-colors-enabled button{
    background-color: ${customPalette.primaryButton}
}
html .custom-colors-enabled .bg-main,
html .custom-colors-enabled .radio__check:before,
html .custom-colors-enabled .checkbox__check:before,
html .custom-colors-enabled .social-buttons__link--circle,
html .custom-colors-enabled .roadmap-01__column,
html .custom-colors-enabled .cta_button-25__item::before,
html .custom-colors-enabled .testimonials-05__blue_box::before,
html .custom-colors-enabled .footer-03__pages_item:after,
html .custom-colors-enabled .features-02__number:before,
html .custom-colors-enabled .features-09__item--new .tag,
html .features-09__item--new .custom-colors-enabled .tag,
html .custom-colors-enabled .features-15__item::before,
html .custom-colors-enabled .bg-medium-color .steps-01__list:before,
html .bg-medium-color .custom-colors-enabled .steps-01__list:before,
html .custom-colors-enabled .bg-light-color .steps-01__list:before,
html .bg-light-color .custom-colors-enabled .steps-01__list:before,
.comps .custom-colors-enabled .bg-accent-color .footer-02,
.comps .custom-colors-enabled .bg-accent-color .footer-04{
    background-color: ${customPalette.accent}
}

html .custom-colors-enabled .bg-main-hover:hover,
html .custom-colors-enabled .comps .button--accent-bg:hover,
html .comps .custom-colors-enabled .button--accent-bg:hover,
html .custom-colors-enabled .checkbox__input:checked:hover ~ .checkbox__check:hover:before {
    background-color: ${customPalette.primaryButtonHover}
}

html .custom-colors-enabled .border-alt,
html .custom-colors-enabled .comps .button--alt-accent-outline,
html .comps .custom-colors-enabled .button--alt-accent-outline,
html .custom-colors-enabled .bg-accent-color .pricing-02__card_box--accent,
html .bg-accent-color .custom-colors-enabled .pricing-02__card_box--accent,
html .custom-colors-enabled .bg-accent-color .pricing-04__card--accent,
html .bg-accent-color .custom-colors-enabled .pricing-04__card--accent {
    border-color: ${customPalette.secondaryButton}
}

html .custom-colors-enabled .color-alt,
html .custom-colors-enabled .comps .button--alt-accent-outline,
html .comps .custom-colors-enabled .button--alt-accent-outline {
    color: ${customPalette.secondaryButton}
}

html .custom-colors-enabled .bg-alt,
html .custom-colors-enabled .comps .button--alt-accent-bg,
html .comps .custom-colors-enabled .button--alt-accent-bg,
html .custom-colors-enabled .bg-accent-color .slick-dots li.slick-active button,
html .bg-accent-color .slick-dots li.slick-active .custom-colors-enabled button {
    background-color: ${customPalette.secondaryButton}
}

html .custom-colors-enabled .bg-alt-hover:hover,
html .custom-colors-enabled .comps .button--alt-accent-bg:hover,
html .comps .custom-colors-enabled .button--alt-accent-bg:hover {
    background-color: ${customPalette.secondaryButtonHover}
}

html .custom-colors-enabled .fill-alt .icon svg *,
html .custom-colors-enabled .comps .button--alt-accent-outline .icon svg *,
html .comps .custom-colors-enabled .button--alt-accent-outline .icon svg *,
html .custom-colors-enabled .fill-alt .button__system_icon svg *,
html .custom-colors-enabled .comps .button--alt-accent-outline .button__system_icon svg *,
html .comps .custom-colors-enabled .button--alt-accent-outline .button__system_icon svg * {
    fill: ${customPalette.secondaryButton}
}

html .custom-colors-enabled .fill-alt-hover:hover .icon svg *,
html .custom-colors-enabled .feature__link:hover .icon svg *,
html .custom-colors-enabled .fill-alt-hover:hover .button__system_icon svg *,
html .custom-colors-enabled .feature__link:hover .button__system_icon svg * {
    fill: ${customPalette.secondaryButton}
}

html .custom-colors-enabled .bg-accent-color .page-component__bg_overlay_box:not(.page-component__bg_overlay_box--always-white),
html .custom-colors-enabled .bg-accent-color .background-color-picker__circle,
html .custom-colors-enabled .bg-accent-color .text--01__box {
    background-color: ${customPalette.accent}
}
.custom-colors-enabled {
  --primary: ${customPalette.primaryButton};
  --primary-dark: ${customPalette.primaryButtonHover};
  --accent: ${customPalette.accent};
  --secondary: ${customPalette.secondaryButton};
  --secondary-dark: ${customPalette.secondaryButtonHover};
}
                `}}>

                </style>
            )
        }else{
            return (null);
        }
    }
}

export default CustomColorsStyles;
