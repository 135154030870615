import React from "react";
import { Icon } from "antd";

interface Props {
  href?: string;
  hasIcon?: boolean;
  children?: React.ReactChild;
}

const TooltipLink = (props: Props): JSX.Element => {
  const { href, children, hasIcon } = props;

  return (
    <a className="content-input__tooltip-link" href={href} target="_blank">
      {children}
      {hasIcon && (
        <Icon className="content-input__tooltip-link-icon" type="link" />
      )}
    </a>
  );
};

export default TooltipLink;
