import {
  PageComponentCategoriesItem,
  PageComponentCategoriesState,
} from "../store/pageComponentCategories/types";
import { PageComponentsItem } from "../store/pageComponents/types";

export const getComponentCategoryItem = (
  pageComponentCategories: PageComponentCategoriesState,
  pageComponentsItem: PageComponentsItem,
  isComponentAdvanced?: boolean
): PageComponentCategoriesItem => {
  if (isComponentAdvanced) {
    return pageComponentCategories.items.find(
      (category) => category.id === pageComponentsItem.original_component_category_id
    );
  }
  return pageComponentCategories.items.find(
    (category) => category.id === pageComponentsItem.category
  );
};
