import DebouncedInput from "../../DebouncedInput";
import React, { FC, useEffect, useRef } from "react";
import { InputProps } from "./interfaces";
import { useCurrentInputData } from "./useCurrentInputData";

const Title: FC<any> = ({
  saveContentInStore,
  generator,
  contentType,
  currentInputData,
  toggleNewEditionsDetected,
  content,
}: InputProps) => {
  
  const currentInputDataRef = useCurrentInputData(currentInputData);

  let onChangeHandler = (value: any) => {
    saveContentInStore(
      generator.changeTitleMarkup(
        { [contentType]: currentInputDataRef.current },
        value
      )
    );
    toggleNewEditionsDetected(true);
  };
  return (
    <DebouncedInput
      className="js-tour__change_title"
      onChange={onChangeHandler}
      placeholder={"Enter component title"}
      defaultValue={content.markup}
      id={contentType}
    />
  );
};

export default Title;
