import { freePlanTitle } from "../../data/constants";

export const getCurrentPlan = (user: any, plans: any) => {
  let userPlanId = user.data.current_plan;
  let plansCount = plans.items.length;

  for (let i = 0; i < plansCount; i++) {
    let currentPlan = plans.items[i];

    if (currentPlan.id === userPlanId) {
      //found current active plan
      return currentPlan;
    }
  }
};
