import { ConfettiSettings } from "../types/globalTypes";

export const showConfetti = () => {
  let confettiSettings: ConfettiSettings = { target: "confetti-holder" };
  let confettiHolder = document.getElementById("confetti-holder");
  if (confettiHolder) {
    confettiHolder.setAttribute("style", "opacity: 1"); //to remove initial height and width inline styles

    let confetti = new window.ConfettiGenerator(confettiSettings);
    confetti.render();

    setTimeout(function() {
      confettiHolder.setAttribute("style", "opacity: 0");
    }, 5000);

    setTimeout(function() {
      confetti.clear();
      confettiHolder.remove();
    }, 6000);
  }
};
