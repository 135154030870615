import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from "react-router-dom";
import { Button } from "antd";

interface Props {
  loading: boolean;
  deleteAction: any;
  deleteItemTitle: string;

  cancelAction: any;
}

class DeleteButtons extends Component<Props> {
  componentDidMount(): void {}

  render() {
    let deleteItemTitle = this.props.deleteItemTitle;
    if (deleteItemTitle === "") {
      deleteItemTitle = "Home page";
    }

    return (
      <div>
        <Button
          size={"small"}
          style={{ marginRight: "10px" }}
          htmlType="button"
          type="primary"
          onClick={(event: any) => {
            event.preventDefault();
            event.stopPropagation();
            this.props.cancelAction();
          }}
        >
          Cancel
        </Button>

        <Button
          size={"small"}
          htmlType="button"
          type="danger"
          loading={this.props.loading}
          onClick={(event: any) => {
            event.preventDefault();
            this.props.deleteAction();
          }}
        >
          Delete "{deleteItemTitle}"?
        </Button>
      </div>
    );
  }
}

export default DeleteButtons;
