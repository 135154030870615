import React from "react";
import { Icon } from "antd";
import { GraphicsOptions } from "../../component_graphics/data/componentGraphicsData";
import ImagePicker from "../../ImagePicker";
import DeleteButton from "./DeleteButton";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { graphicsWithOneItem } from "../../../../data/componentExceptions";
import { Components } from "../../../../enums/Components";

interface Props {
  content: any;
  saveContentInStore: (payload: any) => void;
  generator: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  toggleNewEditionsDetected: any;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  displayMoveUpDownButtons: (
    index: number,
    itemsCount: number,
    moveUp: any,
    moveDown: any
  ) => JSX.Element;
  lastItem: any;
  componentType: string;
  componentTitle: string;
}

const GraphicsImageInput = (props: Props): JSX.Element => {
  const {
    content,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
    saveImageInfoInDB,
    removeImageFromDB,
    displayMoveUpDownButtons,
    lastItem,
    componentType,
    componentTitle,
  } = props;

  let graphicsInputs = content.items.map((item: any, key: number) => {
    const componentName = `${componentType}-${componentTitle}` as Components;
    if (graphicsWithOneItem.includes(componentName) && key > 0) {
      return null;
    }
    let imageInputs = (
      <div
        className="content-input__input"
        key={"graphics-item-" + key.toString()}
      >
        <div className="content-input__image_picker_box">
          <ImagePicker
            currentImgAlt={content.items[key][GraphicsOptions.image].alt}
            saveImageAltInReduxStore={(newAlt: string) => {
              saveContentInStore(
                generator.changeGraphicsImageAlt(
                  {
                    [contentType]: currentInputData,
                  },
                  newAlt,
                  key
                )
              );

              toggleNewEditionsDetected(true);
            }}
            currentImgSrc={content.items[key][GraphicsOptions.image].url}
            currentImgUUID={content.items[key][GraphicsOptions.image].uuid}
            inputId={contentType}
            saveImageInfoInDB={saveImageInfoInDB}
            removeImageFromDB={removeImageFromDB}
            saveImageInReduxStore={(
              newSrc: string,
              newUUID: any,
              width: any,
              height: any
            ) => {
              saveContentInStore(
                generator.changeGraphicsImageUrl(
                  {
                    [contentType]: currentInputData,
                  },
                  newSrc,
                  key
                )
              );
              saveContentInStore(
                generator.changeGraphicsImageUUID(
                  {
                    [contentType]: currentInputData,
                  },
                  newUUID,
                  key
                )
              ); //'' for no image

              saveContentInStore(
                generator.changeGraphicsImageWidth(
                  {
                    [contentType]: currentInputData,
                  },
                  width,
                  key
                )
              );
              saveContentInStore(
                generator.changeGraphicsImageHeight(
                  {
                    [contentType]: currentInputData,
                  },
                  height,
                  key
                )
              );

              toggleNewEditionsDetected(true);
            }}
          />
          {!!content.items[key][GraphicsOptions.image].uploadButtonInfo &&
            content.items[key][GraphicsOptions.image].url === "" && (
              <span className="content-input__edit_logo_help">
                <Icon
                  className="content-input__edit_logo_icon"
                  type="arrow-left"
                />{" "}
                {content.items[key][GraphicsOptions.image].uploadButtonInfo}
              </span>
            )}
        </div>
      </div>
    );

    if (currentInputData.userCanAddItems === false) {
      // If user is not allowed to add items - just display existing images and their fields. This means the amount of images is hardcoded and is predefined by us - design of a template required
      return (
        <div
          className="content-input__input"
          key={"graphics-item-" + key.toString()}
        >
          {imageInputs}
        </div>
      );
    } else if (currentInputData.userCanAddItems === true) {
      // If you can add new items - display cluster and a delete button - just like in other countable elements (pricing table, features etc (see examples in this file)).
      let maxItemsReached = lastItem[GraphicsOptions.image].url !== "";
      let itemsCount = maxItemsReached
        ? content.items.length
        : content.items.length - 1;
      return (
        <div
          className={
            "content-input__cluster " +
            (maxItemsReached === false
              ? " content-input__cluster--with-ghost "
              : " ")
          }
          data-add-new="Add new image ⤵"
          key={"image-fields-" + key.toString()}
        >
          {
            <DeleteButton
              keyProp={key}
              toggleNewEditionsDetected={toggleNewEditionsDetected}
              saveContentInStore={saveContentInStore}
              generator={generator}
              contentType={contentType}
              currentInputData={currentInputData}
            />
          }
          {displayMoveUpDownButtons(
            key,
            itemsCount,
            () => {
              saveContentInStore(
                generator.moveGraphicsUp(
                  {
                    [contentType]: currentInputData,
                  },
                  key
                )
              );
            },
            () => {
              saveContentInStore(
                generator.moveGraphicsDown(
                  {
                    [contentType]: currentInputData,
                  },
                  key
                )
              );
            }
          )}
          {imageInputs}
        </div>
      );
    }
  });

  return graphicsInputs;
};

export default GraphicsImageInput;
