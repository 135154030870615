import {
  getAccessTokenCookie,
  removeAccessTokenCookie,
} from "../../helpers/auth/accessTokenCookie";
import axios from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";

const actionsNames = {
  REGISTRATION_TOGGLE_WAITING_FOR_SERVER: "REGISTRATION_TOGGLE_WAITING_FOR_SERVER" as string,

  REGISTRATION_TOGGLE_INVALID_EMAIL: "REGISTRATION_TOGGLE_INVALID_EMAIL" as string,
  REGISTRATION_TOGGLE_INVALID_PASSWORD: "REGISTRATION_TOGGLE_INVALID_PASSWORD" as string,
  REGISTRATION_TOGGLE_INVALID_PASSWORD_REPEAT: "REGISTRATION_TOGGLE_INVALID_PASSWORD_REPEAT" as string,

  REGISTRATION_SET_EMAIL_MESSAGE: "REGISTRATION_SET_EMAIL_MESSAGE" as string,
  REGISTRATION_SET_PASSWORD_MESSAGE: "REGISTRATION_SET_PASSWORD_MESSAGE" as string,
  REGISTRATION_SET_PASSWORD_REPEAT_MESSAGE: "REGISTRATION_SET_PASSWORD_REPEAT_MESSAGE" as string,

  LOGIN_TOGGLE_WAITING_FOR_SERVER: "LOGIN_TOGGLE_WAITING_FOR_SERVER" as string,

  LOGIN_TOGGLE_INVALID_EMAIL: "LOGIN_TOGGLE_INVALID_EMAIL" as string,
  LOGIN_TOGGLE_INVALID_PASSWORD: "LOGIN_TOGGLE_INVALID_PASSWORD" as string,

  LOGIN_SET_EMAIL_MESSAGE: "LOGIN_SET_EMAIL_MESSAGE" as string,
  LOGIN_SET_PASSWORD_MESSAGE: "LOGIN_SET_PASSWORD_MESSAGE" as string,

  RESET_PASS_TOGGLE_WAITING_FOR_SERVER: "RESET_PASS_TOGGLE_WAITING_FOR_SERVER" as string,
  RESET_PASS_TOGGLE_INVALID_ATTEMPT: "RESET_PASS_TOGGLE_INVALID_ATTEMPT" as string,
  RESET_PASS_SET_MESSAGE: "RESET_PASS_SET_MESSAGE" as string,

  SET_NEW_PASS_TOGGLE_WAITING_FOR_SERVER: "SET_NEW_PASS_TOGGLE_WAITING_FOR_SERVER" as string,
  SET_NEW_PASS_TOGGLE_INVALID_PASSWORD: "SET_NEW_PASS_TOGGLE_INVALID_PASSWORD" as string,
  SET_NEW_PASS_TOGGLE_INVALID_PASSWORD_REPEAT: "SET_NEW_PASS_TOGGLE_INVALID_PASSWORD_REPEAT" as string,
  SET_NEW_PASS_SET_PASSWORD_MESSAGE: "SET_NEW_PASS_SET_PASSWORD_MESSAGE" as string,
  SET_NEW_PASS_SET_PASSWORD_REPEAT_MESSAGE: "SET_NEW_PASS_SET_PASSWORD_REPEAT_MESSAGE" as string,

  EMAIL_CONFIRMATION_TOGGLE_SERVER_RESPONDED: "EMAIL_CONFIRMATION_TOGGLE_SERVER_RESPONDED" as string,

  SET_ACCESS_TOKEN_IS_VALID: "SET_ACCESS_TOKEN_IS_VALID" as string,
  SAVE_ACCESS_TOKEN: "SAVE_ACCESS_TOKEN" as string,
  ACCESS_TOKEN_VALIDATED: "ACCESS_TOKEN_VALIDATED" as string,

  TOGGLE_RESEND_EMAIL_CONFIRMATION_WAITING: "TOGGLE_RESEND_EMAIL_CONFIRMATION_WAITING" as string,
  TOGGLE_NEEDS_ONBOARDING: "TOGGLE_NEEDS_ONBOARDING" as string,
};

export const logout = (isAccountDeleted?: boolean) => {
  return (dispatch: any, getState: any) => {
    axios
      .post(api.auth.logout, {})
      .then((response) => {
        dispatch(toggleAccessTokenIsValid(false));
        dispatch(saveAccessTokenInState(undefined));
        dispatch(accessTokenToggleServerResponded(false));

        removeAccessTokenCookie();
        if (isAccountDeleted) {
          localStorage.setItem(
            "deletedAccountEmail",
            getState().user.data.email
          );
        }
        window.posthog.reset();
        location.reload(); // to clear the websites, websitesPages and other data from the Redux store. Must be done without this workaround.
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

let checkUserTokenRequest = (successCallback?: any) => {
  return (dispatch: any, getState: any) => {
    let accessToken = getAccessTokenCookie();
    // We don't have a dedicated API endpoint for checking the token. We just try to retrieve some data about the current user and see if it's a successful attempt.
    // console.log('accessToken', accessToken);
    axios
      .get(api.auth.user, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        // If the accessToken is valid, we set the auth.accessToken in state for further requests and set auth.isAccessTokenValid = true
        dispatch(toggleAccessTokenIsValid(true));

        if (accessToken) {
          dispatch(saveAccessTokenInState(accessToken));
        }

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        removeAccessTokenCookie();
        // console.log('data fail');
        dispatch(toggleAccessTokenIsValid(false));
      })
      .then((response) => {
        dispatch(accessTokenToggleServerResponded(true));
      });
  };
};

export const validateUserAccessTokenInAuth = () => {
  return checkUserTokenRequest();
};

export const validateUserAccessTokenInDashboard = (successCallback: any) => {
  return checkUserTokenRequest(successCallback);
};

export const toggleAccessTokenIsValid = (isValid: boolean) => {
  return {
    type: actionsNames.SET_ACCESS_TOKEN_IS_VALID as string,
    payload: isValid as boolean,
  };
};
export const saveAccessTokenInState = (accessToken: string | undefined) => {
  return {
    type: actionsNames.SAVE_ACCESS_TOKEN as string,
    payload: accessToken as string | undefined,
  };
};

export const registrationToggleWaitingForServer = (isWaiting: boolean) => {
  return {
    type: actionsNames.REGISTRATION_TOGGLE_WAITING_FOR_SERVER as string,
    payload: isWaiting as boolean,
  };
};

export const registrationToggleInvalidEmail = (isInvalid: boolean) => {
  return {
    type: actionsNames.REGISTRATION_TOGGLE_INVALID_EMAIL as string,
    payload: isInvalid as boolean,
  };
};
export const registrationToggleInvalidPassword = (isInvalid: boolean) => {
  return {
    type: actionsNames.REGISTRATION_TOGGLE_INVALID_PASSWORD as string,
    payload: isInvalid as boolean,
  };
};
export const registrationToggleInvalidPasswordRepeat = (isInvalid: boolean) => {
  return {
    type: actionsNames.REGISTRATION_TOGGLE_INVALID_PASSWORD_REPEAT as string,
    payload: isInvalid as boolean,
  };
};

export const registrationSetEmailMessage = (message: string) => {
  return {
    type: actionsNames.REGISTRATION_SET_EMAIL_MESSAGE as string,
    payload: message as string | undefined,
  };
};
export const registrationSetPasswordMessage = (message: string) => {
  return {
    type: actionsNames.REGISTRATION_SET_PASSWORD_MESSAGE as string,
    payload: message as string | undefined,
  };
};
export const registrationSetPasswordRepeatMessage = (message: string) => {
  return {
    type: actionsNames.REGISTRATION_SET_PASSWORD_REPEAT_MESSAGE as string,
    payload: message as string | undefined,
  };
};

export const loginToggleWaitingForServer = (isWaiting: boolean) => {
  return {
    type: actionsNames.LOGIN_TOGGLE_WAITING_FOR_SERVER as string,
    payload: isWaiting as boolean,
  };
};
export const loginToggleInvalidEmail = (isInvalid: boolean) => {
  return {
    type: actionsNames.LOGIN_TOGGLE_INVALID_EMAIL as string,
    payload: isInvalid as boolean,
  };
};
export const loginToggleInvalidPassword = (isInvalid: boolean) => {
  return {
    type: actionsNames.LOGIN_TOGGLE_INVALID_PASSWORD as string,
    payload: isInvalid as boolean,
  };
};
export const loginSetEmailMessage = (message: string) => {
  return {
    type: actionsNames.LOGIN_SET_EMAIL_MESSAGE as string,
    payload: message as string | undefined,
  };
};
export const loginSetPasswordMessage = (message: string) => {
  return {
    type: actionsNames.LOGIN_SET_PASSWORD_MESSAGE as string,
    payload: message as string | undefined,
  };
};

export const restorePasswordToggleWaitingForServer = (isWaiting: boolean) => {
  return {
    type: actionsNames.RESET_PASS_TOGGLE_WAITING_FOR_SERVER as string,
    payload: isWaiting as boolean,
  };
};
export const restorePasswordToggleInvalidAttempt = (
  isInvalidAttempt: boolean
) => {
  return {
    type: actionsNames.RESET_PASS_TOGGLE_INVALID_ATTEMPT as string,
    payload: isInvalidAttempt as boolean,
  };
};
export const restorePasswordSetServerMessage = (
  message: string | undefined
) => {
  return {
    type: actionsNames.RESET_PASS_SET_MESSAGE as string,
    payload: message as string | undefined,
  };
};

export const setNewPasswordToggleWaitingForServer = (isWaiting: boolean) => {
  return {
    type: actionsNames.SET_NEW_PASS_TOGGLE_WAITING_FOR_SERVER as string,
    payload: isWaiting as boolean,
  };
};
export const setNewPasswordToggleInvalidPassword = (isInvalid: boolean) => {
  return {
    type: actionsNames.SET_NEW_PASS_TOGGLE_INVALID_PASSWORD as string,
    payload: isInvalid as boolean,
  };
};
export const setNewPasswordToggleInvalidPasswordRepeat = (
  isInvalid: boolean
) => {
  return {
    type: actionsNames.SET_NEW_PASS_TOGGLE_INVALID_PASSWORD_REPEAT as string,
    payload: isInvalid as boolean,
  };
};
export const setNewPasswordSetPasswordMessage = (message: string) => {
  return {
    type: actionsNames.SET_NEW_PASS_SET_PASSWORD_MESSAGE as string,
    payload: message as string | undefined,
  };
};
export const setNewPasswordSetPasswordRepeatMessage = (message: string) => {
  return {
    type: actionsNames.SET_NEW_PASS_SET_PASSWORD_REPEAT_MESSAGE as string,
    payload: message as string | undefined,
  };
};

export const emailConfirmationToggleServerResponded = (
  didResponded: boolean
) => {
  return {
    type: actionsNames.EMAIL_CONFIRMATION_TOGGLE_SERVER_RESPONDED as string,
    payload: didResponded as boolean,
  };
};
export const toggleResendEmailConfirmationWaiting = (isWaiting: boolean) => {
  return {
    type: actionsNames.TOGGLE_RESEND_EMAIL_CONFIRMATION_WAITING as string,
    payload: isWaiting as boolean,
  };
};
export const accessTokenToggleServerResponded = (didResponded: boolean) => {
  return {
    type: actionsNames.ACCESS_TOKEN_VALIDATED as string,
    payload: didResponded as boolean,
  };
};
export const toggleNeedsOnboarding = (needs: boolean) => {
  return {
    type: actionsNames.TOGGLE_NEEDS_ONBOARDING as string,
    payload: needs as boolean,
  };
};

export default actionsNames;
