import React from "react";
import { Button } from "antd";
import {
  restorePasswordToggleWaitingForServer,
  restorePasswordSetServerMessage,
  restorePasswordToggleInvalidAttempt,
} from "../../../../store/auth/actions";
import { connect } from "react-redux";
import { sendPasswordResetAsync } from "../../../../helpers/account/sendPasswordResetAsync";
import { openNotification } from "../../../../helpers/openNotification";
import ConditionalTooltip from "../../ConditionalTooltip";
import { notificationDuration } from "../../../../data/constants";
import ResendConfirmationButton from "../../ResendConfirmationButton";

interface Props {
  user: any;
  auth: any;
  restorePasswordToggleWaitingForServer: (payload: boolean) => void;
  restorePasswordSetServerMessage: (payload: string) => void;
  restorePasswordToggleInvalidAttempt: (payload: boolean) => void;
}

const ChangePasswordMenu = (props: Props): JSX.Element => {
  const { user, auth } = props;
  const isEmailVerified = user.data.is_email_verified;

  const handleOnClick = () => {
    props.restorePasswordToggleWaitingForServer(true);
    sendPasswordResetAsync(auth.accessToken, { email: user.data.email })
      .then((response) => {
        let message =
          response.data.detail +
          " " +
          "If you didn't receive a message, double check the entered email and try again.";

        props.restorePasswordSetServerMessage(message);

        openNotification(
          "A restore email sent!",
          <>
            Password reset e-mail has been sent to <b>{user.data.email}</b>
          </>,
          "I understand",
          "info",
          notificationDuration.long
        );
      })
      .catch(() => {
        const errorMessage = "A server error occurred. Please contact us.";
        openNotification(
          "Internal error",
          errorMessage,
          "Close",
          "error",
          notificationDuration.long
        );
        props.restorePasswordToggleInvalidAttempt(true);
        props.restorePasswordSetServerMessage(errorMessage);
      })
      .finally(() => {
        props.restorePasswordToggleWaitingForServer(false);
      });
  };

  return (
    <div className="account_fields__row">
      <div className="account_fields__title">Password</div>
      <div className="account_fields__action">
        <ConditionalTooltip
          condition={!isEmailVerified}
          title={
            <div>
              Verify your email to change the password.{" "}
              <ResendConfirmationButton type="link">
                Resend verification
              </ResendConfirmationButton>
            </div>
          }
        >
          <Button
            onClick={handleOnClick}
            loading={auth.restorePassword.isWaitingForServer}
            disabled={!isEmailVerified}
          >
            Change password
          </Button>
        </ConditionalTooltip>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, auth }) => {
  return {
    user,
    auth,
  };
};
const mapActionsToProps = {
  restorePasswordToggleWaitingForServer,
  restorePasswordSetServerMessage,
  restorePasswordToggleInvalidAttempt,
};
export default connect(mapStateToProps, mapActionsToProps)(ChangePasswordMenu);
