import { Alert, Icon } from "antd";
import React, { useEffect } from "react";
import UpgradeToUnlockButton from "./UpgradeToUnlockButton";
import { UserEvents } from "../../enums/UserEvents";
import { AuthState } from "../../store/auth/types";
import { UserState, SaveUserEventsInStore } from "../../store/user/types";
import { connect } from "react-redux";
import { saveUserEventsInStore } from "../../store/user/actions";
import { setUserEventsAsync } from "../../helpers/user/setUserEventsAsync";

interface Props {
  user: UserState;
  auth: AuthState;
  saveUserEventsInStore: SaveUserEventsInStore;

  title: string;
  description: React.ReactNode;
  buttonText?: string;
  userEventOnMount?: UserEvents;
}

const UpgradeToUnlockMessage = (props: Props): JSX.Element => {
  const {
    title,
    description,
    buttonText,
    userEventOnMount,
    saveUserEventsInStore,
    user,
    auth,
  } = props;

  useEffect(() => {
    if (!userEventOnMount) return;

    setUserEventsAsync(
      {
        [userEventOnMount]: new Date(),
      },
      auth.accessToken,
      user.data.events
    ).then((response) => {
      if (response) {
        saveUserEventsInStore(response.data);
      }
    });
  }, []);

  return (
    <div>
      <Alert
        style={{ marginBottom: 20 }}
        message={title}
        description={description}
        type="info"
        icon={<Icon type="smile" />}
        showIcon
      />
    </div>
  );
};

const mapStateToProps = ({ user, auth }) => {
  return {
    user,
    auth,
  };
};
const mapActionsToProps = {
  saveUserEventsInStore,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(UpgradeToUnlockMessage);
