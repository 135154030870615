import React from "react";
import { CompletionResultData } from "../../../../store/gpt/types";
import { presetsHumanTitles } from "../presets";

interface Props {
  completionResultData: CompletionResultData;
}

const TryAgainTooltipContent = (props: Props): JSX.Element => {
  const { completionResultData } = props;

  let title = completionResultData.prompt;
  let subtitle = "Click to retry";
  if (completionResultData.presetName) {
    title = presetsHumanTitles[completionResultData.presetName];
  }

  return (
    <div className="gpt-assistant__try-again-tooltip">
      <div className="gpt-assistant__try-again-tooltip-title">{title}</div>
      <div className="gpt-assistant__try-again-tooltip-subtitle">
        {subtitle}
      </div>
    </div>
  );
};

export default TryAgainTooltipContent;
