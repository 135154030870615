import React from "react";

function PrevArrow(props:any) {
    const { className, style, onClick } = props;

    return (
        <div className="slider__arrow slider__arrow--prev"
             onClick={onClick}
        >
            <button
                className="circle-button js-prev-arrow circle-button--icon circle-button--light-bg"
                type="button"><span className="icon"><svg width="24" height="17" viewBox="0 0 24 17"
                                                          xmlns="http://www.w3.org/2000/svg"><g
                id="styleguide" fill="none" fillRule="evenodd"><g id="Unicorn-Styleguide"
                                                                  transform="translate(-121 -2006)"
                                                                  fillRule="nonzero"
                                                                  fill="#00396B"
                                                                  className="fill-main"><g
                id="Icons" transform="translate(55 1963)"><g id="arrow-pointing-to-right"
                                                             transform="translate(66 43)"><path
                d="M23.673 7.688L16.531.336a1.094 1.094 0 0 0-1.578 0 1.173 1.173 0 0 0 0 1.625l5.237 5.39H1.116C.5 7.351 0 7.866 0 8.5s.5 1.149 1.116 1.149H20.19l-5.237 5.39a1.173 1.173 0 0 0 0 1.625 1.097 1.097 0 0 0 1.578 0l7.142-7.352a1.173 1.173 0 0 0 0-1.624z"
                id="Shape" transform="matrix(-1 0 0 1 24 0)"/></g></g></g></g></svg></span>
            </button>
        </div>
    );
}
export default PrevArrow;