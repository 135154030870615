import React, { Component } from 'react';
import { Modal } from 'antd';
import { urls } from '../../data/urls';
import SpinnerBox from './SpinnerBox';
import './ChangelogModal.css'

interface Props {
    isModalVisible: boolean;
    handleCancel: () => void;
}

class ChangelogModal extends Component<Props> {

    displayTitle() {
        return (
            <a className="changelog__title website-item__url" href={urls.changelog} target="_blank">
                <span className="changelog__title-text">View the full version</span>
            </a>
        );
    }

    render() {
        const props = this.props;
        return (
            <Modal
                footer={null}
                visible={props.isModalVisible}
                onCancel={props.handleCancel}
                title={this.displayTitle()}
                className="changelog"
                centered
                width={"none"}
                zIndex={100000}
            >
                <div className="changelog__spinner">
                    <SpinnerBox text="Loading changelog..." />
                </div>
                <iframe
                    className="changelog__content"
                    src="https://v2-embednotion.com/Product-Changelog-568b4245b18542dd95a95370c0623ba9"
                />
            </Modal>
        );
    }
}

export default ChangelogModal;
