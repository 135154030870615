import actionsNames from "./actions";
import { PageComponentsState } from "./types";

let initialState: PageComponentsState = {
  items: [],
  dataFetched: false,
};

export const pageComponentsReducer = (
  state: PageComponentsState = initialState,
  action: any
) => {
  switch (action.type) {
    case actionsNames.SAVE_PAGE_COMPONENTS_DATA:
      return {
        ...state,
        items: [
          // ...state.items,
          ...action.payload,
        ],
      };

    case actionsNames.PAGE_COMPONENTS_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };

    default:
      return state;
  }
};
