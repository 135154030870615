import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { DEFAULT_IMAGES_CDN, pressLogos } from "../../data/urls";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { pictogramOptions } from "../../data/pictogramOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import _ from "lodash";
import GraphicsJSX from "../GraphicsJSX";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import WebsiteFeature from "../component_molecules/WebsiteFeature";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Header35 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty, defaultCtaOption } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({
          titleText: "There are No Limits",
          isDefaultEmpty
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpCta({
          activeOption: defaultCtaOption || callToActionOptions.buttons,
          priority: 500,
          isDefaultEmpty,

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "Get it",
              buttonHref: "/sign-up",
              buttonStyleType: buttonStyles.regular.typeTitle,
              buttonColor: buttonStyles.regular.accentBg,
              buttonIsTargetBlank: false,
              pillPrefix: "Whoa!",
            }),

            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "Learn more",
              buttonHref: "/product",
              buttonStyleType: buttonStyles.regular.typeTitle,
              buttonColor: buttonStyles.regular.whiteOutline,
              buttonIsTargetBlank: false,
              pillPrefix: "New",
            }),

            // ghost object
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.ghost.typeTitle,
              buttonColor: buttonStyles.ghost.accentOutline,
              buttonIsTargetBlank: false,
              pillPrefix: "View",
            }),
          ],
        })
      );
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
      this.props.saveContentInStore(
        this.generator.setUpFeatures({
          priority: 200,
          maxItems: 3,
          isDefaultEmpty,

          hasSubtitle: false,
          hasText: true,
          hasPictogram: true,
          hasHighlight: false,
          hasHighlightInfo: false,
          hasButton: true,

          buttonTypes: [
            buttonStyles.ghost.typeTitle,
            buttonStyles.regular.typeTitle,
            buttonStyles.pill.typeTitle,
            buttonStyles.mobileAppStore.typeTitle,
          ],

          items: [
            this.generator.createFeatureContentObject({
              pictogram: this.generator.createPictogramContentObject({
                type: "",
              }),
              title: "Build Instantly",
              text:
                "Cur spatii congregabo. Raptus, talis solems aliquando imperium de alter, albus boreas.",
            }),
            this.generator.createFeatureContentObject({
              pictogram: this.generator.createPictogramContentObject({
                type: "",
              }),
              title: "Fully Responsive",
              text:
                "Ecce. Cum ausus studere, omnes demolitionees acquirere castus, dexter ignigenaes.",
            }),
            this.generator.createFeatureContentObject({
              pictogram: this.generator.createPictogramContentObject({
                type: "",
              }),
              title: "Unlimited Flexibility",
              text:
                "Calceus de germanus cannabis, amor tata! Sunt diatriaes consumere rusticus, altus decores.",
            }),
          ],
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpGraphics({
          activeOption: GraphicsOptions.video,
          userCanAddItems: false,
          maxItems: 1,
          items: [
            this.generator.createGraphicsContentObject({
              imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_DASHBOARD_3_DARK,
              videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
              width: 1280,
              height: 800,
            }),
          ],
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );
    let features = this.props.componentItem.componentData.features.content
      .items;
    let youtubeVideoUrl = this.props.componentItem.componentData.graphics
      .content.items[0][GraphicsOptions.video].youtube.url;

    const currentActiveOption: GraphicsOptions = _.get(
      this.props.componentItem,
      ["componentData", "graphics", "activeOption"]
    );
    const imageUrl: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.image,
      "url",
    ]);
    const codeSnippet: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.HTML,
      "codeSnippet",
    ]);

    return (
      <header
        className={`header-35 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.video}`}
      >
        <div className="header-35__box">
          <div className="container container--mid">
            <div className="header-35__wrapper">
              <div className="header-35__text_box">
                {title.length > 0 && (
                  <h1
                    className={
                      "title-text heading header-35__heading " +
                      (this.props.isDarkBg ? "text-white" : "")
                    }
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )}

                {subtitleText.length > 0 && (
                  <p
                    className={
                      "subtitle-text content_box header-35__text " +
                      (this.props.isDarkBg ? "text-white" : "")
                    }
                    dangerouslySetInnerHTML={{ __html: subtitleText }}
                  />
                )}
                <div className="header-35__buttons">
                  <WebsiteCTA
                    componentItem={this.props.componentItem}
                    isDarkBg={this.props.isDarkBg}
                  />
                </div>
              </div>
              <div className="header-35__video_box">
                <GraphicsJSX
                  currentActiveOption={currentActiveOption}
                  defaultGraphicsOption={GraphicsOptions.video}
                  isDarkBg={this.props.isDarkBg}
                  permanentSpinnerColor
                  imageUrl={imageUrl}
                  videoUrl={youtubeVideoUrl}
                  codeSnippet={codeSnippet}
                  classNames={{
                    image: "header-35__image",
                    video: "header-35__video",
                    HTML: "header-35__HTML",
                  }}
                />
              </div>
            </div>
            <ul className="header-35__features">
              {features &&
                features.map((item: any, key: number) => {
                  if (item.title && item.title !== "") {
                    return (
                      <li
                        className="header-35__feature"
                        key={"feature-header-35-" + key}
                      >
                        <div className="header-35__feature_box">
                          <WebsiteFeature
                            featureObject={item}
                            isWhite={this.props.isDarkBg}
                          />
                        </div>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header35;
