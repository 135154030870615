import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Button, Popover } from "antd";
import "./../GptAssistant.css";
import { api, urls } from "../../../../data/urls";
import returnAuthHeaderForAJAX from "../../../../helpers/auth/returnAuthHeaderForAJAX";
import { AuthState } from "../../../../store/auth/types";
import { connect } from "react-redux";
import { WebsitePagesItem } from "../../../../store/websitePages/types";
import { callOpenAiApi } from "../callOpenAiApi";
import { useDevKeyboardShortcuts } from "../useDevKeyboardShortcuts";
import SendButton from "../SendButton";
import ChatMessageBox from "../ChatMessageBox";
import {
  AddChatMessage,
  AddModifiedComponent,
  AddChatMessageWithDelay,
  DeleteCompletionProgress,
  GetCurrentChatData,
  GptState,
  SetCompletionStatus,
  SetIsLoading,
  SetIsOpenedOnce,
  SetIsTypingWithDelay,
  SetChangeHistoryData,
  GetChangeHistoryItem,
  AddComponentId,
  EditChatMessage,
  HandleOnCompletionFinished,
  HandleOnUnmount,
  HandleOnInterval,
  HandleCleanUpAiData,
  UpdateComponentData,
  HandleOnComponentUpdateFinished,
  Mode,
  SetMode,
  GetCurrentInputData,
  EditInputData,
  SetInputValueAndResize,
  HandleRedo,
  HandleUndo,
  AddPromptHistoryItem,
  AddPromptHistoryItemToStoreAndLocalStorage,
  AddPromptHistoryForAiItem,
} from "../../../../store/gpt/types";
import {
  addChatMessage,
  setIsOpenedOnce,
  setIsLoading,
  setCompletionStatus,
  deleteCompletionProgress,
  addModifiedComponent,
  setChangeHistoryData,
  addComponentId,
  editChatMessage,
  setMode,
  editInputData,
  addPromptHistoryItem,
  addPromptHistoryForAiItem,
} from "../../../../store/gpt/actions";
import _ from "lodash";
import {
  AbortControllerRefItem,
  AbortControllersRef,
  DropdownContainerRef,
  GptInputObject,
  GptPopoverObject,
  HandleOnSubmit,
  QueueItem,
  StopCompletion,
} from "../types";
import GptInput from "../GptInput";
import {
  GPT_ASSISTANT_PROMPT_LENGTH_LIMIT,
  GPT_RENDER_DATA_INTERVAL_MS,
} from "../../../../data/constants";
import { ReactComponent as MaterialWand } from "../../../img/icons/material_wand.svg";
import { WebsitesItem } from "../../../../store/websites/types";
import { getPageArray } from "../getPageArray";
import { useKeyboardShortcuts } from "../useKeyboardShortcuts";
import { addChatMessageWithDelay } from "../../../../store/gpt/thunks/addChatMessageWithDelay";
import { getChangeHistoryItem } from "../../../../store/gpt/thunks/getChangeHistoryItem";
import { getCurrentChatData } from "../../../../store/gpt/thunks/getCurrentChatData";
import { setIsTypingWithDelay } from "../../../../store/gpt/thunks/setIsTypingWithDelay";
import { getMessageHistory } from "../getMessageHistory";
import { handleOnCompletionFinished } from "../../../../store/gpt/thunks/handleOnCompletionFinished";
import { handleOnUnmount } from "../../../../store/gpt/thunks/handleOnUnmount";
import { handleOnInterval } from "../../../../store/gpt/thunks/handleOnInterval";
import { handleCleanUpAiData } from "../../../../store/gpt/thunks/handleCleanUpAiData";
import { saveComponentDataToQueue } from "../saveComponentDataToQueue";
import { updateComponentData } from "../../../../store/gpt/thunks/updateComponentData";
import { handleOnComponentUpdateFinished } from "../../../../store/gpt/thunks/handleOnComponentUpdateFinished";
import { cleanInputValue } from "../cleanInputValue";
import ModeSwitcher from "../ModeSwitcher";
import { PageComponentCategoriesState } from "../../../../store/pageComponentCategories/types";
import { addAndReturnWebsitePageComponent } from "../../../../store/websitePages/thunks";
import { toggleNewWebsitePageEditionsDetected } from "../../../../store/websitePages/actions";
import { getCurrentInputData } from "../../../../store/gpt/thunks/getCurrentInputData";
import { setInputValueAndResize } from "../../../../store/gpt/thunks/setInputValueAndResize";
import classNames from "classnames";
import PresetsDropdown from "../PresetsDropdown";
import ReasoningField from "./ReasoningField";
import LoadingIndicator from "./LoadingIndicator";
import StopGeneratingButton from "./StopGeneratingButton";
import { handleUndo } from "../../../../store/gpt/thunks/handleUndo";
import { handleRedo } from "../../../../store/gpt/thunks/handleRedo";
import { addPromptHistoryItemToStoreAndLocalStorage } from "../../../../store/gpt/thunks/addPromptHistoryItemToStoreAndLocalStorage";

interface Props {
  // Redux start
  auth: AuthState;
  gpt: GptState;
  pageComponentCategories: PageComponentCategoriesState;
  addChatMessage: AddChatMessage;
  setIsOpenedOnce: SetIsOpenedOnce;
  setIsLoading: SetIsLoading;
  setCompletionStatus: SetCompletionStatus;
  deleteCompletionProgress: DeleteCompletionProgress;
  getCurrentChatData: GetCurrentChatData;
  addChatMessageWithDelay: AddChatMessageWithDelay;
  addModifiedComponent: AddModifiedComponent;
  setIsTypingWithDelay: SetIsTypingWithDelay;
  setChangeHistoryData: SetChangeHistoryData;
  addComponentId: AddComponentId;
  editChatMessage: EditChatMessage;
  handleOnCompletionFinished: HandleOnCompletionFinished;
  handleOnUnmount: HandleOnUnmount;
  handleOnInterval: HandleOnInterval;
  handleCleanUpAiData: HandleCleanUpAiData;
  updateComponentData: UpdateComponentData;
  handleOnComponentUpdateFinished: HandleOnComponentUpdateFinished;
  addAndReturnWebsitePageComponent: (payload: any) => void;
  toggleNewWebsitePageEditionsDetected: (payload: boolean) => void;
  setMode: SetMode;
  handleUndo: HandleUndo;
  handleRedo: HandleRedo;
  // Redux end

  // Redux contextual inputs start
  getCurrentInputData: GetCurrentInputData;
  editInputData: EditInputData;
  setInputValueAndResize: SetInputValueAndResize;
  addPromptHistoryItem: AddPromptHistoryItem;
  addPromptHistoryItemToStoreAndLocalStorage: AddPromptHistoryItemToStoreAndLocalStorage;
  addPromptHistoryForAiItem: AddPromptHistoryForAiItem;
  // Redux contextual inputs end

  currentWebsitePage: WebsitePagesItem;
  currentWebsite: WebsitesItem;
  currentPageIndex: number;
  componentIds: string[];
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  isInputDropdownVisible: boolean;
  queueDataRef: React.MutableRefObject<QueueItem[]>;
  intervalRef: React.MutableRefObject<NodeJS.Timeout>;
  dropdownContainerRef: DropdownContainerRef;
  forceStopAllFlagRef: React.MutableRefObject<boolean>;
  abortControllersRef: AbortControllersRef;
}

const GptContextualInput = (props: Props): JSX.Element => {
  const {
    auth,
    gpt,
    currentWebsitePage,
    currentPageIndex,
    setIsOpenedOnce,
    setIsLoading,
    setCompletionStatus,
    deleteCompletionProgress,
    getCurrentChatData,
    addChatMessageWithDelay,
    addModifiedComponent,
    setIsTypingWithDelay,
    setChangeHistoryData,
    addComponentId,
    editChatMessage,
    currentWebsite,
    handleOnCompletionFinished,
    handleOnUnmount,
    handleOnInterval,
    handleCleanUpAiData,
    updateComponentData,
    handleOnComponentUpdateFinished,
    pageComponentCategories,
    addAndReturnWebsitePageComponent,
    toggleNewWebsitePageEditionsDetected,
    setMode,
    componentIds,
    getCurrentInputData,
    editInputData,
    setInputValueAndResize,
    textAreaRef,
    isInputDropdownVisible,
    handleUndo,
    handleRedo,
    queueDataRef,
    intervalRef,
    addPromptHistoryItem,
    addPromptHistoryItemToStoreAndLocalStorage,
    dropdownContainerRef,
    addPromptHistoryForAiItem,
    forceStopAllFlagRef,
    abortControllersRef,
  } = props;

  const pageId = currentWebsitePage.id;
  const inputData = getCurrentInputData(componentIds[0]);
  const config = { ...returnAuthHeaderForAJAX(auth.accessToken) };

  // We push component IDs to the array of force stopped completions. When the stream encounters the corresponding ID, it will be forced to stop.
  const forceStopFlagRef = useRef<string[]>([]);

  useDevKeyboardShortcuts(
    currentWebsitePage.page_components.data.schema,
    currentWebsite
  );

  const setInputValue = (newInputValue: string) => {
    setInputValueAndResize({
      componentId: componentIds[0],
      newValue: newInputValue,
      textAreaRef: textAreaRef,
    });
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleOnSubmit: HandleOnSubmit = async (
    event,
    inputValue,
    presetName,
    temperature
  ) => {
    const cleanedInputValue = cleanInputValue(inputValue);
    const currentMode = "edit"; // remove
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (!cleanedInputValue) return;
    if (inputData.isLoading) return;
    setInputValue("");
    editInputData({
      componentId: componentIds[0],
      newData: {
        isLoading: true,
        reasoning: "",
        completionResultData: {
          prompt: cleanedInputValue,
          presetName,
        },
      },
    });
    if (cleanedInputValue.length > GPT_ASSISTANT_PROMPT_LENGTH_LIMIT) {
      editInputData({
        componentId: componentIds[0],
        newData: {
          isLoading: false,
          reasoning: `Sorry but your prompt is too long: ${cleanedInputValue.length}/${GPT_ASSISTANT_PROMPT_LENGTH_LIMIT}`,
        },
      });
      return;
    }
    if (!presetName) {
      addPromptHistoryItemToStoreAndLocalStorage({
        prompt: cleanedInputValue,
      });
    }
    addPromptHistoryForAiItem({
      prompt: cleanedInputValue,
    });

    let contextArray = componentIds;

    if (contextArray.length === 0) {
      editInputData({
        componentId: componentIds[0],
        newData: {
          isLoading: false,
        },
      });
      return;
    }
    contextArray.forEach((componentId: string) => {
      queueDataRef.current.push({
        componentId,
        completionData: "",
        parsedCompletionData: "",
        isCompletionFinished: false,
        reasoning: "",
        counter: 0,
        slicedCompletionData: "",
      });
    });
    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        handleOnInterval({
          intervalRef,
          forceStopAllFlagRef,
          queueDataRef,
          currentWebsitePage,
          currentWebsite,
          currentPageIndex,
        });
      }, GPT_RENDER_DATA_INTERVAL_MS);
    }

    // We need to empty the array of force stopped completions and abort controllers before the new API call, otherwise the new completions will never start.
    forceStopFlagRef.current = [];
    abortControllersRef.current = abortControllersRef.current.filter((item) => {
      return item.componentId !== componentIds[0];
    });

    contextArray.forEach(async (componentId: string) => {
      const pageArray = getPageArray(currentWebsitePage, currentWebsite);
      const component = pageArray.find((pageComponent) => {
        return pageComponent.id === componentId;
      });
      const finalPrompt = cleanedInputValue;
      addModifiedComponent({
        componentId: component.id,
      });
      editInputData({
        componentId: componentIds[0],
        newData: {
          status: "connecting",
        },
      });
      handleCleanUpAiData({
        componentId,
        currentPageIndex,
        currentWebsitePage,
        currentWebsite,
      });
      const editComponentApiData = {
        userPrompt: finalPrompt,
        component,
        promptHistory: gpt.promptHistoryForAi,
        analyticsData: {
          websiteId: currentWebsite.id,
          pageId,
          componentId,
        },
        pageArray: pageArray,
        mode: currentMode,
        temperature,
        presetName,
      }; // remove unused data, replace message history with new logic
      setChangeHistoryData({
        componentId: component.id,
        before: component.componentData,
        after: {},
        availableAction: "undo",
      });

      const urlCompletion = api.gpt.editComponent;
      const handleOnNewCompletionValue = (newCompletionJson5: string) => {
        saveComponentDataToQueue({
          newCompletionJson5,
          componentId,
          currentWebsitePage,
          currentWebsite,
          queueDataRef,
        });
      };
      const abortController = new AbortController();
      abortControllersRef.current.push({
        componentId,
        abortController,
      });
      await callOpenAiApi(
        handleOnNewCompletionValue,
        handleOnCompletionFinished,
        urlCompletion,
        auth.accessToken,
        editComponentApiData,
        forceStopFlagRef,
        component.id,
        forceStopAllFlagRef,
        pageId,
        queueDataRef,
        abortController,
        stopCompletion,
        editInputData
      );

      editInputData({
        componentId: componentIds[0],
        newData: {
          status: "pending",
        },
      });
    });
  };

  const handleOnPressEnter = (e: any) => {
    // Submit on enter, add new line on shift+enter. Overrides default behavior of a textarea (always new line on enter)
    if (e.key !== "Enter") return;
    if (e.shiftKey) {
      return;
    }
    e.preventDefault();
    handleOnSubmit(e, inputData.inputValue);
  };

  const stopCompletion: StopCompletion = (componentId) => {
    forceStopFlagRef.current.push(componentId);
    updateComponentData({
      currentWebsitePage,
      currentWebsite,
      currentPageIndex,
      componentId,
      queueDataRef,
    });
    handleOnComponentUpdateFinished({
      pageId,
      currentWebsitePage,
      currentWebsite,
      currentPageIndex,
      componentId,
      queueDataRef,
    });
    queueDataRef.current = queueDataRef.current.filter(
      (item) => item.componentId !== componentId
    );
    const abortController = abortControllersRef.current.find(
      (item) => item.componentId === componentId
    );
    if (abortController) {
      abortController.abortController.abort();
    }
  };

  return (
    <PresetsDropdown
      handleOnSubmit={handleOnSubmit}
      componentId={componentIds[0]}
      isInputDropdownVisible={isInputDropdownVisible}
      editInputData={editInputData}
      inputData={inputData}
      setInputValueAndResize={setInputValueAndResize}
      textAreaRef={textAreaRef}
      handleUndo={handleUndo}
      handleRedo={handleRedo}
      currentWebsitePage={currentWebsitePage}
      currentWebsite={currentWebsite}
      currentPageIndex={currentPageIndex}
      gpt={gpt}
      dropdownContainerRef={dropdownContainerRef}
    >
      <div className="gpt-assistant__form-wrapper">
        <form
          className="gpt-assistant__form gpt-assistant__form--contextual-input"
          onSubmit={(e) => handleOnSubmit(e, inputData.inputValue)}
        >
          <GptInput
            className={classNames(
              "ant-input",
              "gpt-assistant__input",
              "gpt-assistant__input--contextual-input",
              {
                "gpt-assistant__input--contextual-input--loading":
                  inputData.isLoading,
                "gpt-assistant__input--contextual-input--has-reasoning":
                  inputData.reasoning,
              }
            )}
            handleOnChange={handleOnChange}
            textAreaRef={textAreaRef}
            handleOnPressEnter={handleOnPressEnter}
            mode={"edit"}
            inputData={inputData}
          />
          <SendButton
            isLoading={inputData.isLoading}
            inputValue={inputData.inputValue}
            className={classNames(
              "gpt-assistant__input-button",
              "gpt-assistant__send-button",
              "gpt-assistant__send-button--contextual-input",
              {
                "gpt-assistant__input-button--hidden":
                  !inputData.inputValue.trim() || inputData.isLoading,
              }
            )}
          />
          <StopGeneratingButton
            isLoading={inputData.isLoading}
            stopCompletion={stopCompletion}
            componentId={componentIds[0]}
          />
        </form>
        <ReasoningField reasoning={inputData.reasoning} />
        <LoadingIndicator isLoading={inputData.isLoading} />
      </div>
    </PresetsDropdown>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    gpt: state.gpt,
    pageComponentCategories: state.pageComponentCategories,
  };
};
const mapActionsToProps = {
  addChatMessage,
  setIsOpenedOnce,
  setIsLoading,
  setCompletionStatus,
  deleteCompletionProgress,
  getCurrentChatData,
  addChatMessageWithDelay,
  addModifiedComponent,
  setIsTypingWithDelay,
  setChangeHistoryData,
  getChangeHistoryItem,
  addComponentId,
  editChatMessage,
  handleOnCompletionFinished,
  handleOnUnmount,
  handleOnInterval,
  handleCleanUpAiData,
  updateComponentData,
  handleOnComponentUpdateFinished,
  addAndReturnWebsitePageComponent,
  toggleNewWebsitePageEditionsDetected,
  setMode,
  getCurrentInputData,
  editInputData,
  setInputValueAndResize,
  handleUndo,
  handleRedo,
  addPromptHistoryItem,
  addPromptHistoryItemToStoreAndLocalStorage,
  addPromptHistoryForAiItem,
};
export default connect(mapStateToProps, mapActionsToProps)(GptContextualInput);
