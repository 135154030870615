import {projectUrl} from "../../data/constants";

export const getFullWebsiteUrl = (currentWebsite:any) => {
    let currentWebsiteProtocol = '';
    let currentWebsiteUrl = '';
    if(currentWebsite.is_custom_domain_active){
        let isSSLissued = currentWebsite.is_ssl_issued; //SSL certs can be issued either by us (Let's Encrypt nginx certbot on the AWS EC2 instance)
        let isCloudflareProtectionEnabled = currentWebsite.is_cloudflare_protected; // protection is provided  by the user via proxying over Cloudflare;
        if(isSSLissued || isCloudflareProtectionEnabled){
            currentWebsiteProtocol = 'https://';
        }else{
            currentWebsiteProtocol = 'http://';
        }
        currentWebsiteUrl = currentWebsiteProtocol + currentWebsite.custom_domain;
    }else{
        currentWebsiteProtocol = 'https://';
        currentWebsiteUrl = currentWebsiteProtocol + currentWebsite.subdomain + '.' + projectUrl;
    }

    return currentWebsiteUrl;
};