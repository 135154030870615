import { setIsTyping } from "../actions";
import { SetIsTypingPayload } from "../types";

export const setIsTypingWithDelay = (
  isTypingPayload: SetIsTypingPayload,
  delay: number = 0
) => {
  return (dispatch) => {
    const { pageId, isTyping } = isTypingPayload;
    const onTimeout = () => {
      dispatch(
        setIsTyping({
          pageId,
          isTyping,
        })
      );
    };
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        onTimeout();
        resolve();
      }, delay);
    });
  };
};
