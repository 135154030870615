import _ from "lodash";
import { buttonStyles } from "../../../../data/buttonStyles";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { DEFAULT_IMAGES_CDN } from "../../../../data/urls";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const itemText = stringifyObjectsAndNumbers(modifiedItem.TEXT) || "";
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.AUTHOR_NAME) || "";
  const itemSubtitle =
    stringifyObjectsAndNumbers(modifiedItem.AUTHOR_POSITION) || "";
  const itemButtonText =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TEXT"])) || "";
  const itemButtonUrl =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "URL"])) || "";
  const itemButtonTarget =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TARGET"])) ||
    "_self";
  return {
    itemText,
    itemTitle,
    itemSubtitle,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
  };
};

const particleUpdateButton = (
  originalItem,
  modifiedItem,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if (modifiedItem.BUTTON === null) {
    originalItem.button.title = "";
    originalItem.button.href = "";
    originalItem.button.isTargetBlank = false;
  }
  if (!isKeyValue(modifiedItem.BUTTON)) {
    return;
  }
  if ("TEXT" in modifiedItem.BUTTON) {
    originalItem.button.title = itemButtonText;
  }
  if ("URL" in modifiedItem.BUTTON) {
    originalItem.button.href = itemButtonUrl;
  }
  if ("TARGET" in modifiedItem.BUTTON) {
    originalItem.button.isTargetBlank = itemButtonTarget === "_blank";
  }
};

const particleModifyOriginalItem = (
  originalItem,
  modifiedItem,
  itemText,
  itemTitle,
  itemSubtitle,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if ("TEXT" in modifiedItem) {
    originalItem.text = itemText;
  }
  if ("BUTTON" in modifiedItem) {
    particleUpdateButton(
      originalItem,
      modifiedItem,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget
    );
  }
  if ("AUTHOR_NAME" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("AUTHOR_POSITION" in modifiedItem) {
    originalItem.subtitle = itemSubtitle;
  }
};

export const updateSingleTestimonial = (componentData, completionContent) => {
  const content = componentData.posts.content;
  if (!("TESTIMONIAL" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.TESTIMONIAL)) {
    if (completionContent.TESTIMONIAL === null) {
      content.items = [];
    }
    return;
  }
  if (!content.items[0]) {
    const newItem = generator.createPostsContentObject();
    newItem.title = "";
    newItem.subtitle = "";
    newItem.text = "";
    newItem.button.title = "";
    newItem.button.href = "";
    newItem.button.settings.appearance.ghostColor =
      buttonStyles.ghost.whiteOutline;
    newItem.thumb.image.url = DEFAULT_IMAGES_CDN.UNICORN_AI;
    newItem.thumb.image.width = 512;
    newItem.thumb.image.height = 512;
    componentData.posts.content.items = [newItem];
  }
  const originalItem = content.items[0];
  const modifiedItem = completionContent.TESTIMONIAL;
  originalItem.IS_BEING_MODIFIED_BY_AI = true;
  const {
    itemText,
    itemTitle,
    itemSubtitle,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
  } = particleGetModifiedValues(modifiedItem);
  particleModifyOriginalItem(
    originalItem,
    modifiedItem,
    itemText,
    itemTitle,
    itemSubtitle,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget
  );
};
