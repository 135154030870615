import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    currentWebsite: any,
    connectDefaultFormIntegration: any,
    isDarkBg: boolean,
}

class CtaForm03 extends Component<Props>{
    componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject className;
            let generator = new generateContentDataObject();
            this.props.saveContentInStore(generator.setUpTitle({isDefaultEmpty,'titleText': 'Ready to Ride a Unicorn?'}));
            this.props.saveContentInStore(generator.setUpSubtitleText({isDefaultEmpty,"subtitleText": "Sign Up to try Unicorn Platform. <br>No credit card required."}));
            this.props.saveContentInStore(generator.setUpCta({
                activeOption: callToActionOptions.form,
                isDefaultEmpty
            }));

            this.props.connectDefaultFormIntegration(this.props.componentItem);
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        return (
            <section>
                <div className="cta_form-03">
                    <div className="container container--mid">
                        <div className="sh-4 cta_form-03__wrapper card-container">
                            <div className="cta_form-03__heading_box">
                                {title.length > 0 && <h2 className="title-text cta_form-03__heading" dangerouslySetInnerHTML={{__html: title}}/>}
                                {subtitleText.length > 0 && <div className="subtitle-text cta_form-03__text content_box" dangerouslySetInnerHTML={{__html: subtitleText}}/>}
                            </div>
                            <div className="cta_form-03__form_box">
                                <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={false} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CtaForm03;
