import { buttonStyles } from "../../../../data/buttonStyles";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemText = stringifyObjectsAndNumbers(modifiedItem.TEXT) || "";
  const itemUrl = stringifyObjectsAndNumbers(modifiedItem.URL) || "";
  const itemTarget = stringifyObjectsAndNumbers(modifiedItem.TARGET) || "_self";
  return {
    itemId,
    itemPosition,
    itemText,
    itemUrl,
    itemTarget,
  };
};
const particleModifyOriginalItem = (
  content,
  originalItem,
  modifiedItem,
  itemPosition,
  itemText,
  itemUrl,
  itemTarget
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    content.buttons.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(content.buttons, item);
      }
    });
  }
  if ("TEXT" in modifiedItem) {
    originalItem.title = itemText;
  }
  if ("URL" in modifiedItem) {
    originalItem.href = itemUrl;
  }
  if ("TARGET" in modifiedItem) {
    originalItem.isTargetBlank = itemTarget === "_blank";
  }
};

export const updateGalleryButtons = (componentData, completionContent) => {
  const content = componentData.contentCta;
  if (!("BUTTONS" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.BUTTONS)) {
    if (completionContent.BUTTONS === null) {
      content.buttons = [];
    }
    return;
  }
  content.buttons = deleteEmptyItems(content.buttons, "title");
  addDefaultValuesToItems(content.buttons);
  completionContent.BUTTONS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemText,
      itemUrl,
      itemTarget,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      content.buttons = deleteItem(content.buttons, itemId);
      return;
    }
    const isItemNew = getIsItemNew(content.buttons, itemId);
    if (isItemNew) {
      const newItem = generator.createButtonContentObject();
      newItem.title = "";
      newItem.href = "";
      newItem.settings.appearance.styleType = buttonStyles.regular.typeTitle;
      newItem.settings.appearance.color = buttonStyles.regular.accentOutline;
      addNewItem(content.buttons, newItem, itemId, itemPosition);
    }
    const originalItem = getOriginalItem(content.buttons, itemId);
    particleModifyOriginalItem(
      content,
      originalItem,
      modifiedItem,
      itemPosition,
      itemText,
      itemUrl,
      itemTarget
    );
  });
  sortItemsByPosition(content.buttons);
};
