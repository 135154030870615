import React from "react";

function NextArrow(props:any) {
    const { className, style, onClick } = props;

    return (
        <div className="slider__arrow slider__arrow--next"
             onClick={onClick}
        >
            <button
                className="circle-button js-next-arrow circle-button--icon circle-button--light-bg"
                type="button"><span className="icon"><svg viewBox="0 0 13 10"
                                                          xmlns="http://www.w3.org/2000/svg"><path
                d="M12.823 4.164L8.954.182a.592.592 0 0 0-.854 0 .635.635 0 0 0 0 .88l2.836 2.92H.604A.614.614 0 0 0 0 4.604c0 .344.27.622.604.622h10.332L8.1 8.146a.635.635 0 0 0 0 .88.594.594 0 0 0 .854 0l3.869-3.982a.635.635 0 0 0 0-.88z"
                fillRule="nonzero" fill="#00396B" className="fill-main"/></svg></span>
            </button>
        </div>
    );
}
export default NextArrow;