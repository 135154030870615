export const unescapeCommas = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (typeof obj[key] !== "string") {
      acc[key] = obj[key];
      return acc;
    }
    acc[key] = obj[key].replace(/&comma;/g, ",");
    return acc;
  }, {});
}
