import _ from "lodash";
import { updateCta } from "./updateCta";
import { updateFeatures } from "./updateFeatures";
import { updatePricingCards } from "./updatePricingCards";
import { updateSubtitle } from "./updateSubtitle";
import { updateTitle } from "./updateTitle";
import { UnicornComponent } from "../../../../classes/UnicornComponent";
import { Components } from "../../../../enums/Components";
import { updatePricingSingle } from "./updatePricingSingle";
import { updatePricingTable } from "./updatePricingTable";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { updatePricingTabs } from "./updatePricingTabs";
import { updateList } from "./updateList";
import { updateForm4BottomInfo } from "./updateForm4BottomInfo";
import { updateTeam } from "./updateTeam";
import { updatePosts } from "./updatePosts";
import { updateFaq } from "./updateFaq";
import { updateGallery } from "./updateGallery";
import { updateLogos } from "./updateLogos";
import { updateSocialLinks } from "./updateSocialLinks";
import { updateContacts } from "./updateContacts";
import { updateSingleTestimonial } from "./updateSingleTestimonial";
import { updateSteps } from "./updateSteps";
import { updateText } from "./updateText";
import { updateCode } from "./updateCode";
import { updateNavLinks } from "./updateNavLinks";
import { updateNavLogo } from "./updateNavLogo";
import { updateSingleLogo } from "./updateSingleLogo";

const generator = new generateContentDataObject();

export const updateComponent = (component, completion) => {
  const completionContent = completion;
  const componentCopy = _.cloneDeep(component);
  let { componentData } = componentCopy;
  const currentComponentInfo = new UnicornComponent(component);
  Object.keys(componentData).forEach((contentType) => {
    if (contentType === "title") {
      updateTitle(componentData, completionContent);
    }
    if (contentType === "subtitleText") {
      updateSubtitle(componentData, completionContent);
    }
    if (contentType === "cta") {
      updateCta(componentData, completionContent);
    }
    if (contentType === "features") {
      updateFeatures(componentData, completionContent, currentComponentInfo);
    }
    if (contentType === "pricing") {
      if (currentComponentInfo.name === Components.pricing03) {
        updatePricingSingle(componentData, completionContent);
      } else if (currentComponentInfo.name === Components.pricing05) {
        updatePricingTabs(componentData, completionContent);
        updatePricingTable(componentData, completionContent);
      } else {
        updatePricingTabs(componentData, completionContent);
        updatePricingCards(componentData, completionContent);
      }
      componentData.pricing.content.items.forEach((pricingItem, index) => {
        componentData = generator.addEmptyPricingDetailIfNeeded(
          componentData,
          index
        );
      });
    }
    if (contentType === "list") {
      updateList(componentData, completionContent);
      componentData.list.content.groups.forEach((group, index) => {
        componentData = generator.addEmptyListItemIfNeeded(
          componentData,
          index
        );
      });
    }
    if (contentType === "posts") {
      if (currentComponentInfo.name === Components.ctaForm04) {
        updateForm4BottomInfo(componentData, completionContent);
      } else if (currentComponentInfo.name === Components.testimonials05) {
        updateSingleTestimonial(componentData, completionContent);
      } else {
        updatePosts(componentData, completionContent, currentComponentInfo);
      }
    }
    if (contentType === "team") {
      updateTeam(componentData, completionContent, currentComponentInfo);
      componentData.team.content.items.forEach((teamMember, index) => {
        componentData = generator.addEmptyTeamMemberSocialNetworkIfNeeded(
          componentData,
          index
        );
      });
    }
    if (contentType === "faq") {
      updateFaq(componentData, completionContent);
      componentData = generator.addEmptyFaqIfNeeded(componentData);
    }
    if (contentType === "gallery") {
      updateGallery(componentData, completionContent, currentComponentInfo);
      componentData.gallery.content.items.forEach((galleryItem, index) => {
        if (galleryItem.contentCta.buttons.length === 0) {
          const newButton = generator.createButtonContentObject();
          newButton.title = "";
          newButton.href = "";
          newButton.isTargetBlank = false;
          galleryItem.contentCta.buttons = [newButton];
        }
        componentData = generator.addEmptyGalleryButtonIfNeeded(
          componentData,
          index
        );
      });
    }
    if (contentType === "logos") {
      if (currentComponentInfo.name === Components.footer) {
        updateSingleLogo(componentData, completionContent);
      } else {
        updateLogos(componentData, completionContent);
      }
    }
    if (contentType === "socialLinks") {
      updateSocialLinks(componentData, completionContent);
      componentData = generator.addEmptySocialLinkIfNeeded(componentData);
    }
    if (contentType === "contacts") {
      updateContacts(componentData, completionContent, currentComponentInfo);
      componentData = generator.addEmptyContactIfNeeded(componentData);
    }
    if (contentType === "steps") {
      updateSteps(componentData, completionContent, currentComponentInfo);
    }
    if (contentType === "text") {
      updateText(componentData, completionContent, currentComponentInfo);
    }
    if (contentType === "code") {
      updateCode(componentData, completionContent);
    }
    if (contentType === "navLinks") {
      updateNavLinks(componentData, completionContent);
    }
    if (contentType === "navLogo") {
      updateNavLogo(componentData, completionContent);
    }
  });
  return componentData;
};
