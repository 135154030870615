import { BlogPostsState } from "./../../store/blogPosts/types";
import { BlogsState } from "../../store/blogs/types";
import { WebsitePagesState } from "../../store/websitePages/types";
import { WebsitesItem } from "../../store/websites/types";

interface Payload {
  currentWebsite: WebsitesItem;
  websitesPages: WebsitePagesState;
  blogs: BlogsState;
  blogPosts: BlogPostsState;
}
export const redirectToPagesOrBlog = ({
  currentWebsite,
  websitesPages,
  blogs,
  blogPosts,
}: Payload) => {
  if (!currentWebsite) return null;
  
  const { subdomain } = currentWebsite;

  if (
    [...websitesPages.items].some((page) => {
      return page.website === currentWebsite.id;
    })
  ) {
    return `/${subdomain}/pages`;
  }

  const currentBlog = blogs.items.find((blog) => {
    return blog.website === currentWebsite.id;
  });

  if (!currentBlog) {
    return `/${subdomain}/pages`;
  }

  if (
    [...blogPosts.items].some((blogPost) => {
      return blogPost.blog === currentBlog.id;
    })
  ) {
    return `/${subdomain}/blog`;
  }

  return `/${subdomain}/pages`;
};
