import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type DeleteAdminAccessAsync = (payload: {
  accessToken: string;
  itemId: string | number;
}) => Promise<AxiosResponse<any>>;

export const deleteAdminAccessAsync: DeleteAdminAccessAsync = ({
  accessToken,
  itemId,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = api.sharedAccess.deleteAdmin + "/" + itemId;
    axios
      .delete(apiUrl, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
