import React, {Component} from "react";
import {Switch} from "antd";
import {indexWebsitePageDashboardUrl} from "../../data/constants";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {getFullPageUrl} from "../../helpers/editor/getFullPageUrl";
import {checkIfPageHasComponents} from "../../helpers/editor/checkIfPageHasComponents";
import {getCurrentWebsitePage} from "../../helpers/getCurrentWebsitePage";
import trimStringTo from "../../helpers/strings/trimStringTo";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {openNotification} from "../../helpers/openNotification";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import _ from "lodash";
import { checkIfGoogleSheetUrl } from "../../helpers/checkIfGoogleSheetUrl";

interface Props {
    websites: any,
    websitesPages: any,
    auth: any,

    currentWebsite: any,
    currentWebsitePage: any,

    saveWebsitePageDataInStore: any,
}
interface MyState {
    excludePageToggleWaitingForServer: boolean,
}
class ExcludePageFromSitemapSwitch extends Component<Props, MyState>{

    constructor(props) {
        super(props);
        this.state = {
            excludePageToggleWaitingForServer: false,
        }
    }

    handleChange = (checked:boolean):void => {
        this.setState({
            ...this.state,
            excludePageToggleWaitingForServer: true
        });
        let accessToken = this.props.auth.accessToken;

        // Our server API expects the 'indexWebsitePageDashboardUrl' as an URL kwarg because a URL of a home page ('') is in appropriate for API and the dashboard.
        let websitePageUrlToServer = this.props.currentWebsitePage.url;
        if(this.props.currentWebsitePage.url === ''){
            websitePageUrlToServer = indexWebsitePageDashboardUrl;
        }

        let apiUrl = api.websitePages.updateWebsitePage.prefix + getCurrentWebsiteSubdomain(this.props.websites.items) + '/' + websitePageUrlToServer + api.websitePages.updateWebsitePage.postfix;
        axios.patch(apiUrl, {'is_excluded_from_sitemap': checked, subdomain: this.props.currentWebsite.subdomain}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(response => {

                // We got a response from the server with an updated website page data - save it in the redux store.
                const dataToStore = {
                  data: {
                    ...this.props.currentWebsitePage,
                    is_excluded_from_sitemap: _.get(response, "data.is_excluded_from_sitemap")
                  }
                }
                this.props.saveWebsitePageDataInStore(dataToStore, this.props.currentWebsite, this.props.websitesPages.items, this.props.currentWebsitePage.url);

                let newPublicationStateMessage:any;
                if(checked){
                    newPublicationStateMessage = (<span>The page has been excluded from sitemap.xml</span>);
                }else {
                    newPublicationStateMessage = (<span>The page has been successfully added to sitemap.xml.</span>);
                }

                if(checked){
                  fireAnalyticsEvent.fireCrisp(CrispEvents.excludeWebsitePageFromSitemap, {
                    subdomain: this.props.currentWebsite.subdomain,
                    page_url: response.data.url,
                  });
                }else{
                  fireAnalyticsEvent.fireCrisp(CrispEvents.addWebsitePageToSitemap, {
                    subdomain: this.props.currentWebsite.subdomain,
                    page_url: response.data.url,
                  });
                }
            })
            .catch(error => {
                // handle error
                if(error.response){
                    let errorData = error.response.data;

                    let errorObjectKey = getErrorKey(errorData);
                    let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'Close', 'warn');
                    }

                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        // This happens when user tries to change a website page which doesn't exist or doesn't belong to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }
                }
            })
            .then(response => {
                // always executed
                this.setState({
                    ...this.state,
                    excludePageToggleWaitingForServer: false
                });
            });
    };

    render(){
        let isPageExcluded = this.props.currentWebsitePage.is_excluded_from_sitemap;

        return (
            <div>
                <Switch loading={this.state.excludePageToggleWaitingForServer} checked={isPageExcluded} onChange={this.handleChange} />
            </div>
        )
    }
}

export default ExcludePageFromSitemapSwitch;
