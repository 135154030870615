import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteSocialNetworkLinks from "../component_molecules/WebsiteSocialNetworkLinks";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Team02 extends Component<Props> {
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if (this.props.checkIsEmptyContent()) {
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "We Never Stop Smiling!"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: 'Hi and nice to meet you! This is our brave team. We work very hard to provide an awesome experience for you.'}));
            this.props.saveContentInStore(this.generator.setUpTeamMembers(
                {
                  isDefaultEmpty,
                    hasPhoto: true,
                    hasName: true,
                    hasJobTitle: true,
                    hasUsername: false,
                    hasSocialNetworks: true,
                    hasHref: false,
                    hasInfo: true,
                }
            ));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the team component to tell more about your company.",
                    default: "Add a button to the bottom of the team component to tell more about your company.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render() {
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let team = this.props.componentItem.componentData.team.content.items;

        return (
            <section>
                <div className="team-02">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <ul className="team-02__list">
                        {team && team.map((teamMember: any, teamMemberKey: number) => {
                            let teamItemJSX = (
                                <li className="sh-2 team-02__person card-container" key={'team-02-' + teamMemberKey}>
                                    <div className="team-02__person_box">
                                        {teamMember && teamMember.graphics && teamMember.graphics.image && teamMember.graphics.image.url !== '' && <div className="team-02__person_img_box"><img src={formatImageUrl(teamMember.graphics.image.url)} className="team-02__person_img"/></div>}
                                        {teamMember.name !== '' && <div className="def-12 title-text--inner team-02__person_name">{teamMember.name}</div>}
                                        {teamMember.jobTitle !=='' && <div className="team-02__person_tag"><span className="content-text def-10 tag color-main bg-light"><span
                                            className="tag__text">{teamMember.jobTitle}</span></span>
                                        </div>}
                                        {teamMember.info !== '' && <div className="content-text def-14 team-02__person_about content_box " dangerouslySetInnerHTML={{__html: teamMember.info}}/>}

                                        {/*If only one and it is empty - it is a ghost element, do not display the block*/}
                                        {((teamMember.socialNetworks.length === 1 && teamMember.socialNetworks[0].href === '') === false) && <div className="team-02__person_social">
                                            <WebsiteSocialNetworkLinks links={teamMember.socialNetworks} colorType='black' direction='center' />
                                        </div>}
                                    </div>
                                </li>);

                            if (teamMember !== undefined) {
                                return (teamItemJSX)
                            } else {
                                return (null);
                            }
                        })}
                    </ul>
                    <div className="bottom_cta">
                        {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                    </div>
                </div>
            </section>
        )
    }
}

export default Team02;
