function getSocialNetworkFromUrl(url: any): string {
  let socialNetwork = url.toLowerCase();

  if (socialNetwork.indexOf("mailto:") === 0) {
    return "email";
  }

  socialNetwork = socialNetwork.replace("https://", "");
  socialNetwork = socialNetwork.replace("http://", "");
  socialNetwork = socialNetwork.replace("www.", "");
  socialNetwork = socialNetwork.split("/")[0];

  socialNetwork = socialNetwork.split(".");

  const subdomain = socialNetwork.slice(-3, -2)[0];
  socialNetwork = socialNetwork.slice(-2, -1)[0];

  if (subdomain === "music" && socialNetwork === "apple") {
    // We have a special case for Apple Music, because it uses a subdomain
    return "apple-music";
  }

  return socialNetwork;
}

export default getSocialNetworkFromUrl;
