import React from "react";
import { Button, Icon } from "antd";
import { CrispEvents } from "../../../../enums/AnalyticsEventsEnums";
import fireAnalyticsEvent from "../../../../helpers/editor/fireAnalyticsEvent";
import generateRandomNumerousId from "../../../../helpers/generateRandomNumerousId";
import { getCurrentWebsitePageUrlForExport } from "../../../../helpers/html_export/getCurrentWebsitePageUrlForExport";
import PageTitle from "./PageTitle";

interface Props {
  currentWebsitePages: any;
  currentWebsite: any;
  websitesPages: any;
}
// display pages
const WebsitePagesDownloadButtons = (props: Props): JSX.Element => {
  const { currentWebsitePages, currentWebsite, websitesPages } = props;

  return currentWebsitePages.map((item: any, key: number) => {
    let currentWebsitePageUrl = getCurrentWebsitePageUrlForExport(
      currentWebsite,
      item.url
    );

    return (
      <li key={key} className="html-export__button_item">
        <Button
          onClick={() => {
            fireAnalyticsEvent.fireCrisp(CrispEvents.exportWebsitePage, {
              subdomain: currentWebsite.subdomain,
              page_url: item.url,
            });
          }}
          type={"primary"}
          icon={"download"}
          size="small"
          // target={"_blank"}
          href={
            currentWebsitePageUrl +
            "?nocache=" +
            generateRandomNumerousId() +
            "&as_file=true&file_name=" +
            (item.url === "" ? "index" : item.url)
          }
          title={"Download the .HTML file."}
          loading={false}
        />
        <div className="html-export__page_title">
          <div className="html-export__page_title_text">
            {" "}
            {<PageTitle item={item} />}
            <a
              hidden={websitesPages.pageChangeInProgress}
              title="View the page in a new tab."
              href={currentWebsitePageUrl}
              target="_blank"
              className="html-export__link"
            >
              <Icon type="link" />
            </a>
          </div>
        </div>
      </li>
    );
  });
};

export default WebsitePagesDownloadButtons;
