export const finishJson5 = (unfinishedJson5: string) => {
  let stack = [];
  let inDoubleQuotes = false;
  let inSingleQuotes = false;
  let inSingleLineComment = false;

  for (let i = 0; i < unfinishedJson5.length; i++) {
    const currentChar = unfinishedJson5[i];
    const nextChar = unfinishedJson5[i + 1];
    let prevChar;
    if (i > 0) {
      prevChar = unfinishedJson5[i - 1];
    }
    if (
      currentChar === "/" &&
      nextChar === "/" &&
      !inDoubleQuotes &&
      !inSingleQuotes
    ) {
      inSingleLineComment = true;
    }
    if (
      currentChar === '"' &&
      !inSingleQuotes &&
      !inSingleLineComment &&
      prevChar !== "\\"
    ) {
      inDoubleQuotes = !inDoubleQuotes;
    }
    if (
      currentChar === "'" &&
      !inDoubleQuotes &&
      !inSingleLineComment &&
      prevChar !== "\\"
    ) {
      inSingleQuotes = !inSingleQuotes;
    }
    if (currentChar === "\n" && inSingleLineComment) {
      inSingleLineComment = false;
    }
    if (
      currentChar === "{" &&
      !inDoubleQuotes &&
      !inSingleQuotes &&
      !inSingleLineComment
    ) {
      stack.unshift("}");
    }
    if (
      currentChar === "[" &&
      !inDoubleQuotes &&
      !inSingleQuotes &&
      !inSingleLineComment
    ) {
      stack.unshift("]");
    }
    if (
      currentChar === "}" &&
      !inDoubleQuotes &&
      !inSingleQuotes &&
      !inSingleLineComment
    ) {
      stack.shift();
    }
    if (
      currentChar === "]" &&
      !inDoubleQuotes &&
      !inSingleQuotes &&
      !inSingleLineComment
    ) {
      stack.shift();
    }
  }

  if (inDoubleQuotes) {
    stack.unshift('"');
  }
  if (inSingleQuotes) {
    stack.unshift("'");
  }
  if (inSingleLineComment) {
    return unfinishedJson5;
  }

  return unfinishedJson5 + stack.join("");
};
