import { BlogPostsState } from "../../store/blogPosts/types";
import { BlogsState } from "../../store/blogs/types";
import { WebsitesItem } from "../../store/websites/types";
import { sortByDateFn } from "../sortByDateFn";

interface Payload {
  currentWebsite: WebsitesItem;
  blogs: BlogsState;
  blogPosts: BlogPostsState;
}
export const redirectBlog = ({ currentWebsite, blogs, blogPosts }: Payload) => {
  if (!currentWebsite) return null;

  const currentBlog = blogs.items.find((blog) => {
    return blog.website === currentWebsite.id;
  });

  if (!currentBlog) {
    return null;
  }

  const currentBlogPosts = [...blogPosts.items]
    .sort(sortByDateFn)
    .filter((blogPost) => {
      return blogPost.blog === currentBlog.id;
    });

  const firstBlogPost = currentBlogPosts[0];

  if (firstBlogPost) {
    return `/${currentWebsite.subdomain}/blog/${firstBlogPost.id}`;
  }

  return null;
};
