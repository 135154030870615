import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Links02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Strong Base for Sustainable Growth"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpList({
              isDefaultEmpty,
                fieldTitle: {default: 'Features lists'},
                label: {default: 'Edit the features lists. Those can be links.'},
                maxItems: 30,
                hasGroups: true,
                maxGroups: 10,
                groupTitleHasTitle: true,
                groupTitleHasPictogram: false,
                groupTitleHasSubtitle: false,
                groups: [
                    this.generator.createGroupContentObject({
                        title: 'Nifty posts'
                    }),
                    this.generator.createGroupContentObject({
                        title: 'Unicorn Platform Live'
                    })
                ],
                items: [
                    this.generator.createListItemContentObject({
                        title: 'Subscribe Our YouTube Channel',
                        href: '#',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: 'A Complete Guide to Unicorn Platform',
                        href: '#',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: '10 Reasons Why Static Landing Page is Better That Anything',
                        href: '#',
                        groupIndex: 0
                    }),

                    this.generator.createListItemContentObject({
                        title: 'I am a Solofounder and I like it',
                        href: '#',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: 'HTML and CSS Basics',
                        href: '#',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Butter and Bread of Any Web Developer',
                        href: '#',
                        groupIndex: 0
                    }),
                    // The last ghost item which is used to add new items.
                    this.generator.createListItemContentObject({
                        title: '',
                        href: '#',
                        groupIndex: 0
                    }),

                    this.generator.createListItemContentObject({
                        title: 'Learn About Us',
                        href: '#',
                        groupIndex: 1
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Technical Support',
                        href: '#',
                        groupIndex: 1
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Customer Care',
                        href: '#',
                        groupIndex: 1
                    }),

                    // The last ghost item which is used to add new items.
                    this.generator.createListItemContentObject({
                        title: '',
                        href: '#',
                        groupIndex: 1
                    }),

                ],
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the component to offer more to read about your project.",
                    default: "Add a CTA (call to action) to the bottom of the component to offer more to read about your project.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'More'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let listItems = this.props.componentItem.componentData.list.content.items;
        let listGroups = this.props.componentItem.componentData.list.content.groups;

        let ctaFirstButton = this.props.componentItem.componentData.cta.content.buttons[0];
        let ctaFirstButtonTitle = ctaFirstButton && ctaFirstButton.title;

        return (
            <section>
                <div className="links-02">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container container--small">

                        {listGroups && listGroups.map((group:any, groupKey:number) => {
                            if(group.title && group.title !== ''){
                                return (
                                    <div className="links-02__list_box" key={'links-group-02-' + groupKey}>
                                        <h3 className={"title-text--inner links-02__list_title def-16" + (this.props.isDarkBg ? 'text-white' : '')}>{group.title}</h3>
                                        <ul className="links-02__list">
                                            {listItems && listItems.map((listItem:any, listItemKey:number) => {
                                                if(listItem.title && listItem.title !== ''
                                                    &&
                                                    //Show only items of this group
                                                    listItem.groupIndex === groupKey){

                                                    return (
                                                        <li className="links-02__item" key={'links-item-02-' + listItemKey}>
                                                            <a
                                                                href={returnHref(listItem.href)}
                                                                className={"content-text links-02__link  " + (this.props.isDarkBg ? 'text-white' : 'link ')}
                                                                dangerouslySetInnerHTML={{__html: listItem.title}}/>

                                                        </li>
                                                    )
                                                }else{
                                                    return (null);
                                                }
                                            })}
                                        </ul>
                                    </div>
                                )
                            }else{
                                return (null);
                            }
                        })}

                        {this.props.componentItem.componentData.cta && ctaFirstButton && ctaFirstButtonTitle && ctaFirstButtonTitle !== '' && 
                          (<div className="bottom_cta"><WebsiteCTA componentItem={this.props.componentItem} /></div>)
                        }
                    </div>
                </div>

            </section>
        )
    }
}

export default Links02;
