import {buttonStyles} from "./buttonStyles";

export const regularButtonsColorClassnames = {
    [buttonStyles.regular.accentBg]: 'button--accent-bg',
    [buttonStyles.regular.accentOutline]: 'button--accent-outline',

    [buttonStyles.regular.altAccentBg]: 'button--alt-accent-bg',
    [buttonStyles.regular.altAccentOutline]: 'button--alt-accent-outline',

    [buttonStyles.regular.blackBg]: 'button--black-bg',
    [buttonStyles.regular.blackOutline]: 'button--black-outline',

    [buttonStyles.regular.whiteBg]: 'button--white-bg',
    [buttonStyles.regular.whiteOutline]: 'button--white-outline',
};