import React, { ReactNode } from 'react';
import { Icon, Tooltip } from 'antd';

interface Props {
    children?: ReactNode;
    tooltip?: ReactNode;
    className?: string;
    maxWidth?: string;
};

const InputLabel = (props: Props): JSX.Element => {
    return (
        <div className={`content-input__label_box ${props.className || ""}`}>
            {props.tooltip && <div className="content-input__tooltip_box">
                <Tooltip placement="top" title={props.tooltip} overlayStyle={{maxWidth: props.maxWidth ? props.maxWidth : "300px"}}>
                    <Icon className="content-input__tooltip_icon" type="info-circle" />
                </Tooltip>
            </div>}
            <label className="content-input__label">{props.children}</label>
        </div>
    );
}

export default InputLabel;
