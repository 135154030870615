import actionsNames from "./actions";
import { FormIntegrationsState } from "./types";

let initialState: FormIntegrationsState = {
  items: [],
  dataFetched: false,
};

export const formIntegrationsReducer = (
  state: FormIntegrationsState = initialState,
  action: any
) => {
  switch (action.type) {
    case actionsNames.SAVE_FORM_INTEGRATIONS_DATA:
      return {
        ...state,
        items: [
          // ...state.items,
          ...action.payload,
        ],
      };

    case actionsNames.FORM_INTEGRATIONS_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };

    default:
      return state;
  }
};
