import React from "react";

interface Props {
  message: string;
}

const InputErrorMessage = (props: Props): JSX.Element => {
  const { message } = props;

  if (!message) return null;

  return <div className="settings-box__error_message">{message}</div>;
};

export default InputErrorMessage;
