import React from "react";
import DeleteAccountMenu from "./DeleteAccountMenu";

const DeleteAccountField = (): JSX.Element => {
  return (
    <div className="account_fields__block">
      <DeleteAccountMenu />
    </div>
  );
};

export default DeleteAccountField;
