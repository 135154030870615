import React, { useState } from "react";
import { Button, Popconfirm, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { connect } from "react-redux";
import { getCurrentWebsite } from "../../../helpers/getCurrentWebsite";
import { doesWebsiteHasPopups } from "../../../helpers/doesWebsiteHasPopups";
import {
  deletePopup,
  toggleWebsitePopupEditionsDetected,
} from "../../../store/websites/actions";
import generateContentDataObject from "../../../helpers/editor/generateContentDataObject";
import {
  ComponentDataPaths,
  DeletePopupPayloadType,
  PopupType,
} from "../../../helpers/types/popupTypes";

interface Props {
  className?: string;
  websites?: any;
  popupData: { popupId: string; isActive: boolean };
  popupType: PopupType;

  contentType: any;
  currentInputData: any;
  buttonIndex?: number;

  saveContentInStore: (payload: object) => void;
  toggleNewEditionsDetected: (payload: boolean) => void;

  deletePopup?: (payload: DeletePopupPayloadType) => void;
  toggleWebsitePopupEditionsDetected?: (payload: boolean) => void;

  componentDataPath: ComponentDataPaths;
}

const PopupItems = (props: Props): JSX.Element => {
  const initiallyCheckedPopupId = props.popupData.popupId || "";
  const [value, setValue] = useState(initiallyCheckedPopupId);
  const generator = new generateContentDataObject();
  const { currentInputData, popupType, componentDataPath, contentType } = props;

  const saveNewPopupDataInStore = (newPopupId: string) => {
    props.saveContentInStore(
      generator.changePopupId(
        { [contentType]: currentInputData },
        newPopupId,
        componentDataPath,
        popupType,
        contentType
      )
    );
  };

  const handleOnChange = (e: RadioChangeEvent) => {
    saveNewPopupDataInStore(e.target.value);
    setValue(e.target.value);
    props.toggleNewEditionsDetected(true);
  };

  const currentWebsite = getCurrentWebsite(props.websites.items);
  const currentWebsiteSubdomain = currentWebsite.subdomain;
  const currentPopupItems = currentWebsite.popups.items;
  const isDisabled = !props.popupData.isActive;

  const displayItemControls = (popupId: string) => {
    const deletePopup = () => {
      props.deletePopup({
        id: popupId,
        currentWebsiteSubdomain,
      });
      props.toggleWebsitePopupEditionsDetected(true);
    };

    return (
      <>
        <span className="content-input__integrations_radio__del">
          <Popconfirm
            placement="bottomRight"
            title={
              <div style={{ width: 300 }}>
                Are you sure you want to delete this popup across the entire
                website?
              </div>
            }
            okText="Delete"
            cancelText="Cancel"
            onConfirm={deletePopup}
          >
            <Button
              htmlType="button"
              className="content-input__integrations_radio__del_button"
              shape="circle"
              title="Delete this popup"
              icon="cross"
              size="small"
            />
          </Popconfirm>
        </span>
        <span className="content-input__integrations_radio__del popup-menu__edit">
          <Button
            htmlType="button"
            className="content-input__integrations_radio__del_button popup-menu__edit-button js-open-custom-popup-button"
            shape="circle"
            title="Edit this popup"
            icon="edit"
            size="small"
            data-custom-popup-id={popupId}
          />
        </span>
      </>
    );
  };

  const displayItems = () => {
    if (!currentPopupItems) {
      return null;
    }

    const listOfPopupItems = currentPopupItems.map((popupItem) => {
      return (
        <div key={popupItem.id}>
          <Radio
            disabled={isDisabled}
            className={`popup-menu__item${
              isDisabled ? " popup-menu__item_disabled" : ""
            }`}
            value={popupItem.id}
          >
            {popupItem.id}
            {displayItemControls(popupItem.id)}
          </Radio>
        </div>
      );
    });

    return listOfPopupItems;
  };

  if (!doesWebsiteHasPopups(currentWebsite)) {
    return null;
  }

  return (
    <div className={props.className || ""}>
      <Radio.Group
        className="popup-menu__items-wrapper"
        value={value}
        onChange={handleOnChange}
      >
        {displayItems()}
      </Radio.Group>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    websites: state.websites,
  };
};
const actionCreators = {
  deletePopup,
  toggleWebsitePopupEditionsDetected,
};
export default connect(mapStateToProps, actionCreators)(PopupItems);
