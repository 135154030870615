import React from "react";

interface Props {
  item: any;
}

const IntegrationItemTitle = (props: Props): JSX.Element => {
  const { item } = props;
  const { integrationTitle } = item;

  if (integrationTitle === "mailchimp") {
    return (
      <>
        Used <code>action</code>:
      </>
    );
  }

  if (integrationTitle === "mailerlite") {
    return (
      <>
        Used <code>action</code>:
      </>
    );
  }

  if (integrationTitle === "webhook") {
    return (
      <>
        Used <code>action</code>:
      </>
    );
  }

  if (integrationTitle === "email") {
    return <>Used email:</>;
  }

  if (integrationTitle === "zapier") {
    if (!item.data.status) {
      // For older version of Zapier integration. The status property exists only in the new version (Oct, 2022)
      return (
        <>
          Used <code>action</code>:
        </>
      );
    }
    return <>Status:</>;
  }

  if (integrationTitle === "google-sheet") {
    return <>Used sheet title:</>;
  }

  return null;
};

export default IntegrationItemTitle;
