import { companyLogos, publicUrl } from "../../../../data/urls";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";

const generator = new generateContentDataObject();

export const updateSingleLogo = (componentData, completionContent) => {
  const content = componentData.logos.content;
  if (!("LOGO" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.LOGO)) {
    if (completionContent.LOGO === null) {
      content.items = [];
    }
    return;
  }
  if (!content.items[0]) {
    const newItem = generator.createLogoContentObject();
    newItem.uploadedSrc =
      publicUrl + companyLogos.prefix + "apple.svg";
    newItem.height = 30;
    content.items = [newItem];
  }
  const originalItem = content.items[0];
  const modifiedItem = completionContent.LOGO;
  let itemHeight = modifiedItem.HEIGHT_PX || 0;
  if (typeof itemHeight !== "number" || isNaN(itemHeight) || itemHeight < 5) {
    itemHeight = 5;
  }
  if (itemHeight > 100) {
    itemHeight = 100;
  }
  const itemHref = stringifyObjectsAndNumbers(modifiedItem.HREF) || "";
  const itemTarget = stringifyObjectsAndNumbers(modifiedItem.TARGET) || "_self";
  if ("HEIGHT_PX" in modifiedItem) {
    originalItem.height = itemHeight;
  }
  if ("HREF" in modifiedItem) {
    originalItem.href = itemHref;
  }
  if ("TARGET" in modifiedItem) {
    originalItem.isTargetBlank = itemTarget === "_blank";
  }
};
