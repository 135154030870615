import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { GraphicsOptions } from "../../component_graphics/data/componentGraphicsData";
import StepHTMLInput from "./StepHTMLInput";
import StepImageInput from "./StepImageInput";
import StepVideoInput from "./StepVideoInput";

interface Props {
  item: any;
  saveContentInStore: any;
  generator: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: (value: boolean) => void;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  state: any;
  content: any;
}

const StepGraphicsOptions = (props: Props): JSX.Element => {
  const {
    item,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
    saveImageInfoInDB,
    removeImageFromDB,
    state,
    content,
  } = props;

  const isCurrentActiveOptionValid = Object.keys(GraphicsOptions).includes(
    state.activeOption
  );
  const activeOption: GraphicsOptions = isCurrentActiveOptionValid
    ? state.activeOption
    : GraphicsOptions.image;

  const mapInputsToGraphicsOption: {
    [key in GraphicsOptions]: JSX.Element;
  } = {
    [GraphicsOptions.image]: (
      <StepImageInput
        item={item}
        saveContentInStore={saveContentInStore}
        generator={generator}
        contentType={contentType}
        currentInputData={currentInputData}
        keyProp={keyProp}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        saveImageInfoInDB={saveImageInfoInDB}
        removeImageFromDB={removeImageFromDB}
      />
    ),
    [GraphicsOptions.video]: (
      <StepVideoInput
        saveContentInStore={saveContentInStore}
        contentType={contentType}
        currentInputData={currentInputData}
        keyProp={keyProp}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        content={content}
      />
    ),
    [GraphicsOptions.HTML]: (
      <StepHTMLInput
        saveContentInStore={saveContentInStore}
        contentType={contentType}
        currentInputData={currentInputData}
        keyProp={keyProp}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        content={content}
      />
    ),
  };

  return mapInputsToGraphicsOption[activeOption];
};

export default StepGraphicsOptions;
