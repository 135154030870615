import React, { Component } from "react";
import "./ImagePicker.css";
import { Button, Icon, Modal, Tooltip } from "antd";
import { Widget } from "@uploadcare/react-widget";
import { openNotification } from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { myImagesValidator } from "../../helpers/myImagesValidator";
import DebouncedInput from "../DebouncedInput";
import generateImageAltFromFileName from "../../helpers/editor/generateImageAltFromFileName";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import classNames from "classnames";

const validators = [myImagesValidator];

interface Props {
  currentImgSrc: string;
  saveImageInReduxStore: any;
  inputId: any;
  saveImageInfoInDB: any;
  currentImgUUID: any;
  removeImageFromDB: any;
  currentImgAlt?: string; //optional because some image do not need alt (e.g. favicon, open graph). If this is undefined, we do not offer to enter alt text.
  saveImageAltInReduxStore?: any;
  height?: number;
}
type MyState = {
  previewModalVisible: boolean;
  imageDrawerVisible: boolean;
  removeConfirmVisible: boolean;
  currentImageAlt: string;
  showSaveImageAltSuccess: boolean;
};
class ImagePicker extends Component<Props, MyState> {
  state = {
    previewModalVisible: false,
    imageDrawerVisible: false,
    removeConfirmVisible: false,
    currentImageAlt: "",
    showSaveImageAltSuccess: false,
  };

  componentWillMount(): void {
    this.setState({
      ...this.state,
      previewModalVisible: false,
      imageDrawerVisible: false,
      removeConfirmVisible: false,
      showSaveImageAltSuccess: false,
    });
    if (this.props.currentImgAlt) {
      this.setState({
        ...this.state,
        currentImageAlt: "",
      });
    }
  }

  // displayImagePicker = () => {
  //     return (
  //         <div className="content-input__group">
  //             <h2 className="content-input__title">Upload an image</h2>
  //             <Uploader
  //                 id={this.props.inputId}
  //                 name={this.props.inputId}
  //                 data-tabs='file url gdrive gphotos dropbox onedrive box'
  //                 data-preview-step={true}
  //                 data-crop='free, 1:1, 8:5, 4:7'
  //                 data-image-shrink="800x800"
  //                 data-system-dialog={false}
  //                 data-images-only={false}
  //                 onChange={(file:any) => {
  //                     // console.log('File changed: ', file);
  //
  //                     // if (file) {
  //                     // file.progress((info:any) => console.log('File progress: ', info.progress));
  //                     // file.done((info:any) => console.log('File uploaded: ', info));
  //                     // }
  //                 }}
  //                 onUploadComplete={(info:any) => {
  //                     console.log('Upload completed:', info);
  //                     let uploadedImgSrc = info.cdnUrl;
  //                     if(uploadedImgSrc){
  //                         this.props.saveImageInReduxStore(uploadedImgSrc);
  //                         //     this.closeImageDrawer();
  //                         //
  //                         //     this.saveImageInfoInDB(info);
  //                     }else{
  //                         openNotification('Error :(', "There was an unknown error during uploading the file. Please refresh the page and try again.", 'OK', 'warn');
  //                     }
  //                 }
  //                 } />
  //         </div>
  //     )
  // };

  showPreviewModal = () => {
    this.setState({
      ...this.state,
      previewModalVisible: true,
    });
  };
  handlePreviewCancel = () => {
    this.setState({
      ...this.state,
      previewModalVisible: false,
    });
  };

  // showImageDrawer = () => {
  //     this.setState({
  //         imageDrawerVisible: true,
  //     });
  // };
  // closeImageDrawer = () => {
  //     this.setState({
  //         imageDrawerVisible: false,
  //     });
  // };

  handleInputChange = (e: any) => {
    this.setState({
      ...this.state,
      currentImageAlt: e.target.value,
    });
  };
  launchSuccessfulSaveAnimation = () => {
    this.setState({
      ...this.state,
      showSaveImageAltSuccess: true,
    });

    setTimeout(() => {
      this.setState({
        ...this.state,
        showSaveImageAltSuccess: false,
      });
    }, 1500);
  };
  handleImageAltUpdate = (newAlt: string): void => {
    this.props.saveImageAltInReduxStore(newAlt);
  };
  handleSetImageAltWhenUpload = (imageName: string): void => {
    if (imageName && imageName !== "") {
      let newAlt = generateImageAltFromFileName(imageName);
      this.props.saveImageAltInReduxStore(newAlt);
    }
  };
  handleOnImageUrlChange = (value: string) => {
    this.props.saveImageInReduxStore(value, undefined, undefined, undefined);
  };

  displayImageAltEditor = (
    label: string,
    tooltip: string,
    onChangeHandler: (value: string) => void,
    defaultValue: string,
    placeholder: string,
    isVisible = true
  ) => {
    if (isVisible) {
      return (
        <div className="image-picker__alt_box">
          <DebouncedInput
            addonBefore={
              <div>
                <div className="content-input__tooltip_box">
                  <Tooltip placement="top" title={tooltip}>
                    <Icon
                      className="content-input__tooltip_icon"
                      type="info-circle"
                      style={{ marginRight: 3 }}
                    />
                  </Tooltip>
                  {label}
                </div>
              </div>
            }
            onChange={onChangeHandler}
            size="small"
            placeholder={placeholder}
            defaultValue={defaultValue}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  displayEmptyImageSrcInput = () => {
    return (
      <>
        <span style={{ color: "rgba(0,0,0,0.45)" }}>or</span>
        <DebouncedInput
          onChange={this.handleOnImageUrlChange}
          placeholder="Enter image URL"
          defaultValue={this.props.currentImgSrc}
        />
      </>
    );
  };

  render() {
    let randomNumber = Math.floor(Math.random() * 20 + 10);
    if (this.props.currentImgSrc === "") {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            width: "100%",
            marginTop: "4px",
          }}
        >
          <Widget
            id={this.props.inputId + "-" + randomNumber.toString()}
            name={this.props.inputId + "-" + randomNumber.toString()}
            tabs="file url gdrive gphotos dropbox onedrive box"
            previewStep={true}
            crop="free, 1:1, 8:5, 4:7"
            imageShrink="1600x1600"
            validators={validators}
            onChange={(info: any) => {
              let uploadedImgSrc = info.cdnUrl;
              if (uploadedImgSrc) {
                //The 'info' object has a 'crop' property only if a user uploads a bitmap image (jpg, png).
                // If SVG - there in no 'crop' property.
                if (info.crop !== undefined) {
                  this.props.saveImageInReduxStore(
                    uploadedImgSrc,
                    info.uuid,
                    info.crop.width,
                    info.crop.height
                  ); // This can save a Graphics element picture, a logo, a Team member photo etc.
                } else {
                  this.props.saveImageInReduxStore(
                    uploadedImgSrc,
                    info.uuid,
                    undefined,
                    undefined
                  );
                }
                this.props.saveImageInfoInDB(info);
                this.handleSetImageAltWhenUpload(info.name);

                fireAnalyticsEvent.fireCrisp(
                  CrispEvents.uploadcareImageUpload,
                  {
                    image_url: uploadedImgSrc,
                    uuid: info.uuid,
                    size_bytes: info.size,
                  }
                );
              } else {
                fireAnalyticsEvent.fireCrisp(
                  CrispEvents.uploadcareImageUploadError,
                  {
                    error_type: "image_upload",
                    error_message: "ImagePicker.tsx upload error",
                  },
                  true
                );

                openNotification(
                  "Error :(",
                  "There was an unknown error during uploading the file. Please refresh the page and try again.",
                  "OK",
                  "warn"
                );
              }
            }}
          />
          {this.displayEmptyImageSrcInput()}
        </div>
      );
    } else {
      return (
        <div style={{ width: "100%" }}>
          <div className="image-picker checkered-bg" style={{ width: "100%" }}>
            <div className="image-picker__controls_box">
              {this.state.removeConfirmVisible === false && (
                <div
                  className={classNames("image-picker__controls", {
                    "image-picker__controls--small":
                      this.props.saveImageAltInReduxStore === undefined,
                  })}
                >
                  <Button
                    onClick={this.showPreviewModal}
                    type="primary"
                    className="image-picker__button"
                    icon="arrows-alt"
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => {
                      this.setState({ removeConfirmVisible: true });
                    }}
                    type="danger"
                    className="image-picker__button"
                    icon="delete"
                    htmlType="button"
                  />
                </div>
              )}

              {this.state.removeConfirmVisible && (
                <div
                  className={classNames("image-picker__controls", {
                    "image-picker__controls--small":
                      this.props.saveImageAltInReduxStore === undefined,
                  })}
                >
                  <Button
                    onClick={() => {
                      this.props.saveImageInReduxStore("", "");
                      this.props.removeImageFromDB(this.props.currentImgUUID);
                      this.setState({ removeConfirmVisible: false });
                    }}
                    type="danger"
                    className="image-picker__button"
                    icon="warning"
                    htmlType="button"
                  >
                    Delete
                  </Button>

                  <Button
                    onClick={() => {
                      this.setState({ removeConfirmVisible: false });
                    }}
                    type="primary"
                    className="image-picker__button"
                    htmlType="button"
                  >
                    Back
                  </Button>
                </div>
              )}
            </div>
            <img
              height={this.props.height ? this.props.height : ""}
              className="image-picker__img"
              src={formatImageUrl(this.props.currentImgSrc)}
            />

            {this.displayImageAltEditor(
              "ALT:",
              "The alt (alternative) text attribute describes an image. It is used by screen readers and search engine crawlers.",
              this.handleImageAltUpdate,
              this.props.currentImgAlt,
              "Explain the image",
              this.props.saveImageAltInReduxStore !== undefined
            )}
            {this.displayImageAltEditor(
              "URL:",
              "Change the image URL if you want to set it directly.",
              this.handleOnImageUrlChange,
              this.props.currentImgSrc,
              "Enter direct image URL"
            )}
          </div>

          <Modal
            visible={this.state.previewModalVisible}
            onCancel={this.handlePreviewCancel}
            width={900}
            footer={null}
          >
            <div style={{ padding: "30px 0 10px 0", textAlign: "center" }}>
              <div className="checkered-bg" style={{ padding: "20px 10px" }}>
                <img
                  src={formatImageUrl(this.props.currentImgSrc)}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
}

export default ImagePicker;
