export enum ComponentTypes {
  team = "team",
  other = "other",
  steps = "steps",
  contact = "contact",
  testimonials = "testimonials",
  code = "code",
  links = "links",
  tabs = "tabs",
  slider = "slider",
  posts = "posts",
  faq = "faq",
  pricing = "pricing",
  clients = "clients",
  features = "features",
  press = "press",
  cta_form = "cta_form",
  cta_button = "cta_button",
  video = "video",
  text = "text",
  header = "header",
  directory = "directory",
}
