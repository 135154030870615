import React, { useEffect } from "react";
import { FC } from "react";
import { InputProps } from "./interfaces";
import _ from "lodash";
import { connect } from "react-redux";
import { Button } from "antd";
import { useCurrentInputData } from "./useCurrentInputData";
import { openNotification } from "../../../helpers/openNotification";
import {
  DEFAULT_ERROR_MESSAGE,
  notificationDuration,
} from "../../../data/constants";

interface Props extends InputProps {}

const Widget: FC<any> = (props: Props) => {
  const {
    generator,
    saveContentInStore,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
  } = props;

  const currentInputDataRef = useCurrentInputData(currentInputData);

  const getWidget = () => {
    if (!(window as any).SenjaIntegrationPlatform) {
      openNotification(
        "Error: Can't connect to Senja",
        DEFAULT_ERROR_MESSAGE,
        "OK",
        "error",
        notificationDuration.medium
      );
      return;
    }
    (window as any).SenjaIntegrationPlatform.getWidgetId()
      .then((id) => {
        if (!id) return;
        const newCode = `<div class="senja-embed" data-id="${id}" data-lazyload="false"></div>`;
        saveContentInStore(
          generator.changeWidgetCode(
            { [contentType]: currentInputDataRef.current },
            newCode
          )
        );
        toggleNewEditionsDetected(true);
      })
      .catch((error) => {
        console.error("error: ", error);
        openNotification(
          "Error: Can't connect to Senja",
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      });
  };

  return (
    <Button type="primary" onClick={getWidget}>
      Select widget
    </Button>
  );
};

export const mapStateToProps = (state: any) => {
  return {
    websitesPages: state.websitesPages,
  };
};
export default connect(null, {})(Widget);
