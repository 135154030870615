import { UnicornComponent } from "../../../../classes/UnicornComponent";
import { Components } from "../../../../enums/Components";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";

export const updateText = (
  componentData,
  completionContent,
  currentComponentInfo: UnicornComponent
) => {
  const content = componentData.text.content;
  if (!("TEXT" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.TEXT)) {
    if (completionContent.TEXT === null) {
      content.items.forEach((item) => {
        item.markup = "";
      });
    }
    return;
  }
  const itemTitle =
    stringifyObjectsAndNumbers(completionContent.TEXT.TITLE) || "";
  const itemContent =
    stringifyObjectsAndNumbers(completionContent.TEXT.CONTENT) || "";
  if (currentComponentInfo.name === Components.text03) {
    if ("TITLE" in completionContent.TEXT) {
      content.items[0].markup = itemTitle;
    }
    if ("CONTENT" in completionContent.TEXT) {
      content.items[1].markup = itemContent;
    }
    return;
  }
  if ("CONTENT" in completionContent.TEXT) {
    content.items[0].markup = itemContent;
  }
};
