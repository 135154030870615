export const checkIfPlanSupportsCaptcha = (user: any, plans: any) => {
  const userPlanId = user.data.current_plan;
  const plansCount = plans.items.length;

  for (let i = 0; i < plansCount; i++) {
    let currentPlan = plans.items[i];

    if (currentPlan.id === userPlanId) {
      //found current active plan
      return currentPlan.is_recaptcha_included;
    }
  }
};
