import { Button, Alert } from 'antd';
import React, { Component } from 'react';
import "./DisplayBadWebsiteOverlay.css"

interface Props {
  website: any,
  history: any,
}
interface State{
  
}
class DisplayBadWebsiteOverlay extends Component<Props, State> {
  render() {
    if (this.props.website !== undefined && this.props.website.bad){
      const infoText = (
      <div>
        <div>The website is flagged as potentially harmful. <br/> Your recent changes were saved, but the website can not be edited anymore and is not available for the public.</div>
      </div>
      )
      const badWebsiteInfo = (
        <div className="display_bad_website_overlay">
          <div className="display_bad_website_overlay__content">
          <Alert
            message="Whoa!"
            description={infoText}
            type="error"
            showIcon
          />
          </div>
        </div>
      )
      return (badWebsiteInfo)
    }else{
      return null;
    }
  }
}

export default DisplayBadWebsiteOverlay;
