import React from "react";
import { Button, Icon } from "antd";
import { CrispEvents } from "../../../../enums/AnalyticsEventsEnums";
import { getCurrentBlog } from "../../../../helpers/blog/getCurrentBlog";
import fireAnalyticsEvent from "../../../../helpers/editor/fireAnalyticsEvent";
import generateRandomNumerousId from "../../../../helpers/generateRandomNumerousId";
import { getCurrentWebsitePageUrlForExport } from "../../../../helpers/html_export/getCurrentWebsitePageUrlForExport";
import PageTitle from "./PageTitle";

interface Props {
  blogPosts: any;
  currentWebsite: any;
  blogs: any;
  websitesPages: any;
}
// display blog posts if any
const BlogPostsDownloadButtons = (props: Props): JSX.Element => {
  const { blogPosts, currentWebsite, blogs, websitesPages } = props;
  const blogObject = getCurrentBlog(blogs.items, currentWebsite);

  let blogPostsDownloadButtons = null;

  if (blogObject !== undefined) {
    let thisBlogPosts = blogPosts.items.filter(
      (item) => item.blog === blogObject.id
    );
    if (thisBlogPosts && thisBlogPosts.length > 0) {
      blogPostsDownloadButtons = thisBlogPosts.map((item: any, key: number) => {
        let currentWebsitePageUrl = getCurrentWebsitePageUrlForExport(
          currentWebsite,
          item.url,
          blogObject.url
        );

        return (
          <li key={key} className="html-export__button_item">
            <Button
              onClick={() => {
                fireAnalyticsEvent.fireCrisp(CrispEvents.exportWebsitePage, {
                  subdomain: currentWebsite.subdomain,
                  page_url: item.url,
                });
              }}
              type={"primary"}
              icon={"download"}
              size="small"
              // target={"_blank"}
              href={
                currentWebsitePageUrl +
                "?nocache=" +
                generateRandomNumerousId() +
                "&as_file=true&file_name=" +
                (item.url === "" ? "index" : item.url)
              }
              title={"Download the .HTML file."}
              loading={false}
            />
            <div className="html-export__page_title">
              <div className="html-export__page_title_text">
                {" "}
                <PageTitle item={item} blogHomePageURL={blogObject.url} />
                <a
                  hidden={websitesPages.pageChangeInProgress}
                  title="View the blog post in a new tab."
                  href={currentWebsitePageUrl}
                  target="_blank"
                  className="html-export__link"
                >
                  <Icon type="link" />
                </a>
              </div>
            </div>
          </li>
        );
      });
    } else {
      return null;
    }
  }

  return blogPostsDownloadButtons;
};

export default BlogPostsDownloadButtons;
