// For fuzzysort library.
import _ from "lodash";

export const sortObjectsByScoresFn = (a, b, path) => {
  const aScores = _.get(a, path);
  const bScores = _.get(b, path);
  const len = Math.min(aScores.length, bScores.length);

  if (!aScores || !bScores) return 0;

  for (let i = 0; i < len; i++) {
    if (aScores[i] > bScores[i]) {
      return -1;
    } else if (aScores[i] < bScores[i]) {
      return 1;
    }
  }

  if (aScores.length < bScores.length) {
    return 1;
  } else if (aScores.length > bScores.length) {
    return -1;
  }

  return 0;
};
