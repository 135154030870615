import axios from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";

const actionsNames = {
  SAVE_SHARED_ACCESS_DATA: "SAVE_SHARED_ACCESS_DATA" as string,
  TOGGLE_SHARED_ACCESS_DATA_FETCHED: "TOGGLE_SHARED_ACCESS_DATA_FETCHED" as string,
  TOGGLE_CHANGE_SHARED_ACCESS_WAITING_FOR_SERVER: "TOGGLE_CHANGE_SHARED_ACCESS_WAITING_FOR_SERVER" as string,
  ADD_NEW_SHARED_ACCESS_ITEM: "ADD_NEW_SHARED_ACCESS_ITEM" as string,
  REMOVE_SHARED_ACCESS_ITEM: "REMOVE_SHARED_ACCESS_ITEM" as string,
};

export const fetchSharedAccesses = () => {
  return (dispatch: any, getState: any) => {
    let accessToken = getState().auth.accessToken;
    axios
      .get(api.sharedAccess.fetch, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        dispatch(toggleSharedAccessFetched(true));
        dispatch(saveSharedAccessInState(response.data));
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

export const saveSharedAccessInState = (data: string) => {
  // get data and store it
  return {
    type: actionsNames.SAVE_SHARED_ACCESS_DATA as string,
    payload: data as string,
  };
};

export const addNewSharedAccessItemInState = (data: string) => {
  // get data and store it
  return {
    type: actionsNames.ADD_NEW_SHARED_ACCESS_ITEM as string,
    payload: data as string,
  };
};

export const removeSharedAccessItemFromState = (itemId: number) => {
  return {
    type: actionsNames.REMOVE_SHARED_ACCESS_ITEM as string,
    payload: itemId as number,
  };
};

export const toggleSharedAccessFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.TOGGLE_SHARED_ACCESS_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export const toggleSharedAccessChangeWaitingForServer = (
  isWaitingForServer: boolean
) => {
  return {
    type: actionsNames.TOGGLE_CHANGE_SHARED_ACCESS_WAITING_FOR_SERVER as string,
    payload: isWaitingForServer as boolean,
  };
};

export default actionsNames;
