export const componentsBackgroundOptionsExceptions:any = {
    // Due to the specific design some components do not allow changing background color.
    // Some component doesn't support background images. Some doesn't support ANY background options (empty array).

    header: {
        '02': [] as any // NO bg options (not even color), because this header has BG animation
    },
    testimonials: {
        '05': ['white', 'light_gray', 'light', 'medium', 'mature', 'accent', 'custom'] as any // NO black bg option, because the component has a black SVG as a ::before element so ew can't recolor it.
    },
    faq: {
        '05': [] as any // NO bg options (not even color), because the component has too much different colors and many shades of gray (<here comes a joke about 50 Shades of Gray>)
    },
    code: {
        '02': ['white', 'black', 'light_gray', 'mature', 'light', 'medium', 'custom'] as any // NO accent bg option because the component has a hardcoded accent-bg button)
    },

};
