import React from "react";
import { draftJsEditorTitle } from "../../../data/constants";
import DebouncedTextarea from "../../DebouncedTextarea";
import MyRichTextEditor from "../../MyRichTextEditor";
import { InputProps } from "./interfaces";
import { useCurrentInputData } from "./useCurrentInputData";
import { Components } from "../../../enums/Components";
import { textWithOneItem } from "../../../data/componentExceptions";

const Text = (props: InputProps): JSX.Element => {
  const {
    content,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
    textEditorType,
    revertToPlainHTML,
    componentType,
    componentTitle,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const componentName = `${componentType}-${componentTitle}` as Components;
  const isSingleItem = textWithOneItem.includes(componentName);

  let textInputs = content.items.map((item: any, key: number) => {
    if (isSingleItem && key > 0) return null;
    let onChangeHandler = (value: any) => {
      saveContentInStore(
        generator.changeTextMarkup(
          { [contentType]: currentInputDataRef.current },
          value,
          key
        )
      );
      toggleNewEditionsDetected(true);
    };
    if (textEditorType && textEditorType === draftJsEditorTitle) {
      return (
        <div key={"text-input-" + key} className="content-input__input">
          <MyRichTextEditor
            revertToPlainHTML={revertToPlainHTML}
            placeholder="Enter a passionate text"
            defaultValue={item.markup}
            onChange={onChangeHandler}
          />
        </div>
      );
    } else {
      return (
        <DebouncedTextarea
          className="content-input__input"
          key={"text-input-" + key}
          onChange={onChangeHandler}
          placeholder={"Enter a passionate text"}
          defaultValue={item.markup}
          autosize={{ minRows: 2, maxRows: 6 }}
          id={contentType}
        />
      );
    }
  });

  return textInputs;
};

export default Text;
