import React from "react";
import { Button, Form, Input } from "antd";
import { connect } from "react-redux";
import {
  saveUserDataInState,
  toggleIsChangeUserEmailInProgress,
} from "../../../../store/user/actions";
import { ToggleIsChangeUserEmailInProgress } from "../../../../store/user/types";
import { changeEmailAsync } from "../../../../helpers/account/changeEmailAsync";
import { openNotification } from "../../../../helpers/openNotification";
import { notificationDuration } from "../../../../data/constants";
import fireAnalyticsEvent from "../../../../helpers/editor/fireAnalyticsEvent";
import {
  CrispEvents,
  PosthogEvents,
} from "../../../../enums/AnalyticsEventsEnums";

interface Props {
  user: any;
  auth: any;
  currentEmail: string;

  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentEmail: React.Dispatch<React.SetStateAction<string>>;

  saveUserDataInState: (data: any) => void;
  toggleIsChangeUserEmailInProgress: ToggleIsChangeUserEmailInProgress;
}

const ChangeEmailForm = (props: Props): JSX.Element => {
  const {
    user,
    auth,
    currentEmail,
    setErrorMessage,
    setIsModalVisible,
    setCurrentEmail,
  } = props;

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = currentEmail.trim().toLowerCase();
    setErrorMessage(null);
    if (email === user.data.email) {
      setIsModalVisible(false);
      return;
    }
    setCurrentEmail(email);
    props.toggleIsChangeUserEmailInProgress(true);
    changeEmailAsync(auth.accessToken, {
      email,
    })
      .then((response) => {
        props.saveUserDataInState({
          email: response.data.new_email,
          is_email_verified: false,
        });
        setIsModalVisible(false);
        openNotification(
          "Done",
          <>
            <p className="paragraph-custom">
              You have successfully changed your email to{" "}
              <b>{response.data.new_email}</b>
            </p>
            <p className="paragraph-custom">
              Please check your inbox for a confirmation email.
            </p>
          </>,
          "OK",
          "success",
          notificationDuration.short
        );
        fireAnalyticsEvent.fireCrisp(CrispEvents.changeEmail, {
          old_email: response.data.old_email,
          new_email: response.data.new_email,
        });
        window.posthog.capture(PosthogEvents.CHANGE_EMAIL, {
          old_email: response.data.old_email,
          new_email: response.data.new_email,
          $set: {
            email: response.data.new_email,
          },
        });
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
        fireAnalyticsEvent.fireCrisp(
          CrispEvents.changeEmailError,
          {
            error_message: error.response.data.message,
          },
          true
        );
      })
      .finally(() => {
        props.toggleIsChangeUserEmailInProgress(false);
      });
  };

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setCurrentEmail(event.currentTarget.value);
  };

  return (
    <Form onSubmit={handleOnSubmit} id="email-form" className="horizontal-form">
      <Input
        className="horizontal-form__input"
        value={currentEmail}
        placeholder="sales@acme.com"
        onChange={handleOnChange}
        name="email"
        autoFocus
        disabled={user.isChangeUserEmailInProgress}
      />
      <Button
        form="email-form"
        htmlType="submit"
        type="primary"
        loading={user.isChangeUserEmailInProgress}
      >
        Change
      </Button>
    </Form>
  );
};

const mapStateToProps = ({ user, auth }) => {
  return { user, auth };
};
const mapActionsToProps = {
  saveUserDataInState,
  toggleIsChangeUserEmailInProgress,
};
export default connect(mapStateToProps, mapActionsToProps)(ChangeEmailForm);
