import React, { Component } from "react";
import { Dropdown, Icon, Menu, Popconfirm, Radio } from "antd";

import "./MockupsContentInput.css";
import { mockups } from "../../data/mockups";
import getMockupDeviceRenderImageFullFilepath, { urls } from "../../data/urls";
import capitalizeString from "../../helpers/strings/capitalizeString";

interface Props {
  content: any;
  saveContentInStore: any;
  toggleNewEditionsDetected: any;
  currentInputData: any;
  generator: any;
  contentType: any;
  componentName?: string;
}

class MockupsContentInput extends Component<Props> {
  containerRef = React.createRef<HTMLDivElement>();
  saveNewMockup = (mockupIndex: number, mockupData: any) => {
    this.props.saveContentInStore(
      this.props.generator.changeMockupDevice(
        { [this.props.contentType]: this.props.currentInputData },
        mockupData.deviceId,
        mockupIndex
      )
    );
    this.props.saveContentInStore(
      this.props.generator.changeMockupOption(
        { [this.props.contentType]: this.props.currentInputData },
        mockupData.optionId,
        mockupIndex
      )
    );
    this.props.saveContentInStore(
      this.props.generator.changeMockupHumanTitle(
        { [this.props.contentType]: this.props.currentInputData },
        mockupData.humanTitle,
        mockupIndex
      )
    );
    this.props.saveContentInStore(
      this.props.generator.changeMockupFileName(
        { [this.props.contentType]: this.props.currentInputData },
        mockupData.fileName,
        mockupIndex
      )
    );
    this.props.toggleNewEditionsDetected(true);
  };

  getDevicePickerMenu = (
    currentMockupObject: any,
    mockupContentIndex: number
  ) => {
    let type = currentMockupObject.type;
    let mockupsToChooseFrom = [];
    if (type === "desktop") {
      mockupsToChooseFrom = mockups.categories.desktops;
    } else if (type === "mobile") {
      mockupsToChooseFrom = mockups.categories.phones;
    } else if (type === "tablet") {
      mockupsToChooseFrom = mockups.categories.tablets;
    }

    if (
      this.props.content.items.length > 1 ||
      this.props.componentName === "header61"
    ) {
      mockupsToChooseFrom = mockupsToChooseFrom.filter(
        ({ id }) => id !== "Empty"
      );
    }

    if (mockupsToChooseFrom.length > 0) {
      const menuItems = mockupsToChooseFrom.map(
        (itemFromGalleryId: any, key: number) => {
          let optionsOfDevice = itemFromGalleryId.options;

          let isThisMenuItemActive =
            itemFromGalleryId.id === currentMockupObject.deviceId;

          let fullFilePath = getMockupDeviceRenderImageFullFilepath({
            type: type,
            fileName: optionsOfDevice[0].fileName,
          });

          const deviceColorOptionsJSX = optionsOfDevice.map(
            (colorOptionItem: any, key: number) => {
              let isThisColorActive =
                colorOptionItem.id === currentMockupObject.optionId;

              let formattedColorTitle = capitalizeString(
                colorOptionItem.id
              ).replace("_", " ");
              let tooltipText = "Set device color to: " + formattedColorTitle;
              if (isThisColorActive) {
                tooltipText = "Active color: " + formattedColorTitle;
              }

              return (
                <div
                  key={key}
                  className={
                    "mockups_content_input__color_item_box " +
                    (isThisColorActive
                      ? " mockups_content_input__color_item_box--active"
                      : " ")
                  }
                >
                  <button
                    title={tooltipText}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      this.saveNewMockup(mockupContentIndex, {
                        deviceId: itemFromGalleryId.id,
                        optionId: colorOptionItem.id,
                        humanTitle: itemFromGalleryId.humanTitle,
                        fileName: colorOptionItem.fileName,
                      });
                    }}
                    className="mockups_content_input__color_button"
                    style={{ backgroundColor: colorOptionItem.color }}
                  ></button>
                </div>
              );
            }
          );

          return (
            <Menu.Item key={key}>
              <div className="mockups_content_input__menu_item">
                <Radio
                  onClick={(e: any) => {
                    e.stopPropagation();
                    this.saveNewMockup(mockupContentIndex, {
                      deviceId: itemFromGalleryId.id,
                      optionId: itemFromGalleryId.options[0].id,
                      humanTitle: itemFromGalleryId.humanTitle,
                      fileName: itemFromGalleryId.options[0].fileName,
                    });
                  }}
                  value={itemFromGalleryId.id}
                  checked={isThisMenuItemActive}
                />
                <div className="mockups_content_input__device_box">
                  <img
                    className="mockups_content_input__device"
                    src={`${fullFilePath}?height=72`}
                    alt="device"
                  />
                </div>
                <div className="mockups_content_input__human_title">
                  {itemFromGalleryId.humanTitle}
                </div>
                {optionsOfDevice.length > 1 &&
                  isThisMenuItemActive === true && (
                    <div className="mockups_content_input__colors_box">
                      {deviceColorOptionsJSX}
                    </div>
                  )}
                <div
                  className="mockups_content_input__resolution"
                  title={
                    "Recommended screenshot format: " +
                    itemFromGalleryId.resolution
                  }
                >
                  {itemFromGalleryId.resolution}
                </div>
              </div>
            </Menu.Item>
          );
        }
      );

      const menu = <Menu>{menuItems}</Menu>;

      return menu;
    } else {
      return null;
    }
  };

  render() {
    const deviceDropdowns = this.props.content.items.map(
      (item: any, key: number) => {
        let menu = this.getDevicePickerMenu(item, key);

        let dropdownTitle = "";
        if (item.type === "mobile") {
          dropdownTitle = "Select another mobile phone device.";
        } else if (item.type === "desktop") {
          dropdownTitle = "Change laptop or PC device.";
        } else if (item.type === "tablet") {
          dropdownTitle = "Set another tablet device.";
        }

        let fullFilePath = getMockupDeviceRenderImageFullFilepath({
          type: item.type,
          fileName: item.fileName,
        });

        const dropdown = (
          <Dropdown
            overlayClassName="mockups_content_input__dropdown"
            overlay={menu}
            trigger={["click"]}
            placement="topLeft"
            getPopupContainer={() => this.containerRef.current}
          >
            <a
              className="ant-dropdown-link"
              title={dropdownTitle}
              onClick={(e) => e.preventDefault()}
            >
              <div className="mockups_content_input__menu_item">
                <div className="mockups_content_input__device_box">
                  <img
                    className="mockups_content_input__device"
                    src={`${fullFilePath}?height=72`}
                    alt="device"
                  />
                </div>
                <div className="mockups_content_input__human_title">
                  {item.humanTitle}
                </div>
                <Icon style={{ marginLeft: 7 }} type="down" />
              </div>
            </a>
          </Dropdown>
        );
        return (
          <div className="mockups_content_input__dropdown_box" key={key} ref={this.containerRef}>
            {dropdown}
          </div>
        );
      }
    );

    return (
      <div className="mockups_content_input">
        {/*
                разбить пикер на два типа: десктоп и мобайл
                если у компоннета только один тип мокапа, не выводить заголовок для типа (единственного)
            */}
        <div className="mockups_content_input__dropdowns">
          {deviceDropdowns}
        </div>
        <div className="mockups_content_input__disclaimer">
          <Popconfirm
            cancelText={undefined}
            placement="left"
            title={
              <div style={{ width: 500 }}>
                The third party designs appearing on this website are the
                intellectual property of their respective owners. Unicorn
                Platform does not own the third party product designs. <br />
                <br /> Unicorn Platform disclaims all express or implied
                warranties with respect to these assets, including
                non-infringement of intellectual property rights. This
                disclaimer is a part of Terms of Service.
              </div>
            }
            icon={<Icon type="exclamation-circle" />}
            okText="Read Terms of Service"
            onConfirm={() => {
              window.open(urls.tos);
            }}
          >
            <span>
              Disclaimer{" "}
              <Icon style={{ marginLeft: 3 }} type="exclamation-circle" />
            </span>
          </Popconfirm>
        </div>
      </div>
    );
  }
}

export default MockupsContentInput;
