import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import _ from "lodash";
import GraphicsJSX from "../GraphicsJSX";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Faq03 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({ titleText: "",isDefaultEmpty })
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({ subtitleText: "",isDefaultEmpty })
      );

      this.props.saveContentInStore(
        this.generator.setUpGraphics({
          fieldTitle: {
            [GraphicsOptions.image]: "Image",
            [GraphicsOptions.video]: "Video",
            default: "Image",
          },
          label: {
            [GraphicsOptions.image]: "Upload an image:",
            [GraphicsOptions.video]: "Enter URL to Youtube video:",
            default: "Upload an image:",
          },
          labelTooltip: {
            [GraphicsOptions.image]:
              "Upload an image from your computer, your cloud or a direct URL.",
            [GraphicsOptions.video]:
              "Show an explaining or an onboarding video.",
            default: "",
          },

          activeOption: GraphicsOptions.video,
          userCanAddItems: false,
          maxItems: 1,
          items: [
            this.generator.createGraphicsContentObject({
              videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
              imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_DASHBOARD_1,
              width: 1280,
              height: 800,
            }),
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpFaq({
          isDefaultEmpty,
          maxItems: 33,
          items: [
            this.generator.createFaqContentObject({
              title: "What is Unicorn Platform?",
              text:
                "Unicorn Platform is a landing page builder for startups and makers. You can quickly create a beautiful website for a mobile/desktop app, for a SaaS product, for a plugin, an open-source project, a SMM tool etc.",
            }),

            this.generator.createFaqContentObject({
              title: "What are the advantages on this builder?",
              text:
                "The key advantage is the approach. We are building a landing page generator for startups only. That means, we pay all the attention to startups-only demands. In particular, we will provide not-so-popular integrations, specific components and templates.",
            }),
            this.generator.createFaqContentObject({
              title:
                "Do I need to know HTML/CSS to build a website with Unicorn Platform?",
              text:
                "Zero coding skills required to build a website. Although, if you are allowed to add any custom code to tune your website if you want to.",
            }),
            this.generator.createFaqContentObject({
              title: "How to collect emails with Unicorn Platform?",
              text:
                "Use one of the prebuilt forms to collect emails of your visitor. We provide integrations with popular email marketing tools such as Mailchimp so your data will be automatically sent there (we do not store the data so it is safe). We also keep creating integrations with other popular services, so if you need something specific - just ask.",
            }),
            this.generator.createFaqContentObject({
              title:
                "Do I need to be a designer to build a website with Unicorn Platform?",
              text:
                "Nope. Unicorn Platform's philosophy tells that everyone can have a brilliant website. So you need no design experience to build a landing page.",
            }),

            // The last ghost item which is used to add new items.
            this.generator.createFaqContentObject({
              title: "",
              text: "",
            }),
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a CTA (call to action) to the bottom of the F.A.Q. component to let your visitors to ask further questions about your project. Add a 'mailto:' link or a link to a survey form (Google Forms, Typeform, JotForm).",
            default:
              "Add a CTA (call to action) to the bottom of the F.A.Q. component to let your visitors to ask further questions about your project. Add a 'mailto:' link or a link to a survey form (Google Forms, Typeform, JotForm).",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "Contact us",
              buttonHref: "#",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "need help?",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let faq = this.props.componentItem.componentData.faq.content.items;
    let youtubeVideoUrl = this.props.componentItem.componentData.graphics
      .content.items[0][GraphicsOptions.video].youtube.url; // This component has exactly one item and more can not be added (see createGraphicsContentObject() above).

    const currentActiveOption: GraphicsOptions = _.get(
      this.props.componentItem,
      ["componentData", "graphics", "activeOption"]
    );
    const imageUrl: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.image,
      "url",
    ]);
    const codeSnippet: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.HTML,
      "codeSnippet",
    ]);

    return (
      <section>
        <div
          className={`faq-03 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.video}`}
        >
          <TitleBox 
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />
          <div className="container container--large faq-03__container">
            <div className="faq-03__wrapper">
              <ul className="faq-03__list">
                {faq &&
                  faq.map((item: any, key: number) => {
                    if (item.title !== "") {
                      return (
                        <li className="faq-03__item" key={"faq-03-" + key}>
                          <button
                            className="faq-03__question color-main js-open-faq"
                            type="button"
                          >
                            <div className="faq-03__question_text">
                              <span className="title-text--inner def-16">
                                {item.title}
                              </span>
                              <div className="faq-03__arrow_icon">
                                <span className="icon">
                                  <svg
                                    width="12px"
                                    height="7px"
                                    viewBox="0 0 12 7"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      id="styleguide"
                                      stroke="none"
                                      strokeWidth="1"
                                      fill="none"
                                      fillRule="evenodd"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <g
                                        id="Unicorn-Styleguide"
                                        transform="translate(-1182.000000, -2712.000000)"
                                        strokeWidth="2"
                                        stroke="#4D61FC"
                                        className="stroke-main"
                                      >
                                        <g
                                          id="Messages"
                                          transform="translate(81.000000, 2467.000000)"
                                        >
                                          <g
                                            id="toolstips"
                                            transform="translate(791.000000, 57.000000)"
                                          >
                                            <g
                                              id="Tooltip"
                                              transform="translate(1.000000, 98.000000)"
                                            >
                                              <polyline
                                                id="Line"
                                                points="310.5 95.5 315 91 319.5 95.5"
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                            <div className="faq-03__answer js-faq-item">
                              <div className="content_box ">
                                {item.text !== "" && (
                                  <div
                                    className="content-text faq-03__answer_text"
                                    dangerouslySetInnerHTML={{
                                      __html: item.text,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </button>
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
              </ul>
              <div className="faq-03__button_box">
                {this.props.componentItem.componentData.cta &&
                  <WebsiteCTA componentItem={this.props.componentItem} />}
              </div>
            </div>
            <div className="faq-03__video">
              <GraphicsJSX
                currentActiveOption={currentActiveOption}
                defaultGraphicsOption={GraphicsOptions.video}
                isDarkBg={this.props.isDarkBg}
                permanentSpinnerColor
                imageUrl={imageUrl}
                videoUrl={youtubeVideoUrl}
                codeSnippet={codeSnippet}
                classNames={{
                  image: "faq-03__image",
                  video: "faq-03__video_box",
                  HTML: "faq-03__HTML faq-03__editor-HTML",
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq03;
