import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Slider06 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Assertive Layouts"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpGallery({isDefaultEmpty,maxItems: 33}));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the slider component to invite users to read more about the project.",
                    default: "Add a button to the bottom of the slider component to invite users to read more about the project.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'See more layouts'
                    }),
                ],

                priority: 10
            }));
        }
    }

    componentDidMount(): void {

    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let gallery = this.props.componentItem.componentData.gallery.content.items;

        let settings = {
            'dots': false,
            'infinite': false,
            'speed': 300,
            'fade': false,
            'cssEase': 'ease',
            'adaptiveHeight': true,
            // 'lazyLoad': 'ondemand',
            'variableWidth': true,
            'slidesToShow': 3,
            'slidesToScroll': 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };

        return (
            <section>
                <div className="slider-06">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className=" slider-06__container">
                        <div className="slider slider-06__slider">
                            <Slider {...settings}>
                                {gallery && gallery.map((item:any, key:number) => {
                                    if(item.contentGraphic[0] !== undefined && item.contentGraphic[0].image !== undefined && item.contentGraphic[0].image.url !== ''){
                                        return (
                                            <li className="slider__item" key={"slider-06-item-" + key}>
                                                <div className="slider-06__item_box">
                                                    <img className="js-lightbox-single-image slider__img slider-06__img"
                                                         src={formatImageUrl(item.contentGraphic[0].image.url)}
                                                         data-width="1440"
                                                         data-height="900"
                                                    />
                                                </div>
                                            </li>
                                        )
                                    }else{
                                        return (null);
                                    }
                                })}
                            </Slider>
                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>

                    </div>
                </div>

            </section>
        )
    }
}

export default Slider06;
