import { getPathToCustomBgColorData } from "./getPathToCustomBgColorData";
import _ from "lodash";
import { CustomColorData } from "./types/globalTypes";

export const getCustomBackgroundColorData = (
  componentItem: any,
  isFooter?: boolean
): CustomColorData => {
  let pathToData = getPathToCustomBgColorData(isFooter);

  const customBackgroundColorData: CustomColorData = _.get(
    componentItem,
    pathToData,
    {}
  );
  const { color, isActive, isDark } = customBackgroundColorData;
  return {
    color,
    isActive,
    isDark,
  };
};
