import { updatePricingTableColumns } from "./updatePricingTableColumns";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { updatePricingTableRowTitles } from "./updatePricingTableRowTitles";

export const updatePricingTable = (componentData, completionContent) => {
  if (!("PRICING" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.PRICING)) {
    if (completionContent.PRICING === null) {
      componentData.pricing.content.comparisionTableHeadersSet = [];
      componentData.pricing.content.items = [];
    }
    return;
  }
  if (!("PRICING_TABLE" in completionContent.PRICING)) {
    return;
  }
  if (!isKeyValue(completionContent.PRICING.PRICING_TABLE)) {
    if (completionContent.PRICING.PRICING_TABLE === null) {
      componentData.pricing.content.comparisionTableHeadersSet = [];
      componentData.pricing.content.items = [];
    }
    return;
  }
  updatePricingTableRowTitles(
    componentData,
    completionContent.PRICING.PRICING_TABLE
  );
  updatePricingTableColumns(
    componentData,
    completionContent.PRICING.PRICING_TABLE
  );
};
