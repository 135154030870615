import React, {Component} from "react";
import generateContentDataObject from '../../helpers/editor/generateContentDataObject'
import {callToActionOptions} from "../../data/callToActionOptions";
import {pressLogos} from "../../data/urls";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {pictogramOptions} from "../../data/pictogramOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import WebsiteFeature from "../component_molecules/WebsiteFeature";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Header03 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty, defaultCtaOption } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: 'Customer Care Center',
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpCta({
                activeOption: defaultCtaOption || callToActionOptions.buttons,
                priority: 500,
                isDefaultEmpty,

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Get it",
                        buttonHref: '/sign-up',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.accentBg,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'Whoa!'
                    }),

                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Learn more",
                        buttonHref: '/product',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.accentOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'New'
                    }),

                    // ghost object
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        buttonColor: buttonStyles.ghost.accentOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'View'
                    })
                ]

            }));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                priority: 200,
                maxItems: 3,

                hasSubtitle: false,
                hasText: true,
                hasPictogram: true,
                hasHighlight: false,
                hasHighlightInfo: false,
                hasButton: true,

                buttonTypes: [buttonStyles.ghost.typeTitle, buttonStyles.regular.typeTitle, buttonStyles.pill.typeTitle, buttonStyles.mobileAppStore.typeTitle],

                items: [
                    this.generator.createFeatureContentObject({
                        title: 'Build Instantly',
                        text: 'Cur spatii congregabo. Raptus, talis solems aliquando imperium de alter, albus boreas.',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "Read here",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.ghost.typeTitle,
                            buttonColor: buttonStyles.ghost.accentOutline,
                        })
                    }),
                    this.generator.createFeatureContentObject({
                        title: 'Fully Responsive',
                        text: 'Ecce. Cum ausus studere, omnes demolitionees acquirere castus, dexter ignigenaes.',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "View guide",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.ghost.typeTitle,
                            buttonColor: buttonStyles.ghost.accentOutline,
                        })
                    }),
                    this.generator.createFeatureContentObject({
                        title: 'Unlimited Flexibility',
                        text: 'Calceus de germanus cannabis, amor tata! Sunt diatriaes consumere rusticus, altus decores.',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "Learn more",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.ghost.typeTitle,
                            buttonColor: buttonStyles.ghost.accentOutline,
                        })
                    }),
                ]

            }));

        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);
        let features = this.props.componentItem.componentData.features.content.items;

        return (

            <header className="header-03">
                <div className="header-03__box">
                    <div className="header-03__overlay">
                        <div className="container container--mid header-03__container">
                            <div className="header-03__card_holder">
                                <div className="header-03__card header-03__card--bottom card-container"/>
                                <div className="header-03__card header-03__card--middle card-container"/>
                                <div className="header-03__card header-03__card--top card-container">
                                    <div className="header-03__card_content">
                                        {title.length > 0 && <h1 className={"title-text heading heading--accent header-03__heading "} dangerouslySetInnerHTML={{__html: title}}/>}

                                        {subtitleText.length > 0 && <p className={"subtitle-text content_box header-03__text "} dangerouslySetInnerHTML={{__html: subtitleText}}/>}
                                        <div className="header-03__buttons">
                                            <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={false} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul className="header-03__features">

                                {features && features.map((item:any, key:number) => {
                                    if(item.title && item.title !== ''){
                                        return (
                                            <li className="header-03__feature" key={'feature-header-03-' + key}>
                                                <div className="header-03__feature_box">
                                                    <WebsiteFeature featureObject={item} isWhite={this.props.isDarkBg} />
                                                </div>
                                            </li>
                                        )
                                    }else{
                                        return (null);
                                    }

                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

        )
    }
}

export default Header03;
