import React, { useState } from "react";
import { Button, Icon, Modal } from "antd";
import {
  saveUserDataInState,
  toggleIsChangeUserEmailInProgress,
} from "../../../../store/user/actions";
import { toggleResendEmailConfirmationWaiting } from "../../../../store/auth/actions";
import { connect } from "react-redux";
import { ToggleIsChangeUserEmailInProgress } from "../../../../store/user/types";
import ConditionalTooltip from "../../ConditionalTooltip";
import ChangeEmailForm from "./ChangeEmailForm";
import InputErrorMessage from "../../InputErrorMessage";
import ResendConfirmationButton from "../../ResendConfirmationButton";
import _ from "lodash";

interface Props {
  user: any;
  auth: any;
  saveUserDataInState: (data: any) => void;
  toggleIsChangeUserEmailInProgress: ToggleIsChangeUserEmailInProgress;
  toggleResendEmailConfirmationWaiting: (payload: boolean) => void;
}

const ChangeEmailMenu = (props: Props): JSX.Element => {
  const { user } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentEmail, setCurrentEmail] = useState<string>(user.data.email);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const isVerified = user.data.is_email_verified;

  const showModal = () => {
    setCurrentEmail(user.data.email);
    setErrorMessage(null);
    setIsModalVisible(true);
  };

  const handleOnCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="account_fields__row" style={{marginBottom: "20px"}}>
        <div>
          <div className="account_fields__title">Email</div>
          <div className="account_fields__value">
            <ConditionalTooltip
              condition={!isVerified}
              title={
                <div>
                  Your email is not verified. Please go to your email inbox and
                  click the link.{" "}
                  <ResendConfirmationButton type="link">
                    Resend verification
                  </ResendConfirmationButton>
                </div>
              }
            >
              <div className="account_fields__value_wrapper">
                {user.data.email}
                {!isVerified && (
                  <Icon
                    type="warning"
                    className="account_fields__icon account_fields__icon--warning"
                  />
                )}
              </div>
            </ConditionalTooltip>
          </div>
        </div>
        <div className="account_fields__action">
          <ConditionalTooltip 
          title={"You can't change email because you are logged in with Google."}
          condition={_.get(user, "data.type") === "google"}
          >
            <Button
              onClick={showModal}
              loading={user.isChangeUserEmailInProgress}
              disabled={_.get(user, "data.type") === "google"}
            >
              Change email
            </Button>
          </ConditionalTooltip>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleOnCancel}
        title="Enter new email"
        footer={null}
        width={350}
      >
        <div>
          <ChangeEmailForm
            currentEmail={currentEmail}
            setErrorMessage={setErrorMessage}
            setIsModalVisible={setIsModalVisible}
            setCurrentEmail={setCurrentEmail}
          />
          <InputErrorMessage message={errorMessage} />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ user, auth }) => {
  return {
    user,
    auth,
  };
};
const mapActionsToProps = {
  saveUserDataInState,
  toggleIsChangeUserEmailInProgress,
  toggleResendEmailConfirmationWaiting,
};
export default connect(mapStateToProps, mapActionsToProps)(ChangeEmailMenu);
