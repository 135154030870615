import _ from "lodash";

export const getPageArray = (currentWebsitePage, currentWebsite) => {
  const nav = _.cloneDeep(currentWebsite.nav);
  nav.id = "navigation";
  const footer = _.cloneDeep(currentWebsite.footer);
  footer.id = "footer";
  const pageComponents = _.get(
    currentWebsitePage,
    "page_components.data.schema",
    []
  );
  return [nav, ...pageComponents, footer];
};
