import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "./returnAuthHeaderForAJAX";

interface Data {
  client_id: string;
  redirect_uri: string;
}
interface ResponseData {
  code: string;
  code_verifier: string;
}
export const createOauth2GrantAsync = (
  accessToken: string,
  data: Data
): Promise<AxiosResponse<ResponseData>> => {
  return new Promise((resolve, reject) => {
    axios
      .post(api.oauth2.createGrant, data, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((response) => {
        reject(response);
      });
  });
};
