import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type CreateAdminAccessAsync = (payload: {
  accessToken: string;
  websiteId: string | number;
}) => Promise<AxiosResponse<any>>;

export const createAdminAccessAsync: CreateAdminAccessAsync = ({
  accessToken,
  websiteId,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = api.sharedAccess.createAdmin + "?indentifier=" + encodeURIComponent(websiteId);
    axios
      .post(apiUrl, {}, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
