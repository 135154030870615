import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

export const changeProPlanAsync = (
  accessToken: string,
  paddlePlanId: number
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    const apiUrl =
      api.account.changeProPlan.prefix +
      paddlePlanId +
      api.account.changeProPlan.postfix;
    axios
      .post(apiUrl, {}, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
