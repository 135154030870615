import actionsNames from "./actions";
import { MetaState } from "./types";

let initialState: MetaState = {
  items: [],
  dataFetched: false,
  cacheFetched: false,
  cache: "",
};

export const MetaReducer = (state: MetaState = initialState, action: any) => {
  switch (action.type) {
    case actionsNames.SAVE_META_DATA:
      return {
        ...state,
        items: [...action.payload],
      };

    case actionsNames.SAVE_CACHE:
      return {
        ...state,
        cache: action.payload,
      };

    case actionsNames.TOGGLE_META_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };

    case actionsNames.TOGGLE_CACHE_FETCHED:
      return {
        ...state,
        cacheFetched: action.payload,
      };

    default:
      return state;
  }
};
