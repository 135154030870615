export const buttonStyles = {
    'regular': {
        'typeTitle': 'regular', //the title of the style group

        'accentBg': 'accentBg', //a particular style example of this group
        'accentOutline': 'accentOutline',

        'altAccentBg': 'altAccentBg',
        'altAccentOutline': 'altAccentOutline',

        'blackBg': 'blackBg',
        'blackOutline': 'blackOutline',

        'whiteBg': 'whiteBg',
        'whiteOutline': 'whiteOutline',
    },
    'mobileAppStore': {
        'typeTitle': 'mobileAppStore',  //the title of the style group
        'ios': 'ios', //a particular style example of this group
        'android': 'android',
        'other': 'other'
    },
    'download': {
        'typeTitle': 'download',  //the title of the style group
        'windows': 'windows', //a particular style example of this group
        'osx': 'osx',
        'chrome': 'chrome',
        'other': 'other'
    },
    'ghost': {
        'typeTitle': 'ghost',  //the title of the style group

        'accentOutline': 'accentOutline',
        'altAccentOutline': 'altAccentOutline',
        'blackOutline': 'blackOutline',
        'whiteOutline': 'whiteOutline',
    },
    'pill': {
        'typeTitle': 'pill',  //the title of the style group
        'blue': 'blue', //a particular style example of this group
        'green': 'green',
        'black': 'black',
        'orange': 'orange',
        'purple': 'purple',
        'white': 'white',
    }
};