import React from "react";
import FontSizeMenu from "./FontSizeMenu";
import { Divider } from "antd";
import ButtonStyleMenu from "./ButtonStyleMenu";
import InputStyleMenu from "./InputStyleMenu";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../../store/websites/thunks";
import { GetCurrentStoreData } from "../../../store/websites/types";
import _ from "lodash";
import SearchbarStyleMenu from "./SearchbarStyleMenu";
import CardStyleMenu from "./CardStyleMenu";
import ContainerStyleMenu from "./ContainerStyleMenu";

interface Props {
  getCurrentStoreData: GetCurrentStoreData;
}

const StyleMenu = (props: Props): JSX.Element => {
  const {} = props;

  return (
    <div>
      <div>
        <div className="settings-box__input">
          <div
            className="settings-box__input_label"
            style={{ marginBottom: 12 }}
          >
            <h2 className="settings-box__title">Font size</h2>
          </div>
          <FontSizeMenu />
        </div>
        <Divider className="settings__divider" />
        <div className="settings-box__input">
          <div
            className="settings-box__input_label"
            style={{ marginBottom: 12 }}
          >
            <h2 className="settings-box__title">Button style</h2>
          </div>
          <ButtonStyleMenu />
        </div>
        <Divider className="settings__divider" />
        <div className="settings-box__input" style={{ paddingBottom: 32 }}>
          <div
            className="settings-box__input_label"
            style={{ marginBottom: 12 }}
          >
            <h2 className="settings-box__title">Input style</h2>
          </div>
          <InputStyleMenu />
        </div>
        <Divider className="settings__divider" />
        <div className="settings-box__input" style={{ paddingBottom: 32 }}>
          <div
            className="settings-box__input_label"
            style={{ marginBottom: 12 }}
          >
            <h2 className="settings-box__title">Card style</h2>
          </div>
          <CardStyleMenu />
        </div>
        <Divider className="settings__divider" />
        <div className="settings-box__input" style={{ paddingBottom: 32 }}>
          <div
            className="settings-box__input_label"
            style={{ marginBottom: 12 }}
          >
            <h2 className="settings-box__title">Spacing</h2>
          </div>
          <ContainerStyleMenu />
        </div>
      </div>
    </div>
  );
};

export default connect(null, { getCurrentStoreData })(StyleMenu);
