import React from "react";

interface Props {
  reasoning: string;
}

const ReasoningField = (props: Props): JSX.Element => {
  const { reasoning } = props;
  if (!reasoning) return null;
  return <div className="gpt-assistant__reasoning-field">{reasoning}</div>;
};

export default ReasoningField;
