import React from "react";
import SpinnerBox from "../../SpinnerBox";
import WebsitePagesDownloadButtons from "./WebsitePagesDownloadButtons";
import BlogHomePageDownloadButton from "./BlogHomePageDownloadButton";
import BlogPostsDownloadButtons from "./BlogPostsDownloadButtons";

interface Props {
  currentWebsitePages: any;
  currentWebsite: any;
  websitesPages: any;
  blogs: any;
  blogPosts: any;
}

const DownloadButtons = (props: Props): JSX.Element => {
  const {
    currentWebsitePages,
    currentWebsite,
    websitesPages,
    blogs,
    blogPosts,
  } = props;

  if (websitesPages.pagesWithoutComponentsFetched === false) {
    return <SpinnerBox text={"Pages are loading..."} />;
  }
  
  return (
    <>
      <WebsitePagesDownloadButtons
        currentWebsitePages={currentWebsitePages}
        currentWebsite={currentWebsite}
        websitesPages={websitesPages}
      />
      <BlogHomePageDownloadButton
        blogs={blogs}
        currentWebsite={currentWebsite}
        websitesPages={websitesPages}
      />
      <BlogPostsDownloadButtons
        blogPosts={blogPosts}
        currentWebsite={currentWebsite}
        blogs={blogs}
        websitesPages={websitesPages}
      />
    </>
  );
};

export default DownloadButtons;
