import React, { useEffect, useRef } from "react";
import { Button, Checkbox } from "antd";
import { buttonStyles } from "../../../data/buttonStyles";
import { draftJsEditorTitle } from "../../../data/constants";
import { InputProps } from "./interfaces";
import DebouncedInput from "../../DebouncedInput";
import DebouncedTextarea from "../../DebouncedTextarea";
import MyRichTextEditor from "../../MyRichTextEditor";
import { useCurrentInputData } from "./useCurrentInputData";

const Features = (props: InputProps): JSX.Element => {
  const {
    content,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
    textEditorType,
    revertToPlainHTML,
    displayMoveUpDownButtons,
    displayPictogramEditorOpener,
    displayButtonStyleEditorOpener,
    setState,
    state,
  } = props;

  const currentInputDataRef = useCurrentInputData(currentInputData);

  let featuresCount = content.items.length;

  let featuresInputs = content.items.map((item: any, key: number) => {
    let onChangeFeatureTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changeFeatureTitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeFeatureSubtitleHandler = (value: any) => {
      saveContentInStore(
        generator.changeFeatureSubtitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeFeatureTextHandler = (value: any) => {
      saveContentInStore(
        generator.changeFeatureText(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeFeatureHighlightedInfoHandler = (value: any) => {
      saveContentInStore(
        generator.changeFeatureHighlightedInfo(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };

    let onChangeFeatureButtonPillPrefixHandler = (value: any) => {
      saveContentInStore(
        generator.changeFeatureButtonPillPrefix(
          { [contentType]: currentInputDataRef.current },
          value,
          key
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeFeatureButtonTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changeFeatureButtonTitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeFeatureButtonHrefHandler = (value: any) => {
      saveContentInStore(
        generator.changeFeatureButtonHref(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };

    let returnFeatureTextField = () => {
      if (textEditorType && textEditorType === draftJsEditorTitle) {
        return (
          <div className="content-input__input">
            <MyRichTextEditor
              revertToPlainHTML={revertToPlainHTML}
              placeholder="Enter feature description"
              defaultValue={item.text}
              onChange={onChangeFeatureTextHandler}
            />
          </div>
        );
      } else {
        return (
          <DebouncedTextarea
            className="content-input__input"
            onChange={onChangeFeatureTextHandler}
            defaultValue={item.text}
            autosize={{ minRows: 1, maxRows: 6 }}
            placeholder="Enter feature description"
          />
        );
      }
    };

    return (
      <div
        className={
          "content-input__cluster " +
          (item.isHighlighted ? " content-input__cluster--accent " : "")
        }
        key={"features-" + key}
      >
        <Button
          onClick={(event: any) => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.deleteFeature(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          }}
          htmlType="button"
          className="content-input__control_cluster"
          shape="circle"
          title="Delete this feature"
          icon="cross"
          size="small"
        />

        {displayMoveUpDownButtons(
          key,
          featuresCount,
          () => {
            saveContentInStore(
              generator.moveFeatureUp(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          },
          () => {
            saveContentInStore(
              generator.moveFeatureDown(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          }
        )}

        {item.title !== "" &&
          currentInputDataRef.current.hasPictogram &&
          displayPictogramEditorOpener(item.pictogram, key)}

        <div className="content-input__group">
          <DebouncedInput
            className="content-input__input"
            onChange={onChangeFeatureTitleHandler}
            defaultValue={item.title}
            placeholder="Enter feature title"
          />

          {item.title !== "" && currentInputDataRef.current.hasSubtitle && (
            <DebouncedInput
              className="content-input__input"
              onChange={onChangeFeatureSubtitleHandler}
              defaultValue={item.subtitle}
              placeholder="Enter feature subtitle"
            />
          )}

          {item.title !== "" &&
            currentInputDataRef.current.hasText &&
            returnFeatureTextField()}

          {currentInputDataRef.current.hasHighlight && item.title !== "" && (
            <Checkbox
              className="content-input__input"
              checked={item.isHighlighted}
              onChange={(event: any) => {
                saveContentInStore(
                  generator.changeFeatureIsHighlighted(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    key,
                    event.target.checked
                  )
                );
                toggleNewEditionsDetected(true);
              }}
            >
              Highlighted item
            </Checkbox>
          )}

          {currentInputDataRef.current.hasHighlightInfo && item.isHighlighted && (
            <div className="content-input__input">
              <DebouncedInput
                onChange={onChangeFeatureHighlightedInfoHandler}
                defaultValue={item.highlightedInfo}
                placeholder="Enter highlighted badge text"
              />
            </div>
          )}
        </div>
        {item.title !== "" && currentInputDataRef.current.hasButton && (
          <div className="content-input__group">
            {item.button.href !== "" &&
              item.button.title !== "" &&
              displayButtonStyleEditorOpener(item.button, () => {
                setState({
                  ...state,
                  currentFeatureIndex: key, //we need to know the index of a feature to edit it's button. Same for any other countable elements with buttons or pictograms.
                  buttonStylesOptions: currentInputDataRef.current.buttonTypes,
                  currentButtonObject: item.button,
                });
              })}

            {/*If this button has an active styleType === 'pill' we show additional field - for prefix.*/}
            {item.button.href !== "" &&
              item.button.title !== "" &&
              item.button.settings.appearance.styleType ===
                buttonStyles.pill.typeTitle && (
                <DebouncedInput
                  onChange={onChangeFeatureButtonPillPrefixHandler}
                  defaultValue={item.button.pillPrefix}
                  placeholder="New"
                  className="content-input__input"
                />
              )}

            {item.title !== "" && (
              <DebouncedInput
                className="content-input__input"
                onChange={onChangeFeatureButtonTitleHandler}
                defaultValue={item.button.title}
                placeholder="Learn more"
              />
            )}
            {item.title !== "" && item.button.title !== "" && (
              <DebouncedInput
                className="content-input__input"
                onChange={onChangeFeatureButtonHrefHandler}
                defaultValue={item.button.href}
                placeholder="/learn-more"
              />
            )}
            {item.button.href !== "" && item.button.title !== "" && (
              <Checkbox
                className="content-input__input"
                checked={item.button.isTargetBlank}
                onChange={(event: any) => {
                  saveContentInStore(
                    generator.changeFeatureButtonIsTargetBlank(
                      {
                        [contentType]: currentInputDataRef.current,
                      },
                      key,
                      event.target.checked
                    )
                  );
                  toggleNewEditionsDetected(true);
                }}
              >
                Open in a new tab
              </Checkbox>
            )}
          </div>
        )}
      </div>
    );
  });
  return (
    <div>
      {featuresInputs}
      <Button
        size="small"
        style={{ marginBottom: 40 }}
        onClick={(event: any) => {
          toggleNewEditionsDetected(true);
          saveContentInStore(
            generator.addEmptyFeatureIfNeeded({
              [contentType]: currentInputDataRef.current,
            })
          );
        }}
        icon="plus"
      >
        Add item
      </Button>
    </div>
  );
};

export default Features;
