import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type DeletePageAsync = (payload: {
  accessToken: string;
  subdomain: string;
  pageUrl: string;
}) => Promise<AxiosResponse<any>>;

export const deletePageAsync: DeletePageAsync = ({
  accessToken,
  subdomain,
  pageUrl,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${api.websitePages.deleteWebsitePage.prefix}${subdomain}/${pageUrl}${api.websitePages.deleteWebsitePage.postfix}`;
    axios
      .delete(apiUrl, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
