import { Button, Icon, Input } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { api } from '../../data/urls';
import { CrispEvents } from '../../enums/AnalyticsEventsEnums';
import returnAuthHeaderForAJAX from '../../helpers/auth/returnAuthHeaderForAJAX';
import fireAnalyticsEvent from '../../helpers/editor/fireAnalyticsEvent';
import { getErrorKey } from '../../helpers/editor/getErrorKey';
import { getErrorValue } from '../../helpers/editor/getErrorValue';
import { handleBadWebsiteError } from '../../helpers/handleBadWebsiteError';
import { handleLimitedWebsiteAccessError } from '../../helpers/handleLimitedWebsiteAccessError';
import { openNotification } from '../../helpers/openNotification';
import './MultiUserAccessItem.css'

interface Props {
  auth: any,
  toggleSharedAccessChangeWaitingForServer: any,
  sharedAccesses: any,
  firstName: string,
  email: string,
  sharedAccessItemId: number,
  removeSharedAccessItemFromState: any,
}

class MultiUserAccessItem extends Component<Props> {
  deleteShareAccess = () => {
    this.props.toggleSharedAccessChangeWaitingForServer(true);

    let accessToken = this.props.auth.accessToken;
    let apiUrl = api.sharedAccess.delete.prefix + this.props.sharedAccessItemId.toString() + api.sharedAccess.delete.postfix;
    axios.delete(apiUrl, {...returnAuthHeaderForAJAX(accessToken)})
        .then(response => {
          this.props.removeSharedAccessItemFromState(this.props.sharedAccessItemId);
          
          fireAnalyticsEvent.fireCrisp(CrispEvents.removeSharedAccess, {
            shared_access_id: this.props.sharedAccessItemId,
          });
        })
        .catch(error => {
            // handle error
            if(error.response){
                let errorData = error.response.data;

                let errorObjectKey = getErrorKey(errorData);
                let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                fireAnalyticsEvent.fireCrisp(CrispEvents.removeSharedAccessError, {
                  error_type: errorObjectKey,
                  error_message: errorObjectValue,
                  shared_access_id: this.props.sharedAccessItemId,
                }, true);
                
                handleBadWebsiteError(errorData);
                handleLimitedWebsiteAccessError(errorData);

                if (errorData.detail !== undefined){
                    let message = errorData.detail;

                    // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
                    openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                }
            }
        })
        .then(response => {
            // always executed
            this.props.toggleSharedAccessChangeWaitingForServer(false);
        });
};

  render() {
    return (
      <div className="multi_user_access_item">
        <Input
          disabled
          placeholder="Enter email"
          value={this.props.email}
          type="email"
          prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
        />
        <Button title={"Revoke access for " + this.props.email} onClick={this.deleteShareAccess} style={{ marginLeft: 15 }} ghost type="danger" icon="user-delete" disabled={this.props.sharedAccesses.isWaitingForChangeSharedAccessResponse}></Button>
      </div>
    );
  }
}

export default MultiUserAccessItem;
