import { Button, Divider, Modal } from "antd";
import React, { useState } from "react";
import DeleteAccountForm from "./DeleteAccountForm";
import { connect } from "react-redux";
import { logout } from "../../../../store/auth/actions";

interface Props {
  user: any;
  logout: (isAccountDeleted?: boolean) => void;
}

const DeleteAccountMenu = (props: Props): JSX.Element => {
  const { user, logout } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentEmail, setCurrentEmail] = useState<string>("");

  const showModal = () => {
    setCurrentEmail("");
    setIsModalVisible(true);
  };

  const handleOnCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="account_fields__row" style={{ marginBottom: "20px" }}>
        <div className="account_fields__title">Log out of this device</div>
        <div className="account_fields__action">
          <Button
            onClick={(e) => {
              logout();
            }}
          >
            Log out
          </Button>
        </div>
      </div>
      <div className="account_fields__row">
        <div
          className="account_fields__title"
          style={{ color: "var(--blood)" }}
        >
          Danger zone
        </div>
        <div className="account_fields__action">
          <Button
            type="danger"
            ghost
            onClick={showModal}
            loading={user.isDeleteAccountInProgress}
          >
            Delete account
          </Button>
        </div>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleOnCancel}
        title="Delete account"
        footer={null}
        width={350}
      >
        <div>
          <div className="account_fields__second_warning_text">
            <p className="paragraph-custom">
              <b>Warning:</b> If you proceed, all your websites, pages, blogs,
              and associated subscriptions will be permanently deleted and
              cannot be recovered. This action is irreversible.
            </p>
            <p className="paragraph-custom">
              To verify, please enter your email address:{" "}
              <b>{user.data.email}</b>
            </p>
          </div>
          <DeleteAccountForm
            setCurrentEmail={setCurrentEmail}
            currentEmail={currentEmail}
            handleOnCancel={handleOnCancel}
          />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return { user };
};
export default connect(mapStateToProps, { logout })(DeleteAccountMenu);
