import React, { Component } from "react";
import { Link } from "react-router-dom";
import SettingsBox from "./SettingsBox";
import { Button, Icon, Popover, Empty, Badge, Divider } from "antd";
import SpinnerBox from "./SpinnerBox";
import {
  googleFontsEnabledClassname,
  projectTitle,
} from "../../data/constants";
import UpdateSubdomainForm from "./UpdateSubdomainForm";
import PublishPages from "./PublishPages";
import AddWebsiteCustomCodeForm from "./AddWebsiteCustomCodeForm";
import ColorPaletteItem from "./ColorPaletteItem";
import { websiteColors } from "../../data/websiteColors";
import FontOptionItem from "./FontOptionItem";
import { websiteFontOptions } from "../../data/websiteFontOptions";
import FaviconUploader from "./FaviconUploader";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { handleUpgradeToUnlock } from "../../helpers/editor/handleUpgradeToUnlock";
import OpenGraphImageUploader from "./OpenGraphImageUploader";
import { urls } from "../../data/urls";
import HtmlExport from "./html_export/HtmlExport";
import CustomColorPalette from "./CustomColorPalette";
import CustomFonts from "./CustomFonts";
import { checkIfObjectEmpty } from "../../helpers/checkIfObjectEmpty";
import WebsiteSEOsettings from "./WebsiteSEOsettings";
import { getFullWebsiteUrl } from "../../helpers/editor/getFullWebsiteUrl";
import { api } from "../../data/urls";
import EditMultiUserAccess from "./EditMultiUserAccess";
import capitalizeString from "../../helpers/strings/capitalizeString";
import filterOnlyCurrentWebsiteSharedAccesses from "../../helpers/shareAccess/filterOnlyCurrentWebsiteSharedAccesses";
import isWebsiteSharedWithCurrentUser from "../../helpers/shareAccess/isWebsiteSharedWithCurrentUser";
import WebsiteWidgets from "./WebsiteWidgets";
import { connect } from "react-redux";
import {
  toggleSharedAccessChangeWaitingForServer,
  addNewSharedAccessItemInState,
  removeSharedAccessItemFromState,
} from "../../store/sharedAccesses/actions";
import {
  toggleWebsitePageChangeInProgress,
  replaceWebsitesPagesInState,
} from "../../store/websitePages/actions";
import {
  subdomainUpdateWaitingForServer,
  subdomainUpdateToggleInvalidAttempt,
  subdomainUpdateErrorMessage,
  toggleWebsitesFetched,
  customDomainUpdateWaitingForServer,
  customDomainUpdateToggleInvalidAttempt,
  customDomainUpdateErrorMessage,
  sslToggleActivationWaitingForServer,
  websiteCustomCodeUpdateWaitingForServer,
  websiteCustomCodeUpdateToggleInvalidAttempt,
  websiteCustomCodeUpdateErrorMessage,
  websiteExtraCustomCodeUpdateWaitingForServer,
  websiteExtraCustomCodeUpdateToggleInvalidAttempt,
  websiteExtraCustomCodeUpdateErrorMessage,
  customDomainToggleActivationWaitingForServer,
  enableBrandingWaitingForServer,
  toggleWaitingForResponseOnColorPaletteChange,
  toggleWaitingForResponseOnFontChange,
  toggleWaitingForCommonWebsiteUpdate,
  toggleCloudflareProtectionWaitingForServer,
  toggleWaitingForWebsiteLanguagesUpdate,
} from "../../store/websites/actions";
import { ActiveMenu } from "./SettingsDrawerContent";
import { getCurrentStoreData, saveWebsiteBackup } from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import CustomSitemapUrlsSettings from "./CustomSitemapUrlsSettings";
import _ from "lodash";
import StyleMenu from "./style_menu/StyleMenu";
import CustomDomainSettingsNew from "./CustomDomainSettingsNew";

interface Props {
  websitesPages: any;
  websites: any;
  auth: any;
  user: any;
  plans: any;
  blogs: any;
  blogPosts: any;
  sharedAccesses: any;
  subdomainUpdateWaitingForServer: any;
  subdomainUpdateToggleInvalidAttempt: any;
  subdomainUpdateErrorMessage: any;
  toggleWebsitesFetched: any;
  customDomainUpdateWaitingForServer: any;
  customDomainUpdateToggleInvalidAttempt: any;
  customDomainUpdateErrorMessage: any;
  sslToggleActivationWaitingForServer: any;
  websiteCustomCodeUpdateWaitingForServer?: any;
  websiteCustomCodeUpdateToggleInvalidAttempt?: any;
  websiteCustomCodeUpdateErrorMessage?: any;
  websiteExtraCustomCodeUpdateWaitingForServer?: any;
  websiteExtraCustomCodeUpdateToggleInvalidAttempt?: any;
  websiteExtraCustomCodeUpdateErrorMessage?: any;
  customDomainToggleActivationWaitingForServer: any;
  toggleWebsitePageChangeInProgress: any;
  replaceWebsitesPagesInState: any;
  enableBrandingWaitingForServer: any;
  toggleWaitingForResponseOnColorPaletteChange: any;
  toggleWaitingForResponseOnFontChange: any;
  toggleWaitingForCommonWebsiteUpdate: any;
  toggleCloudflareProtectionWaitingForServer: any;
  toggleSharedAccessChangeWaitingForServer: any;
  addNewSharedAccessItemInState: any;
  removeSharedAccessItemFromState: any;
  toggleWaitingForWebsiteLanguagesUpdate: any;

  removeImageFromDB: any;
  saveImageInfoInDB: any; //for favicons
  history: any;
  publishPage: any;
  saveWebsitePageDataInStore: any;
  saveWebsiteDataInStore: any;
  currentMenuTitle: ActiveMenu;
  setActiveMenu: React.Dispatch<React.SetStateAction<ActiveMenu>>;
  getCurrentStoreData: GetCurrentStoreData;
  saveWebsiteBackup: any;
}
type MyState = {
  doesCurrentUserHaveAccessToPaidFeatures: boolean;
  currentWebsite: any;
  isWebsiteSharedWithCurrentUserCheckResult: any;
};

class WebsiteSettings extends Component<Props, MyState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      doesCurrentUserHaveAccessToPaidFeatures: false,
      currentWebsite: undefined,
      isWebsiteSharedWithCurrentUserCheckResult: undefined,
    };
  }
  componentWillReceiveProps(nextProps) {
    // a user can access to a paid features in 2 cases:
    // if the user is on a paid plan
    // or if a user is editing a website of other paid user.
    let websitesArray = nextProps.websites.items;
    let currentWebsite = getCurrentWebsite(websitesArray);
    if (currentWebsite !== undefined) {
      let isWebsiteSharedWithCurrentUserCheckResult =
        isWebsiteSharedWithCurrentUser(
          this.props.sharedAccesses,
          currentWebsite.id,
          this.props.user.data.email
        );
      this.setState({
        doesCurrentUserHaveAccessToPaidFeatures:
          nextProps.user.isPro ||
          isWebsiteSharedWithCurrentUserCheckResult.isTrue,
        currentWebsite: currentWebsite,
        isWebsiteSharedWithCurrentUserCheckResult:
          isWebsiteSharedWithCurrentUserCheckResult,
      });
    }
  }
  componentDidMount(): void {
    //We want to remove all errors from inputs on render.
    this.clearUpdateSubdomainInput();
    this.clearUpdateCustomDomainInput();
    this.clearUpdateCustomCodeInput();
  }
  clearUpdateSubdomainInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.subdomainUpdateToggleInvalidAttempt(false);
    this.props.subdomainUpdateErrorMessage(undefined);
  };

  clearUpdateCustomDomainInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.customDomainUpdateToggleInvalidAttempt(false);
    this.props.customDomainUpdateErrorMessage(undefined);
  };

  clearUpdateCustomCodeInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.websiteCustomCodeUpdateToggleInvalidAttempt(false);
    this.props.websiteCustomCodeUpdateErrorMessage(undefined);
  };

  clearUpdateExtraCustomCodeInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.websiteExtraCustomCodeUpdateToggleInvalidAttempt(false);
    this.props.websiteExtraCustomCodeUpdateErrorMessage(undefined);
  };

  displayMultiUserAccessControl = (currentWebsite: any) => {
    let tooltipMessage: any;
    if (this.state.doesCurrentUserHaveAccessToPaidFeatures === false) {
      tooltipMessage = (
        <span>
          <Link to="/plan">
            <Button htmlType="button" icon="star" ghost size="small">
              Upgrade
            </Button>
          </Link>{" "}
          to remove {projectTitle} branding 🦄
        </span>
      );
    } else {
      tooltipMessage =
        projectTitle +
        " progress is highly depends on the branding. It helps us to develop the product faster and ship more awesome features for you. We'd appreciate keeping our unremarkable link in the footer of your website 🙂";
    }

    let returnGiveMultiUserAccess = (
      listOfSharedAccessOfThisWebsiteOnly: any
    ) => {
      let doesCurrentUserEmailIsInTheSharedAccessesList =
        this.state.isWebsiteSharedWithCurrentUserCheckResult.isTrue;
      // Only pro users can manage disable branding badge
      if (doesCurrentUserEmailIsInTheSharedAccessesList) {
        return (
          <div className="settings-box__shared_website_restriction_box">
            <Icon
              style={{ color: "var(--blood)", marginRight: 3 }}
              type="close-circle"
            />{" "}
            You can not give or remove access to a shared website.
          </div>
        );
      } else if (this.state.doesCurrentUserHaveAccessToPaidFeatures) {
        return (
          <EditMultiUserAccess
            currentWebsite={currentWebsite}
            websites={this.props.websites}
            auth={this.props.auth}
            user={this.props.user}
            doesCurrentUserHaveAccessToPaidFeatures={
              this.state.doesCurrentUserHaveAccessToPaidFeatures
            }
            toggleSharedAccessChangeWaitingForServer={
              this.props.toggleSharedAccessChangeWaitingForServer
            }
            sharedAccesses={this.props.sharedAccesses}
            addNewSharedAccessItemInState={
              this.props.addNewSharedAccessItemInState
            }
            removeSharedAccessItemFromState={
              this.props.removeSharedAccessItemFromState
            }
            listOfSharedAccessOfThisWebsiteOnly={
              listOfSharedAccessOfThisWebsiteOnly
            }
          />
          // <div></div>
        );
      } else {
        return (
          <div className="settings-box__form">
            <Button
              onClick={() => {
                this.props.setActiveMenu("account__plan");
              }}
              icon="star"
              type="primary"
              size="large"
              htmlType="button"
            >
              Upgrade to add users
            </Button>
          </div>
        );
      }
    };

    const currentWebsiteSubdomain = capitalizeString(currentWebsite.subdomain);
    let listOfSharedAccessOfThisWebsiteOnly =
      filterOnlyCurrentWebsiteSharedAccesses(
        this.props.sharedAccesses,
        currentWebsite.id
      );

    let sharedAccessesBadgeCount = 0;
    let shouldDisplaySharedAccessesCountBadge =
      listOfSharedAccessOfThisWebsiteOnly !== undefined;
    if (shouldDisplaySharedAccessesCountBadge) {
      sharedAccessesBadgeCount = listOfSharedAccessOfThisWebsiteOnly.length;
    }

    return (
      <div className="settings-box__input">
        <div className="settings-box__input_label">
          <div>
            <Popover
              content={
                <div>
                  Give other users (managers, clients or friends) full edit
                  access to the website.
                  <br />
                  <br /> Added users will be able:
                  <ul
                    className="settings-box__features_list"
                    style={{ marginTop: 5 }}
                  >
                    <li className="settings-box__features_list_item">
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#4ADDB4"
                      />
                      View <b>{currentWebsiteSubdomain}</b> pages.
                    </li>
                    <li className="settings-box__features_list_item">
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#4ADDB4"
                      />
                      Edit the <b>{currentWebsiteSubdomain}</b> website pages
                      content.
                    </li>
                    <li className="settings-box__features_list_item">
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#4ADDB4"
                      />
                      Duplicate the <b>{currentWebsiteSubdomain}</b> website and
                      own a copy of it on their account.
                    </li>
                    <li className="settings-box__features_list_item">
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#4ADDB4"
                      />
                      Edit settings of the website and its website pages.
                    </li>
                    <li className="settings-box__features_list_item">
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#4ADDB4"
                      />
                      Create, publish, clone and delete pages.
                    </li>
                    <li className="settings-box__features_list_item">
                      <Icon
                        type="check-circle"
                        theme="twoTone"
                        twoToneColor="#4ADDB4"
                      />
                      Same for the <b>{currentWebsiteSubdomain}</b> website
                      blog: view, edit and manage blog and blog posts.
                    </li>
                  </ul>
                  Added users will <b>not</b> be able:
                  <br />
                  <ul
                    className="settings-box__features_list"
                    style={{ marginTop: 5 }}
                  >
                    <li className="settings-box__features_list_item">
                      <Icon
                        type="close-circle"
                        theme="twoTone"
                        twoToneColor="#D0021B"
                      />
                      Delete the <b>{currentWebsiteSubdomain}</b> website or its
                      blog.
                    </li>
                    <li className="settings-box__features_list_item">
                      <Icon
                        type="close-circle"
                        theme="twoTone"
                        twoToneColor="#D0021B"
                      />
                      Change the collaborators access setting.
                    </li>
                  </ul>
                </div>
              }
            >
              <Icon type="info-circle" />
            </Popover>
          </div>
          <div className="settings-box__input_info_text">
            Invite people to collaborate on the website:
            <span
              style={{ marginLeft: 15, position: "relative", bottom: "13px" }}
            >
              {/* Need to keep the empty div inside the badge to enable the badge appearing animation. I do not know why this works in this way. */}
              {/* We do not want to show the counter of given acceses to the website if the website does not belong to the current user, but was shared with them. */}
              {/* Anyway, the manager of a website will not see the actual list of other managers to keep their emails confidential. */}
              {this.state.isWebsiteSharedWithCurrentUserCheckResult.isTrue ===
                false && (
                <Badge
                  title={"Added editors: " + sharedAccessesBadgeCount}
                  style={{ backgroundColor: "var(--ant)" }}
                  count={sharedAccessesBadgeCount}
                >
                  <div></div>
                </Badge>
              )}
            </span>
          </div>
        </div>

        {returnGiveMultiUserAccess(listOfSharedAccessOfThisWebsiteOnly)}
      </div>
    );
  };

  displayPublishPages = (currentWebsite: any) => {
    let currentWebsiteId = currentWebsite.id;

    let allWebsitePages = this.props.websitesPages.items;
    let currentWebsitePages = [];

    // Gather all pages of the current website.
    let websitePagesCount = allWebsitePages.length;
    for (let i = 0; i < websitePagesCount; i++) {
      let websitePage = allWebsitePages[i];
      if (websitePage.website === currentWebsiteId) {
        currentWebsitePages.push(websitePage);
      }
    }
    // If the website has no pages, display nothing.
    if (currentWebsitePages.length === 0) {
      return null;
    } else {
      return (
        <div className="settings-box__input">
          <div className="settings-box__input_label">
            <div>
              <Popover
                content={
                  <div>
                    By publishing you make your pages available for everyone to
                    visit.
                    <br />
                    <span style={{ opacity: 0.7 }}>
                      {" "}
                      Unpublish all the pages to hide the whole website.
                    </span>
                  </div>
                }
              >
                <Icon type="info-circle" />
              </Popover>
            </div>
            <div className="settings-box__input_info_text">Publish pages:</div>
          </div>
          <PublishPages
            auth={this.props.auth}
            currentWebsitePages={currentWebsitePages}
            toggleWebsitePageChangeInProgress={
              this.props.toggleWebsitePageChangeInProgress
            }
            websitesPages={this.props.websitesPages}
            websites={this.props.websites}
            currentWebsite={currentWebsite}
            replaceWebsitesPagesInState={this.props.replaceWebsitesPagesInState}
            saveWebsitePageDataInStore={this.props.saveWebsitePageDataInStore}
            publishPage={this.props.publishPage}
          />
        </div>
      );
    }
  };

  displayHtmlExport = (currentWebsite: any) => {
    let currentWebsiteId = currentWebsite.id;

    let allWebsitePages = this.props.websitesPages.items;
    let currentWebsitePages = [];

    // Gather all pages of the current website.
    let websitePagesCount = allWebsitePages.length;
    for (let i = 0; i < websitePagesCount; i++) {
      let websitePage = allWebsitePages[i];
      if (websitePage.website === currentWebsiteId) {
        currentWebsitePages.push(websitePage);
      }
    }
    // If the website has no pages, display nothing.
    if (currentWebsitePages.length === 0) {
      return null;
    } else {
      return (
        <div className="settings-box__input">
          <HtmlExport
            auth={this.props.auth}
            currentWebsitePages={currentWebsitePages}
            websitesPages={this.props.websitesPages}
            websites={this.props.websites}
            user={this.props.user}
            blogs={this.props.blogs}
            blogPosts={this.props.blogPosts}
            history={this.props.history}
            currentWebsite={currentWebsite}
            doesCurrentUserHaveAccessToPaidFeatures={
              this.state.doesCurrentUserHaveAccessToPaidFeatures
            }
            setActiveMenu={this.props.setActiveMenu}
          />
        </div>
      );
    }
  };

  displayFontOptions = (currentWebsite: any) => {
    if (currentWebsite !== undefined) {
      let fontOptions = websiteFontOptions.map((item: any, key: number) => {
        return (
          <FontOptionItem
            fontClassname={item.fontClassname}
            fontTitle={item.fontTitle}
            fontInfo={item.fontInfo}
            key={key}
            currentWebsite={currentWebsite}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            websites={this.props.websites}
            auth={this.props.auth}
            toggleWaitingForResponseOnFontChange={
              this.props.toggleWaitingForResponseOnFontChange
            }
          />
        );
      });

      // check if cust font enalbed
      // if yes - prefill pass the current custom fonts to set initial state

      let activeTitlesFontFamily = "Lato";
      let activeTextFontFamily = "Lato";

      let customFontsActive =
        currentWebsite.active_font_classname === googleFontsEnabledClassname;
      if (customFontsActive) {
        let customFontsSet = currentWebsite.custom_fonts_set;
        if (customFontsSet && checkIfObjectEmpty(customFontsSet) === false) {
          // if customFontsSet{} exists and it is not an empty object
          activeTitlesFontFamily = customFontsSet.title;
          activeTextFontFamily = customFontsSet.text;
        }
      }

      return (
        <div className="settings-box__fonts">
          {fontOptions}
          <CustomFonts
            currentWebsite={currentWebsite}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            websites={this.props.websites}
            auth={this.props.auth}
            toggleWaitingForResponseOnFontChange={
              this.props.toggleWaitingForResponseOnFontChange
            }
            activeTitlesFontFamily={activeTitlesFontFamily}
            activeTextFontFamily={activeTextFontFamily}
          />
        </div>
      );
    }
  };

  displayColorPalettes = (currentWebsite: any) => {
    if (currentWebsite !== undefined) {
      let colorPalettes = websiteColors.map((item: any, key: number) => {
        return (
          <ColorPaletteItem
            styleClassname={item.styleClassname}
            styleTitle={item.styleTitle}
            key={key}
            currentWebsite={currentWebsite}
            saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
            websites={this.props.websites}
            auth={this.props.auth}
            toggleWaitingForResponseOnColorPaletteChange={
              this.props.toggleWaitingForResponseOnColorPaletteChange
            }
          />
        );
      });

      let isCurrentWebsiteHasCustomPaletteActive = false;
      if (
        currentWebsite.custom_color_palette &&
        currentWebsite.custom_color_palette.isCustomPaletteActivated === true
      ) {
        isCurrentWebsiteHasCustomPaletteActive = true;
      }

      return (
        <div className="settings-box__palettes">
          <div className="settings-box__palettes_box">
            {colorPalettes}
            <CustomColorPalette
              isCurrentWebsiteHasCustomPaletteActive={
                isCurrentWebsiteHasCustomPaletteActive
              }
              currentWebsite={currentWebsite}
              saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
              websites={this.props.websites}
              auth={this.props.auth}
              toggleWaitingForResponseOnColorPaletteChange={
                this.props.toggleWaitingForResponseOnColorPaletteChange
              }
            />
          </div>
        </div>
      );
    }
  };

  render() {
    if (
      this.props.websites.dataFetched &&
      this.props.websitesPages.pagesWithoutComponentsFetched
    ) {
      // After the form is rendered, it fills in default values for the inputs. That means if user entered something and don't save that, his changes may disappear.
      // let websitesArray = this.props.websites.items;
      // let currentWebsite = getCurrentWebsite(websitesArray);
      let currentWebsite = this.state.currentWebsite;

      if (currentWebsite === undefined) {
        // If no such website, offer to go to the main dashboard screen.
        return (
          <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
            <Empty description={<span>Website not found</span>}>
              <Link to="/">
                <Button type="primary">Back to your websites</Button>
              </Link>
            </Empty>
          </div>
        );
      }

      let currentWebsiteSubdomain = currentWebsite.subdomain;
      let currentWebsiteCustomDomain = currentWebsite.custom_domain;
      let fullWebsiteUrl = getFullWebsiteUrl(currentWebsite);
      const { currentMenuTitle } = this.props;
      const { currentBlogPost } = this.props.getCurrentStoreData();
      const isLegacyCodeVisible = _.get(currentWebsite, "custom_html_code") || _.get(currentWebsite, "extra_custom_html_code");

      if (currentMenuTitle === "websiteSettings") {
        return (
          <div className="settings__container">
            <SettingsBox titleText="General settings">
              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          How your website will be <br />
                          accessible for visitors.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Subdomain:
                  </div>
                </div>

                <UpdateSubdomainForm
                  currentWebsiteSubdomain={currentWebsiteSubdomain}
                  history={this.props.history}
                />
              </div>

              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Favicon is a small icon placed <br />
                          in top of a browser tab.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">Favicon:</div>
                </div>

                <FaviconUploader
                  settingsType="website"
                  saveDataInStore={this.props.saveWebsiteDataInStore}
                  auth={this.props.auth}
                  apiUrl={
                    api.websites.updateWebsite.prefix +
                    currentWebsite.subdomain +
                    api.websites.updateWebsite.postfix
                  }
                  currentWebsite={currentWebsite}
                  saveImageInfoInDB={this.props.saveImageInfoInDB}
                  removeImageFromDB={this.props.removeImageFromDB}
                />
              </div>

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div style={{ width: 308 }}>
                          Displayed when your website is shared on social media.
                          Default for each page, unless overridden. Recommended
                          size: 1200x630px.
                          <br />
                          <br />
                          If you don't specify any image, we'll look for one in
                          your site's content.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Website social image:{" "}
                    <span className="settings-box__input_info_subtext">
                      a.k.a. Open Graph image
                    </span>
                  </div>
                </div>

                <OpenGraphImageUploader
                  settingsType={"website"}
                  saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                  auth={this.props.auth}
                  currentWebsite={currentWebsite}
                  saveImageInfoInDB={this.props.saveImageInfoInDB}
                  removeImageFromDB={this.props.removeImageFromDB}
                  apiUrl={
                    api.websites.updateWebsite.prefix +
                    currentWebsiteSubdomain +
                    api.websites.updateWebsite.postfix
                  }
                  displayedSettingsType={
                    currentBlogPost ? "blogPost" : "website"
                  }
                />
              </div>

              <Divider className="settings__divider" />

              <WebsiteSEOsettings
                auth={this.props.auth}
                user={this.props.user}
                currentWebsite={currentWebsite}
                websites={this.props.websites}
                saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                fullWebsiteUrl={fullWebsiteUrl}
                toggleWaitingForCommonWebsiteUpdate={
                  this.props.toggleWaitingForCommonWebsiteUpdate
                }
                toggleWaitingForWebsiteLanguagesUpdate={
                  this.props.toggleWaitingForWebsiteLanguagesUpdate
                }
              />

              <Divider className="settings__divider" />

              {/* We removed it, but we still keep it visible for the users who already used it. */}
              {_.get(currentWebsite, "custom_sitemap.items", []).length > 0 && (
                <>
                  <CustomSitemapUrlsSettings />
                  <Divider className="settings__divider" />
                </>
              )}

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Add HTML code before the closing &lt;/head&gt; tag.
                          <br/>
                          The code will be added to all pages and blog posts.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Global &lt;head&gt; HTML code:
                  </div>
                </div>

                <AddWebsiteCustomCodeForm
                  websites={this.props.websites}
                  auth={this.props.auth}
                  user={this.props.user}
                  history={this.props.history}
                  html_code_field={"global_head_html"}
                  waitingForServer={
                    this.props.websiteCustomCodeUpdateWaitingForServer
                  }
                  toggleInvalidAttempt={
                    this.props.websiteCustomCodeUpdateToggleInvalidAttempt
                  }
                  errorMessage={this.props.websiteCustomCodeUpdateErrorMessage}
                  saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                  currentWebsite={currentWebsite}
                  clearInput={this.clearUpdateCustomCodeInput}
                  isWaitingForServer={
                    this.props.websites.isWaitingForWebsiteCustomCodeUpdateResponse
                  }
                  saveWebsiteBackup={this.props.saveWebsiteBackup}
                />
              </div>

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Add HTML code before the closing &lt;/body&gt; tag.
                          <br/>
                          The code will be added to all pages and blog posts.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Global &lt;body&gt; HTML code:
                  </div>
                </div>

                <AddWebsiteCustomCodeForm
                  websites={this.props.websites}
                  auth={this.props.auth}
                  user={this.props.user}
                  history={this.props.history}
                  html_code_field={"global_body_html"}
                  waitingForServer={
                    this.props.websiteExtraCustomCodeUpdateWaitingForServer
                  }
                  toggleInvalidAttempt={
                    this.props.websiteExtraCustomCodeUpdateToggleInvalidAttempt
                  }
                  errorMessage={
                    this.props.websiteExtraCustomCodeUpdateErrorMessage
                  }
                  saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                  currentWebsite={currentWebsite}
                  clearInput={this.clearUpdateExtraCustomCodeInput}
                  isWaitingForServer={
                    this.props.websites
                      .isWaitingForWebsiteExtraCustomCodeUpdateResponse
                  }
                  saveWebsiteBackup={this.props.saveWebsiteBackup}
                />
              </div>

              {isLegacyCodeVisible && <>
              <Divider className="settings__divider" />

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Add HTML code to your websites{" "}
                          <code>&lt;head&gt;</code>. You can <br />
                          also use <code>&lt;script&gt;</code> and{" "}
                          <code>&lt;style&gt;</code> HTML tags. <br />
                          <br />
                          Example use cases: Google Domain Verification process,{" "}
                          <br />
                          Google Analytics.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Website &lt;head&gt; HTML code (legacy):
                  </div>
                </div>

                <AddWebsiteCustomCodeForm
                  websites={this.props.websites}
                  auth={this.props.auth}
                  user={this.props.user}
                  history={this.props.history}
                  html_code_field={"custom_html_code"}
                  waitingForServer={
                    this.props.websiteCustomCodeUpdateWaitingForServer
                  }
                  toggleInvalidAttempt={
                    this.props.websiteCustomCodeUpdateToggleInvalidAttempt
                  }
                  errorMessage={this.props.websiteCustomCodeUpdateErrorMessage}
                  saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                  currentWebsite={currentWebsite}
                  clearInput={this.clearUpdateCustomCodeInput}
                  isWaitingForServer={
                    this.props.websites.isWaitingForWebsiteCustomCodeUpdateResponse
                  }
                  saveWebsiteBackup={this.props.saveWebsiteBackup}
                />
              </div>

              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Add HTML code at the bottom, right before the closing{" "}
                          <code>&lt;/body&gt;</code> tag.
                          <br />
                          You can also use <code>&lt;script&gt;</code> and{" "}
                          <code>&lt;style&gt;</code> HTML tags. <br />
                          <br />
                          Use it for Intercom or other widgets.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Website &lt;body&gt; HTML code (legacy):
                  </div>
                </div>

                <AddWebsiteCustomCodeForm
                  websites={this.props.websites}
                  auth={this.props.auth}
                  user={this.props.user}
                  history={this.props.history}
                  html_code_field={"extra_custom_html_code"}
                  waitingForServer={
                    this.props.websiteExtraCustomCodeUpdateWaitingForServer
                  }
                  toggleInvalidAttempt={
                    this.props.websiteExtraCustomCodeUpdateToggleInvalidAttempt
                  }
                  errorMessage={
                    this.props.websiteExtraCustomCodeUpdateErrorMessage
                  }
                  saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                  currentWebsite={currentWebsite}
                  clearInput={this.clearUpdateExtraCustomCodeInput}
                  isWaitingForServer={
                    this.props.websites
                      .isWaitingForWebsiteExtraCustomCodeUpdateResponse
                  }
                  saveWebsiteBackup={this.props.saveWebsiteBackup}
                />
              </div>
              </>}

            </SettingsBox>
          </div>
        );
      }
      if (currentMenuTitle === "collaborators") {
        return (
          <div className="settings__container">
            <SettingsBox titleText="Collaborators">
              {this.displayMultiUserAccessControl(currentWebsite)}
            </SettingsBox>
          </div>
        );
      }
      if (currentMenuTitle === "customDomain") {
        return (
          <div className="settings__container">
            <SettingsBox titleText="Custom domain">
              <CustomDomainSettingsNew
                history={this.props.history}
                currentWebsite={currentWebsite}
                saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                doesCurrentUserHaveAccessToPaidFeatures={
                  this.state.doesCurrentUserHaveAccessToPaidFeatures
                }
                setActiveMenu={this.props.setActiveMenu}
              />
            </SettingsBox>
          </div>
        );
      }
      if (currentMenuTitle === "colorsAndFonts") {
        return (
          <div className="settings__container">
            <SettingsBox titleText="Website theme">
              <div className="settings-box__input" style={{ marginBottom: 0 }}>
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>Change color palette of the whole website. </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Color palette:
                  </div>
                </div>
                {this.displayColorPalettes(currentWebsite)}
              </div>
              <div className="settings-box__input">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>
                          Change fonts on each page of "
                          {currentWebsiteSubdomain}".
                          <br /> The collection was carefully created by the
                          Unicorn Platform designers.
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">Fonts:</div>
                </div>

                {this.displayFontOptions(currentWebsite)}
              </div>
              <Divider className="settings__divider" />
              <StyleMenu />
            </SettingsBox>
          </div>
        );
      }
      if (currentMenuTitle === "widgets") {
        return (
          <div className="settings__container">
            <SettingsBox titleText="Widgets">
              <WebsiteWidgets
                auth={this.props.auth}
                user={this.props.user}
                currentWebsite={currentWebsite}
                saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                fullWebsiteUrl={fullWebsiteUrl}
                websites={this.props.websites}
              />
            </SettingsBox>
          </div>
        );
      }
      if (currentMenuTitle === "exportHtml") {
        return (
          <div className="settings__container">
            <SettingsBox titleText="Export HTML">
              {this.displayHtmlExport(currentWebsite)}
            </SettingsBox>
          </div>
        );
      }
      return null;
    } else {
      return <SpinnerBox text="Website settings are loading..." />;
    }
  }
}

const mapStateToProps = (state: any) => {
  return {
    websitesPages: state.websitesPages,
    websites: state.websites,
    auth: state.auth,
    user: state.user,
    sharedAccesses: state.sharedAccesses,
    blogs: state.blogs,
    blogPosts: state.blogPosts,
    plans: state.plans,
  };
};
const mapActionsToProps = {
  subdomainUpdateWaitingForServer,
  subdomainUpdateToggleInvalidAttempt,
  subdomainUpdateErrorMessage,
  toggleWebsitesFetched,
  customDomainUpdateWaitingForServer,
  customDomainUpdateToggleInvalidAttempt,
  customDomainUpdateErrorMessage,
  sslToggleActivationWaitingForServer,
  websiteCustomCodeUpdateWaitingForServer,
  websiteCustomCodeUpdateToggleInvalidAttempt,
  websiteCustomCodeUpdateErrorMessage,
  websiteExtraCustomCodeUpdateWaitingForServer,
  websiteExtraCustomCodeUpdateToggleInvalidAttempt,
  websiteExtraCustomCodeUpdateErrorMessage,
  customDomainToggleActivationWaitingForServer,
  toggleWebsitePageChangeInProgress,
  replaceWebsitesPagesInState,
  enableBrandingWaitingForServer,
  toggleWaitingForResponseOnColorPaletteChange,
  toggleWaitingForResponseOnFontChange,
  toggleWaitingForCommonWebsiteUpdate,
  toggleCloudflareProtectionWaitingForServer,
  toggleSharedAccessChangeWaitingForServer,
  addNewSharedAccessItemInState,
  removeSharedAccessItemFromState,
  toggleWaitingForWebsiteLanguagesUpdate,
  getCurrentStoreData,
  saveWebsiteBackup,
};
export default connect(mapStateToProps, mapActionsToProps)(WebsiteSettings);
