import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useSelected, useFocused } from 'slate-react'

/*
  It is what Slate editor render from Slate "Post Body" object
*/

const ImageElement = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  return (
    <figure {...attributes}>
      <img src={element.url} style={{ boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none', cursor: 'pointer' }}/>
      <figcaption>
        {children}
      </figcaption>
    </figure>
  )
}

const EmbedElement = ({ attributes, children, element }) => {
  const selected = useSelected()
  const focused = useFocused()
  const src = element.src
  const height = element.height
  const width = element.width
  return (
    <div {...attributes} style={{ boxShadow: selected && focused ? '0 0 0 3px #B4D5FF' : 'none' , cursor: 'pointer'}}>
      <iframe
        src={`${src}`}
        height={height}
        width={width}
        frameBorder="0"
        allowFullScreen
      />
      {children}
    </div>
  )
}

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>
    case 'heading-three':
      return <h3 {...attributes}>{children}</h3>
    case 'heading-four':
      return <h4 {...attributes}>{children}</h4>
    case 'paragraph':
      return <p {...attributes}>{children}</p>
    case 'code':
      return <code {...attributes}>{children}</code>
    case 'italic':
      return <em {...attributes}>{children}</em>
    case 'underline':
      return <u {...attributes}>{children}</u>
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>
    case 'list-item':
      return <li {...attributes}>{children}</li>
    case 'quote':
      return <blockquote {...attributes}>{children}</blockquote>
    case 'embed':
      return <EmbedElement attributes={attributes} children={children} element={element} />
    case 'image':
      return <ImageElement attributes={attributes} children={children} element={element} />
    case 'link':
      return (
        <a {...attributes} href={element.url} target="_blank">
          {children}
        </a>
      )
    case 'bold': 
      return <strong {...attributes}>{children}</strong>
    case 'html-code':
      return <p {...attributes}>{children}</p>
    default:
      return <p {...attributes}>{children}</p>
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <b>{children}</b>
  }

  if (leaf.code) {
    children = <code>{children}</code>
  }

  if (leaf.italic) {
    children = <em>{children}</em>
  }

  if (leaf.underline) {
    children = <u>{children}</u>
  }

  return <span {...attributes}>{children}</span>
};

export { Leaf };
export { Element };
