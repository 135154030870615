import React, { Component } from "react";
import DebouncedTextarea from "../../DebouncedTextarea";
import { Icon, Tooltip } from "antd";
import "./PlainHtml.scss";

interface Props {
  currentWebsite: any;
  currentBlogPost: any;
  currentPostIndex: number;

  currentPostUrl: any;

  blogPosts: any;
  changesDetected: boolean;

  handleChangesDetected: any;

  toggleNewBlogPostEditionsDetected: any;

  plainHtmlPostBody: any;
  onChangePlainHtmlPostBody: any;

  savePostBodyToStore: any;
  savePostBodyToServer: any;
}

interface State {
  mainTitle: string;
}

class PlainHtml extends Component<Props, State> {
  onChangePlainHtmlEditor = (value) => {
    this.props.toggleNewBlogPostEditionsDetected(true);

    this.props.handleChangesDetected(true);

    this.props.onChangePlainHtmlPostBody(value);

    this.props.savePostBodyToStore(this.props.currentPostIndex, [], value);
    this.props.savePostBodyToServer(
      this.props.currentWebsite.subdomain,
      this.props.currentPostUrl,
      this.props.currentBlogPost.editor_type,
      [],
      value
    );
  };

  componentDidMount() {
    this.setState({
      mainTitle: this.props.currentBlogPost.main_title,
    });
  }

  render() {
    let savingStatusJSX = null;
    if (
      this.props.blogPosts.isWaitingForSaveBlogPostSchemeResponse === true ||
      this.props.blogPosts.isWaitingForSaveBlogPostMainTitleSchemeResponse ||
      this.props.blogPosts.isWaitingForEditorTypeUpdateResponce
    ) {
      savingStatusJSX = (
        <div className="plain-html__saving-status" style={{ width: "40px" }}>
          {" "}
          <Tooltip
            placement={"top"}
            title={
              "The content is being saved right now. Please do not close the window."
            }
          >
            <Icon style={{ color: "#1890ff" }} type="loading" />
          </Tooltip>
        </div>
      );
    } else if (this.props.changesDetected) {
      savingStatusJSX = (
        <div className="plain-html__saving-status" style={{ width: "40px" }}>
          {" "}
          <Tooltip
            placement={"top"}
            title={
              "New changes detected. We will autosave it in a few moments. Keep creating!"
            }
          >
            <Icon theme="twoTone" twoToneColor="#1890ff" type="clock-circle" />
          </Tooltip>{" "}
        </div>
      );
    } else {
      savingStatusJSX = (
        <div className="plain-html__saving-status" style={{ width: "40px" }}>
          {" "}
          <Tooltip
            placement={"top"}
            title={
              "Good. The changes have been successfully saved in our server."
            }
          >
            <Icon theme="twoTone" twoToneColor="#1890ff" type="check-circle" />
          </Tooltip>{" "}
        </div>
      );
    }

    return (
      <div className="plain-html__wrapper">
        <DebouncedTextarea
          placeholder="Paste your post in HTML format"
          autosize={{ minRows: 10 }}
          style={{ maxWidth: "680px", marginBottom: "20px" }}
          value={this.props.currentBlogPost.body_html}
          defaultValue={this.props.currentBlogPost.body_html}
          onChange={this.onChangePlainHtmlEditor}
        />
        {savingStatusJSX}
      </div>
    );
  }
}

export default PlainHtml;
