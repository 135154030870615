import React, { Component } from 'react'
import { Alert, Button, Icon, Spin} from "antd"
import { urls } from "../../../data/urls";
import { appendScript } from '../../../utils/appendScript'
import generateFormIntegrationDataObject from "../../../helpers/editor/generateFormIntegrationDataObject"
import capitalizeString from "../../../helpers/strings/capitalizeString"
import generateRandomNumerousId from "../../../helpers/generateRandomNumerousId"
import GoogleGLogo from '../../../img/icons/google_g_logo.svg'
import { api } from '../../../../src/data/urls'
import {GOOGLE_OAUTH_CLIENT_ID} from "../../../data/constants";
import { SaveConnectedIntegrationsInServer } from '../../../helpers/types/globalTypes';

interface Props {
  componentItem: any, // Potential can move to global store
  currentWebsite: any,
  saveWebsiteNewFormIntegrationInState: any,
  toggleNewWebsitePageEditionsDetected: any,
  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer,
  currentFormIntegrationTitle: string,
}

export class GoogleSheetsConnectButton extends Component<Props>{

  state = {
    gapiIsSignedIn: false,
    gapiScriptIsLoaded: false,
    connectionInProgress: false
  };

  gapi = null;

  componentDidMount() {
    // Inject gapi script, wait loading and initClient https://developers.google.com/identity/sign-in/web/reference
    appendScript("https://apis.google.com/js/api.js", this.handleClientLoad);
  }


  handleClientLoad = () => {
    let interval = setInterval(() => {
      if (typeof (window as any).gapi !== 'undefined') {

        // Add gapi to the global variable of this component
        this.gapi = (window as any).gapi;

        this.gapi.load('client:auth2', this.initClient);
        clearInterval(interval);
        this.setState({
          gapiScriptIsLoaded: true
        })
      }
    }, 250);
  }


  initClient = () => {
    // Unicorn Platform Srvice Account Client ID - OAuth
    const CLIENT_ID = GOOGLE_OAUTH_CLIENT_ID;

    const DISCOVERY_DOCS = [
      "https://sheets.googleapis.com/$discovery/rest?version=v4",
      "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"
    ];
    const SCOPES = "https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/drive.file";

    this.gapi.client.init({
      clientId: CLIENT_ID,
      discoveryDocs: DISCOVERY_DOCS,
      scope: SCOPES,
      ux_mode: 'popup',
    }).then(() => {
      // Listen for sign-in state changes.
      this.gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);

      // Handle the initial sign-in state.
      this.updateSigninStatus(this.gapi.auth2.getAuthInstance().isSignedIn.get());

    }, (err) => { console.error('initClient', err) });
  }


  updateSigninStatus = (isSignedIn: any) => {
    if (isSignedIn) {
      this.setState({
        gapiIsSignedIn: true
      })
    } else {
      this.setState({
        gapiIsSignedIn: false
      })
    }
  }


  createColumnsTitles = async (sheetInfo) => {
    // Get current fields, with user created
    let fieldItems = this.props.componentItem.componentData.cta.content.form.fields.items;
    let sheetId = sheetInfo.result.spreadsheetId;

    // Set default columns titles
    let defaultColumnsTitles = [
      "ID", // default first column
      "CREATED ON" // default second column
    ]

    // Set user columns titles
    let userColumnsTitles = [];
    for (let fieldItem of fieldItems) {
      userColumnsTitles.push(fieldItem.name);
    }

    // All column titles array
    let firstRow = defaultColumnsTitles.concat(userColumnsTitles);

    // Create cell values
    let values = [];
    values.push(firstRow);

    this.gapi.client.sheets.spreadsheets.values.update({
      spreadsheetId: sheetId,
      valueInputOption: 'USER_ENTERED',
      range: "A1:Z1",
      resource: {
        values: values
      }
    }).then(() => {
      // console.log('Columns titles was created')
    });
  }


  createNewSheet = async () => {
    return this.gapi.client.sheets.spreadsheets.create({
      properties: {
        title: `${capitalizeString(this.props.currentWebsite.subdomain)} leads id${generateRandomNumerousId()} (Unicorn Platform)`
      }
    }).then((res) => {

      // console.log('New sheet was created')
      return res
    });
  }


  shareSheetToUPServiceAccount = async (sheetResp) => {
    const UP_SERVICE_ACCOUNT_EMAIL = "google-sheet-integration-servi@unicorn-platform-279015.iam.gserviceaccount.com";

    return this.gapi.client.drive.permissions.create({
      "fileId": sheetResp.result.spreadsheetId,
      "sendNotificationEmail": false,
      "fields": "id",
      "resource": {
        "role": "writer",
        "type": "user",
        "emailAddress": UP_SERVICE_ACCOUNT_EMAIL
      }
    }).then(() => {
      // console.log('Permissions to the sheet was shared to UP Service Account')
    });
  }


  saveIntegrationInState = async (newConnectedIntegrationObject) => {
    this.props.saveWebsiteNewFormIntegrationInState(
      {
        newConnectedIntegrationObject,
        "currentWebsiteSubdomain": this.props.currentWebsite.subdomain
      }
    );

    // console.log('The Google Sheet integration was saved to the server')
  }


  handleGoogleSignIn = async () => {
    return this.gapi.auth2.getAuthInstance().signIn().then((res) => {
      // Update state
      this.updateSigninStatus(this.gapi.auth2.getAuthInstance().isSignedIn.get());

      // console.log('Sign In')

      // Return response if it is need
      return res
    });
  }


  handleGoogleSignOut = async () => {
    return this.gapi.auth2.getAuthInstance().signOut().then(() => {
      // Update state
      this.updateSigninStatus(this.gapi.auth2.getAuthInstance().isSignedIn.get());

      // console.log('Sign Out')
    });
  }

  handleGoogleDisconnect = async () => {
    return this.gapi.auth2.getAuthInstance().disconnect();
  }


  connectGoogleSheets = async (event) => {

    try {
      this.setState({
        connectionInProgress: true
      })

      // Sign In
      await this.handleGoogleSignIn();

      // Save response from created Sheet
      const newSheetInfo = await this.createNewSheet();

      // First writing to the Sheet
      await this.createColumnsTitles(newSheetInfo);

      // Share permissions to the Unicorn Platform Service Account
      await this.shareSheetToUPServiceAccount(newSheetInfo);
      
      const newConnectedIntegrationObject = generateFormIntegrationDataObject.googleSheet(
        this.props.currentFormIntegrationTitle,
        newSheetInfo.result.properties.title,
        newSheetInfo.result.spreadsheetId,
        newSheetInfo.result.spreadsheetUrl,
        api.websites.passFormDataToGoogleSheet
      )

      // Save this integration
      await this.saveIntegrationInState(newConnectedIntegrationObject)

      // Also save the new integration of the website in the server:
      this.props.saveConnectedIntegrationsInServer(newConnectedIntegrationObject);

      // Revokes all of the scopes that the user granted
      await this.handleGoogleDisconnect();

      // Sign Out
      await this.handleGoogleSignOut();

      this.setState({
        connectionInProgress: false
      })

      // Detect component changes because when a new connection is added, it is automatically applied to the form which was being edited
      this.props.toggleNewWebsitePageEditionsDetected(true);

    } catch (err) {
      this.setState({
        connectionInProgress: false
      })

      console.error('Connect Google Sheets fail...', err)
    }
  }


  render() {
    let JSXButtonIcon = null;
    const antIcon = <Icon type="loading" style={{ fontSize: 16 }} spin />;

    if (!this.state.gapiScriptIsLoaded || this.state.connectionInProgress) {
      JSXButtonIcon = (
        <Spin indicator={antIcon} />
      )
    } else {
      JSXButtonIcon = (
        <img style={{ height: "16px" }} src={GoogleGLogo} />
      )
    }

    const permissionsMessage = (
      <div>
        <span>After clicking this button you will be asked to authorize your Google account. <br /> We will request permission to your Google Drive and Google Sheets. We need it to:</span>
        <ul>
          <li>Create a new spreadsheet.</li>
          <li>Populate the header of the new spreadsheet (e.g. ID, EMAIL, NAME).</li>
          <li>Give permanent writing access to our app (to be able to populate your form).</li>
        </ul>
        <br />
        <span>We explained in details which permissions we get, why we need it and how we work with the access granted in our friendly <a href={urls.privacyPolicy} target="_blank">Privacy policy           <Icon type="link" /></a>
        </span>
      </div>
    );



    return (
      <>
        <Button disabled={!this.state.gapiScriptIsLoaded || this.state.connectionInProgress} onClick={(event) => { this.connectGoogleSheets(event) }} style={{ display: "flex", alignItems: "center" }}>
          {JSXButtonIcon} <span style={{ marginLeft: "8px" }}>Connect Google Sheets</span>
        </Button>
        <div style={{ marginTop: 20 }}>
          <Alert
            message="Requested permissions"
            description={permissionsMessage}
            type="info"
            showIcon
          />
          <Alert
            style={{ marginTop: 20 }}
            message="Fun fact"
            description={(<div>We do not keep access to your Google account. <br /><br /> We sign out right after we create a new spreadsheet and give our app permanent access to populate this particular spreadsheet with the data which your Unicorn Platform forms will pass into it.</div>)}
            type="info"
            showIcon
          />
        </div>
      </>
    )
  }
}
