import React, { useState } from "react";
import Modal, { ModalProps } from "antd/lib/modal";
import "./CancelPlanModal.css";
import SpinnerBox from "../../SpinnerBox";

interface Props extends ModalProps {}

const CancelPlanModal = (props: Props): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Modal
      title="We'd like to know why you're leaving"
      centered
      className="cancellation-modal"
      width="auto"
      footer={null}
      {...props}
    >
      <iframe
        className="cancellation-modal__form"
        src="https://docs.google.com/forms/d/e/1FAIpQLSeunocwqlayw1I9qCmlcatcYsIxfg4iplwF7d254gfzXZraMQ/viewform?embedded=true"
        width="736"
        height="500"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
        onLoad={() => setIsLoading(false)}
      />
      {isLoading && <SpinnerBox />}
    </Modal>
  );
};

export default CancelPlanModal;
