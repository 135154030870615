import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import MockupWithContent from "../../components/MockupWithContent";
import _ from "lodash";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Header61 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty, defaultCtaOption } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpGraphics({
          activeOption: GraphicsOptions.image,
          userCanAddItems: false,
          maxItems: 1,
          fieldInfo: {
            [GraphicsOptions.image]: "",
            [GraphicsOptions.video]: "",
            default: "",
          },
          items: [
            this.generator.createGraphicsContentObject({
              imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CALENDAR,
              videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
              width: 1280,
              height: 800,
            }),
          ],
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpTitle({
          titleText: "Build a Great Website for Your Startup",
          isDefaultEmpty
        })
      );
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
      this.props.saveContentInStore(
        this.generator.setUpMockups({
          items: [
            this.generator.createMockupsContentObject({
              type: "desktop",
              deviceId: "apple_macbook_air",
              optionId: "space_grey",
              fileName: "apple_macbook_air_space_grey.png",
              humanTitle: "MacBook Air",
            }),
          ],
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpCta({
          activeOption: defaultCtaOption || callToActionOptions.buttons,
          isDefaultEmpty
        })
      );
    }
  }

  render() {
    const [
      mockupItem,
    ] = this.props.componentItem.componentData.mockups.content.items;
    const { deviceId } = mockupItem;
    const isEmptyMockup = deviceId === "Empty";

    this.props.connectDefaultFormIntegration(this.props.componentItem);

    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let graphicsObject = this.props.componentItem.componentData.graphics.content
      .items[0]; //This component has exactly one item and more can not be added (see createGraphicsContentObject() above).

    let mockup = displayTitleMarkupText(
      this.props.componentItem.componentData.mockups.content.items[0]
    );

    const currentActiveOption: GraphicsOptions = _.get(
      this.props.componentItem,
      ["componentData", "graphics", "activeOption"]
    );

    return (
      <header
        className={`header-61 desktop-mockup-inside--${mockup.deviceId} graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image}`}
      >
        <div className="container container--large header-61__container">
          <div className="header-61__left">
            <div className="header-61__left_content">
              <div className="header-61__left_content_box">
                {title.length > 0 && (
                  <h1
                    className={
                      "title-text heading heading--accent header-61__heading " +
                      (this.props.isDarkBg ? "text-white" : "")
                    }
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                )}

                {subtitleText.length > 0 && (
                  <p
                    className={
                      "subtitle-text header-61__text content_box " +
                      (this.props.isDarkBg ? "text-white" : "")
                    }
                    dangerouslySetInnerHTML={{ __html: subtitleText }}
                  />
                )}

                <div className="header-61__cta_box">
                  <WebsiteCTA
                    componentItem={this.props.componentItem}
                    isDarkBg={this.props.isDarkBg}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-61__right">
          <div className="header-61__right_wrapper">
            <div className="header-61__mockup">
              <MockupWithContent
                mockupObject={mockup}
                graphicsObject={graphicsObject}
                isDarkBg={this.props.isDarkBg}
                currentActiveOption={currentActiveOption}
              />
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default Header61;
