import React from "react";
import { InputProps } from "../interfaces";
import { GraphicsOptions } from "../../component_graphics/data/componentGraphicsData";
import GraphicsImageInput from "./GraphicsImageInput";
import GraphicsVideoInput from "./GraphicsVideoInput";
import GraphicsHTMLInput from "./GraphicsHTMLInput";

const Graphics = (props: InputProps): JSX.Element => {
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    state,
    saveImageInfoInDB,
    removeImageFromDB,
    componentType,
    componentTitle,
  } = props;

  /*
   * Graphics can be either a predefined by us amount of items (e.g., when a template has 2 mockups and needs only 2 photos) or graphics can be an addable item (e.g. when we offer a photo grid to be filled in).
   * The userCanAddItems is responsible for the choice. userCanAddItems is set manually by us for each component once it is initiated.
   * */

  const activeOption: GraphicsOptions = state.activeOption;
  const lastItem = content.items[content.items.length - 1];

  const mapInputsToGraphicsOption: {
    [key in GraphicsOptions]: JSX.Element;
  } = {
    [GraphicsOptions.image]: (
      <GraphicsImageInput
        content={content}
        saveContentInStore={saveContentInStore}
        generator={generator}
        contentType={contentType}
        currentInputData={currentInputData}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        saveImageInfoInDB={saveImageInfoInDB}
        removeImageFromDB={removeImageFromDB}
        displayMoveUpDownButtons={displayMoveUpDownButtons}
        lastItem={lastItem}
        componentType={componentType}
        componentTitle={componentTitle}
      />
    ),
    [GraphicsOptions.video]: (
      <GraphicsVideoInput
        content={content}
        saveContentInStore={saveContentInStore}
        generator={generator}
        contentType={contentType}
        currentInputData={currentInputData}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        displayMoveUpDownButtons={displayMoveUpDownButtons}
        lastItem={lastItem}
        componentType={componentType}
        componentTitle={componentTitle}
      />
    ),
    [GraphicsOptions.HTML]: (
      <GraphicsHTMLInput
        currentInputData={currentInputData}
        content={content}
        lastItem={lastItem}
        displayMoveUpDownButtons={displayMoveUpDownButtons}
        saveContentInStore={saveContentInStore}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        generator={generator}
        contentType={contentType}
        componentType={componentType}
        componentTitle={componentTitle}
      />
    ),
  };

  return (
    <div>
      <div className="content-input__group">
        {mapInputsToGraphicsOption[activeOption]}
      </div>
    </div>
  );
};

export default Graphics;
