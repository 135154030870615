import React from "react";
import { connect } from "react-redux";
import {
  getCurrentStoreData,
  savePartialWebsiteData,
} from "../../../store/websites/thunks";
import {
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
  editCustomStyles,
} from "../../../store/websites/actions";
import _ from "lodash";
import Item from "./components/Item";
import { StyleMenuProps } from "./components/types";
import "./StyleMenu.scss";

const Example = (): JSX.Element => {
  return (
    <div className="comps">
      <div
        className="style-blue-1 checkered-bg checkered-bg--no-hover has-opacity style-menu__preview"
        style={{
          padding: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: 350,
        }}
      >
        <div className="example-card card-container sh-3" style={{ zIndex: 100 }}>
          <h3 className="def-20 title-text--inner">Example card</h3>
          <p className="content-text">Lorem ipsum dolor sit amet.</p>
        </div>
      </div>
    </div>
  );
};

const CardStyleMenu = (props: StyleMenuProps): JSX.Element => {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Example />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <Item
          inputType="number"
          placeholder="10"
          customStyleProperty="cardRadius"
          tooltip="Change the border radius of each card."
          presets={[
            { value: "0", description: "Sharp" },
            { value: "4", description: "Rounded" },
            { value: "10", description: "Default" },
          ]}
          {...props}
        >
          Radius
        </Item>
        <Item
          inputType="dropdown"
          customStyleProperty="cardShadow"
          tooltip="Change the shadow style of each card."
          isCardShadow
          {...props}
        >
          Shadow
        </Item>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    websites: state.websites,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  editCustomStyles,
  savePartialWebsiteData,
  changeWebsitesStateGlobalData,
  changeWebsiteItemGlobalData,
})(CardStyleMenu);
