export const sortByStartWithSearchValueFn = (a: string, b: string, searchValue: string) => {
  if (!searchValue) return 0;
  if (!a || !b) return 0;
  const domainA = a.toLowerCase();
  const domainB = b.toLowerCase();
  const searchLower = searchValue.toLowerCase();
  const startsWithA = domainA.startsWith(searchLower);
  const startsWithB = domainB.startsWith(searchLower);

  if (startsWithA && !startsWithB) return -1;
  if (!startsWithA && startsWithB) return 1;
  return 0;
};
