import { ActionNames, setIsTyping } from "./gpt/actions";
import { AddChatMessagePayload } from "./gpt/types";

// This middleware watches for actions that should trigger other GPT-related actions.
export const gptMiddleware = (store) => (next) => (action) => {
  // When a chat message is added, set isTyping to false to remove the typing indicator.
  if (action.type === ActionNames.ADD_CHAT_MESSAGE) {
    const { pageId } = action.payload as AddChatMessagePayload;
    store.dispatch(setIsTyping({ pageId, isTyping: "none" }));
  }
  return next(action);
};
