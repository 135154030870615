import React from "react";
import { GPT_ASSISTANT_PROMPT_LENGTH_LIMIT } from "../../../data/constants";

interface Props {
  inputValue: string;
}

const FillTemplateCharacterCounter = (props: Props): JSX.Element => {
  const { inputValue } = props;
  if (inputValue.length < GPT_ASSISTANT_PROMPT_LENGTH_LIMIT / 2) return null;

  return (
    <div className="fill-template-modal__character-counter">
      {inputValue.length}/{GPT_ASSISTANT_PROMPT_LENGTH_LIMIT}
    </div>
  );
};

export default FillTemplateCharacterCounter;
