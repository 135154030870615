import _ from "lodash";
import { appsumoPlanTitle, ltdPlanTitle } from "../../data/constants";

export const checkIsLtdPlan = (user: any, plans: any) => {
  const userPlanId = user.data.current_plan;
  const currentPlan = plans.items.find((plan) => plan.id === userPlanId);
  return (
    currentPlan.title === appsumoPlanTitle || currentPlan.title === ltdPlanTitle
  );
};
