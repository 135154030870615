import _ from "lodash";
import { GraphicsOptions } from "../components/editor/component_graphics/data/componentGraphicsData";

export const checkIfSlideVisible = (
  item: any,
  currentActiveOption: GraphicsOptions
) => {
  const imageUrl = _.get(item, [
    "contentGraphic",
    0,
    GraphicsOptions.image,
    "url",
  ]);
  const videoUrl = _.get(item, [
    "contentGraphic",
    0,
    GraphicsOptions.video,
    "youtube",
    "url",
  ]);

  return (
    currentActiveOption === GraphicsOptions.HTML ||
    (currentActiveOption === GraphicsOptions.image && !!imageUrl) ||
    (currentActiveOption === GraphicsOptions.video && !!videoUrl)
  );
};
