import { CustomColorData } from "./types/globalTypes";

export const getPathToCustomBgColorData = (
  isFooter?: boolean,
  property?: keyof CustomColorData
) => {
  let path = ["settings", "background", "customBackgroundColor"];
  if (isFooter) path = ["settings", "customBackgroundColor"];
  if (!!property) {
    path.push(property);
  }
  return path;
};
