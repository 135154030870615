import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    isDarkBg: boolean,
}

class CtaButton01 extends Component<Props>{
    generator = new generateContentDataObject();
    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpCta({
                activeOption: callToActionOptions.buttons,
                isDefaultEmpty
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        return (
            <section>
                <div className="cta_button-01">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--small">
                        <div className="cta_button-01__button_box">
                        <WebsiteCTA componentItem={this.props.componentItem} />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default CtaButton01;
