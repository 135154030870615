import {openNotification} from "./openNotification";

export const myImagesValidator = (fileInfo:any) => {
    let fileNameIntoArray = fileInfo.name.split('.');
    let fileNameArrayLength = fileNameIntoArray.length;
    let fileExtension = fileNameIntoArray[fileNameArrayLength - 1];
    fileExtension = fileExtension.toLowerCase();
    if (
        fileExtension == 'jpg' ||
        fileExtension == 'jpeg' ||
        fileExtension == 'png' ||
        fileExtension == 'ico' ||
        fileExtension == 'icon' ||
        fileExtension == 'gif' ||
        fileExtension == 'webp' ||
        fileExtension == 'svg'
    ) {

    }else{
        openNotification('Only images 🙏', "I'm sorry but you can upload only images: JPG, PNG, SVG, WebP and GIF.", 'Sure', 'warn', 999);
        throw new Error('image');
    }
    if(fileInfo.size > 7500000){
        if(fileExtension == 'gif'){
            openNotification('GIF too large 🐡', "GIFs over 7 MB will make your website loading for years. Huge files damage both conversion and SEO. Please compress your GIF with this free service: ezgif.com", 'Understood', 'warn', 999);
        }else{
            openNotification('Image too large 🐋', "Images over 7 MB will make your website loading for years. Huge files damage both conversion and SEO. Please resize your image using this free online service: photopea.com", 'OK', 'warn', 999);
        }
        throw new Error('size');
    }
};