import React from "react";
import { CodeInputData } from "../../../enums/CodeInputData";
import { InputProps } from "./interfaces";
import DebouncedInput from "../../DebouncedInput";
import DebouncedTextarea from "../../DebouncedTextarea";
import { useCurrentInputData } from "./useCurrentInputData";

const Code = (props: InputProps): JSX.Element => {
  const {
    content,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const onChangeHandler = (value: any, dataType: CodeInputData) => {
    const currentInputObject = { [contentType]: currentInputDataRef.current };
    saveContentInStore(
      generator.changeCodeData(currentInputObject, dataType, value)
    );
    toggleNewEditionsDetected(true);
  };

  let codeInputs = (
    <div>
      {currentInputDataRef.current.hasCodeSnippet && (
        <DebouncedTextarea
          className="content-input__input content-input__input--monospace"
          onChange={(value) => {
            onChangeHandler(value, CodeInputData.codeSnippet);
          }}
          placeholder={"Enter any HTML code. Examples: <iframe> <script> etc."}
          defaultValue={content.codeSnippet}
          autosize={{ minRows: 2, maxRows: 20 }}
          id={contentType}
        />
      )}

      {currentInputDataRef.current.hasGistUrl && (
        <DebouncedInput
          onChange={(value) => {
            onChangeHandler(value, CodeInputData.gistUrl);
          }}
          defaultValue={content.gistUrl}
          placeholder="Enter GitHub Gist URL"
          className="content-input__input"
        />
      )}

      {(currentInputDataRef.current.hasTitle || currentInputDataRef.current.hasSubtitle) && (
        <div className="content-input__group">
          {currentInputDataRef.current.hasTitle && (
            <DebouncedInput
              onChange={(value) => {
                onChangeHandler(value, CodeInputData.title);
              }}
              defaultValue={content.title}
              placeholder="Enter block title"
              className="content-input__input"
            />
          )}
          {currentInputDataRef.current.hasSubtitle && (
            <DebouncedTextarea
              onChange={(value) => {
                onChangeHandler(value, CodeInputData.subtitle);
              }}
              defaultValue={content.subtitle}
              placeholder="Enter block subtitle"
              className="content-input__input"
              autosize={{ minRows: 1, maxRows: 6 }}
            />
          )}
        </div>
      )}
    </div>
  );

  return codeInputs;
};

export default Code;
