import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Features05 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Strong Base for Sustainable Growth"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpList({
              isDefaultEmpty,
                fieldTitle: {default: 'Features lists'},
                label: {default: 'Edit the features lists. Those can be links.'},
                maxItems: 16,
                hasGroups: true,
                maxGroups: 3,
                groupTitleHasTitle: true,
                groupTitleHasPictogram: false,
                groupTitleHasSubtitle: false,
                groups: [
                    this.generator.createGroupContentObject({
                        title: 'Top-notch design'
                    }),
                    this.generator.createGroupContentObject({
                        title: 'Ease of use'
                    }),
                    this.generator.createGroupContentObject({
                        title: 'Built with passion'
                    }),
                ],
                items: [
                    this.generator.createListItemContentObject({
                        title: 'Custom animations',
                        href: '',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Handmade templates',
                        href: '',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Fully responsive',
                        href: '',
                        groupIndex: 0
                    }),

                    // The last ghost item which is used to add new items.
                    this.generator.createListItemContentObject({
                        title: '',
                        href: '',
                        groupIndex: 0
                    }),


                    this.generator.createListItemContentObject({
                        title: '12 color palettes',
                        href: '',
                        groupIndex: 1
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Custom code fields',
                        href: '',
                        groupIndex: 1
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Dozens of emoji',
                        href: '',
                        groupIndex: 1
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Form integrations',
                        href: '',
                        groupIndex: 1
                    }),

                    // The last ghost item which is used to add new items.
                    this.generator.createListItemContentObject({
                        title: '',
                        href: '',
                        groupIndex: 1
                    }),


                    this.generator.createListItemContentObject({
                        title: 'Your needs are important',
                        href: '',
                        groupIndex: 2
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Attention to details',
                        href: '',
                        groupIndex: 2
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Good for geeks',
                        href: '',
                        groupIndex: 2
                    }),

                    // The last ghost item which is used to add new items.
                    this.generator.createListItemContentObject({
                        title: '',
                        href: '',
                        groupIndex: 2
                    }),
                ],
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the component to offer more to read about your project.",
                    default: "Add a CTA (call to action) to the bottom of the component to offer more to read about your project.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'More'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let listItems = this.props.componentItem.componentData.list.content.items;
        let listGroups = this.props.componentItem.componentData.list.content.groups;

        return (
            <section>
                <div className="features-05">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container">
                        <ul className="features-05__items__wrapper">
                            {listGroups && listGroups.map((group:any, groupKey:number) => {
                                if(group.title && group.title !== ''){
                                    return (
                                        <div className="features-05__item_box" key={'features-group-05-' + groupKey}>
                                            <h3 className={"def-18_72 title-text--inner features-05__item_title " + (this.props.isDarkBg ? 'text-white' : '')}>{group.title}</h3>
                                            <ul className="features-05__items">
                                                {listItems && listItems.map((listItem:any, listItemKey:number) => {
                                                    if(listItem.title && listItem.title !== ''
                                                        &&
                                                        //Show only items of this group
                                                        listItem.groupIndex === groupKey){

                                                        let itemTextJSX = (<div
                                                            className={"content-text features-05__item_text " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: listItem.title}}/>);
                                                        if(listItem.href && listItem.href !== ''){
                                                            itemTextJSX = (<a
                                                                href={returnHref(listItem.href)}
                                                                className={"content-text features-05__item_text " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: listItem.title}}/>);
                                                        }

                                                        return (
                                                            <li className="features-05__item" key={'features-item-05-' + listItemKey}>
                                                                <div className="features-05__item_content">
                                                                    <div className="features-05__item_icon">
                                                                        <span className="icon">
                                                                            <svg width="22px"
                                                                                 height="17px"
                                                                                 viewBox="0 0 22 17"
                                                                                 version="1.1"
                                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                                <g id="styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <g id="Unicorn-Styleguide" transform="translate(-76.000000, -1899.000000)" fillRule="nonzero" fill="#4D61FC"
                                                                                   className="fill-main features-05__item_shape">
                                                                                <g id="Icons" transform="translate(76.000000, 1856.000000)">
                                                                                <g id="checked" transform="translate(0.000000, 43.000000)">
                                                                                <path
                                                                                    d="M0.253846154,9.30769231 C0.0846153846,9.13846154 0,8.88461538 0,8.71538462 C0,8.54615385 0.0846153846,8.29230769 0.253846154,8.12307692 L1.43846154,6.93846154 C1.77692308,6.6 2.28461538,6.6 2.62307692,6.93846154 L7.36153846,12.0153846 C7.53076923,12.1846154 7.78461538,12.1846154 7.95384615,12.0153846 L19.3769231,0.253846154 C19.7153846,-0.0846153846 20.2230769,-0.0846153846 20.5615385,0.253846154 L21.7461538,1.43846154 C22.0846154,1.77692308 22.0846154,2.28461538 21.7461538,2.62307692 L8.20769231,16.6692308 C8.03846154,16.8384615 7.86923077,16.9230769 7.61538462,16.9230769 C7.36153846,16.9230769 7.19230769,16.8384615 7.02307692,16.6692308 L0.253846154,9.30769231 Z"
                                                                                    id="Shape"/>
                                                                                </g>
                                                                                </g>
                                                                                </g>
                                                                                </g>
                                                                                </svg>
                                                                        </span>
                                                                    </div>
                                                                    {listItem.title && listItem.title !== '' && itemTextJSX}
                                                                </div>
                                                            </li>
                                                        )
                                                    }else{
                                                        return (null);
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    )
                                }else{
                                    return (null);
                                }

                            })}
                        </ul>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>

                    </div>
                </div>

            </section>
        )
    }
}

export default Features05;
