import { AuthState } from "./types";
import actionsNames from "./actions";

let initialState: AuthState = {
  isAccessTokenValid: false,
  accessToken: undefined,
  accessTokenValidated: false,
  login: {
    isWaitingForServer: false,
    isInvalidEmail: false,
    isInvalidPassword: false,

    emailMessage: undefined,
    passwordMessage: undefined,
  },
  join: {
    isWaitingForServer: false,

    isInvalidEmail: false,
    isInvalidPassword: false,
    isInvalidPasswordRepeat: false,
    isInvalidFirstName: false,
    isInvalidSecondName: false,

    emailMessage: undefined,
    passwordMessage: undefined,
    passwordRepeatMessage: undefined,
    firstNameMessage: undefined,
    secondNameMessage: undefined,
  },
  restorePassword: {
    isWaitingForServer: false,
    isInvalidAttempt: false,
    message: undefined,
  },
  setNewPassword: {
    isWaitingForServer: false,
    isInvalidPassword: false,
    isInvalidPasswordRepeat: false,
    passwordMessage: undefined,
    passwordRepeatMessage: undefined,
  },
  emailConfirmation: {
    serverResponded: false,
  },
  resendEmailVerifyWaiting: false,
  needsOnboarding: false,
};

export const authReducer = (state: AuthState = initialState, action: any) => {
  switch (action.type) {
    // Access token
    case actionsNames.SET_ACCESS_TOKEN_IS_VALID:
      return {
        ...state,
        isAccessTokenValid: action.payload,
      };
    case actionsNames.SAVE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case actionsNames.ACCESS_TOKEN_VALIDATED:
      return {
        ...state,
        accessTokenValidated: action.payload,
      };

    // Registration
    case actionsNames.REGISTRATION_TOGGLE_WAITING_FOR_SERVER:
      return {
        ...state,
        join: {
          ...state.join,
          isWaitingForServer: action.payload,
        },
      };
    case actionsNames.REGISTRATION_TOGGLE_INVALID_EMAIL:
      return {
        ...state,
        join: {
          ...state.join,
          isInvalidEmail: action.payload,
        },
      };
    case actionsNames.REGISTRATION_TOGGLE_INVALID_PASSWORD:
      return {
        ...state,
        join: {
          ...state.join,
          isInvalidPassword: action.payload,
        },
      };
    case actionsNames.REGISTRATION_TOGGLE_INVALID_PASSWORD_REPEAT:
      return {
        ...state,
        join: {
          ...state.join,
          isInvalidPasswordRepeat: action.payload,
        },
      };
    case actionsNames.REGISTRATION_SET_EMAIL_MESSAGE:
      return {
        ...state,
        join: {
          ...state.join,
          emailMessage: action.payload,
        },
      };
    case actionsNames.REGISTRATION_SET_PASSWORD_MESSAGE:
      return {
        ...state,
        join: {
          ...state.join,
          passwordMessage: action.payload,
        },
      };
    case actionsNames.REGISTRATION_SET_PASSWORD_REPEAT_MESSAGE:
      return {
        ...state,
        join: {
          ...state.join,
          passwordRepeatMessage: action.payload,
        },
      };

    // Login
    case actionsNames.LOGIN_TOGGLE_WAITING_FOR_SERVER:
      return {
        ...state,
        login: {
          ...state.login,
          isWaitingForServer: action.payload,
        },
      };
    case actionsNames.LOGIN_TOGGLE_INVALID_EMAIL:
      return {
        ...state,
        login: {
          ...state.login,
          isInvalidEmail: action.payload,
        },
      };

    case actionsNames.LOGIN_TOGGLE_INVALID_PASSWORD:
      return {
        ...state,
        login: {
          ...state.login,
          isInvalidPassword: action.payload,
        },
      };
    case actionsNames.LOGIN_SET_EMAIL_MESSAGE:
      return {
        ...state,
        login: {
          ...state.login,
          emailMessage: action.payload,
        },
      };
    case actionsNames.LOGIN_SET_PASSWORD_MESSAGE:
      return {
        ...state,
        login: {
          ...state.login,
          passwordMessage: action.payload,
        },
      };

    // Reset password
    case actionsNames.RESET_PASS_TOGGLE_WAITING_FOR_SERVER:
      return {
        ...state,
        restorePassword: {
          ...state.restorePassword,
          isWaitingForServer: action.payload,
        },
      };
    case actionsNames.RESET_PASS_TOGGLE_INVALID_ATTEMPT:
      return {
        ...state,
        restorePassword: {
          ...state.restorePassword,
          isInvalidAttempt: action.payload,
        },
      };
    case actionsNames.RESET_PASS_SET_MESSAGE:
      return {
        ...state,
        restorePassword: {
          ...state.restorePassword,
          message: action.payload,
        },
      };

    // Set new password
    case actionsNames.SET_NEW_PASS_TOGGLE_WAITING_FOR_SERVER:
      return {
        ...state,
        setNewPassword: {
          ...state.setNewPassword,
          isWaitingForServer: action.payload,
        },
      };
    case actionsNames.SET_NEW_PASS_TOGGLE_INVALID_PASSWORD:
      return {
        ...state,
        setNewPassword: {
          ...state.setNewPassword,
          isInvalidPassword: action.payload,
        },
      };
    case actionsNames.SET_NEW_PASS_TOGGLE_INVALID_PASSWORD_REPEAT:
      return {
        ...state,
        setNewPassword: {
          ...state.setNewPassword,
          isInvalidPasswordRepeat: action.payload,
        },
      };

    case actionsNames.SET_NEW_PASS_SET_PASSWORD_MESSAGE:
      return {
        ...state,
        setNewPassword: {
          ...state.setNewPassword,
          passwordMessage: action.payload,
        },
      };
    case actionsNames.SET_NEW_PASS_SET_PASSWORD_REPEAT_MESSAGE:
      return {
        ...state,
        setNewPassword: {
          ...state.setNewPassword,
          passwordRepeatMessage: action.payload,
        },
      };

    // Email confirmation
    case actionsNames.EMAIL_CONFIRMATION_TOGGLE_SERVER_RESPONDED:
      return {
        ...state,
        emailConfirmation: {
          ...state.emailConfirmation,
          serverResponded: action.payload,
        },
      };

    case actionsNames.TOGGLE_RESEND_EMAIL_CONFIRMATION_WAITING:
      return {
        ...state,
        resendEmailVerifyWaiting: action.payload,
      };
    case actionsNames.TOGGLE_NEEDS_ONBOARDING:
      return {
        ...state,
        needsOnboarding: action.payload,
      };

    default:
      return state;
  }
};
