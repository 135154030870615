import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import generateRandomNumerousId from "../../../../helpers/generateRandomNumerousId";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  const itemUrl = stringifyObjectsAndNumbers(modifiedItem.URL) || "";
  const itemTarget = stringifyObjectsAndNumbers(modifiedItem.TARGET) || "_self";
  return {
    itemId,
    itemPosition,
    itemTitle,
    itemUrl,
    itemTarget,
  };
};

const particleModifyOriginalItem = (
  content,
  originalItem,
  modifiedItem,
  itemPosition,
  itemTitle,
  itemUrl,
  itemTarget
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    content.topLevelItems.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(content.topLevelItems, item);
      }
    });
  }
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("URL" in modifiedItem) {
    originalItem.href = itemUrl;
  }
  if ("TARGET" in modifiedItem) {
    originalItem.isTargetBlank = itemTarget === "_blank";
  }
};

export const updateNavTopLevelLinks = (componentData, completionContent) => {
  const content = componentData.navLinks.content;
  content.topLevelItems = deleteEmptyItems(content.topLevelItems, "title");
  addDefaultValuesToItems(content.topLevelItems);
  completionContent.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemTitle,
      itemUrl,
      itemTarget,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      const deleteditem = getOriginalItem(content.topLevelItems, itemId);
      if (deleteditem) {
        delete content.dropdownItems[deleteditem.id];
      }
      content.topLevelItems = deleteItem(content.topLevelItems, itemId);
      return;
    }
    const isItemNew = getIsItemNew(content.topLevelItems, itemId);
    if (isItemNew) {
      const newItem = generator.createNavLinksItemContentObject({
        title: "",
        href: "",
        id: generateRandomNumerousId(),
      });
      addNewItem(content.topLevelItems, newItem, itemId, itemPosition);
    }
    const originalItem = getOriginalItem(content.topLevelItems, itemId);
    particleModifyOriginalItem(
      content,
      originalItem,
      modifiedItem,
      itemPosition,
      itemTitle,
      itemUrl,
      itemTarget
    );
  });
  sortItemsByPosition(content.topLevelItems);
};
