import React, {Component} from "react";
import {Alert, Button, Icon, Input, message, Popover, Switch, Tooltip} from "antd";
import {api} from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {openNotification} from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import './EditMultiUserAccess.css'
import MultiUserAccessItem from "./MultiUserAccessItem";
import { handleLimitedWebsiteAccessError } from "../../helpers/handleLimitedWebsiteAccessError";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import _ from "lodash";

interface Props {
    currentWebsite: any,
    websites: any,
    auth: any,
    user: any,
    toggleSharedAccessChangeWaitingForServer: any,
    sharedAccesses: any,
    addNewSharedAccessItemInState: any,
    removeSharedAccessItemFromState: any,
    listOfSharedAccessOfThisWebsiteOnly: any,
    doesCurrentUserHaveAccessToPaidFeatures: any,
}
type MyState = { currentAddNewUserAccessEmailInputValue: string };

class EditMultiUserAccess extends Component<Props, MyState>{
    componentDidMount(): void {
      this.setState({
        currentAddNewUserAccessEmailInputValue: ''
      })
    }

    constructor(props) {
      super(props);
      this.state = {
        currentAddNewUserAccessEmailInputValue: ''
      };
    }

    handleAddNewSharedAccessEmailInputChange = (event:any) => {
      this.setState({
          currentAddNewUserAccessEmailInputValue: event.target.value
      });
    };

    displayItems = () => {
      let usersJSX = [];
      for (let item of this.props.listOfSharedAccessOfThisWebsiteOnly) {
        let singleItem = (
          <MultiUserAccessItem
            key={'collaborator-' + item.id}
            email={item.manager_email}
            sharedAccessItemId={item.id}
            firstName={item.manager_first_name}
            auth={this.props.auth}
            toggleSharedAccessChangeWaitingForServer={this.props.toggleSharedAccessChangeWaitingForServer}
            sharedAccesses={this.props.sharedAccesses}
            removeSharedAccessItemFromState={this.props.removeSharedAccessItemFromState}
          />
        )
        usersJSX.push(singleItem);
      }
      return (
        <div>
          {usersJSX}
        </div>
      )
    }

    createSharedAccess = () => {
        this.props.toggleSharedAccessChangeWaitingForServer(true);

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.sharedAccess.create;
        axios.post(apiUrl, {'manager_email': this.state.currentAddNewUserAccessEmailInputValue, 'website_id': this.props.currentWebsite.id}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(response => {
              this.props.addNewSharedAccessItemInState(response.data);
              fireAnalyticsEvent.fireCrisp(CrispEvents.addSharedAccess, {
                subdomain: this.props.currentWebsite.subdomain,
                owner_id: this.props.user.data.id,
              });
            })
            .catch(error => {
                // handle error
                if(error.response){
                    let errorData = error.response.data;

                    let errorObjectKey = getErrorKey(errorData);
                    let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                    fireAnalyticsEvent.fireCrisp(CrispEvents.addSharedAccessError, {
                      error_type: errorObjectKey,
                      error_message: errorObjectValue,
                      subdomain: this.props.currentWebsite.subdomain,
                      owner_id: this.props.user.data.id,
                    }, true);
                    
                    handleBadWebsiteError(errorData);
                    handleLimitedWebsiteAccessError(errorData);

                    if (errorData.not_pro !== undefined){
                        let message = errorData.not_pro;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.already_exists !== undefined){
                      let message = errorData.already_exists;
                      openNotification('Already given', message, 'Understood', 'warn');
                    }

                    if (errorData.email !== undefined){
                      let message = errorData.email;
                      openNotification('User not found', message, 'Got it', 'warn');
                    }

                    if (errorData.limit !== undefined){
                      let message = errorData.limit;
                      openNotification('Limit reached', message, 'Understood', 'warn');
                    }

                    if (errorData.yourself !== undefined){
                      let message = errorData.yourself;
                      openNotification('🤔', message, 'Makes sense', 'warn');
                    }

                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.toggleSharedAccessChangeWaitingForServer(false);
            });
    };
    render(){
        return (
            <div className="edit-multi-user-access">
              {this.props.listOfSharedAccessOfThisWebsiteOnly.length > 0 && <Alert closable style={{maxWidth: 485, marginBottom: 25}} showIcon message={(<span>Please note: the multiuser access does not support concurrent edition. Only the latest saved changes will be saved. <br/> Do not edit a website simultaneously with others!</span>)} type="warning" />}
              <div className="edit-multi-user-access__users_box">
                <div className="edit-multi-user-access__form_box">
                  <form className="edit-multi-user-access__form" action="" onSubmit={(event:any) => 
                  {                    
                    event.preventDefault();
                    this.createSharedAccess();
                  }
                    }>
                    <Input
                      placeholder="Enter email"
                      required
                      disabled={this.props.sharedAccesses.isWaitingForChangeSharedAccessResponse}
                      onChange={this.handleAddNewSharedAccessEmailInputChange}
                      type="email"
                      prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      suffix={
                        <Tooltip title="Enter a Unicorn Platform user email.">
                          <Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} />
                        </Tooltip>
                      }
                    />
                    <Button
                      // onClick={() => this.createSharedAccess()}
                      style={{marginLeft: 15}}
                      htmlType="submit"
                      loading={this.props.sharedAccesses.isWaitingForChangeSharedAccessResponse}
                      icon="user-add">
                      Grant full access
                    </Button>
                  </form>

                </div>

                {_.size(this.props.listOfSharedAccessOfThisWebsiteOnly) > 0 && <div className="edit-multi-user-access__users">
                  {this.displayItems()}
                </div>}
              </div>
            </div>
        )
    }
}

export default EditMultiUserAccess;
