import { freePlanTitle, appsumoPlanTitle, ltdPlanTitle } from '../../data/constants';

export const getCurrentPlanPeriod = (user:any, plans:any) => {
    let userPlanId = user.data.current_plan;
    let plansCount = plans.items.length;

    for(let i = 0; i < plansCount; i++){
        let currentPlan = plans.items[i];

        if(currentPlan.id === userPlanId){ //found current active plan
            if(currentPlan.title === freePlanTitle || currentPlan.title === appsumoPlanTitle || currentPlan.title === ltdPlanTitle){
                // These plans has no period, but in DB it actually has to have a period. SO we return undefined.
                return undefined
            }else{
                return currentPlan.period;
            }
        }
    }
};