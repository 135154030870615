import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import { WebsitesItem } from "../../store/websites/types";

type UpdateWebsiteAsync = (payload: {
  accessToken: string;
  subdomain: string;
  data: Partial<WebsitesItem>;
}) => Promise<AxiosResponse<any>>;

export const updateWebsiteAsync: UpdateWebsiteAsync = ({
  accessToken,
  subdomain,
  data,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${api.websites.updateWebsite.prefix}${subdomain}${api.websites.updateWebsite.postfix}`;
    axios
      .patch(apiUrl, data, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
