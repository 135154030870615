import React from "react";
import { pricingItemDetailIconOptions } from "../../data/pricingItemDetailIconOptions";
import { iconsImgPath, publicUrl } from "../../data/urls";

interface Props {
  detailObj: any;
  iconClassname: string;
}

const PricingDetailsIconSet = (props: Props): JSX.Element => {
  const { detailObj, iconClassname } = props;

  if (detailObj.icon === pricingItemDetailIconOptions.greenTick) {
    return (
      <img
        className={iconClassname}
        src={publicUrl + iconsImgPath.prefix + "checked--acid.svg"}
      />
    );
  } else if (detailObj.icon === pricingItemDetailIconOptions.redCross) {
    return (
      <img
        className={iconClassname}
        src={publicUrl + iconsImgPath.prefix + "cross--red.svg"}
      />
    );
  } else {
    return null;
  }
};

export default PricingDetailsIconSet;
