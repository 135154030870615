import React, { Component } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import ConfirmEmail from "../dashboard/ConfirmEmail";
import { connect } from "react-redux";
import "./PrimaryLayout.css";

import {
  accessTokenToggleServerResponded,
  emailConfirmationToggleServerResponded,
  logout,
  saveAccessTokenInState,
  toggleAccessTokenIsValid,
  toggleResendEmailConfirmationWaiting,
  validateUserAccessTokenInDashboard,
} from "../../store/auth/actions";
import {
  fetchCurrentUserInfo,
  saveUserDataInState,
  toggleCancellingPro,
  toggleSwitchingProPlanInProgress,
  toggleUpgradingFromFreeToProInProgress,
  toggleUserDataFetched,
  toggleUserLtd,
  toggleUserAppsumo,
  toggleUserPro,
  saveUserEventsInStore,
  setUserStateData,
} from "../../store/user/actions";
import { Layout, Button, Empty, Typography } from "antd";
import {
  fetchPlans,
  savePlansInState,
  togglePlansFetched,
} from "../../store/plans/actions";
import {
  fetchTemplates,
  saveTemplatesInState,
  toggleTemplatesFetched,
} from "../../store/templates/actions";
import {
  changeStripeCheckoutProductsArray,
  changeStripePublicKey,
  changeStripeSecretKey,
  changeWebsiteNavColor,
  cloneWebsiteWaitingForServer,
  createWebsiteErrorMessage,
  createWebsiteWaitingForServer,
  websiteCustomCodeUpdateErrorMessage,
  websiteCustomCodeUpdateToggleInvalidAttempt,
  websiteCustomCodeUpdateWaitingForServer,
  customDomainToggleActivationWaitingForServer,
  customDomainUpdateErrorMessage,
  customDomainUpdateToggleInvalidAttempt,
  customDomainUpdateWaitingForServer,
  deleteWebsiteWaitingForServer,
  enableBrandingWaitingForServer,
  websiteExtraCustomCodeUpdateErrorMessage,
  websiteExtraCustomCodeUpdateToggleInvalidAttempt,
  websiteExtraCustomCodeUpdateWaitingForServer,
  fetchWebsites,
  removeWebsiteFromStore,
  saveSingleWebsiteInState,
  saveWebsiteFooterInState,
  saveWebsiteNavInState,
  saveWebsiteNewFormIntegrationInState,
  removeWebsiteFormIntegration,
  saveWebsitesInState,
  sslToggleActivationWaitingForServer,
  subdomainUpdateErrorMessage,
  subdomainUpdateToggleInvalidAttempt,
  subdomainUpdateWaitingForServer,
  toggleWaitingForResponseOnColorPaletteChange,
  toggleWaitingForResponseOnFontChange,
  toggleWaitingForResponseOnWebsiteFooterEditions,
  toggleWaitingForResponseOnWebsiteFormIntegrationAdd,
  toggleWaitingForResponseOnWebsiteNavEditions,
  toggleWaitingForConnectPaymentIntegration,
  toggleWebsiteFooterEditionsDetected,
  toggleWebsiteNavEditionsDetected,
  toggleWebsitesFetched,
  toggleWaitingForChangePaymentIntegrationProductsArray,
  toggleWaitingForEditPaymentIntegrationProductDetails,
  toggleWaitingForConfigurePaymentIntegration,
  toggleWaitingForCommonWebsiteUpdate,
  updateActiveDashboardTab,
  toggleCloudflareProtectionWaitingForServer,
  toggleWaitingForWebsiteLanguagesUpdate,
  toggleWebsiteCaptchaEditionsDetected,
  toggleWaitingForResponseOnWebsiteCaptchaEditions,
  toggleWebsitePopupEditionsDetected,
  toggleWaitingForResponseOnWebsitePopupEditions,
  toggleLoadedWebsitesData,
  editFillTemplateData,
  toggleIsWebsiteFromTemplateCreated,
  changeWebsitesStateGlobalData,
  changeWebsiteNavIntegrationId,
} from "../../store/websites/actions";
import {
  toggleSharedAccessChangeWaitingForServer,
  addNewSharedAccessItemInState,
  removeSharedAccessItemFromState,
} from "../../store/sharedAccesses/actions";
import {
  blogCustomCodePostsUpdateErrorMessage,
  blogCustomCodePostsUpdateToggleInvalidAttempt,
  blogCustomCodePostsUpdateWaitingForServer,
  blogCustomCodeUpdateErrorMessage,
  blogCustomCodeUpdateToggleInvalidAttempt,
  blogCustomCodeUpdateWaitingForServer,
  blogExtraCustomCodePostsUpdateErrorMessage,
  blogExtraCustomCodePostsUpdateToggleInvalidAttempt,
  blogExtraCustomCodePostsUpdateWaitingForServer,
  blogExtraCustomCodeUpdateErrorMessage,
  blogExtraCustomCodeUpdateToggleInvalidAttempt,
  blogExtraCustomCodeUpdateWaitingForServer,
  blogMetaDescriptionUpdateErrorMessage,
  blogMetaDescriptionUpdateToggleInvalidAttempt,
  blogMetaDescriptionUpdateWaitingForServer,
  blogMetaTitleUpdateErrorMessage,
  blogMetaTitleUpdateToggleInvalidAttempt,
  blogMetaTitleUpdateWaitingForServer,
  changeBlogNavColor,
  cloneBlogWaitingForServer,
  createBlogErrorMessage,
  createBlogWaitingForServer,
  deleteBlogWaitingForServer,
  fetchBlogs,
  removeBlogFromStore,
  saveBlogFooterInState,
  saveBlogNavInState,
  saveBlogsInState,
  saveSingleBlogInState,
  toggleBlogFooterEditionsDetected,
  toggleBlogNavEditionsDetected,
  toggleWaitingForCommonBlogUpdate,
  toggleWaitingForResponseOnBlogFooterEditions,
  toggleWaitingForResponseOnBlogNavEditions,
  urlUpdateErrorMessage,
  urlUpdateToggleInvalidAttempt,
  urlUpdateWaitingForServer,
  editLoadedBlogsData,
  changeBlogNavIntegrationId,
} from "../../store/blogs/actions";
import {
  addWebsitePageComponent,
  pasteCopiedWebsitePageComponent,
  changeWebsitePageComponentBackgroundColor,
  changeWebsitePageComponentBackgroundImage,
  changeWebsitePageComponentBackgroundImageOverlayOpacity,
  changeWebsitePageComponentPaddingTop,
  changeWebsitePageComponentPaddingBottom,
  changeWebsitePageComponentVisibility,
  changeWebsitePageComponentContent,
  cloneWebsitePageWaitingForServer,
  createWebsitePageErrorMessage,
  createWebsitePageWaitingForServer,
  deleteWebsitePageComponent,
  deleteWebsitePageWaitingForServer,
  editWebsitePageSchemeToggleWaitingForServer,
  fetchWebsitePagesWithoutComponents,
  fetchSingleWebsitePages,
  moveWebsitePageComponent,
  pageMetaDescriptionUpdateErrorMessage,
  pageMetaDescriptionUpdateToggleInvalidAttempt,
  pageMetaDescriptionUpdateWaitingForServer,
  pageMetaTitleUpdateErrorMessage,
  pageMetaTitleUpdateToggleInvalidAttempt,
  pageMetaTitleUpdateWaitingForServer,
  pageUrlUpdateErrorMessage,
  pageUrlUpdateToggleInvalidAttempt,
  pageUrlUpdateWaitingForServer,
  publishPageToggleWaitingForServer,
  removeSingleWebsitePageFromStore,
  removeWebsitePagesFromStore,
  replaceWebsitesPagesInState,
  saveWebsitesPagesInState,
  toggleAllWebsitePagesFetched,
  toggleNewWebsitePageEditionsDetected,
  toggleWaitingForCommonWebsitePageUpdate,
  toggleWebsitePageChangeInProgress,
  toggleWaitingForMovePageToAnotherWebsite,
  toggleIsPageNavHidden,
  toggleIsPageFooterHidden,
  toggleNewWebsitePageNavEditionsDetected,
  savePageComponentsInState,
  toggleIsPageEmpty,
  changeComponentGlobalData,
  changeWebsitePagesStateGlobalData,
  changePageItemGlobalData,
} from "../../store/websitePages/actions";
import {
  changeBlogPostBody,
  changeBlogPostComponentBackgroundColor,
  changeBlogPostComponentBackgroundImage,
  changeBlogPostTitle,
  changePostEditorType,
  changePostEditorTypeWaitingForServer,
  cloneBlogPostWaitingForServer,
  createBlogPostErrorMessage,
  createBlogPostWaitingForServer,
  deleteBlogPostComponent,
  deleteBlogPostWaitingForServer,
  editBlogPostMainTitleSchemeToggleWaitingForServer,
  editBlogPostSchemeToggleWaitingForServer,
  fetchAllBlogPostsWithoutContent,
  moveBlogPostComponent,
  postMetaDescriptionUpdateErrorMessage,
  postMetaDescriptionUpdateToggleInvalidAttempt,
  postMetaDescriptionUpdateWaitingForServer,
  postMetaTitleUpdateErrorMessage,
  postMetaTitleUpdateToggleInvalidAttempt,
  postMetaTitleUpdateWaitingForServer,
  postUrlUpdateErrorMessage,
  postUrlUpdateToggleInvalidAttempt,
  postUrlUpdateWaitingForServer,
  publishPostToggleWaitingForServer,
  removeBlogPostsFromStore,
  removeSingleBlogPostFromStore,
  replaceBlogsPostsInState,
  saveBlogsPostsInState,
  toggleAllBlogPostsFetched,
  toggleBlogPostChangeInProgress,
  toggleNewBlogPostEditionsDetected,
  toggleNewBlogPostMainTitleEditionsDetected,
  toggleWaitingForCommonBlogPostUpdate,
  saveBlogPostContentInState,
  changeBlogPostData,
} from "../../store/blogPosts/actions";
import { getCurrentWebsiteSubdomain } from "../../helpers/getCurrentWebsiteSubdomain";
import Nav from "../dashboard/Nav";
import Editor from "../dashboard/Editor";
import { toggleProcessingSystemReady } from "../../store/payment/actions";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  PATH_HISTORY_LS_KEY,
  indexWebsitePageDashboardUrl,
  notificationDuration,
} from "../../data/constants";
import { api } from "../../data/urls";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import { fetchPageComponentCategories } from "../../store/pageComponentCategories/actions";
import { fetchPageComponents } from "../../store/pageComponents/actions";
import { fetchFormIntegrations } from "../../store/formIntegrations/actions";
import { fetchSharedAccesses } from "../../store/sharedAccesses/actions";
import { fetchMeta, fetchCache } from "../../store/meta/actions";
import { getFullPageUrl } from "../../helpers/editor/getFullPageUrl";
import { getFullPostUrl } from "../../helpers/editor/getFullPostUrl";
import { checkIfPageHasComponents } from "../../helpers/editor/checkIfPageHasComponents";
import { getCurrentWebsitePage } from "../../helpers/getCurrentWebsitePage";
import { getCurrentWebsitePageUrl } from "../../helpers/getCurrentWebsitePageUrl";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { getCurrentBlog } from "../../helpers/blog/getCurrentBlog";
// import Intercom from 'react-intercom';
import getUnixTime from "../../helpers/getUnixTime";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import trimStringTo from "../../helpers/strings/trimStringTo";

import BlogPostEditor from "../blog/BlogPostEditor";
import { footerSchemeOptions } from "../../data/footerSchemeOptions";
import composeSortedArray from "../../helpers/composeSortedArray";
import ContentInput from "../editor/ContentInput";
import { applyCustomColorsClassnames } from "../../helpers/editor/applyCustomColorsClassnames";
import ContentEditionBox from "../editor/ContentEditionBox/ContentEditionBox";
import IntegrationsMarketplaceDrawer from "../editor/formIntegration/IntegrationsMarketplaceDrawer";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import SpinnerBox from "../dashboard/SpinnerBox";
import IllustratedSpinnerBox from "../dashboard/IllustratedSpinnerBox";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import {
  ChangeWebsiteNavIntegrationId,
  ChangeWebsitesStateGlobalData,
  EditFillTemplateData,
  GetCurrentStoreData,
  SaveWebsiteBackup,
  ToggleLoadedWebsitesData,
} from "../../store/websites/types";
import {
  ChangeComponentGlobalData,
  ChangePageItemGlobalData,
  ChangeWebsitePagesStateGlobalData,
  SavePageComponentsInState,
  ToggleIsPageEmpty,
} from "../../store/websitePages/types";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import {
  SaveUserEventsInStore,
  SetUserStateData,
} from "../../store/user/types";
import { setUserEventsAsync } from "../../helpers/user/setUserEventsAsync";
import { UserEvents } from "../../enums/UserEvents";
import { CustomColorData } from "../../helpers/types/globalTypes";
import DuePaymentAlert from "../dashboard/DuePaymentAlert";
import {
  GetCurrentChatData,
  HandleOnUnmount,
  SetMode,
} from "../../store/gpt/types";
import { setMode } from "../../store/gpt/actions";
import { getCurrentChatData } from "../../store/gpt/thunks/getCurrentChatData";
import { getAccessTokenCookie } from "../../helpers/auth/accessTokenCookie";
import { handleOnUnmount } from "../../store/gpt/thunks/handleOnUnmount";
import _ from "lodash";
import {
  createAndSavePageAsync,
  fetchAndSavePageTemplatesAsync,
} from "../../store/websitePages/thunks";
import TemplateSelect from "../dashboard/TemplateSelect";
import {
  createWebsiteFromTemplate,
  getCurrentStoreData,
  saveWebsiteBackup,
} from "../../store/websites/thunks";
import generateRandomSubdomain from "../../helpers/strings/generateRandomSubdomain";
import ChangelogBannerNew from "../dashboard/ChangelogBannerNew";
import { validatePath } from "../../helpers/validatePath";
import { sortByDateFn } from "../../helpers/sortByDateFn";
import { ChangeBlogPostData } from "../../store/blogPosts/types";
import PageTemplatesList from "../dashboard/PageTemplatesList";
import { redirectToPagesOrBlog } from "../../helpers/redirects/redirectToPagesOrBlog";
import { redirectPages } from "../../helpers/redirects/redirectPages";
import { redirectBlog } from "../../helpers/redirects/redirectBlog";
import { createAndSavePost } from "../../store/blogPosts/thunks";
import { ChangeBlogNavIntegrationId } from "../../store/blogs/types";
import addNoCacheParamToURL from "../../helpers/editor/addNoCacheParamToURL";

const { Header, Content } = Layout;

interface Props {
  auth: any;
  user: any;
  plans: any;
  meta: any;
  templates: any;
  websites: any;
  websitesPages: any;
  blogs: any;
  blogPosts: any;
  payment: any;
  formIntegrations: any;
  history: any;
  sharedAccesses: any;
  gpt: any;

  pageComponents: any;
  pageComponentCategories: any;

  emailConfirmationToggleServerResponded: object;
  saveAccessTokenInState: object;
  toggleAccessTokenIsValid: any;
  accessTokenToggleServerResponded: any;
  saveUserDataInState: any;
  toggleUserDataFetched: any;
  togglePlansFetched: object;
  savePlansInState: object;
  toggleTemplatesFetched: object;
  saveTemplatesInState: object;
  toggleWebsitesFetched: object;
  saveWebsitesInState: any;
  saveWebsitesPagesInState: any;
  toggleAllWebsitePagesFetched: object;
  validateUserAccessTokenInDashboard: any;
  fetchCurrentUserInfo: any;
  fetchPlans: any;
  fetchTemplates: any;
  fetchWebsites: any;
  fetchBlogs: any;
  fetchWebsitePagesWithoutComponents: any;
  fetchAllBlogPostsWithoutContent: any;
  logout: any;
  toggleCancellingPro: any;
  toggleUserPro: any;
  toggleUserLtd: any;
  toggleUserAppsumo: any;
  toggleProcessingSystemReady: any;
  toggleSwitchingProPlanInProgress: any;
  toggleUpgradingFromFreeToProInProgress: any;
  subdomainUpdateWaitingForServer: any;
  subdomainUpdateToggleInvalidAttempt: any;
  subdomainUpdateErrorMessage: any;

  customDomainUpdateWaitingForServer: any;
  customDomainUpdateToggleInvalidAttempt: any;
  customDomainUpdateErrorMessage: any;

  websiteCustomCodeUpdateWaitingForServer: any;
  websiteCustomCodeUpdateToggleInvalidAttempt: any;
  websiteCustomCodeUpdateErrorMessage: any;

  websiteExtraCustomCodeUpdateWaitingForServer: any;
  websiteExtraCustomCodeUpdateToggleInvalidAttempt: any;
  websiteExtraCustomCodeUpdateErrorMessage: any;

  blogCustomCodeUpdateWaitingForServer: any;
  blogCustomCodeUpdateToggleInvalidAttempt: any;
  blogCustomCodeUpdateErrorMessage: any;

  blogExtraCustomCodeUpdateWaitingForServer: any;
  blogExtraCustomCodeUpdateToggleInvalidAttempt: any;
  blogExtraCustomCodeUpdateErrorMessage: any;

  blogCustomCodePostsUpdateWaitingForServer: any;
  blogCustomCodePostsUpdateToggleInvalidAttempt: any;
  blogCustomCodePostsUpdateErrorMessage: any;

  blogExtraCustomCodePostsUpdateWaitingForServer: any;
  blogExtraCustomCodePostsUpdateToggleInvalidAttempt: any;
  blogExtraCustomCodePostsUpdateErrorMessage: any;

  blogMetaTitleUpdateToggleInvalidAttempt: any;
  blogMetaTitleUpdateErrorMessage: any;
  blogMetaTitleUpdateWaitingForServer: any;

  blogMetaDescriptionUpdateToggleInvalidAttempt: any;
  blogMetaDescriptionUpdateErrorMessage: any;
  blogMetaDescriptionUpdateWaitingForServer: any;

  toggleSharedAccessChangeWaitingForServer: any;
  addNewSharedAccessItemInState: any;
  removeSharedAccessItemFromState: any;

  customDomainToggleActivationWaitingForServer: any;
  sslToggleActivationWaitingForServer: any;

  toggleWebsitePageChangeInProgress: any;
  replaceWebsitesPagesInState: any;
  enableBrandingWaitingForServer: any;

  pageUrlUpdateWaitingForServer: any;
  pageUrlUpdateToggleInvalidAttempt: any;
  pageUrlUpdateErrorMessage: any;

  pageMetaTitleUpdateWaitingForServer: any;
  pageMetaTitleUpdateToggleInvalidAttempt: any;
  pageMetaTitleUpdateErrorMessage: any;

  pageMetaDescriptionUpdateWaitingForServer: any;
  pageMetaDescriptionUpdateToggleInvalidAttempt: any;
  pageMetaDescriptionUpdateErrorMessage: any;

  publishPageToggleWaitingForServer: any;

  createWebsiteWaitingForServer: any;
  createWebsiteErrorMessage: any;

  createWebsitePageWaitingForServer: any;
  createWebsitePageErrorMessage: any;

  saveSingleWebsiteInState: any;
  fetchSingleWebsitePages: any;

  cloneWebsiteWaitingForServer: any;
  deleteWebsiteWaitingForServer: any;

  removeWebsiteFromStore: any;
  removeWebsitePagesFromStore: any;
  deleteWebsitePageWaitingForServer: any;

  removeSingleWebsitePageFromStore: any;

  cloneWebsitePageWaitingForServer: any;
  fetchPageComponentCategories: any;
  fetchPageComponents: any;

  fetchFormIntegrations: any;
  fetchSharedAccesses: any;

  fetchMeta: any;
  fetchCache: () => Promise<void>;

  editWebsitePageSchemeToggleWaitingForServer: any;
  moveWebsitePageComponent: any;
  deleteWebsitePageComponent: any;
  changeWebsitePageComponentContent: any;
  toggleNewWebsitePageEditionsDetected: any;
  toggleNewWebsitePageNavEditionsDetected: any;
  addWebsitePageComponent: any;
  pasteCopiedWebsitePageComponent: any;

  saveWebsiteNavInState: any;
  saveWebsiteFooterInState: any;

  toggleWebsiteNavEditionsDetected: any;
  toggleWebsiteFooterEditionsDetected: any;

  toggleWaitingForResponseOnWebsiteFooterEditions: any;
  toggleWaitingForResponseOnWebsiteNavEditions: any;

  toggleWaitingForResponseOnWebsiteFormIntegrationAdd: any;
  saveWebsiteNewFormIntegrationInState: any;
  removeWebsiteFormIntegration: any;

  toggleWaitingForConnectPaymentIntegration: any;
  toggleWaitingForChangePaymentIntegrationProductsArray: any;
  toggleWaitingForEditPaymentIntegrationProductDetails: any;
  toggleWaitingForConfigurePaymentIntegration: any;

  toggleWaitingForResponseOnColorPaletteChange: any;
  toggleWaitingForResponseOnFontChange: any;
  changeWebsitePageComponentBackgroundColor: any;
  changeWebsitePageComponentBackgroundImage: any;
  changeWebsitePageComponentBackgroundImageOverlayOpacity: any;
  changeWebsitePageComponentPaddingTop: any;
  changeWebsitePageComponentPaddingBottom: any;
  changeWebsitePageComponentVisibility: any;
  changeWebsiteNavColor: any;
  toggleResendEmailConfirmationWaiting: any;

  changeStripeSecretKey: any;
  changeStripePublicKey: any;
  changeStripeCheckoutProductsArray: any;

  toggleWaitingForCommonWebsiteUpdate: any;
  toggleWaitingForWebsiteLanguagesUpdate: any;
  toggleWaitingForCommonWebsitePageUpdate: any;
  toggleWaitingForMovePageToAnotherWebsite: any;

  toggleIsPageNavHidden: any;
  toggleIsPageFooterHidden: any;

  saveBlogsInState: any;
  saveBlogsPostsInState: any;
  deleteBlogWaitingForServer: any;
  createBlogPostWaitingForServer: any;
  createBlogPostErrorMessage: any;
  removeBlogPostsFromStore: any;
  deleteBlogPostWaitingForServer: any;
  editBlogPostSchemeToggleWaitingForServer: any;
  cloneBlogPostWaitingForServer: any;
  removeSingleBlogPostFromStore: any;
  toggleBlogPostChangeInProgress: any;
  publishPostToggleWaitingForServer: any;
  replaceBlogsPostsInState: any;
  createBlogErrorMessage: any;
  createBlogWaitingForServer: any;
  removeBlogFromStore: any;
  saveSingleBlogInState: any;
  toggleNewBlogPostEditionsDetected: any;
  changeBlogPostBody: any;
  changeBlogPostTitle: any;
  toggleWaitingForCommonBlogUpdate: any;
  editBlogPostMainTitleSchemeToggleWaitingForServer: any;
  toggleNewBlogPostMainTitleEditionsDetected: any;

  updateActiveDashboardTab: any;

  urlUpdateWaitingForServer: any;
  urlUpdateToggleInvalidAttempt: any;
  urlUpdateErrorMessage: any;
  postUrlUpdateWaitingForServer: any;
  postUrlUpdateToggleInvalidAttempt: any;
  postUrlUpdateErrorMessage: any;
  postMetaTitleUpdateWaitingForServer: any;
  postMetaTitleUpdateToggleInvalidAttempt: any;
  postMetaTitleUpdateErrorMessage: any;
  postMetaDescriptionUpdateWaitingForServer: any;
  postMetaDescriptionUpdateToggleInvalidAttempt: any;
  postMetaDescriptionUpdateErrorMessage: any;

  toggleWaitingForCommonBlogPostUpdate: any;

  changePostEditorType: any;
  changePostEditorTypeWaitingForServer: any;

  toggleWaitingForResponseOnBlogNavEditions: any;
  toggleWaitingForResponseOnBlogFooterEditions: any;
  toggleBlogNavEditionsDetected: any;
  toggleBlogFooterEditionsDetected: any;

  saveBlogNavInState: any;
  saveBlogFooterInState: any;
  toggleCloudflareProtectionWaitingForServer: any;
  toggleWebsiteCaptchaEditionsDetected: any;
  toggleWaitingForResponseOnWebsiteCaptchaEditions: any;
  toggleWebsitePopupEditionsDetected: any;
  toggleWaitingForResponseOnWebsitePopupEditions: any;

  toggleLoadedWebsitesData?: ToggleLoadedWebsitesData;
  savePageComponentsInState?: SavePageComponentsInState;
  toggleIsPageEmpty?: ToggleIsPageEmpty;
  saveUserEventsInStore?: SaveUserEventsInStore;
  setMode: SetMode;
  getCurrentChatData: GetCurrentChatData;
  editFillTemplateData: EditFillTemplateData;
  editLoadedBlogsData: any;
  saveBlogPostContentInState: any;

  toggleIsWebsiteFromTemplateCreated: (payload: boolean) => void;
  handleOnUnmount: HandleOnUnmount;
  setUserStateData: SetUserStateData;
  createAndSavePageAsync: any;
  createWebsiteFromTemplate: any;
  changeComponentGlobalData: ChangeComponentGlobalData;
  changeWebsitePagesStateGlobalData: ChangeWebsitePagesStateGlobalData;
  getCurrentStoreData: GetCurrentStoreData;
  changePageItemGlobalData: ChangePageItemGlobalData;
  changeBlogPostData: ChangeBlogPostData;
  changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData;
  fetchAndSavePageTemplatesAsync: () => Promise<unknown>;
  saveWebsiteBackup: SaveWebsiteBackup;
  createAndSavePost: any;
  changeWebsiteNavIntegrationId: ChangeWebsiteNavIntegrationId;
  changeBlogNavIntegrationId: ChangeBlogNavIntegrationId;
}

type MyState = {
  activeTab: string;
  isFormVisible: boolean;
  formIntegrationsMarketplaceVisible: boolean;
  connectIntegrationVisible: boolean;
  navEditionFieldsVisible: boolean;
  footerEditionFieldsVisible: boolean;
  isWelcomeEmailSent: boolean;
};

class PrimaryLayout extends Component<Props, MyState> {
  generator = new generateContentDataObject();
  unlistenHistory;

  componentWillMount(): void {
    this.setState({
      activeTab: "1",
      isFormVisible: false,
      formIntegrationsMarketplaceVisible: false,
      connectIntegrationVisible: false,
      navEditionFieldsVisible: false,
      footerEditionFieldsVisible: false,
      isWelcomeEmailSent: false,
    });
  }

  componentDidMount(): void {
    // Uncomment to log page components on key press:
    // const handleKeyDown = (event) => {
    //   if (event.key === "\\") {
    //     const { currentWebsitePage } = this.props.getCurrentStoreData();
    //     console.log(_.get(currentWebsitePage, "page_components.data.schema"));
    //   }
    //   if (event.key === "]") {
    //     const { currentWebsitePage } = this.props.getCurrentStoreData();
    //     console.log(_.get(currentWebsitePage, "page_components.data.schema.0"));
    //   }
    // };
    // window.addEventListener("keydown", handleKeyDown);

    const referrer = sessionStorage.getItem("referrer");
    if (referrer === null) {
      sessionStorage.setItem(
        "referrer",
        document.referrer === "" ? "$direct" : document.referrer
      );
    }
    if (!getAccessTokenCookie()) {
      this.props.toggleAccessTokenIsValid(false);
      this.props.accessTokenToggleServerResponded(true);
      return;
    }
    this.props.validateUserAccessTokenInDashboard(this.fetchData);
    this.unlistenHistory = this.props.history.listen((location, action) => {
      const path = location.pathname;
      if (path !== "/") {
        localStorage.setItem(PATH_HISTORY_LS_KEY, path);
      }
    });
  }

  componentWillUnmount(): void {
    if (this.unlistenHistory) {
      this.unlistenHistory();
    }
  }

  componentDidUpdate(oldProps: any) {
    let newLocation = window.location.pathname;
    let oldLocation = oldProps.location.pathname;
    if (newLocation !== oldLocation) {
      try {
        //     (window as any).Intercom('update');
        this.renderOnlineChat();
      } catch (e) {
        console.log("Crisp error: \n ", e);
      }
    }
  }

  saveWebsitePageDataInStore = (
    response: any,
    currentWebsite: any,
    websitePages: any,
    websitePageUrl: string
  ) => {
    // Get current website id.
    let currentWebsiteId = currentWebsite.id;

    // Get current website pages set from the store.
    let oldWebsitesPagesArray = websitePages;
    // Copy it.
    let newWebsitesPagesArray = [...oldWebsitesPagesArray];
    // Find in the store the item which has been just updated. Same website AND same URL.
    let updatedWebsitePagesArrayItemIndex = newWebsitesPagesArray.findIndex(
      (websitePage: any) =>
        websitePage.website === currentWebsiteId &&
        websitePage.url === websitePageUrl
    );
    // Get the updated item object from the server.
    let updatedWebsiteData = response.data;
    // Populate the updated item with fresh data from the server.
    let updatedWebsitePagesArrayItem = { ...updatedWebsiteData };
    // Replace the old item with the updated item in the copied websites pages set.
    newWebsitesPagesArray[updatedWebsitePagesArrayItemIndex] =
      updatedWebsitePagesArrayItem;

    // The updated websites pages set is going to the redux store.
    this.props.replaceWebsitesPagesInState(newWebsitesPagesArray);
  };
  saveBlogPostDataInStore = (
    response: any,
    currentBlog: any,
    blogPosts: any,
    blogPostUrl: string
  ) => {
    // Get current blog id.
    let currentBlogId = currentBlog.id;

    // Get current blog posts set from the store.
    let oldBlogsPostsArray = blogPosts;
    // Copy it.
    let newBlogPostsArray = [...oldBlogsPostsArray];
    // Find in the store the item which has been just updated. Same blog id AND same URL.
    let updatedBlogPostsArrayItemIndex = newBlogPostsArray.findIndex(
      (blogPost: any) => {
        return blogPost.blog === currentBlogId && blogPost.url === blogPostUrl;
      }
    );
    // Get the updated item object from the server.
    let updatedBlogData = response.data;
    // Populate the updated item with fresh data from the server.
    let updatedBlogPostsArrayItem = { ...updatedBlogData };
    // Replace the old item with the updated item in the copied blogs posts set.
    newBlogPostsArray[updatedBlogPostsArrayItemIndex] =
      updatedBlogPostsArrayItem;

    // The updated blogs posts set is going to the redux store.
    this.props.replaceBlogsPostsInState(newBlogPostsArray);
  };
  saveWebsiteDataInStore = (response: any, currentSubdomain: string) => {
    //After sending a change in a website to the server, we get a response: full object of the updated instance. We put this new data in our local redux store array.

    // Get current websites set in the store.
    let oldWebsitesArray = this.props.websites.items;
    // Copy it.
    let newWebsitesArray = [...oldWebsitesArray];
    // Find in the store the item which has been just updated.
    let updatedWebsiteArrayItemIndex = newWebsitesArray.findIndex(
      (website: any) => website.subdomain === currentSubdomain
    );
    // Get the updated item object from the server.
    let updatedWebsiteData = response.data;
    // Populate the updated item with fresh data from the server.
    let updatedWebsiteArrayItem = { ...updatedWebsiteData };
    // Replace the old item with the updated item in the copied websites set.
    newWebsitesArray[updatedWebsiteArrayItemIndex] = updatedWebsiteArrayItem;

    // The updated websites set is going to the redux store.
    this.props.saveWebsitesInState(newWebsitesArray);
  };
  saveBlogDataInStore = (response: any, currentBlogId: number) => {
    //After sending a change in a blog to the server, we get a response: full object of the updated instance. We put this new data in our local redux store array.

    // Get current blogs set in the store.
    let oldBlogsArray = this.props.blogs.items;
    // Copy it.
    let newBlogsArray = [...oldBlogsArray];
    // Find in the store the item which has been just updated.
    let updatedBlogArrayItemIndex = newBlogsArray.findIndex(
      (blog: any) => blog.id === currentBlogId
    );

    // Get the updated item object from the server.
    let updatedBlogData = response.data;
    // Populate the updated item with fresh data from the server.
    let updatedBlogArrayItem = { ...updatedBlogData };
    // Replace the old item with the updated item in the copied websites set.
    newBlogsArray[updatedBlogArrayItemIndex] = updatedBlogArrayItem;

    // The updated blogs set is going to the redux store.
    this.props.saveBlogsInState(newBlogsArray);
  };
  displayNavOrFooterEditionBox = (
    currentWebsite: any,
    fields: any,
    saveContentInStore: any,
    toggleNewEditionsDetected: any,
    hide: any,
    type: string,
    isFirstComponentBgDark: boolean | undefined,
    isBlogObjectEdited: boolean,
    triggerComponentRemount: () => void
  ) => {
    // render edition of Nav of Footer

    // isBlogObjectEdited is needed to detect when we call displayFooter in the <BlogPostEditor>. We need another "save in state" function and we also need to get the nav/footer object not from website but from blog model.
    // if editing blog Nav/Footer - isBlogObjectEdited=true
    // if editing website Nav/Footer - isBlogObjectEdited=false

    let currentBlog = getCurrentBlog(this.props.blogs.items, currentWebsite);

    let validateFooterInputs = (currentInputType: string): boolean => {
      // We to check if current footer scheme has the contentInput or not.

      // footerObject contains links, buttons, logo and any other footer-related objects. We have different footers for website and blog.
      let footerObject: any;
      if (isBlogObjectEdited) {
        footerObject = currentBlog.footer;
      } else {
        footerObject = currentWebsite.footer;
      }
      let currentFooterSchemeIndex = footerObject.settings.scheme; //fields === currentWebsite or currentBlog . (dot) nav or footer
      let currentFooterSchemeRulesObject = footerSchemeOptions.find(
        (item: any) => {
          return item.schemeIndex === currentFooterSchemeIndex;
        }
      );

      if (
        currentFooterSchemeRulesObject.hasCopyrightMarkup &&
        currentInputType === "text"
      ) {
        return true;
      } else if (
        currentFooterSchemeRulesObject.hasSocialLinks &&
        currentInputType === "socialLinks"
      ) {
        return true;
      } else if (
        currentFooterSchemeRulesObject.hasCta &&
        currentInputType === "cta"
      ) {
        return true;
      } else if (
        currentFooterSchemeRulesObject.hasLogo &&
        currentInputType === "logos"
      ) {
        return true;
      } else if (
        currentFooterSchemeRulesObject.hasPosts &&
        currentInputType === "posts"
      ) {
        return true;
      } else if (
        currentFooterSchemeRulesObject.hasContacts &&
        currentInputType === "contacts"
      ) {
        return true;
      } else if (
        currentFooterSchemeRulesObject.hasGroupsOfLinks &&
        currentInputType === "list"
      ) {
        return true;
      } else {
        return false;
      }
    };

    let displayEditionFields = () => {
      let dataSortedArray = composeSortedArray(fields);

      // create a list of all inputs
      const contentInputsArray = []; //an array of JSX components

      let componentInputsCount = dataSortedArray.length;
      for (let i = 0; i < componentInputsCount; i++) {
        let currentItem = dataSortedArray[i];
        let currentInputType = currentItem[0]; //e.g. "navLogo" or "navLinks" or "footerCopyright"
        let currentInputData = currentItem[1];
        let currentInputsArrayLength = contentInputsArray.length;

        let pushItem = () => {
          contentInputsArray.push(
            <ContentInput
              saveContentInStore={saveContentInStore}
              toggleNewEditionsDetected={toggleNewEditionsDetected}
              contentType={currentInputType}
              currentInputData={currentInputData}
              currentWebsite={currentWebsite}
              key={currentInputsArrayLength}
              toggleWaitingForCommonWebsiteUpdate={
                this.props.toggleWaitingForCommonWebsiteUpdate
              }
              showFormIntegrationsDrawer={this.showFormIntegrationsDrawer}
              removeWebsiteFormIntegration={
                this.props.removeWebsiteFormIntegration
              }
              componentType={type}
              componentTitle={"01"} //currently we have only one type of nav an footer
              saveImageInfoInDB={this.saveImageInfoInDB}
              removeImageFromDB={this.removeImageFromDB}
              isFirstComponentBgDark={isFirstComponentBgDark}
              user={this.props.user}
              websites={this.props.websites}
              auth={this.props.auth}
              toggleWaitingForConnectPaymentIntegration={
                this.props.toggleWaitingForConnectPaymentIntegration
              }
              toggleWaitingForChangePaymentIntegrationProductsArray={
                this.props.toggleWaitingForChangePaymentIntegrationProductsArray
              }
              toggleWaitingForEditPaymentIntegrationProductDetails={
                this.props.toggleWaitingForEditPaymentIntegrationProductDetails
              }
              toggleWaitingForConfigurePaymentIntegration={
                this.props.toggleWaitingForConfigurePaymentIntegration
              }
              changeStripeSecretKey={this.props.changeStripeSecretKey}
              changeStripePublicKey={this.props.changeStripePublicKey}
              changeStripeCheckoutProductsArray={
                this.props.changeStripeCheckoutProductsArray
              }
              saveWebsiteDataInStore={this.saveWebsiteDataInStore}
              isBlogObjectEdited={isBlogObjectEdited}
              currentBlog={currentBlog}
            />
          );
        };

        if (type === "footer") {
          if (validateFooterInputs(currentInputType)) {
            // If footer we need to check if the input exists in the current footer scheme (declared in footerSchemeOptions)
            pushItem();
          }
        } else {
          pushItem();
        }
      }

      let customColorsActiveClassnames =
        applyCustomColorsClassnames(currentWebsite);

      return (
        <div className="comps">
          <div
            className={
              currentWebsite.color_classname +
              " " +
              customColorsActiveClassnames
            }
          >
            <ul style={{ listStyle: "none" }}>{contentInputsArray}</ul>
          </div>
        </div>
      );
    };

    let saveWebsiteFooterBgColorInState = (
      color: string,
      customBgData?: CustomColorData
    ) => {
      let newFooterObject = {
        footerObject: this.generator.changeFooterBgColor(
          currentWebsite.footer,
          color
        ),
        currentWebsiteSubdomain: currentWebsite.subdomain,
      };
      if (!!customBgData) {
        newFooterObject = {
          footerObject: this.generator.changeFooterCustomBgColor(
            currentWebsite.footer,
            customBgData
          ),
          currentWebsiteSubdomain: currentWebsite.subdomain,
        };
      }
      this.props.saveWebsiteFooterInState(newFooterObject);
    };

    let saveBlogFooterBgColorInState = (
      color: string,
      customBgData?: CustomColorData
    ) => {
      let newFooterObject = {
        footerObject: this.generator.changeFooterBgColor(
          currentBlog.footer,
          color
        ),
        currentBlogId: currentBlog.id,
      };
      if (!!customBgData) {
        newFooterObject = {
          footerObject: this.generator.changeFooterCustomBgColor(
            currentBlog.footer,
            customBgData
          ),
          currentBlogId: currentBlog.id,
        };
      }
      this.props.saveBlogFooterInState(newFooterObject);
    };

    // we need to calculate currentPageIndex because some nav/footer settings are not global but specific for a particular page (hide/show, bg color)
    // when invoked from blog currentPageIndex is -1
    let currentWebsitePageUrl = getCurrentWebsitePageUrl(
      currentWebsite,
      this.props.websitesPages.items
    );
    let currentWebsitePage = getCurrentWebsitePage(
      currentWebsitePageUrl,
      this.props.websitesPages.items,
      currentWebsite
    );
    let currentPageIndex =
      this.props.websitesPages.items.indexOf(currentWebsitePage);

    return (
      <div>
        <ContentEditionBox
          toggleWebsiteFooterEditionsDetected={
            this.props.toggleWebsiteFooterEditionsDetected
          }
          toggleWebsiteNavEditionsDetected={
            this.props.toggleWebsiteNavEditionsDetected
          }
          saveWebsiteFooterInState={this.props.saveWebsiteFooterInState}
          saveWebsiteNavInState={this.props.saveWebsiteNavInState}
          toggleBlogFooterEditionsDetected={
            this.props.toggleBlogFooterEditionsDetected
          }
          toggleBlogNavEditionsDetected={
            this.props.toggleBlogNavEditionsDetected
          }
          saveBlogFooterInState={this.props.saveBlogFooterInState}
          saveBlogNavInState={this.props.saveBlogNavInState}
          isBlogObjectEdited={isBlogObjectEdited}
          currentBlog={currentBlog}
          currentWebsite={currentWebsite}
          componentType={type}
          displayFields={displayEditionFields}
          hide={hide}
          saveComponentBackgroundColorInStore={
            isBlogObjectEdited
              ? saveBlogFooterBgColorInState
              : saveWebsiteFooterBgColorInState
          }
          saveImageInfoInDB={this.saveImageInfoInDB}
          removeImageFromDB={this.saveImageInfoInDB}
          toggleIsPageNavHidden={this.props.toggleIsPageNavHidden}
          toggleIsPageFooterHidden={this.props.toggleIsPageFooterHidden}
          currentPageIndex={currentPageIndex}
          currentWebsitePage={currentWebsitePage}
          toggleNewWebsitePageNavEditionsDetected={
            this.props.toggleNewWebsitePageNavEditionsDetected
          }
          triggerComponentRemount={triggerComponentRemount}
        />
        <IntegrationsMarketplaceDrawer
          updateIntegrationId={(integrationId) => {
            if (isBlogObjectEdited) {
              this.props.changeBlogNavIntegrationId({
                blogId: _.get(currentBlog, "id"),
                integrationId,
              });
              this.props.toggleBlogNavEditionsDetected(true);
              return;
            }
            this.props.changeWebsiteNavIntegrationId({
              websiteId: _.get(currentWebsite, "id"),
              integrationId,
            });
            this.props.toggleWebsiteNavEditionsDetected(true);
          }}
          formIntegrationsMarketplaceVisible={
            this.state.formIntegrationsMarketplaceVisible
          }
          connectIntegrationVisible={this.state.connectIntegrationVisible}
          closeFormIntegrationsDrawer={this.closeFormIntegrationsDrawer}
          closeConnectFormIntegrationDrawer={
            this.closeConnectFormIntegrationDrawer
          }
          history={this.props.history}
          currentWebsite={currentWebsite}
          user={this.props.user}
          componentItem={currentWebsite.nav} //this is needed for for Google Sheet integration to get the current form fields to create a sheet with columns
          saveWebsiteNewFormIntegrationInState={
            this.props.saveWebsiteNewFormIntegrationInState
          }
          toggleNewWebsitePageEditionsDetected={
            this.props.toggleNewWebsitePageEditionsDetected
          }
          websites={this.props.websites}
          formIntegrations={this.props.formIntegrations}
          showConnectFormIntegrationDrawer={
            this.showConnectFormIntegrationDrawer
          }
          toggleWaitingForResponseOnWebsiteFormIntegrationAdd={
            this.props.toggleWaitingForResponseOnWebsiteFormIntegrationAdd
          }
          auth={this.props.auth}
        />
      </div>
    );
  };

  publishPage = (
    checked: boolean,
    websitePageUrl: string,
    currentWebsite: any,
    publishButtonLocation?:
      | "editor"
      | "pageSettings"
      | "websiteSettings"
      | "pagesScreen"
  ) => {
    // Toggle publish page: on/off.

    this.props.toggleWebsitePageChangeInProgress(true);
    this.props.publishPageToggleWaitingForServer(true);
    let accessToken = this.props.auth.accessToken;

    // Our server API expects the 'indexWebsitePageDashboardUrl' as an URL kwarg because a URL of a home page ('') is in appropriate for API and the dashboard.
    let websitePageUrlToServer = websitePageUrl;
    if (websitePageUrl === "") {
      websitePageUrlToServer = indexWebsitePageDashboardUrl;
    }

    const { currentWebsitePage } = this.props.getCurrentStoreData();

    let apiUrl =
      api.websitePages.updateWebsitePage.prefix +
      getCurrentWebsiteSubdomain(this.props.websites.items) +
      "/" +
      websitePageUrlToServer +
      api.websitePages.updateWebsitePage.postfix;
    axios
      .patch(
        apiUrl,
        {
          is_published: checked,
          first_page_title: currentWebsitePage.first_page_title,
          first_page_subtitle: currentWebsitePage.first_page_subtitle,
          hero_image_url: currentWebsitePage.hero_image_url,
          hero_image_uuid: currentWebsitePage.hero_image_uuid,
          subdomain: getCurrentWebsiteSubdomain(this.props.websites.items),
        },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        // We got a response from the server with an updated website page data - save it in the redux store.
        const dataToStore = {
          data: {
            ...currentWebsitePage,
            is_published: _.get(response, "data.is_published"),
            first_page_title: _.get(response, "data.first_page_title"),
            first_page_subtitle: _.get(response, "data.first_page_subtitle"),
            hero_image_url: _.get(response, "data.hero_image_url"),
            hero_image_uuid: _.get(response, "data.hero_image_uuid"),
          },
        };
        this.saveWebsitePageDataInStore(
          dataToStore,
          currentWebsite,
          this.props.websitesPages.items,
          websitePageUrl
        );

        let newPublicationStateMessage = (
          <span>
            The page has been unpublished. Visitors from the Internet can not
            access it.
          </span>
        );

        let fullPageUrl = getFullPageUrl(currentWebsite, websitePageUrl);

        if (checked) {
          let isWebsiteHasContent = checkIfPageHasComponents(
            getCurrentWebsitePage(
              websitePageUrl,
              this.props.websitesPages.items,
              currentWebsite
            ).page_components
          );
          if (isWebsiteHasContent) {
            newPublicationStateMessage = (
              <span>
                The page has been published. <br />
                It can be accessed here:
                <br />
                <a target="_blank" href={fullPageUrl}>
                  {trimStringTo(fullPageUrl, 70)}
                </a>
              </span>
            );
          } else {
            newPublicationStateMessage = (
              <span>The page has been successfully published.</span>
            );
          }
        }

        if (checked) {
          fireAnalyticsEvent.fireCrisp(CrispEvents.publishWebsitePage, {
            subdomain: currentWebsite.subdomain,
            page_url: response.data.url,
          });

          setUserEventsAsync(
            {
              [UserEvents.PublishedPageOnce]: new Date(),
            },
            accessToken,
            this.props.user.data.events
          ).then((response) => {
            if (response) {
              this.props.saveUserEventsInStore(response.data);
            }
          });
        } else {
          fireAnalyticsEvent.fireCrisp(CrispEvents.unpublishWebsitePage, {
            subdomain: currentWebsite.subdomain,
            page_url: response.data.url,
          });
        }
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);
          handleBadWebsiteError(errorData);

          if (checked) {
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.publishWebsitePageError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                subdomain: currentWebsite.subdomain,
                page_url: websitePageUrlToServer,
              },
              true
            );
          } else {
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.unpublishWebsitePageError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                subdomain: currentWebsite.subdomain,
                page_url: websitePageUrlToServer,
              },
              true
            );
          }

          if (errorData.limit !== undefined) {
            let message = errorData.limit;
            openNotification(
              "Limit reached",
              message,
              "Understood",
              "warn",
              10
            );

            if (!this.props.user.isPro) {
              setUserEventsAsync(
                {
                  [UserEvents.CouldNotPublishOnFree]: new Date(),
                },
                this.props.auth.accessToken,
                this.props.user.data.events
              ).then((response) => {
                if (response) {
                  this.props.saveUserEventsInStore(response.data);
                }
              });
            }
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "Close", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website page which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWebsitePageChangeInProgress(false);
        this.props.publishPageToggleWaitingForServer(false);
      });
  };

  publishPost = (
    status: string,
    currentWebsite: any,
    currentBlog: any,
    currentPost: any
  ) => {
    // Publish post status: draft / public / direct_url.

    this.props.toggleBlogPostChangeInProgress(true);
    this.props.publishPostToggleWaitingForServer(true);
    let accessToken = this.props.auth.accessToken;
    let currentPostUrl = currentPost.url;

    let apiUrl =
      api.blogPosts.updateBlogPost.prefix +
      getCurrentWebsiteSubdomain(this.props.websites.items) +
      "/" +
      currentPostUrl +
      api.blogPosts.updateBlogPost.postfix;

    axios
      .patch(
        apiUrl,
        {
          status: status,
          subdomain: getCurrentWebsiteSubdomain(this.props.websites.items),
        },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        // We got a response from the server with an updated blog post data - save it in the redux store.
        this.saveBlogPostDataInStore(
          response,
          currentBlog,
          this.props.blogPosts.items,
          currentPostUrl
        );

        let newPublicationStateMessage;
        let responseData = response.data;
        let fullPostUrl = getFullPostUrl(
          currentWebsite,
          currentBlog.url,
          currentPostUrl
        );

        if (responseData.status === "public") {
          newPublicationStateMessage = (
            <span>
              The post has been published. <br />
              It can be accessed here:
              <br />
              <a target="_blank" href={addNoCacheParamToURL(fullPostUrl)}>
                {trimStringTo(fullPostUrl, 70)}
              </a>
            </span>
          );

          fireAnalyticsEvent.fireCrisp(CrispEvents.publishBlogPost, {
            subdomain: currentWebsite.subdomain,
            post_url: response.data.url,
          });
        }

        if (responseData.status === "draft") {
          newPublicationStateMessage = (
            <span>
              The post has been unpublished. Visitors from the Internet can not
              access it.
            </span>
          );

          fireAnalyticsEvent.fireCrisp(CrispEvents.draftBlogPost, {
            subdomain: currentWebsite.subdomain,
            post_url: response.data.url,
          });
        }

        if (responseData.status === "direct_url") {
          newPublicationStateMessage = (
            <span>
              The post has been accessed only by link{" "}
              <a target="_blank" href={fullPostUrl}>
                {trimStringTo(fullPostUrl, 70)}
              </a>
              . <br />
              But the post is not published on the blog home page
            </span>
          );

          fireAnalyticsEvent.fireCrisp(CrispEvents.directUrlBlogPost, {
            subdomain: currentWebsite.subdomain,
            post_url: response.data.url,
          });
        }
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          if (status === "public") {
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.publishBlogPostError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                subdomain: currentWebsite.subdomain,
                post_url: currentPostUrl,
              },
              true
            );
          }

          if (status === "draft") {
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.draftBlogPostError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                subdomain: currentWebsite.subdomain,
                post_url: currentPostUrl,
              },
              true
            );
          }

          if (status === "direct_url") {
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.directUrlBlogPostError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                subdomain: currentWebsite.subdomain,
                post_url: currentPostUrl,
              },
              true
            );
          }
          handleBadWebsiteError(errorData);
          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "Close", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website page which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleBlogPostChangeInProgress(false);
        this.props.publishPostToggleWaitingForServer(false);
      });
  };

  fetchData = async () => {
    // component will mount many times and we don't want to fetch each time new data, so we fetch only once and mark it as checked.
    if (this.props.user.dataFetched === false) {
      // Fetch query parameter for cache busting. Must be placed at the top because it is used in other fetches.
      if (!this.props.meta.cacheFetched) {
        await this.props.fetchCache();
      }
      // Fetch current user info and then fetch pricing plans.
      this.props.fetchCurrentUserInfo(this.props.fetchPlans);

      // get the page components and the categories so users can new ones in the Editor
      this.props.fetchPageComponents();
      this.props.fetchPageComponentCategories();

      // get possible form integrations so users can connect their forms to these services (the connection data is saved in each website: the connected_form_integrations field.)
      this.props.fetchFormIntegrations();

      // fetch shared accesses (collaboratos)
      this.props.fetchSharedAccesses();

      this.props.fetchMeta();
    }
    if (this.props.websites.dataFetched === false) {
      // After the websites array is fetched, we need to also fetch each page configuration
      this.props.fetchWebsites(this.props.fetchWebsitePagesWithoutComponents);

      // get templates
      this.props.fetchTemplates();
      this.props.fetchAndSavePageTemplatesAsync();
    }
    if (this.props.blogs.dataFetched === false) {
      // After the blogs array is fetched, we need to also fetch each page configuration
      this.props.fetchBlogs(this.props.fetchAllBlogPostsWithoutContent);
    }
  };

  saveImageInfoInDB = (file: any) => {
    // We store each uploaded file in the server to authorize the owner of the image when deleting it from Uploadcare. We also store additional info such as size and mimeType.
    /*
        file = {
            cdnUrl: "https://ucarecdn.com/b402c953-e3fe-4950-975d-3a4f0d665d2c/-/crop/370x551/0,0/-/preview/"
            cdnUrlModifiers: "-/crop/370x551/0,0/-/preview/"
            crop: {left: 0, top: 0, width: 370, height: 551}
            isImage: true
            isStored: true
            mimeType: "image/jpeg"
            name: "photo-1560780551-bd5d3eacd2c9"
            originalImageInfo: {color_mode: "RGB", orientation: null, format: "JPEG", height: 3201, width: 2134, …}
            originalUrl: "https://ucarecdn.com/b402c953-e3fe-4950-975d-3a4f0d665d2c/"
            size: 601455
            sourceInfo: {source: "url-tab"}
            uuid: "b402c953-e3fe-4950-975d-3a4f0d665d2c"
        }
        * */

    if (file) {
      let currentWebsite = getCurrentWebsite(this.props.websites.items);
      let currentWebsiteSubdomain = currentWebsite.subdomain;

      let currentWebsitePageUrl = getCurrentWebsitePageUrl(
        currentWebsite,
        this.props.websitesPages.items
      );

      let accessToken = this.props.auth.accessToken;
      let apiUrl =
        api.images.createImage.prefix +
        currentWebsiteSubdomain +
        api.images.createImage.postfix;
      axios
        .post(
          apiUrl,
          {
            // subdomain: currentWebsiteSubdomain,
            uuid: file.uuid,
            cdn_url: file.cdnUrl,
            original_url: file.originalUrl,
            original_image_info:
              file.originalImageInfo === null ? "" : file.originalImageInfo,
            size_bytes: file.size,

            name: file.name,
            width: file.crop === undefined ? null : file.crop.width,
            height: file.crop === undefined ? null : file.crop.height,
            mime_type: file.mimeType,
            website_page_url: currentWebsitePageUrl,
          },
          { ...returnAuthHeaderForAJAX(accessToken) }
        )
        .then((response) => {
          fireAnalyticsEvent.fireCrisp(CrispEvents.saveImageOnServer, {
            image_url: file.originalUrl,
            uuid: file.uuid,
            size_bytes: file.size,
          });
        })
        .catch((error) => {
          // handle error
          if (error.response) {
            let errorData = error.response.data;

            let errorObjectKey = getErrorKey(errorData);
            let errorObjectValue = getErrorValue(errorData, errorObjectKey);

            fireAnalyticsEvent.fireCrisp(
              CrispEvents.saveImageOnServerError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                image_url: file.originalUrl,
                uuid: file.uuid,
              },
              true
            );

            // if (errorData.uuid !== undefined){
            //     let message = errorData.subdomain.join(' ');
            //     // this.props.createWebsiteErrorMessage(message);
            // }
            //
            // if (errorData.not_pro !== undefined){
            //     let message = errorData.not_pro;
            //     openNotification('Denied', message, 'OK', 'warn');
            // }
            //
            // if (errorData.limit !== undefined){
            //     let message = errorData.limit;
            //     openNotification('Limit reached!', message, 'OK', 'warn');
            // }
            //
            // if (errorData.locked !== undefined){
            //     let message = errorData.locked;
            //     openNotification('Denied', message, 'OK', 'warn');
            // }
            //
            // if (errorData.detail !== undefined){
            //     let message = errorData.detail;
            //
            //     // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            //     openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
            // }
          }
        })
        .then((response) => {});
    } else {
      openNotification(
        "Error :(",
        "There was an unknown error during uploading the file. Please refresh the page and try again.",
        "OK",
        "warn"
      );
    }
  };
  removeImageFromDB = (uuid: any) => {
    // uuid can be '' in case it's served from a direct hardcoded URL - e.g. for the placeholder images.
    // if uuid === '' no need to delete the record from the DB. There is no such record.
    if (uuid && uuid !== "") {
      let accessToken = this.props.auth.accessToken;
      let currentWebsiteSubdomain = getCurrentWebsiteSubdomain(
        this.props.websites.items
      );
      let apiUrl =
        api.images.deleteImage.prefix +
        currentWebsiteSubdomain +
        "/" +
        uuid +
        api.images.deleteImage.postfix;
      axios
        .delete(apiUrl, { ...returnAuthHeaderForAJAX(accessToken) })
        .then((response) => {
          fireAnalyticsEvent.fireCrisp(CrispEvents.removeImageFromServer, {
            uuid,
          });
        })
        .catch((error) => {
          // handle error
          if (error.response) {
            let errorData = error.response.data;

            let errorObjectKey = getErrorKey(errorData);
            let errorObjectValue = getErrorValue(errorData, errorObjectKey);

            fireAnalyticsEvent.fireCrisp(
              CrispEvents.removeImageFromServerError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                uuid,
              },
              true
            );

            // if (errorData.uuid !== undefined){
            //     let message = errorData.uuid.join(' ');
            //     openNotification('Error', message, 'OK', 'warn');
            // }
            //
            // if (errorData.not_pro !== undefined){
            //     let message = errorData.not_pro;
            //     openNotification('Denied', message, 'OK', 'warn');
            // }
            //
            // if (errorData.limit !== undefined){
            //     let message = errorData.limit;
            //     openNotification('Limit reached!', message, 'OK', 'warn');
            // }
            //
            // if (errorData.locked !== undefined){
            //     let message = errorData.locked;
            //     openNotification('Denied', message, 'OK', 'warn');
            // }
            //
            // if (errorData.detail !== undefined){
            //     let message = errorData.detail;
            //
            //     openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
            // }
          }
        })
        .then((response) => {});
    }
  };
  showNavEditionFieldsDrawer = () => {
    this.setState({
      ...this.state,
      navEditionFieldsVisible: true,
    });
  };
  showFooterEditionFieldsDrawer = () => {
    this.setState({
      ...this.state,
      footerEditionFieldsVisible: true,
    });
  };
  hideNavEditionFieldsDrawer = () => {
    this.setState({
      ...this.state,
      navEditionFieldsVisible: false,
    });
  };
  hideFooterEditionFieldsDrawer = () => {
    this.setState({
      ...this.state,
      footerEditionFieldsVisible: false,
    });
  };
  showFormIntegrationsDrawer = () => {
    let currentWebsite = getCurrentWebsite(this.props.websites.items);
    fireAnalyticsEvent.fireCrisp(CrispEvents.openAddNewIntegrationWindow, {
      subdomain: currentWebsite.subdomain,
    });
    this.setState({
      ...this.state,
      formIntegrationsMarketplaceVisible: true,
    });
  };
  closeFormIntegrationsDrawer = () => {
    this.setState({
      ...this.state,
      formIntegrationsMarketplaceVisible: false,
    });
  };

  showConnectFormIntegrationDrawer = () => {
    this.setState({
      ...this.state,
      connectIntegrationVisible: true,
    });
  };
  closeConnectFormIntegrationDrawer = () => {
    this.setState({
      ...this.state,
      connectIntegrationVisible: false,
    });
  };

  renderOnlineChat = () => {
    if (
      this.props.user.dataFetched &&
      this.props.plans.dataFetched &&
      this.props.websites.dataFetched
    ) {
      let data = this.props.user.data;

      let allWebsitesSubdomains = "";
      let allWebsitesIntegrationsTitles = "";

      let websitesCount = this.props.websites.items.length;
      for (let i = 0; i < websitesCount; i++) {
        let currentWebsite = this.props.websites.items[i];
        allWebsitesSubdomains +=
          currentWebsite.subdomain + " (ID: " + currentWebsite.id + " )" + ", ";

        let currentWebsiteIntegrations =
          currentWebsite.connected_form_integrations.connected;
        if (
          currentWebsiteIntegrations !== undefined &&
          currentWebsiteIntegrations.length > 0
        ) {
          let currentWebsiteIntegrationsCount =
            currentWebsiteIntegrations.length;
          for (let i = 0; i < currentWebsiteIntegrationsCount; i++) {
            allWebsitesIntegrationsTitles +=
              currentWebsiteIntegrations[i].integrationTitle + ", ";
          }
        }
      }
      let currentPlanTitle = "";
      let currentPlanPeriod = "";
      let currentPlanId = "";
      let currentPlanPrice = 0;

      let userPlanId = this.props.user.data.current_plan;
      let plansCount = this.props.plans.items.length;
      for (let i = 0; i < plansCount; i++) {
        let currentPlan = this.props.plans.items[i];
        if (currentPlan.id === userPlanId) {
          currentPlanTitle = currentPlan.title;
          currentPlanPeriod = currentPlan.period;
          currentPlanId = currentPlan.id;
          currentPlanPrice = currentPlan.price;
        }
      }

      const user = {
        user_id: data.id,
        email: data.email,
        name: data.first_name,
        last_name: data.last_name,
        created_at: getUnixTime(data.created),
        hide_default_launcher: true,
        paid_subscriber: this.props.user.isPro,
        all_integrations_titles: allWebsitesIntegrationsTitles,
        current_plan_title: currentPlanTitle,
        current_plan_period: currentPlanPeriod,
        current_plan_id: currentPlanId,
        current_plan_price: currentPlanPrice,
        websites_count: websitesCount,
        images_count: this.props.user.data.uploaded_images,
        is_email_verified: this.props.user.data.is_email_verified,
        websites_subdomains: allWebsitesSubdomains,
        ref_get_param: data.ref_get_param,
        next_payment_date: getUnixTime(data.next_payment_date),
        custom_attributes: {},
        utm_campaign: data.utm_campaign,
        utm_content: data.utm_content,
        utm_medium: data.utm_medium,
        utm_source: data.utm_source,
      };

      // let appID = getIntercomAppID();

      // return (
      // <Intercom appID={appID} { ...user } />
      // )
      // (window as any).$crisp.push(["set", "user:name", [user.name]], ["set", "user:last_name", [user.last_name]])
      //https://help.crisp.chat/en/article/how-can-i-automatically-set-custom-user-data-1xh7pqk/
      window.$crisp.push(["set", "user:email", [user.email]]);
      window.$crisp.push(["set", "user:nickname", [user.name]]);

      //https://help.crisp.chat/en/article/how-can-i-automatically-set-user-segments-oerdox/
      // let userSegments = [];
      // if(user.paid_subscriber){
      //     userSegments.push('paid');
      // }
      // (window as any).$crisp.push(["set", "session:segments", [[userSegments]]])
      (window as any).$crisp.push([
        "set",
        "session:data",
        [
          [
            // ["user_id", user.user_id],
            // ["email", user.email],
            // ["name", user.name],
            // ["created_at", user.created_at],
            // ["hide_default_launcher", user.hide_default_launcher],
            ["last_name", JSON.stringify(user.last_name)],
            ["paid_subscriber", JSON.stringify(user.paid_subscriber)],
            [
              "all_integrations_titles",
              JSON.stringify(user.all_integrations_titles),
            ],
            ["current_plan_title", JSON.stringify(user.current_plan_title)],
            ["current_plan_period", JSON.stringify(user.current_plan_period)],
            ["current_plan_id", JSON.stringify(user.current_plan_id)],
            ["current_plan_price", JSON.stringify(user.current_plan_price)],
            ["websites_count", user.websites_count],
            ["images_count", user.images_count],
            ["is_email_verified", JSON.stringify(user.is_email_verified)],
            ["websites_subdomains", JSON.stringify(user.websites_subdomains)],
            ["ref_get_param", JSON.stringify(user.ref_get_param)],
            ["next_payment_date", JSON.stringify(user.next_payment_date)],
            ["custom_attributes", JSON.stringify(user.custom_attributes)],
            ["utm_campaign", JSON.stringify(user.utm_campaign)],
            ["utm_content", JSON.stringify(user.utm_content)],
            ["utm_medium", JSON.stringify(user.utm_medium)],
            ["utm_source", JSON.stringify(user.utm_source)],
          ],
        ],
      ]);

      // if true - just signed up
      // let mySignUpEventInterval = setInterval(() => {
      // needsOnboarding only true when they signed up
      if (
        this.props.auth.needsOnboarding &&
        this.state.isWelcomeEmailSent === false
      ) {
        // console.log("tick");
        // if (this.state.isWelcomeEmailSent === false) {
        //     console.log("user:signup");
        this.setState({
          ...this.state,
          isWelcomeEmailSent: true,
        });
        // clearInterval(mySignUpEventInterval);
      }
      // }, 1000);
      // console.log('re-render chat with this data: ' , user);
      // return (null);
    } else {
      // return (null);
    }
  };

  render() {
    const currentWebsite = getCurrentWebsite(this.props.websites.items);
    const currentWebsitePageUrl = getCurrentWebsitePageUrl(
      currentWebsite,
      this.props.websitesPages.items
    );
    const currentWebsitePage = getCurrentWebsitePage(
      currentWebsitePageUrl,
      this.props.websitesPages.items,
      currentWebsite
    );
    if (this.props.auth.accessTokenValidated === false) {
      return (
        <div>
          <IllustratedSpinnerBox text="Initializing..." />
        </div>
      );
    }

    if (
      this.props.auth.accessTokenValidated === true &&
      this.props.auth.isAccessTokenValid === false
    ) {
      const params = window.location.search;
      return <Redirect to={`/auth/login${params}`} />;
    }
    return (
      <div>
        <ChangelogBannerNew />
        <Layout className="primary-layout">
          <Header>
            <div className="primary-layout__nav_container">
              <Nav
                websites={this.props.websites}
                websitesPages={this.props.websitesPages}
                blogs={this.props.blogs}
                blogPosts={this.props.blogPosts}
                user={this.props.user}
                auth={this.props.auth}
                plans={this.props.plans}
                logout={this.props.logout}
                history={this.props.history}
                publishPage={this.publishPage}
                publishPost={this.publishPost}
                toggleBlogPostChangeInProgress={
                  this.props.toggleBlogPostChangeInProgress
                }
                publishPostToggleWaitingForServer={
                  this.props.publishPostToggleWaitingForServer
                }
                saveBlogPostDataInStore={this.saveBlogPostDataInStore}
                saveImageInfoInDB={this.saveImageInfoInDB}
                removeImageFromDB={this.removeImageFromDB}
                updateActiveDashboardTab={this.props.updateActiveDashboardTab}
                saveWebsitePageDataInStore={this.saveWebsitePageDataInStore}
                saveWebsiteDataInStore={this.saveWebsiteDataInStore}
                saveBlogDataInStore={this.saveBlogDataInStore}
                changePageItemGlobalData={this.props.changePageItemGlobalData}
                getCurrentStoreData={this.props.getCurrentStoreData}
              />
            </div>
          </Header>
          <DuePaymentAlert />
          <Content>
            <canvas
              id="confetti-holder"
              style={{ width: 0, height: 0 }}
            ></canvas>
            <div>
              <Switch>
                <Route
                  exact
                  path="/:website_url"
                  render={(props) => {
                    const { websitesPages, blogPosts, websites, blogs } =
                      this.props;
                    if (
                      !websites.dataFetched ||
                      !websitesPages.pagesWithoutComponentsFetched ||
                      !blogPosts.allPostsWithoutContentFetched ||
                      !blogs.dataFetched
                    ) {
                      return (
                        <div style={{ marginTop: "60px" }}>
                          <SpinnerBox text="Loading website..." />
                        </div>
                      );
                    }
                    const { currentWebsite } = this.props.getCurrentStoreData();
                    if (!currentWebsite) {
                      return (
                        <Empty
                          description="Website not found"
                          style={{ marginTop: "88px" }}
                        >
                          <Link to={"/"}>
                            <Button type="primary">Back to websites</Button>
                          </Link>
                        </Empty>
                      );
                    }
                    const redirectUrl = redirectToPagesOrBlog({
                      currentWebsite,
                      websitesPages: this.props.websitesPages,
                      blogs: this.props.blogs,
                      blogPosts: this.props.blogPosts,
                    });
                    return <Redirect to={redirectUrl} />;
                  }}
                />
                <Route
                  exact
                  path="/:website_url/pages"
                  render={(props) => {
                    const { websitesPages, websites } = this.props;
                    if (
                      !websites.dataFetched ||
                      !websitesPages.pagesWithoutComponentsFetched
                    ) {
                      return (
                        <div style={{ marginTop: "60px" }}>
                          <SpinnerBox text="Loading pages..." />
                        </div>
                      );
                    }
                    const { currentWebsite } = this.props.getCurrentStoreData();
                    if (!currentWebsite) {
                      return (
                        <Empty
                          description="Website not found"
                          style={{ marginTop: "88px" }}
                        >
                          <Link to={"/"}>
                            <Button type="primary">Back to websites</Button>
                          </Link>
                        </Empty>
                      );
                    }
                    const redirectUrl = redirectPages({
                      currentWebsite,
                      websitesPages: this.props.websitesPages,
                    });
                    if (redirectUrl) {
                      return <Redirect to={redirectUrl} />;
                    }
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          margin: "64px 0",
                          gap: 12,
                        }}
                        className="page-templates__inline-container"
                      >
                        <Typography.Title level={2}>
                          Add the First Page
                        </Typography.Title>
                        <PageTemplatesList currentPageUrl="" />
                      </div>
                    );
                  }}
                />
                <Route
                  exact
                  path="/:website_url/pages/:website_page_id"
                  render={(props) => {
                    const { websites } = this.props;
                    if (!websites.dataFetched) {
                      return (
                        <div style={{ marginTop: "60px" }}>
                          <SpinnerBox text="Loading page..." />
                        </div>
                      );
                    }
                    const { currentWebsite } = this.props.getCurrentStoreData();
                    if (!currentWebsite) {
                      return (
                        <Empty
                          description="Website not found"
                          style={{ marginTop: "88px" }}
                        >
                          <Link to={"/"}>
                            <Button type="primary">Back to websites</Button>
                          </Link>
                        </Empty>
                      );
                    }
                    const { website_url } = props.match.params;
                    return (
                      <Editor
                        key={website_url} // We re-mount the editor when the website changes
                        saveWebsiteBackup={this.props.saveWebsiteBackup}
                        pageComponents={this.props.pageComponents}
                        pageComponentCategories={
                          this.props.pageComponentCategories
                        }
                        user={this.props.user}
                        auth={this.props.auth}
                        websitesPages={this.props.websitesPages}
                        websites={this.props.websites}
                        formIntegrations={this.props.formIntegrations}
                        history={props.history}
                        changeWebsitePageComponentVisibility={
                          this.props.changeWebsitePageComponentVisibility
                        }
                        editWebsitePageSchemeToggleWaitingForServer={
                          this.props.editWebsitePageSchemeToggleWaitingForServer
                        }
                        moveWebsitePageComponent={
                          this.props.moveWebsitePageComponent
                        }
                        toggleNewWebsitePageEditionsDetected={
                          this.props.toggleNewWebsitePageEditionsDetected
                        }
                        deleteWebsitePageComponent={
                          this.props.deleteWebsitePageComponent
                        }
                        changeWebsitePageComponentContent={
                          this.props.changeWebsitePageComponentContent
                        }
                        addWebsitePageComponent={
                          this.props.addWebsitePageComponent
                        }
                        pasteCopiedWebsitePageComponent={
                          this.props.pasteCopiedWebsitePageComponent
                        }
                        changeWebsitePageComponentBackgroundColor={
                          this.props.changeWebsitePageComponentBackgroundColor
                        }
                        changeWebsitePageComponentBackgroundImage={
                          this.props.changeWebsitePageComponentBackgroundImage
                        }
                        changeWebsitePageComponentBackgroundImageOverlayOpacity={
                          this.props
                            .changeWebsitePageComponentBackgroundImageOverlayOpacity
                        }
                        changeWebsitePageComponentPaddingTop={
                          this.props.changeWebsitePageComponentPaddingTop
                        }
                        changeWebsitePageComponentPaddingBottom={
                          this.props.changeWebsitePageComponentPaddingBottom
                        }
                        saveWebsiteNavInState={this.props.saveWebsiteNavInState}
                        saveWebsiteFooterInState={
                          this.props.saveWebsiteFooterInState
                        }
                        toggleWebsiteNavEditionsDetected={
                          this.props.toggleWebsiteNavEditionsDetected
                        }
                        toggleWebsiteFooterEditionsDetected={
                          this.props.toggleWebsiteFooterEditionsDetected
                        }
                        toggleWaitingForResponseOnWebsiteFooterEditions={
                          this.props
                            .toggleWaitingForResponseOnWebsiteFooterEditions
                        }
                        toggleWaitingForResponseOnWebsiteNavEditions={
                          this.props
                            .toggleWaitingForResponseOnWebsiteNavEditions
                        }
                        saveWebsiteNewFormIntegrationInState={
                          this.props.saveWebsiteNewFormIntegrationInState
                        }
                        removeWebsiteFormIntegration={
                          this.props.removeWebsiteFormIntegration
                        }
                        toggleWaitingForResponseOnWebsiteFormIntegrationAdd={
                          this.props
                            .toggleWaitingForResponseOnWebsiteFormIntegrationAdd
                        }
                        toggleWaitingForConnectPaymentIntegration={
                          this.props.toggleWaitingForConnectPaymentIntegration
                        }
                        toggleWaitingForChangePaymentIntegrationProductsArray={
                          this.props
                            .toggleWaitingForChangePaymentIntegrationProductsArray
                        }
                        toggleWaitingForEditPaymentIntegrationProductDetails={
                          this.props
                            .toggleWaitingForEditPaymentIntegrationProductDetails
                        }
                        toggleWaitingForConfigurePaymentIntegration={
                          this.props.toggleWaitingForConfigurePaymentIntegration
                        }
                        toggleWaitingForCommonWebsiteUpdate={
                          this.props.toggleWaitingForCommonWebsiteUpdate
                        }
                        changeWebsiteNavColor={this.props.changeWebsiteNavColor}
                        removeImageFromDB={this.removeImageFromDB}
                        saveImageInfoInDB={this.saveImageInfoInDB}
                        changeStripeSecretKey={this.props.changeStripeSecretKey}
                        changeStripePublicKey={this.props.changeStripePublicKey}
                        changeStripeCheckoutProductsArray={
                          this.props.changeStripeCheckoutProductsArray
                        }
                        saveWebsiteDataInStore={this.saveWebsiteDataInStore}
                        toggleIsPageNavHidden={this.props.toggleIsPageNavHidden}
                        toggleIsPageFooterHidden={
                          this.props.toggleIsPageFooterHidden
                        }
                        toggleNewWebsitePageNavEditionsDetected={
                          this.props.toggleNewWebsitePageNavEditionsDetected
                        }
                        toggleWebsiteCaptchaEditionsDetected={
                          this.props.toggleWebsiteCaptchaEditionsDetected
                        }
                        toggleWaitingForResponseOnWebsiteCaptchaEditions={
                          this.props
                            .toggleWaitingForResponseOnWebsiteCaptchaEditions
                        }
                        toggleWebsitePopupEditionsDetected={
                          this.props.toggleWebsitePopupEditionsDetected
                        }
                        toggleWaitingForResponseOnWebsitePopupEditions={
                          this.props
                            .toggleWaitingForResponseOnWebsitePopupEditions
                        }
                        toggleLoadedWebsitesData={
                          this.props.toggleLoadedWebsitesData
                        }
                        savePageComponentsInState={
                          this.props.savePageComponentsInState
                        }
                        toggleIsPageEmpty={this.props.toggleIsPageEmpty}
                        saveUserEventsInStore={this.props.saveUserEventsInStore}
                        navEditionFieldsVisible={
                          this.state.navEditionFieldsVisible
                        }
                        showNavEditionFieldsDrawer={
                          this.showNavEditionFieldsDrawer
                        }
                        hideNavEditionFieldsDrawer={
                          this.hideNavEditionFieldsDrawer
                        }
                        displayNavOrFooterEditionBox={
                          this.displayNavOrFooterEditionBox
                        }
                        blogs={this.props.blogs}
                        saveBlogNavInState={this.props.saveBlogNavInState}
                        toggleBlogNavEditionsDetected={
                          this.props.toggleBlogNavEditionsDetected
                        }
                        footerEditionFieldsVisible={
                          this.state.footerEditionFieldsVisible
                        }
                        saveBlogFooterInState={this.props.saveBlogFooterInState}
                        showFooterEditionFieldsDrawer={
                          this.showFooterEditionFieldsDrawer
                        }
                        hideFooterEditionFieldsDrawer={
                          this.hideFooterEditionFieldsDrawer
                        }
                        toggleBlogFooterEditionsDetected={
                          this.props.toggleBlogFooterEditionsDetected
                        }
                        gpt={this.props.gpt}
                        setMode={this.props.setMode}
                        currentWebsite={currentWebsite}
                        currentWebsitePage={currentWebsitePage}
                        currentWebsitePageUrl={currentWebsitePageUrl}
                        getCurrentChatData={this.props.getCurrentChatData}
                        editFillTemplateData={this.props.editFillTemplateData}
                        handleOnUnmount={this.props.handleOnUnmount}
                        changeComponentGlobalData={
                          this.props.changeComponentGlobalData
                        }
                        changeWebsitePagesStateGlobalData={
                          this.props.changeWebsitePagesStateGlobalData
                        }
                        changePageItemGlobalData={
                          this.props.changePageItemGlobalData
                        }
                        changeWebsitesStateGlobalData={
                          this.props.changeWebsitesStateGlobalData
                        }
                        getCurrentStoreData={this.props.getCurrentStoreData}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/:website_url/blog"
                  render={(props) => {
                    const { blogPosts, blogs, websites } = this.props;
                    if (
                      !websites.dataFetched ||
                      !blogPosts.allPostsWithoutContentFetched ||
                      !blogs.dataFetched
                    ) {
                      return (
                        <div style={{ marginTop: "60px" }}>
                          <SpinnerBox text="Loading blog..." />
                        </div>
                      );
                    }
                    const { currentWebsite } = this.props.getCurrentStoreData();
                    if (!currentWebsite) {
                      return (
                        <Empty
                          description="Website not found"
                          style={{ marginTop: "88px" }}
                        >
                          <Link to={"/"}>
                            <Button type="primary">Back to websites</Button>
                          </Link>
                        </Empty>
                      );
                    }
                    const redirectUrl = redirectBlog({
                      currentWebsite,
                      blogs: this.props.blogs,
                      blogPosts: this.props.blogPosts,
                    });
                    if (redirectUrl) {
                      return <Redirect to={redirectUrl} />;
                    }
                    const handleOnCreateArticle = () => {
                      this.props
                        .createAndSavePost()
                        .then(({ postData, currentBlog }) => {
                          this.props.history.push(
                            `/${currentWebsite.subdomain}/blog/${postData.id}`
                          );
                        });
                    };
                    return (
                      <Empty
                        description="You don't have any blog posts yet"
                        style={{ marginTop: "88px" }}
                      >
                        <Button
                          type="primary"
                          onClick={handleOnCreateArticle}
                          loading={
                            this.props.blogPosts
                              .isWaitingForCreateBlogPostResponse
                          }
                        >
                          Start writing
                        </Button>
                      </Empty>
                    );
                  }}
                />
                <Route
                  exact
                  path="/:website_url/blog/:blog_post_id"
                  render={(props) => {
                    const { websites } = this.props;
                    if (!websites.dataFetched) {
                      return (
                        <div style={{ marginTop: "60px" }}>
                          <SpinnerBox text="Loading blog post..." />
                        </div>
                      );
                    }
                    let isBlogUrlBlank = false;
                    const { currentBlogPost } =
                      this.props.getCurrentStoreData();
                    return (
                      <BlogPostEditor
                        getCurrentStoreData={this.props.getCurrentStoreData}
                        saveWebsiteBackup={this.props.saveWebsiteBackup}
                        auth={this.props.auth}
                        websites={this.props.websites}
                        blogs={this.props.blogs}
                        blogPosts={this.props.blogPosts}
                        history={props.history}
                        locationPostUrl={_.get(
                          currentBlogPost,
                          "url",
                          "undefined"
                        )}
                        isBlogUrlBlank={isBlogUrlBlank}
                        editBlogPostMainTitleSchemeToggleWaitingForServer={
                          this.props
                            .editBlogPostMainTitleSchemeToggleWaitingForServer
                        }
                        toggleNewBlogPostMainTitleEditionsDetected={
                          this.props.toggleNewBlogPostMainTitleEditionsDetected
                        }
                        toggleWaitingForResponseOnBlogNavEditions={
                          this.props.toggleWaitingForResponseOnBlogNavEditions
                        }
                        toggleWaitingForResponseOnBlogFooterEditions={
                          this.props
                            .toggleWaitingForResponseOnBlogFooterEditions
                        }
                        toggleBlogNavEditionsDetected={
                          this.props.toggleBlogNavEditionsDetected
                        }
                        toggleBlogFooterEditionsDetected={
                          this.props.toggleBlogFooterEditionsDetected
                        }
                        toggleBlogPostChangeInProgress={
                          this.props.toggleBlogPostChangeInProgress
                        }
                        changePostEditorType={this.props.changePostEditorType}
                        changePostEditorTypeWaitingForServer={
                          this.props.changePostEditorTypeWaitingForServer
                        }
                        changeBlogPostBody={this.props.changeBlogPostBody}
                        changeBlogPostTitle={this.props.changeBlogPostTitle}
                        editBlogPostSchemeToggleWaitingForServer={
                          this.props.editBlogPostSchemeToggleWaitingForServer
                        }
                        toggleNewBlogPostEditionsDetected={
                          this.props.toggleNewBlogPostEditionsDetected
                        }
                        toggleWebsiteCaptchaEditionsDetected={
                          this.props.toggleWebsiteCaptchaEditionsDetected
                        }
                        toggleWaitingForResponseOnWebsiteCaptchaEditions={
                          this.props
                            .toggleWaitingForResponseOnWebsiteCaptchaEditions
                        }
                        toggleWebsitePopupEditionsDetected={
                          this.props.toggleWebsitePopupEditionsDetected
                        }
                        toggleWaitingForResponseOnWebsitePopupEditions={
                          this.props
                            .toggleWaitingForResponseOnWebsitePopupEditions
                        }
                        saveImageInfoInDB={this.saveImageInfoInDB}
                        saveWebsiteDataInStore={this.saveBlogDataInStore}
                        removeImageFromDB={this.removeImageFromDB}
                        navEditionFieldsVisible={
                          this.state.navEditionFieldsVisible
                        }
                        showNavEditionFieldsDrawer={
                          this.showNavEditionFieldsDrawer
                        }
                        hideNavEditionFieldsDrawer={
                          this.hideNavEditionFieldsDrawer
                        }
                        displayNavOrFooterEditionBox={
                          this.displayNavOrFooterEditionBox
                        }
                        saveBlogNavInState={this.props.saveBlogNavInState}
                        saveWebsiteNavInState={this.props.saveWebsiteNavInState}
                        toggleWebsiteNavEditionsDetected={
                          this.props.toggleWebsiteNavEditionsDetected
                        }
                        footerEditionFieldsVisible={
                          this.state.footerEditionFieldsVisible
                        }
                        saveBlogFooterInState={this.props.saveBlogFooterInState}
                        showFooterEditionFieldsDrawer={
                          this.showFooterEditionFieldsDrawer
                        }
                        hideFooterEditionFieldsDrawer={
                          this.hideFooterEditionFieldsDrawer
                        }
                        saveWebsiteFooterInState={
                          this.props.saveWebsiteFooterInState
                        }
                        toggleWebsiteFooterEditionsDetected={
                          this.props.toggleWebsiteFooterEditionsDetected
                        }
                        editLoadedBlogsData={this.props.editLoadedBlogsData}
                        saveBlogPostContentInState={
                          this.props.saveBlogPostContentInState
                        }
                        changeBlogPostData={this.props.changeBlogPostData}
                      />
                    );
                  }}
                />

                {/*confirm email - render a component inside the PrimaryLayout which sends a POST with the key. If OK - send green notification, if fail - send red notification and warn me.*/}
                <Route
                  path="/rest-auth/registration/account-confirm-email/:key"
                  render={(props) => (
                    <ConfirmEmail
                      auth={this.props.auth}
                      emailConfirmationToggleServerResponded={
                        this.props.emailConfirmationToggleServerResponded
                      }
                      fetchCurrentUserInfo={this.props.fetchCurrentUserInfo}
                    />
                  )}
                />

                <Route
                  exact
                  path="/:website_url/*"
                  render={(props) => {
                    const { websites } = this.props;
                    if (!websites.dataFetched) {
                      return (
                        <div style={{ marginTop: "60px" }}>
                          <SpinnerBox text="Loading website..." />
                        </div>
                      );
                    }
                    const { currentWebsite } = this.props.getCurrentStoreData();
                    if (!currentWebsite) {
                      return (
                        <Empty
                          description="Website not found"
                          style={{ marginTop: "88px" }}
                        >
                          <Link to={"/"}>
                            <Button type="primary">Back to websites</Button>
                          </Link>
                        </Empty>
                      );
                    }

                    return (
                      <div
                        style={{ paddingTop: "40px", paddingBottom: "40px" }}
                      >
                        <Empty description={<span>Page not found</span>}>
                          <Link to={"/" + currentWebsite.subdomain}>
                            <Button type="primary">Back to website</Button>
                          </Link>
                        </Empty>
                      </div>
                    );
                  }}
                />

                <Route
                  exact
                  path="/"
                  render={() => {
                    if (
                      !this.props.blogs.dataFetched ||
                      !this.props.blogPosts.allPostsWithoutContentFetched ||
                      !this.props.websites.dataFetched ||
                      !this.props.websitesPages.pagesWithoutComponentsFetched ||
                      !this.props.templates.dataFetched
                    ) {
                      return (
                        <div style={{ marginTop: "60px" }}>
                          <SpinnerBox text="Loading..." />
                        </div>
                      );
                    }
                    const urlParams = new URLSearchParams(
                      window.location.search
                    );
                    const templateParam = urlParams.get("template");

                    if (templateParam) {
                      if (!this.props.websites.isWebsiteFromTemplateCreated) {
                        this.props.templates.items.forEach((template) => {
                          if (templateParam === template.template_subdomain) {
                            this.props.toggleIsWebsiteFromTemplateCreated(true);
                            this.props.createWebsiteFromTemplate({
                              event: new Event("create"),
                              templateSubdomain: template.template_subdomain,
                              templateWebsiteId: template.website,
                              newWebsiteSubdomain:
                                generateRandomSubdomain().toString(),
                              hideForm: () => {},
                              history: this.props.history,
                            });
                          }
                        });
                      }
                    }

                    const pathhistoryLs =
                      localStorage.getItem(PATH_HISTORY_LS_KEY);

                    if (
                      validatePath({
                        path: pathhistoryLs,
                        websites: this.props.websites,
                        websitePages: this.props.websitesPages,
                        blogs: this.props.blogs,
                        blogPosts: this.props.blogPosts,
                      })
                    ) {
                      return <Redirect to={pathhistoryLs} />;
                    }

                    const firstWebsite = [...this.props.websites.items].sort(
                      sortByDateFn
                    )[0];
                    if (firstWebsite) {
                      return <Redirect to={`/${firstWebsite.subdomain}`} />;
                    }
                    const onSubmit = (
                      event,
                      templateSubdomain?,
                      templateWebsiteId?
                    ) => {
                      this.props.createWebsiteFromTemplate({
                        event,
                        templateSubdomain,
                        templateWebsiteId,
                        newWebsiteSubdomain:
                          generateRandomSubdomain().toString(),
                        hideForm: () => {},
                        history: this.props.history,
                      });
                    };
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {this.props.templates.dataFetched && (
                          <Typography.Title style={{ marginTop: "64px" }}>
                            Choose a Starting Point
                          </Typography.Title>
                        )}
                        <TemplateSelect
                          templates={this.props.templates}
                          onSubmit={onSubmit}
                          isLoading={
                            this.props.websites
                              .isWaitingForCreateWebsiteResponse
                          }
                          websites={this.props.websites}
                          user={this.props.user}
                        />
                      </div>
                    );
                  }}
                />
              </Switch>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

const putStateToProps = (state: any) => {
  return {
    auth: state.auth as any,
    user: state.user as object,
    plans: state.plans as object,
    templates: state.templates as any,
    websites: state.websites as object,
    websitesPages: state.websitesPages as object,
    blogs: state.blogs as object,
    blogPosts: state.blogPosts as object,
    payment: state.payment as object,
    pageComponentCategories: state.pageComponentCategories as object,
    pageComponents: state.pageComponents as object,
    formIntegrations: state.formIntegrations as object,
    sharedAccesses: state.sharedAccesses as object,
    meta: state.meta as object,
    gpt: state.gpt,
  };
};

const putActionsToProps = {
  toggleCloudflareProtectionWaitingForServer,
  toggleAccessTokenIsValid,
  saveAccessTokenInState,
  emailConfirmationToggleServerResponded,
  accessTokenToggleServerResponded,
  saveUserDataInState,
  toggleUserDataFetched,

  togglePlansFetched,
  savePlansInState,

  toggleTemplatesFetched,
  saveTemplatesInState,

  toggleWebsitesFetched,
  saveWebsitesInState,
  saveBlogsInState,

  saveWebsitesPagesInState,
  toggleAllWebsitePagesFetched,
  validateUserAccessTokenInDashboard,
  fetchCurrentUserInfo,
  fetchPlans,
  fetchTemplates,
  fetchWebsites,
  fetchWebsitePagesWithoutComponents,
  logout,

  toggleCancellingPro,
  toggleUserPro,
  toggleUserLtd,
  toggleUserAppsumo,
  toggleProcessingSystemReady,
  toggleSwitchingProPlanInProgress,
  toggleUpgradingFromFreeToProInProgress,
  subdomainUpdateWaitingForServer,
  subdomainUpdateToggleInvalidAttempt,
  subdomainUpdateErrorMessage,

  customDomainUpdateWaitingForServer,
  customDomainUpdateToggleInvalidAttempt,
  customDomainUpdateErrorMessage,

  websiteCustomCodeUpdateWaitingForServer,
  websiteCustomCodeUpdateToggleInvalidAttempt,
  websiteCustomCodeUpdateErrorMessage,

  websiteExtraCustomCodeUpdateWaitingForServer,
  websiteExtraCustomCodeUpdateToggleInvalidAttempt,
  websiteExtraCustomCodeUpdateErrorMessage,

  customDomainToggleActivationWaitingForServer,
  sslToggleActivationWaitingForServer,

  toggleWebsitePageChangeInProgress,
  replaceWebsitesPagesInState,
  enableBrandingWaitingForServer,

  pageUrlUpdateWaitingForServer,
  pageUrlUpdateToggleInvalidAttempt,
  pageUrlUpdateErrorMessage,

  pageMetaTitleUpdateWaitingForServer,
  pageMetaTitleUpdateToggleInvalidAttempt,
  pageMetaTitleUpdateErrorMessage,

  pageMetaDescriptionUpdateWaitingForServer,
  pageMetaDescriptionUpdateToggleInvalidAttempt,
  pageMetaDescriptionUpdateErrorMessage,

  publishPageToggleWaitingForServer,

  createWebsiteWaitingForServer,
  createWebsiteErrorMessage,

  createWebsitePageWaitingForServer,
  createWebsitePageErrorMessage,

  saveSingleWebsiteInState,
  fetchSingleWebsitePages,

  cloneWebsiteWaitingForServer,
  deleteWebsiteWaitingForServer,

  removeWebsiteFromStore,
  removeWebsitePagesFromStore,

  deleteWebsitePageWaitingForServer,
  removeSingleWebsitePageFromStore,

  cloneWebsitePageWaitingForServer,

  fetchPageComponentCategories,
  fetchPageComponents,

  fetchFormIntegrations,
  fetchSharedAccesses,
  fetchMeta,
  fetchCache,

  editWebsitePageSchemeToggleWaitingForServer,
  moveWebsitePageComponent,
  deleteWebsitePageComponent,
  changeWebsitePageComponentContent,
  toggleNewWebsitePageEditionsDetected,
  addWebsitePageComponent,
  pasteCopiedWebsitePageComponent,
  saveWebsiteNavInState,
  saveWebsiteFooterInState,
  toggleWebsiteNavEditionsDetected,
  toggleWebsiteFooterEditionsDetected,
  toggleWaitingForResponseOnWebsiteFooterEditions,
  toggleWaitingForResponseOnWebsiteNavEditions,

  saveWebsiteNewFormIntegrationInState,
  removeWebsiteFormIntegration,
  toggleWaitingForResponseOnWebsiteFormIntegrationAdd,
  toggleWaitingForConnectPaymentIntegration,
  toggleWaitingForEditPaymentIntegrationProductDetails,
  toggleWaitingForConfigurePaymentIntegration,
  toggleWaitingForChangePaymentIntegrationProductsArray,

  toggleWaitingForResponseOnColorPaletteChange,
  toggleWaitingForResponseOnFontChange,
  changeWebsitePageComponentBackgroundColor,
  changeWebsitePageComponentBackgroundImage,
  changeWebsitePageComponentBackgroundImageOverlayOpacity,
  changeWebsiteNavColor,
  changeWebsitePageComponentPaddingBottom,
  changeWebsitePageComponentPaddingTop,
  changeWebsitePageComponentVisibility,
  toggleResendEmailConfirmationWaiting,

  changeStripeSecretKey,
  changeStripePublicKey,
  changeStripeCheckoutProductsArray,

  toggleWaitingForCommonWebsiteUpdate,
  toggleWaitingForWebsiteLanguagesUpdate,
  toggleWaitingForCommonWebsitePageUpdate,
  toggleWaitingForMovePageToAnotherWebsite,
  toggleIsPageFooterHidden,
  toggleIsPageNavHidden,

  changeBlogNavColor,

  cloneBlogWaitingForServer,

  createBlogErrorMessage,
  createBlogWaitingForServer,
  deleteBlogWaitingForServer,

  fetchBlogs,

  removeBlogFromStore,

  saveSingleBlogInState,
  saveBlogFooterInState,
  saveBlogNavInState,

  changeBlogPostComponentBackgroundColor,
  changeBlogPostComponentBackgroundImage,
  changeBlogPostBody,
  changeBlogPostTitle,
  toggleWaitingForCommonBlogUpdate,

  cloneBlogPostWaitingForServer,

  createBlogPostErrorMessage,
  createBlogPostWaitingForServer,
  deleteBlogPostComponent,
  deleteBlogPostWaitingForServer,

  editBlogPostSchemeToggleWaitingForServer,

  fetchAllBlogPostsWithoutContent,

  moveBlogPostComponent,

  postMetaDescriptionUpdateErrorMessage,
  postMetaDescriptionUpdateToggleInvalidAttempt,
  postMetaDescriptionUpdateWaitingForServer,
  postMetaTitleUpdateErrorMessage,
  postMetaTitleUpdateToggleInvalidAttempt,
  postMetaTitleUpdateWaitingForServer,
  postUrlUpdateErrorMessage,
  postUrlUpdateToggleInvalidAttempt,
  postUrlUpdateWaitingForServer,
  changePostEditorType,
  changePostEditorTypeWaitingForServer,
  publishPostToggleWaitingForServer,

  removeSingleBlogPostFromStore,
  removeBlogPostsFromStore,
  replaceBlogsPostsInState,
  saveBlogsPostsInState,
  toggleAllBlogPostsFetched,
  editBlogPostMainTitleSchemeToggleWaitingForServer,
  toggleNewBlogPostEditionsDetected,
  toggleNewBlogPostMainTitleEditionsDetected,
  urlUpdateWaitingForServer,
  urlUpdateToggleInvalidAttempt,
  urlUpdateErrorMessage,
  toggleBlogPostChangeInProgress,
  updateActiveDashboardTab,
  toggleWaitingForCommonBlogPostUpdate,
  toggleBlogNavEditionsDetected,
  toggleBlogFooterEditionsDetected,
  toggleWaitingForResponseOnBlogNavEditions,
  toggleWaitingForResponseOnBlogFooterEditions,

  blogCustomCodeUpdateErrorMessage,
  blogCustomCodeUpdateToggleInvalidAttempt,
  blogCustomCodeUpdateWaitingForServer,
  blogExtraCustomCodeUpdateErrorMessage,
  blogExtraCustomCodeUpdateToggleInvalidAttempt,
  blogExtraCustomCodeUpdateWaitingForServer,
  blogCustomCodePostsUpdateErrorMessage,
  blogCustomCodePostsUpdateToggleInvalidAttempt,
  blogCustomCodePostsUpdateWaitingForServer,
  blogExtraCustomCodePostsUpdateErrorMessage,
  blogExtraCustomCodePostsUpdateToggleInvalidAttempt,
  blogExtraCustomCodePostsUpdateWaitingForServer,
  blogMetaTitleUpdateToggleInvalidAttempt,
  blogMetaTitleUpdateErrorMessage,
  blogMetaTitleUpdateWaitingForServer,
  blogMetaDescriptionUpdateToggleInvalidAttempt,
  blogMetaDescriptionUpdateErrorMessage,
  blogMetaDescriptionUpdateWaitingForServer,

  toggleSharedAccessChangeWaitingForServer,
  addNewSharedAccessItemInState,
  removeSharedAccessItemFromState,
  toggleNewWebsitePageNavEditionsDetected,
  toggleWebsiteCaptchaEditionsDetected,
  toggleWaitingForResponseOnWebsiteCaptchaEditions,
  toggleWebsitePopupEditionsDetected,
  toggleWaitingForResponseOnWebsitePopupEditions,
  toggleLoadedWebsitesData,
  savePageComponentsInState,
  toggleIsPageEmpty,
  saveUserEventsInStore,
  setMode,
  getCurrentChatData,
  editFillTemplateData,
  editLoadedBlogsData,
  saveBlogPostContentInState,
  toggleIsWebsiteFromTemplateCreated,
  handleOnUnmount,
  setUserStateData,
  createAndSavePageAsync,
  createWebsiteFromTemplate,
  changeComponentGlobalData,
  changeWebsitePagesStateGlobalData,
  getCurrentStoreData,
  changePageItemGlobalData,
  changeBlogPostData,
  changeWebsitesStateGlobalData,
  fetchAndSavePageTemplatesAsync,
  saveWebsiteBackup,
  createAndSavePost,
  changeWebsiteNavIntegrationId,
  changeBlogNavIntegrationId,
};

export default connect(putStateToProps, putActionsToProps)(PrimaryLayout);
