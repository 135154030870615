import { Menu, Icon, Dropdown, Tooltip } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { useEffect, useState } from "react";
import {
  DropdownContainerRef,
  HandleOnSubmit,
  MenuItems,
  PresetName,
} from "./types";
import { presets } from "./presets";
import { getRandomValueFromObject } from "../../../helpers/getRandomValueFromObject";
import classNames from "classnames";
import { ReactComponent as AlignLeftShort } from "../../../img/icons/align-left-short.svg";
import { fuzzySearch } from "../../../helpers/fuzzySearch";
import {
  ContextualInput,
  EditInputData,
  GptState,
  HandleRedo,
  HandleUndo,
  SetInputValueAndResize,
} from "../../../store/gpt/types";
import { focusTextArea } from "./focusTextArea";
import TryAgainTooltipContent from "./contextual_input/TryAgainTooltipContent";
import { PosthogEvents } from "../../../enums/AnalyticsEventsEnums";
import { getPageArray } from "./getPageArray";
import { getActualComponentName } from "../../../helpers/getActualComponentName";

const ITEM_CLASS = "gpt-assistant__presets-dropdown-item";
const SUBMENU_CLASS = "gpt-assistant__presets-dropdown-submenu-item";

interface Props {
  children: React.ReactNode;
  handleOnSubmit: HandleOnSubmit;
  isInputDropdownVisible: boolean;
  componentId: string;
  editInputData: EditInputData;
  inputData: ContextualInput;
  setInputValueAndResize: SetInputValueAndResize;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  handleUndo: HandleUndo;
  handleRedo: HandleRedo;
  currentWebsitePage: any;
  currentWebsite: any;
  currentPageIndex: number;
  gpt: GptState;
  dropdownContainerRef: DropdownContainerRef;
}

const PresetsDropdown = (props: Props): JSX.Element => {
  const {
    children,
    handleOnSubmit,
    componentId,
    isInputDropdownVisible,
    editInputData,
    inputData,
    setInputValueAndResize,
    textAreaRef,
    handleUndo,
    handleRedo,
    currentWebsitePage,
    currentWebsite,
    currentPageIndex,
    gpt,
    dropdownContainerRef,
  } = props;

  const { isLoading, inputValue, completionResultData } = inputData;

  const [isPresetsDropdownVisible, setIsPresetsDropdownVisible] =
    useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsPresetsDropdownVisible(isInputDropdownVisible && !isLoading);
    }, 1);
  }, [isInputDropdownVisible]);

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsPresetsDropdownVisible(false);
      }, 1);
    }
    if (!isLoading && isInputDropdownVisible) {
      setTimeout(() => {
        setIsPresetsDropdownVisible(true);
      }, 1);
    }
  }, [isLoading]);

  const getTitleText = () => {
    if (componentId === "navigation") {
      return "Edit the navigation with AI";
    }
    if (componentId === "footer") {
      return "Edit the footer with AI";
    }
    return "Edit this component with AI";
  };

  const isMatchingSearch = (search: string) => {
    return fuzzySearch(inputValue, search);
  };

  const handleOnClickPreset = (
    event,
    presetName: PresetName,
    temperature?: number
  ) => {
    const component = getPageArray(currentWebsitePage, currentWebsite).find(
      (item) => {
        return item.id === componentId;
      }
    );
    window.posthog.capture(PosthogEvents.CLICK_PRESET, {
      website_id: currentWebsite.id,
      preset_name: presetName,
      component: getActualComponentName(component),
    });
    let promptValue = presets[presetName] as string;
    if (presetName === "randomize") {
      promptValue = getRandomValueFromObject(presets.randomize);
    }
    handleOnSubmit(event, promptValue, presetName, temperature);
  };

  const handleOnDone = () => {
    editInputData({
      componentId,
      newData: {
        reasoning: "",
        completionResultData: {
          hasChanges: false,
          isSessionStarted: false,
        },
      },
    });
    focusTextArea(textAreaRef);
  };

  const handleOnUndoMouseEnter = () => {
    handleUndo({
      componentId,
      currentPageIndex,
      currentWebsitePage,
      currentWebsite,
    });
  };

  const handleOnUndoMouseLeave = () => {
    handleRedo({
      componentId,
      currentPageIndex,
      currentWebsitePage,
      currentWebsite,
    });
  };

  const handleOnUndoClick = () => {
    handleUndo({
      componentId,
      currentPageIndex,
      currentWebsitePage,
      currentWebsite,
    });
    editInputData({
      componentId,
      newData: {
        reasoning: "",
        completionResultData: {
          hasChanges: false,
          isSessionStarted: false,
        },
      },
    });
    focusTextArea(textAreaRef);
  };

  const handleOnTryAgain = (newValue = "") => {
    const { presetName } = completionResultData;
    handleUndo({
      componentId,
      currentPageIndex,
      currentWebsitePage,
      currentWebsite,
    });
    handleOnDone();
    if (presetName) {
      handleOnClickPreset(null, presetName, 0.7);
      return;
    }
    setInputValueAndResize({
      componentId,
      newValue,
      textAreaRef,
    });
    focusTextArea(textAreaRef);
  };

  const menuItems: MenuItems = [
    {
      text: "Improve writing",
      search: "improve writing",
      icon: <Icon type="form" />,
      prompt: "improveWriting",
    },
    {
      text: "Change tone",
      search: "change tone",
      icon: <Icon type="notification" />,
      submenu: [
        {
          text: "Professional 🎓",
          prompt: "toneProfessional",
          search: "change tone professional",
        },
        {
          text: "Casual ☕",
          prompt: "toneCasual",
          search: "change tone casual",
        },
        {
          text: "Confident 😎",
          prompt: "toneConfident",
          search: "change tone confident",
        },
        {
          text: "Straightforward 🎯",
          prompt: "toneStraightforward",
          search: "change tone straightforward",
        },
        {
          text: "Friendly 😊",
          prompt: "toneFriendly",
          search: "change tone friendly",
        },
      ],
    },
    {
      text: "Fix grammar",
      icon: <Icon type="check-square" />,
      prompt: "grammar",
      search: "fix grammar",
    },
    {
      text: "Translate",
      search: "translate",
      icon: <Icon type="global" />,
      submenu: [
        {
          text: "English 🇬🇧",
          prompt: "translateEnglish",
          search: "translate english",
        },
        {
          text: "Spanish 🇪🇸",
          prompt: "translateSpanish",
          search: "translate spanish",
        },
        {
          text: "French 🇫🇷",
          prompt: "translateFrench",
          search: "translate french",
        },
        {
          text: "German 🇩🇪",
          prompt: "translateGerman",
          search: "translate german",
        },
        {
          text: "Hindi 🇮🇳",
          prompt: "translateHindi",
          search: "translate hindi",
        },
        {
          text: "Portuguese 🇵🇹",
          prompt: "translatePortuguese",
          search: "translate portuguese",
        },
        {
          text: "Chinese 🇨🇳",
          prompt: "translateChinese",
          search: "translate chinese",
        },
        {
          text: "Chinese (Traditional) 🇹🇼",
          prompt: "translateChineseTraditional",
          search: "translate chinese traditional",
        },
        {
          text: "Malay 🇲🇾",
          prompt: "translateMalay",
          search: "translate malay",
        },
        {
          text: "Russian 🇷🇺",
          prompt: "translateRussian",
          search: "translate russian",
        },
        {
          text: "Korean 🇰🇷",
          prompt: "translateKorean",
          search: "translate korean",
        },
        {
          text: "Italian 🇮🇹",
          prompt: "translateItalian",
          search: "translate italian",
        },
        {
          text: "Japanese 🇯🇵",
          prompt: "translateJapanese",
          search: "translate japanese",
        },
        {
          text: "Vietnamese 🇻🇳",
          prompt: "translateVietnamese",
          search: "translate vietnamese",
        }
      ],
    },
    {
      text: "Make longer",
      search: "make longer",
      icon: <Icon type="align-left" />,
      prompt: "longer",
    },
    {
      text: "Make shorter",
      search: "make shorter",
      icon: (
        <AlignLeftShort
          width="12"
          height="12"
          style={{ marginRight: "8px", transform: "translateY(5px)" }}
        />
      ),
      prompt: "shorter",
    },
    {
      text: "Add emojis",
      icon: <Icon type="smile" />,
      prompt: "emojis",
      search: "add emojis",
    },
    {
      text: "Randomize",
      icon: <Icon type="experiment" />,
      prompt: "randomize",
      search: "randomize",
    },
  ];

  const renderPromptHistory = () => {
    return gpt.promptHistory.map((item) => {
      if (!isMatchingSearch(`recent prompts ${item.prompt}`)) return null;
      return (
        <Menu.Item
          key={item.prompt}
          className={classNames(
            ITEM_CLASS,
            "gpt-assistant__presets-dropdown-item--with-tooltip"
          )}
          onClick={() => {
            handleOnTryAgain(item.prompt);
          }}
        >
          <Tooltip
            title={item.prompt}
            placement="left"
            mouseLeaveDelay={0}
            mouseEnterDelay={0.2}
          >
            <div className="gpt-assistant__presets-dropdown-tooltip-wrapper">
              <Icon type="retweet" /> {item.prompt}
            </div>
          </Tooltip>
        </Menu.Item>
      );
    });
  };

  const renderMenu = (menuItems: MenuItems, titleText: string) => {
    const menu = menuItems.map((item, index) => {
      if (item.submenu) {
        const hasItemsThatMatchSearch = item.submenu.some((subitem) => {
          return isMatchingSearch(subitem.search);
        });
        if (!hasItemsThatMatchSearch) return null;
        return (
          <SubMenu
            key={index}
            className={SUBMENU_CLASS}
            title={
              <>
                {item.icon} {item.text}
              </>
            }
          >
            {item.submenu.map((subitem, index) => {
              if (!isMatchingSearch(subitem.search)) return null;
              return (
                <Menu.Item
                  key={index}
                  className={ITEM_CLASS}
                  onClick={(e) => {
                    handleOnClickPreset(e.domEvent, subitem.prompt);
                  }}
                >
                  {subitem.text}
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      }
      if (!isMatchingSearch(item.search)) return null;
      return (
        <Menu.Item
          key={index}
          className={ITEM_CLASS}
          onClick={(e) => {
            handleOnClickPreset(e.domEvent, item.prompt);
          }}
        >
          {item.icon} {item.text}
        </Menu.Item>
      );
    });
    const hasItemsThatMatchSearch = menu.some((item) => {
      return item !== null;
    });
    const hasHistoryItemsThatMatchSearch = gpt.promptHistory.some(
      (historyItem) => {
        return isMatchingSearch(`recent prompts ${historyItem.prompt}`);
      }
    );
    return (
      <Menu
        className={classNames({
          "gpt-assistant__presets-dropdown-menu--hidden":
            !hasItemsThatMatchSearch && !hasHistoryItemsThatMatchSearch,
        })}
      >
        {hasItemsThatMatchSearch && (
          <Menu.Item
            className={classNames(
              ITEM_CLASS,
              "gpt-assistant__presets-dropdown-group-title"
            )}
            disabled
          >
            {titleText}
          </Menu.Item>
        )}
        {menu}
        {hasItemsThatMatchSearch && hasHistoryItemsThatMatchSearch && (
          <Menu.Divider />
        )}
        {gpt.promptHistory.length > 0 && hasHistoryItemsThatMatchSearch && (
          <Menu.Item
            className={classNames(
              ITEM_CLASS,
              "gpt-assistant__presets-dropdown-group-title"
            )}
            disabled
          >
            Recent prompts
          </Menu.Item>
        )}
        {renderPromptHistory()}
      </Menu>
    );
  };

  const renderControlsMenu = () => {
    const doneMatches = isMatchingSearch("done");
    const tryAgainMatches = isMatchingSearch("try again");
    const undoMatches = isMatchingSearch("undo");

    return (
      <Menu
        className={classNames({
          "gpt-assistant__presets-dropdown-menu--hidden":
            !doneMatches && !tryAgainMatches && !undoMatches,
        })}
      >
        {doneMatches && (
          <Menu.Item className={ITEM_CLASS} onClick={handleOnDone}>
            <Icon type="check" /> Done
          </Menu.Item>
        )}
        {doneMatches &&
          (tryAgainMatches ||
            (undoMatches && completionResultData.hasChanges)) && (
            <Menu.Divider />
          )}
        {tryAgainMatches && (
          <Menu.Item
            className={classNames(
              ITEM_CLASS,
              "gpt-assistant__presets-dropdown-item--with-tooltip"
            )}
            onClick={() => {
              handleOnTryAgain(completionResultData.prompt);
            }}
          >
            <Tooltip
              title={
                <TryAgainTooltipContent
                  completionResultData={completionResultData}
                />
              }
              placement="left"
              mouseLeaveDelay={0}
              mouseEnterDelay={0.2}
            >
              <div className="gpt-assistant__presets-dropdown-tooltip-wrapper">
                <Icon type="retweet" /> Try again
              </div>
            </Tooltip>
          </Menu.Item>
        )}
        {completionResultData.hasChanges && undoMatches && (
          <Menu.Item
            className={ITEM_CLASS}
            onMouseEnter={handleOnUndoMouseEnter}
            onMouseLeave={handleOnUndoMouseLeave}
            onClick={handleOnUndoClick}
          >
            <Icon type="rollback" /> Undo
          </Menu.Item>
        )}
      </Menu>
    );
  };

  return (
    <Dropdown
      overlay={
        completionResultData.isSessionStarted
          ? renderControlsMenu()
          : renderMenu(menuItems, getTitleText())
      }
      placement={"bottomRight"}
      overlayClassName="gpt-assistant__presets-dropdown"
      visible={isPresetsDropdownVisible}
      getPopupContainer={() => dropdownContainerRef.current}
    >
      {children}
    </Dropdown>
  );
};

export default PresetsDropdown;
