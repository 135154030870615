import React from "react";
import { Tooltip } from "antd";
import { TooltipPropsWithTitle } from "antd/lib/tooltip";

interface Props extends TooltipPropsWithTitle {
  condition: boolean;
}

const ConditionalTooltip = (props: Props): JSX.Element => {
  const { children, condition, title } = props;

  return (
    <Tooltip {...props} title={condition ? title : null}>
      {children}
    </Tooltip>
  );
};

export default ConditionalTooltip;
