import React from "react";
import MoneyPig from "../../../../img/other/money_pig.png";
import { connect } from "react-redux";
import { checkIsLtdPlan } from "../../../../helpers/user/checkIsLtdPlan";

interface Props {
  user: any;
  plans: any;
}

const BillingDataEmpty = (props: Props): JSX.Element => {
  const { user, plans } = props;
  const isLtd = checkIsLtdPlan(user, plans);

  const renderText = () => {
    if (isLtd) {
      return (
        <>
          Billing info, invoices, and payments of regular subscriptions are
          displayed here. Your plan is a lifetime long, therefore there are no
          billing details to be displayed.
        </>
      );
    }
    return (
      <>Your billing info, invoices and payments will be displayed here.</>
    );
  };

  return (
    <div className="payment_info__empty_box">
      <img
        className="payment_info__empty_image"
        alt="Money box illustration"
        src={MoneyPig}
      />
      <span className="payment_info__empty_text">{renderText()}</span>
    </div>
  );
};

const mapStateToProps = ({ user, plans }) => {
  return {
    user,
    plans,
  };
};
export default connect(mapStateToProps, null)(BillingDataEmpty);
