import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import { setAccessTokenCookie } from "../../helpers/auth/accessTokenCookie";
import { openNotification } from "../../helpers/openNotification";
import {
  DEFAULT_ERROR_TITLE,
  notificationDuration,
} from "../../data/constants";
import { Icon } from "antd";
import _ from "lodash";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { FacebookEvents, GoogleTagEvents } from "../../enums/AnalyticsEventsEnums";
import { setUserEventsAsync } from "../../helpers/user/setUserEventsAsync";
import { UserEvents } from "../../enums/UserEvents";
import { apiUrlBase } from "../../data/urls";

const SocialAuth = (props) => {
  let location = props.location;

  useEffect(() => {
    const values = queryString.parse(location.search);
    const code = values.code ? values.code : null;

    if (code) {
      onGogglelogin();
    }
  }, []);

  const googleLoginHandler = (code) => {
    return axios
      .get(
        `${apiUrlBase}/api/v1/rest_auth/auth/login/google/${code}`
      )
      .then((res) => {
        setAccessTokenCookie(res.data.auth_token);
        return res.data;
      })
      .catch((error) => {
        console.error(error);
        console.log("error.response: ", error.response);
        openNotification(
          DEFAULT_ERROR_TITLE,
          "Couldn't fetch website data. Please contact us.",
          "OK",
          "error",
          notificationDuration.short
        );
        return error;
      });
  };

  const onGogglelogin = async () => {
    const response = await googleLoginHandler(location.search);
    const authType = _.get(response, "auth_type");
    const email = _.get(response, "user.email");
    const accessToken = _.get(response, "auth_token");

    if (authType === "signup") {
      props.setUserStateData({
        isSignedUp: true,
        authType: "google",
      });
      fireAnalyticsEvent.fireFacebook(FacebookEvents.registration);
      fireAnalyticsEvent.fireGoogleTag(GoogleTagEvents.signUp);
      window.twq("event", "tw-oeqwf-oeqws", {
        email_address: email,
      });
      setUserEventsAsync(
        {
          [UserEvents.SignedUp]: new Date(),
          [UserEvents.SignedUpWebsiteCount]: new Date(),
        },
        accessToken,
        {}
      ).then((response) => {
        if (response) {
          props.saveUserEventsInStore(response.data);
        }
      });
      window.fpr("referral", { email });
    }
    if (authType === "login") {
      props.setUserStateData({
        isLoggedIn: true,
        authType: "google",
      });
    }
    props.history.push("/");
  };

  return (
    <div>
      <Icon type="loading" style={{ color: "var(--ant)", marginRight: 8 }} />{" "}
      Logging in...
    </div>
  );
};

export default withRouter(SocialAuth);
