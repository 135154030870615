export const getCurrentBlog = (blogsArray: any, currentWebsite: any) => {
    if(blogsArray === undefined || currentWebsite === undefined) {
        return undefined
    }
    let blogOfThisWebsite = blogsArray.find((item:any) => {
        return (item.website === currentWebsite.id)
    });

    if(blogOfThisWebsite === undefined) {
        return undefined
    }else{
        return blogOfThisWebsite;
    }
};