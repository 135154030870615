import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {logoOptions} from "../../data/logoOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteLogo from "../component_molecules/WebsiteLogo";
import { publicUrl } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Posts05 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Learn & Get Inspired"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: 'Ferox, mirabilis demolitiones velox transferre de bassus, lotus secula. Domesticus hibridas ducunt ad genetrix.'}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,
                maxItems: 36,
                hasButton: true,
                hasBgColor: true,
                hasText: true,
                hasThumb: false,
                hasLogo: true,
                labelTooltip: {default: "Showcase your guides or tutorials. 2-4 items is enough to show your care and build some trust."},
                buttonTypes: [buttonStyles.ghost.typeTitle],

                items: [
                    this.generator.createPostsContentObject({
                        title: "How to Activate MailChimp Forms",
                        subtitle: '2 min guide',
                        text: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scelerisque nec pharetra id, tempor a tortor.',
                        bgColor: '#b9e985',
                        logo: this.generator.createLogoContentObject({
                            height: 50,
                            uploadedSrc: publicUrl + '/img/logos/companies/' + 'mailchimp.svg'
                        }),
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Read guide", buttonHref: '#', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline, buttonIsTargetBlank: true}),
                    }),

                    this.generator.createPostsContentObject({
                        title: "Airtable + Unicorn Platform",
                        subtitle: '11 min read',
                        text: 'Indexs sunt monss de talis barcas. Ubi est gratis diatria? Eheu. Species bassus fluctus est. Devatios peregrinationes, tanquam gratis me.',
                        bgColor: '#e4e4e4',
                        logo: this.generator.createLogoContentObject({
                            height: 50,
                            uploadedSrc: publicUrl + '/img/logos/companies/' + 'airtable-small.svg'
                        }),
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Read post", buttonHref: '#', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline, buttonIsTargetBlank: true}),
                    }),
                    this.generator.createPostsContentObject({
                        title: "Intercom on Product: Rise of the “keyboard-first” generation",
                        subtitle: '14 min read',
                        text: 'Ecce, danista! Pol, pius castor! Nunquam resuscitabo silva. Varius, peritus ollas satis imperium de regius, bi-co.',
                        bgColor: '#dbfff5',
                        logo: this.generator.createLogoContentObject({
                            height: 40,
                            uploadedSrc: publicUrl + '/img/logos/companies/' + 'intercom.svg'
                        }),
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Read post", buttonHref: '#', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline, buttonIsTargetBlank: true}),
                    }),

                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the posts component to invite users to read your blog.",
                    default: "Add a button to the bottom of the posts component to invite users to read your blog.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: 'Discover more in our blog',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: ''
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        return (
            <section>
                <div className="posts-05">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--large">
                        <div className="posts-05__wrapper">
                            {posts && posts.map((item:any, key:number) => {
                                if(item !== undefined){
                                    return (
                                        <div className="sh-4 card-container posts-05__item" key={"post-05-item-" + key}>
                                            <div className={"posts-05__img_box " + ((item.logo.type == '' || (item.logo.type == logoOptions.uploaded.title && item.logo.uploadedSrc === '')) ? ' posts-05__img_box--small' : '')} style={{backgroundColor: item.bgColor}}>
                                                {item.logo.type !== '' && item.logo.uploadedSrc !== '' && <WebsiteLogo logoObject={item.logo} className='posts-05__img'/>}
                                            </div>
                                            <div className="posts-05__text">
                                                {item.title !== '' &&<h3 className="posts-05__title">
                                                  <span className="title-text--inner def-18_72">
                                                    {item.title}
                                                  </span>
                                                  <span
                                                    className="content-text def-14 posts-05__title_info">{item.subtitle}</span></h3>}
                                                <div className="content-text posts-05__info content_box" dangerouslySetInnerHTML={{__html: item.text}}/>
                                                {item.button && item.button.title !== '' && <div className="posts-05__link">
													<div className="feature__button_box">
                                                        <WebsiteButton config={{
                                                            buttonStyleType: item.button.settings.appearance.styleType,
                                                            buttonColor: item.button.settings.appearance.color,
                                                            buttonType: linkOrButtonOptions.link,
                                                            buttonTitle: item.button.title,
                                                            buttonHref: item.button.href,
                                                            buttonIsTargetBlank: item.button.isTargetBlank,
                                                            buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                                            pillPrefix: item.button.pillPrefix,
                                                            pillColor: item.button.settings.appearance.pillColor,
                                                            ghostColor: item.button.settings.appearance.ghostColor,
                                                        }} />
													</div>
												</div>}
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default Posts05;
