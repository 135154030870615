import React from "react";
import _ from "lodash";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import DebouncedInput from "../../../DebouncedInput";
import { GraphicsOptions } from "../../component_graphics/data/componentGraphicsData";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  saveContentInStore: (payload: any) => void;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: any;
  content: any;
}

const StepVideoInput = (props: Props): JSX.Element => {
  const {
    saveContentInStore,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
    content,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);
  const generator = new generateContentDataObject();

  const handleOnChange = (value: string) => {
    saveContentInStore(
      generator.changeStepVideoUrl(
        { [contentType]: currentInputDataRef.current },
        value,
        keyProp
      )
    );
    toggleNewEditionsDetected(true);
  };

  return (
    <div className="content-input__input">
      <DebouncedInput
        placeholder="https://www.youtube.com/watch?v=7wtfhZwyrcc"
        debounceDelay={2000}
        onChange={handleOnChange}
        defaultValue={_.get(content, [
          "items",
          keyProp,
          "graphics",
          GraphicsOptions.video,
          "youtube",
          "url",
        ])}
        id={contentType}
      />
    </div>
  );
};

export default StepVideoInput;
