import axios from "axios";
import _ from "lodash";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";

const actionsNames = {
  SAVE_META_DATA: "SAVE_META_DATA",
  TOGGLE_META_DATA_FETCHED: "TOGGLE_META_DATA_FETCHED",
  TOGGLE_CACHE_FETCHED: "TOGGLE_CACHE_FETCHED",
  SAVE_CACHE: "SAVE_CACHE",
};

export const fetchCache = () => {
  return (dispatch: any, getState: any) => {
    const accessToken = getState().auth.accessToken;
    return new Promise<void>((resolve, reject) => {
      axios
        .get(api.meta.fetchCache, { ...returnAuthHeaderForAJAX(accessToken) })
        .then((response) => {
          const cache = _.get(response, ["data", 0, "cache_bust"], "0");
          dispatch(toggleCacheFetched(true));
          dispatch(saveCache(cache));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const fetchMeta = () => {
  return (dispatch: any, getState: any) => {
    const cacheParam = getState().meta.cache;
    const url = `${api.meta.fetch}?v=${cacheParam}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(toggleMetaFetched(true));
        dispatch(saveMetaInState(response.data));
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

export const saveMetaInState = (data: string) => {
  // get data and store it
  return {
    type: actionsNames.SAVE_META_DATA as string,
    payload: data as string,
  };
};

export const toggleMetaFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.TOGGLE_META_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export const toggleCacheFetched = (isFetched: boolean) => {
  return {
    type: actionsNames.TOGGLE_CACHE_FETCHED,
    payload: isFetched,
  };
};
export const saveCache = (cache: string) => {
  return {
    type: actionsNames.SAVE_CACHE,
    payload: cache,
  };
};

export default actionsNames;
