import React from "react";
import { Icon } from "antd";
import { ReactComponent as MaterialWandBig } from "../../../img/icons/material_wand_big.svg";

interface Props {
  type: "default" | "ai";
}

const FillTemplateIcon = (props: Props): JSX.Element => {
  const { type = "default" } = props;
  if (type === "default") {
    return <Icon type="profile" className="fill-template-modal__icon" />;
  }

  return <MaterialWandBig />;
};

export default FillTemplateIcon;
