import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import _ from "lodash";
import GraphicsJSX from "../GraphicsJSX";
import { displayImageUrl } from "../../helpers/content_displayers/displayImageUrl";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  displayButtons: any;
  isDarkBg: boolean;
}

class Features07 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;
      this.props.saveContentInStore(
        this.generator.setUpGraphics({
          activeOption: GraphicsOptions.image,
          userCanAddItems: false,
          maxItems: 1,
          items: [
            this.generator.createGraphicsContentObject({
              imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CRM,
              videoUrl: "https://www.youtube.com/watch?v=J2U9Hmmpqhc",
              width: 1280,
              height: 800,
            }),
          ],
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpTitle({
          titleText: "Engage More with an Outstanding Website",
          isDefaultEmpty
        })
      );
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Buttons",
            default: "Buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a CTA (call to action) to the feature. It can be a link to a '/features' page so users can learn more about your product.",
            default:
              "Add a CTA (call to action) to the feature. It can be a link to a '/features' page so users can learn more about your product.",
          },
          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "Learn more",
              buttonHref: "#",
              buttonStyleType: buttonStyles.regular.typeTitle,
              buttonColor: buttonStyles.regular.blackBg,
              pillPrefix: "See",
            }),
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.regular.typeTitle,
              buttonColor: buttonStyles.regular.blackBg,
              pillPrefix: "See",
            }),
          ],
          priority: 10,
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );
    let imageUrl = displayImageUrl(
      this.props.componentItem.componentData.graphics.content.items[0][
        GraphicsOptions.image
      ]
    ); //This component has exactly one item and more can not be added (see createGraphicsContentObject() above).

    const currentActiveOption: GraphicsOptions = _.get(
      this.props.componentItem,
      ["componentData", "graphics", "activeOption"]
    );
    const videoUrl: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.video,
      "youtube",
      "url",
    ]);
    const codeSnippet: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.HTML,
      "codeSnippet",
    ]);

    return (
      <section>
        <div
          className={`features-07 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image}`}
        >
          <div className="container container--large">
            <div className="features-07__wrapper">
              <div className="features-07__main">
                <div className="features-07__main_content">
                  <div className="title-box">
                    {title.length > 0 && (
                      <h2
                        className={
                          "title-text heading features-07__heading " +
                          (this.props.isDarkBg ? "text-white" : "")
                        }
                        dangerouslySetInnerHTML={{ __html: title }}
                      />
                    )}
                    {subtitleText.length > 0 && (
                      <div
                        className={
                          "subtitle-text title-box__text features-07__text content_box " +
                          (this.props.isDarkBg ? "text-white" : "")
                        }
                        dangerouslySetInnerHTML={{ __html: subtitleText }}
                      />
                    )}
                  </div>

                  {this.props.componentItem.componentData.cta &&
                    <WebsiteCTA componentItem={this.props.componentItem} />}
                </div>
              </div>
              <div className="features-07__visual">
                <div className="features-07__img_box">
                  <GraphicsJSX
                    currentActiveOption={currentActiveOption}
                    defaultGraphicsOption={GraphicsOptions.image}
                    isDarkBg={this.props.isDarkBg}
                    imageUrl={imageUrl}
                    videoUrl={videoUrl}
                    codeSnippet={codeSnippet}
                    classNames={{
                      image: "features-07__img",
                      video: "features-07__video",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Features07;
