import React from "react";
import { abstractIconsCount } from "../../data/abstractIconsCount";
import SVG from "react-inlinesvg";
import { abstractIcons, publicUrl } from "../../data/urls";
import { Icon } from "antd";

interface Props {
  id?: string;
}

const AbstractIcon = (props: Props): JSX.Element => {
  const { id } = props;

  let iconId = (Math.floor(Math.random() * abstractIconsCount) + 1).toString();
  if (id) {
    iconId = id;
  }

  return (
    <SVG
      src={
        publicUrl +
        abstractIcons.prefix +
        "abstract-icon-" +
        iconId +
        ".svg"
      }
      preloader={
        <div className="primary-layout__icon_placeholder">
          <Icon
            style={{ fontSize: 16, color: "var(--ocean)" }}
            type="loading"
          />
        </div>
      }
    ></SVG>
  );
};

export default AbstractIcon;
