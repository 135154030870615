import React from "react";
import { Icon } from "antd";
import formatVideoSourceUrl from "../helpers/editor/formatVideoSourceUrl";
import Spinner from "./Spinner";
import WistiaLogo from "../img/logos/wistia_logo_color.png";
import { GraphicsOptions } from "../components/editor/component_graphics/data/componentGraphicsData";
import ConditionalDiv from "../components/ConditionalDiv";

interface Props {
  isDarkBg: boolean;
  videoUrl: string;
  className?: string;
  permanentSpinnerColor?: boolean;
  defaultGraphicsOption: GraphicsOptions;
  containerClassName?: string;
}

const VideoElement = (props: Props): JSX.Element => {
  const {
    isDarkBg,
    videoUrl,
    className,
    permanentSpinnerColor,
    defaultGraphicsOption,
    containerClassName,
  } = props;

  if (!videoUrl) return null;

  const displayVideoElement = () => {
    let formattedVideoUrl = videoUrl;
    // first, let's detect the video source. it can be: youtube, vimeo, wistia or an mp4 file
    if (videoUrl.indexOf(".mp4") !== -1) {
      // a direct mp4 file should be displayed in the <video> tag
      return (
        <video className="video-01__iframe" controls key={formattedVideoUrl}>
          <source src={formattedVideoUrl} type="video/mp4" />
        </video>
      );
    }
    if (videoUrl.indexOf("vimeo") !== -1) {
      return (
        <iframe
          className="video-01__iframe video-01__iframe--vimeo"
          src={formattedVideoUrl}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      );
    }
    if (videoUrl.indexOf("wistia") !== -1) {
      // Wistia offers an embed code for their videos which containt a script. The script is not executing when added dynamically.
      // Therefore, we can not display a Wistia video in the app.
      // We display the video in the visitor's page by injecting their HTML directly.

      // video-01__iframe--fixed-height - because the div has no Wistia code which will generate an element with its own height
      return (
        <div className="video-01__iframe video-01__iframe--gray video-01__iframe--fixed-height wistia-info-box-new">
          <div className="video-01__iframe__info_box">
            <Icon type="warning" theme="twoTone" style={{ fontSize: 20 }} />
            <img
              className="video-01__iframe__logo"
              src={WistiaLogo}
              height="35"
              alt="Wistia logo"
            />
            <div className="video-01__iframe__text">
              Wistia embedded videos can only be displayed on the public page.
              Publish the page and open it to view the video.
            </div>
          </div>
        </div>
      );
      // else - youtube
    }
    // we do formatVideoSourceUrl() in frontend/src/helpers/editor/generateContentDataObject.ts - before saving an URL.
    // We started saving the formatted url after 2 years of project.
    // Thus we still need to run formatVideoSourceUrl() before displaying here for backwards compatibility - to protect data of websites of users who already saved un unformatted URL.
    formattedVideoUrl = formatVideoSourceUrl(formattedVideoUrl);
    if (formattedVideoUrl !== "") {
      return (
        <iframe
          className="video-01__iframe video-01__iframe--youtube"
          src={formattedVideoUrl}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen={true}
        />
      );
    }
    return null;
  };

  return (
    <ConditionalDiv
      condition={!!containerClassName}
      className={containerClassName}
    >
      <div
        className={`${
          defaultGraphicsOption !== GraphicsOptions.video
            ? "graphics-video__video-element"
            : ""
        } ${className || ""}`}
      >
        {displayVideoElement()}
        <Spinner
          isDarkBg={isDarkBg}
          permanentSpinnerColor={permanentSpinnerColor}
        />
      </div>
    </ConditionalDiv>
  );
};

export default VideoElement;
