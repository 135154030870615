import React from "react";
import DebouncedInput from "../../../DebouncedInput";
import { GraphicsOptions } from "../../component_graphics/data/componentGraphicsData";
import DeleteButton from "./DeleteButton";
import { Components } from "../../../../enums/Components";
import { graphicsWithOneItem } from "../../../../data/componentExceptions";

interface Props {
  content: any;
  saveContentInStore: (payload: any) => void;
  generator: any;
  contentType: any;
  currentInputData: any;
  toggleNewEditionsDetected: any;
  displayMoveUpDownButtons: (
    index: number,
    itemsCount: number,
    moveUp: any,
    moveDown: any
  ) => JSX.Element;
  lastItem: any;
  componentType: string;
  componentTitle: string;
}

const GraphicsVideoInput = (props: Props): JSX.Element => {
  const {
    content,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    lastItem,
    componentType,
    componentTitle,
  } = props;

  let graphicsInputs = content.items.map((item: any, key: number) => {
    const componentName = `${componentType}-${componentTitle}` as Components;
    if (graphicsWithOneItem.includes(componentName) && key > 0) {
      return null;
    }
    let onChangeGraphicsYoutubeVideoHandler = (value: any) => {
      saveContentInStore(
        generator.changeGraphicsYoutubeVideoUrl(
          { [contentType]: currentInputData },
          value,
          key
        )
      );
      toggleNewEditionsDetected(true);
    };

    let videoInputs = (
      <DebouncedInput
        onChange={onChangeGraphicsYoutubeVideoHandler}
        defaultValue={content.items[key][GraphicsOptions.video].youtube.url}
        placeholder="https://www.youtube.com/watch?v=7wtfhZwyrcc"
        id={contentType}
        debounceDelay={2000}
      />
    );

    if (currentInputData.userCanAddItems === false) {
      // If user is not allowed to add items - just display existing images and their fields. This means the amount of images is hardcoded and is predefined by us - design of a template required
      return (
        <div
          className="content-input__input"
          key={"graphics-item-" + key.toString()}
        >
          {videoInputs}
        </div>
      );
    }
    // If you can add new items - display cluster and a delete button - just like in other countable elements (pricing table, features etc (see examples in this file)).
    let maxItemsReached = lastItem[GraphicsOptions.video].youtube.url !== "";
    let itemsCount = maxItemsReached
      ? content.items.length
      : content.items.length - 1;
    return (
      <div
        className={
          "content-input__cluster content-input__cluster--small " +
          (maxItemsReached === false
            ? " content-input__cluster--with-ghost "
            : " ")
        }
        data-add-new="Add new video ⤵"
        key={"video-fields-" + key}
      >
        {
          <DeleteButton
            keyProp={key}
            toggleNewEditionsDetected={toggleNewEditionsDetected}
            saveContentInStore={saveContentInStore}
            generator={generator}
            contentType={contentType}
            currentInputData={currentInputData}
          />
        }
        {displayMoveUpDownButtons(
          key,
          itemsCount,
          () => {
            saveContentInStore(
              generator.moveGraphicsUp(
                {
                  [contentType]: currentInputData,
                },
                key
              )
            );
          },
          () => {
            saveContentInStore(
              generator.moveGraphicsDown(
                {
                  [contentType]: currentInputData,
                },
                key
              )
            );
          }
        )}
        {videoInputs}
      </div>
    );
  });

  return graphicsInputs;
};

export default GraphicsVideoInput;
