import React from "react";
import { draftJsEditorTitle } from "../../../../data/constants";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import DebouncedTextarea from "../../../DebouncedTextarea";
import MyRichTextEditor from "../../../MyRichTextEditor";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  textEditorType: string;
  revertToPlainHTML: () => void;
  item: any;
  saveContentInStore: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: (value: boolean) => void;
}

const GallerySliderDescription = (props: Props): JSX.Element => {
  const {
    textEditorType,
    revertToPlainHTML,
    item,
    saveContentInStore,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const generator = new generateContentDataObject();

  let onChangeGalleryContentTextHandler = (value: any) => {
    saveContentInStore(
      generator.changeGalleryContentText(
        { [contentType]: currentInputDataRef.current },
        keyProp,
        value
      )
    );
    toggleNewEditionsDetected(true);
  };

  if (!currentInputDataRef.current.contentHasText) return null;

  if (textEditorType && textEditorType === draftJsEditorTitle) {
    return (
      <div className="content-input__input">
        <MyRichTextEditor
          revertToPlainHTML={revertToPlainHTML}
          placeholder="Enter slide description"
          defaultValue={item.contentText}
          onChange={onChangeGalleryContentTextHandler}
        />
      </div>
    );
  }
  return (
    <DebouncedTextarea
      className="content-input__input"
      onChange={onChangeGalleryContentTextHandler}
      defaultValue={item.contentText}
      autosize={{ minRows: 1, maxRows: 6 }}
      placeholder="Enter slide description"
    />
  );
};

export default GallerySliderDescription;
