import { Button } from "antd";
import React from "react";

interface Props {
  toggleNewEditionsDetected: any;
  saveContentInStore: any;
  generator: any;
  contentType: any;
  currentInputData: any;
  keyProp: number;
}

const DeleteButton = (props: Props) => {
  const {
    toggleNewEditionsDetected,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    keyProp,
  } = props;

  return (
    <Button
      onClick={() => {
        toggleNewEditionsDetected(true);
        saveContentInStore(
          generator.deleteGraphics({ [contentType]: currentInputData }, keyProp)
        );
      }}
      htmlType="button"
      className="content-input__control_cluster"
      shape="circle"
      title="Delete this graphics item"
      icon="cross"
      size="small"
    />
  );
};

export default DeleteButton;
