// actionsNames.SWITCH_COMPONENT

import _ from "lodash";
import { SwitchComponentPayload } from "../types";
import { getComponentCategoryItem } from "../../../helpers/getComponentCategoryItem";
import { Components } from "../../../enums/Components";
import { getActualComponentName } from "../../../helpers/getActualComponentName";
import { componentsWithoutEmptyMockups } from "../../../data/componentExceptions";
import generateContentDataObject from "../../../helpers/editor/generateContentDataObject";

const transferComponentItems = (
  currentComponent,
  newComponentItem,
  dataKey: string
) => {
  // If a certain component layout requires a hard-coded amount of items, we may have an error if the old component has less items than the new one.
  // This function adds the missing items.
  let oldItems = _.get(
    currentComponent,
    ["componentData", dataKey, "content", "items"],
    []
  );
  let newItems = _.get(
    newComponentItem,
    ["componentData", dataKey, "content", "items"],
    []
  );
  if (oldItems.length < newItems.length) {
    currentComponent.componentData[dataKey].content.items = [
      ...oldItems,
      ...newItems.slice(oldItems.length),
    ];
  }
};
const removeOldKey = (currentComponent, oldKey: string) => {
  // Removes data blocks that are not present in the new component layout (e.g. "title", "cta", "graphics").
  // Saves the removed data in the oldRemovedComponentData object, so that it can be restored if the user switches back to the old component layout.
  currentComponent.oldRemovedComponentData[oldKey] =
    currentComponent.componentData[oldKey];
  delete currentComponent.componentData[oldKey];
};
const addNewKey = (currentComponent, newComponentItem, newKey: string) => {
  // Adds new data blocks that are present in the new component layout (e.g. "title", "cta", "graphics").
  // If they are present in the oldRemovedComponentData object, they are restored from there. If not, they are filled with dummy data from the new component layout's config.
  const { oldRemovedComponentData } = currentComponent;
  const removedKeys = oldRemovedComponentData
    ? Object.keys(oldRemovedComponentData)
    : [];

  if (removedKeys.includes(newKey)) {
    currentComponent.componentData[newKey] = oldRemovedComponentData[newKey];
    delete oldRemovedComponentData[newKey];
  } else {
    currentComponent.componentData[newKey] =
      newComponentItem.componentData[newKey];
  }
};
const rewriteUtilityKeys = (
  currentComponent,
  newComponentItem,
  newLayoutDataKey
) => {
  // We preserve data when switching layouts. However, some data should be rewritten on switch, because it's no longer relevant for the new layout (e.g. "label", "maxItems")
  // This function rewrites all the necessary data.
  Object.keys(newComponentItem.componentData[newLayoutDataKey]).forEach(
    (newLayoutDataItemKey) => {
      if (
        newLayoutDataItemKey !== "content" &&
        newLayoutDataItemKey !== "activeOption" &&
        newLayoutDataItemKey !== "ctaBottomInfo" &&
        newLayoutDataKey in currentComponent.componentData
      ) {
        currentComponent.componentData[newLayoutDataKey][newLayoutDataItemKey] =
          newComponentItem.componentData[newLayoutDataKey][
            newLayoutDataItemKey
          ];
      }
    }
  );
};
const switchMockups = (currentComponent, newComponentItem) => {
  // Preserves mockups when switching layouts.
  const prevMockups = _.get(
    currentComponent,
    ["componentData", "mockups", "content", "items"],
    []
  );
  const newMockups = _.get(
    newComponentItem,
    ["componentData", "mockups", "content", "items"],
    []
  );
  currentComponent.savedMockups = [
    ...prevMockups,
    ...currentComponent.savedMockups,
  ];
  currentComponent.componentData.mockups.content.items = newMockups;
  currentComponent.componentData.mockups.content.items.forEach((mockup) => {
    const savedMockup = currentComponent.savedMockups.find(
      (savedMockup) => savedMockup.type === mockup.type
    );
    if (savedMockup) {
      const newComponentName = getActualComponentName(newComponentItem);
      const isUnsupportedMockupType =
        savedMockup.deviceId === "Empty" &&
        componentsWithoutEmptyMockups.includes(newComponentName);
      if (!isUnsupportedMockupType) {
        Object.assign(mockup, savedMockup);
      }
      currentComponent.savedMockups = currentComponent.savedMockups.filter(
        (mockup) => mockup !== savedMockup
      );
    }
  });
};
const switchPaddings = (currentComponent, newComponentItem) => {
  const prevPaddingTop = _.get(currentComponent, "settings.padding.top");
  const prevPaddingBottom = _.get(currentComponent, "settings.padding.bottom");
  const newPaddingTop = _.get(newComponentItem, "settings.padding.top");
  const newPaddingBottom = _.get(newComponentItem, "settings.padding.bottom");
  if (newPaddingTop === undefined && prevPaddingTop !== undefined) {
    currentComponent.savedPaddings.top = prevPaddingTop;
    _.set(currentComponent, "settings.padding.top", undefined);
  }
  if (newPaddingBottom === undefined && prevPaddingBottom !== undefined) {
    currentComponent.savedPaddings.bottom = prevPaddingBottom;
    _.set(currentComponent, "settings.padding.bottom", undefined);
  }
  if (prevPaddingTop === undefined && newPaddingTop !== undefined) {
    if (currentComponent.savedPaddings.top) {
      _.set(
        currentComponent,
        "settings.padding.top",
        currentComponent.savedPaddings.top
      );
      delete currentComponent.savedPaddings.top;
    }
  }
  if (prevPaddingBottom === undefined && newPaddingBottom !== undefined) {
    if (currentComponent.savedPaddings.bottom) {
      _.set(
        currentComponent,
        "settings.padding.bottom",
        currentComponent.savedPaddings.bottom
      );
      delete currentComponent.savedPaddings.bottom;
    }
  }
};
const switchAdvanced = (
  currentComponent,
  newComponentItem,
  prevComponentName,
  newComponentName
) => {
  let prevStoreKey = prevComponentName;
  let newStoreKey = newComponentName;
  if (currentComponent.original_component_id) {
    prevStoreKey = currentComponent.original_component_id;
  }
  if (newComponentItem.original_component_id) {
    newStoreKey = newComponentItem.original_component_id;
  }
  const currentAdvancedData: any = {
    customCss: currentComponent.customCss,
    customJs: currentComponent.customJs,
    customClasses: currentComponent.customClasses,
    customAttributes: currentComponent.customAttributes,
  };
  const directoryContent = _.get(currentComponent, [
    "componentData",
    "directory",
    "content",
  ]);
  if (directoryContent) {
    currentAdvancedData.directoryItemHtml = directoryContent.directoryItemHtml;
  }
  const savedCss = _.get(
    currentComponent,
    ["savedAdvanced", newStoreKey, "customCss"],
    ""
  );
  const savedJs = _.get(
    currentComponent,
    ["savedAdvanced", newStoreKey, "customJs"],
    ""
  );
  const savedClasses = _.get(
    currentComponent,
    ["savedAdvanced", newStoreKey, "customClasses"],
    ""
  );
  const savedAttributes = _.get(
    currentComponent,
    ["savedAdvanced", newStoreKey, "customAttributes"],
    ""
  );

  currentComponent.customCss = savedCss || newComponentItem.customCss;
  currentComponent.customJs = savedJs || newComponentItem.customJs;
  currentComponent.customClasses = savedClasses || newComponentItem.customClasses;
  currentComponent.customAttributes = savedAttributes || newComponentItem.customAttributes;

  if (directoryContent) {
    const savedDirectoryItemHtml = _.get(
      currentComponent,
      ["savedAdvanced", newStoreKey, "directoryItemHtml"],
      ""
    );
    const newDirectoryItemHtml = _.get(
      newComponentItem,
      ["componentData", "directory", "content", "directoryItemHtml"],
      ""
    );
    directoryContent.directoryItemHtml = savedDirectoryItemHtml || newDirectoryItemHtml;
  }

  delete currentComponent.savedAdvanced[newStoreKey];
  currentComponent.savedAdvanced[prevStoreKey] = currentAdvancedData;
};

export const switchComponentReducer = (state, action) => {
  const payload: SwitchComponentPayload = action.payload;
  const { pageId, componentId, newComponentItem, pageComponentCategories } =
    payload;

  const pagesArrayCopy = _.cloneDeep(state.items);
  const currentPage = pagesArrayCopy.find((page) => page.id === pageId);
  const componentsArray = _.get(currentPage, "page_components.data.schema", []);
  const currentComponent = componentsArray.find(
    (component) => component.id === componentId
  );
  if (!currentComponent) {
    return state;
  }
  const newComponentItemCopy = componentsArray.find(
    (component) => component.id === newComponentItem.id
  );
  newComponentItemCopy.isSwitchDataSent = true; // Set the flag here to avoid potential infinite loops.

  if (!currentComponent.oldRemovedComponentData) {
    currentComponent.oldRemovedComponentData = {};
  }
  if (!currentComponent.savedPaddings) {
    currentComponent.savedPaddings = {};
  }
  if (!currentComponent.savedMockups) {
    currentComponent.savedMockups = [];
  }
  if (!currentComponent.savedAdvanced) {
    currentComponent.savedAdvanced = {};
  }

  const prevComponentCategory = getComponentCategoryItem(
    pageComponentCategories,
    currentComponent
  );
  const prevComponentName = `${prevComponentCategory.title}-${currentComponent.title}`;

  currentComponent.category = newComponentItem.category;
  currentComponent.title = newComponentItem.title;

  const newComponentCategory = getComponentCategoryItem(
    pageComponentCategories,
    newComponentItem
  );
  const newComponentName = `${newComponentCategory.title}-${newComponentItem.title}`;

  currentComponent.actualComponentName = newComponentName;

  const newKeys = _.difference(
    Object.keys(newComponentItem.componentData),
    Object.keys(currentComponent.componentData)
  );
  const oldKeys = _.difference(
    Object.keys(currentComponent.componentData),
    Object.keys(newComponentItem.componentData)
  );

  oldKeys.forEach((oldKey) => {
    removeOldKey(currentComponent, oldKey);
  });

  newKeys.forEach((newKey) => {
    addNewKey(currentComponent, newComponentItem, newKey);
  });

  Object.keys(newComponentItem.componentData).forEach((newLayoutDataKey) => {
    rewriteUtilityKeys(currentComponent, newComponentItem, newLayoutDataKey);
  });

  if ("graphics" in newComponentItem.componentData) {
    transferComponentItems(currentComponent, newComponentItem, "graphics");
    const newItems = _.get(
      newComponentItem,
      ["componentData", "graphics", "content", "items"],
      []
    );
    newItems.forEach((newItem, index) => {
      const uploadButtonInfo = _.get(newItem, "image.uploadButtonInfo", "");
      const oldItem = _.get(currentComponent, [
        "componentData",
        "graphics",
        "content",
        "items",
        index,
        "image",
      ]);
      if (oldItem) {
        currentComponent.componentData.graphics.content.items[
          index
        ].image.uploadButtonInfo = uploadButtonInfo;
      }
    });
  }

  if ("text" in newComponentItem.componentData) {
    transferComponentItems(currentComponent, newComponentItem, "text");
  }

  if ("mockups" in newComponentItem.componentData) {
    switchMockups(currentComponent, newComponentItem);
  }

  if (newComponentName === Components.text03) {
    const textContent = currentComponent.componentData.text.content;
    textContent.items = [textContent.items[1], textContent.items[0]];
    const titleText = _.get(
      currentComponent,
      "oldRemovedComponentData.title.content.markup"
    );
    if (titleText) {
      textContent.items[0].markup = titleText;
    }
  }
  if (prevComponentName === Components.text03) {
    const textContent = currentComponent.componentData.text.content;
    textContent.items = [textContent.items[1], textContent.items[0]];
    const titleContent = _.get(currentComponent, "componentData.title.content");
    if (titleContent) {
      currentComponent.componentData.title.content.markup =
        textContent.items[1].markup;
    }
  }

  if (newComponentName === Components.photos01) {
    const prevPhoto = _.get(currentComponent, [
      "oldRemovedComponentData",
      "graphics",
      "content",
      "items",
      0,
      "image",
    ]);
    if (
      _.get(currentComponent, [
        "componentData",
        "posts",
        "content",
        "items",
        0,
        "thumb",
        "image",
      ])
    ) {
      if (prevPhoto) {
        currentComponent.componentData.posts.content.items[0].thumb.image =
          prevPhoto;
      } else {
        currentComponent.componentData.posts.content.items[0].thumb.image = {
          url: "",
          href: "",
          uuid: "",
          uploadButtonInfo: "",
          height: undefined,
          width: undefined,
        };
      }
    } else {
      if (prevPhoto) {
        const generator = new generateContentDataObject();
        const newThumb = generator.createGraphicsContentObject();
        newThumb.image = prevPhoto;
        currentComponent.componentData.posts.content.items[0] =
          generator.createPostsContentObject({
            thumb: newThumb,
          });
      }
    }
  }

  if (prevComponentName === Components.photos01) {
    const prevPhoto = _.get(currentComponent, [
      "oldRemovedComponentData",
      "posts",
      "content",
      "items",
      0,
      "thumb",
      "image",
    ]);
    if (
      _.get(currentComponent, [
        "componentData",
        "graphics",
        "content",
        "items",
        0,
        "image",
      ])
    ) {
      if (prevPhoto) {
        currentComponent.componentData.graphics.content.items[0].image =
          prevPhoto;
      } else {
        currentComponent.componentData.graphics.content.items[0].image = {
          url: "",
          href: "",
          uuid: "",
          uploadButtonInfo: "",
          height: undefined,
          width: undefined,
        };
      }
    }
  }

  if (prevComponentName === Components.features02) {
    currentComponent.componentData.features.content.items.forEach((feature) => {
      feature.features02Subtitle = feature.subtitle;
    });
  }

  if (
    prevComponentName === Components.features01 ||
    prevComponentName === Components.features02 ||
    prevComponentName === Components.features09 ||
    prevComponentName === Components.text08
  ) {
    currentComponent.componentData.features.content.items.forEach((feature) => {
      feature.subtitle = feature.text;
    });
  }

  if (
    prevComponentName === Components.features03 ||
    prevComponentName === Components.links03 ||
    prevComponentName === Components.links04 ||
    prevComponentName === Components.links06
  ) {
    currentComponent.componentData.features.content.items.forEach((feature) => {
      feature.text = feature.subtitle;
    });
  }

  if (newComponentName === Components.features02) {
    currentComponent.componentData.features.content.items.forEach((feature) => {
      feature.subtitle = feature.features02Subtitle;
    });
  }

  switchPaddings(currentComponent, newComponentItem);

  switchAdvanced(
    currentComponent,
    newComponentItem,
    prevComponentName,
    newComponentName
  );

  currentComponent.original_component_id =
      newComponentItem.original_component_id;

  return {
    ...state,
    items: pagesArrayCopy,
  };
};
