import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type CreatePageAsync = (payload: {
  accessToken: string;
  subdomain: string;
  pageUrl: string;
  templateId?: number;
}) => Promise<AxiosResponse<any>>;

export const createPageAsync: CreatePageAsync = ({
  accessToken,
  subdomain,
  pageUrl,
  templateId,
}) => {
  return new Promise((resolve, reject) => {
    let apiUrl = `${api.websitePages.createWebsitePage.prefix}${subdomain}${api.websitePages.createWebsitePage.postfix}`;
    if (templateId) {
      apiUrl += `?template_id=${templateId}`;
    }
    axios
      .post(
        apiUrl,
        { url: pageUrl, subdomain },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
