import { Tooltip, Icon } from "antd";
import Search from "antd/lib/input/Search";
import React from "react";
import { urls } from "../../../../data/urls";
import generateFormIntegrationDataObject from "../../../../helpers/editor/generateFormIntegrationDataObject";
import { toggleNewWebsitePageEditionsDetected } from "../../../../store/websitePages/actions";
import { saveWebsiteNewFormIntegrationInState } from "../../../../store/websites/actions";
import { connect } from "react-redux";
import { SaveConnectedIntegrationsInServer } from "../../../../helpers/types/globalTypes";

interface Props {
  currentFormIntegrationTitle: string;
  currentWebsite: any;
  websites: any;
  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer;

  toggleNewWebsitePageEditionsDetected: (payload: boolean) => void;
  saveWebsiteNewFormIntegrationInState: (payload: any) => void;
}

const MailerLiteInputs = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentWebsite,
    saveConnectedIntegrationsInServer,
    websites,
  } = props;

  return (
    <li className="content-input">
      <div className="content-input__label_box">
        <Tooltip
          placement="leftTop"
          title={
            <div>
              Please copy the <code>action=""</code> attribute contents:
              <br />
              <br />
              <a
                href={urls.assets.mailerliteIntegrationAction}
                style={{ display: "block" }}
                target="_blank"
              >
                <img
                  style={{ cursor: "pointer", width: "100%" }}
                  src={urls.assets.mailerliteIntegrationAction}
                  alt="Mailerlite integraion guide image."
                />
              </a>
              <br />
              Read this{" "}
              <a
                style={{
                  color: "white",
                  textDecoration: "underline",
                }}
                href={urls.guides.mailerliteIntegration}
                target="_blank"
              >
                simple tutorial <Icon type="link" />
              </a>{" "}
              if you need help.
            </div>
          }
        >
          <Icon className="content-input__tooltip_icon" type="info-circle" />
        </Tooltip>
        <label
          htmlFor={currentFormIntegrationTitle}
          className="content-input__label"
        >
          Enter the Mailerlite <code>action</code> and press "Save":
        </label>
      </div>
      <div className="content-input__input_box">
        <Search
          onSearch={(value: any) => {
            props.toggleNewWebsitePageEditionsDetected(true);

            const newConnectedIntegrationObject = generateFormIntegrationDataObject.mailerlite(
              currentFormIntegrationTitle,
              value
            );

            props.saveWebsiteNewFormIntegrationInState({
              newConnectedIntegrationObject,
              currentWebsiteSubdomain: currentWebsite.subdomain,
            });
            saveConnectedIntegrationsInServer(newConnectedIntegrationObject);
          }}
          enterButton={
            <span style={{ verticalAlign: "middle" }}>
              Save{" "}
              <Icon
                type={
                  websites.isWaitingForFormIntegrationsServerResponse
                    ? "loading"
                    : "save"
                }
              />
            </span>
          }
          placeholder="https://assets.mailerlite.com/jsonp/<some_id>/forms/<some_id>/subscribe"
          id={currentFormIntegrationTitle}
        />
      </div>
    </li>
  );
};

const mapActionsToProps = {
  toggleNewWebsitePageEditionsDetected,
  saveWebsiteNewFormIntegrationInState,
};
export default connect(null, mapActionsToProps)(MailerLiteInputs);
