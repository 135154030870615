import { cleanUpAiData } from "../../../components/editor/ai2/cleanUpAiData";
import { getPageArray } from "../../../components/editor/ai2/getPageArray";
import { changeWebsitePageComponentContent } from "../../websitePages/actions";
import {
  saveWebsiteFooterInState,
  saveWebsiteNavInState,
} from "../../websites/actions";
import { HandleCleanUpAiData } from "../types";

export const handleCleanUpAiData: HandleCleanUpAiData = ({
  currentWebsitePage,
  currentWebsite,
  componentId,
  currentPageIndex,
}) => {
  return (dispatch) => {
    const pageArray = getPageArray(currentWebsitePage, currentWebsite);
    const component = pageArray.find((pageComponent) => {
      return pageComponent.id === componentId;
    });
    const cleanedComponentData = cleanUpAiData(component);
    const componentIndex = pageArray.indexOf(component) - 1;
    if (component.id === "navigation") {
      dispatch(
        saveWebsiteNavInState({
          navObject: {
            ...currentWebsite.nav,
            componentData: cleanedComponentData,
          },
          currentWebsiteSubdomain: currentWebsite.subdomain,
        })
      );
    }
    if (component.id === "footer") {
      dispatch(
        saveWebsiteFooterInState({
          footerObject: {
            ...currentWebsite.footer,
            componentData: cleanedComponentData,
          },
          currentWebsiteSubdomain: currentWebsite.subdomain,
        })
      );
    }
    if (component.id !== "navigation" && component.id !== "footer") {
      dispatch(
        changeWebsitePageComponentContent({
          currentPageIndex,
          componentKey: componentIndex,
          componentData: cleanedComponentData,
        })
      );
    }
  };
};
