import { checkIfPlanSupportsCaptcha } from "../plans/checkIfPlanSupportsCaptcha";
import isWebsiteSharedWithCurrentUser from "../shareAccess/isWebsiteSharedWithCurrentUser";

export const checkIfCaptchaAvailableForUser = (
  user: any,
  plans: any,
  sharedAccesses: any,
  currentWebsite: any
) => {
  const currentPlanSupportsCaptcha = checkIfPlanSupportsCaptcha(user, plans);
  const isCurrentWebsiteShared = isWebsiteSharedWithCurrentUser(
    sharedAccesses,
    currentWebsite.id,
    user.data.email
  ).isTrue;
  return currentPlanSupportsCaptcha || isCurrentWebsiteShared;
};
