import React, { useEffect } from "react";
import { GraphicsOptions } from "../components/editor/component_graphics/data/componentGraphicsData";
import EditorCodeSnippet from "./EditorCodeSnippet";
import ImageElement from "./ImageElement";
import VideoElement from "./VideoElement";
import _ from "lodash";

interface ClassNames {
  image?: string;
  video?: string;
  HTML?: string;
}
interface Props {
  currentActiveOption?: GraphicsOptions;
  defaultGraphicsOption: GraphicsOptions;
  classNames?: ClassNames;
  isDarkBg: boolean;
  imageUrl: string;
  videoUrl: string;
  codeSnippet: string;
  alt?: string;
  permanentSpinnerColor?: boolean;
  isEditorHTMLBackgroundAlwaysLight?: boolean;
  imageDataWidth?: number;
  imageDataHeight?: number;
  setTabsHeightOnChange?: boolean;
  containerClasses?: ClassNames;
}

const GraphicsJSX = (props: Props): JSX.Element => {
  const {
    currentActiveOption,
    classNames,
    isDarkBg,
    imageUrl,
    videoUrl,
    codeSnippet,
    defaultGraphicsOption,
    alt,
    permanentSpinnerColor,
    isEditorHTMLBackgroundAlwaysLight,
    imageDataWidth,
    imageDataHeight,
    setTabsHeightOnChange,
    containerClasses,
  } = props;

  const mapJSXToGraphicsOptions = {
    [GraphicsOptions.image]: (
      <ImageElement
        src={imageUrl}
        className={_.get(classNames, "image")}
        alt={alt}
        dataWidth={imageDataWidth}
        dataHeight={imageDataHeight}
        setTabsHeightOnChange={setTabsHeightOnChange}
        containerClassName={_.get(containerClasses, "image")}
      />
    ),
    [GraphicsOptions.video]: (
      <VideoElement
        className={_.get(classNames, "video")}
        isDarkBg={isDarkBg}
        videoUrl={videoUrl}
        permanentSpinnerColor={permanentSpinnerColor}
        defaultGraphicsOption={defaultGraphicsOption}
        containerClassName={_.get(containerClasses, "video")}
      />
    ),
    [GraphicsOptions.HTML]: (
      <EditorCodeSnippet
        className={_.get(classNames, "HTML")}
        isDarkBg={isDarkBg}
        codeSnippet={codeSnippet}
        isBackgroundAlwaysLight={isEditorHTMLBackgroundAlwaysLight}
        containerClassName={_.get(containerClasses, "HTML")}
      />
    ),
  };

  useEffect(() => {
    if (setTabsHeightOnChange) {
      window.unicornplatform.tabs.setAll();
    }
  }, [currentActiveOption]);

  if (currentActiveOption in mapJSXToGraphicsOptions) {
    return mapJSXToGraphicsOptions[currentActiveOption];
  }

  return mapJSXToGraphicsOptions[defaultGraphicsOption];
};

export default GraphicsJSX;
