import React, {Component} from "react";
import {Badge, Button, Icon} from "antd";
import './MyButton.css'
interface Props {
    showButtonSuccess: boolean,

    onClick?: any,
    type?: any;
    disabled?: boolean;
    style?: any,
    id?: any;
    className?: any;
    loading?: boolean;
    ghost?: boolean;
    hidden?: boolean;

    shape?: any,
    size?: any,
    htmlType?: any,
    icon?: any,
    block?: any,
}

class MyButton extends Component<Props>{
    render(){
        return (
            <div>
                <div className={"my-button"}>
                    {this.props.showButtonSuccess && <div className="my-button_tick"><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></div>}

                    <Button
                        onClick={this.props.onClick}
                        shape={this.props.shape}
                        size={this.props.size}
                        htmlType={this.props.htmlType}
                        loading={this.props.loading}
                        icon={this.props.icon}
                        block={this.props.block}
                        hidden={this.props.hidden}

                        type={this.props.type}
                        id={this.props.id}
                        disabled={this.props.disabled}
                        className={this.props.className}
                        style={this.props.style}
                    />
                </div>
            </div>

        )
    }
}

export default MyButton;