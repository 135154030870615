import { editInputData } from "../actions";
import { getCurrentInputData } from "./getCurrentInputData";

export const setInputValueAndResize = ({
  componentId,
  newValue,
  textAreaRef,
}) => {
  return (dispatch, getState) => {
    const currentInput = dispatch(getCurrentInputData(componentId));
    if (currentInput.isLoading) return;
    if (textAreaRef && textAreaRef.current) {
      const textAreaNode = textAreaRef.current;
      textAreaNode.value = newValue;
      textAreaNode.style.height = "0";
      textAreaNode.style.height = textAreaNode.scrollHeight.toString() + "px";
    }
    dispatch(
      editInputData({
        componentId,
        newData: {
          inputValue: newValue,
        },
      })
    );
  };
};
