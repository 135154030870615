import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { companyLogos, publicUrl } from "../../../../data/urls";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  let itemHeight = modifiedItem.HEIGHT_PX || 0;
  if (typeof itemHeight !== "number" || isNaN(itemHeight) || itemHeight < 5) {
    itemHeight = 5;
  }
  if (itemHeight > 100) {
    itemHeight = 100;
  }
  const itemUrl = stringifyObjectsAndNumbers(modifiedItem.URL) || "";
  const itemTarget = stringifyObjectsAndNumbers(modifiedItem.TARGET) || "_self";
  return {
    itemId,
    itemPosition,
    itemHeight,
    itemUrl,
    itemTarget,
  };
};

const particleModifyOriginalItem = (
  content,
  originalItem,
  modifiedItem,
  itemPosition,
  itemHeight,
  itemUrl,
  itemTarget
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(content.items, item);
      }
    });
  }
  if ("HEIGHT_PX" in modifiedItem) {
    originalItem.height = itemHeight;
  }
  if ("URL" in modifiedItem) {
    originalItem.href = itemUrl;
  }
  if ("TARGET" in modifiedItem) {
    originalItem.isTargetBlank = itemTarget === "_blank";
  }
};

export const updateLogos = (componentData, completionContent) => {
  const content = componentData.logos.content;
  if (!("LOGOS" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.LOGOS)) {
    if (completionContent.LOGOS === null) {
      content.items = [];
    }
    return;
  }
  content.items = deleteEmptyItems(content.items, "uploadedSrc");
  addDefaultValuesToItems(content.items);
  completionContent.LOGOS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemHeight,
      itemUrl,
      itemTarget,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      content.items = deleteItem(content.items, itemId);
      return;
    }
    const isItemNew = getIsItemNew(content.items, itemId);
    if (isItemNew) {
      const newItem = generator.createLogoContentObject();
      newItem.uploadedSrc =
      publicUrl + companyLogos.prefix + "apple.svg";
      newItem.height = 30;
      addNewItem(content.items, newItem, itemId, itemPosition);
    }
    const originalItem = getOriginalItem(content.items, itemId);
    particleModifyOriginalItem(
      content,
      originalItem,
      modifiedItem,
      itemPosition,
      itemHeight,
      itemUrl,
      itemTarget
    );
  });
  sortItemsByPosition(content.items);
};
