import capitalizeString from "../strings/capitalizeString";

function generateImageAltFromFileName(imageName:string):string{
    let convertFilenameToAltText = (filename:string):string => {
        let alt = filename.replace(/-/g, ' ').replace(/_/g, ' '); // via https://stackoverflow.com/questions/14262770/javascript-replace-dash-hyphen-with-a-space
        alt = alt.toLowerCase();
        alt = capitalizeString(alt);
        alt = alt.substr(0, alt.lastIndexOf('.')); //name.jpg -> name
        return alt;
    };
    return convertFilenameToAltText(imageName);
}

export default generateImageAltFromFileName;
