export enum UserEvents {
  UpgradedToNonMaker = "upgraded_to_non_maker",
  UpgradedToMaker = "upgraded_to_maker",
  UpgradedToNonMakerTwoMonth = "upgraded_to_non_maker_two_month",
  UpgradedToMakerTwoMonth = "upgraded_to_maker_two_month",
  SignedUp = "signed_up",
  CustomDomainEnteredOnFreePlan = "custom_domain_entered_on_free_plan",
  VisitedPlansScreenOnFreeAccount = "visited_plans_screen_on_free_account",
  SignedUpWebsiteCount = "signed_up_website_count",
  VisitedEditor = "visited_editor",
  PublishedPageOnce = "published_page_once",

  ExportNotAvailable = "export_not_available",
  CaptchaNotAvailable = "captcha_not_available",
  SendToEmailNotAvailable = "send_to_email_not_available",
  GoogleSheetNotAvailable = "google_sheet_not_available",
  CouldNotPublishOnFree = "could_not_publish_on_free",
}
