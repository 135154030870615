import { useRef, useEffect } from "react";

// This is a hack to make sure that the currentInputData is always up to date. Otherwise, AI and input fields will be out of sync.
export const useCurrentInputData = (currentInputData) => {
  const currentInputDataRef = useRef(currentInputData);
  useEffect(() => {
    currentInputDataRef.current = currentInputData;
  }, [currentInputData]);
  return currentInputDataRef;
};
