import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import VideoElement from "../VideoElement";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}

class Video04 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpGraphics({
                activeOption: GraphicsOptions.video,
                userCanAddItems: true,
                maxItems: 66,
                items: [
                    this.generator.createGraphicsContentObject({
                        videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
                    }),
                    this.generator.createGraphicsContentObject({
                        videoUrl: "https://www.youtube.com/watch?v=J2U9Hmmpqhc",
                    }),
                    this.generator.createGraphicsContentObject({
                        videoUrl: "",
                    }),
                ]
            }));
            this.props.saveContentInStore(this.generator.setUpTitle({
              isDefaultEmpty,
                titleText: ''
            }));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({
              isDefaultEmpty,
                subtitleText: ''
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the video component to offer more videos to see.",
                    default: "Add a CTA (call to action) to the bottom of the video component to offer more videos to see.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'More to see'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let youtubeVideos = this.props.componentItem.componentData.graphics.content.items; // This component has exactly one item and more can not be added (see createGraphicsContentObject() above).

        return (
            <section>
                <div className="video-04">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container video-04__container">

                        {youtubeVideos && youtubeVideos.map((item:any, key:number) => {
                            if(item[GraphicsOptions.video].youtube.url && item[GraphicsOptions.video].youtube.url !== ''){
                                return (
                                  <VideoElement 
                                    videoUrl={item[GraphicsOptions.video].youtube.url}
                                    isDarkBg={this.props.isDarkBg}
                                    defaultGraphicsOption={GraphicsOptions.video}
                                    className="video-04__item"
                                    key={'video-04-' + key}
                                  />
                                )
                            }else{
                                return (null);
                            }
                        })}

                    </div>
                    <div className="container">
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Video04;
