import { Components } from "../../../../enums/Components";
import {
  availableGraphicsOptionExceptions,
  componentsWithGraphicsOptions,
  GraphicsOptions,
} from "../data/componentGraphicsData";

export const getAvailableGraphicsOptions = (
  componentType: string,
  componentTitle: string
): GraphicsOptions[] => {
  const fullComponentTitle = `${componentType}-${componentTitle}` as Components;

  if (
    !componentsWithGraphicsOptions.includes(fullComponentTitle)
  ) {
    return null;
  }

  if (Object.keys(availableGraphicsOptionExceptions).includes(fullComponentTitle)) {
    return availableGraphicsOptionExceptions[fullComponentTitle];
  }

  return Object.values(GraphicsOptions);
};
