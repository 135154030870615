import { GOOGLE_OAUTH2_CLIENT_ID } from "../data/constants";
import { appUrl } from "../data/urls";

export const openGoogleLoginPage = () => {
  const googleAuthUrl = "https://accounts.google.com/o/oauth2/v2/auth";
  const scope = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
  ].join(" ");
  const params = new URLSearchParams({
    response_type: "code",
    client_id: GOOGLE_OAUTH2_CLIENT_ID,
    redirect_uri: `${appUrl}/auth/google-auth`,
    prompt: "select_account",
    access_type: "offline",
    scope,
  });
  const url = `${googleAuthUrl}?${params}`;
  window.location.href = url;
};
