import React from "react";
import { urls } from "../../../../data/urls";
import { ZapierIntegrationStatus } from "../../../../enums/ZapierIntegrationStatus";
import TooltipLink from "../../TooltipLink";

interface Props {
  integrationTitle: string;
  zapierStatus: ZapierIntegrationStatus;
  zapierStatusLoaded: boolean;
  zapierStatusError: string;
  item: any;
}

const IntegrationItemInfo = (props: Props): JSX.Element => {
  const {
    integrationTitle,
    zapierStatus,
    zapierStatusLoaded,
    item,
    zapierStatusError,
  } = props;

  if (integrationTitle === "webhook") {
    return <span>Method: {item.data.method}</span>;
  }

  if (integrationTitle === "google-sheet") {
    return (
      <TooltipLink href={item.data.spreadsheetUrl} hasIcon>
        Open sheet
      </TooltipLink>
    );
  }

  if (integrationTitle === "zapier") {
    if (!zapierStatusLoaded) return null;
    if (zapierStatus === ZapierIntegrationStatus.notActive) {
      return (
        <>
          Please activate this integration in the{" "}
          <TooltipLink href={urls.thirdparty.zapierEditor} hasIcon>
            Zapier editor
          </TooltipLink>
        </>
      );
    }
    if (zapierStatus === ZapierIntegrationStatus.turnedOff) {
      return (
        <>This integration will not trigger any Zaps until turned back on.</>
      );
    }
    if (zapierStatus === ZapierIntegrationStatus.active) {
      return <>This integration triggers a Zap on form submission.</>;
    }
    if (zapierStatus === ZapierIntegrationStatus.error) {
      return <>We couldn't fetch integration status: "{zapierStatusError}"</>;
    }
  }

  return null;
};

export default IntegrationItemInfo;
