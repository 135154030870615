import { marketingParams } from "../data/constants";
import getUrlParameter from "./getUrlParameter";

export const getMarketingParamsForDiscord = () => {
  let paramString = "";
  marketingParams
    .map((param) => {
      return {
        name: param,
        value: getUrlParameter(param),
      };
    })
    .forEach((param) => {
      if (!!param.value) {
        paramString += `\n${param.name}: ${param.value}`;
      }
    });
  return paramString;
};
