export enum CrispEvents {
  setThumbnailAlt = "set_thumbnail_alt",
  setThumbnailAltError = "set_thumbnail_alt_error",
  switchPlan = "switch_plan",
  switchPlanError = "switch_plan_error",
  signUp = "user:signup",
  createBlog = "create_blog",
  createBlogError = "create_blog_error",
  createWebsiteFromTemplate = "create_website_from_template",
  createWebsiteFromTemplateError = "create_website_from_template_error",
  cloneWebsite = "clone_website",
  cloneWebsiteError = "clone_website_error",
  deleteWebsite = "delete_website",
  deleteWebsiteError = "delete_website_error",
  deleteBlog = "delete_blog",
  deleteBlogError = "delete_blog_error",
  forceCloudflareSSL = "force_cloudflare_ssl",
  forceCloudflareSSLError = "force_cloudflare_ssl_error",
  forceSSL = "force_ssl",
  forceSSLError = "force_ssl_error",
  createWebsitePage = "create_website_page",
  createWebsitePageError = "create_website_page_error",
  exportWebsitePage = "export_website_page",
  cloneWebsitePage = "clone_website_page",
  cloneWebsitePageError = "clone_website_page_error",
  moveWebsitePage = "move_website_page",
  moveWebsitePageError = "move_website_page_error",
  deleteWebsitePage = "delete_website_page",
  deleteWebsitePageError = "delete_website_page_error",
  publishWebsitePage = "publish_website_page",
  unpublishWebsitePage = "unpublish_website_page",
  publishWebsitePageError = "publish_website_page_error",
  unpublishWebsitePageError = "unpublish_website_page_error",
  excludeWebsitePageFromSitemap = "exclude_website_page_from_sitemap",
  addWebsitePageToSitemap = "add_website_page_to_sitemap",
  changePageUrl = "change_page_url",
  changePageUrlError = "change_page_url_error",
  changePostUrl = "change_post_url",
  changePostUrlError = "change_post_url_error",
  changePageHeadCustomCode = "change_page_head_custom_code",
  changePostHeadCustomCode = "change_post_head_custom_code",
  changePageHeadCustomCodeError = "change_page_head_custom_code_error",
  changePostHeadCustomCodeError = "change_post_head_custom_code_error",
  changePageMetaTitle = "change_page_meta_title",
  changePageMetaTitleError = "change_page_meta_title_error",
  changeBlogMetaTitle = "change_blog_meta_title",
  changeBlogMetaTitleError = "change_blog_meta_title_error",
  changePostMetaTitle = "change_post_meta_title",
  changePageMetaDescription = "change_page_meta_description",
  changePostMetaDescription = "change_post_meta_description",
  changeBlogMetaDescription = "change_blog_meta_description",
  changeSubdomain = "change_subdomain",
  changeCustomDomain = "change_custom_domain",
  changeBlogURL = "change_blog_url",
  activateCustomDomain = "activate_custom_domain",
  deactivateCustomDomain = "deactivate_custom_domain",
  uploadcareImageUpload = "uploadcare_image_upload",
  saveImageOnServer = "save_image_on_server",
  uploadFavicon = "upload_favicon",
  setFavicon = "set_favicon",
  removeImageFromServer = "remove_image_from_server",
  uploadOpengraphImage = "upload_open_graph_image",
  setOpengraphImage = "set_open_graph_image",
  changeWebsiteHeadCustomCode = "change_website_head_custom_code",
  changeWebsiteBodyCustomCode = "change_website_body_custom_code",
  changeBlogHeadCustomCode = "change_blog_head_custom_code",
  changeBlogBodyCustomCode = "change_blog_body_custom_code",
  successfullyPaid = "paid",
  cancelToPlanError = "cancel_plan_error",
  changeFont = "change_font",
  changeColorPalette = "change_color_palette",
  savePageEditions = "save_page_editions",
  openEditorScreen = "open_editor_screen",
  savePostEditions = "save_post_editions",
  openComponentsGalleryWindow = "open_components_gallery_window",
  addFormComponent = "add_form_component",
  addComponent = "add_component",
  pasteCopiedComponent = "paste_copied_component",
  changeCTAtoForm = "change_cta_to_form",
  openCheckoutWindow = "open_checkout_window",
  openPlansScreen = "open_plans_screen",
  openAddNewIntegrationWindow = "open_add_integration_window",
  addIntegration = "add_integration",
  logout = "logout",
  createBlogPost = "create_blog_post",
  publishBlogPost = "publish_blog_post",
  draftBlogPost = "draft_blog_post",
  directUrlBlogPost = "direct_url_blog_post",
  cloneBlogPost = "clone_blog_post",
  deleteBlogPost = "delete_blog_post",
  addSharedAccess = "add_shared_access",
  removeSharedAccess = "remove_shared_access",
  changeWebsiteWidgets = "edit_widgets",
  changeFontError = "change_font_error",
  changeColorPaletteError = "change_color_palette_error",
  changeWebsiteWidgetsError = "edit_widgets_error",
  addIntegrationError = "add_integration_error",
  addSharedAccessError = "add_shared_access_error",
  setFaviconError = "set_favicon_error",
  setOpengraphImageError = "set_open_graph_image_error",
  changeWebsiteHeadCustomCodeError = "change_website_head_custom_code_error",
  changeWebsiteBodyCustomCodeError = "change_website_body_custom_code_error",
  savePageEditionsError = "save_page_editions_error",
  savePostEditionsError = "save_post_editions_error",
  resentVerificationEmail = "resend_verification_email",
  resentVerificationEmailError = "resend_verification_email_error",
  createBlogPostError = "create_blog_post_error",
  publishBlogPostError = "publish_blog_post_error",
  draftBlogPostError = "draft_blog_post_error",
  directUrlBlogPostError = "direct_url_blog_post_error",
  cloneBlogPostError = "clone_blog_post_error",
  deleteBlogPostError = "delete_blog_post_error",
  removeSharedAccessError = "remove_shared_access_error",
  changeBlogHeadCustomCodeError = "change_blog_head_custom_code_error",
  changeBlogBodyCustomCodeError = "change_blog_body_custom_code_error",
  changeBlogHeadCustomCodePostsError = "change_blog_head_custom_code_posts_error",
  changeBlogBodyCustomCodePostsError = "change_blog_body_custom_code_posts_error",
  changeBlogMetaDescriptionError = "change_blog_meta_description_error",
  changeBlogHeadCustomCodePosts = "change_blog_head_custom_code_posts",
  changeBlogBodyCustomCodePosts = "change_blog_body_custom_code_posts",
  changePostMetaTitleError = "change_post_meta_title_error",
  changePostMetaDescriptionError = "change_post_meta_description_error",
  activateCustomDomainError = "activate_custom_domain_error",
  deactivateCustomDomainError = "deactivate_custom_domain_error",
  changeCustomDomainError = "change_custom_domain_error",
  changeSubdomainError = "change_subdomain_error",
  changeBlogURLError = "change_blog_url_error",
  changePageMetaDescriptionError = "change_page_meta_description_error",
  saveImageOnServerError = "save_image_on_server_error",
  removeImageFromServerError = "remove_image_from_server_error",
  uploadOpengraphImageError = "upload_open_graph_image_error",
  uploadFaviconError = "upload_favicon_error",
  uploadcareImageUploadError = "uploadcare_image_upload_error",
  successfullyUpgradeToPlan = "upgrade_to_plan",
  successfullyUpgradeToMakerPlan = "upgrade_to_maker_plan",
  successfullyUpgradeToNonMakerPlan = "upgrade_to_non_maker_plan",
  upgradeToPlanError = "upgrade_to_plan_error",
  successfullyCancelPlan = "cancel_plan",
  loadBillingDataError = "load_billing_data_error",
  changeGraphicsOption = "change_graphics_option",
  undefined = "undefined_event",
  authorizeApp = "authorize_app",
  authorizeAppError = "authorize_app_error",
  revokeAccess = "revoke_access",
  revokeAccessError = "revoke_access_error",
  changeEmail = "change_email",
  changeEmailError = "change_email_error",
  deleteAccountError = "delete_account_error",
  paymentFailed = "event:initial_payment_failed",
}
export enum FacebookEvents {
  registration = "Registration",
  purchase = "Purchase",
}
export enum GoogleTagEvents {
  signUp = "Sign Up v.2",
  upgrade = "Upgrade",
}

export enum PosthogEvents {
  LOGIN = "login",
  SIGNUP = "signup",
  CLICK_CREATE_NEW_WEBSITE = "click_create_new_website",
  CLICK_CREATE_OR_PREVIEW_TEMPLATE = "click_create_or_preview_template",
  CLICK_CREATE_FROM_THIS_TEMPLATE = "click_create_from_this_template",
  CHOOSE_AI_CONTENT = "choose_ai_content",
  CHOOSE_PLACEHOLDER_CONTENT = "choose_placeholder_content",
  GENERATE_CONTENT_WITH_AI = "generate_content_with_ai",
  SAVE_AI_GENERATED_CONTENT = "save_ai_generated_content",
  CLOSE_AI_TEMPLATE_MODAL = "close_ai_template_modal",
  CLICK_ASK_AI = "click_ask_ai",
  CLICK_PRESET = "click_preset",
  SIGN_UP_FULL = "sign_up_full",
  UPGRADE_FROM_FREE = "upgrade_from_free",
  CHANGE_PLAN = "change_plan",
  CANCEL_PLAN = "cancel_plan",
  UPGRADE_PLAN = "upgrade_plan",
  DOWNGRADE_PLAN = "downgrade_plan",
  CHANGE_EMAIL = "change_email",
  ADD_COMPONENT = "add_component",
  CLICK_INSERT_COMPONENT = "click_insert_component",
  CLICK_ADD_COMPONENT = "click_add_component",
  CLICK_EDIT = "click_edit",
  CLICK_DELETE_COMPONENT = "click_delete_component",
  CLICK_SETTINGS_TAB = "click_settings_tab",
  CLICK_ACCOUNT_DROPDOWN = "click_account_dropdown",
  USE_PAGE_TEMPLATE = "use_page_template",
  SET_PAGE_URL = "set_page_url",
}
