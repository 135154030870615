import React from "react";
import { InputProps } from "../interfaces";
import StepButtonSection from "./StepButtonSection";
import DeleteStepButton from "./DeleteStepButton";
import StepTextSection from "./StepTextSection";
import MoveStepButtons from "./MoveStepButtons";
import AddNewStepButton from "./AddNewStepButton";
import StepGraphicsOptions from "./StepGraphicsOptions";

const Steps = (props: InputProps): JSX.Element => {
  const {
    currentInputData,
    content,
    saveContentInStore,
    toggleNewEditionsDetected,
    generator,
    contentType,
    currentWebsite,
    textEditorType,
    revertToPlainHTML,
    displayMoveUpDownButtons,
    saveImageInfoInDB,
    removeImageFromDB,
    displayButtonStyleEditorOpener,
    state,
    displayButtonPluginsDrawerOpener,
    setState,
  } = props;

  const stepsInputs = content.items.map((item: any, key: number) => {
    return (
      <div className={"content-input__cluster "} key={"steps-" + key}>
        <DeleteStepButton
          keyProp={key}
          saveContentInStore={saveContentInStore}
          generator={generator}
          contentType={contentType}
          currentInputData={currentInputData}
          toggleNewEditionsDetected={toggleNewEditionsDetected}
        />
        <MoveStepButtons
          displayMoveUpDownButtons={displayMoveUpDownButtons}
          keyProp={key}
          content={content}
          saveContentInStore={saveContentInStore}
          generator={generator}
          contentType={contentType}
          currentInputData={currentInputData}
        />
        <StepGraphicsOptions
          item={item}
          saveContentInStore={saveContentInStore}
          generator={generator}
          contentType={contentType}
          currentInputData={currentInputData}
          keyProp={key}
          toggleNewEditionsDetected={toggleNewEditionsDetected}
          saveImageInfoInDB={saveImageInfoInDB}
          removeImageFromDB={removeImageFromDB}
          state={state}
          content={content}
        />
        <StepTextSection
          item={item}
          keyProp={key}
          textEditorType={textEditorType}
          revertToPlainHTML={revertToPlainHTML}
          saveContentInStore={saveContentInStore}
          generator={generator}
          contentType={contentType}
          currentInputData={currentInputData}
          toggleNewEditionsDetected={toggleNewEditionsDetected}
        />
        <StepButtonSection
          item={item}
          keyProp={key}
          saveContentInStore={saveContentInStore}
          generator={generator}
          contentType={contentType}
          currentInputData={currentInputData}
          toggleNewEditionsDetected={toggleNewEditionsDetected}
          displayButtonStyleEditorOpener={displayButtonStyleEditorOpener}
          state={state}
          setState={setState}
          currentWebsite={currentWebsite}
          displayButtonPluginsDrawerOpener={displayButtonPluginsDrawerOpener}
        />
      </div>
    );
  });

  return (
    <div>
      {stepsInputs}
      <AddNewStepButton
        saveContentInStore={saveContentInStore}
        generator={generator}
        contentType={contentType}
        currentInputData={currentInputData}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        content={content}
      />
    </div>
  );
};

export default Steps;
