import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { displayMarkupText } from "../../helpers/content_displayers/displayMarkupText";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}

class Text07 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: "Make a Rad Website",
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpText(
                {
                    items: [
                        {"markup":isDefaultEmpty ? "" : "Est magnum nix, cesaris. Festus, velox decors tandem attrahendam de barbatus, camerarius terror. A falsis, amicitia barbatus indictio. Hydras ridetis! Pol, a bene hippotoxota, orgia! Cur buxum peregrinationes? Pol."}
                    ]}
            ));
            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the text component to offer more to read.",
                    default: "Add a CTA (call to action) to the bottom of the text component to offer more to read.",
                },


                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Read more in out cute blog",
                        buttonHref: '/more',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        buttonColor: buttonStyles.pill.blue,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'New'
                    }),

                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'More'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts

        // In this Text component there is only one text element.
        let text = displayMarkupText(this.props.componentItem.componentData.text.content.items[0].markup);
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let ctaFirstButton = this.props.componentItem.componentData.cta.content.buttons[0];
        let ctaFirstButtonHref = ctaFirstButton && ctaFirstButton.href;
        return (
            <section>
                <div className="text--07">
                    <div className="container container--small">
                        {title.length > 0 && <h2 className={"title-text heading " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: title}}/>}
                        <div className={"content_box " + (this.props.isDarkBg ? 'text-white' : '')}>
                            {text.length > 0 && <p className={"content-text content_box " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: text}}/>}
                        </div>

                        {ctaFirstButton && ctaFirstButtonHref && ctaFirstButtonHref !== '' && <div className="text--07__link_box">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>}
                    </div>
                </div>
            </section>
        )
    }
}

export default Text07;
