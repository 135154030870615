import { Button, Icon } from "antd";
import React from "react";
import { pricingTiersFeatures } from "../../../../../data/pricingTiersFeatures";
import { getCurrentPlan } from "../../../../../helpers/plans/getCurrentPlan";
import { connect } from "react-redux";

interface Props {
  user: any;
  plans: any;

  title: string;
  showDealsModal: () => void;
}

const PlanFeatures = (props: Props): JSX.Element => {
  const { user, plans, title, showDealsModal } = props;

  let featuresJSX = [];
  const isXyzAvailable = () => {
    const currentPlan = getCurrentPlan(user, plans);
    return user.isPro && currentPlan.title !== "maker";
  };
  for (let i = 0; i < pricingTiersFeatures.length; i++) {
    let featureValue = pricingTiersFeatures[i][title];

    let singleFeatureJSX;
    let isDealsFeature = pricingTiersFeatures[i]["is_deals"];
    let isXyzFeature = pricingTiersFeatures[i]["is_xyz"];
    // all features except deals has a value which we display in the feature cell.
    // the deals feature is special: we need to display a JSX element - a button to open the deals modal window.
    // so we marked th deals feature as 'is_deals'
    // let's check if the feature is the special 'deals' feature
    if (isDealsFeature || (isXyzFeature && isXyzAvailable())) {
      if (featureValue) {
        singleFeatureJSX = (
          <div className="plan__feature" key={i}>
            <Button
              className={"plan__feature_button"}
              type={"primary"}
              ghost
              onClick={() => showDealsModal()}
              icon={"arrow-right"}
            >
              Get it
            </Button>
          </div>
        );
      } else {
        singleFeatureJSX = (
          <div className="plan__feature" key={i}>
            &nbsp;
          </div>
        );
      }
    } else {
      if (typeof featureValue === "boolean") {
        if (featureValue === true) {
          singleFeatureJSX = (
            <div className="plan__feature" key={i}>
              <Icon
                style={{ color: "var(--ant)" }}
                type="check-circle"
                theme="filled"
              />
            </div>
          );
        } else {
          singleFeatureJSX = (
            <div className="plan__feature" key={i}>
              &nbsp;
            </div>
          );
        }
      } else if (typeof featureValue === "number") {
        singleFeatureJSX = (
          <div className="plan__feature" key={i}>
            {featureValue.toString()}
          </div>
        );
      } else if (typeof featureValue === "string") {
        singleFeatureJSX = (
          <div className="plan__feature" key={i}>
            {featureValue}
          </div>
        );
      }
    }

    featuresJSX.push(singleFeatureJSX);
  }
  return <div>{featuresJSX}</div>;
};

const mapStateToProps = ({ user, plans }) => {
  return { user, plans };
};
export default connect(mapStateToProps, null)(PlanFeatures);
