import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import generateRandomNumerousId from "../../../../helpers/generateRandomNumerousId";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  const itemUrl = stringifyObjectsAndNumbers(modifiedItem.URL) || "";
  const itemTarget = stringifyObjectsAndNumbers(modifiedItem.TARGET) || "_self";
  return {
    itemId,
    itemPosition,
    itemTitle,
    itemUrl,
    itemTarget,
  };
};

const particleModifyOriginalItem = (
  dropdownItemsObject,
  topLevelItemId,
  originalItem,
  modifiedItem,
  itemPosition,
  itemTitle,
  itemUrl,
  itemTarget
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    dropdownItemsObject[topLevelItemId].forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(dropdownItemsObject[topLevelItemId], item);
      }
    });
  }
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("URL" in modifiedItem) {
    originalItem.href = itemUrl;
  }
  if ("TARGET" in modifiedItem) {
    originalItem.isTargetBlank = itemTarget === "_blank";
  }
};

export const updateNavSubmenuLinks = (
  componentData,
  completionContent,
  originalTopLevelItem
) => {
  const content = componentData.navLinks.content;
  const topLevelItemId = originalTopLevelItem.id;
  const dropdownItemsObject = content.dropdownItems;
  if (!dropdownItemsObject[topLevelItemId]) {
    dropdownItemsObject[topLevelItemId] = [];
  }
  dropdownItemsObject[topLevelItemId] = deleteEmptyItems(
    dropdownItemsObject[topLevelItemId],
    "title"
  );
  addDefaultValuesToItems(dropdownItemsObject[topLevelItemId]);
  completionContent.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemTitle,
      itemUrl,
      itemTarget,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      dropdownItemsObject[topLevelItemId] = deleteItem(
        dropdownItemsObject[topLevelItemId],
        itemId
      );
      return;
    }
    const isItemNew = getIsItemNew(dropdownItemsObject[topLevelItemId], itemId);
    if (isItemNew) {
      const newItem = generator.createNavLinksItemContentObject({
        title: "",
        href: "",
        id: generateRandomNumerousId(),
      });
      addNewItem(
        dropdownItemsObject[topLevelItemId],
        newItem,
        itemId,
        itemPosition
      );
    }
    const originalItem = getOriginalItem(
      dropdownItemsObject[topLevelItemId],
      itemId
    );
    particleModifyOriginalItem(
      dropdownItemsObject,
      topLevelItemId,
      originalItem,
      modifiedItem,
      itemPosition,
      itemTitle,
      itemUrl,
      itemTarget
    );
  });
  sortItemsByPosition(dropdownItemsObject[topLevelItemId]);
};
