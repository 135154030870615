import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import _ from "lodash";
import "./Other04.scss";
import classNames from "classnames";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Other04 extends Component<Props, any> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      this.props.saveContentInStore(
        this.generator.setUpMarketsy({
          isDefaultEmpty,
        })
      );
    }
  }

  componentDidMount(): void {
    // Check if the script is already in the head of the dom
    let script = document.querySelector(
      `script[src="https://marketsy.ai/libs/widget.js"]`
    ) as HTMLScriptElement;
    if (script) return;
    script = document.createElement("script");
    script.src = "https://marketsy.ai/libs/widget.js";
    script.type = "module";
    document.body.append(script);
  }

  render() {
    const widgetSrc = _.get(
      this.props.componentItem,
      "componentData.marketsy.content.src",
      ""
    );
    const widgetId = _.get(
      this.props.componentItem,
      "componentData.marketsy.content.id",
      ""
    );

    return (
      <section>
        <div className="other-04">
          <div
            className={classNames("other-04__widget-container", {
              "is-loading": this.state.isLoading,
            })}
          >
            {this.state.isLoading && <div className="other-04__spinner"></div>}
            <iframe
              className="marketsy-widget"
              onLoad={() => {
                this.setState({ isLoading: false });
              }}
              id={`${widgetId}-${this.props.componentItem.id}`}
              src={`${widgetSrc}&seed=${this.props.componentItem.id}`}
              width="100%"
              height="0px"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      </section>
    );
  }
}

export default Other04;
