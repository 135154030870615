import React, { Component } from "react";
import { Button, Icon, Input, Tooltip } from "antd";
import "./WebsiteRedirects.css";
import MyButton from "../MyButton";
import { distanceInWords } from "date-fns";
import { trimSymbol } from "../../helpers/strings/trimSymbol";

interface Props {
  redirectUrlPlaceholder: any;
  currentRedirectFromUrl: any;
  currentRedirectDestinationUrl: any;
  connectedOn: any;
  itemId: string;
  editRedirectItem: any;
  websites: any;
  deleteRedirectItem: any;
}
type MyState = {
  currentRedirectFromUrl: string;
  currentRedirectDestinationUrl: string;
  showSaveRedirectUrlButtonSuccess: boolean;
  showSaveCodeButtonSuccess: boolean;
};
class WebsiteRedirectItem extends Component<Props, MyState> {
  constructor(props) {
    super(props);
    this.state = {
      currentRedirectFromUrl: "",
      currentRedirectDestinationUrl: "",
      showSaveRedirectUrlButtonSuccess: false,
      showSaveCodeButtonSuccess: false,
    };
  }

  componentWillMount(): void {
    this.setState({
      currentRedirectFromUrl: this.props.currentRedirectFromUrl,
      currentRedirectDestinationUrl: this.props.currentRedirectDestinationUrl,
      showSaveRedirectUrlButtonSuccess: false,
    });
  }

  handleCurrentRedirectFromUrlInputChange = (e: any) => {
    let newValue = e.target.value;
    // We do no want to parse user input so we block '/' - this disables absolute paths and nesting
    newValue = newValue.replace(" ", "");
    newValue = newValue.toLowerCase();
    this.setState({ currentRedirectFromUrl: newValue });
  };
  handleCurrentRedirectDestinationUrlInputChange = (e: any) => {
    let newValue = e.target.value;
    // newValue = newValue.toLowerCase();
    this.setState({ currentRedirectDestinationUrl: newValue });
  };

  launchSuccessfulSaveAnimation = () => {
    this.setState({
      showSaveRedirectUrlButtonSuccess: true,
    });

    setTimeout(() => {
      this.setState({
        showSaveRedirectUrlButtonSuccess: false,
      });
    }, 1500);
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.setState(
      (prevState: MyState) => {
        return {
          currentRedirectFromUrl: trimSymbol(
            prevState.currentRedirectFromUrl,
            "/"
          ),
        };
      },
      () => {
        this.props.editRedirectItem(
          this.props.itemId,
          this.state.currentRedirectFromUrl,
          this.state.currentRedirectDestinationUrl,
          this.launchSuccessfulSaveAnimation
        );
      }
    );
  };

  render() {
    let isFromEqual =
      this.props.currentRedirectFromUrl === this.state.currentRedirectFromUrl;
    let isToEqual =
      this.props.currentRedirectDestinationUrl ===
      this.state.currentRedirectDestinationUrl;
    let isButtonVisible = isFromEqual === false || isToEqual === false; //if one of them differs, can save the changes;

    let clientCurrentTime = new Date();
    let difference = distanceInWords(
      clientCurrentTime.getTime(),
      new Date(this.props.connectedOn).getTime()
    );

    let isWaitingForServerResponse = this.props.websites
      .isWaitingForCommonWebsiteUpdateResponse;

    return (
      <div className="website-redirects__item">
        <form onSubmit={this.handleSubmit} className="website-redirects__form">
          <div className="website-redirects__inputs">
            <Tooltip
              trigger={"focus"}
              title={
                <div>
                  Example: <br />
                  <br />
                  <b>about-us</b>
                  <br />
                </div>
              }
              placement="top"
            >
              <Input
                name={"redirect_from-" + this.props.itemId}
                id={"redirect_from-" + this.props.itemId}
                required={false}
                className="website-redirects__input website-redirects__input--from"
                placeholder={"Enter page URL"}
                type="text"
                addonBefore={"from"}
                value={this.state.currentRedirectFromUrl}
                onChange={this.handleCurrentRedirectFromUrlInputChange}
                //disabled={isWaitingForServerResponse}
              />
            </Tooltip>

            <div className="website-redirects__inputs_divider">
              <Icon type="arrow-right" />
            </div>

            <Tooltip
              trigger={"focus"}
              title={
                <div>
                  Examples: <br />
                  <br />
                  <b>our-team</b>
                  <br />
                  <b>https://domain.com/about-us</b>
                </div>
              }
              placement="top"
            >
              <Input
                name={"redirect_to-" + this.props.itemId}
                id={"redirect_to-" + this.props.itemId}
                required={false}
                className="website-redirects__input website-redirects__input--to"
                addonBefore={<span> to</span>}
                placeholder={"Enter destination URL"}
                type="text"
                value={this.state.currentRedirectDestinationUrl}
                onChange={this.handleCurrentRedirectDestinationUrlInputChange}
                //disabled={isWaitingForServerResponse}
              />
            </Tooltip>

            <Button
              onClick={(event: any) => {
                this.props.deleteRedirectItem(this.props.itemId);
              }}
              htmlType="button"
              loading={isWaitingForServerResponse}
              className="website-redirects__delete"
              shape="circle"
              title="Delete this redirect"
              icon="cross"
              size="small"
            />
          </div>
          <MyButton
            className="settings-box__form_save"
            icon="save"
            loading={isWaitingForServerResponse}
            showButtonSuccess={this.state.showSaveRedirectUrlButtonSuccess}
            type="primary"
            htmlType="submit"
            hidden={!isButtonVisible}
          />
        </form>
      </div>
    );
  }
}

export default WebsiteRedirectItem;
