import React from "react";
import { Input } from "antd";
import { connect } from "react-redux";
import { getCaptchaSitekey } from "../../../helpers/captcha/getCaptchaSitekey";
import {
  toggleWebsiteCaptchaEditionsDetected,
  saveWebsiteCaptchaInState,
} from "../../../store/websites/actions";
import ContentInputLabel from "../ContentInputLabel";
import MyButton from "../../MyButton";
import { urls } from "../../../data/urls";
import TooltipLink from "../TooltipLink";

interface Props {
  currentWebsite: any;
  toggleWebsiteCaptchaEditionsDetected?: (payload: boolean) => void;
  saveWebsiteCaptchaInState?: (payload: object) => void;
}

interface State {
  currentSitekey: string;
  showButtonSuccess: boolean;
}

class CaptchaSitekeyInput extends React.Component<Props, State> {
  info = {
    title: (
      <div>
        Enter the <b>site</b> key:
      </div>
    ),
    popover: (
      <div className="content-input__tooltip-content">
        <p>
          Enter the <b>site</b> key from your reCAPTCHA dashboard to activate
          the basic protection.
        </p>
        <p>
          Learn about the site key in{" "}
          <TooltipLink href={urls.guides.recaptcha} hasIcon>
            our guide
          </TooltipLink>
        </p>
      </div>
    ),
  };

  constructor(props) {
    super(props);
    this.state = {
      currentSitekey: getCaptchaSitekey(this.props.currentWebsite),
      showButtonSuccess: false,
    };
  }

  handleSubmit = (e: any) => {
    e.preventDefault();
    const captchaObject = {
      ...this.props.currentWebsite.recaptcha,
      sitekey: this.state.currentSitekey,
    };
    const currentWebsiteSubdomain = this.props.currentWebsite.subdomain;
    this.props.toggleWebsiteCaptchaEditionsDetected(true);
    this.props.saveWebsiteCaptchaInState({
      currentWebsiteSubdomain,
      captchaObject,
    });
    this.playSuccessAnimation();
  };

  handleInputChange = (e: any) => {
    this.setState({
      currentSitekey: e.target.value,
    });
  };

  displayInput = () => {
    return (
      <form onSubmit={this.handleSubmit} className="settings-box__form">
        <div className="settings-box__input_box captcha-drawer__input">
          <Input
            name="captcha_site_key"
            id="captcha_site_key"
            required={false}
            placeholder="6Lfp_6AfAAAAAJe6GjinVESzvsOUlfRvFPU0wj_Q"
            type="text"
            value={this.state.currentSitekey}
            onChange={this.handleInputChange}
            maxLength={100}
          />
        </div>
        {this.displaySaveButton()}
      </form>
    );
  };

  playSuccessAnimation = () => {
    this.setState({
      showButtonSuccess: true,
    });
    setTimeout(() => {
      this.setState({
        showButtonSuccess: false,
      });
    }, 1500);
  };

  displaySaveButton = () => {
    const isButtonHidden =
      this.state.currentSitekey ===
      getCaptchaSitekey(this.props.currentWebsite);
    return (
      <MyButton
        className="settings-box__form_save"
        icon="save"
        type="primary"
        htmlType="submit"
        hidden={isButtonHidden}
        showButtonSuccess={this.state.showButtonSuccess}
      />
    );
  };

  render() {
    return (
      <div className="captcha-drawer__section">
        <ContentInputLabel title={this.info.popover}>
          {this.info.title}
        </ContentInputLabel>
        {this.displayInput()}
      </div>
    );
  }
}

export default connect(null, {
  toggleWebsiteCaptchaEditionsDetected,
  saveWebsiteCaptchaInState,
})(CaptchaSitekeyInput);
