import actionsNames from "./actions";
import { PlansState } from "./types";

let initialState: PlansState = {
  items: [],
  dataFetched: false,
};

export const plansReducer = (state: PlansState = initialState, action: any) => {
  switch (action.type) {
    case actionsNames.SAVE_PLANS_DATA:
      return {
        ...state,
        items: [
          // ...state.items,
          ...action.payload,
        ],
      };

    case actionsNames.PLANS_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };

    default:
      return state;
  }
};
