import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { connect } from "react-redux";
import { fillTemplateAsync } from "../../../store/gpt/thunks/fillTemplateAsync";
import {
  DeleteModifiedComponent,
  FillTemplateAsync,
} from "../../../store/gpt/types";
import {
  ClearFooter,
  ClearNavigation,
  EditFillTemplateData,
  WebsitesState,
} from "../../../store/websites/types";
import { cleanInputValue } from "../../editor/ai2/cleanInputValue";
import { getCurrentWebsite } from "../../../helpers/getCurrentWebsite";
import { getCurrentWebsitePageUrl } from "../../../helpers/getCurrentWebsitePageUrl";
import {
  ClearWebsitePage,
  WebsitePagesState,
} from "../../../store/websitePages/types";
import { getCurrentWebsitePage } from "../../../helpers/getCurrentWebsitePage";
import {
  AbortControllersRef,
  CompletionRef,
  QueueItem,
} from "../../editor/ai2/types";
import { clearWebsitePage } from "../../../store/websitePages/thunks";
import _ from "lodash";
import { clearFooter, clearNavigation } from "../../../store/websites/thunks";
import { editFillTemplateData } from "../../../store/websites/actions";
import FillTemplateCharacterCounter from "./FillTemplateCharacterCounter";
import { GPT_ASSISTANT_PROMPT_LENGTH_LIMIT } from "../../../data/constants";
import { deleteModifiedComponent } from "../../../store/gpt/actions";
import { PosthogEvents } from "../../../enums/AnalyticsEventsEnums";

interface Props {
  websites: WebsitesState;
  websitesPages: WebsitePagesState;
  fillTemplateAsync: FillTemplateAsync;
  clearWebsitePage: ClearWebsitePage;
  clearNavigation: ClearNavigation;
  clearFooter: ClearFooter;
  editFillTemplateData: EditFillTemplateData;
  deleteModifiedComponent: DeleteModifiedComponent;

  queueDataRef: React.MutableRefObject<QueueItem[]>;
  intervalRef: React.MutableRefObject<NodeJS.Timeout>;
  forceStopAllFlagRef: React.MutableRefObject<boolean>;
  handleFillTemplateCancel: () => void;
  setIsFillTemplateFormLoading: (value: boolean) => void;
  isLoading: boolean;
  abortControllersRef: AbortControllersRef;
}

const FillTemplateForm = (props: Props): JSX.Element => {
  const {
    websites,
    websitesPages,
    fillTemplateAsync,
    queueDataRef,
    intervalRef,
    handleFillTemplateCancel,
    forceStopAllFlagRef,
    clearWebsitePage,
    clearNavigation,
    clearFooter,
    editFillTemplateData,
    setIsFillTemplateFormLoading,
    isLoading,
    abortControllersRef,
    deleteModifiedComponent,
  } = props;
  const [inputValue, setInputValue] = useState("");

  const completionRef: CompletionRef = useRef({
    currentCompletion: "",
    fullCompletion: "",
  });
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsFillTemplateFormLoading(true);
    const cleanedInputValue = cleanInputValue(inputValue);
    const currentWebsite = getCurrentWebsite(websites.items);
    const currentPageUrl = getCurrentWebsitePageUrl(
      currentWebsite,
      websitesPages.items
    );
    const currentWebsitePage = getCurrentWebsitePage(
      currentPageUrl,
      websitesPages.items,
      currentWebsite
    );
    const currentPageIndex = websitesPages.items.indexOf(currentWebsitePage);
    const templateSubdomain = _.get(
      currentWebsite,
      ["fillTemplateData", "templateSubdomain"],
      ""
    );
    const skipNav = _.get(
      currentWebsite,
      ["fillTemplateData", "skipNav"],
      false
    );
    const skipFooter = _.get(
      currentWebsite,
      ["fillTemplateData", "skipFooter"],
      false
    );
    window.posthog.capture(PosthogEvents.GENERATE_CONTENT_WITH_AI, {
      website_id: currentWebsite.id,
      template_subdomain: templateSubdomain,
    });
    const response = await fillTemplateAsync({
      currentWebsitePage,
      currentWebsite,
      currentPageIndex,
      cleanedInputValue,
      queueDataRef,
      intervalRef,
      forceStopAllFlagRef,
      abortControllersRef,
      templateSubdomain,
      setIsFillTemplateFormLoading,
      deleteModifiedComponent,
      completionRef,
      skipNav,
      skipFooter,
    });
    setIsFillTemplateFormLoading(false);
    const isError = _.get(response, ["isError"]);
    if (isError) {
      return;
    }
    if (!skipNav) {
      clearNavigation();
    }
    clearWebsitePage();
    if (!skipFooter) {
      clearFooter();
    }
    handleFillTemplateCancel();
    editFillTemplateData({
      websiteId: currentWebsite.id,
      newData: {
        status: "inProgress",
        hasUnsavedAiGeneratedContent: true,
      },
    });
  };

  return (
    <form
      onSubmit={handleOnSubmit}
      id="fill-template-form"
      className="fill-template-modal__form"
    >
      <TextArea
        rows={4}
        autosize={{ minRows: 3, maxRows: 7 }}
        style={{ width: "400px" }}
        onChange={handleOnChange}
        value={inputValue}
        placeholder="CyberUnicorn: An AI-powered platform that magically streamlines teamwork by telepathically connecting employees for seamless collaboration."
        className="fill-template-modal__textarea"
        disabled={isLoading}
        autoFocus
        maxLength={GPT_ASSISTANT_PROMPT_LENGTH_LIMIT}
      />
      <FillTemplateCharacterCounter inputValue={inputValue} />
    </form>
  );
};

const mapStateToProps = ({ websites, websitesPages }) => {
  return { websites, websitesPages };
};
const mapActionsToProps = {
  fillTemplateAsync,
  clearWebsitePage,
  clearNavigation,
  clearFooter,
  editFillTemplateData,
  deleteModifiedComponent,
};
export default connect(mapStateToProps, mapActionsToProps)(FillTemplateForm);
