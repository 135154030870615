import React, { Component } from "react";
import "./App.css";
import "./temp-patch.css";
import "antd/dist/antd.css";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./store/reducers";
import { Redirect, Route, Switch } from "react-router";
import AuthLayout from "./components/layout/AuthLayout";
import PrimaryLayout from "./components/layout/PrimaryLayout";
import { BrowserRouter as Router } from "react-router-dom";
import NotFound from "./components/NotFound";
import getUrlParameter from "./helpers/getUrlParameter";
import Cookies from "js-cookie";
import MobileResolutionWarning from "./components/MobileResolutionWarning";
import thunk from "redux-thunk";
import { message } from "antd";
import bugsnag from "@bugsnag/js";
import bugsnagReact from "@bugsnag/plugin-react";
import HelpButton from "./components/dashboard/HelpButton";
import Permissions from "./components/auth/Permissions";
import { gptMiddleware } from "./store/middleware";

const bugsnagClient = bugsnag("8145657e5ead31dc9ca3deedd22b141c");
bugsnagClient.use(bugsnagReact, React);

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk, gptMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      process.env.NODE_ENV === "development"
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (a) => a // compose() expects a function, so if devtools extension is not installed, we pass a dummy function because otherwise we'll get an error
  )
);

const ErrorBoundary = bugsnagClient.getPlugin("react");

class App extends Component {
  storeUtmTagsInCookies() {
    // see unicornplatform/allauth/account/adapter.py for more info
    let ref = getUrlParameter("ref");
    if (ref !== undefined && ref !== null && ref.length > 0) {
      Cookies.set("ref_get_param", ref);
    }

    let utmTags = ["utm_source", "utm_medium", "utm_content", "utm_campaign"];
    let utmTagsCount = utmTags.length;

    for (let i = 0; i < utmTagsCount; i++) {
      let utmTag = utmTags[i];
      let utmTagContent = getUrlParameter(utmTag);
      if (
        utmTagContent !== undefined &&
        utmTagContent !== null &&
        utmTagContent.length > 0
      ) {
        Cookies.set(utmTag, utmTagContent);
      }
    }

    let templateTag = getUrlParameter("template");
    if (
      templateTag !== undefined &&
      templateTag !== null &&
      templateTag.length > 0
    ) {
      Cookies.set("template", templateTag);
    }
  }
  componentDidMount(): void {
    (window as any).$crisp = [
      ["config", "sound:mute", [true]],
      [
        "on",
        "chat:closed",
        () => {
          (window as any).$crisp.push(["do", "chat:hide"]);
        },
      ],
    ];
    (window as any).CRISP_WEBSITE_ID = "545a7057-30ba-42a0-99b6-7c8ba46735d6";

    (window as any).$crisp.push([
      "on",
      "session:loaded",
      () => {
        let unreadCount = (window as any).$crisp.get("chat:unread:count");
        if (unreadCount === 0) {
          // if nothing to read from us for a user, hide the chat
          (window as any).$crisp.push(["do", "chat:hide"]);
        } else {
          // if we sent something to a user, keep the chat button visible so user wants to click on it
        }
      },
    ]);

    (window as any).$crisp.push([
      "on",
      "message:received",
      () => {
        (window as any).$crisp.push(["do", "chat:show"]);
        (window as any).$crisp.push(["do", "chat:open"]);
      },
    ]);

    (function() {
      var d = document;
      var s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    if (location.search && location.search.length > 0) {
      this.storeUtmTagsInCookies();
    }

    // https://ant.design/components/message/
    // Global settings
    message.config({
      duration: 5, //seconds
      maxCount: 3,
    });
  }

  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <Router>
            <Switch>
              {/*Only one of the routes in a given <Switch> will render.*/}

              <Route path="/auth" component={AuthLayout} />
              <Route path="/permission/:app" component={Permissions} />

              {/*// django generates links with password reset special data (uid and token) in this format: reset/<uidb64>/<token>/*/}
              {/*// we catch these requests and redirect to the /auth/reset because the AuthLayout takes care about layout and data for our forms*/}
              <Redirect
                from="/reset/:uid/:token"
                to="/auth/reset/:uid/:token"
              />

              <Route exact path="/404" component={NotFound} />

              <Route path="/" component={PrimaryLayout} />
            </Switch>
          </Router>

          {/*{this.loadChatWidget()}*/}
          <HelpButton />
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
