import React from "react";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import Pictogram from "./Pictogram";
import WebsiteButton from "./WebsiteButton";

interface Props {
  featureObject: any;
  isWhite: boolean;
}

const WebsiteFeature = (props: Props): JSX.Element => {
  const { featureObject, isWhite } = props;

  if (featureObject.title !== "") {
    return (
      <div
        className={
          "feature " +
          (featureObject.isCentered ? " feature--center " : "") +
          (isWhite ? " feature--white text-white " : "")
        }
      >
        <h3 className="feature__title">
          {featureObject.pictogram.type !== "" && (
            <div className="feature__icon">
              <span className="icon">
                <Pictogram pictogramObject={featureObject.pictogram} />
              </span>
            </div>
          )}

          <span className="feature__title_text title-text--inner def-12">{featureObject.title}</span>
        </h3>
        <div className="feature__content">
          <span
            className="content-text feature__content_text content_box"
            dangerouslySetInnerHTML={{ __html: featureObject.text }}
          />
        </div>
        {featureObject.button.title && featureObject.button.href && (
          <div className="feature__button_box">
            <WebsiteButton
              config={{
                buttonStyleType:
                  featureObject.button.settings.appearance.styleType,
                buttonColor: featureObject.button.settings.appearance.color,
                buttonType: linkOrButtonOptions.link,
                buttonTitle: featureObject.button.title,
                buttonHref: featureObject.button.href,
                buttonIsTargetBlank: featureObject.button.isTargetBlank,
                buttonMobileAppStoreType:
                  featureObject.button.settings.appearance.mobileAppStoreType,
                pillPrefix: featureObject.button.pillPrefix,
                pillColor: featureObject.button.settings.appearance.pillColor,
                ghostColor: featureObject.button.settings.appearance.ghostColor,
              }}
            />
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default WebsiteFeature;
