import actionsNames from "./actions";
import moveArrayItem from "../../helpers/moveArrayItem";
import { defaultBackgrounds } from "../../data/defaultBackgrounds";
import { componentsVerticalPaddings } from "../../data/componentsVerticalPaddings";
import _ from "lodash";
import {
  ChangeComponentGlobalDataPayload,
  ChangeComponentIntegrationIdPayload,
  ChangeDirectoryContentPayload,
  ChangePageItemGlobalDataPayload,
  ChangeWebsitePagesStateGlobalDataPayload,
  EditApiSourceValidationDataPayload,
  ReplaceTemplateComponentsWithEmptyComponentsPayload,
  SetCustomBackgroundColorPayload,
  WebsitePagesItem,
  WebsitePagesState,
} from "./types";
import { switchComponentReducer } from "./reducers/switchComponentReducer";
import { getComponentFromId } from "../../helpers/getComponentFromId";

let initialState: WebsitePagesState = {
  items: [],
  allPagesFetched: false,
  pagesWithoutComponentsFetched: false,
  pageChangeInProgress: false, // We don't provide a server response indicator for each page. We have only one global for every page change request.

  isWaitingForPageUrlUpdateResponse: false,
  isInvalidPageUrlUpdateAttempt: false,
  pageUrlUpdateErrorMessage: undefined,

  isWaitingForPageMetaTitleUpdateResponse: false,
  isInvalidPageMetaTitleUpdateAttempt: false,
  pageMetaTitleUpdateErrorMessage: undefined,

  isWaitingForPageMetaDescriptionUpdateResponse: false,
  isInvalidPageMetaDescriptionUpdateAttempt: false,
  pageMetaDescriptionUpdateErrorMessage: undefined,

  isWaitingForPublishPageResponse: false,

  isWaitingForCreateWebsitePageResponse: false,
  createWebsitePageErrorMessage: undefined,

  isWaitingForCloneWebsitePageResponse: false,
  isWaitingForDeleteWebsitePageResponse: false,

  isWaitingForSaveWebsitePageSchemeResponse: false,
  websitePageNewEditionsDetected: false,
  websitePageNewNavEditionsDetected: false,

  isWaitingForCommonWebsitePageUpdateResponse: false, //used when there is no state for a specific website change
  isWaitingForMoveWebsitePageResponse: false, // when move a page to another website

  advancedComponentsArray: [],
  advancedComponentsLoadedStatus: "not_loaded",
  isFullAdvancedComponentBeingFetched: false,

  isWaitingForUpdatePageSourceApiResponse: false,
  isWaitingForUpdateIsDynamicRouteEnabled: false,

  isWaitingPageTemplateResponse: false,
  pageTemplatesArray: [],
  pageTemplatesLoadedStatus: "not_loaded",

  isPageSheetPurgeCacheLoading: false,
};

export const websitePagesReducer = (
  state: WebsitePagesState = initialState,
  action: any
) => {
  switch (action.type) {
    case actionsNames.SAVE_WEBSITES_PAGES_IN_STATE:
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };

    case actionsNames.SAVE_PAGE_COMPONENTS_IN_STATE: {
      const currentPageData = [...state.items];
      const fullPageData = [...action.payload];

      const updatedPageData = currentPageData.map((currentPageItem) => {
        const id = _.get(currentPageItem, "id");
        const fullPageItem = _.find(fullPageData, { id });

        if (!fullPageItem) return currentPageItem;

        const itemComponentData = _.get(fullPageItem, "page_components");
        return _.set(currentPageItem, "page_components", itemComponentData);
      });

      return {
        ...state,
        items: updatedPageData,
      };
    }

    case actionsNames.REPLACE_WEBSITES_PAGES_IN_STATE:
      return {
        ...state,
        items: [...action.payload],
      };

    case actionsNames.CLEAR_WEBSITES_PAGES:
      // when we fetch websitePages we clear the array.
      return {
        ...state,
        items: [],
      };

    case actionsNames.ALL_WEBSITES_PAGES_DATA_FETCHED:
      return {
        ...state,
        allPagesFetched: action.payload,
      };

    case actionsNames.WEBSITES_PAGES_DATA_WITHOUT_COMPONENTS_FETCHED:
      return {
        ...state,
        pagesWithoutComponentsFetched: action.payload,
      };

    case actionsNames.TOGGLE_WEBSITE_PAGES_CHANGE_IN_PROGRESS:
      // Waiting for the server for a response.
      return {
        ...state,
        pageChangeInProgress: action.payload,
      };

    case actionsNames.PAGE_URL_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForPageUrlUpdateResponse: action.payload,
      };

    case actionsNames.MOVE_PAGE_TO_ANOTHER_WEBSITE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForMoveWebsitePageResponse: action.payload,
      };

    case actionsNames.PAGE_URL_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidPageUrlUpdateAttempt: action.payload,
      };
    case actionsNames.PAGE_URL_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        pageUrlUpdateErrorMessage: action.payload,
      };

    case actionsNames.PAGE_META_TITLE_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForPageMetaTitleUpdateResponse: action.payload,
      };
    case actionsNames.PAGE_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidPageMetaTitleUpdateAttempt: action.payload,
      };
    case actionsNames.PAGE_META_TITLE_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        pageMetaTitleUpdateErrorMessage: action.payload,
      };

    case actionsNames.PAGE_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForPageMetaDescriptionUpdateResponse: action.payload,
      };
    case actionsNames.PAGE_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidPageMetaDescriptionUpdateAttempt: action.payload,
      };
    case actionsNames.PAGE_META_DESCRIPTION_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        pageMetaDescriptionUpdateErrorMessage: action.payload,
      };

    case actionsNames.PUBLISH_PAGE_TOGGLE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForPublishPageResponse: action.payload,
      };

    case actionsNames.CREATE_WEBSITE_PAGE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCreateWebsitePageResponse: action.payload,
      };
    case actionsNames.CREATE_WEBSITE_PAGE_ERROR_MESSAGE:
      return {
        ...state,
        createWebsitePageErrorMessage: action.payload,
      };

    case actionsNames.DELETE_WEBSITE_PAGE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForDeleteWebsitePageResponse: action.payload,
      };

    case actionsNames.CLONE_WEBSITE_PAGE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCloneWebsitePageResponse: action.payload,
      };

    case actionsNames.REMOVE_WEBSITE_PAGES_FROM_STORE:
      let oldWebsitePages = state.items;
      let newWebsitePages = oldWebsitePages.filter((websitePage: any) => {
        return websitePage.website !== action.payload;
      });

      return {
        ...state,
        items: newWebsitePages,
      };

    case actionsNames.REMOVE_SINGLE_WEBSITE_PAGE_FROM_STORE:
      let allWebsitesPages = state.items;
      let allWebsitesPagesExceptTheGivenOne = allWebsitesPages.filter(
        (websitePage: any) => {
          return !(
            websitePage.website === action.payload.websiteId &&
            websitePage.url === action.payload.pageUrl
          );
        }
      );

      return {
        ...state,
        items: allWebsitesPagesExceptTheGivenOne,
      };

    //    edit scheme
    case actionsNames.EDIT_WEBSITE_PAGE_SCHEME_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForSaveWebsitePageSchemeResponse: action.payload,
      };
    case actionsNames.WEBSITE_PAGE_NEW_EDITIONS_DETECTED:
      return {
        ...state,
        websitePageNewEditionsDetected: action.payload,
      };

    case actionsNames.TOGGLE_WEBSITE_PAGE_NAV_NEW_EDITIONS_DETECTED:
      return {
        ...state,
        websitePageNewNavEditionsDetected: action.payload,
      };

    case actionsNames.MOVE_WEBSITE_PAGE_COMPONENT:
      // action.payload.currentPageIndex tells the index of the page under edition (reminder: we store all the pages in the items[])
      // action.payload.oldIndex tells the old index of the component
      // action.payload.newIndex tells the new index of the component

      // get the page components list
      let oldComponentsArray =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let newComponentsArray = [...oldComponentsArray];
      // perform the movement
      moveArrayItem(
        newComponentsArray,
        action.payload.oldIndex,
        action.payload.newIndex
      );

      //clone state
      let newState = { ...state };
      // change the components list with the new one (with the moving performed)
      newState.items[
        action.payload.currentPageIndex
      ].page_components.data.schema = newComponentsArray;

      return {
        ...newState,
      };
    case actionsNames.DELETE_WEBSITE_PAGE_COMPONENT:
      // action.payload.currentPageIndex tells the index of the component to delete

      // get the page components list
      let oldComponentsList =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let newComponentsList = [...oldComponentsList];
      // perform the delete
      newComponentsList.splice(action.payload.componentIndex, 1);

      // get the pages list
      let websitePagesList = state.items;
      // update the components list in the website pages list
      websitePagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = newComponentsList;

      return {
        ...state,
        items: websitePagesList,
      };

    case actionsNames.ADD_WEBSITE_PAGE_COMPONENT:
      const { originalAdvancedComponent, additionalProperties } =
        action.payload;
      // action.payload.currentPageIndex tells the index of the component to delete

      // get the page components list
      let previousComponentsObject =
        state.items[action.payload.currentPageIndex].page_components;

      //check if it's empty (when a new page is created it can be empty or filled with some default components - we must take care of both cases.
      let isContentEmpty =
        Object.entries(previousComponentsObject).length === 0 &&
        previousComponentsObject.constructor === Object;
      if (isContentEmpty) {
        // if empty, fill with object properties.
        state.items[action.payload.currentPageIndex].page_components = {
          data: {
            schema: [],
          },
        };
        //update the variable as well because this variable is used next
        previousComponentsObject =
          state.items[action.payload.currentPageIndex].page_components;
      }
      // get the current page components array[].
      let previousComponentsList = previousComponentsObject.data.schema;

      // clone the page components list
      let updatedComponentsList: any = [...previousComponentsList];

      // == Start of: see if the component has some predefined background options:
      let componentCategoryTitle = action.payload.componentCategoryTitle;
      let defaultBackgroundObject = defaultBackgrounds[componentCategoryTitle];
      if (defaultBackgroundObject) {
        defaultBackgroundObject =
          defaultBackgroundObject[action.payload.componentTitle];
      }
      let defaultBackgroundColor = "white"; //default for all
      if (defaultBackgroundObject && defaultBackgroundObject["color"]) {
        defaultBackgroundColor = defaultBackgroundObject["color"];
      }
      let defaultBackgroundImageUrl = ""; //default for all
      if (defaultBackgroundObject && defaultBackgroundObject["imageUrl"]) {
        defaultBackgroundImageUrl = defaultBackgroundObject["imageUrl"];
      }
      // == End of: see if the component has some predefined background options:

      // == Start of: find the componentpredefined vertical padding value:
      // each component has a default padding-top and padding-bottom value. Most common value: 70. Sometimes 30 and 0.
      let defaultPaddingTop = undefined;
      if (
        componentsVerticalPaddings[action.payload.componentCategoryTitle] &&
        componentsVerticalPaddings[action.payload.componentCategoryTitle][
          action.payload.componentTitle
        ] &&
        componentsVerticalPaddings[action.payload.componentCategoryTitle][
          action.payload.componentTitle
        ]["top"]
      ) {
        defaultPaddingTop =
          componentsVerticalPaddings[action.payload.componentCategoryTitle][
            action.payload.componentTitle
          ]["top"];
      }

      let defaultPaddingBottom = undefined;
      if (
        componentsVerticalPaddings[action.payload.componentCategoryTitle] &&
        componentsVerticalPaddings[action.payload.componentCategoryTitle][
          action.payload.componentTitle
        ] &&
        componentsVerticalPaddings[action.payload.componentCategoryTitle][
          action.payload.componentTitle
        ]["bottom"]
      ) {
        defaultPaddingBottom =
          componentsVerticalPaddings[action.payload.componentCategoryTitle][
            action.payload.componentTitle
          ]["bottom"];
      }

      // == End of: find the componentpredefined vertical padding value:

      // The AI may specify a default CTA option for the component. If it's not valid, we set it to null and ignore it.
      let defaultCtaOption = action.payload.headerCtaType;
      if (defaultCtaOption !== "form" && defaultCtaOption !== "buttons") {
        defaultCtaOption = null;
      }
      // perform the adding
      let newComponent: any = {
        title: action.payload.componentTitle,
        category: action.payload.componentCategoryId,
        componentData: {},
        id:
          action.payload.componentCategoryTitle +
          "-" +
          action.payload.componentTitle +
          "-" +
          Math.floor(Math.random() * 90000) +
          1,
        settings: {
          padding: {
            top: defaultPaddingTop,
            bottom: defaultPaddingBottom,
          },
          visibility: {
            isHiddenOnDesktops: false,
            isHiddenOnTablets: false,
            isHiddenOnMobiles: false,
          },
          background: {
            color: defaultBackgroundColor, // Get info form defaultBackgrounds.ts. Default for all: 'white'.
            imageUrl: defaultBackgroundImageUrl, // Get info form defaultBackgrounds.ts. Default for all: '' (empty string means no bg image used). if this != '', then bg image is active and the 'page-component__bg_image_box' has a class 'page-component__bg_image_box--image' which makes 'page-component__bg_overlay_box' act as an image overlay, not as a color background (just add transparency)
            imageUUID: undefined, //this field was added on the 26th of February 2020.
            overlayOpacity: 0.8, //added on Jul 01 2021. Used here: frontend/src/components/dashboard/BackgroundImagePicker.tsx
            activeOption: "image",
            backgroundVideoUrl: "",
          },
        },
        isDefaultEmpty: action.payload.isDefaultEmpty === true, // Set it to true to make the component empty by default. If false, will be filled with default content. Used primarily for AI generated components.
        defaultCtaOption,
      };
      if (originalAdvancedComponent) {
        newComponent.initial_original_component_id = originalAdvancedComponent.id;
        newComponent.original_component_id = originalAdvancedComponent.id;
        newComponent.settings = originalAdvancedComponent.settings;
        newComponent.componentData = originalAdvancedComponent.componentData;
        newComponent.customCss = originalAdvancedComponent.customCss;
        newComponent.customJs = originalAdvancedComponent.customJs;
        newComponent.customClasses = originalAdvancedComponent.customClasses;
        newComponent.customAttributes =
          originalAdvancedComponent.customAttributes;
        newComponent.savedAdvanced = originalAdvancedComponent.savedAdvanced;
        newComponent.customLayoutGroup = originalAdvancedComponent.customLayoutGroup;
      }

      if (additionalProperties) {
        Object.assign(newComponent, additionalProperties);
      }

      updatedComponentsList.splice(
        action.payload.indexToPlace,
        0,
        newComponent
      );

      // get the pages list
      let pagesList = state.items;
      // update the components list in the website pages list
      pagesList[action.payload.currentPageIndex].page_components.data.schema =
        updatedComponentsList;

      return {
        ...state,
        items: pagesList,
      };

    case actionsNames.PASTE_COPIED_WEBSITE_PAGE_COMPONENT: {
      // get the page components list // action.payload.currentPageIndex tells the index of the component to delete
      let previousComponentsObject =
        state.items[action.payload.currentPageIndex].page_components;

      //check if it's empty (when a new page is created it can be empty or filled with some default components - we must take care of both cases.
      let isContentEmpty =
        Object.entries(previousComponentsObject).length === 0 &&
        previousComponentsObject.constructor === Object;
      if (isContentEmpty) {
        // if empty, fill with object properties.
        state.items[action.payload.currentPageIndex].page_components = {
          data: {
            schema: [],
          },
        };
        //update the variable as well because this variable is used next
        previousComponentsObject =
          state.items[action.payload.currentPageIndex].page_components;
      }
      // get the current page components array[].
      let previousComponentsList = previousComponentsObject.data.schema;

      // clone the page components list
      let updatedComponentsList: any = [...previousComponentsList];

      // perform the adding
      let newComponent: object = {
        ...action.payload.pastedComponentData,
      };
      // generate the new unique ID
      newComponent["id"] =
        getComponentFromId(newComponent["id"]) +
        "-" +
        Math.floor(Math.random() * 90000) +
        1;

      updatedComponentsList.splice(
        action.payload.indexToPlace,
        0,
        newComponent
      );

      // get the pages list
      let pagesList = state.items;
      // update the components list in the website pages list
      pagesList[action.payload.currentPageIndex].page_components.data.schema =
        updatedComponentsList;

      return {
        ...state,
        items: pagesList,
      };
    }

    case actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_CONTENT:
      // action.payload.componentKey is the edited component index
      // action.payload.currentPageIndex is the edited page index
      // action.payload.componentData is the new componentData object{}. It can be a huge object with all the default data or a single update or, e.g., a header text

      // get the page components list
      const page = state.items[action.payload.currentPageIndex];
      if (!page) {
        return state;
      }
      if (!page.page_components) {
        return state;
      }
      let previousComponentsArray = page.page_components.data.schema;

      // clone the page components list
      let updatedComponentsArray: any = [...previousComponentsArray];
      // get the component which componentData we want to edit
      let updatedComponent =
        updatedComponentsArray[action.payload.componentKey];
      if (!updatedComponent) return state;
      // edit the componentData
      updatedComponent.componentData = {
        ...updatedComponent.componentData,
        ...action.payload.componentData,
      };

      // save this component back to the components list
      updatedComponentsArray[action.payload.componentKey] = updatedComponent;

      // clone the pages list
      let newPagesList = [...state.items];
      // get current page and save the updated components list of the current page
      newPagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = updatedComponentsArray;

      return {
        ...state,
        items: newPagesList,
      };

    case actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_COLOR:
      // action.payload.componentKey is the edited component index
      // action.payload.currentPageIndex is the edited page index
      // action.payload.color is the new BG color title. See componentsBackgroundColorsOptions.ts to view them all.

      // get the page components list
      let oldComponents =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let newComponents: any = [...oldComponents];
      // get the component which componentData we want to edit
      let updatedComponentItem = newComponents[action.payload.componentKey];
      // edit the componentData
      updatedComponentItem.settings.background.color = action.payload.color;

      // save this component back to the components list
      newComponents[action.payload.componentKey] = updatedComponentItem;

      // clone the pages list
      let newWebsitePagesList = [...state.items];
      // get current page and save the updated components list of the current page
      newWebsitePagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = newComponents;

      return {
        ...state,
        items: newWebsitePagesList,
      };

    case actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_ACTIVE_OPTION: {
      // get the page components list // action.payload.activeOption is the new active option (image/video). // action.payload.currentPageIndex is the edited page index // action.payload.componentKey is the edited component index
      let oldComponents =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let newComponents: any = [...oldComponents];
      // get the component which componentData we want to edit
      let updatedComponentItem = newComponents[action.payload.componentKey];
      // edit the componentData
      updatedComponentItem.settings.background.activeOption =
        action.payload.activeOption;

      // save this component back to the components list
      newComponents[action.payload.componentKey] = updatedComponentItem;

      // clone the pages list
      let newWebsitePagesList = [...state.items];
      // get current page and save the updated components list of the current page
      newWebsitePagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = newComponents;

      return {
        ...state,
        items: newWebsitePagesList,
      };
    }

    case actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_VIDEO: {
      // get the page components list // action.payload.currentPageIndex is the edited page index // action.payload.componentKey is the edited component index
      let oldComponents =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let newComponents: any = [...oldComponents];
      // get the component which componentData we want to edit
      let updatedComponentItem = newComponents[action.payload.componentKey];
      // edit the componentData
      updatedComponentItem.settings.background.backgroundVideoUrl =
        action.payload.backgroundVideoUrl;

      // save this component back to the components list
      newComponents[action.payload.componentKey] = updatedComponentItem;

      // clone the pages list
      let newWebsitePagesList = [...state.items];
      // get current page and save the updated components list of the current page
      newWebsitePagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = newComponents;

      return {
        ...state,
        items: newWebsitePagesList,
      };
    }

    case actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_PADDING_TOP: {
      // get the page components list // action.payload.color is the new BG color title. See componentsBackgroundColorsOptions.ts to view them all. // action.payload.currentPageIndex is the edited page index // action.payload.componentKey is the edited component index
      let oldComponents =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let newComponents: any = [...oldComponents];
      // get the component which componentData we want to edit
      let updatedComponentItem = newComponents[action.payload.componentKey];

      // edit the componentData
      // if the component settings object does not have the padding.top and padding.bottom, let's set it so we can access the properties without an JS exception
      if (updatedComponentItem.settings.padding === undefined) {
        updatedComponentItem.settings["padding"] = {
          top: undefined,
          bottom: undefined,
        };
      }
      updatedComponentItem.settings.padding.top =
        action.payload.new_padding_top_value;

      // save this component back to the components list
      newComponents[action.payload.componentKey] = updatedComponentItem;

      // clone the pages list
      let newWebsitePagesList = [...state.items];
      // get current page and save the updated components list of the current page
      newWebsitePagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = newComponents;

      return {
        ...state,
        items: newWebsitePagesList,
      };
    }

    case actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_PADDING_BOTTOM: {
      // get the page components list // action.payload.color is the new BG color title. See componentsBackgroundColorsOptions.ts to view them all. // action.payload.currentPageIndex is the edited page index // action.payload.componentKey is the edited component index
      let oldComponents =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let newComponents: any = [...oldComponents];
      // get the component which componentData we want to edit
      let updatedComponentItem = newComponents[action.payload.componentKey];
      // edit the componentData
      // if the component settings object does not have the padding.top and padding.bottom, let's set it so we can access the properties without an JS exception
      if (updatedComponentItem.settings.padding === undefined) {
        updatedComponentItem.settings["padding"] = {
          top: undefined,
          bottom: undefined,
        };
      }
      updatedComponentItem.settings.padding.bottom =
        action.payload.new_padding_bottom_value;

      // save this component back to the components list
      newComponents[action.payload.componentKey] = updatedComponentItem;

      // clone the pages list
      let newWebsitePagesList = [...state.items];
      // get current page and save the updated components list of the current page
      newWebsitePagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = newComponents;

      return {
        ...state,
        items: newWebsitePagesList,
      };
    }

    case actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_VISIBILITY: {
      // get the page components list // action.payload.color is the new BG color title. See componentsBackgroundColorsOptions.ts to view them all. // action.payload.currentPageIndex is the edited page index // action.payload.componentKey is the edited component index
      let oldComponents =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let newComponents: any = [...oldComponents];
      // get the component which componentData we want to edit
      let updatedComponentItem = newComponents[action.payload.componentKey];
      // edit the componentData
      // if the component settings object does not have the visibility setting, let's set it so we can access the properties without an JS exception
      if (updatedComponentItem.settings.visibility === undefined) {
        updatedComponentItem.settings["visibility"] = {
          isHiddenOnDesktops: false,
          isHiddenOnTablets: false,
          isHiddenOnMobiles: false,
        };
      }

      if (action.payload.resolution === "desktop") {
        updatedComponentItem.settings.visibility.isHiddenOnDesktops =
          action.payload.isHidden;
      } else if (action.payload.resolution === "tablet") {
        updatedComponentItem.settings.visibility.isHiddenOnTablets =
          action.payload.isHidden;
      } else if (action.payload.resolution === "mobile") {
        updatedComponentItem.settings.visibility.isHiddenOnMobiles =
          action.payload.isHidden;
      }

      // save this component back to the components list
      newComponents[action.payload.componentKey] = updatedComponentItem;

      // clone the pages list
      let newWebsitePagesList = [...state.items];
      // get current page and save the updated components list of the current page
      newWebsitePagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = newComponents;

      return {
        ...state,
        items: newWebsitePagesList,
      };
    }

    case actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE:
      // action.payload.componentKey is the edited component index
      // action.payload.currentPageIndex is the edited page index
      // action.payload.imageUrl is the new BG image url.
      // action.payload.imageUUID is the new BG image UUID.

      // get the page components list
      let previousComponents =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let updatedComponents: any = [...previousComponents];
      // get the component which componentData we want to edit
      let updatedComponentSingleItem =
        updatedComponents[action.payload.componentKey];

      // edit the componentData
      updatedComponentSingleItem.settings.background.imageUrl =
        action.payload.imageUrl || "";
      updatedComponentSingleItem.settings.background.imageUUID =
        action.payload.imageUUID || undefined;

      // save this component back to the components list
      updatedComponents[action.payload.componentKey] =
        updatedComponentSingleItem;

      // clone the pages list
      let finalNewWebsitePagesList = [...state.items];
      // get current page and save the updated components list of the current page
      finalNewWebsitePagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = updatedComponents;

      return {
        ...state,
        items: finalNewWebsitePagesList,
      };

    case actionsNames.CHANGE_WEBSITE_PAGE_COMPONENT_BACKGROUND_IMAGE_OVERLAY_OPACITY: {
      // action.payload.componentKey is the edited component index
      // action.payload.currentPageIndex is the edited page index
      // action.payload.newBgImageOverlayOpacity is the new value.

      // get the page components list
      let previousComponents =
        state.items[action.payload.currentPageIndex].page_components.data
          .schema;

      // clone the page components list
      let updatedComponents: any = [...previousComponents];
      // get the component which componentData we want to edit
      let updatedComponentSingleItem =
        updatedComponents[action.payload.componentKey];

      // edit the componentData
      updatedComponentSingleItem.settings.background.overlayOpacity =
        action.payload.newBgImageOverlayOpacity;

      // save this component back to the components list
      updatedComponents[action.payload.componentKey] =
        updatedComponentSingleItem;

      // clone the pages list
      let finalNewWebsitePagesList = [...state.items];
      // get current page and save the updated components list of the current page
      finalNewWebsitePagesList[
        action.payload.currentPageIndex
      ].page_components.data.schema = updatedComponents;

      return {
        ...state,
        items: finalNewWebsitePagesList,
      };
    }

    case actionsNames.COMMON_WEBSITE_PAGE_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCommonWebsitePageUpdateResponse: action.payload,
      };

    case actionsNames.TOGGLE_WEBSITE_PAGE_NAV_HIDDEN: {
      // action.payload.currentPageIndex is the edited page index
      // action.payload.is_nav_hidden - new state of nav for this page

      // clone the pages list
      let finalNewWebsitePagesList = [...state.items];

      // get current page and save the change
      finalNewWebsitePagesList[action.payload.currentPageIndex].is_nav_hidden =
        action.payload.is_nav_hidden;

      return {
        ...state,
        items: finalNewWebsitePagesList,
      };
    }
    case actionsNames.TOGGLE_WEBSITE_PAGE_FOOTER_HIDDEN: {
      // action.payload.currentPageIndex is the edited page index
      // action.payload.is_footer_hidden - new state of footer for this page

      // clone the pages list
      let finalNewWebsitePagesList = [...state.items];

      // get current page and save the change
      finalNewWebsitePagesList[
        action.payload.currentPageIndex
      ].is_footer_hidden = action.payload.is_footer_hidden;

      return {
        ...state,
        items: finalNewWebsitePagesList,
      };
    }
    case actionsNames.TOGGLE_IS_PAGE_EMPTY: {
      const currentPages = [...state.items];
      const { pageId, newIsEmpty } = action.payload;
      const currentPage = _.find(currentPages, { id: pageId });
      _.set(currentPage as WebsitePagesItem, "is_empty", newIsEmpty); // Had to use type assertion here because typescript and lodash have some weird conflict
      return {
        ...state,
        items: currentPages,
      };
    }
    case actionsNames.SET_CUSTOM_BACKGROUND_COLOR: {
      const payload: SetCustomBackgroundColorPayload = action.payload;
      const { pageIndex, data, componentIndex } = payload;

      const updatedComponentsArray = [...state.items];
      const updatedComponent =
        updatedComponentsArray[pageIndex].page_components.data.schema[
          componentIndex
        ];

      updatedComponent.settings.background.customBackgroundColor = {
        ...updatedComponent.settings.background.customBackgroundColor,
        ...data,
      };

      return {
        ...state,
        items: updatedComponentsArray,
      };
    }

    case actionsNames.REPLACE_TEMPLATE_COMPONENTS_WITH_EMPTY_COMPONENTS: {
      const { websiteId, emptyPagesArray } =
        action.payload as ReplaceTemplateComponentsWithEmptyComponentsPayload;
      const allPagesArray = [...state.items];
      const currentWebsitePagesArray = allPagesArray.filter(
        (page) => page.website === websiteId
      );

      currentWebsitePagesArray.forEach((currentPageItem) => {
        const url = _.get(currentPageItem, "url");
        const emptyPageItem = _.find(emptyPagesArray, { url });

        if (!emptyPageItem) return currentPageItem;

        const itemComponentData = _.get(emptyPageItem, "page_components");
        _.set(currentPageItem, "page_components", itemComponentData);
      });

      return {
        ...state,
        items: allPagesArray,
      };
    }

    // Changes top-level non-readonly component data such as aiDescription
    case actionsNames.CHANGE_COMPONENT_GLOBAL_DATA: {
      const payload: ChangeComponentGlobalDataPayload = action.payload;
      const { pageId, componentId, data } = payload;

      const pagesArrayCopy = _.cloneDeep(state.items);
      const currentPage = pagesArrayCopy.find((page) => page.id === pageId);
      const currentComponent = currentPage.page_components.data.schema.find(
        (component) => component.id === componentId
      );
      Object.assign(currentComponent, data);

      return {
        ...state,
        items: pagesArrayCopy,
      };
    }

    case actionsNames.CHANGE_WEBSITE_PAGES_STATE_GLOBAL_DATA: {
      const payload: ChangeWebsitePagesStateGlobalDataPayload = action.payload;
      const { data } = payload;
      return {
        ...state,
        ...data,
      };
    }

    case actionsNames.CHANGE_PAGE_ITEM_GLOBAL_DATA: {
      const payload: ChangePageItemGlobalDataPayload = action.payload;
      const { pageId, data } = payload;

      const pagesArrayCopy = _.cloneDeep(state.items);
      const currentPage = pagesArrayCopy.find((page) => page.id === pageId);
      Object.assign(currentPage, data);

      return {
        ...state,
        items: pagesArrayCopy,
      };
    }

    case actionsNames.SWITCH_COMPONENT: {
      return switchComponentReducer(state, action);
    }

    case actionsNames.CHANGE_DIRECTORY_CONTENT: {
      const payload: ChangeDirectoryContentPayload = action.payload;
      const { pageId, componentId, field, value } = payload;

      const pagesArrayCopy = _.cloneDeep(state.items);
      const currentPage = pagesArrayCopy.find((page) => page.id === pageId);
      const currentComponent = currentPage.page_components.data.schema.find(
        (component) => component.id === componentId
      );
      _.set(
        currentComponent,
        ["componentData", "directory", "content", field],
        value
      );

      return {
        ...state,
        items: pagesArrayCopy,
      };
    }

    case actionsNames.EDIT_API_SOURCE_VALIDATION_DATA: {
      const payload: EditApiSourceValidationDataPayload = action.payload;
      const { pageId, newData } = payload;

      const pagesArrayCopy = _.cloneDeep(state.items);
      const currentPage = pagesArrayCopy.find((page) => page.id === pageId);
      currentPage.apiSourceValidationData = {
        ...currentPage.apiSourceValidationData,
        ...newData,
      };

      return {
        ...state,
        items: pagesArrayCopy,
      };
    }

    case actionsNames.CHANGE_COMPONENT_INTEGRATION_ID: {
      const payload: ChangeComponentIntegrationIdPayload = action.payload;
      const { componentId, pageId, integrationId } = payload;

      const pagesArrayCopy = _.cloneDeep(state.items);
      const currentPage = pagesArrayCopy.find((page) => page.id === pageId);
      const componentsArray = _.get(currentPage, "page_components.data.schema");
      if (!componentsArray) {
        return state;
      }
      const currentComponent = componentsArray.find(
        (component) => component.id === componentId
      );
      if (!currentComponent) {
        return state;
      }
      _.set(currentComponent, "componentData.cta.content.form.connectedIntegrationsIds", [integrationId]);

      return {
        ...state,
        items: pagesArrayCopy,
      };
    }

    default:
      return state;
  }
};
