import _ from "lodash";
import { checkIsLtdPlan } from "./checkIsLtdPlan";
import { checkIsProPlan } from "./checkIsProPlan";

export const isBillingHidden = (user: any, plans: any) => {
  const isUserPro = checkIsProPlan(user, plans);
  const isUserLtd = checkIsLtdPlan(user, plans);
  const paddleSubscriptionId = _.get(user, "data.paddle_subscription_id");

  // We also check for paddleSubscriptionId because users should be able to see their billing info after they cancel subscription.
  return (!isUserPro || isUserLtd) && !paddleSubscriptionId;
};
