import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type MovePageAsync = (payload: {
  accessToken: string;
  newWebsiteId: number;
  pageId: number;
  subdomain: string;
}) => Promise<AxiosResponse<any>>;

export const movePageAsync: MovePageAsync = ({
  accessToken,
  newWebsiteId,
  pageId,
  subdomain,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl =
      api.websitePages.moveWebsitePageToAnotherWebsite.prefix +
      subdomain +
      "/" +
      pageId +
      "/" +
      newWebsiteId +
      api.websitePages.moveWebsitePageToAnotherWebsite.postfix;

    axios
      .post(apiUrl, {}, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
