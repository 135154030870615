export const checkIfComponentHasBrightCustomColor = (currentWebsite:any) => {
    // If user sets the accent custom color too bright, we need to keep the text on it black

    let ifComponentHasBrightCustomColor = false;

    if(currentWebsite && currentWebsite.custom_color_palette){
        if(currentWebsite.custom_color_palette.isCustomPaletteActivated === true){
            if(currentWebsite.custom_color_palette.isAccentColorBright === true){
                ifComponentHasBrightCustomColor = true;
            }
        }
    }

    return (ifComponentHasBrightCustomColor);
};