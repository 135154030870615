import _ from "lodash";
import { pricingItemDetailIconOptions } from "../../../../data/pricingItemDetailIconOptions";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemText = stringifyObjectsAndNumbers(modifiedItem.TEXT) || "";
  const itemHoverInfo =
    stringifyObjectsAndNumbers(modifiedItem.HOVER_INFO) || "";
  let itemIcon = stringifyObjectsAndNumbers(modifiedItem.ICON);
  if (
    itemIcon !== pricingItemDetailIconOptions.greenTick &&
    itemIcon !== pricingItemDetailIconOptions.redCross
  ) {
    itemIcon = pricingItemDetailIconOptions.greenTick;
  }
  return {
    itemId,
    itemPosition,
    itemText,
    itemIcon,
    itemHoverInfo,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemText,
  itemIcon,
  itemHoverInfo
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.detailsSet.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.detailsSet, item);
      }
    });
  }
  if ("TEXT" in modifiedItem) {
    originalItem.title = itemText;
  }
  if ("ICON" in modifiedItem) {
    originalItem.icon = itemIcon;
  }
  if ("HOVER_INFO" in modifiedItem) {
    originalItem.hoverInfo = itemHoverInfo;
  }
};

export const updatePricingFeature = (componentData, completionContent) => {
  if (!("FEATURES" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.FEATURES)) {
    if (completionContent.FEATURES === null) {
      componentData.detailsSet = [];
    }
    return;
  }
  componentData.detailsSet = deleteEmptyItems(
    componentData.detailsSet,
    "title"
  );
  addDefaultValuesToItems(componentData.detailsSet);
  completionContent.FEATURES.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemText,
      itemIcon,
      itemHoverInfo,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      componentData.detailsSet = deleteItem(componentData.detailsSet, itemId);
      return;
    }
    const isItemNew = getIsItemNew(componentData.detailsSet, itemId);
    if (isItemNew) {
      const newItem = generator.createPricingItemDetailContentObject();
      newItem.title = "";
      addNewItem(componentData.detailsSet, newItem, itemId, itemPosition);
    }
    const originalItem = getOriginalItem(componentData.detailsSet, itemId);
    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemText,
      itemIcon,
      itemHoverInfo
    );
  });
  sortItemsByPosition(componentData.detailsSet);
};
