import React, { Component } from "react";
import "./CodexEditor.scss";
import { Tooltip, Icon } from "antd";

import EditorJS from "@editorjs/editorjs";
import edjsParser from "editorjs-parser";

// Editorjs.io tools, see all here https://github.com/editor-js/awesome-editorjs
import Embed from "@editorjs/embed";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import Code from "@editorjs/code";
import Image from "@editorjs/image";
import Raw from "@editorjs/raw";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Marker from "@editorjs/marker";
import CheckList from "@editorjs/checklist";
import Delimiter from "@editorjs/delimiter";
import InlineCode from "@editorjs/inline-code";
import Table from "editorjs-table";
import DragDrop from "editorjs-drag-drop";
import generateRandomNumerousId from "../../../helpers/generateRandomNumerousId";
import { api, publicUrl } from "../../../data/urls";
import { getSrcDict } from "../../../helpers/images/getSrcdict";
import { formatImageUrl } from "../../../helpers/strings/formatImageUrl";
import _ from "lodash";

type State = {
  currentBlogPost: any;
  isEditorReady: boolean;
};

interface Props {
  auth: any;
  currentBlogPost: any;
  currentPostIndex: number;
  currentPostUrl: string;
  currentWebsite: any;

  blogPosts: any;
  toggleNewBlogPostEditionsDetected: any;
  savePostBodyToStore: any;
  savePostBodyToServer: any;

  changesDetected: boolean;
  handleChangesDetected: any;

  focusOnCodexEditor: any;
}

class CodexEditor extends Component<Props, State> {
  editor: any = undefined;
  parser: any = undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      currentBlogPost: this.props.currentBlogPost,
      isEditorReady: false,
    };
  }

  initEditorJS = () => {
    var editor = new EditorJS({
      holder: "js-codex-editor",
      autofocus: false,
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
        },
        header: {
          class: Header,
          inlineToolbar: true,
          config: {
            placeholder: "Enter a header",
            levels: [2, 3, 4],
            defaultLevel: 2,
          },
        },
        list: {
          class: List,
          inlineToolbar: true,
        },
        marker: Marker as any,
        code: Code as any,
        raw: Raw as any,
        quote: {
          class: Quote,
          config: {
            quotePlaceholder: "Enter a quote",
            captionPlaceholder: "Quote's author",
          },
        },
        checklist: CheckList as any,
        delimiter: Delimiter as any,
        inlineCode: InlineCode as any,
        // table: Table as any,
        image: {
          class: Image,
          inlineToolbar: true,
          config: {
            captionPlaceholder: "Enter image caption",
            // uploader: {
            //   uploadByFile(file){
            //     return uploadImageByFile(file)
            //   },
            // },
            endpoints: {
              byFile: api.images.uploadImagesAsFile, // Your backend file uploader endpoint
            },
            types: "image/*", // https://github.com/codex-team/ajax#accept-string
          },
        },
        embed: Embed, // See all supported services here https://github.com/editor-js/embed/blob/master/docs/services.md
      },
      placeholder: `Type 'TAB' for commands`,
      data: this.props.currentBlogPost.body_json,
      inlineToolbar: true,
      logLevel: "ERROR" as any,
      onReady: () => {
        this.setState(
          {
            isEditorReady: true,
          },
          () => {
            new DragDrop(this.editor);
          }
        );
      },
      onChange: (api) => {
        this.handleChangePostData(api);
      },
    });

    return editor;
  };

  componentDidMount(): void {
    this.editor = this.initEditorJS();

    const customParsers = {
      checklist: (data, config) => {
        // parsing functionality
        // the config arg is user provided config merged with default config

        let items = ``;

        data.items.map((item, index) => {
          const randomNumber = generateRandomNumerousId();

          items =
            items +
            `
              <div class="checkbox">
                  <input type="checkbox" class="checkbox__input" id="${
                    index + randomNumber
                  }" ${item.checked && `checked`}>
                  <div class="checkbox__check">
                      <img class="checkbox__icon" src="${
                        publicUrl
                      }/img/icons/checked--fat--white.svg">
                  </div>
                  <label class="checkbox__label" for="${index + randomNumber}">
                      ${item.text}
                  </label>
              </div>
            `;
        });

        const checklist = `<div class="checklist">${items}</div>`;

        return checklist;
      },
      delimiter: (data, config) => {
        let element = `<div class="delimiter"></div>`;

        return element;
      },
      paragraph: (data, config) => {
        let textLinksWithTargetBlank = data.text.replace(
          /<a/g,
          `<a target="_blank" `
        );

        return `<p class="paragraph">${textLinksWithTargetBlank}</p>`;
      },
      image: (data, config) => {
        const imageObject = {
          url: formatImageUrl(data.file.url),
          width: data.file.width,
          height: data.file.height,
        };
        const srcdict = getSrcDict(imageObject, false, [
          290,
          null,
          320,
          345,
          null,
          375,
          395,
          null,
          425,
          738,
          null,
          768,
          1060,
          null,
          null,
        ]);

        let caption = "";
        let altImg = "";
        let urlArray = data.file.url.split("/");

        if (data.caption.length > 0) {
          caption = `<figcaption class="fig-cap">${data.caption}</figcaption>`;
          altImg = data.caption;

          // remove html tags from caption
          // via https://stackoverflow.com/questions/5002111/how-to-strip-html-tags-from-string-in-javascript
          let div = document.createElement("div");
          div.innerHTML = altImg;
          altImg = div.innerText;
        } else {
          altImg = urlArray[urlArray.length - 1];
          altImg = altImg.substr(0, altImg.lastIndexOf(".")); //name.jpg -> name
        }

        let imgSrcString = `src="${data.file.url}"`;

        if (
          this.props.currentWebsite.is_image_optimization_on &&
          srcdict.src &&
          srcdict.srcset &&
          srcdict.sizes
        ) {
          imgSrcString = `src="${srcdict.src}" 
srcset="${srcdict.srcset}"
sizes="${srcdict.sizes}"`;
        }

        let image = `
            <figure class="fig-img${
              data.withBackground === true ? " fig-img-bg" : ""
            }">
              <img 
                loading="lazy"
                class="img
                ${data.withBackground === true ? " img-bg" : ""} 
                ${data.withBorder === true ? "img-border" : ""}
                ${data.stretched === true ? "img-fullwidth" : ""}" 

                ${imgSrcString}

                alt="${altImg}"
              />
              ${caption}
            </figure>
          `;

        return image;
      },
    };

    this.parser = new edjsParser(undefined, customParsers, undefined);
  }

  componentDidUpdate(prevProps) {
    if (_.get(this.props, "currentBlogPost.id") !== _.get(prevProps, "currentBlogPost.id")) {
      this.editor.isReady.then(() => {
        this.editor.destroy();
        this.editor = this.initEditorJS();
      });
    }

    if (this.props.focusOnCodexEditor === true) {
      this.editor.caret.focus(false);
    }
  }

  componentWillUnmount(): void {
    if (this.state.isEditorReady === true) {
      this.editor.destroy();
    } else {
      this.editor = undefined;
    }
  }

  handleChangePostData = (api: any) => {
    this.props.toggleNewBlogPostEditionsDetected(true);
    this.props.handleChangesDetected(true);

    this.editor
      .save()
      .then((outputData) => {
        let bodyHtml = this.parser.parse(outputData);

        this.props.savePostBodyToStore(
          this.props.currentPostIndex,
          outputData,
          bodyHtml
        );

        this.props.savePostBodyToServer(
          this.props.currentWebsite.subdomain,
          this.props.currentPostUrl,
          this.props.currentBlogPost.editor_type,
          outputData,
          bodyHtml
        );
      })
      .catch((error) => {
        console.log("Saving failed: ", error);
      });
  };

  render() {
    let savingStatusJSX = null;
    if (
      this.props.blogPosts.isWaitingForSaveBlogPostSchemeResponse ||
      this.props.blogPosts.isWaitingForSaveBlogPostMainTitleSchemeResponse
    ) {
      savingStatusJSX = (
        <div className="codex-editor__saving-status">
          {" "}
          <Tooltip
            placement={"top"}
            title={
              "The content is being saved right now. Please do not close the window."
            }
          >
            <Icon style={{ color: "#1890ff" }} type="loading" />
          </Tooltip>
        </div>
      );
    } else if (
      this.props.changesDetected ||
      this.props.blogPosts.blogPostNewEditionsDetected ||
      this.props.blogPosts.blogPostMainTitleNewEditionsDetected
    ) {
      savingStatusJSX = (
        <div className="codex-editor__saving-status">
          {" "}
          <Tooltip
            placement={"top"}
            title={
              "New changes detected. We will autosave it in a few moments. Keep creating!"
            }
          >
            <Icon theme="twoTone" twoToneColor="#1890ff" type="clock-circle" />
          </Tooltip>{" "}
        </div>
      );
    } else {
      savingStatusJSX = (
        <div className="codex-editor__saving-status">
          {" "}
          <Tooltip
            placement={"top"}
            title={
              "Good. The changes have been successfully saved in our server."
            }
          >
            <Icon theme="twoTone" twoToneColor="#1890ff" type="check-circle" />
          </Tooltip>{" "}
        </div>
      );
    }

    return (
      <div className="codex-editor content_box content_box--blog blog-editor__content_box">
        <div id="js-codex-editor" />
        <div className="codex-editor__editor-toolbar">{savingStatusJSX}</div>
      </div>
    );
  }
}

export default CodexEditor;
