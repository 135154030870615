import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { GraphicsOptions } from "../../component_graphics/data/componentGraphicsData";
import ImagePicker from "../../ImagePicker";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  item: any;
  saveContentInStore: any;
  generator: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: (value: boolean) => void;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
}

const StepImageInput = (props: Props): JSX.Element => {
  const {
    item,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
    saveImageInfoInDB,
    removeImageFromDB,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  if (!currentInputDataRef.current.hasImage) return null;

  return (
    <div className="content-input__input">
      <ImagePicker
        currentImgAlt={item.graphics[GraphicsOptions.image].alt}
        saveImageAltInReduxStore={(newAlt: string) => {
          saveContentInStore(
            generator.changeStepImageAlt(
              {
                [contentType]: currentInputDataRef.current,
              },
              newAlt,
              keyProp
            )
          );

          toggleNewEditionsDetected(true);
        }}
        currentImgSrc={item.graphics[GraphicsOptions.image].url}
        currentImgUUID={item.graphics[GraphicsOptions.image].uuid}
        inputId={contentType}
        saveImageInfoInDB={saveImageInfoInDB}
        removeImageFromDB={removeImageFromDB}
        saveImageInReduxStore={(
          newSrc: string,
          newUUID: any,
          width: any,
          height: any
        ) => {
          saveContentInStore(
            generator.changeStepImageUrl(
              {
                [contentType]: currentInputDataRef.current,
              },
              newSrc,
              keyProp
            )
          );
          saveContentInStore(
            generator.changeStepImageUUID(
              {
                [contentType]: currentInputDataRef.current,
              },
              newUUID,
              keyProp
            )
          ); //'' for no image

          saveContentInStore(
            generator.changeStepImageWidth(
              {
                [contentType]: currentInputDataRef.current,
              },
              width,
              keyProp
            )
          );
          saveContentInStore(
            generator.changeStepImageHeight(
              {
                [contentType]: currentInputDataRef.current,
              },
              height,
              keyProp
            )
          );

          toggleNewEditionsDetected(true);
        }}
      />
    </div>
  );
};

export default StepImageInput;
