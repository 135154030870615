import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type CreateWebsiteAsync = (payload: {
  accessToken: string;
  subdomain: string;
  footer;
  nav;
  popups;
  templateWebsiteId: number;
  templateSubdomain: string;
}) => Promise<AxiosResponse<any>>;

export const createWebsiteAsync: CreateWebsiteAsync = ({
  accessToken,
  subdomain,
  footer,
  nav,
  popups,
  templateWebsiteId,
  templateSubdomain,
}) => {
  const isBlankWebsite = !templateSubdomain || !templateWebsiteId;
  return new Promise((resolve, reject) => {
    let apiUrl = api.websites.createWebsite;
    if (!isBlankWebsite) {
      apiUrl =
        api.websites.createWebsiteFromTemplate.prefix +
        templateWebsiteId +
        api.websites.createWebsiteFromTemplate.postfix;
    }
    axios
      .post(
        apiUrl,
        { subdomain, footer, nav, popups },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
