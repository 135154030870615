import React, { Component } from "react";
import { Button, Collapse, Icon, Switch } from "antd";
import { projectTitle } from '../../../../data/constants';
import { openChatWidget } from "../../../../helpers/editor/openChatWidget";

interface Props {

}
const { Panel } = Collapse;

class PlansFaq extends Component<Props>{
  state = {
    isChatLoading: false,
  };

  openChat() {
    this.setState({
      isChatLoading: true,
    });
    (window as any).$crisp.push(["on", "chat:opened", () => {
      this.setState({
        isChatLoading: false,
      });
    }]);

    openChatWidget();
  }

  render() {
    return (
      <div>
        <h2 style={{ textAlign: 'center', margin: '60px 0 20px' }}>Frequently Asked Questions</h2>
        <Collapse bordered={false} defaultActiveKey={['1']} style={{ width: 450, margin: 'auto' }}>
          <Panel header="Can I try Unicorn Platform before purchasing it?" key="1">
            You can play with {projectTitle} on our free-forever plan. You can create and publish one website. <br />Upgrade if you need more websites or if you want to connect a custom domain.
          </Panel>
          <Panel header="Can I cancel my account plan at any time?" key="2">
            You can cancel your subscription anytime by clicking a button. <br />If you are not satisfied with the service, you can ask for a full refund within 30 days.
          </Panel>
          <Panel header="What will happen if I cancel my subscription?" key="3">
            We will stop charging you immediately. <br />Also, we will calculate the remaining time of your subscription. After that date, we will unpublish all extra websites and remove attached custom domains if any.
          </Panel>
          <Panel header="If I change a plan, what will happen to the credits I already paid?" key="6">
            We will use the unspent credits to pay for your new plan. You will be charged less.
          </Panel>
          <Panel header="Can I host my website somewhere else?" key="4">
            All paid plans include the 'HTML export' feature. <br />You can download your website anytime.
          </Panel>
          <Panel header="What kind of support does Unicorn Platform provide?" key="5">
            We provide live chat human support for all users. <br /> Feel free to <Button
              size="small"
              onClick={() => { this.openChat() }}
              htmlType="button">ask us <Icon type={'message'} /></Button> anything or suggest an idea! We usually reply within 40 mins or faster.
          </Panel>
        </Collapse>

        <div style={{ textAlign: 'center', margin: '40px 0 0' }}>
          Did not find the answer? 🙂 

          <Button
          style={{marginLeft: 5}}
            size="small"
            onClick={() => { this.openChat() }}
            htmlType="button">chat with us!<Icon type={'message'} />
          </Button>
        </div>
      </div>
    )
  }
}

export default PlansFaq;
