type ImageObject = {
  [key: string]: number;
};

export const calculateDisplayDimensions = (
  imageObject: ImageObject,
  maxWidth: number,
  maxHeight: number,
  isCover: boolean = false,
  widthKey: string = "width",
  heightKey: string = "height"
): ImageObject => {
  let imageWidth: number = null;
  let imageHeight: number = null;

  if (widthKey in imageObject && heightKey in imageObject) {
    imageWidth = imageObject[widthKey];
    imageHeight = imageObject[heightKey];
  }

  if (
    !imageWidth ||
    !imageHeight ||
    typeof imageWidth !== "number" ||
    typeof imageHeight !== "number"
  ) {
    return {
      [widthKey]: null,
      [heightKey]: null,
    };
  }

  if (isCover) {
    imageHeight = Math.ceil((maxWidth / imageWidth) * imageHeight);
    imageWidth = maxWidth;
    if (imageHeight < maxHeight) {
      imageWidth = Math.ceil((maxHeight / imageHeight) * imageWidth);
      imageHeight = maxHeight;
    }
  } else {
    if (imageWidth > maxWidth) {
      imageHeight = Math.ceil((maxWidth / imageWidth) * imageHeight);
      imageWidth = maxWidth;
    }
    if (imageHeight > maxHeight) {
      imageWidth = Math.ceil((maxHeight / imageHeight) * imageWidth);
      imageHeight = maxHeight;
    }
  }

  return {
    [widthKey]: imageWidth,
    [heightKey]: imageHeight,
  };
};
