import { getPageArray } from "./getPageArray";
import json5 from "json5";
import { finishJson5 } from "./finishJson5";
import { SaveComponentDataToQueue } from "../../../store/gpt/types";

export const saveComponentDataToQueue: SaveComponentDataToQueue = ({
  newCompletionJson5,
  componentId,
  currentWebsitePage,
  currentWebsite,
  queueDataRef,
}) => {
  console.log('newCompletionJson5: ', newCompletionJson5);
  const pageArray = getPageArray(currentWebsitePage, currentWebsite);
  const component = pageArray.find((pageComponent) => {
    return pageComponent.id === componentId;
  });
  const queueItem = queueDataRef.current.find((queueItem) => {
    return queueItem.componentId === component.id;
  });
  if (!queueItem) return;

  const fullCompletion = newCompletionJson5.startsWith("{")
    ? newCompletionJson5
    : "//" + newCompletionJson5;

  queueItem.completionData = fullCompletion;
};
