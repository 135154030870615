import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    isDarkBg: boolean,
}

class CtaButton25 extends Component<Props>{

    generator = new generateContentDataObject();
    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: 'Get started with Unicorn Platform',
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({
              isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpCta({
                activeOption: callToActionOptions.buttons,
                isDefaultEmpty
            }));

            this.props.saveContentInStore(this.generator.setUpFeatures({
                maxItems: 16,

                hasSubtitle: true,
                hasText: false,
                hasPictogram: false,
                hasHighlight: false,
                hasHighlightInfo: false,
                hasButton: false,

                items: [
                    this.generator.createFeatureContentObject({
                        title: 'Powerful Builder',
                        subtitle: 'Make a Website Quickly',
                    }),
                    this.generator.createFeatureContentObject({
                        title: 'Custom Animations',
                        subtitle: 'Woven-Hand Interactions',
                    }),
                    this.generator.createFeatureContentObject({
                        title: 'Slick Design',
                        subtitle: 'Hand Crafted Components',
                    }),


                    // The last ghost item which is used to add new items.
                    this.generator.createFeatureContentObject({
                        title: '',
                        subtitle: ''
                    }),
                ],
                isDefaultEmpty

            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);
        let features = this.props.componentItem.componentData.features.content.items;
        return (
            <section>
                <div className="cta_button-25">
                    <div className="cta_button-25__container container container--mid">
                        <div className="cta_button-25__title">
                            <TitleBox 
                              title={title}
                              subtitleText={subtitleText}
                              isDarkBg={this.props.isDarkBg}
                            />
                        </div>
                    </div>

                    <div className="cta_button-25__button_wrapper">
                        <WebsiteCTA componentItem={this.props.componentItem} />
                    </div>
                    <ul className="cta_button-25__features">
                        {features && features.map((item:any, key:number) => {
                            if(item.title && item.title !== ''){
                                return (
                                    <li className="cta_button-25__item" key={'cta-features-25-' + key}>
                                        <h3 className={"title-text--inner cta_button-25__top_title def-18" + (this.props.isDarkBg ? 'text-white' : '')}>{item.title}</h3>
                                        <h4 className={"content-text def-14 cta_button-25__content " + (this.props.isDarkBg ? 'text-white' : '')}>{item.subtitle}</h4>
                                    </li>
                                )
                            }else{
                                return (null);
                            }
                        })}
                    </ul>
                </div>
            </section>
        )
    }
}

export default CtaButton25;
