import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { GraphicsOptions } from "../data/componentGraphicsData";

export const setGraphicsActiveOption = (
  currentInputObject: any,
  currentContentType: ContentInputsTypesEnum,
  newActiveOption: GraphicsOptions
) => {
  const newInputObject = { ...currentInputObject };
  newInputObject[currentContentType].activeOption = newActiveOption;
  return currentInputObject;
};
