import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import ReactEmbedGist from 'react-embed-gist';
import { displayMarkupText } from "../../helpers/content_displayers/displayMarkupText";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}


class Code01 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "We love JavaScript"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: ''}));
            this.props.saveContentInStore(this.generator.setUpCode(
                {
                    fieldTitle: {default: "Code snippet"},
                    label: {default: "Enter the GitHub Gist URL:"},
                    labelTooltip: {default: "Create a public GitHub Gist and paste its URL here. Example.: https://gist.github.com/alexanderisora/e5eee4d915e13fa53dc8779316e41bcf"},
                    hasGistUrl: true,
                    gistUrl: 'https://gist.github.com/alexanderisora/e5eee4d915e13fa53dc8779316e41bcf',
                    isDefaultEmpty
                }
            ));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        buttonColor: buttonStyles.ghost.accentOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'View'
                    })
                ],
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts

        // In this Text component there is only one text element.
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        /*
        * gistUrl looks like this: https://gist.github.com/alexanderisora/e5eee4d915e13fa53dc8779316e41bcf
        * we need this: alexanderisora/e5eee4d915e13fa53dc8779316e41bcf (see https://github.com/msaracevic/react-embed-gist)
        * */
        let gistUrl = displayMarkupText(this.props.componentItem.componentData.code.content.gistUrl);

        let gistParam = gistUrl.replace('https://gist.github.com/', '');

        if(gistUrl.indexOf('https://gist.github.com') === -1){
            gistParam = 'alexanderisora/e5eee4d915e13fa53dc8779316e41bcf';
        }
        if(gistParam === ''){
            gistParam = 'alexanderisora/e5eee4d915e13fa53dc8779316e41bcf';
        }

        if(gistParam.indexOf('/') === -1){
            gistParam = 'alexanderisora/e5eee4d915e13fa53dc8779316e41bcf';
        }

        if(gistParam.split('/').length !== 2){
            gistParam = 'alexanderisora/e5eee4d915e13fa53dc8779316e41bcf';
        }

        if(gistParam.split('/')[0] === ''){
            gistParam = 'alexanderisora/e5eee4d915e13fa53dc8779316e41bcf';
        }
        if(gistParam.split('/')[1] === ''){
            gistParam = 'alexanderisora/e5eee4d915e13fa53dc8779316e41bcf';
        }

        return (
            <section>
                <div className="code-01">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="code-01__container container container--mid js-github-gist-container">
                        <ReactEmbedGist
                            gist={gistParam}
                            // wrapperClass="gist__bash"
                            loadingClass="loading__screen"
                            titleClass="gist__title"
                            // file=".bash_profile.sh"
                        />

                    </div>
                    <div className="container">
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Code01;
