import React from "react";
import _ from "lodash";
import DebouncedTextarea from "../../../DebouncedTextarea";
import { GraphicsOptions } from "../../component_graphics/data/componentGraphicsData";
import DeleteButton from "./DeleteButton";
import classNames from "classnames";
import { Components } from "../../../../enums/Components";
import { graphicsWithOneItem } from "../../../../data/componentExceptions";

interface Props {
  content: any;
  currentInputData: any;
  lastItem: any;
  toggleNewEditionsDetected: any;
  displayMoveUpDownButtons: (
    index: number,
    itemsCount: number,
    moveUp: any,
    moveDown: any
  ) => JSX.Element;
  saveContentInStore: (payload: any) => void;
  generator: any;
  contentType: any;
  componentType: string;
  componentTitle: string;
}

const GraphicsHTMLInput = (props: Props): JSX.Element => {
  const {
    content,
    currentInputData,
    lastItem,
    displayMoveUpDownButtons,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    componentType,
    componentTitle,
  } = props;
  const pathToValue = `[${GraphicsOptions.HTML}].codeSnippet`;

  const graphicsInputs = content.items.map((item: any, key: number) => {
    const componentName = `${componentType}-${componentTitle}` as Components;
    if (graphicsWithOneItem.includes(componentName) && key > 0) {
      return null;
    }
    const handleOnChange = (value: string) => {
      saveContentInStore(
        generator.changeGraphicsCodeSnippet(
          { [contentType]: currentInputData },
          value,
          key
        )
      );
      toggleNewEditionsDetected(true);
    };

    const codeInputs = (
      <DebouncedTextarea
        className="content-input__input content-input__input--monospace"
        placeholder={"Enter any HTML code. Examples: <iframe> <script> etc."}
        autosize={{ minRows: 2, maxRows: 20 }}
        onChange={handleOnChange}
        defaultValue={_.get(content, `items[${key}]${pathToValue}`)}
      />
    );

    if (!currentInputData.userCanAddItems) {
      return (
        <div className="content-input__input" key={`graphics-item-${key}`}>
          {codeInputs}
        </div>
      );
    }

    const maxItemsReached = !!_.get(lastItem, pathToValue);
    let itemsCount = maxItemsReached
      ? content.items.length
      : content.items.length - 1;

    return (
      <div
        className={classNames(
          "content-input__cluster",
          "content-input__cluster--small",
          {
            "content-input__cluster--with-ghost": !maxItemsReached,
          }
        )}
        data-add-new="Add new video ⤵"
        key={`video-fields-${key}`}
      >
        {
          <DeleteButton
            keyProp={key}
            toggleNewEditionsDetected={toggleNewEditionsDetected}
            saveContentInStore={saveContentInStore}
            generator={generator}
            contentType={contentType}
            currentInputData={currentInputData}
          />
        }
        {displayMoveUpDownButtons(
          key,
          itemsCount,
          () => {
            saveContentInStore(
              generator.moveGraphicsUp(
                {
                  [contentType]: currentInputData,
                },
                key
              )
            );
          },
          () => {
            saveContentInStore(
              generator.moveGraphicsDown(
                {
                  [contentType]: currentInputData,
                },
                key
              )
            );
          }
        )}
        {codeInputs}
      </div>
    );
  });

  return graphicsInputs;
};

export default GraphicsHTMLInput;
