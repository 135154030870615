import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import Slider from "react-slick";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { DEFAULT_IMAGES_CDN, publicUrl } from "../../data/urls";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Testimonials05 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({ isDefaultEmpty, titleText: "" })
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({ isDefaultEmpty, subtitleText: "" })
      );
      this.props.saveContentInStore(
        this.generator.setUpPosts({
          isDefaultEmpty,
          maxItems: 1,
          hasTitle: true,
          hasThumb: true,
          hasText: true,
          hasLogo: false,
          hasButton: true,
          fieldTitle: { default: "Testimonial" },
          label: { default: "Edit the testimonial:" },
          labelTooltip: {
            default: "A superb testimonial from the most loyal customer.",
          },
          buttonTypes: [buttonStyles.ghost.typeTitle],

          items: [
            this.generator.createPostsContentObject({
              title: "Maria Jameson",
              subtitle: "CEO @ ACNE Inc",
              text:
                "Nam porttitor blandit accenim in dolor euismod, id commodo mi consectetur. Curabitu nisi. Nullam vehicula nisi velit. Mauris turpis nisl, molestie ut ipsum et, suscipit vehictie.",
              thumb: this.generator.createGraphicsContentObject({
                imageUrl: DEFAULT_IMAGES_CDN.PERSON_15,
                width: 400,
                height: 400,
              }),
              button: this.generator.createButtonContentObject({
                buttonType: linkOrButtonOptions.link,
                buttonTitle: "",
                buttonHref: "",
                buttonStyleType: buttonStyles.ghost.typeTitle,
                ghostColor: buttonStyles.ghost.accentOutline,
                buttonIsTargetBlank: true,
              }),
            }),
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a button to the bottom of the testimonial component to link to more testimonials.",
            default:
              "Add a button to the bottom of the testimonial component to link to more testimonials.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "Read more",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let post = this.props.componentItem.componentData.posts.content.items[0];

    return (
      <section>
        <div className="testimonials-05">
          <TitleBox
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />
          <div className="container container--small testimonials-05__container">
            <img
              className="testimonials-05__emoji"
              src={publicUrl + "/img/other/emoji_group.svg"}
              alt="A group of positive emoji."
            />
            {post && (
              <div className="testimonials-05__card">
                <div className="testimonials-05__box">
                  <div className="testimonials-05__cloud">
                    <div className="content_box ">
                      <div
                        className="content-text testimonials-05__text"
                        dangerouslySetInnerHTML={{ __html: post.text }}
                      />
                    </div>
                    {post.button &&
                      post.button.title !== "" &&
                      post.button.href !== "" && (
                        <div className="feature__button_box">
                          <WebsiteButton
                            config={{
                              buttonStyleType:
                                post.button.settings.appearance.styleType,
                              buttonColor:
                                post.button.settings.appearance.color,
                              buttonType: linkOrButtonOptions.link,
                              buttonTitle: post.button.title,
                              buttonHref: post.button.href,
                              buttonIsTargetBlank: post.button.isTargetBlank,
                              buttonMobileAppStoreType:
                                post.button.settings.appearance
                                  .mobileAppStoreType,
                              pillPrefix: post.button.pillPrefix,
                              pillColor:
                                post.button.settings.appearance.pillColor,
                              ghostColor:
                                post.button.settings.appearance.ghostColor,
                            }}
                          />
                        </div>
                      )}
                  </div>
                </div>
                <div className="testimonials-05__person_box">
                  <div
                    className="testimonials-05__person_image"
                    style={{
                      backgroundImage: "url(" + formatImageUrl(post.thumb.image.url) + ")",
                    }}
                  />
                  <div className="testimonials-05__person_info">
                    <h3
                      className={
                        "def-16 title-text--inner testimonials-05__name " +
                        (this.props.isDarkBg ? "text-white" : "")
                      }
                      dangerouslySetInnerHTML={{ __html: post.title }}
                    />
                    <div
                      className={
                        "content-text def-12 testimonials-05__occupation " +
                        (this.props.isDarkBg ? "text-white" : "")
                      }
                      dangerouslySetInnerHTML={{ __html: post.subtitle }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="bottom_cta">
            {this.props.componentItem.componentData.cta && (
              <WebsiteCTA componentItem={this.props.componentItem} />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Testimonials05;
