export const slugifyNew = (str: string) => {
  return window.slugify(
    (str || "")
      .toString()
      .replace(/[!@#$%^&*(),.?":{}|<>`\\/=+_;'\[\]~]/g, "-"),
    {
      lower: true,
    }
  );
};
