import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import VideoElement from "../VideoElement";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteFeature from "../component_molecules/WebsiteFeature";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}

class Video03 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpGraphics({
                activeOption: GraphicsOptions.video,
                userCanAddItems: false,
                maxItems: 1,
                items: [
                    this.generator.createGraphicsContentObject({
                        videoUrl: "https://www.youtube.com/watch?v=J2U9Hmmpqhc",
                    })
                ]
            }));
            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));

            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                maxItems: 6,

                items: [
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Numerous Integrations',
                        }
                    ),
                    this.generator.createFeatureContentObject({title: 'Custom code'}),

                    // The last ghost item which is used to add new items.
                    this.generator.createFeatureContentObject({
                        title: '',
                        text: ''
                    })],

            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the video component to offer more videos to see.",
                    default: "Add a CTA (call to action) to the bottom of the video component to offer more videos to see.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'see also'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let youtubeVideoUrl = this.props.componentItem.componentData.graphics.content.items[0][GraphicsOptions.video].youtube.url; // This component has exactly one item and more can not be added (see createGraphicsContentObject() above).
        let features = this.props.componentItem.componentData.features.content.items;

        return (
            <section>
                <div className="video-03">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container">
                        <div className="video-03__wrapper">
                              <VideoElement 
                                videoUrl={youtubeVideoUrl}
                                isDarkBg={this.props.isDarkBg}
                                defaultGraphicsOption={GraphicsOptions.video}
                                className="video-03__item"
                              />
                            <div className="video-03__text_box">
                                {features && features.map((item:any, key:number) => {

                                    if(item.title && item.title !== ''){
                                        return (
                                            <WebsiteFeature featureObject={item} isWhite={this.props.isDarkBg} key={key}/>
                                        )
                                    }else{
                                        return (null);
                                    }

                                })}
                            </div>

                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default Video03;
