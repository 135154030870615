import React from "react";
import { InputProps } from "./interfaces";
import MockupsContentInput from "../MockupsContentInput";

const Mockups = (props: InputProps): JSX.Element => {
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    componentType,
    componentTitle,
  } = props;

  return (
    <div>
      <MockupsContentInput
        saveContentInStore={saveContentInStore}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        currentInputData={currentInputData}
        generator={generator}
        contentType={contentType}
        content={content}
        componentName={[componentType, componentTitle].join("")}
      />
    </div>
  );
};

export default Mockups;
