import _ from "lodash";
import { updateComponent } from "../../../components/editor/ai2/componentUpdaters/updateComponent";
import { getPageArray } from "../../../components/editor/ai2/getPageArray";
import {
  changeWebsitePageComponentContent,
  toggleNewWebsitePageEditionsDetected,
} from "../../websitePages/actions";
import {
  saveWebsiteFooterInState,
  saveWebsiteNavInState,
  toggleWebsiteFooterEditionsDetected,
  toggleWebsiteNavEditionsDetected,
} from "../../websites/actions";
import { editChatMessage, editInputData } from "../actions";
import { Chat, UpdateComponentData } from "../types";
import { getCurrentChatData } from "./getCurrentChatData";
import { getCurrentInputData } from "./getCurrentInputData";

export const updateComponentData: UpdateComponentData = ({
  currentWebsitePage,
  currentWebsite,
  componentId,
  queueDataRef,
  currentPageIndex,
}) => {
  return (dispatch) => {
    const pageArray = getPageArray(currentWebsitePage, currentWebsite);
    const component = pageArray.find((pageComponent) => {
      return pageComponent.id === componentId;
    });
    const queueItem = queueDataRef.current.find(
      (item) => item.componentId === componentId
    );
    const { completionData, parsedCompletionData, reasoning } = queueItem;
    const skipNav = _.get(
      currentWebsite,
      ["fillTemplateData", "skipNav"],
      false
    );
    const skipFooter = _.get(
      currentWebsite,
      ["fillTemplateData", "skipFooter"],
      false
    );
    const fillTemplateStatus = _.get(
      currentWebsite,
      ["fillTemplateData", "status"],
      ""
    );

    const currentInput = dispatch(getCurrentInputData(componentId));
    if (currentInput) {
      if (currentInput.reasoning !== reasoning) {
        dispatch(
          editInputData({
            componentId,
            newData: {
              reasoning,
            },
          })
        );
      }
    }

    // console.log(
    //   "%c%s%c %s",
    //   "color: #ffec78;",
    //   componentId,
    //   "",
    //   completionData
    // );

    if (!parsedCompletionData) {
      return;
    }
    let updatedComponentData = component.componentData;
    try {
      updatedComponentData = updateComponent(component, parsedCompletionData);
    } catch (e) {
      console.error(e);
    }
    const componentIndex = pageArray.indexOf(component) - 1;
    if (component.id === "navigation" && (!skipNav || fillTemplateStatus !== "inProgress")) {
      dispatch(
        saveWebsiteNavInState({
          navObject: {
            ...currentWebsite.nav,
            componentData: updatedComponentData,
          },
          currentWebsiteSubdomain: currentWebsite.subdomain,
        })
      );
      dispatch(toggleWebsiteNavEditionsDetected(true));
    }
    if (component.id === "footer" && (!skipFooter || fillTemplateStatus !== "inProgress")) {
      dispatch(
        saveWebsiteFooterInState({
          footerObject: {
            ...currentWebsite.footer,
            componentData: updatedComponentData,
          },
          currentWebsiteSubdomain: currentWebsite.subdomain,
        })
      );
      dispatch(toggleWebsiteFooterEditionsDetected(true));
    }
    if (component.id !== "navigation" && component.id !== "footer") {
      dispatch(
        changeWebsitePageComponentContent({
          currentPageIndex,
          componentKey: componentIndex,
          componentData: updatedComponentData,
        })
      );
      dispatch(toggleNewWebsitePageEditionsDetected(true));
    }
    const customHTMLComponents = document.querySelectorAll(
      ".page-component__gpt-loading-wrapper--visible .other-01__wrapper"
    );
    if (customHTMLComponents.length > 0) {
      customHTMLComponents.forEach((customHTMLComponent) => {
        customHTMLComponent.scrollTop = customHTMLComponent.scrollHeight;
      });
    }
  };
};
