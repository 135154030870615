import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {companyLogos, pressLogos, publicUrl} from "../../data/urls";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteLogo from "../component_molecules/WebsiteLogo";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Clients03 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "We Helped Many Companies to Shine"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: ''}));
            this.props.saveContentInStore(this.generator.setUpLogos({
              isDefaultEmpty,
                maxItems: 40,
                labelTooltip: {default: "The logos are forced to black & white. But colorful logos will look better because the colors are revealed on a cursor hover."},
                items: [
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'facebook.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'ibm.svg',
                        height: 20,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'dribbble.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'microsoft.svg',
                        height: 20,
                    }),

                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'adobe.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'invision.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'apple.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'virgin.svg',
                        height: 40,
                    }),
                ]

            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the clients component to offer to learn more cases.",
                    default: "Add a CTA (call to action) to the bottom of the clients component to offer to learn more cases.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'See also'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let logos = this.props.componentItem.componentData.logos.content.items;

        return (
            <section>

                <div className="clients-03">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container container--mid">
                        <ul className="clients-03__list">
                            {logos && logos.map((item:any, key:number) => {
                                let logoItemContent;

                                if(item.href && item.href !== ''){
                                    logoItemContent =
                                        (<a className="clients-03__link">
                                            <WebsiteLogo 
                                              logoObject={item}
                                              className='clients-03__logo'
                                            />
                                        </a>)
                                }else{
                                    logoItemContent =
                                        (<span className="clients-03__img_box">
                                            <WebsiteLogo 
                                              logoObject={item}
                                              className='clients-03__logo'
                                            />
                                        </span>)
                                }

                                if(item.uploadedSrc && item.uploadedSrc !== ''){
                                    return (
                                        <li key={"clients-03-" + key} className="clients-03__item">
                                            {logoItemContent}
                                        </li>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </ul>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

export default Clients03;
