import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import _ from "lodash";
import ContentInputsTypesEnum from "../../enums/ContentInputsTypesEnum";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import { checkIfSlideVisible } from "../../helpers/checkIfSlideVisible";
import GraphicsJSX from "../GraphicsJSX";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteFeature from "../component_molecules/WebsiteFeature";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Slider07 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({ titleText: "",isDefaultEmpty })
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({ subtitleText: "",isDefaultEmpty })
      );
      this.props.saveContentInStore(
        this.generator.setUpGallery({
          maxItems: 99,
          fieldInfo: { default: "Recommended format: 1440x900px" },
          isDefaultEmpty
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpFeatures({
          isDefaultEmpty,
          maxItems: 6,
          priority: 300,
          items: [
            this.generator.createFeatureContentObject({
              title: "Powerful Builder",
            }),
            this.generator.createFeatureContentObject({
              title: "Enjoy Integrations",
              button: this.generator.createButtonContentObject({
                buttonType: linkOrButtonOptions.link,
                buttonTitle: "More features",
                buttonHref: "/",
                buttonStyleType: buttonStyles.ghost.typeTitle,
                ghostColor: buttonStyles.ghost.accentOutline,
              }),
            }),
            this.generator.createFeatureContentObject({ title: "" }),
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a button to the bottom of the component to invite users to read more about the project.",
            default:
              "Add a button to the bottom of the component to invite users to read more about the project.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "Learn here",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  componentDidMount(): void {}

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let gallery = this.props.componentItem.componentData.gallery.content.items;
    let features = this.props.componentItem.componentData.features.content
      .items;

    let settings = {
      dots: false,
      infinite: true,
      speed: 300,
      fade: true,
      cssEase: "ease-out",
      adaptiveHeight: true,
      centerMode: true,
      variableWidth: false,
      slidesToShow: 1,
      // 'lazyLoad': 'ondemand',
      focusOnSelect: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            autoplay: true,
            autoplaySpeed: 2000,
          },
        },
      ],
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    const currentActiveOption = _.get(
      this.props.componentItem,
      ["componentData", ContentInputsTypesEnum.gallery, "activeOption"],
      GraphicsOptions.image
    );

    return (
      <section>
        <div
          className={`slider-07 editor-slider graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image}`}
        >
          <TitleBox 
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />

          <div className="container container--max slider-07__wrapper">
            {features && features.length > 0 && features[0].title !== "" && (
              <ul className="slider-07__feature_container">
                {features.map((item: any, key: number) => {
                  return (
                    <li
                      className="slider-07__feature"
                      key={"slider-feature-07-" + key}
                    >
                      <WebsiteFeature featureObject={item} isWhite={this.props.isDarkBg} />
                    </li>
                  );
                })}
              </ul>
            )}

            <div className="slider-07__slider_wrapper">
              <div className="slider slider-07__slider">
                <Slider {...settings}>
                  {gallery &&
                    gallery.map((item: any, key: number) => {
                      const videoUrl = _.get(item, [
                        "contentGraphic",
                        0,
                        GraphicsOptions.video,
                        "youtube",
                        "url",
                      ]);
                      const codeSnippet = _.get(item, [
                        "contentGraphic",
                        0,
                        GraphicsOptions.HTML,
                        "codeSnippet",
                      ]);

                      if (checkIfSlideVisible(item, currentActiveOption)) {
                        return (
                          <li
                            className="slider-07__item slider__item slider-07__item_editor"
                            key={"slider-07-item-" + key}
                          >
                            <GraphicsJSX
                              currentActiveOption={currentActiveOption}
                              defaultGraphicsOption={GraphicsOptions.image}
                              classNames={{
                                image: `js-lightbox-single-image slider-07__img`,
                                video: "slider-07__video",
                                HTML: "slider-07__HTML slider-07__editor-HTML",
                              }}
                              isDarkBg={this.props.isDarkBg}
                              imageUrl={item.contentGraphic[0].image.url}
                              videoUrl={videoUrl}
                              codeSnippet={codeSnippet}
                              permanentSpinnerColor
                              imageDataWidth={
                                item.contentGraphic[0].image.width || 1440
                              }
                              imageDataHeight={
                                item.contentGraphic[0].image.height || 900
                              }
                            />
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Slider>
              </div>
            </div>
          </div>

          <div className="bottom_cta">
            {this.props.componentItem.componentData.cta &&
              <WebsiteCTA componentItem={this.props.componentItem} />}
          </div>
        </div>
      </section>
    );
  }
}

export default Slider07;
