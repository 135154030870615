export function uppercaseKeys(json) {
  if (typeof json !== "object" || json === null) {
    return json;
  }

  if (Array.isArray(json)) {
    return json.map(uppercaseKeys);
  }

  const uppercasedJson = {};
  for (const key in json) {
    const uppercasedKey = key.toUpperCase();
    uppercasedJson[uppercasedKey] = uppercaseKeys(json[key]);
  }

  return uppercasedJson;
}
