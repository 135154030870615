export const addNewItem = (
  itemsArray: any[],
  newItem,
  itemId,
  itemPosition
) => {
  newItem.IS_BEING_MODIFIED_BY_AI = true;
  newItem.READONLY_ID = itemId;
  newItem.POSITION_IN_ARRAY = itemPosition;
  itemsArray.push(newItem);
};
