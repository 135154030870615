import React from "react";
import { Button, Icon } from "antd";
import { CrispEvents } from "../../../../enums/AnalyticsEventsEnums";
import { getCurrentBlog } from "../../../../helpers/blog/getCurrentBlog";
import fireAnalyticsEvent from "../../../../helpers/editor/fireAnalyticsEvent";
import generateRandomNumerousId from "../../../../helpers/generateRandomNumerousId";
import { getCurrentWebsitePageUrlForExport } from "../../../../helpers/html_export/getCurrentWebsitePageUrlForExport";
import PageTitle from "./PageTitle";

interface Props {
  blogs: any;
  currentWebsite: any;
  websitesPages: any;
}
// display blog home page if exist
const BlogHomePageDownloadButton = (props: Props): JSX.Element => {
  const { blogs, currentWebsite, websitesPages } = props;
  const blogObject = getCurrentBlog(blogs.items, currentWebsite);

  if (blogObject !== undefined) {
    let item = blogObject;
    let currentWebsitePageUrl = getCurrentWebsitePageUrlForExport(
      currentWebsite,
      item.url
    );
    return (
      <>
        <br />
        <li className="html-export__button_item">
          <Button
            onClick={() => {
              fireAnalyticsEvent.fireCrisp(CrispEvents.exportWebsitePage, {
                subdomain: currentWebsite.subdomain,
                page_url: item.url,
              });
            }}
            type={"primary"}
            icon={"download"}
            size="small"
            // target={"_blank"}
            href={
              currentWebsitePageUrl +
              "?nocache=" +
              generateRandomNumerousId() +
              "&as_file=true&file_name=" +
              (item.url === "" ? "index" : item.url)
            }
            title={"Download the .HTML file."}
            loading={false}
          />
          <div className="html-export__page_title">
            <div className="html-export__page_title_text">
              {" "}
              {<PageTitle item={item} />}
              <a
                hidden={websitesPages.pageChangeInProgress}
                title="View the blog home in a new tab."
                href={currentWebsitePageUrl}
                target="_blank"
                className="html-export__link"
              >
                <Icon type="link" />
              </a>
            </div>
          </div>
        </li>
      </>
    );
  }
  
  return null;
};

export default BlogHomePageDownloadButton;
