export const getWebsiteProtocol = (website: any):string|undefined => {
    // returns 'http://' (if no SSL yet) or 'https://' (if SSL certificate was installed (or Cloudflare was chosen)).
    // returns undefined if website is undefined
    if(website === undefined) {
        return undefined
    }else{
        if(website['is_ssl_issued']){
            return 'https://';
        }else{
            return 'http://';
        }
    }
};