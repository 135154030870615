import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import GalleryGraphicsOptions from "./GalleryGraphicsOptions";
import GallerySliderDescription from "./GallerySliderDescription";
import GallerySliderSubtitle from "./GallerySliderSubtitle";
import GallerySliderTitle from "./GallerySliderTitle";

interface Props {
  item: any;
  saveContentInStore: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: (value: boolean) => void;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  textEditorType: string;
  revertToPlainHTML: () => void;
  state: any;
}

const GallerySliderField = (props: Props): JSX.Element => {
  const {
    item,
    saveContentInStore,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
    saveImageInfoInDB,
    removeImageFromDB,
    textEditorType,
    revertToPlainHTML,
    state,
  } = props;

  return (
    <div className="content-input__group">
      <GalleryGraphicsOptions
        item={item}
        saveContentInStore={saveContentInStore}
        contentType={contentType}
        currentInputData={currentInputData}
        keyProp={keyProp}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        saveImageInfoInDB={saveImageInfoInDB}
        removeImageFromDB={removeImageFromDB}
        state={state}
      />
      <GallerySliderTitle
        item={item}
        saveContentInStore={saveContentInStore}
        contentType={contentType}
        currentInputData={currentInputData}
        keyProp={keyProp}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
      />
      <GallerySliderSubtitle
        item={item}
        saveContentInStore={saveContentInStore}
        contentType={contentType}
        currentInputData={currentInputData}
        keyProp={keyProp}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
      />
      <GallerySliderDescription
        textEditorType={textEditorType}
        revertToPlainHTML={revertToPlainHTML}
        item={item}
        saveContentInStore={saveContentInStore}
        contentType={contentType}
        currentInputData={currentInputData}
        keyProp={keyProp}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
      />
    </div>
  );
};

export default GallerySliderField;
