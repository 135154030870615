import { Button } from "antd";
import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  keyProp: number;
  saveContentInStore: any;
  generator: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  toggleNewEditionsDetected: (value: boolean) => void;
}

const DeleteStepButton = (props: Props): JSX.Element => {
  const {
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const handleDeleteStep = () => {
    toggleNewEditionsDetected(true);
    saveContentInStore(
      generator.deleteStep(
        { [contentType]: currentInputDataRef.current },
        keyProp
      )
    );
  };

  return (
    <Button
      onClick={handleDeleteStep}
      htmlType="button"
      className="content-input__control_cluster"
      shape="circle"
      title="Delete this step"
      icon="cross"
      size="small"
    />
  );
};

export default DeleteStepButton;
