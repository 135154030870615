export const createUniqueFormFieldName = (
  fieldName: string,
  fields: any[],
  i = 1
): string => {
  const newName = i === 1 ? fieldName : `${fieldName}${i}`;
  if (fields.some((field) => field.name === newName)) {
    return createUniqueFormFieldName(fieldName, fields, i + 1);
  }
  return newName;
};
