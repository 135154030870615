import { stringifyObjectsAndNumbers } from "./../stringifyObjectsAndNumbers";
import { isKeyValue } from "../../../../helpers/isKeyValue";

export const updateCode = (componentData, completionContent) => {
  const content = componentData.code.content;
  if ("HTML_CODE" in completionContent) {
    content.codeSnippet =
      stringifyObjectsAndNumbers(completionContent.HTML_CODE) || "";
    return;
  }
  if (!("CODE" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.CODE)) {
    if (completionContent.CODE === null) {
      content.title = "";
      content.subtitle = "";
      content.gistUrl = "";
      content.codeSnippet = "";
    }
    return;
  }
  if ("TITLE" in completionContent.CODE) {
    content.title =
      stringifyObjectsAndNumbers(completionContent.CODE.TITLE) || "";
  }
  if ("SUBTITLE" in completionContent.CODE) {
    content.subtitle =
      stringifyObjectsAndNumbers(completionContent.CODE.SUBTITLE) || "";
  }
  if ("GIST_URL" in completionContent.CODE) {
    content.gistUrl =
      stringifyObjectsAndNumbers(completionContent.CODE.GIST_URL) || "";
  }
  if ("CODE_SNIPPET" in completionContent.CODE) {
    content.codeSnippet =
      stringifyObjectsAndNumbers(completionContent.CODE.CODE_SNIPPET) || "";
  }
};
