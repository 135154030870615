import React, { Component } from 'react';
import { distanceInWords } from "date-fns";
import { connect } from 'react-redux';
import ChangelogModal from './ChangelogModal';
import './VersionInfo.css';

interface Props {
    meta?: any;
}

interface State {
    isModalVisible: boolean;
}

class VersionInfo extends Component<Props, State> {
    defaultDateOfUpdate = new Date();
    defaultVersion = null;

    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
        }
    }

    openModal() {
        this.setState({
            isModalVisible: true,
        });
    }

    closeModal() {
        this.setState({
            isModalVisible: false,
        });
    }
    
    fetchVersionFromMeta() {
        if (this.props.meta && this.props.meta['items'] && this.props.meta['items'].length === 1 && this.props.meta['items'][0]['product_version']) {
            return this.props.meta['items'][0]['product_version'];
        }
        return this.defaultVersion;
    }

    fetchUpdateDateFromMeta() {
        if (this.props.meta && this.props.meta['items'] && this.props.meta['items'].length === 1 && this.props.meta['items'][0]['last_product_update_date']) {
            return this.props.meta['items'][0]['last_product_update_date'];
        }
        return this.defaultDateOfUpdate;
    }

    displayDateOfUpdate() {
        const ParsedDateOfUpdate = Date.parse(this.fetchUpdateDateFromMeta());
        const currentTime = new Date();
        const lastUpdateTime = new Date(ParsedDateOfUpdate);
        return distanceInWords(currentTime.getTime(), lastUpdateTime.getTime());
    }

    render() {
        return (
            <div className="version-info">
                <span className="version-info__text">
                    Unicorn Platform {this.fetchVersionFromMeta()}
                </span>
                <span className="version-info__text version-info__text_small">
                    Updated <a
                        target="_self"
                        href="javascript:void(0);"
                        style={{ color: "inherit", textDecoration: "underline" }}
                        onClick={() => { this.openModal() }}
                    > {this.displayDateOfUpdate()} ago</a>.
                </span>
                <ChangelogModal
                    isModalVisible={this.state.isModalVisible}
                    handleCancel={() => { this.closeModal() }}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        meta: state.meta as object,
    }
}

export default connect(mapStateToProps)(VersionInfo);