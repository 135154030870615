//similar: setIsProPlan
export const checkIsProPlan = (user:any, plans:any) => {
    let userPlanId = user.data.current_plan;
    let plansCount = plans.items.length;
    for(let i = 0; i < plansCount; i++){
        let currentPlan = plans.items[i];
        if(currentPlan.id === userPlanId && currentPlan.price > 0){
            return true;
        }
    }
    return false
};