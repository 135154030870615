import React, { FC, useEffect, useRef } from "react";
import { draftJsEditorTitle } from "../../../data/constants";
import MyRichTextEditor from "../../MyRichTextEditor";
import DebouncedTextarea from "../../DebouncedTextarea";
import { InputProps } from "./interfaces";
import { useCurrentInputData } from "./useCurrentInputData";

const SubtitleText: FC<any> = ({
  saveContentInStore,
  generator,
  contentType,
  currentInputData,
  toggleNewEditionsDetected,
  content,
  textEditorType,
  revertToPlainHTML,
}: InputProps) => {
  
  const currentInputDataRef = useCurrentInputData(currentInputData);

  let onChangeHandler = (value: any) => {
    saveContentInStore(
      generator.changeSubtitleTextMarkup(
        { [contentType]: currentInputDataRef.current },
        value
      )
    );
    toggleNewEditionsDetected(true);
  };

  if (textEditorType && textEditorType === draftJsEditorTitle) {
    return (
      <MyRichTextEditor
        placeholder="Enter subtitle text"
        defaultValue={content.markup}
        onChange={onChangeHandler}
        revertToPlainHTML={revertToPlainHTML}
      />
    );
  } else {
    return (
      <DebouncedTextarea
        onChange={onChangeHandler}
        placeholder={
          "Unicorn Platform is a powerful website builder for startups, solo-entrepreneurs and hackers. Try it for free."
        }
        defaultValue={content.markup}
        autosize={{ minRows: 2, maxRows: 10 }}
        id={contentType}
      />
    );
  }
};

export default SubtitleText;
