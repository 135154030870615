import React, { Component } from "react";
import "./EditorScreenPageControls.css";
import { Button, Drawer, Icon, Modal, Popover, Tooltip } from "antd";
import { getCurrentWebsitePageUrl } from "../../helpers/getCurrentWebsitePageUrl";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { indexWebsitePageDashboardUrl } from "../../data/constants";
import { getCurrentWebsitePage } from "../../helpers/getCurrentWebsitePage";
import { checkIfPageHasComponents } from "../../helpers/editor/checkIfPageHasComponents";
import { getFullPageUrl } from "../../helpers/editor/getFullPageUrl";
import addNoCacheParamToURL from "../../helpers/editor/addNoCacheParamToURL";
import SettingsDrawerContent from "./SettingsDrawerContent";
import _ from "lodash";
import PublishPageMenu from "./PublishPageMenu";
import { ChangePageItemGlobalData } from "../../store/websitePages/types";
import { GetCurrentStoreData } from "../../store/websites/types";
import { getMetaDataFromComponentsArray } from "../../helpers/getMetaDataFromComponentsArray";
import UnsavedChangesModal from "./UnsavedChangesModal";

interface Props {
  websites: any;
  websitesPages: any;
  publishPage: any;
  history: any;
  saveWebsiteDataInStore: any;
  saveWebsitePageDataInStore: any;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  saveBlogDataInStore: any;
  saveBlogPostDataInStore: any;
  publishPost: any;
  drawerWidth: number | string;
  setDrawerWidth: any;
  changePageItemGlobalData: ChangePageItemGlobalData;
  getCurrentStoreData: GetCurrentStoreData;
}
interface MyState {
  openPageUrl: "";
  isSettingsDrawerVisible: boolean;
  isUnsavedChangesModalVisible: boolean;
}

class EditorScreenPageControls extends Component<Props, MyState> {
  componentWillMount(): void {
    this.setState({
      openPageUrl: "",
      isSettingsDrawerVisible: false,
      isUnsavedChangesModalVisible: false,
    });
  }

  showModal = () => {
    this.setState({
      isUnsavedChangesModalVisible: true,
    });
  };
  hideModal = () => {
    this.setState({
      isUnsavedChangesModalVisible: false,
    });
  };
  onDiscard = () => {
    this.setState({
      isUnsavedChangesModalVisible: false,
      isSettingsDrawerVisible: false,
    });
  };
  showDrawer = () => {
    this.setState({
      isSettingsDrawerVisible: true,
    });
  };
  hideDrawer = () => {
    const { currentWebsite } = this.props.getCurrentStoreData();
    if (
      _.get(currentWebsite, "hasUnsavedChanges") &&
      this.state.isSettingsDrawerVisible
    ) {
      this.showModal();
      return;
    }
    this.setState({
      isSettingsDrawerVisible: false,
    });
  };


  render() {
    if (
      this.props.websites.dataFetched &&
      this.props.websitesPages.pagesWithoutComponentsFetched
    ) {
      let currentWebsite = getCurrentWebsite(this.props.websites.items);

      if (currentWebsite === undefined) {
        return null;
      }

      const fillTemplateProgress = _.get(
        currentWebsite,
        "fillTemplateData.status"
      );
      let currentWebsiteSubdomain = currentWebsite.subdomain;

      let websitesPagesArray = this.props.websitesPages.items;
      let currentWebsitePageUrl = getCurrentWebsitePageUrl(
        currentWebsite,
        websitesPagesArray
      );
      let currentWebsitePage = getCurrentWebsitePage(
        currentWebsitePageUrl,
        websitesPagesArray,
        currentWebsite
      );

      if (currentWebsitePage === undefined) {
        return null;
      }

      let fullPageUrl = getFullPageUrl(currentWebsite, currentWebsitePageUrl);

      // when placing an URL ofr settings we must replace '' to indexWebsitePageDashboardUrl ('home').
      let currentWebsitePageUrlForSettings = currentWebsitePageUrl;
      if (currentWebsitePageUrlForSettings === "") {
        currentWebsitePageUrlForSettings = indexWebsitePageDashboardUrl;
      }

      let displayOpenOrPublishPageButtons = () => {
        let currentPageComponents = currentWebsitePage.page_components; //an object containing everything about the page content.

        // if a page contains some components
        if (checkIfPageHasComponents(currentPageComponents) === true) {
          /*Show the Open Page link is the page is published or offer to publish page if not.*/
          let ifAnyUnsavedChangesExist: boolean = false;
          ifAnyUnsavedChangesExist =
            this.props.websitesPages.websitePageNewEditionsDetected ||
            this.props.websites.isFooterEditionsDetected ||
            this.props.websites.isNavEditionsDetected;

          let ifAnyChangesBeingSaved: boolean = false;
          ifAnyChangesBeingSaved =
            this.props.websitesPages
              .isWaitingForSaveWebsitePageSchemeResponse ||
            this.props.websites.isWaitingForFooterEditionsServerResponse ||
            this.props.websites.isWaitingForNavEditionsServerResponse;

          let ifWaitingForPublishPageResponse =
            this.props.websitesPages.isWaitingForPublishPageResponse;

          if (currentWebsitePage.is_published) {
            // if published, offer to open the page
            return (
              <a
                className="js-tour__open-page"
                onClick={() => {
                  this.setState({
                    openPageUrl: addNoCacheParamToURL(fullPageUrl),
                  });
                }}
                href={
                  this.state.openPageUrl === ""
                    ? addNoCacheParamToURL(fullPageUrl)
                    : this.state.openPageUrl
                }
                target="_blank"
              >
                <Button
                  htmlType="button"
                  type="primary"
                  size="small"
                  icon={ifAnyChangesBeingSaved ? "loading" : "link"}
                >
                  Open page
                </Button>
              </a>
            );
          } else {
            const publishButtonJSX = (
              <Popover
                content={
                  <PublishPageMenu
                    saveWebsitePageDataInStore={
                      this.props.saveWebsitePageDataInStore
                    }
                  />
                }
                trigger="click"
                placement="bottomRight"
                destroyTooltipOnHide
                overlayClassName="no-arrow padding-12"
                overlayStyle={{
                  display: ifAnyUnsavedChangesExist ? "none" : "block",
                }}
                onVisibleChange={(visible) => {
                  if (!visible) return;

                  const { currentWebsitePage } =
                    this.props.getCurrentStoreData();

                  if (!currentWebsitePage) return;

                  const pageComponentItems = currentWebsitePage.page_components;

                  if (!pageComponentItems) return;
                  const {
                    first_page_title,
                    first_page_subtitle,
                    hero_image_url,
                    hero_image_uuid,
                  } = getMetaDataFromComponentsArray(pageComponentItems);
                  
                  this.props.changePageItemGlobalData({
                    pageId: currentWebsitePage.id,
                    data: {
                      first_page_title,
                      first_page_subtitle,
                      hero_image_url,
                      hero_image_uuid,
                    },
                  });
                }}
              >
                <Button
                  htmlType="button"
                  disabled={ifAnyUnsavedChangesExist}
                  icon="eye"
                  loading={ifWaitingForPublishPageResponse}
                  type="primary"
                  size="small"
                >
                  Publish page
                </Button>
              </Popover>
            );

            // warn user to save changes before publishing the page because if he publish it without saving the components edition data he will lose them.
            if (ifAnyUnsavedChangesExist) {
              return (
                <Tooltip
                  title={
                    <span>
                      Please <b>save changes</b> before publishing the page{" "}
                      <Icon type="arrow-down" />
                    </span>
                  }
                >
                  <div className="js-tour__publish">{publishButtonJSX}</div>
                </Tooltip>
              );
            } else {
              return (
                <div className="js-tour__publish"> {publishButtonJSX} </div>
              );
            }
          }
        }
      };

      return (
        <>
          <ul className="editor-screen-page-controls">
            {fillTemplateProgress !== "inProgress" && (
              <li className="editor-screen-page-controls__item">
                <Button
                  ghost
                  size="small"
                  type="default"
                  htmlType="button"
                  onClick={this.showDrawer}
                >
                  <Icon type="setting" />
                  Settings
                </Button>
              </li>
            )}
            <li className="editor-screen-page-controls__item">
              {displayOpenOrPublishPageButtons()}
            </li>
          </ul>
          <Drawer
            title="Settings"
            visible={this.state.isSettingsDrawerVisible}
            onClose={this.hideDrawer}
            className="settings-drawer"
            zIndex={1000}
            width={this.props.drawerWidth}
            afterVisibleChange={(visible) => {
              if (visible) {
                document.body.style.removeProperty("overflow");
                document.body.style.removeProperty("touch-action");
              }
            }}
            destroyOnClose
          >
            <SettingsDrawerContent
              history={this.props.history}
              saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
              saveWebsitePageDataInStore={this.props.saveWebsitePageDataInStore}
              publishPage={this.props.publishPage}
              saveImageInfoInDB={this.props.saveImageInfoInDB}
              removeImageFromDB={this.props.removeImageFromDB}
              type="page"
              saveBlogDataInStore={this.props.saveBlogDataInStore}
              saveBlogPostDataInStore={this.props.saveBlogPostDataInStore}
              publishPost={this.props.publishPost}
              setDrawerWidth={this.props.setDrawerWidth}
            />
          </Drawer>
          <Modal
            title="You have unsaved changes"
            visible={this.state.isUnsavedChangesModalVisible}
            onCancel={this.hideModal}
            centered
            width={300}
            footer={null}
          >
            <UnsavedChangesModal
              onDiscard={this.onDiscard}
            />
          </Modal>
        </>
      );
    } else {
      return null;
    }
  }
}

export default EditorScreenPageControls;
