import { BlogsState, ChangeBlogNavIntegrationIdPayload } from "./types";
import actionsNames from "./actions";
import _ from "lodash";

let initialState: BlogsState = {
  items: [],
  dataFetched: false,
  isWaitingForCreateBlogResponse: false,
  createBlogErrorMessage: undefined,

  isWaitingForBlogMetaDescriptionUpdateResponse: false,
  isInvalidBlogMetaDescriptionUpdateAttempt: false,
  blogMetaDescriptionUpdateErrorMessage: undefined,

  isWaitingForBlogCustomCodeUpdateResponse: false,
  isInvalidBlogCustomCodeUpdateAttempt: false,
  blogCustomCodeUpdateErrorMessage: undefined,

  isWaitingForBlogExtraCustomCodeUpdateResponse: false,
  isInvalidBlogExtraCustomCodeUpdateAttempt: false,
  blogExtraCustomCodeUpdateErrorMessage: undefined,

  isWaitingForBlogCustomCodePostsUpdateResponse: false,
  isInvalidBlogCustomCodePostsUpdateAttempt: false,
  blogCustomCodePostsUpdateErrorMessage: undefined,

  isWaitingForBlogExtraCustomCodePostsUpdateResponse: false,
  isInvalidBlogExtraCustomCodePostsUpdateAttempt: false,
  blogExtraCustomCodePostsUpdateErrorMessage: undefined,

  isWaitingForBlogMetaTitleUpdateResponse: false,
  isInvalidBlogMetaTitleUpdateAttempt: false,
  blogMetaTitleUpdateErrorMessage: undefined,

  isWaitingForCommonBlogUpdateResponse: false,

  isFooterEditionsDetected: false,
  isNavEditionsDetected: false,
  isWaitingForFooterEditionsServerResponse: false,
  isWaitingForNavEditionsServerResponse: false,
  isWaitingForURLUpdateResponse: false,

  loadedBlogs: [],
};

export const blogsReducer = (state: BlogsState = initialState, action: any) => {
  switch (action.type) {
    case actionsNames.SAVE_BLOGS_DATA:
      return {
        ...state,
        items: [
          // ...state.items,
          ...action.payload,
        ],
      };

    case actionsNames.ADD_SINGLE_BLOG_DATA:
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };

    case actionsNames.TOGGLE_DETECTED_FOOTER_CHANGES:
      return {
        ...state,
        isFooterEditionsDetected: action.payload,
      };
    case actionsNames.TOGGLE_DETECTED_NAV_CHANGES:
      return {
        ...state,
        isNavEditionsDetected: action.payload,
      };

    case actionsNames.FOOTER_CHANGES_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForFooterEditionsServerResponse: action.payload,
      };
    case actionsNames.NAV_CHANGES_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForNavEditionsServerResponse: action.payload,
      };

    case actionsNames.BLOGS_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };

    case actionsNames.CREATE_BLOG_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCreateBlogResponse: action.payload,
      };
    case actionsNames.CREATE_BLOG_ERROR_MESSAGE:
      return {
        ...state,
        createBlogErrorMessage: action.payload,
      };

    case actionsNames.CLONE_BLOG_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCloneBlogResponse: action.payload,
      };

    case actionsNames.URL_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForURLUpdateResponse: action.payload,
      };

    case actionsNames.DELETE_BLOG_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForDeleteBlogResponse: action.payload,
      };

    case actionsNames.BLOG_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForBlogCustomCodeUpdateResponse: action.payload,
      };

    case actionsNames.BLOG_EXTRA_CUSTOM_CODE_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForBlogExtraCustomCodeUpdateResponse: action.payload,
      };

    case actionsNames.BLOG_CUSTOM_CODE_POSTS_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForBlogCustomCodePostsUpdateResponse: action.payload,
      };

    case actionsNames.BLOG_EXTRA_CUSTOM_CODE_POSTS_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForBlogExtraCustomCodePostsUpdateResponse: action.payload,
      };

    case actionsNames.BLOG_META_TITLE_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForBlogMetaTitleUpdateResponse: action.payload,
      };
    case actionsNames.BLOG_META_TITLE_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidBlogMetaTitleUpdateAttempt: action.payload,
      };
    case actionsNames.BLOG_META_TITLE_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        blogMetaTitleUpdateErrorMessage: action.payload,
      };

    case actionsNames.BLOG_META_DESCRIPTION_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForBlogMetaDescriptionUpdateResponse: action.payload,
      };
    case actionsNames.BLOG_META_DESCRIPTION_UPDATE_TOGGLE_INVALID_UPDATE:
      return {
        ...state,
        isInvalidBlogMetaDescriptionUpdateAttempt: action.payload,
      };
    case actionsNames.BLOG_META_DESCRIPTION_UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        blogMetaDescriptionUpdateErrorMessage: action.payload,
      };

    case actionsNames.REMOVE_BLOG_FROM_STORE:
      let oldBlogs = state.items;
      let newBlogs = oldBlogs.filter((blog: any) => {
        return blog.website !== action.payload;
      });

      return {
        ...state,
        items: newBlogs,
      };

    case actionsNames.SAVE_BLOG_NAV_IN_STATE:
      let updatedBlogs = state.items;

      for (let i = 0; i < updatedBlogs.length; i++) {
        if (updatedBlogs[i].id === action.payload.currentBlogId) {
          updatedBlogs[i].nav = action.payload.navObject;
        }
      }

      return {
        ...state,
        items: updatedBlogs,
      };

    case actionsNames.CHANGE_BLOG_NAV_COLOR:
      let updatedBlogsArray = state.items;

      for (let i = 0; i < updatedBlogsArray.length; i++) {
        if (updatedBlogsArray[i].id === action.payload.currentBlogId) {
          updatedBlogsArray[i].nav.settings.style.color =
            action.payload.navColor;
        }
      }

      return {
        ...state,
        items: updatedBlogsArray,
      };

    case actionsNames.SAVE_BLOG_FOOTER_IN_STATE:
      let newBlogsList = state.items;

      for (let i = 0; i < newBlogsList.length; i++) {
        if (newBlogsList[i].id === action.payload.currentBlogId) {
          newBlogsList[i].footer = action.payload.footerObject;
        }
      }

      return {
        ...state,
        items: newBlogsList,
      };

    case actionsNames.COMMON_BLOG_UPDATE_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForCommonBlogUpdateResponse: action.payload,
      };

    case actionsNames.EDIT_LOADED_BLOGS_DATA: {
      const { blogId } = action.payload;
      const currentLoadedBlogs = [...state.loadedBlogs];
      const currentItemIndex = _.findIndex(currentLoadedBlogs, {
        blogId,
      });

      if (currentItemIndex === -1) {
        currentLoadedBlogs.push(action.payload);
      } else {
        currentLoadedBlogs[currentItemIndex] = action.payload;
      }

      return {
        ...state,
        loadedBlogs: currentLoadedBlogs,
      };
    }
    case actionsNames.CHANGE_BLOG_NAV_INTEGRATION_ID: {
      const payload: ChangeBlogNavIntegrationIdPayload = action.payload;
      const { blogId, integrationId } = payload;
      const blogsArrayCopy = _.cloneDeep(state.items);
      const currentBlog = blogsArrayCopy.find((blog) => blog.id === blogId);
      if (!currentBlog) return state;
      _.set(currentBlog, "nav.componentData.cta.content.form.connectedIntegrationsIds", [integrationId]);
      return {
        ...state,
        items: blogsArrayCopy,
      };
    }

    default:
      return state;
  }
};
