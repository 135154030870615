function processObject(obj) {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      processArray(obj[key]);
    } else if (typeof obj[key] === "object") {
      processObject(obj[key]);
    }
  }
}

function processArray(arr) {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    if (Array.isArray(item)) {
      processArray(item);
    } else if (typeof item === "object") {
      processObject(item);
    }
    if (typeof item === "string" || typeof item === "number") {
      continue;
    }
    arr[i] = { READONLY_ID: i, POSITION_IN_ARRAY: i, ...item };
  }
}

export function addKeysToArrays(json) {
  if (Array.isArray(json)) {
    processArray(json);
  } else if (typeof json === "object") {
    processObject(json);
  }
}
