import React from "react";
import getIntegrationLogoSourceFromTitle from "../../../helpers/editor/getIntegrationLogoSourceFromTitle";

interface Props {
  currentFormIntegrationTitle: string;
  currentFormIntegrationHumanTitle: string;
}

const IntegrationTitle = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentFormIntegrationHumanTitle,
  } = props;

  return (
    <h2 className="page-component__title">
      Fill in the fields to connect{" "}
      <img
        title={currentFormIntegrationHumanTitle}
        style={{ marginLeft: 7 }}
        height="18"
        src={getIntegrationLogoSourceFromTitle(currentFormIntegrationTitle)}
        alt={currentFormIntegrationHumanTitle + " logo"}
      />
    </h2>
  );
};

export default IntegrationTitle;
