import _ from "lodash";
import { finishJson5 } from "../../../components/editor/ai2/finishJson5";
import { GPT_CHARACTERS_PER_TICK_EDIT } from "../../../data/constants";
import { editInputData } from "../actions";
import { HandleOnInterval } from "../types";
import { getCurrentInputData } from "./getCurrentInputData";
import { handleOnComponentUpdateFinished } from "./handleOnComponentUpdateFinished";
import { updateComponentData } from "./updateComponentData";
import json5 from "json5";
import { editFillTemplateData } from "../../websites/actions";
import JSConfetti from "js-confetti";

export const handleOnInterval: HandleOnInterval = ({
  intervalRef,
  forceStopAllFlagRef,
  queueDataRef,
  currentWebsitePage,
  currentWebsite,
  currentPageIndex,
  charactersPerTick = GPT_CHARACTERS_PER_TICK_EDIT,
  isTemplate,
}) => {
  return (dispatch) => {
    const pageId = currentWebsitePage.id;
    if (queueDataRef.current.length === 0 && isTemplate) {
      const componentsCompleted = _.get(
        currentWebsite,
        "fillTemplateData.componentsCompleted"
      );
      const componentsTotal = _.get(
        currentWebsite,
        "fillTemplateData.componentsTotal"
      );
      if (componentsCompleted >= componentsTotal) {
        const jsConfetti = new JSConfetti();
        jsConfetti.addConfetti({
          confettiNumber: 1000,
          confettiRadius: 4,
        });
        jsConfetti.addConfetti({
          emojis: ["🦄"],
          emojiSize: 75,
          confettiNumber: 15,
        });
      }
    }
    if (queueDataRef.current.length === 0 || forceStopAllFlagRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      if (isTemplate) {
        dispatch(
          editFillTemplateData({
            websiteId: currentWebsite.id,
            newData: {
              status: "finished",
              skipFooter: false,
              skipNav: false,
            },
          })
        );
      }
      return;
    }
    const firstComponentInQueue = queueDataRef.current[0];
    if (!firstComponentInQueue) return;
    const {
      componentId,
      isCompletionFinished,
      completionData,
    } = firstComponentInQueue;

    if (completionData) {
      firstComponentInQueue.slicedCompletionData = completionData.slice(
        0,
        firstComponentInQueue.counter + charactersPerTick
      );
      if (completionData !== firstComponentInQueue.slicedCompletionData) {
        firstComponentInQueue.counter += charactersPerTick;
      }

      const regex = /\n/;
      const reasoning = firstComponentInQueue.slicedCompletionData.split(
        regex
      )[0];
      if (firstComponentInQueue.reasoning !== reasoning) {
        firstComponentInQueue.reasoning = reasoning.startsWith("{")
          ? ""
          : reasoning.replace(/\/\//g, "").trim();
      }

      let availableCompletionData;
      try {
        availableCompletionData = json5.parse(
          finishJson5(firstComponentInQueue.slicedCompletionData.trim())
        );
      } catch (error) {}
      if (availableCompletionData) {
        firstComponentInQueue.parsedCompletionData = availableCompletionData;
      }
    }

    const currentInput = dispatch(getCurrentInputData(componentId));
    if (currentInput && currentInput.status === "pending") {
      dispatch(
        editInputData({
          componentId,
          newData: {
            status: "editing",
          },
        })
      );
    }

    dispatch(
      updateComponentData({
        currentWebsitePage,
        currentWebsite,
        componentId,
        queueDataRef,
        currentPageIndex,
      })
    );

    const isAllDataPrinted =
      !!completionData &&
      firstComponentInQueue.slicedCompletionData === completionData;

    if (isCompletionFinished && isAllDataPrinted) {
      if (isTemplate) {
        const componentsCompleted: number = _.get(
          currentWebsite,
          ["fillTemplateData", "componentsCompleted"],
          0
        );
        dispatch(
          editFillTemplateData({
            websiteId: currentWebsite.id,
            newData: {
              componentsCompleted: componentsCompleted + 1,
            },
          })
        );
      }
      dispatch(
        handleOnComponentUpdateFinished({
          pageId,
          currentWebsitePage,
          currentWebsite,
          currentPageIndex,
          componentId,
          queueDataRef,
          isTemplate,
        })
      );
      queueDataRef.current.shift();
    }
  };
};
