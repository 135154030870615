import React from "react";
import FillTemplateItem from "./FillTemplateItem";
import "./FillTemplateModalContent.css";
import { connect } from "react-redux";
import { editFillTemplateData } from "../../../store/websites/actions";
import {
  EditFillTemplateData,
  WebsitesState,
} from "../../../store/websites/types";
import { getCurrentWebsite } from "../../../helpers/getCurrentWebsite";
import _ from "lodash";
import FillTemplateForm from "./FillTemplateForm";
import { AbortControllersRef, QueueItem } from "../../editor/ai2/types";
import { PosthogEvents } from "../../../enums/AnalyticsEventsEnums";

interface Props {
  websites: WebsitesState;
  handleFillTemplateCancel: () => void;
  editFillTemplateData: EditFillTemplateData;

  queueDataRef: React.MutableRefObject<QueueItem[]>;
  intervalRef: React.MutableRefObject<NodeJS.Timeout>;
  forceStopAllFlagRef: React.MutableRefObject<boolean>;
  setIsFillTemplateFormLoading: (value: boolean) => void;
  isLoading: boolean;
  abortControllersRef: AbortControllersRef;
}

const FillTemplateModalContent = (props: Props): JSX.Element => {
  const {
    handleFillTemplateCancel,
    editFillTemplateData,
    websites,
    queueDataRef,
    intervalRef,
    forceStopAllFlagRef,
    setIsFillTemplateFormLoading,
    isLoading,
    abortControllersRef,
  } = props;
  const currentWebsite = getCurrentWebsite(websites.items);
  const modalPage = _.get(currentWebsite, "fillTemplateData.modalPage");
  const templateSubdomain = _.get(
    currentWebsite,
    "fillTemplateData.templateSubdomain"
  );

  const onDefaultClick = () => {
    handleFillTemplateCancel();
    window.posthog.capture(PosthogEvents.CHOOSE_PLACEHOLDER_CONTENT, {
      website_id: currentWebsite.id,
      template_subdomain: templateSubdomain,
    });
  };

  const onAiClick = () => {
    editFillTemplateData({
      websiteId: currentWebsite.id,
      newData: {
        modalPage: "form",
      },
    });
    window.posthog.capture(PosthogEvents.CHOOSE_AI_CONTENT, {
      website_id: currentWebsite.id,
      template_subdomain: templateSubdomain,
    });
  };

  if (modalPage === "form") {
    return (
      <FillTemplateForm
        queueDataRef={queueDataRef}
        intervalRef={intervalRef}
        handleFillTemplateCancel={handleFillTemplateCancel}
        forceStopAllFlagRef={forceStopAllFlagRef}
        setIsFillTemplateFormLoading={setIsFillTemplateFormLoading}
        isLoading={isLoading}
        abortControllersRef={abortControllersRef}
      />
    );
  }

  return (
    <div className="fill-template-modal">
      <FillTemplateItem
        type="ai"
        title="AI generated content"
        subtitle="Generate content from a description"
        onClick={onAiClick}
      />
      <FillTemplateItem
        type="default"
        title="Placeholder content"
        subtitle="Continue with default text"
        onClick={onDefaultClick}
      />
    </div>
  );
};

const mapStateToProps = ({ websites }) => {
  return {
    websites,
  };
};
const mapActionsToProps = {
  editFillTemplateData,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(FillTemplateModalContent);
