import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { companyLogos, pressLogos } from "../../data/urls";
import { checkIfUrlStartsWithProtocol } from "../../helpers/editor/checkIfUrlStartsWithProtocol";
import getUrlWithoutProtocol from "../../helpers/editor/getUrlWithoutProtocol";
import { checkIfUrlStartsWithMailto } from "../../helpers/editor/checkIfUrlStartsWithMailto";
import { checkIfUrlStartsWithTel } from "../../helpers/editor/checkIfUrlStartsWithTel";
import { getUrlWithoutMailto } from "../../helpers/editor/getUrlWithoutMailto";
import { getUrlWithoutTel } from "../../helpers/editor/getUrlWithoutTel";
import { getPhoneNumberHref } from "../../helpers/getPhoneNumberHref";
import classNames from "classnames";
import WebsiteSocialNetworkLinks from "../component_molecules/WebsiteSocialNetworkLinks";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Contact02 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setSocialLinks({
          isDefaultEmpty,
          title: "Follow us:",
          items: [
            {
              href: "https://twitter.com/johnrushx",
            },
            {
              href: "https://fb.com/unicornplatform",
            },
            {
              href: "mailto:hi@unicornplatform.com",
            },
            {
              href: "",
            },
          ],
          hasTitle: true,
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpContacts({
          isDefaultEmpty,
          hasContactsItems: true,
          contactsItemHasTitle: true,
          maxItems: 20,
          items: [
            {
              title: "Support:",
              body: "mailto:hi@unicornplatform.com",
            },
            {
              title: "Sales:",
              body: "tel:+1 (555) 123 4567",
            },

            {
              title: "",
              body: "",
            },
          ],
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts

    let contacts = this.props.componentItem.componentData.contacts.content
      .items;

    let socialLinks = this.props.componentItem.componentData.socialLinks.content
      .items;
    let socialLinksTitle = this.props.componentItem.componentData.socialLinks
      .content.title;

    return (
      <section>
        <div className="contact-02">
          <div className="container container--mid">
            <ul className="contact-02__list">
              {/*if not single ghost left*/}
              {(socialLinks.length === 1 && socialLinks[0].href === "") ===
                false && (
                <li className="contact-02__list_item">
                  {socialLinksTitle && socialLinksTitle !== "" && (
                    <h3
                      className={classNames("def-16 title-text--inner contact-02__item_title", {
                        "text-white": this.props.isDarkBg,
                      })}
                    >
                      {socialLinksTitle}
                    </h3>
                  )}
                  <div className="contact-02__social">
                    <WebsiteSocialNetworkLinks 
                      links={socialLinks}
                      colorType={this.props.isDarkBg ? "white" : "color"}
                      direction={"left"}
                    />
                  </div>
                </li>
              )}

              {contacts &&
                contacts.map((item: any, key: number) => {
                  if (item.body && item.body !== "") {
                    let itemBodyStartsWithProtocol = checkIfUrlStartsWithProtocol(
                      item.body
                    );
                    const itemBodyStartsWithMailto = checkIfUrlStartsWithMailto(
                      item.body
                    );
                    const itemBodyStartsWithTel = checkIfUrlStartsWithTel(
                      item.body
                    );
                    let cleanLookingUrl = getUrlWithoutProtocol(item.body);
                    let href = item.body.toLowerCase();
                    if (itemBodyStartsWithMailto) {
                      cleanLookingUrl = getUrlWithoutMailto(item.body);
                    }
                    if (itemBodyStartsWithTel) {
                      cleanLookingUrl = getUrlWithoutTel(item.body);
                      href = getPhoneNumberHref(item.body);
                    }

                    let contactItemContent;
                    if (
                      itemBodyStartsWithProtocol ||
                      itemBodyStartsWithMailto ||
                      itemBodyStartsWithTel
                    ) {
                      contactItemContent = (
                        <a
                          className={classNames("content-text contact-02__link", {
                            "text-white": this.props.isDarkBg,
                            link: !this.props.isDarkBg,
                          })}
                          target="_blank"
                          href={href}
                        >
                          {cleanLookingUrl}
                        </a>
                      );
                    } else {
                      contactItemContent = (
                        <span
                          className={classNames({
                            "text-white": this.props.isDarkBg,
                          })}
                          dangerouslySetInnerHTML={{ __html: item.body }}
                        />
                      );
                    }

                    return (
                      <li
                        className="contact-02__list_item"
                        key={"contacts-02-" + key}
                      >
                        {item.title && item.title !== "" && (
                          <h3
                            className={classNames("def-16 title-text--inner contact-02__item_title", {
                              "text-white": this.props.isDarkBg,
                            })}
                          >
                            {item.title}
                          </h3>
                        )}
                        {contactItemContent}
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}

              <li className="contact-02__list_item" />
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact02;
