import DebouncedInput from "../../DebouncedInput";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { InputProps } from "./interfaces";
import { useCurrentInputData } from "./useCurrentInputData";
import InputLabel from "../InputLabel";
import {
  Button,
  Dropdown,
  Icon,
  Menu,
  Select,
  Tooltip,
  Typography,
} from "antd";
import _ from "lodash";
import DebouncedInputNumber from "../../DebouncedInputNumber";
import { connect } from "react-redux";
import { GetCurrentStoreData } from "../../../store/websites/types";
import { getCurrentStoreData } from "../../../store/websites/thunks";
import { CancelTokenSource } from "axios";
import { fetchAndSaveApiValidationData } from "../../../store/websitePages/thunks";
import { WebsitePagesState } from "../../../store/websitePages/types";
import { getCurrentWebsitesDynamicPages } from "../../../helpers/getCurrentWebsitesDynamicPages";
import { checkIfStringContainsProtocol } from "../../../helpers/checkIfStringContainsProtocol";
import { ValidationAlert } from "../../dashboard/UpdateApiSourceForm";
import { urls } from "../../../data/urls";
import { getActiveDomain } from "../../../helpers/websites/getActiveDomain";
import { slugifyNew } from "../../../helpers/strings/slugifyNew";

const { Paragraph, Title } = Typography;

interface Props extends InputProps {
  getCurrentStoreData: GetCurrentStoreData;
  fetchAndSaveApiValidationData: any;
  websitesPages: WebsitePagesState;
}

interface UrlWarningProps extends Props {
  currentWebsitesDynamicPages: any[];
  dynamicUrlPrefix: string;
  sampleRowsHaveAbsolutePath: boolean;
  isHomePageDynamic: boolean;
  currentRowsSample: any[];
  dynamicUrl: string;
}
const UrlWarning = (props: UrlWarningProps) => {
  const {
    currentWebsitesDynamicPages,
    dynamicUrlPrefix,
    sampleRowsHaveAbsolutePath,
    isHomePageDynamic,
    getCurrentStoreData,
    websitesPages,
    currentRowsSample,
    dynamicUrl,
  } = props;

  const { currentWebsitePage, currentWebsite } = getCurrentStoreData();
  if (
    !!currentWebsitePage &&
    !!currentWebsitePage.api_source_url &&
    dynamicUrlPrefix &&
    dynamicUrlPrefix.toString().startsWith("{{")
  ) {
    return null;
  }

  const currentWebsitePages = websitesPages.items.filter((item) => {
    return (
      currentWebsite && item.website === currentWebsite.id && !item.is_deleted
    );
  });
  const prefixPage = currentWebsitePages.find((page) => {
    return page.url === (dynamicUrlPrefix || "");
  });

  const isNoPrefix = !dynamicUrlPrefix || dynamicUrlPrefix === "no_prefix";
  const isDynamicPagesExist = currentWebsitesDynamicPages.length > 0;
  const isPageNotExist = !prefixPage;
  const isPrefixPageNotDynamic = !!prefixPage && !prefixPage.api_source_url;

  let message = null;
  let title = "Invalid URLs";
  let type: "success" | "info" | "error" | "warning" = "warning";

  if (isNoPrefix && !isHomePageDynamic) {
    if (isDynamicPagesExist) {
      message =
        "These URLs seem to be leading nowhere because you didn't select a dynamic page. Please select an existing dynamic page in the prefix dropdown or create a new one.";
    } else {
      message =
        "These URLs seem to be leading nowhere because you didn't select a dynamic page. Please create a dynamic page and select it in the prefix dropdown.";
    }
  }

  if (!isNoPrefix && isPageNotExist) {
    if (isDynamicPagesExist) {
      message = `These URLs seem to be leading nowhere because the page "/${
        dynamicUrlPrefix || ""
      }" doesn't exist. Please select an existing dynamic page in the prefix dropdown or create a new one.`;
    } else {
      message = `These URLs seem to be leading nowhere because the page "/${
        dynamicUrlPrefix || ""
      }" doesn't exist. Please create a dynamic page and select it in the prefix dropdown.`;
    }
  }

  if (!isNoPrefix && isPrefixPageNotDynamic) {
    message = `These URLs seem to be leading nowhere because the page "/${
      dynamicUrlPrefix || ""
    }" is not dynamic. Please add the Google Sheets source URL in this page's settings.`;
  }

  if (!message || sampleRowsHaveAbsolutePath) {
    type = "success";
    title = "URLs are valid";
    message = "Directory items will open the following dynamic pages on click:";
  }

  if (sampleRowsHaveAbsolutePath) {
    message = "Directory items will open the following URLs on click:";
  }

  let sampleRowValues = currentRowsSample
    .map((row) => row[dynamicUrl])
    .slice(0, 4);
  sampleRowValues = [...new Set(sampleRowValues)];

  return (
    <div
      className="update-api-source-form__validation-message-container"
      style={{ marginRight: 0 }}
    >
      <ValidationAlert
        message={
          <div>
            <Title level={4}>{title}</Title>
            <Paragraph style={{ textWrap: "pretty" } as any}>
              {message}
            </Paragraph>
            {type === "success" && (
              <Paragraph style={{ textWrap: "pretty" } as any}>
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    listStyle: "none",
                    margin: 0,
                  }}
                >
                  {sampleRowValues.map((val, i) => {
                    if (sampleRowValues.length > 3 && i === 2) {
                      return (
                        <li
                          key={i}
                          style={{ listStyleType: "none", margin: 0 }}
                        >
                          ...
                        </li>
                      );
                    }
                    let value = (val || "").toString();
                    let domainPrefix = "";
                    let urlPrefix = "";
                    if (!checkIfStringContainsProtocol(value)) {
                      value = slugifyNew(value);
                      if (!value.startsWith("/")) {
                        value = `/${value}`;
                      }
                      domainPrefix = `https://${getActiveDomain(
                        currentWebsite
                      )}`;
                      urlPrefix =
                        dynamicUrlPrefix && dynamicUrlPrefix !== "no_prefix"
                          ? `/${dynamicUrlPrefix}`
                          : "";
                    }
                    return (
                      <li
                        key={i}
                        style={
                          {
                            listStyleType: "none",
                            margin: 0,
                            maxWidth: "100%",
                            textWrap: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          } as any
                        }
                      >
                        <a
                          href={`${domainPrefix}${urlPrefix}${value}`}
                          target="_blank"
                          className="underlined_link"
                        >
                          {urlPrefix}
                          {value}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Paragraph>
            )}
            {!sampleRowsHaveAbsolutePath && (
              <Paragraph>
                Learn more about dynamic pages in{" "}
                <a
                  href={urls.guides.dynamicDataGoogleSheets}
                  className="underlined_link"
                  target="_blank"
                >
                  our guide
                </a>
                .
              </Paragraph>
            )}
          </div>
        }
        type={type}
        noButtons={true}
      />
    </div>
  );
};

const { Option, OptGroup } = Select;
const Directory: FC<any> = (props: Props) => {
  const {
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
    content,
    getCurrentStoreData,
    fetchAndSaveApiValidationData,
    websitesPages,
  } = props;

  const currentInputDataRef = useCurrentInputData(currentInputData);
  const dropdownContainerRef = useRef(null);
  const selectContainerRef = useRef(null);
  const filterFieldsContainerRef = useRef(null);
  const sortingContainerRef = useRef(null);
  const cancelTokenSourceRef = useRef<CancelTokenSource>(null);
  const urlContainerRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");

  const { currentWebsitePage, currentWebsite } = getCurrentStoreData();
  const currentWebsitesDynamicPages = getCurrentWebsitesDynamicPages(
    websitesPages,
    currentWebsite
  );
  const dynamicPageUrls = currentWebsitesDynamicPages
    .map((page) => page.url)
    .filter((url) => !!url);
  const isHomePageDynamic = !!currentWebsitesDynamicPages.find((page) => {
    return page.url === "";
  });
  const isValidationLoading: boolean = _.get(
    currentWebsitePage,
    "apiSourceValidationData.isLoading",
    false
  );
  const validationData = _.get(
    currentWebsitePage,
    "apiSourceValidationData.data"
  );

  useEffect(() => {
    if (isValidationLoading || !!validationData) return;
    fetchAndSaveApiValidationData(
      currentWebsitePage.api_source_url,
      cancelTokenSourceRef
    );
  }, []);

  let onChangeHandler = (value: any, callback) => {
    saveContentInStore(
      callback({ [contentType]: currentInputDataRef.current }, value)
    );
    toggleNewEditionsDetected(true);
  };

  const directoryItemHtml = _.get(content, "directoryItemHtml", "");
  const currentRowsSample = _.get(content, "currentRowsSample", []);
  const dynamicUrlPrefix = _.get(content, "dynamicUrlPrefix", "");
  const dynamicUrl = _.get(content, "dynamicUrl", "");
  const dynamicTarget = _.get(content, "dynamicTarget", "new_tab");
  const tags: string[] = _.get(content, "tags", []);
  const filteredTags: string[] = _.get(content, "filteredTags", "")
    .split(";")
    .filter((tag) => tag.trim());
  const availableColumns: string[] = _.get(content, "availableColumns", []);
  const filteredTagsMode: "all" | "any" = _.get(
    content,
    "filteredTagsMode",
    "all"
  );
  const filterFieldsMode: "all" | "any" = _.get(
    content,
    "filterFieldsMode",
    "all"
  );
  const filterFieldsArray: string[] = _.get(content, "filterFields", "")
    .toString()
    .split(";")
    .map((t) => t.trim())
    .filter((tag) => tag);
  const sortingField: string = _.get(content, "sortingField", "");
  const sortingOrder: "ascending" | "descending" = _.get(
    content,
    "sortingOrder",
    "ascending"
  );
  const isTagsHidden = _.get(content, "isTagsHidden", false);
  const categoryColumn = _.get(content, "categoryColumn", "category");

  const availableCmsProps: string[] = _.get(
    currentWebsitePage,
    "apiSourceValidationData.data.keys",
    []
  ).map((key: string) => `{{$${key}}}`);

  const sampleRowsHaveAbsolutePath = useMemo(() => {
    return currentRowsSample.some((row) => {
      return checkIfStringContainsProtocol(row[dynamicUrl]);
    });
  }, [currentRowsSample, dynamicUrl]);

  const availableColumnsJsx = [...availableColumns, ...availableCmsProps].map(
    (column) => {
      if (
        (column || "").toString().startsWith("{{") &&
        !searchValue.startsWith("{")
      ) {
        return null;
      }
      return (
        <Option key={column} value={column}>
          {column}
        </Option>
      );
    }
  );

  const availableSortedUrlColumns = [...availableColumns, ...availableCmsProps]
    .filter((column) => {
      return column !== "slugified_title";
    })
    .sort((a, b) => {
      const aCol = a.toString().toLowerCase();
      const bCol = b.toString().toLowerCase();
      if (aCol === "title") return -1;
      if (bCol === "title") return 1;
      if (aCol === "url") return -1;
      if (bCol === "url") return 1;
      if (aCol === "pageurl") return -1;
      if (bCol === "pageurl") return 1;
      if (aCol === "link") return -1;
      if (bCol === "link") return 1;
      if (aCol === "page") return -1;
      if (bCol === "page") return 1;
      return 0;
    })
    .map((column) => {
      const c = column.toString().toLowerCase();
      if (
        (c || "").toString().startsWith("{{") &&
        !searchValue.startsWith("{")
      ) {
        return null;
      }
      const isHighlighted =
        c === "title" ||
        c === "url" ||
        c === "pageurl" ||
        c === "link" ||
        c === "page";
      let sampleRowValues = currentRowsSample
        .map((row) => row[column])
        .slice(0, 3);
      sampleRowValues = [...new Set(sampleRowValues)];
      return (
        <Option
          key={column}
          value={column}
          style={{ textDecoration: isHighlighted ? "underline" : "none" }}
        >
          <Tooltip
            title={
              !(column || "").toString().startsWith("{{") &&
              sampleRowValues.length > 0 && (
                <div>
                  {sampleRowValues.map((val, i) => {
                    let value = (val || "").toString();
                    if (!checkIfStringContainsProtocol(value)) {
                      value = slugifyNew(value);
                      if (!value.startsWith("/")) {
                        value = `/${value}`;
                      }
                    }
                    return (
                      <div
                        style={{
                          maxWidth: 200,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                        key={i}
                      >
                        {value}
                      </div>
                    );
                  })}
                </div>
              )
            }
            placement="left"
            mouseLeaveDelay={0}
          >
            <div className="content-input__select-option-wrapper">{column}</div>
          </Tooltip>
        </Option>
      );
    });

  const tagFilterOptions = [...tags, ...availableCmsProps].map((tag) => {
    if (
      (tag || "").toString().startsWith("{{") &&
      !searchValue.startsWith("{")
    ) {
      return null;
    }
    if (filteredTags.includes(tag)) {
      return null;
    }
    return (
      <Option key={tag} value={tag}>
        {tag}
      </Option>
    );
  });
  const handleOnFilterTagChange = (newFilterTags: string[]) => {
    saveContentInStore(
      generator.changeDirectoryFilteredTags(
        {
          [contentType]: currentInputDataRef.current,
        },
        newFilterTags
      )
    );
    toggleNewEditionsDetected(true);
  };

  const fieldFilterOptions = [...availableColumns, ...availableCmsProps].map(
    (column) => {
      if (
        (column || "").toString().startsWith("{{") &&
        !searchValue.startsWith("{")
      ) {
        return null;
      }
      if (
        filterFieldsArray.includes(column) ||
        filterFieldsArray.includes(`-${column}`)
      ) {
        return null;
      }
      return (
        <OptGroup key={column}>
          <Option value={column} label={`"${column}" exists`}>
            Visible if <b>{column}</b> is TRUE
          </Option>
          <Option value={`-${column}`} label={`"${column}" doesn't exist`}>
            Visible if <b>{column}</b> is FALSE
          </Option>
        </OptGroup>
      );
    }
  );
  const handleOnFilterFieldChange = (newFilterFields: string[]) => {
    saveContentInStore(
      generator.changeDirectoryFilterFields(
        {
          [contentType]: currentInputDataRef.current,
        },
        newFilterFields.join(";")
      )
    );
    toggleNewEditionsDetected(true);
  };

  const targetMenu = (
    <Menu
      selectedKeys={[dynamicTarget]}
      onClick={({ key }) => {
        onChangeHandler(key, generator.changeDirectoryDynamicTarget);
      }}
    >
      <Menu.Item key="new_tab">New tab</Menu.Item>
      <Menu.Item key="same_tab">Same tab</Menu.Item>
    </Menu>
  );

  const dynamicTargetTitleMap = {
    new_tab: {
      title: "New tab",
      tooltip: "Links will open in a new browser tab.",
    },
    same_tab: {
      title: "Same tab",
      tooltip: "Links will open in the same browser tab.",
    },
  };

  const isPrefixInputVisible = !sampleRowsHaveAbsolutePath;

  const isDynamicTargetInputVisible = useMemo(() => {
    return (directoryItemHtml || "").toString().includes("{{dynamicTarget}}");
  }, [directoryItemHtml]);
  const isDynamicUrlInputsVisible = useMemo(() => {
    return (directoryItemHtml || "").toString().includes("{{dynamicUrl}}");
  }, [directoryItemHtml]);

  const dynamicPageOptions = [...dynamicPageUrls, ...availableCmsProps].map(
    (url) => {
      const currentUrl = (url || "").toString();
      if (currentUrl.startsWith("{{") && !searchValue.startsWith("{")) {
        return null;
      }
      return (
        <Option key={url} value={url}>
          {currentUrl.startsWith("{{") ? currentUrl : `/${currentUrl}`}
        </Option>
      );
    }
  );

  let dynamicUrlInputLabel = "Item URLs:";
  let dynamicUrlInputTooltip =
    "Specify which URLs will be opened when you click on the directory items. Choose the field in your CMS that contains the URL. Add a prefix to open a dynamic page (e.g. /tools/unicornplatform).";
  if (!isDynamicUrlInputsVisible && isDynamicTargetInputVisible) {
    dynamicUrlInputLabel = "Items URL target:";
    dynamicUrlInputTooltip =
      "Specify where the URLs will be opened when you click on the directory items.";
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div>
        <DebouncedInput
          onChange={(value) => {
            onChangeHandler(value, generator.changeDirectoryCmsUrl);
          }}
          placeholder="https://docs.google.com/spreadsheets/d/e/2PACX-1vRKapXyifgCbzW_cAgpYEA78r2r7KNXPKueel8ZpcA1gpzzCV7sBFTZktDnLm9hDKG1kcKZM7NTrtLc/pub?output=csv"
          defaultValue={content.cmsUrl}
          id={`${contentType}-cmsUrl`}
        />
        <div style={{ marginTop: "5px" }}>
          Learn more in{" "}
          <a
            href="https://help.unicornplatform.com/en/article/how-to-configure-directory-component-directory-page-1fvlq92/"
            className="underlined_link"
            target="_blank"
          >
            our guide
          </a>
          .
        </div>
      </div>
      <div>
        <InputLabel
          maxWidth="350px"
          tooltip="Placeholder text for the search bar. Use {{amount}} to display the total number of items in the directory. Leave empty to hide the search bar."
        >
          Search bar:
        </InputLabel>
        <DebouncedInput
          onChange={(value) => {
            onChangeHandler(value, generator.changeDirectorySearchPlaceholder);
          }}
          placeholder="Search among {{amount}} items"
          defaultValue={content.searchPlaceholder}
          id={`${contentType}-searchPlaceholder`}
        />
      </div>
      <div style={{ display: "flex", gap: 20 }}>
        <div style={{ width: "100%" }}>
          <InputLabel tooltip='Sets the maximum number of items to display initially. Additional items will be accessible via a "Show all" button.'>
            Item limit:
          </InputLabel>
          <DebouncedInputNumber
            onChange={(value) => {
              onChangeHandler(value, generator.changeDirectoryMaxItems);
            }}
            placeholder="4"
            defaultValue={content.maxItems}
            id={`${contentType}-maxItems`}
            min={1}
            max={1000}
            style={{ width: "100%" }}
          />
        </div>
        <div style={{ width: "100%" }}>
          <InputLabel tooltip="The text displayed on the button that reveals all items when clicked. Leave empty to hide the button.">
            Button text:
          </InputLabel>
          <DebouncedInput
            onChange={(value) => {
              onChangeHandler(
                value,
                generator.changeDirectoryShowMoreButtonText
              );
            }}
            placeholder="Show all"
            defaultValue={content.showMoreButtonText}
            id={`${contentType}-showMoreButtonText`}
          />
        </div>
      </div>
      <div ref={selectContainerRef} className="content-input__select-box">
        <InputLabel tooltip='Specify the field in your data that contains tags in this format: "tag1;tag2;tag3". Use visibility button to hide the tags bar.'>
          Tags field:
        </InputLabel>
        <div style={{ display: "flex", gap: 10 }}>
          <Select
            showSearch
            placeholder="Select column"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option.props.children as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={() => selectContainerRef.current}
            value={categoryColumn}
            onChange={(value) => {
              onChangeHandler(value, generator.changeDirectoryCategoryColumn);
            }}
            className="content-input__select"
            onSearch={setSearchValue}
            onBlur={() => setSearchValue("")}
            onSelect={() => setSearchValue("")}
          >
            <Option value="no_tags">No tags</Option>
            {availableColumnsJsx}
            {isValidationLoading && (
              <Option value="loading" disabled>
                Loading global properties...
              </Option>
            )}
          </Select>
          {!!categoryColumn && categoryColumn !== "no_tags" && (
            <Tooltip
              title={isTagsHidden ? "Tags are hidden." : "Tags are visible."}
              mouseLeaveDelay={0}
              placement="topRight"
              getPopupContainer={() => selectContainerRef.current}
            >
              <Button
                icon={isTagsHidden ? "eye-invisible" : "eye"}
                onClick={() => {
                  onChangeHandler(
                    !isTagsHidden,
                    generator.changeDirectoryIsTagsHidden
                  );
                }}
              />
            </Tooltip>
          )}
        </div>
      </div>
      {((categoryColumn !== "no_tags" &&
        [...tags, ...availableCmsProps].length > 0) ||
        filteredTags.length > 0) && (
        <div ref={dropdownContainerRef} className="content-input__select-box">
          <InputLabel
            tooltip={`Keep only selected tags. The tags bar is hidden if only 1 tag is selected. Switch "All" and "Any" to change the logic for multiple tags.`}
            maxWidth="340px"
          >
            Filter items by tags:
          </InputLabel>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            <Select
              mode="multiple"
              placeholder="Select tags"
              value={filteredTags}
              getPopupContainer={() => dropdownContainerRef.current}
              allowClear
              showArrow
              onChange={handleOnFilterTagChange}
              onSearch={setSearchValue}
              onBlur={() => setSearchValue("")}
              onSelect={() => setSearchValue("")}
            >
              {tagFilterOptions}
              {isValidationLoading && (
                <Option value="loading" disabled>
                  Loading global properties...
                </Option>
              )}
            </Select>
            {filteredTags.length > 1 && (
              <Tooltip
                title={
                  filteredTagsMode === "all"
                    ? "Items are displayed if they match every tag."
                    : "Items are displayed if they match some of the tags."
                }
                mouseLeaveDelay={0}
                overlayStyle={{ width: 190 }}
                placement="topRight"
                getPopupContainer={() => dropdownContainerRef.current}
              >
                <Button
                  onClick={() => {
                    onChangeHandler(
                      filteredTagsMode === "all" ? "any" : "all",
                      generator.changeDirectoryFilteredTagsMode
                    );
                  }}
                >
                  {filteredTagsMode === "all" ? "All" : "Any"}
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      )}

      <div ref={filterFieldsContainerRef} className="content-input__select-box">
        <InputLabel
          tooltip={`Display items only if they match certain conditions. The value is considered FALSE if it's empty or 'false'. Otherwise, it's TRUE. Switch "All" and "Any" to change the logic for multiple fields.`}
          maxWidth="350px"
        >
          Filter items by fields:
        </InputLabel>
        <div
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          <Select
            mode="multiple"
            placeholder="Select fields"
            value={filterFieldsArray}
            getPopupContainer={() => filterFieldsContainerRef.current}
            allowClear
            showArrow
            onChange={handleOnFilterFieldChange}
            onSearch={setSearchValue}
            onBlur={() => setSearchValue("")}
            onSelect={() => setSearchValue("")}
          >
            {fieldFilterOptions}
            {isValidationLoading && (
              <Option value="loading" disabled>
                Loading global properties...
              </Option>
            )}
          </Select>
          {filterFieldsArray.length > 1 && (
            <Tooltip
              title={
                filterFieldsMode === "all"
                  ? "Items are displayed if they match every condition."
                  : "Items are displayed if they match some of the conditions."
              }
              mouseLeaveDelay={0}
              overlayStyle={{ width: 225 }}
              placement="topRight"
              getPopupContainer={() => filterFieldsContainerRef.current}
            >
              <Button
                onClick={() => {
                  onChangeHandler(
                    filterFieldsMode === "all" ? "any" : "all",
                    generator.changeDirectoryFilterFieldsMode
                  );
                }}
              >
                {filterFieldsMode === "all" ? "All" : "Any"}
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
      <div ref={sortingContainerRef} className="content-input__select-box">
        <InputLabel
          maxWidth="350px"
          tooltip="Specify the field to sort the items by. Select ascending order to display the items from A to Z or from 0 to 9. Select descending order to display the items from Z to A or from 9 to 0."
        >
          Sort by:
        </InputLabel>
        <div
          style={{
            display: "flex",
            gap: 10,
          }}
        >
          <Select
            showSearch
            placeholder="Select field"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option.props.children as string)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            getPopupContainer={() => sortingContainerRef.current}
            value={sortingField}
            onChange={(value) => {
              onChangeHandler(value, generator.changeDirectorySortingField);
            }}
            className="content-input__select"
            onSearch={setSearchValue}
            onBlur={() => setSearchValue("")}
            onSelect={() => setSearchValue("")}
          >
            <Option value="no_sorting">No sorting</Option>
            <Option value="random_sorting">Random</Option>
            {availableColumnsJsx}
            {isValidationLoading && (
              <Option value="loading" disabled>
                Loading global properties...
              </Option>
            )}
          </Select>
          {!!sortingField &&
            sortingField !== "no_sorting" &&
            sortingField !== "random_sorting" && (
              <Tooltip
                title={
                  sortingOrder === "ascending"
                    ? "Ascending order."
                    : "Descending order."
                }
                mouseLeaveDelay={0}
                placement="topRight"
                getPopupContainer={() => sortingContainerRef.current}
              >
                <Button
                  icon={`sort-${sortingOrder}`}
                  onClick={() => {
                    onChangeHandler(
                      sortingOrder === "ascending" ? "descending" : "ascending",
                      generator.changeDirectorySortingOrder
                    );
                  }}
                />
              </Tooltip>
            )}
        </div>
      </div>
      {(isDynamicUrlInputsVisible || isDynamicTargetInputVisible) && (
        <div ref={urlContainerRef} className="content-input__select-box">
          <InputLabel maxWidth="350px" tooltip={dynamicUrlInputTooltip}>
            {dynamicUrlInputLabel}
          </InputLabel>
          <div
            style={{
              display: "flex",
              gap: 10,
            }}
          >
            {isDynamicUrlInputsVisible && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  gap: 10,
                  alignItems: "center",
                }}
              >
                {isPrefixInputVisible && (
                  <Select
                    showSearch
                    placeholder="Select prefix"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option.props.children as string)
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    className="content-input__select"
                    getPopupContainer={() => urlContainerRef.current}
                    style={{ width: 136 }}
                    value={dynamicUrlPrefix}
                    onChange={(value) => {
                      onChangeHandler(
                        value,
                        generator.changeDirectoryDynamicUrlPrefix
                      );
                    }}
                    onSearch={setSearchValue}
                    onBlur={() => setSearchValue("")}
                    onSelect={() => setSearchValue("")}
                  >
                    <Option key="no_prefix">
                      {isHomePageDynamic ? "Home page" : "No prefix"}
                    </Option>
                    {dynamicPageOptions}
                    {isValidationLoading && (
                      <Option value="loading" disabled>
                        Loading global properties...
                      </Option>
                    )}
                  </Select>
                )}
                {isPrefixInputVisible && (
                  <span style={{ color: "rgba(0,0,0,0.45)" }}>/</span>
                )}
                <Select
                  showSearch
                  placeholder="Select field"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    return (
                      (option.props.value || "")
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  className="content-input__select"
                  getPopupContainer={() => urlContainerRef.current}
                  onChange={(value) => {
                    onChangeHandler(value, generator.changeDirectoryDynamicUrl);
                  }}
                  dropdownClassName="content-input__select-dropdown-with-tooltips"
                  value={dynamicUrl}
                  style={{ width: 136 }}
                  onSearch={setSearchValue}
                  onBlur={() => setSearchValue("")}
                  onSelect={() => setSearchValue("")}
                >
                  {availableSortedUrlColumns}
                  {isValidationLoading && (
                    <Option value="loading" disabled>
                      <div className="content-input__select-option-wrapper">
                        Loading global properties...
                      </div>
                    </Option>
                  )}
                </Select>
              </div>
            )}
            {isDynamicTargetInputVisible && (
              <Tooltip
                title={dynamicTargetTitleMap[dynamicTarget].tooltip}
                mouseLeaveDelay={0}
                placement={isDynamicUrlInputsVisible ? "topRight" : "right"}
                getPopupContainer={() => urlContainerRef.current}
                overlayStyle={{ maxWidth: 200 }}
              >
                <Dropdown
                  overlay={targetMenu}
                  trigger={["click"]}
                  getPopupContainer={() => urlContainerRef.current}
                >
                  <Button>
                    {dynamicTargetTitleMap[dynamicTarget].title}{" "}
                    <Icon type="down" />
                  </Button>
                </Dropdown>
              </Tooltip>
            )}
          </div>
          {isDynamicUrlInputsVisible && (
            <UrlWarning
              {...props}
              currentWebsitesDynamicPages={currentWebsitesDynamicPages}
              dynamicUrlPrefix={dynamicUrlPrefix}
              sampleRowsHaveAbsolutePath={sampleRowsHaveAbsolutePath}
              isHomePageDynamic={isHomePageDynamic}
              currentRowsSample={currentRowsSample}
              dynamicUrl={dynamicUrl}
            />
          )}
        </div>
      )}
    </div>
  );
};

export const mapStateToProps = (state: any) => {
  return {
    websitesPages: state.websitesPages,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  fetchAndSaveApiValidationData,
})(Directory);
