import React from "react";
import { Icon } from "antd";
import "./FillTemplateProgress.css";
import { connect } from "react-redux";
import { WebsitesState } from "../../../store/websites/types";
import { getCurrentWebsite } from "../../../helpers/getCurrentWebsite";
import _ from "lodash";

interface Props {
  websites: WebsitesState;
}

const FillTemplateProgressIndicator = (props: Props): JSX.Element => {
  const { websites } = props;
  const currentWebsite = getCurrentWebsite(websites.items);
  const componentsTotal = _.get(
    currentWebsite,
    ["fillTemplateData", "componentsTotal"],
    0
  );
  const isConnecting = _.get(currentWebsite, [
    "fillTemplateData",
    "isConnectingToOpenAI",
  ]);
  const buttonText = isConnecting
    ? "Connecting to OpenAI..."
    : "Generating your page...";
  const componentsCompleted =
    _.get(currentWebsite, ["fillTemplateData", "componentsCompleted"], 0) + 1;

  let progressPercentage =
    componentsTotal && componentsCompleted
      ? Math.round((componentsCompleted / componentsTotal) * 100)
      : 0;
  if (progressPercentage > 100) {
    progressPercentage = 100;
  }

  return (
    <div className="fill-template-progress">
      <div className="fill-template-progress__text-box">
        <span className="fill-template-progress__text"> {buttonText}</span>
      </div>
      <div
        style={{
          width: `${progressPercentage}%`,
          position: "absolute",
          top: "0",
          left: "0",
          height: "100%",
          overflow: "hidden",
          transition: "width 0.5s",
        }}
      >
        <div className="fill-template-progress__indicator"></div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ websites }) => ({ websites });
export default connect(mapStateToProps, null)(FillTemplateProgressIndicator);
