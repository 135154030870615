import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {pictogramOptions} from "../../data/pictogramOptions";
import { displayMarkupText } from "../../helpers/content_displayers/displayMarkupText";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}

class Code02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Just copy and paste"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: ''}));

            this.props.saveContentInStore(this.generator.setUpCode(
                {
                  isDefaultEmpty,
                    fieldTitle: {default: "Code snippet"},
                    label: {default: "Paste a code snippet:"},
                    labelTooltip: {default: ""},
                    hasGistUrl: false,
                    hasSubtitle: true,
                    hasTitle: true,
                    hasCodeSnippet: true,
                    
                }
            ));

        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts

        // In this Text component there is only one text element.
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let codeSnippet = displayMarkupText(this.props.componentItem.componentData.code.content.codeSnippet);
        let codeTitle = displayMarkupText(this.props.componentItem.componentData.code.content.title);
        let codeSubtitle = displayMarkupText(this.props.componentItem.componentData.code.content.subtitle);

        let randomIndex = Math.floor(Math.random() * 9999); //because each Code-02 component needs a unique index for click-to-copy plugin.

        return (
            <section>
                <div className="code-02">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container">
                        <div className="code-02__wrapper">
                            <div className="code-02__block">
                                <div className="sh-4 card-container code-02__block_box">
                                    <pre className="content-text code-02__code" id={"code-block-" + randomIndex}>{codeSnippet}</pre>
                                </div>
                                <div className="code-02__button_box">
                                    <button className="button js-copy-text button--accent-bg " type="submit"
                                            data-clipboard-target={"#code-block-" + randomIndex}><span className="button__text">Copy code</span>
                                        <div className="spinner"/>
                                        <div className="button__submit_success">
                                            <div className="button__success_circle"/>
                                            <div>
                                                <svg className="button__success_tick" width="13" height="13"
                                                     viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
                                                    <path className="button__success_tick_path" stroke="#FFF"
                                                          d="M0 8l5.119 3.873L11.709.381" fill="none"
                                                          fillRule="evenodd" strokeLinecap="square"/>
                                                </svg>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                            {/*If both title and subtitle are empty, do not display. */}
                            {((codeTitle === '' && codeSubtitle === '') === false) && <div className="code-02__info">
                                <h3 className={"title-text--inner code-02__info_title def-18_72" + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: codeTitle}}/>
                                <div className={"content-text code-02__info_text " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: codeSubtitle}}/>
                            </div>}
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default Code02;
