import React, { Component } from "react";
import { Button, Input } from "antd";
import { api } from "../../data/urls";
import { getCurrentWebsiteSubdomain } from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { projectUrl } from "../../data/constants";
import { openNotification } from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import MyButton from "../MyButton";
import { getWebsiteProtocol } from "../../helpers/getWebsiteProtocol";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { connect } from "react-redux";
import {
  subdomainUpdateErrorMessage,
  subdomainUpdateToggleInvalidAttempt,
  subdomainUpdateWaitingForServer,
  toggleWebsitesFetched,
} from "../../store/websites/actions";
import { savePartialWebsiteData } from "../../store/websites/thunks";
import {
  SavePartialWebsiteData,
  WebsitesItem,
} from "../../store/websites/types";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { WebsitePagesState } from "../../store/websitePages/types";
import { getCurrentWebsitePageUrl } from "../../helpers/getCurrentWebsitePageUrl";
import _ from "lodash";

interface Props {
  websites: any;
  auth: any;
  websitesPages: WebsitePagesState;
  subdomainUpdateToggleInvalidAttempt: any;
  subdomainUpdateErrorMessage: any;
  toggleWebsitesFetched: any;
  subdomainUpdateWaitingForServer: any;
  savePartialWebsiteData: SavePartialWebsiteData;

  currentWebsiteSubdomain: string;
  history: any;
}
type MyState = {
  subdomain: string;
  // showSaveButtonSuccess: boolean
};
class UpdateSubdomainForm extends Component<Props, MyState> {
  componentWillMount(): void {
    // We want to set up an initial value of a website subdomain.
    this.setState({
      subdomain: this.props.currentWebsiteSubdomain,
      // showSaveButtonSuccess: false,
    });
  }
  clearUpdateSubdomainInput = (): void => {
    //Remove all errors and make inputs normal colour (not red).
    this.props.subdomainUpdateToggleInvalidAttempt(false);
    this.props.subdomainUpdateErrorMessage(undefined);
  };

  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    // We set the new state only when we change a settings active website.
    // We want to set up an initial value of a new picked website.
    if (
      this.props.currentWebsiteSubdomain !== nextProps.currentWebsiteSubdomain
    ) {
      this.setState({ subdomain: nextProps.currentWebsiteSubdomain });
      // We also clear errors when switch a website.
      this.clearUpdateSubdomainInput();
    }
  }

  // launchSuccessfulSaveAnimation = () =>{
  //     this.setState({
  //         showSaveButtonSuccess: true
  //     });
  //
  //     setTimeout(() => {
  //         this.setState({
  //             showSaveButtonSuccess: false
  //         })
  //     }, 1500);
  // };

  handleSubdomainUpdate = (e: any): void => {
    e.preventDefault();
    const currentWebsite = getCurrentWebsite(this.props.websites.items);
    const currentPageUrl = getCurrentWebsitePageUrl(
      currentWebsite,
      this.props.websitesPages.items
    );
    const oldSubdomain = currentWebsite.subdomain;
    // this.props.form.validateFieldsAndScroll((err:object, values:any) => {
    //     if (!err) {
    this.props.subdomainUpdateWaitingForServer(true);

    //We want to remove all errors from inputs on a new request.
    this.clearUpdateSubdomainInput();

    // let newCustomDomain = this.state.custom_domain;
    let newSubdomain = this.state.subdomain
      .toString()
      .toLowerCase()
      .replace(" ", "");

    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websites.updateWebsite.prefix +
      getCurrentWebsiteSubdomain(this.props.websites.items) +
      api.websites.updateWebsite.postfix;
    axios
      .patch(
        apiUrl,
        { subdomain: newSubdomain },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        const dataToStore: Partial<WebsitesItem> = {
          ...currentWebsite,
          subdomain: _.get(response, "data.subdomain"),
        };
        this.props.toggleWebsitesFetched(false);
        this.props.savePartialWebsiteData({
          data: dataToStore,
          websiteId: currentWebsite.id,
        });

        // let newSubdomain = values["subdomain"];

        // Change url programmatically.
        // https://stackoverflow.com/questions/31079081/programmatically-navigate-using-react-router - "2. Use composition and render a <Route>"
        const newUrl = this.props.history.location.pathname.replace(
          `/${oldSubdomain}/`,
          `/${newSubdomain}/`
        );
        this.props.history.replace(newUrl);

        this.props.toggleWebsitesFetched(true);

        let updatedWebsiteData = response.data;

        // Tell a user that the operation is successful.
        let newSubdomainUrl = "https://" + newSubdomain + "." + projectUrl;
        let newSubdomainMessage = (
          <span>
            Successfully changed the subdomain. Your website can be accessed
            here:&nbsp;
            <a target="_blank" href={newSubdomainUrl}>
              {newSubdomainUrl}
            </a>
          </span>
        );

        if (updatedWebsiteData.is_custom_domain_active) {
          let websiteProtocol = getWebsiteProtocol(updatedWebsiteData);
          let customDomainUrl =
            websiteProtocol + updatedWebsiteData["custom_domain"];
          newSubdomainMessage = (
            <span>
              Successfully changed the subdomain. <br />
              However, since the website's <b>custom</b> domain is activated,
              the website is accessible here:&nbsp;
              <a target="_blank" href={customDomainUrl}>
                {customDomainUrl}
              </a>
            </span>
          );
        }

        // keep this even at the bottom (after this.props.history.replace('/' + newSubdomain + '/settings');) - or Intercom will break
        fireAnalyticsEvent.fireCrisp(CrispEvents.changeSubdomain, {
          new_subdomain: response.data.subdomain,
        });
      })
      .catch((error) => {
        if (error.response) {
          let errorData = error.response.data;
          handleBadWebsiteError(errorData);

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.changeSubdomainError,
            {
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              new_subdomain: newSubdomain,
            },
            true
          );

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.subdomain !== undefined) {
            let message = errorData.subdomain.join(" ");
            this.props.subdomainUpdateErrorMessage(message);
            this.props.subdomainUpdateToggleInvalidAttempt(true);
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.subdomainUpdateWaitingForServer(false);
      });
    // }
    // });
  };

  handleInputChange = (e: any) => {
    this.setState({ subdomain: e.target.value });
  };

  render() {
    return (
      <form
        onSubmit={this.handleSubdomainUpdate}
        className="settings-box__form"
      >
        <div className="settings-box__input_box full-width">
          <Input
            name="subdomain"
            id="subdomain"
            required={false}
            placeholder="Subdomain"
            type="text"
            value={this.state.subdomain}
            onChange={this.handleInputChange}
            addonAfter={"." + projectUrl}
          />
          <div
            className="settings-box__error_message"
            style={{
              opacity:
                this.props.websites.subdomainUpdateErrorMessage === undefined
                  ? 0
                  : 1,
            }}
          >
            {this.props.websites.subdomainUpdateErrorMessage}
          </div>
        </div>
        <Button
          // showButtonSuccess={this.state.showSaveButtonSuccess}
          className="settings-box__form_save"
          icon="save"
          type="primary"
          loading={this.props.websites.isWaitingForSubdomainUpdateResponse}
          htmlType="submit"
          hidden={this.props.currentWebsiteSubdomain === this.state.subdomain}
        />
      </form>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    websites: state.websites,
    auth: state.auth,
    websitesPages: state.websitesPages,
  };
};
const mapActionsToProps = {
  subdomainUpdateToggleInvalidAttempt,
  subdomainUpdateErrorMessage,
  toggleWebsitesFetched,
  subdomainUpdateWaitingForServer,
  savePartialWebsiteData,
};
export default connect(mapStateToProps, mapActionsToProps)(UpdateSubdomainForm);
