import React, { useEffect, useState } from "react";
import capitalizeString from "../../helpers/strings/capitalizeString";
import { Oauth2Apps } from "../../enums/Oauth2Apps";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Empty } from "antd";
import getUrlParameter from "../../helpers/getUrlParameter";
import { urls } from "../../data/urls";
import { getAllQueryParams } from "../../helpers/getAllQueryParams";
import { openNotification } from "../../helpers/openNotification";
import { fetchCurrentUserInfo } from "../../store/user/actions";
import { logout } from "../../store/auth/actions";
import SpinnerBox from "../dashboard/SpinnerBox";
import { createOauth2GrantAsync } from "../../helpers/auth/createOauth2GrantAsync";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import trimStringTo from "../../helpers/strings/trimStringTo";
import "./Permissions.css";

type RouterParams = {
  app: Oauth2Apps;
};
interface Props extends RouteComponentProps<RouterParams> {
  auth: any;
  user: any;
  fetchCurrentUserInfo: (callBack: Function) => void;
  logout: () => void;
}

const Permissions = (props: Props): JSX.Element => {
  const { match, auth, user } = props;

  const [isUserDataFetched, setIsUserDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const appName = match.params.app;
  const displayedAppName = capitalizeString(appName);

  const stateParam = getUrlParameter("state");
  const clientIdParam = getUrlParameter("client_id");
  const redirectURIParam = getUrlParameter("redirect_uri");

  const queryString = getAllQueryParams();

  useEffect(() => {
    if (!user.isUserDataFetched) {
      props.fetchCurrentUserInfo(() => setIsUserDataFetched(true));
    }
  }, []);

  const handleAuthorize = () => {
    setIsLoading(true);
    createOauth2GrantAsync(auth.accessToken, {
      client_id: clientIdParam,
      redirect_uri: redirectURIParam,
    })
      .then((response) => {
        fireAnalyticsEvent.fireCrisp(CrispEvents.authorizeApp, {
          user: user.data.email,
          app: displayedAppName,
        });
        const code = response.data.code;
        const codeVerifier = response.data.code_verifier;
        window.location.href = `${redirectURIParam}?code=${code}&code_verifier=${codeVerifier}&state=${stateParam}`;
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error.response.data);
        openNotification(
          "Something went wrong...",
          "An error has occurred. Please reload the page and try again. If it doesn't help, contact us.",
          "OK",
          "error"
        );
        fireAnalyticsEvent.fireCrisp(
          CrispEvents.authorizeAppError,
          {
            user: user.data.email,
            app: displayedAppName,
            status: error.response.status,
            message: trimStringTo(error.response.data, 120),
          },
          true
        );
      });
  };

  const handleLogout = () => {
    props.logout();
  };

  const handleCancel = () => {
    window.close();
  };

  if (!Object.values(Oauth2Apps).includes(appName)) {
    return <Redirect to={`/404`} />;
  }

  if (!auth.accessToken) {
    return <Redirect to={`/auth/login${queryString}`} />;
  }

  if (!isUserDataFetched) {
    return (
      <div className="permissions">
        <SpinnerBox />
      </div>
    );
  }

  return (
    <div className="permissions">
      <Empty
        className="permissions__info"
        image={urls.assets.oauth2.zapier}
        description={
          <span className="permissions__description">
            <span className="permissions__title">
              Grant {displayedAppName} full access
            </span>
            <span className="permissions__text">
              <span className="permissions__paragraph">
                An external application <b>{displayedAppName}</b> wants to
                access your Unicorn&nbsp;Platform account{" "}
                <b>{user.data.email}</b>. <a onClick={handleLogout}>Not you?</a>
              </span>
              <span className="permissions__paragraph">
                You can revoke access at any time in your Connections settings.
              </span>
            </span>
          </span>
        }
      >
        <div className="permissions__buttons-container">
          <div className="permissions__buttons">
            <Button
              type="primary"
              loading={isLoading}
              onClick={handleAuthorize}
            >
              Authorize&nbsp;{displayedAppName}
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </div>
        </div>
      </Empty>
    </div>
  );
};

const mapStateToProps = ({ auth, user }) => {
  return { auth, user };
};
const mapActionsToProps = {
  fetchCurrentUserInfo,
  logout,
};
export default connect(mapStateToProps, mapActionsToProps)(Permissions);
