import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { MetaState } from "../../store/meta/types";
import _ from "lodash";
import ChangelogModal from "./ChangelogModal";

interface Props {
  meta: MetaState;
}

const ChangelogBannerNew = (props: Props): JSX.Element => {
  const { meta } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const bannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (window as any).uniOpenChangelogModal = () => {
      setIsModalVisible(true);
    };
    (window as any).uniCloseChangelogBanner = () => {
      if (bannerRef.current) {
        bannerRef.current.style.display = "none";
        const bannerId = _.get(meta, "items.0.changelog_banner_id");
        localStorage.setItem("bannerId", bannerId);
      }
    };
  });

  const closeModal = () => {
    setIsModalVisible(false);
  };

  if (!meta.dataFetched) return null;
  const bannerHtml = _.get(meta, "items.0.changelog_banner_html");
  if (!bannerHtml) return null;
  const bannerId = _.get(meta, "items.0.changelog_banner_id");
  if (!bannerId) return null;
  if (localStorage.getItem("bannerId") === bannerId.toString()) return null;

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: bannerHtml,
        }}
        ref={bannerRef}
        id="uni-banner-wrapper"
        data-banner-id={bannerId}
      />
      <ChangelogModal
        isModalVisible={isModalVisible}
        handleCancel={() => {
          closeModal();
        }}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  meta: state.meta,
});
export default connect(mapStateToProps, {})(ChangelogBannerNew);
