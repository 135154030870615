import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import axios from "axios";

export const fetchConnectedIntegrationsAsync = (
  accessToken: string,
  websiteId: number
): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.websites.fetchWebsites}?id=${websiteId}`, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        const website = response.data[0];
        resolve(website.connected_form_integrations);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
