import React from "react";
import { connect } from "react-redux";
import {
  getCurrentStoreData,
  savePartialWebsiteData,
} from "../../../store/websites/thunks";
import {
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
  editCustomStyles,
} from "../../../store/websites/actions";
import _ from "lodash";
import Item from "./components/Item";
import { StyleMenuProps } from "./components/types";
import "./StyleMenu.scss";

const Example = (): JSX.Element => {
  return (
    <div className="comps">
      <div
        className="style-blue-1 checkered-bg checkered-bg--no-hover has-opacity style-menu__preview"
        style={{
          padding: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: 350,
        }}
      >
        <a
          className="button button--accent-bg"
          href="javascript:void(0);"
          style={{ zIndex: 100 }}
        >
          <span className="button__text">Example button</span>
        </a>
      </div>
    </div>
  );
};

const ButtonStyleMenu = (props: StyleMenuProps): JSX.Element => {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Example />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <Item
          inputType="number"
          placeholder="4"
          customStyleProperty="buttonRadius"
          tooltip="Change the border radius of each button."
          presets={[
            { value: "0", description: "Sharp" },
            { value: "4", description: "Default" },
            { value: "10", description: "Round" },
            { value: "999", description: "Circle" },
          ]}
          {...props}
        >
          Radius
        </Item>
        <Item
          inputType="number"
          customStyleProperty="borderSize"
          placeholder="2"
          max={99}
          tooltip="Change the border thickness of each button."
          presets={[
            { value: "0", description: "No border" },
            { value: "1", description: "Thin" },
            { value: "2", description: "Default" },
            { value: "3", description: "Thick" },
          ]}
          {...props}
        >
          Border size
        </Item>
        <Item
          inputType="number"
          placeholder="20"
          customStyleProperty="buttonHorizontalPadding"
          max={99}
          tooltip="Change the horizontal padding of each button."
          presets={[
            { value: "8", description: "Narrow" },
            { value: "20", description: "Default" },
            { value: "32", description: "Wide" },
          ]}
          {...props}
        >
          Horizontal padding
        </Item>
        <Item
          inputType="number"
          placeholder="12"
          customStyleProperty="buttonVerticalPadding"
          max={99}
          tooltip="Change the vertical padding of each button."
          presets={[
            { value: "4", description: "Short" },
            { value: "12", description: "Default" },
            { value: "16", description: "Tall" },
          ]}
          {...props}
        >
          Vertical padding
        </Item>
        <Item
          inputType="number"
          placeholder="14"
          customStyleProperty="buttonFontSize"
          max={99}
          tooltip="Change the font size of each button. It will auto-scale for some button types to maintain proportions."
          presets={[
            { value: "12", description: "Small" },
            { value: "14", description: "Default" },
            { value: "16", description: "Large" },
          ]}
          {...props}
        >
          Font size
        </Item>
        <Item
          inputType="number"
          placeholder="700"
          customStyleProperty="buttonFontWeight"
          max={999}
          tooltip="Change the font weight of each button."
          presets={[
            { value: "400", description: "Normal" },
            { value: "700", description: "Default" },
            { value: "900", description: "Heavy" },
          ]}
          {...props}
        >
          Font weight
        </Item>
        <Item
          inputType="dropdown"
          customStyleProperty="buttonShadow"
          tooltip="Change the shadow style of each button."
          {...props}
        >
          Shadow
        </Item>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    websites: state.websites,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  editCustomStyles,
  savePartialWebsiteData,
  changeWebsitesStateGlobalData,
  changeWebsiteItemGlobalData,
})(ButtonStyleMenu);
