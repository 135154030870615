export const checkIsFormOld = (form:any) => {
    /*
            Important: we need to somehow detect old email only forms.
            When we added complex forms (many inputs) there was some new additions to the form object.
            One of such additions: settings.jsCodeOnSuccess
            The jsCodeOnSuccess is a new attribute and is added to all new forms.
            Old forms has settings.jsCodeOnSuccess === undefined.
            */

    return form.settings["jsCodeOnSuccess"] === undefined;
};