export const generatePriceString = (planPrice: number, planPeriod: string) => {
  let price = planPrice;
  let period = "month";

  if (planPeriod === "yearly") {
    price *= 12;
    period = "year";
  }

  return `${price}$/${period}`;
};
