export const checkIfCustomColorsActive = (currentWebsite:any) => {
    // If user sets color override, this is true.

    let ifCustomColorsActive = false;

    if(currentWebsite && currentWebsite.custom_color_palette){
        if(currentWebsite.custom_color_palette.isCustomPaletteActivated === true){
            ifCustomColorsActive = true;
        }
    }

    return (ifCustomColorsActive);
};