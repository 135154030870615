import { updateNavSubmenuLinks } from "./updateNavSubmenuLinks";
import { updateNavTopLevelLinks } from "./updateNavTopLevelLinks";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";

export const updateNavLinks = (componentData, completionContent) => {
  const content = componentData.navLinks.content;
  if (!("NAVIGATION_LINKS" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.NAVIGATION_LINKS)) {
    if (completionContent.NAVIGATION_LINKS === null) {
      content.topLevelItems = [];
      content.dropdownItems = {};
    }
    return;
  }
  updateNavTopLevelLinks(componentData, completionContent.NAVIGATION_LINKS);
  completionContent.NAVIGATION_LINKS.forEach((modifiedTopLevelItem) => {
    if (!("SUBMENU_ITEMS" in modifiedTopLevelItem)) {
      return;
    }
    const originalTopLevelItem = getOriginalItem(
      content.topLevelItems,
      modifiedTopLevelItem.READONLY_ID
    );
    if (!originalTopLevelItem) return;
    if (!Array.isArray(modifiedTopLevelItem.SUBMENU_ITEMS)) {
      if (modifiedTopLevelItem.SUBMENU_ITEMS === null) {
        delete content.dropdownItems[originalTopLevelItem.id];
      }
      return;
    }
    if (modifiedTopLevelItem.SUBMENU_ITEMS.length === 0) return;
    updateNavSubmenuLinks(
      componentData,
      modifiedTopLevelItem.SUBMENU_ITEMS,
      originalTopLevelItem
    );
  });
};
