export const getErrorKey = (errorData:any) => {
    try{
        if(errorData !== undefined){
            let key = Object.keys(errorData)[0]; //server returns only one error reason, so the error object has only 1 key:value pair;
            return key;
        }else{
            return undefined;
        }
    }catch (e) {
        return undefined;
    }
};