import { formatHex } from "./formatHex";

// https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
function getContrast(hexcolor: string) {
  hexcolor = formatHex(hexcolor);
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  // true is the color is bright, false if the color is dark
  return yiq >= 135 ? true : false;
}

export default getContrast;
