import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import ContentInputsTypesEnum from "../../enums/ContentInputsTypesEnum";
import _ from "lodash";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import GraphicsJSX from "../GraphicsJSX";
import { checkIfSlideVisible } from "../../helpers/checkIfSlideVisible";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Slider01 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({ titleText: "The New Way to Inspire",isDefaultEmpty })
      );
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
      this.props.saveContentInStore(
        this.generator.setUpGallery({
          isDefaultEmpty,
          maxItems: 99,
          fieldInfo: {
            default: "Recommended format: 1440x900px. All the same size.",
          },
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a button to the bottom of the slider component to invite users to read more about the project.",
            default:
              "Add a button to the bottom of the slider component to invite users to read more about the project.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "Learn more",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let gallery = this.props.componentItem.componentData.gallery.content.items;

    let settings = {
      dots: true,
      infinite: true,
      speed: 300,
      fade: false,
      cssEase: "ease",
      adaptiveHeight: false,
      // lazyLoad: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    const currentActiveOption = _.get(
      this.props.componentItem,
      ["componentData", ContentInputsTypesEnum.gallery, "activeOption"],
      GraphicsOptions.image
    );

    return (
      <section>
        <div
          className={`slider-01 ${
            gallery.length > 1 ? "slider_with_dots_inside" : ""
          } graphics-${currentActiveOption} default-graphics-${
            GraphicsOptions.image
          }`}
        >
          <TitleBox 
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />

          <div className="container slider-01__container">
            <div className="slider">
              <Slider {...settings}>
                {gallery &&
                  gallery.map((item: any, key: number) => {
                    const videoUrl = _.get(item, [
                      "contentGraphic",
                      0,
                      GraphicsOptions.video,
                      "youtube",
                      "url",
                    ]);
                    const codeSnippet = _.get(item, [
                      "contentGraphic",
                      0,
                      GraphicsOptions.HTML,
                      "codeSnippet",
                    ]);

                    if (checkIfSlideVisible(item, currentActiveOption)) {
                      return (
                        <li className="slider__item" key={"slider-item-" + key}>
                          <GraphicsJSX
                            currentActiveOption={currentActiveOption}
                            defaultGraphicsOption={GraphicsOptions.image}
                            classNames={{
                              image: `slider__img`,
                              video: "slider-01__video",
                              HTML: "slider-01__HTML slider-01__editor-HTML",
                            }}
                            isDarkBg={this.props.isDarkBg}
                            imageUrl={item.contentGraphic[0].image.url}
                            videoUrl={videoUrl}
                            codeSnippet={codeSnippet}
                            permanentSpinnerColor
                          />
                        </li>
                      );
                    } else {
                      return null;
                    }
                  })}
              </Slider>
            </div>

            <div className="bottom_cta">
              {this.props.componentItem.componentData.cta &&
                <WebsiteCTA componentItem={this.props.componentItem} />}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Slider01;
