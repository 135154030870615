import {indexWebsitePageDashboardUrl} from "../data/constants";
import {getCurrentWebsiteSubdomain} from "./getCurrentWebsiteSubdomain";

export const getCurrentWebsite = (websitesArray: any) => {
    if(websitesArray === undefined) {
        return undefined
    }
    let currentSubdomain = getCurrentWebsiteSubdomain(websitesArray); // Get current subdomain from the URL in a browser.
    let websiteWithThisSubdomain = websitesArray.find((item:any) => {
        return (item.subdomain === currentSubdomain)
    });

    if(websiteWithThisSubdomain === undefined) {
        return undefined
    }else{
        return websiteWithThisSubdomain;
    }
};