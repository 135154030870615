import _ from "lodash";
import { UnicornComponent } from "../../../../classes/UnicornComponent";
import { DEFAULT_IMAGES_CDN, companyLogos, publicUrl } from "../../../../data/urls";
import { Components } from "../../../../enums/Components";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { validateHex } from "../../../../helpers/editor/validateHex";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { checkIfEmptyValue } from "./updaterFunctions.ts/checkIfEmptyValue";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  const itemText = stringifyObjectsAndNumbers(modifiedItem.TEXT) || "";
  const itemSubtitle = stringifyObjectsAndNumbers(modifiedItem.SUBTITLE) || "";
  const itemButtonText =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TEXT"])) || "";
  const itemButtonUrl =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "URL"])) || "";
  const itemButtonTarget =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TARGET"])) ||
    "_self";
  const itemUrl = stringifyObjectsAndNumbers(modifiedItem.URL) || "";
  const itemTarget = stringifyObjectsAndNumbers(modifiedItem.TARGET) || "_self";
  const itemIsHighlighted = modifiedItem.IS_HIGHLIGHTED === true;
  let itemBgColor =
    stringifyObjectsAndNumbers(modifiedItem.BACKGROUND_COLOR) || "#ffffff";
  if (!validateHex(itemBgColor)) {
    itemBgColor = "#ffffff";
  }
  let itemLogoHeight = modifiedItem.LOGO_HEIGHT || 0;
  if (
    typeof itemLogoHeight !== "number" ||
    isNaN(itemLogoHeight) ||
    itemLogoHeight < 5
  ) {
    itemLogoHeight = 5;
  }
  if (itemLogoHeight > 100) {
    itemLogoHeight = 100;
  }
  return {
    itemId,
    itemPosition,
    itemTitle,
    itemText,
    itemSubtitle,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
    itemUrl,
    itemTarget,
    itemIsHighlighted,
    itemBgColor,
    itemLogoHeight,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemTitle,
  itemText,
  itemSubtitle,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget,
  itemUrl,
  itemTarget,
  itemIsHighlighted,
  itemBgColor,
  itemLogoHeight
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.posts.content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.posts.content.items, item);
      }
    });
  }
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("TEXT" in modifiedItem) {
    originalItem.text = itemText;
  }
  if ("SUBTITLE" in modifiedItem) {
    originalItem.subtitle = itemSubtitle;
  }
  if ("URL" in modifiedItem) {
    originalItem.href = itemUrl;
  }
  if ("TARGET" in modifiedItem) {
    originalItem.isTargetBlank = itemTarget === "_blank";
  }
  if ("IS_HIGHLIGHTED" in modifiedItem) {
    originalItem.isHighlighted = itemIsHighlighted;
  }
  if ("BACKGROUND_COLOR" in modifiedItem) {
    originalItem.bgColor = itemBgColor;
  }
  if ("LOGO_HEIGHT" in modifiedItem) {
    originalItem.logo.height = itemLogoHeight;
  }
  if ("BUTTON" in modifiedItem) {
    if (modifiedItem.BUTTON === null) {
      originalItem.button.title = "";
      originalItem.button.href = "";
      originalItem.button.isTargetBlank = false;
      return;
    }
    if ("TEXT" in modifiedItem.BUTTON) {
      originalItem.button.title = itemButtonText;
    }
    if ("URL" in modifiedItem.BUTTON) {
      originalItem.button.href = itemButtonUrl;
    }
    if ("TARGET" in modifiedItem.BUTTON) {
      originalItem.button.isTargetBlank = itemButtonTarget === "_blank";
    }
  }
};

export const updatePosts = (
  componentData,
  completionContent,
  currentComponentInfo: UnicornComponent
) => {
  if (!("CARDS" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.CARDS)) {
    if (completionContent.CARDS === null) {
      componentData.posts.content.items = [];
    }
    return;
  }
  if (currentComponentInfo.name === Components.posts04) {
    componentData.posts.content.items = deleteEmptyItems(
      componentData.posts.content.items,
      "href"
    );
  }
  if (currentComponentInfo.name === Components.links01) {
    componentData.posts.content.items = deleteEmptyItems(
      componentData.posts.content.items,
      "href"
    );
  }
  if (currentComponentInfo.name === Components.photos01) {
    componentData.posts.content.items = componentData.posts.content.items.filter(
      (item) => {
        const isLookupFieldEmpty = checkIfEmptyValue(item.thumb.image.url);
        return !isLookupFieldEmpty || item.IS_BEING_MODIFIED_BY_AI;
      }
    );
  }
  addDefaultValuesToItems(componentData.posts.content.items);
  completionContent.CARDS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemTitle,
      itemText,
      itemSubtitle,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget,
      itemUrl,
      itemTarget,
      itemIsHighlighted,
      itemBgColor,
      itemLogoHeight,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      componentData.posts.content.items = deleteItem(
        componentData.posts.content.items,
        itemId
      );
      return;
    }
    const isItemNew = getIsItemNew(componentData.posts.content.items, itemId);
    if (isItemNew) {
      const newItem = generator.createPostsContentObject();
      newItem.title = "";
      newItem.subtitle = "";
      newItem.href = "#";
      newItem.text = "";
      newItem.bgColor = "#ffffff";
      let thumbnail = DEFAULT_IMAGES_CDN.UNICORN_AI;
      newItem.thumb.image.width = 512;
      newItem.thumb.image.height = 512;
      if (currentComponentInfo.name === Components.press03) {
        thumbnail =
          publicUrl + companyLogos.prefix + "apple.svg";
        newItem.thumb.image.width = 31;
        newItem.thumb.image.height = 35;
      }
      newItem.thumb.image.url = thumbnail;
      if (
        currentComponentInfo.name === Components.press02 ||
        currentComponentInfo.name === Components.testimonials01 ||
        currentComponentInfo.name === Components.testimonials02
      ) {
        newItem.logo.uploadedSrc =
          publicUrl + companyLogos.prefix + "apple.svg";
        newItem.logo.height = 30;
      }
      addNewItem(
        componentData.posts.content.items,
        newItem,
        itemId,
        itemPosition
      );
    }
    const originalItem = getOriginalItem(
      componentData.posts.content.items,
      itemId
    );
    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemTitle,
      itemText,
      itemSubtitle,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget,
      itemUrl,
      itemTarget,
      itemIsHighlighted,
      itemBgColor,
      itemLogoHeight
    );
  });
  sortItemsByPosition(componentData.posts.content.items);
};
