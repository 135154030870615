import React, {Component} from "react";
import {Select, Button, Icon, Switch, Tooltip} from "antd";
import "./PublishPages.css";
import SpinnerBox from "./SpinnerBox";
import {indexWebsitePageDashboardUrl, projectUrl} from "../../data/constants";
import NoTitleWarning from "./NoTitleWarning";
import trimStringTo from "../../helpers/strings/trimStringTo";
import {getWebsiteProtocol} from "../../helpers/getWebsiteProtocol";

interface Props {
    auth: any,
    currentWebsite: any,
    currentBlog: any,
    currentBlogPosts: any,
    toggleBlogPostChangeInProgress: any,
    blogPosts: any,
    blogs: any,
    saveBlogPostDataInStore: any,
    publishPost: any
}

class PublishPosts extends Component<Props>{


    handleChangePostStatus = (status, currentWebsite,  currentBlog, currentBlogPost) => {
      this.props.publishPost(status, currentWebsite, currentBlog, currentBlogPost)
    }

    displayBlogPostsSwitches = () => {
        let displayPostTitle = (item:any) => {
            let prefix = (<span className="publish-pages__page_title_prefix">/{trimStringTo(item.url, 20)}</span>);

            if (item.title.length > 0){
                let formattedTitle = item.title;
                let isTitleTooLong = false;
                if (item.title.length > 50){
                    formattedTitle = formattedTitle.slice(0, 47);
                    isTitleTooLong = true;
                }

                return (<span>{prefix} <span className={isTitleTooLong ? 'publish-pages__big_title' : ''}>{formattedTitle}</span></span>)
            }else{
                return (<NoTitleWarning prefix={prefix}/>)
            }
        };
        const { Option } = Select;
        const blogPostsSwitches = this.props.currentBlogPosts.map((postItem:any, key:number) =>
            {
                let getCurrentBlogPostUrl = (blogPostUrl:string) => {
                    let currentBlogPostUrl = '';
                    if(this.props.currentWebsite.is_custom_domain_active){
                        let websiteProtocol = getWebsiteProtocol(this.props.currentWebsite);
                        currentBlogPostUrl = websiteProtocol + this.props.currentWebsite.custom_domain + '/' + this.props.currentBlog.url + '/' + blogPostUrl;
                    }else{
                        currentBlogPostUrl = 'https://' + this.props.currentWebsite.subdomain + '.' + projectUrl + '/' + this.props.currentBlog.url + '/' + blogPostUrl;
                    }

                    return currentBlogPostUrl;
                };

                return (
                    <li key={key} className="publish-pages__switch_item">
                        <Select value={postItem.status} style={{ width: 120 }} onChange={ value => this.handleChangePostStatus(value, this.props.currentWebsite, this.props.currentBlog, postItem)}>
                          <Option value="public">Public</Option>
                          <Option value="draft">Draft</Option>
                          <Option value="direct_url">Direct URL</Option>
                        </Select>
                        <div className="publish-pages__page_title">
                            <div className="publish-pages__page_title_text"> 
                                {displayPostTitle(postItem)}
                                <a hidden={this.props.blogPosts.postChangeInProgress} title="View the post in a new tab." href={getCurrentBlogPostUrl(postItem.url)} target="_blank" className="publish-pages__link">
                                    <Icon type="link" />
                                </a>
                            </div>
                        </div>
                    </li>
                )
            }
        );


        if (this.props.blogPosts.allPostsWithoutContentFetched === false){
            return (<SpinnerBox text={'Posts are loading...'}/>)
        }else{
            return blogPostsSwitches;
        }
    };

    render(){
        return (
            <div className="publish-pages">
                {/*Todo: */}
                <ul className="publish-pages__switches_list">
                    {this.displayBlogPostsSwitches()}
                </ul>
            </div>
        )
    }
}

export default PublishPosts;
