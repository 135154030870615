import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import MockupWithContent from "../../components/MockupWithContent";
import _ from "lodash";
import ContentInputsTypesEnum from "../../enums/ContentInputsTypesEnum";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import { checkIfSlideVisible } from "../../helpers/checkIfSlideVisible";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteFeature from "../component_molecules/WebsiteFeature";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Slider10 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({ titleText: "The New Way to Inspire",isDefaultEmpty })
      );
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
      this.props.saveContentInStore(
        this.generator.setUpGallery({
          isDefaultEmpty,
          maxItems: 99,
          fieldInfo: { default: "" },
          items: [
            this.generator.createGalleryContentObject({
              contentGraphic: [
                this.generator.createGraphicsContentObject({
                  imageUrl: DEFAULT_IMAGES_CDN.UI_MOBILE_DASHBOARD,
                  videoUrl:
                    "https://up-blog-prod.s3.amazonaws.com/waterfall_compressed.mp4",
                  width: 412,
                  height: 892,
                }),
              ],
            }),
            this.generator.createGalleryContentObject({
              contentGraphic: [
                this.generator.createGraphicsContentObject({
                  imageUrl: DEFAULT_IMAGES_CDN.UI_MOBILE_PROFILE,
                  videoUrl:
                    "https://up-blog-prod.s3.amazonaws.com/neon_compressed.mp4",
                  width: 412,
                  height: 892,
                }),
              ],
            }),
            this.generator.createGalleryContentObject({
              contentGraphic: [
                this.generator.createGraphicsContentObject({
                  imageUrl: DEFAULT_IMAGES_CDN.UI_MOBILE_ECOMMERCE,
                  videoUrl:
                    "https://up-blog-prod.s3.amazonaws.com/sunset_compressed.mp4",
                  width: 412,
                  height: 892,
                }),
              ],
            }),
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpMockups({
          items: [
            this.generator.createMockupsContentObject({
              type: "mobile",
              deviceId: "iphone_11_pro_max",
              optionId: "space_grey",
              fileName: "apple_iphone_11_pro_max_space_grey.png",
              humanTitle: "iPhone 11 Pro Max",
            }),
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpFeatures({
          isDefaultEmpty,
          maxItems: 6,
          priority: 300,
          items: [
            this.generator.createFeatureContentObject({
              title: "Powerful Sync",
            }),
            this.generator.createFeatureContentObject({
              title: "Authentic Design",
              button: this.generator.createButtonContentObject({
                buttonType: linkOrButtonOptions.link,
                buttonTitle: "Learn why",
                buttonHref: "/",
                buttonStyleType: buttonStyles.ghost.typeTitle,
                ghostColor: buttonStyles.ghost.accentOutline,
              }),
            }),
            this.generator.createFeatureContentObject({ title: "" }),
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a button to the bottom of the slider component to invite users to read more about the app.",
            default:
              "Add a button to the bottom of the slider component to invite users to read more about the app.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "Learn more",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  componentDidMount(): void {}

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let gallery = this.props.componentItem.componentData.gallery.content.items;
    let features = this.props.componentItem.componentData.features.content
      .items;

    // let graphicsObject = this.props.componentItem.componentData.graphics.content.items[0]; //This component has exactly one item and more can not be added (see createGraphicsContentObject() above).

    let mockup = displayTitleMarkupText(
      this.props.componentItem.componentData.mockups.content.items[0]
    );

    let settings = {
      dots: false,
      infinite: true,
      speed: 300,
      fade: true,
      cssEase: "ease-out",
      adaptiveHeight: false,
      centerMode: true,
      variableWidth: false,
      slidesToShow: 1,
      // 'lazyLoad': 'ondemand',
      focusOnSelect: false,
      arrows: true,
      responsive: [
        { breakpoint: 440, settings: { autoplay: true, autoplaySpeed: 1400 } },
      ],
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    const currentActiveOption = _.get(
      this.props.componentItem,
      ["componentData", ContentInputsTypesEnum.gallery, "activeOption"],
      GraphicsOptions.image
    );

    return (
      <section>
        <div
          className={`slider-10 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image}`}
        >
          <TitleBox
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />

          <div className="container slider-10__wrapper">
            <div className="slider-10__slider_container">
              <div className="slider slider-10__slider">
                <Slider {...settings}>
                  {gallery &&
                    gallery.map((item: any, key: number) => {
                      const videoUrl = _.get(item, [
                        "contentGraphic",
                        0,
                        GraphicsOptions.video,
                        "youtube",
                        "url",
                      ]);
                      const codeSnippet = _.get(item, [
                        "contentGraphic",
                        0,
                        GraphicsOptions.HTML,
                        "codeSnippet",
                      ]);

                      if (checkIfSlideVisible(item, currentActiveOption)) {
                        return (
                          <li
                            className="slider-10__item slider__item"
                            key={"slider-10-item-" + key}
                          >
                            <div className="slider-10__img">
                              <MockupWithContent
                                mockupObject={mockup}
                                graphicsObject={item.contentGraphic[0]}
                                isDarkBg={this.props.isDarkBg}
                                currentActiveOption={currentActiveOption}
                                HTMLClassNames="graphics-HTML__editor-code_mobile-mockup slider-10__editor-HTML"
                              />
                            </div>
                          </li>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Slider>
              </div>

              {/*<div className="slider-10__mockup_container">*/}
              {/*</div>*/}
            </div>

            <ul className="slider-10__text_container">
              {features &&
                features.map((item: any, key: number) => {
                  return (
                    <li
                      className="slider-10__feature"
                      key={"slider-feature-10-" + key}
                    >
                      <WebsiteFeature
                        featureObject={item}
                        isWhite={this.props.isDarkBg}
                      />
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className="bottom_cta">
            {this.props.componentItem.componentData.cta && (
              <WebsiteCTA componentItem={this.props.componentItem} />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Slider10;
