import React, { Component } from "react";
import './BackgroundImagePicker.css'
import { logoOptions } from "../../../data/logoOptions";
import ImagePicker from "../../editor/ImagePicker";
import { defaultBgImageOverlayOpacity } from "../../../data/constants";
import BackgroundTransparencySlider from "./BackgroundTransparencySlider";

interface Props {
  componentItem: any,
  currentWebsite: any,
  toggleNewEditionsDetected: any,
  currentComponentBackgroundOptions: any,

  saveImageInfoInDB: any,
  removeImageFromDB: any,
  saveComponentBackgroundImageInStore: any,
  saveComponentBackgroundImageOverlayOpacityInStore: any,
}

class BackgroundImagePicker extends Component<Props>{
  handleChangeBgImageTransparency(value: number) {
    this.props.toggleNewEditionsDetected(true);
    this.props.saveComponentBackgroundImageOverlayOpacityInStore(value);
  };
  render() {
    //First, get the initial values
    let currentBackgroundImageUrl = ''; //'' not undefined by default because ImagePicker wants a string and we indicate 'no image' state with an empty string

    // See the object structure here: unicornplatform/frontend/src/store/websitePages/reducers.ts: newComponent
    if (this.props.componentItem && this.props.componentItem.settings && this.props.componentItem.settings.background) {
      if (this.props.componentItem.settings.background.imageUrl) {
        currentBackgroundImageUrl = this.props.componentItem.settings.background.imageUrl;
      }
    }

    let currentBackgroundImageUUID = undefined;
    if (this.props.componentItem && this.props.componentItem.settings && this.props.componentItem.settings.background) {
      if (this.props.componentItem.settings.background.imageUUID) {
        currentBackgroundImageUUID = this.props.componentItem.settings.background.imageUUID;
      }
    }

    //start of Bg Image Transparency
    // also used on frontend/src/pageComponents/PageComponent.tsx
    let currentBgImageTransparencyValue = undefined;
    if (this.props.componentItem && this.props.componentItem.settings && this.props.componentItem.settings.background) {
      if (this.props.componentItem.settings.background.overlayOpacity !== undefined) {
        currentBgImageTransparencyValue = this.props.componentItem.settings.background.overlayOpacity;
      }
    }
    // end of Bg Image Transparency
    return (
      <div>
        <ImagePicker
          currentImgSrc={currentBackgroundImageUrl}
          currentImgUUID={currentBackgroundImageUUID}
          key={'bg-image-' + this.props.componentItem.id}
          inputId={'bg-image-' + this.props.componentItem.id}
          saveImageInfoInDB={this.props.saveImageInfoInDB}
          removeImageFromDB={this.props.removeImageFromDB}
          saveImageInReduxStore={
            (newSrc: string, newUUID: any) => {
              //save in state
              this.props.saveComponentBackgroundImageInStore(newSrc, newUUID);

              this.props.toggleNewEditionsDetected(true);
            }
          }
        />
        {currentBackgroundImageUrl && currentBackgroundImageUrl !== '' && <BackgroundTransparencySlider
          label="Transparency:"
          step={0.01}
          value={currentBgImageTransparencyValue === undefined ? defaultBgImageOverlayOpacity : currentBgImageTransparencyValue}
          tipFormatter={(value: number) => `${Math.round(value * 100)}%`}
          onChange={(value: any) => {
            this.handleChangeBgImageTransparency(value);
          }}
          defaultValue={30}
          min={0}
          max={1}
        />}
      </div>
    )
  }
}

export default BackgroundImagePicker;
