import { checkIfPricingTabHasCustomText } from "./checkIfPricingTabHasCustomText";

// If one of the tabs doesn't have text, we hide both of them.
// Additionally we check if the pricing object has a custom text key, because old components don't have it.
export const checkIfPricingTabsAreHidden = (pricingObject: any) => {
  return (
    (checkIfPricingTabHasCustomText(pricingObject, "left") &&
      !pricingObject.leftTabText) ||
    (checkIfPricingTabHasCustomText(pricingObject, "right") &&
      !pricingObject.rightTabText)
  );
};
