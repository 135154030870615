//kudos: https://stackoverflow.com/questions/5306680/move-an-array-element-from-one-array-position-to-another
function moveArrayItem(arr:any, old_index:number, new_index:number) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    //this function modifies the array
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export default moveArrayItem;