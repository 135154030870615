import _ from "lodash";

const particleCleanUpData = (itemsArray: any[]) => {
  if (!itemsArray) return;
  itemsArray.forEach((item) => {
    delete item.IS_BEING_MODIFIED_BY_AI;
    delete item.READONLY_ID;
    delete item.POSITION_IN_ARRAY;
    delete item.ID;
  });
};

export const cleanUpAiData = (component) => {
  const componentCopy = _.cloneDeep(component);
  const { componentData } = componentCopy;
  Object.keys(componentData).forEach((contentType) => {
    if (contentType === "cta") {
      particleCleanUpData(componentData.cta.content.buttons);
      particleCleanUpData(componentData.cta.content.form.fields.items);
    }
    if (contentType === "features") {
      particleCleanUpData(componentData.features.content.items);
    }
    if (contentType === "pricing") {
      particleCleanUpData(componentData.pricing.content.items);
      particleCleanUpData(
        componentData.pricing.content.comparisionTableHeadersSet
      );
      componentData.pricing.content.items.forEach((pricingItem) => {
        particleCleanUpData(pricingItem.comparisionTableDetailsSet);
        particleCleanUpData(pricingItem.detailsSet);
      });
    }
    if (contentType === "list") {
      particleCleanUpData(componentData.list.content.items);
      particleCleanUpData(componentData.list.content.groups);
    }
    if (contentType === "posts") {
      particleCleanUpData(componentData.posts.content.items);
    }
    if (contentType === "team") {
      particleCleanUpData(componentData.team.content.items);
    }
    if (contentType === "faq") {
      particleCleanUpData(componentData.faq.content.items);
    }
    if (contentType === "gallery") {
      particleCleanUpData(componentData.gallery.content.items);
      componentData.gallery.content.items.forEach((galleryItem) => {
        particleCleanUpData(galleryItem.contentCta.buttons);
      });
    }
    if (contentType === "logos") {
      particleCleanUpData(componentData.logos.content.items);
    }
    if (contentType === "socialLinks") {
      particleCleanUpData(componentData.socialLinks.content.items);
    }
    if (contentType === "contacts") {
      particleCleanUpData(componentData.contacts.content.items);
    }
    if (contentType === "steps") {
      particleCleanUpData(componentData.steps.content.items);
    }
    if (contentType === "navLinks") {
      particleCleanUpData(componentData.navLinks.content.topLevelItems);
      Object.keys(componentData.navLinks.content.dropdownItems).forEach(
        (topLevelId) => {
          particleCleanUpData(
            componentData.navLinks.content.dropdownItems[topLevelId]
          );
        }
      );
    }
  });
  return componentData;
};
