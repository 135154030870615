import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Posts01 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({ titleText: "In the Wild",isDefaultEmpty })
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({ subtitleText: "",isDefaultEmpty })
      );
      this.props.saveContentInStore(
        this.generator.setUpPosts({
          isDefaultEmpty,
          maxItems: 36,
          fieldInfo: {
            default: "We recommend using square or vertical images",
          },
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a button to the bottom of the posts component to invite users to read your blog.",
            default:
              "Add a button to the bottom of the posts component to invite users to read your blog.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "Read more",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let posts = this.props.componentItem.componentData.posts.content.items;

    let ctaFirstButton = this.props.componentItem.componentData.cta.content
      .buttons[0];
    let ctaFirstButtonTitle = ctaFirstButton && ctaFirstButton.title;

    return (
      <section>
        <div className="posts-01">
          <TitleBox 
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />

          <div className="container">
            <div className="posts-01__wrapper">
              {posts &&
                posts.map((item: any, key: number) => {
                  if (item !== undefined) {
                    if (item.href !== "") {
                      return (
                        <a
                          className="card-container posts-01__item sh-4 sh-has-hover"
                          key={"posts-01-" + key}
                          href={returnHref(item.href)}
                        >
                          <div className="posts-01__img_box">
                            {item.thumb.image.url && (
                              <img
                                className="posts-01__img"
                                src={formatImageUrl(item.thumb.image.url)}
                              />
                            )}
                            <div className="posts-01__text">
                              <h3 className="def-18_72 title-text--inner posts-01__title">{item.title}</h3>
                              <div
                                className="content-text posts-01__info"
                                dangerouslySetInnerHTML={{
                                  __html: item.subtitle,
                                }}
                              />
                            </div>
                          </div>
                        </a>
                      );
                    } else {
                      return (
                        <div className="sh-4 card-container posts-01__item" key={"posts-01-" + key}>
                          <div className="posts-01__img_box">
                            {item.thumb.image.url && (
                              <img
                                className="posts-01__img"
                                src={formatImageUrl(item.thumb.image.url)}
                              />
                            )}
                            <div className="posts-01__text">
                              <h3 className="def-18_72 title-text--inner posts-01__title">{item.title}</h3>
                              <div
                                className="content-text posts-01__info"
                                dangerouslySetInnerHTML={{
                                  __html: item.subtitle,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  } else {
                    return null;
                  }
                })}
            </div>

            {this.props.componentItem.componentData.cta &&
              ctaFirstButton &&
              ctaFirstButtonTitle &&
              ctaFirstButtonTitle !== "" && (
                <div className="bottom_cta">
                  <WebsiteCTA componentItem={this.props.componentItem} />
                </div>
              )}
          </div>
        </div>
      </section>
    );
  }
}

export default Posts01;
