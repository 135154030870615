export const checkIsWebsitePublished = (website: any, websitePagesArray: any) => {
    // a published website is a website with at least 1 published page
    let isWebsitePublished = false;

    if (website === undefined || websitePagesArray === undefined) {
        return false;
    }
    let currentWebsiteId = website['id']
    // iterate through all pages
    for (let index = 0; index < websitePagesArray.length; index++) {
        let currentWebsitePageWebsiteId = websitePagesArray[index]['website'];
        // see if this page belongs to this website and is published
        // if yes, the website is published
        if (currentWebsitePageWebsiteId === currentWebsiteId && websitePagesArray[index]['is_published'] === true) {
            isWebsitePublished = true;
            break;
        }
    }
    return isWebsitePublished;
};