import { Icon } from "antd";
import React from "react";
import { urls } from "../../../data/urls";

interface Props {
  currentFormIntegrationHumanTitle: string;
}

const IntegrationNotAvailable = (props: Props): JSX.Element => {
  const { currentFormIntegrationHumanTitle } = props;

  return (
    <div>
      <h2>On its way...</h2>
      <div className="page-component__integration_not_ready">
        <Icon
          style={{ fontSize: 30, marginBottom: 15 }}
          type="meh"
          theme="twoTone"
        />{" "}
        <br />
        <span>
          Not ready yet, sorry. <br />
          Check out the{" "}
          <a target="_blank" href={urls.roadmap}>
            Trello roadmap
          </a>{" "}
          and vote to get the {currentFormIntegrationHumanTitle} integration
          here faster.
        </span>
      </div>
    </div>
  );
};

export default IntegrationNotAvailable;
