import {lightboxActivationWidth} from "../../data/constants";

function getLightboxClassname(width:any):string {
    // If image is big, add the special js- classname to it that initiates the lightbox plugin. No need to enlarge small images.

    let lightBoxClassname = '';
    let imageWidth = parseInt(width, 10);
    if(imageWidth > lightboxActivationWidth){
        lightBoxClassname = ' js-lightbox-single-image ';
    }
    return lightBoxClassname;
}

export default getLightboxClassname;