import React, { useState } from "react";
import { Popconfirm } from "antd";
import classNames from "classnames";
import getContrast from "../../../helpers/editor/getContrast";
import _ from "lodash";
import { validateHex } from "../../../helpers/editor/validateHex";
import CustomBackgroundColorInput from "./CustomBackgroundColorInput";
import { getPathToCustomBgColorData } from "../../../helpers/getPathToCustomBgColorData";
import { CustomColorData } from "../../../helpers/types/globalTypes";
import { formatHex } from "../../../helpers/editor/formatHex";

interface Props {
  currentBackgroundColor: string;
  componentItem: any;
  isFooter: boolean;

  saveComponentBackgroundColorInStore: (
    color: string,
    customColorData?: CustomColorData
  ) => void;
  toggleNewEditionsDetected: (payload: boolean) => void;
}
const CustomBackgroundColorButton = (props: Props): JSX.Element => {
  const {
    saveComponentBackgroundColorInStore,
    currentBackgroundColor,
    toggleNewEditionsDetected,
    componentItem,
    isFooter,
  } = props;
  let pathToColor = getPathToCustomBgColorData(isFooter, "color");
  const [currentColor, setCurrentColor] = useState<string>(
    _.get(componentItem, pathToColor, "")
  );
  const [isPopconfirmOpened, setIsPopconfirmOpened] = useState(false);

  const restoreColor = () => {
    setCurrentColor(_.get(componentItem, pathToColor, ""));
  };

  const handleConfirm = () => {
    let color = formatHex(currentColor);
    if (!validateHex(color)) {
      restoreColor();
      setIsPopconfirmOpened(false);
      return;
    }
    setCurrentColor(color);
    saveComponentBackgroundColorInStore("custom", {
      color,
      isActive: true,
      isDark: !getContrast(color),
    });
    toggleNewEditionsDetected(true);
    setIsPopconfirmOpened(false);
  };

  const handleCancel = () => {
    restoreColor();
    setIsPopconfirmOpened(false);
  };

  const handleOnVisibleChange = (visible: boolean) => {
    if (!visible) {
      restoreColor();
    }
    setIsPopconfirmOpened(visible);
  };

  return (
    <Popconfirm
      overlayClassName="background-color-picker__popconfirm"
      icon={null}
      title={
        <CustomBackgroundColorInput
          currentColor={currentColor}
          setCurrentColor={setCurrentColor}
          isPopconfirmOpened={isPopconfirmOpened}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        />
      }
      placement="topRight"
      okText="Apply"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      onVisibleChange={handleOnVisibleChange}
      visible={isPopconfirmOpened}
    >
      <div className="background-color-picker__tooltip-frame">
        <button
          className={classNames("background-color-picker__item", {
            "background-color-picker__item--small":
              currentBackgroundColor === "custom",
          })}
        >
          <span className="background-color-picker__circle background-color-picker__custom"></span>
        </button>
      </div>
    </Popconfirm>
  );
};

export default CustomBackgroundColorButton;
