import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type CloneWebsiteAsync = (payload: {
  accessToken: string;
  subdomain: string;
}) => Promise<AxiosResponse<any>>;

export const cloneWebsiteAsync: CloneWebsiteAsync = ({
  accessToken,
  subdomain,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${api.websites.cloneWebsite.prefix}${subdomain}${api.websites.cloneWebsite.postfix}`;
    axios
      .post(apiUrl, {}, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
