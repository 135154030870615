import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { pictogramOptions } from "../../data/pictogramOptions";
import { getCustomBackgroundColorData } from "../../helpers/getCustomBackgroundColorData";
import { displayMarkupText } from "../../helpers/content_displayers/displayMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteCTA from "../component_molecules/WebsiteCTA";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  displayButtons: any;
  isDarkBg: boolean;
}

class Text01 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;
      this.props.saveContentInStore(
        this.generator.setUpText({
          priority: 300,
          items: [
            {
              markup:
              isDefaultEmpty ? "" : "Why does Unicorn Platform rock? Because it’s a landing page builder focused on a small target audience - startups and makers.",
            },
          ],
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "Learn more about the mission",
              buttonHref: "/product",
              buttonStyleType: buttonStyles.pill.typeTitle,
              buttonColor: buttonStyles.pill.white,
              buttonIsTargetBlank: false,
              pillPrefix: "New",
            }),

            // ghost object
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              buttonColor: buttonStyles.pill.white,
              buttonIsTargetBlank: false,
              pillPrefix: "View",
            }),
          ],
          priority: 200,
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpPictograms({
          priority: 500,
          items: [
            {
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f64c.svg",
              }),
            },
          ],
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts

    // In this Text component there is only one text element.
    let text = displayMarkupText(
      this.props.componentItem.componentData.text.content.items[0].markup
    );

    let pictogram = this.props.componentItem.componentData.pictograms.content
      .items[0].pictogram;

    let ctaFirstButton = this.props.componentItem.componentData.cta.content
      .buttons[0];
    let ctaFirstButtonHref = ctaFirstButton && ctaFirstButton.href;

    const customBackgroundColorData = getCustomBackgroundColorData(
      this.props.componentItem
    );

    let styleAttr: React.CSSProperties = {};
    if (customBackgroundColorData.isActive) {
      styleAttr = {
        backgroundColor: `#${customBackgroundColorData.color}`,
      };
    }

    return (
      <section>
        <div className="text--01">
          <div className="container container--small">
            <div
              className="sh-1 card-container text--01__box text--01__box--editor"
              style={styleAttr}
            >
              <div className="text--01__emoji">
                <Pictogram 
                  pictogramObject={pictogram}
                  className=" "
                />
              </div>
              <div
                className={
                  "content_box " + (this.props.isDarkBg ? "text-white" : "")
                }
              >
                {text.length > 0 && (
                  <p
                    className={
                      "content-text text--01__content " +
                      (this.props.isDarkBg ? "text-white" : "")
                    }
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                )}
              </div>

              {ctaFirstButton &&
                ctaFirstButtonHref &&
                ctaFirstButtonHref !== "" && (
                  <div className="text--01__link_box">
                    <WebsiteCTA componentItem={this.props.componentItem} />
                  </div>
                )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Text01;
