import React from "react";
import { connect } from "react-redux";
import { Button, Icon, Tooltip } from "antd";
import PaypalLogo from "../../../../img/logos/paypal-word.svg";
import _ from "lodash";
import { BillingDataStatus } from "../../../../enums/BillingDataStatus";
import { setPaymentMethodDataStatus } from "../../../../store/user/actions";
import { SetPaymentMethodDataStatus } from "../../../../store/user/types";
import { openNotification } from "../../../../helpers/openNotification";
import { notificationDuration } from "../../../../data/constants";

interface Props {
  user: any;

  setPaymentMethodDataStatus: SetPaymentMethodDataStatus;
}

const PaymentMethod = (props: Props): JSX.Element => {
  const { user, setPaymentMethodDataStatus } = props;
  const { paymentMethodDataStatus } = user;
  const paymentMethod = _.get(user, "paymentMethod[0]");
  const updateUrl = _.get(paymentMethod, "update_url");
  const paddlePaymentMethodData = _.get(
    paymentMethod,
    "payment_information.payment_method"
  );
  const lastFourDigits = _.get(
    paymentMethod,
    "payment_information.last_four_digits"
  );
  const paddleSubscriptionStatus = _.get(
    user,
    "data.paddle_subscription_status"
  );
  const isButtonDisabled =
    paymentMethodDataStatus !== BillingDataStatus.loaded ||
    paddleSubscriptionStatus === "deleted";

  const displayPaymentMethod = () => {
    if (paymentMethodDataStatus === BillingDataStatus.inProgress) {
      return <Icon type="loading" style={{ color: "var(--ant)" }} />;
    }

    if (paymentMethodDataStatus === BillingDataStatus.loaded) {
      if (paddlePaymentMethodData === "paypal") {
        return (
          <div className="payment_info__method_paypal">
            <img src={PaypalLogo} alt="PayPal" height="24" />
          </div>
        );
      }
      return (
        <div className="payment_info__method_card">
          <Icon type="credit-card" theme="twoTone" />
          <span className="payment_info__method_card_number">
            <span className="payment_info__method_card_blur">
              XXXX XXXX XXXX
            </span>
            {lastFourDigits}
          </span>
        </div>
      );
    }

    return (
      <Tooltip title="We failed to load your billing info 😔">
        <Icon type="warning" theme="twoTone" twoToneColor="#FAAD14" />
      </Tooltip>
    );
  };

  const changePaymentMethod = () => {
    (window as any).Paddle.Checkout.open({
      override: updateUrl,
      successCallback: () => {
        openNotification(
          "Done",
          "You have successfully changed the payment method.",
          "Got it",
          "success",
          notificationDuration.short,
          <Icon type="smile" style={{ color: "var(--grass)" }} />
        );
        setPaymentMethodDataStatus(BillingDataStatus.notLoaded);
      },
    });
  };

  return (
    <>
      <h4>Payment method</h4>
      <div className="payment_info__billing">
        <div className="payment_info__method_box">
          <div className="payment_info__method">{displayPaymentMethod()}</div>
        </div>
        <div className="payment_info__controls_box">
          <Tooltip
            title={
              paddleSubscriptionStatus === "deleted" &&
              paymentMethodDataStatus === BillingDataStatus.loaded
                ? "Please renew your subscription to change the payment method."
                : ""
            }
          >
            <Button
              target="_blank"
              type="default"
              disabled={isButtonDisabled}
              loading={paymentMethodDataStatus === BillingDataStatus.inProgress}
              onClick={changePaymentMethod}
            >
              Change
            </Button>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
const mapActionsToProps = {
  setPaymentMethodDataStatus,
};
export default connect(mapStateToProps, mapActionsToProps)(PaymentMethod);
