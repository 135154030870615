import { Chat, ContextualInput, GptState } from "../types";

export const getCurrentInputData = (componentId: string) => {
  return (dispatch, getState) => {
    const gptState: GptState = getState().gpt;
    const currentInput = gptState.contextualInputs.find(
      (input) => input.componentId === componentId
    );
    if (!currentInput) {
      return {
        componentId,
        inputValue: "",
        isLoading: false,
        status: "none",
        reasoning: "",
        completionResultData: {
          isSessionStarted: false,
          hasChanges: false,
          prompt: "",
          presetName: null,
        },
      } as ContextualInput;
    }
    return currentInput;
  };
};
