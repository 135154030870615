import React from "react";

interface Props {
  condition: boolean;
  children?: JSX.Element;
  className?: string;
}

const ConditionalDiv = (props: Props): JSX.Element => {
  const { condition, children, className } = props;
  if (condition) {
    return <div className={className}>{children}</div>;
  }
  return children;
};

export default ConditionalDiv;
