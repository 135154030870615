import React from "react";
import { InputProps } from "../interfaces";
import GalleryButtonField from "./GalleryButtonField";
import GallerySliderField from "./GallerySliderField";
import GalleryTabField from "./GalleryTabField";
import GalleryMoveButtons from "./GalleryMoveButtons";
import GalleryDeleteSlideButton from "./GalleryDeleteSlideButton";
import GalleryAddNewSlideButton from "./GalleryAddNewSlideButton";
import { useCurrentInputData } from "../useCurrentInputData";

const Gallery = (props: InputProps): JSX.Element => {
  // the 'gallery' element is used for 'slider' and 'tabs' components.
  const {
    content,
    currentInputData,
    saveContentInStore,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    currentWebsite,
    displayPictogramEditorOpener,
    displayButtonStyleEditorOpener,
    setState,
    state,
    displayButtonPluginsDrawerOpener,
    textEditorType,
    revertToPlainHTML,
    saveImageInfoInDB,
    removeImageFromDB,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  let maxItemsReached = currentInputDataRef.current.maxItems === content.items.length;

  let galleryItemsInputs = content.items.map((item: any, key: number) => {
    return (
      <div
        className={
          "content-input__cluster " +
          (maxItemsReached === false
            ? " content-input__cluster--with-ghost "
            : " ")
        }
        data-add-new="Add new item ⤵"
        key={"gallery-" + key}
      >
        <GalleryDeleteSlideButton
          item={item}
          saveContentInStore={saveContentInStore}
          contentType={contentType}
          currentInputData={currentInputData}
          keyProp={key}
          toggleNewEditionsDetected={toggleNewEditionsDetected}
        />
        <GalleryMoveButtons
          displayMoveUpDownButtons={displayMoveUpDownButtons}
          keyProp={key}
          content={content}
          saveContentInStore={saveContentInStore}
          contentType={contentType}
          currentInputData={currentInputData}
        />
        <GalleryTabField
          item={item}
          saveContentInStore={saveContentInStore}
          contentType={contentType}
          currentInputData={currentInputData}
          keyProp={key}
          toggleNewEditionsDetected={toggleNewEditionsDetected}
          displayPictogramEditorOpener={displayPictogramEditorOpener}
        />
        <GallerySliderField
          textEditorType={textEditorType}
          revertToPlainHTML={revertToPlainHTML}
          item={item}
          saveContentInStore={saveContentInStore}
          contentType={contentType}
          currentInputData={currentInputData}
          keyProp={key}
          toggleNewEditionsDetected={toggleNewEditionsDetected}
          saveImageInfoInDB={saveImageInfoInDB}
          removeImageFromDB={removeImageFromDB}
          state={state}
        />
        <GalleryButtonField
          galleryItem={item}
          currentWebsite={currentWebsite}
          saveContentInStore={saveContentInStore}
          contentType={contentType}
          currentInputData={currentInputData}
          currentGalleryItemIndex={key}
          toggleNewEditionsDetected={toggleNewEditionsDetected}
          displayButtonStyleEditorOpener={displayButtonStyleEditorOpener}
          setState={setState}
          state={state}
          displayButtonPluginsDrawerOpener={displayButtonPluginsDrawerOpener}
        />
      </div>
    );
  });

  return (
    <div>
      {galleryItemsInputs}
      <GalleryAddNewSlideButton
        saveContentInStore={saveContentInStore}
        contentType={contentType}
        currentInputData={currentInputData}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
        content={content}
        maxItemsReached={maxItemsReached}
      />
    </div>
  );
};

export default Gallery;
