import React from "react";
import { Input, Button, Form } from "antd";
import { connect } from "react-redux";
import { toggleIsDeleteAccountInProgress } from "../../../../store/user/actions";
import { ToggleIsDeleteAccountInProgress } from "../../../../store/user/types";
import { deleteAccountAsync } from "../../../../helpers/account/deleteAccountAsync";
import { logout } from "../../../../store/auth/actions";
import { openNotification } from "../../../../helpers/openNotification";
import { notificationDuration } from "../../../../data/constants";
import fireAnalyticsEvent from "../../../../helpers/editor/fireAnalyticsEvent";
import { CrispEvents } from "../../../../enums/AnalyticsEventsEnums";
import _ from "lodash";

interface Props {
  user: any;
  auth: any;
  currentEmail: string;

  setCurrentEmail: React.Dispatch<React.SetStateAction<string>>;
  toggleIsDeleteAccountInProgress: ToggleIsDeleteAccountInProgress;
  handleOnCancel: () => void;

  logout: (isAccountDeleted?: boolean) => void;
}

const DeleteAccountForm = (props: Props): JSX.Element => {
  const { setCurrentEmail, currentEmail, user, auth, handleOnCancel } = props;

  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setCurrentEmail(event.currentTarget.value);
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props.toggleIsDeleteAccountInProgress(true);
    deleteAccountAsync(auth.accessToken)
      .then(() => {
        props.logout(true);
      })
      .catch((error) => {
        props.toggleIsDeleteAccountInProgress(false);
        const errorMessage = _.get(
          error,
          "response.data.message",
          "Unknown error"
        );
        console.error(errorMessage);
        handleOnCancel();
        openNotification(
          "Server error",
          "We are sorry, but something went wrong. Please refresh the page and try again.",
          "Close",
          "error",
          notificationDuration.medium
        );
        fireAnalyticsEvent.fireCrisp(
          CrispEvents.deleteAccountError,
          {
            email: user.data.email,
            error_message: errorMessage,
          },
          true
        );
      });
  };

  return (
    <Form
      id="delete-account-form"
      className="horizontal-form"
      onSubmit={handleOnSubmit}
    >
      <Input
        className="horizontal-form__input"
        placeholder="sales@acme.com"
        autoFocus
        onChange={handleOnChange}
        value={currentEmail}
        disabled={user.isDeleteAccountInProgress}
      />
      <Button
        form="delete-account-form"
        htmlType="submit"
        type="primary"
        disabled={currentEmail.trim() !== user.data.email}
        loading={user.isDeleteAccountInProgress}
        className="ant-btn--danger-custom"
      >
        Delete
      </Button>
    </Form>
  );
};

const mapStateToProps = ({ user, auth }) => {
  return { user, auth };
};
const mapActionsToProps = {
  toggleIsDeleteAccountInProgress,
  logout,
};
export default connect(mapStateToProps, mapActionsToProps)(DeleteAccountForm);
