import React from "react";
import { connect } from "react-redux";
import { Icon, Skeleton, Table, Tag } from "antd";
import _ from "lodash";
import { BillingDataStatus } from "../../../../enums/BillingDataStatus";

interface Props {
  user: any;
}

const PaymentHistory = (props: Props): JSX.Element => {
  const { user } = props;
  const { paymentHistoryDataStatus } = user;
  const paymentsHistory = _.get(user, "paymentsHistory");
  const paymentsHistoryArray = Object.values(paymentsHistory || {}).reverse();

  const displayPaymentHistory = () => {
    const columns = [
      {
        title: "Date",
        dataIndex: "payout_date",
        key: "payout_date",
      },
      {
        title: "Status",
        dataIndex: "is_paid",
        key: "is_paid",
        render: (is_paid: number) => (
          <span>
            {is_paid === 1 ? (
              <Tag color={"green"}>Succeeded</Tag>
            ) : (
              <Tag>Upcoming</Tag>
            )}
          </span>
        ),
      },
      {
        title: "Amount",
        dataIndex: "amount_and_currency",
        key: "amount_and_currency",
        render: (text, record) => (
          <span>
            <span style={{ opacity: 0.5, fontSize: "0.8em" }}>
              {record.currency}
            </span>{" "}
            {record.amount}
          </span>
        ),
      },
      {
        title: " ",
        dataIndex: "receipt_url",
        key: "invoice",
        render: (receipt_url) =>
          receipt_url && (
            <span title="Open the invoice in a new tab.">
              <a href={receipt_url} target="_blank">
                <Icon type="link" /> Receipt
              </a>
            </span>
          ),
      },
    ];

    if (paymentHistoryDataStatus === BillingDataStatus.inProgress) {
      return (
        <div className="payment_info__skeleton_box">
          <Skeleton paragraph={{ rows: 6 }} active title={false} />
        </div>
      );
    }

    if (paymentHistoryDataStatus === BillingDataStatus.loaded) {
      return (
        <Table
          pagination={false}
          dataSource={paymentsHistoryArray}
          columns={columns}
          rowKey="id"
        />
      );
    }

    return <Table columns={columns} />;
  };

  return (
    <div className="payment_info__list_box">
      <h4>History</h4>
      {displayPaymentHistory()}
    </div>
  );
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps, null)(PaymentHistory);
