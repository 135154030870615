import { GraphicsOptions } from "../components/editor/component_graphics/data/componentGraphicsData";
import generateContentDataObject from "../helpers/editor/generateContentDataObject";
import generateRandomNumerousId from "../helpers/generateRandomNumerousId";
import { buttonStyles } from "./buttonStyles";
import { callToActionOptions } from "./callToActionOptions";

type Data = Partial<{
  id: string;
  componentData: any;
}>;

export const getDefaultPopupData = (data?: Data) => {
  const result = {
    title: "01",
    category: "01",
    componentData: {},
    id: `popup-01-${generateRandomNumerousId()}`,
    settings: {
      padding: {
        top: 0,
        bottom: 0,
      },
      visibility: {
        isHiddenOnDesktops: false,
        isHiddenOnTablets: false,
        isHiddenOnMobiles: false,
      },
      background: {
        color: "white", // Get info form defaultBackgrounds.ts. Default for all: 'white'.
        imageUrl: "", // Get info form defaultBackgrounds.ts. Default for all: '' (empty string means no bg image used). if this != '', then bg image is active and the 'page-component__bg_image_box' has a class 'page-component__bg_image_box--image' which makes 'page-component__bg_overlay_box' act as an image overlay, not as a color background (just add transparency)
        imageUUID: undefined, //this field was added on the 26th of February 2020.
        overlayOpacity: 0.8, //added on Jul 01 2021. Used here: frontend/src/components/dashboard/BackgroundImagePicker.tsx
        activeOption: "image",
        backgroundVideoUrl: "",
      },
    },
  };
  if (data) {
    Object.assign(result, data);
  }
  return result;
};

export const getDefaultSuccessPopupComponentData = () => {
  const generator = new generateContentDataObject();
  const popupTitleObject = generator.setUpTitle({
    titleText: "Submission Successful",
  });
  const popupSubtitleObject = generator.setUpSubtitleText({
    subtitleText: "The form has been successfully submitted.",
  });
  const popupCtaObject = generator.setUpCta({
    activeOption: callToActionOptions.buttons,
    buttons: [
      generator.createButtonContentObject({
        buttonTitle: "Back to site",
        buttonColor: buttonStyles.regular.blackBg,
        onClickJs: "event.preventDefault(); window.unicornplatform.closeAllPopups();",
      }),
    ],
  });
  const popupGraphicsObject = generator.setUpGraphics({
    activeOption: GraphicsOptions.image,
    userCanAddItems: false,
    maxItems: 1,
    fieldInfo: {
      [GraphicsOptions.image]: "",
      [GraphicsOptions.video]: "",
      default: "",
    },
    items: [
      generator.createGraphicsContentObject({
        imageUrl: "",
        videoUrl: "",
      }),
    ],
  });
  return {
    ...popupTitleObject,
    ...popupSubtitleObject,
    ...popupCtaObject,
    ...popupGraphicsObject,
  };
};
