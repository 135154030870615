import React, {Component} from "react";
import generateContentDataObject from '../../helpers/editor/generateContentDataObject'
import {callToActionOptions} from "../../data/callToActionOptions";
import {pressLogos} from "../../data/urls";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import WebsiteButton from "../component_molecules/WebsiteButton";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Header28 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: 'Customer Care Center',
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpFeatures({
                maxItems: 2,

                hasSubtitle: false,
                hasText: true,
                hasPictogram: false,
                hasHighlight: false,
                hasHighlightInfo: false,
                hasButton: true,

                buttonTypes: [buttonStyles.ghost.typeTitle, buttonStyles.regular.typeTitle, buttonStyles.pill.typeTitle, buttonStyles.mobileAppStore.typeTitle],

                items: [
                    this.generator.createFeatureContentObject({
                        title: 'Development guides',
                        text: 'Ubi est albus saga? Vortex de barbatus extum, magicae vigil! Nunquam captis coordinatae. Nunquam prensionem zelus. Germanus, azureus extums interdum tractare de brevis, superbus luna.',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "View all guides",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.regular.typeTitle,
                            buttonColor: buttonStyles.regular.whiteBg,
                        })
                    }),
                    this.generator.createFeatureContentObject({
                        title: 'When you need more',
                        text: 'A falsis, zirbus grandis abaculus. Ortum aegre ducunt ad dexter particula. Idoleum fatalis planeta est. Magnum, neuter spatiis diligenter.',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "Get live help",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.regular.typeTitle,
                            buttonColor: buttonStyles.regular.whiteOutline,
                        })
                    }),
                ],
                isDefaultEmpty

            }));

        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let features = this.props.componentItem.componentData.features.content.items;

        return (

            <header className="header-28">
                <div className="container container--premid header-28__container">

                    {title.length > 0 && <h2 className={"title-text heading text-center " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: title}}/>}

                    <ul className="header-28__buttons">

                        {features && features.map((item:any, key:number) => {

                            if(item.title && item.title !== ''){
                                return (
                                    <li className="header-28__item" key={'feature-of-header-28-' + key}>

                                        <h4 className={"def-16 title-text--inner "+(this.props.isDarkBg ? 'text-white' : '')}>
                                            {item.title}
                                        </h4>

                                        {item.text && item.text !== '' &&
										<div className={"content-text header-28__text content_box " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: item.text}}/>}

                                        <WebsiteButton config={{
                                            buttonStyleType: item.button.settings.appearance.styleType,
                                            buttonColor: item.button.settings.appearance.color,
                                            buttonType: linkOrButtonOptions.link,
                                            buttonTitle: item.button.title,
                                            buttonHref: item.button.href,
                                            buttonIsTargetBlank: item.button.isTargetBlank,
                                            buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                            pillPrefix: item.button.pillPrefix,
                                            pillColor: item.button.settings.appearance.pillColor,
                                            ghostColor: item.button.settings.appearance.ghostColor,
                                        }} />

                                    </li>
                                )
                            }else{
                                return (null);
                            }
                        })}

                    </ul>
                </div>
            </header>
        )
    }
}

export default Header28;
