import classNames from "classnames";
import React from "react";

interface Props {
  isDarkBg: boolean;
  permanentSpinnerColor?: boolean;
}

const Spinner = (props: Props): JSX.Element => {
  const { isDarkBg, permanentSpinnerColor } = props;
  return (
    <div
      className={classNames("spinner", {
        "spinner--black": !isDarkBg,
        "spinner--permanent": permanentSpinnerColor,
      })}
    />
  );
};

export default Spinner;
