export const focusTextArea = (
  textAreaRef: React.RefObject<HTMLTextAreaElement>
) => {
  if (!textAreaRef) return;
  const textAreaNode = textAreaRef.current;
  if (!textAreaNode) return;
  const value = textAreaNode.value;
  textAreaNode.value = "";
  textAreaNode.focus();
  textAreaNode.value = value;
};
