import React, {Component} from "react";
import generateContentDataObject from '../../helpers/editor/generateContentDataObject'
import {callToActionOptions} from "../../data/callToActionOptions";
import {pressLogos} from "../../data/urls";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {pictogramOptions} from "../../data/pictogramOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Header27 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty, defaultCtaOption } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: 'Customer Care Center',
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: defaultCtaOption || callToActionOptions.buttons,
                priority: 500,

                formButtonStyleColor: buttonStyles.regular.whiteBg,
                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Get it",
                        buttonHref: '/sign-up',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.whiteBg,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'Whoa!'
                    }),

                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Learn more",
                        buttonHref: '/product',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonColor: buttonStyles.regular.whiteOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'New'
                    }),

                    // ghost object
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        buttonColor: buttonStyles.ghost.whiteOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'View'
                    })
                ]

            }));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                priority: 200,
                maxItems: 3,

                hasSubtitle: false,
                hasText: true,
                hasPictogram: true,
                hasHighlight: false,
                hasHighlightInfo: false,
                hasButton: true,

                buttonTypes: [buttonStyles.ghost.typeTitle, buttonStyles.regular.typeTitle, buttonStyles.pill.typeTitle, buttonStyles.mobileAppStore.typeTitle],

                items: [
                    this.generator.createFeatureContentObject({
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f4e3.svg',
                        }),
                        title: 'Recent Product Updates',
                        text: 'Read what is new',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "View Announcement",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.ghost.typeTitle,
                            buttonColor: buttonStyles.ghost.accentOutline,
                        })
                    }),
                    this.generator.createFeatureContentObject({
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f340.svg',
                        }),
                        title: '15 Less Known Features of Unicorn Platform',
                        text: '',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "Read article",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.ghost.typeTitle,
                            buttonColor: buttonStyles.ghost.accentOutline,
                        })
                    }),
                    this.generator.createFeatureContentObject({
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f36a.svg',
                        }),
                        title: 'Cookie Based AB Testing',
                        text: '10 min guide',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "Read in Blog",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.ghost.typeTitle,
                            buttonColor: buttonStyles.ghost.accentOutline,
                        })
                    }),
                    // The last ghost item which is used to add new items.
                    this.generator.createFeatureContentObject({
                      title: '',
                      subtitle: ''
                  }),
                ]

            }));

        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);
        let features = this.props.componentItem.componentData.features.content.items;

        return (

            <header className="header-27">
                <div className="container container--small header-27__container">
                    {title.length > 0 && <h1 className={"title-text heading " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: title}}/>}

                    {subtitleText.length > 0 && <p className={"subtitle-text content_box "  + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: subtitleText}}/>}

                    <div className="header-27__buttons">
                        <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={this.props.isDarkBg} />
                    </div>
                </div>
                <div className="container container--mid">
                    <ul className="header-27__links">
                        {features && features.map((item:any, key:number) => {
                            if(item.title && item.title !== ''){
                                return (
                                    <li className="sh-1 header-27__item card-container" key={'feature-header-27-' + key}>

                                        <div className="header-27__emoji">
                                            <Pictogram 
                                              pictogramObject={item.pictogram}
                                              className=''
                                            />
                                        </div>

                                        <h3 className="def-20 title-text--inner header-27__title">{item.title}</h3>
                                        <div className="content-text def-14 header-27__info content_box " dangerouslySetInnerHTML={{__html: item.text}}/>

                                        <div className="header-27__link">
                                            {item.button.href !== '' && <WebsiteButton config={{
                                                buttonStyleType: item.button.settings.appearance.styleType,
                                                buttonColor: item.button.settings.appearance.color,
                                                buttonType: linkOrButtonOptions.link,
                                                buttonTitle: item.button.title,
                                                buttonHref: item.button.href,
                                                buttonIsTargetBlank: item.button.isTargetBlank,
                                                buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                                pillPrefix: item.button.pillPrefix,
                                                pillColor: item.button.settings.appearance.pillColor,
                                                ghostColor: item.button.settings.appearance.ghostColor,
                                            }} />}
                                        </div>

                                    </li>
                                )
                            }else{
                                return (null);
                            }

                        })}
                    </ul>
                </div>
            </header>


        )
    }
}

export default Header27;
