import { transliterate } from 'transliteration';

export function formatToURLPath(str: string) {
  if (!str) return '';
  if (typeof str !== 'string') return '';

  let formattedStr = str;

  // Turn all spaces into dashes
  formattedStr = str.replace(/\s+/g, '-');

  // Transliterate the string
  formattedStr = transliterate(formattedStr);

  if (formattedStr.length > 500) {
    formattedStr = formattedStr.slice(0, 500);
  }

  // Delete any special characters
  formattedStr = formattedStr.replace(/[^\w\/-]/g, '');

  // Lowercase the string
  formattedStr = formattedStr.toLowerCase();

  // Remove trailing and leading slashes and dashes
  formattedStr = formattedStr.replace(/^[-/]+|[-/]+$/g, '')

  // Turn double slashes into single slash
  formattedStr = formattedStr.replace(/\/{2,}/g, '/');

  // Turn double dashes into single dash
  formattedStr = formattedStr.replace(/-{2,}/g, '-');

  return formattedStr;
}
