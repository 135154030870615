function trimStringTo(string:string, limit: number){
    /* if a string exceeds the limit, trim it and add an ellipsis on the end. */
    if(string === undefined){
        return '';
    }else{
        let newString = string.length > limit ?
            string.substring(0, limit - 3) + "..." :
            string;
        return newString;
    }

}

export default trimStringTo;