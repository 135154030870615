import _ from "lodash";
import { BlogDataStatus } from "../../enums/BlogDataStatus";
import { getCurrentWebsite } from "../getCurrentWebsite";
import { getCurrentBlog } from "./getCurrentBlog";

export const getBlogDataStatus = (
  websites: any,
  blogs: any,
  blogPosts: any
): BlogDataStatus => {
  const isWebsiteDataFetched = _.get(websites, "dataFetched");
  const isBlogDataFetched = _.get(blogs, "dataFetched");
  const isBlogPostsDataFetched = _.get(
    blogPosts,
    "allPostsWithoutContentFetched"
  );

  if (!isWebsiteDataFetched || !isBlogDataFetched || !isBlogPostsDataFetched) {
    return BlogDataStatus.noData;
  }

  const websiteItems = _.get(websites, "items");
  const loadedBlogs = _.get(blogs, "loadedBlogs");
  const currentWebsite = getCurrentWebsite(websiteItems);
  const currentBlog = getCurrentBlog(blogs.items, currentWebsite);
  const blogId = _.get(currentBlog, "id");

  const currentBlogDataStatus: BlogDataStatus = _.find(loadedBlogs, { blogId });

  return _.get(currentBlogDataStatus, "status", BlogDataStatus.notLoaded);
};
