import { Components } from "../enums/Components";

export const graphicsWithOneItem = [
  Components.header39,
  Components.header35,
  Components.header23,
  Components.header63,
  Components.header61,
  Components.header60,
  Components.video01,
  Components.video02,
  Components.video03,
];

export const pricingWithOneItem = [Components.pricing03];

export const postsWithOneItem = [Components.testimonials05];

export const textWithOneItem = [
  Components.text02,
  Components.text01,
  Components.text07,
  Components.text04,
];

export const pricingWithoutIndividualInfo = [
  Components.pricing01,
  Components.pricing02,
  Components.pricing05,
];

export const customGroups = {
  features: [
    Components.features01,
    Components.features02,
    Components.features03,
    Components.features09,
    Components.links04,
    Components.links06,
    Components.links03,
    Components.text08,
  ],
  cards: [
    Components.posts01,
    Components.posts02,
    Components.posts03,
    Components.posts04,
    Components.posts05,
    Components.links01,
    Components.text06,
  ],
  featureBlocks: [Components.features06, Components.features07],
  groupedLists: [Components.features05, Components.links02, Components.links05],
  gallery: [
    Components.slider01,
    Components.slider05,
    Components.slider07,
    Components.slider10,
    Components.tabs01,
    Components.tabs03,
    Components.tabs04,
    Components.tabs10,
  ],
  logos: [
    Components.press01,
    Components.clients01,
    Components.clients02,
    Components.clients03,
  ],
  press: [Components.press02, Components.press03],
  text: [
    Components.text01,
    Components.text02,
    Components.text03,
    Components.text07,
    Components.text04,
  ],
  testimonials: [
    Components.testimonials01,
    Components.testimonials02,
    Components.testimonials05,
    Components.other02
  ],
  forms: [
    Components.ctaForm01,
    Components.ctaForm03,
    Components.ctaForm04,
    Components.other03
  ],
};

export const componentsWithoutEmptyMockups = [
  Components.header61,
  Components.header62,
  Components.header64,
  Components.header65,
];

export const headersWithoutGraphics = [
  Components.header04,
  Components.header28,
  Components.header49,
  Components.header29,
  Components.header44,
  Components.header03,
  Components.header27,
  Components.header43,
];

export const galleryComponents = [
  Components.slider01,
  Components.slider05,
  Components.slider07,
  Components.slider10,
  Components.tabs01,
  Components.tabs03,
  Components.tabs04,
  Components.tabs10,
];
