import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import Slider from "react-slick";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteFeature from "../component_molecules/WebsiteFeature";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import { publicUrl } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Slider04 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "The New Way to Inspire"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpGallery({
              isDefaultEmpty,
                maxItems: 99,
                fieldInfo: {default: 'Recommended screenshots: 750x1334px'},
                items: [
                    this.generator.createGalleryContentObject({
                        contentGraphic: [this.generator.createGraphicsContentObject({
                            imageUrl: publicUrl + "/img/screenshots/app.png",
                        })],
                    }),
                    this.generator.createGalleryContentObject({
                        contentGraphic: [this.generator.createGraphicsContentObject({
                            imageUrl: publicUrl + "/img/screenshots/app2.png",
                        })],
                    }),
                    this.generator.createGalleryContentObject({
                        contentGraphic: [this.generator.createGraphicsContentObject({
                            imageUrl: publicUrl + "/img/screenshots/app.png",
                        })],
                    }),
                    this.generator.createGalleryContentObject({
                        contentGraphic: [this.generator.createGraphicsContentObject({
                            imageUrl: publicUrl + "/img/screenshots/app2.png",
                        })],
                    }),
                ]

            }));

            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                maxItems: 6,
                priority: 300,
                items: [
                    this.generator.createFeatureContentObject({title: 'Powerful Sync'}),
                    this.generator.createFeatureContentObject({
                            title: 'Authentic Design',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Learn why", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline})
                        }
                    ),
                    this.generator.createFeatureContentObject({title: ''}),
                ],
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the slider component to invite users to read more about the app.",
                    default: "Add a button to the bottom of the slider component to invite users to read more about the app.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Learn more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    componentDidMount(): void {

    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let gallery = this.props.componentItem.componentData.gallery.content.items;
        let features = this.props.componentItem.componentData.features.content.items;

        let settings = {
            'dots': false,
            'infinite': true,
            'speed': 300,
            'fade': true,
            'cssEase': 'ease-out',
            'adaptiveHeight': false,
            'centerMode': true,
            'variableWidth': false,
            'slidesToShow': 1,
            // 'lazyLoad': 'ondemand',
            'focusOnSelect': false,
            'arrows': true,
            'responsive': [{'breakpoint': 440, 'settings': {'autoplay': true, 'autoplaySpeed': 1400}}],
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
        };

        return (
            <section>
                <div className="slider-04">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container slider-04__wrapper">
                        <div className="slider-04__slider_container">
                            <div className="slider slider-04__slider">
                                <Slider {...settings}>
                                    {gallery && gallery.map((item:any, key:number) => {
                                        if(item.contentGraphic[0] !== undefined && item.contentGraphic[0].image !== undefined && item.contentGraphic[0].image.url !== ''){
                                            return (
                                                <li className="slider-04__item slider__item" key={"slider-04-item-" + key}>
                                                    <img className="slider-04__img" src={formatImageUrl(item.contentGraphic[0].image.url)}/>
                                                </li>
                                            )
                                        }else{
                                            return (null);
                                        }
                                    })}
                                </Slider>
                            </div>

                            <div className="slider-04__mockup_container">
                                <div className="mockup  mockup--white mockup--iphone mockup--portrait ">
                                    <div className="mockup__case">
                                        <div className="mockup__spacer"/>
                                        <div className="mockup__speaker"/>
                                        <div className="mockup__screen">
                                            <div className="mockup__screenshot"/>
                                        </div>
                                        <div className="mockup__button"/>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <ul className="slider-04__text_container">

                            {features && features.map((item:any, key:number) => {
                                return (
                                    <li className="slider-04__feature" key={"slider-feature-04-" + key}>
                                        <WebsiteFeature featureObject={item} isWhite={this.props.isDarkBg} />
                                    </li>
                                )
                            })}

                        </ul>

                    </div>

                    <div className="bottom_cta">
                        {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                    </div>

                </div>

            </section>
        )
    }
}

export default Slider04;
