//https://stackoverflow.com/questions/175739/built-in-way-in-javascript-to-check-if-a-string-is-a-valid-number
import isNumeric from "./isNumeric";

function isNumericOrDashOrPlus(variable:any){
    // We need this function for pricings: e.g. "22", "2-40", "29+" should be true, "contact us", "contact-us" - false
    let checkedValue = variable.toString();
    checkedValue = checkedValue.replace('-', '').replace('.', '').replace(',', '').replace('+', '').replace('_', ''); //We clear dashes and pluses to check the rest of the string.
    return isNumeric(checkedValue);
}

export default isNumericOrDashOrPlus;