import { BlogPostsState } from "../store/blogPosts/types";
import { BlogsState } from "../store/blogs/types";
import { WebsitePagesState } from "../store/websitePages/types";
import { WebsitesState } from "../store/websites/types";

interface Payload {
  path: string;
  websites: WebsitesState;
  websitePages: WebsitePagesState;
  blogs: BlogsState;
  blogPosts: BlogPostsState;
}
export const validatePath = (payload: Payload) => {
  const { path, websites, websitePages, blogs, blogPosts } = payload;
  let pathString = path;
  if (!pathString || typeof pathString !== "string") {
    return false;
  }

  if (pathString.startsWith("/")) {
    pathString = pathString.slice(1);
  }

  let pathArray = pathString.split("/");

  const subdomain = pathArray[0];

  const website = websites.items.find((website) => {
    return website.subdomain === subdomain;
  });

  if (!website) {
    return false; // Website doesn't exist, so the path isn leading anywhere
  }

  const allowedPaths = [website.subdomain, `${website.subdomain}/`];

  websitePages.items.forEach((page) => {
    if (page.website === website.id) {
      allowedPaths.push(`${website.subdomain}/pages/${page.id}`);
      allowedPaths.push(`${website.subdomain}/pages/${page.id}/`);
    }
  });

  const blog = blogs.items.find((blog) => {
    return blog.website === website.id;
  });

  blogPosts.items.forEach((post) => {
    if (blog && post.blog === blog.id) {
      allowedPaths.push(`${website.subdomain}/blog/${post.id}`);
    }
  });

  if (!allowedPaths.includes(pathString)) {
    return false;
  }

  return true;
};
