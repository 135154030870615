import React from "react";
import { defaultUploadedPictogramHeight } from "../../data/constants";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
  uploadedSrc: string;
  height: number;
  className?: string;
  alt?: string;
}

const UploadedIcon = (props: Props): JSX.Element => {
  const { uploadedSrc, height, className, alt } = props;

  // height by default: defaultUploadedPictogramHeight
  return (
    <img
      src={formatImageUrl(uploadedSrc)}
      className={className ? className : ""}
      alt={alt ? alt : ""}
      height={height ? height : defaultUploadedPictogramHeight}
    />
  );
};

export default UploadedIcon;
