import React from "react";
import CaptchaSwitch from "./CaptchaSwitch";
import CaptchaSitekeyInput from "./CaptchaSitekeyInput";
import CaptchaHideBadge from "./CaptchaHideBadge";
import { Alert } from "antd";
import { urls } from "../../../data/urls";
import { connect } from "react-redux";
import CaptchaSecretkeyInput from "./CaptchaSecretkeyInput";
import { checkIfCaptchaAvailableForUser } from "../../../helpers/captcha/checkIfCaptchaAvailableForUser";
import UpgradeToUnlockMessage from "../UpgradeToUnlockMessage";
import "./CaptchaDrawer.css";
import { UserEvents } from "../../../enums/UserEvents";

interface Props {
  currentWebsite: any;
  user?: any;
  plans?: any;
  sharedAccesses?: any;
}

class CaptchaDrawer extends React.Component<Props> {
  info = {
    privacy: {
      title: "Privacy Policy for reCAPTCHA",
      description: (
        <div>
          <p>
            reCAPTCHA is a Google service. By activating reCAPTCHA on your
            website, you allow Google to collect the personal information of
            your visitors. Google's{" "}
            <a href={urls.thirdparty.googlePrivacy} target="_blank">
              Privacy Policy
            </a>{" "}
            and{" "}
            <a href={urls.thirdparty.googleTerms} target="_blank">
              Terms of Service
            </a>{" "}
            apply.
          </p>
          <p>
            Please explicitly inform your users that the website is protected by
            reCAPTCHA, and update your Privacy Policy if necessary.
          </p>
        </div>
      ),
    },
  };

  render() {
    const { user, plans, sharedAccesses, currentWebsite } = this.props;
    const isCaptchaAvailable = checkIfCaptchaAvailableForUser(
      user,
      plans,
      sharedAccesses,
      currentWebsite
    );

    if (isCaptchaAvailable) {
      return (
        <div className="captcha-drawer">
          <CaptchaSwitch currentWebsite={currentWebsite} />
          <CaptchaSitekeyInput currentWebsite={currentWebsite} />
          <CaptchaSecretkeyInput currentWebsite={currentWebsite} />
          <CaptchaHideBadge currentWebsite={currentWebsite} />
          <Alert
            className="captcha-drawer__alert"
            showIcon
            message={this.info.privacy.title}
            description={this.info.privacy.description}
            type="info"
          />
        </div>
      );
    }
    return (
      <UpgradeToUnlockMessage
        title="Hold on, cowboy"
        description={
          <span>
            reCAPTCHA is available only on <b>Startup</b> plan or higher. Please
            upgrade your account to get it.
          </span>
        }
        userEventOnMount={UserEvents.CaptchaNotAvailable}
      />
    );
  }
}

const mapStateToProps = (state: any) => {
  const { user, plans, sharedAccesses } = state;
  return {
    user,
    plans,
    sharedAccesses,
  };
};
export default connect(mapStateToProps, null)(CaptchaDrawer);
