import React, { Component } from "react";
import { Button, Input } from "antd";
import { api } from "../../data/urls";
import { getCurrentWebsiteSubdomain } from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { notificationDuration, projectUrl } from "../../data/constants";
import { openNotification } from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { connect } from "react-redux";
import { saveUserEventsInStore } from "../../store/user/actions";
import { SaveUserEventsInStore } from "../../store/user/types";
import { setUserEventsAsync } from "../../helpers/user/setUserEventsAsync";
import { UserEvents } from "../../enums/UserEvents";
import _ from "lodash";
import { getSubdomain } from "../../helpers/custom_domain/getSubdomain";

interface Props {
  currentWebsiteCustomDomain: string;
  currentWebsiteSubdomain: string;
  websites: any;
  history: any;
  user: any;
  clearUpdateCustomDomainInput: any;
  auth: any;
  customDomainUpdateWaitingForServer: any;
  customDomainUpdateToggleInvalidAttempt: any;
  customDomainUpdateErrorMessage: any;
  saveWebsiteDataInStore: any;
  doesCurrentUserHaveAccessToPaidFeatures: any;
  currentWebsite: any;
  customDomainInputValue: string;
  setCustomDomainInputValue: React.Dispatch<React.SetStateAction<string>>;
  next?: () => void;

  saveUserEventsInStore: SaveUserEventsInStore;
}
class UpdateCustomDomainForm extends Component<Props> {
  componentWillReceiveProps(
    nextProps: Readonly<Props>,
    nextContext: any
  ): void {
    // We set the new state only when we change a settings active website.
    // We want to set up an initial value of a new picked website.
    if (
      this.props.currentWebsiteSubdomain !== nextProps.currentWebsiteSubdomain
    ) {
      this.props.setCustomDomainInputValue(
        nextProps.currentWebsiteCustomDomain
      );
      // We also clear errors when switch a website.
      this.props.clearUpdateCustomDomainInput();
    }
  }

  handleUpgradeToUnlock = (e: any): void => {
    this.props.history.push("/plan");
  };

  handleCustomDomainUpdate = (e: any): void => {
    e.preventDefault();

    let newCustomDomain = this.props.customDomainInputValue;

    if (newCustomDomain === this.props.currentWebsiteCustomDomain) {
      return;
    }

    if (typeof newCustomDomain === "string") {
      if (newCustomDomain.startsWith("http://")) {
        newCustomDomain = newCustomDomain.replace("http://", "");
      }
      if (newCustomDomain.startsWith("https://")) {
        newCustomDomain = newCustomDomain.replace("https://", "");
      }
      if (newCustomDomain.startsWith("www.")) {
        newCustomDomain = newCustomDomain.replace("www.", "");
      }
      newCustomDomain = newCustomDomain.replace(/ /g, "");
      newCustomDomain = newCustomDomain.replace(/\//g, "");
    }

    this.props.setCustomDomainInputValue(newCustomDomain);
    
    this.props.customDomainUpdateWaitingForServer(true);

    //We want to remove all errors from inputs on a new request.
    this.props.clearUpdateCustomDomainInput();


    let accessToken = this.props.auth.accessToken;
    let apiUrl = `${api.websites.connectCustomDomain}/${this.props.currentWebsite.id}`

    let data = { custom_domain: newCustomDomain, subdomain_part: getSubdomain(newCustomDomain) };

    axios
      .post(apiUrl, data, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        const dataToStore = {
          data: {
            ...this.props.currentWebsite,
            custom_domain: _.get(response, "data.data.custom_domain"),
            is_custom_domain_active: _.get(response, "data.data.is_custom_domain_active"),
            is_ssl_issued: _.get(response, "data.data.is_ssl_issued"),
            domain_data: _.get(response, "data.data.domain_data"),
            domain_setup_version: _.get(response, "data.data.domain_setup_version"),
          }
        }
        this.props.saveWebsiteDataInStore(
          dataToStore,
          this.props.currentWebsiteSubdomain
        );
        let currentWebsiteSubdomain = getCurrentWebsiteSubdomain(
          this.props.websites.items
        );

        fireAnalyticsEvent.fireCrisp(CrispEvents.changeCustomDomain, {
          subdomain: response.data.subdomain,
          new_custom_domain: response.data.custom_domain,
        });
        // let newCustomDomain = values["custom_domain"];

        let subdomainUrl =
          "https://" + currentWebsiteSubdomain + "." + projectUrl;

        if (newCustomDomain === "") {
          // If a user just removed his custom domain (set to blank)
          let blankCustomDomainMessage = (
            <span>
              Successfully removed custom domain. You can add another custom
              domain anytime. Your website can be accessed here:&nbsp;
              <a target="_blank" href={subdomainUrl}>
                {subdomainUrl}
              </a>
            </span>
          );
        } else {
          if (!this.props.doesCurrentUserHaveAccessToPaidFeatures) {
            const message = (
              <span>
                Successfully set custom domain to <b>{newCustomDomain}</b>.
                <br />
                <br />
                To activate the custom domain, please upgrade your account to a paid plan.
              </span>
            );
            openNotification(
              "Great!",
              message,
              "Sure",
              "success",
              notificationDuration.medium
            );
          }

          if (
            !this.props.doesCurrentUserHaveAccessToPaidFeatures &&
            newCustomDomain
          ) {
            setUserEventsAsync(
              {
                [UserEvents.CustomDomainEnteredOnFreePlan]: new Date(),
              },
              accessToken,
              this.props.user.data.events
            ).then((response) => {
              if (response) {
                this.props.saveUserEventsInStore(response.data);
              }
            });
          }

          if (this.props.doesCurrentUserHaveAccessToPaidFeatures) {
            this.props.next();
          }
        }
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.changeCustomDomainError,
            {
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              subdomain: getCurrentWebsiteSubdomain(this.props.websites.items),
              new_custom_domain: newCustomDomain,
            },
            true
          );

          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          handleBadWebsiteError(errorData);

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
          if (errorData.message !== undefined) {
            let message = errorData.message;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }

          if (errorData.custom_domain !== undefined) {
            let message = errorData.custom_domain.join(" ");
            this.props.customDomainUpdateErrorMessage(message);
            this.props.customDomainUpdateToggleInvalidAttempt(true);
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.customDomainUpdateWaitingForServer(false);
      });
    // }
    // });
  };

  handleInputChange = (e: any) => {
    this.props.setCustomDomainInputValue(e.target.value.toLowerCase()); // We additionally make it lower in the serializer (unicornplatform/websites/api/serializers.py)
  };

  render() {
    let returnCustomDomainField = () => {
      return (
        <div style={{ width: "100%" }}>
          <Input
            name="custom_domain"
            id="custom_domain"
            required={false}
            placeholder="domain.com"
            type="text"
            value={this.props.customDomainInputValue}
            onChange={this.handleInputChange}
          />
          <div
            className="settings-box__error_message"
            style={{
              opacity:
                this.props.websites.customDomainUpdateErrorMessage === undefined
                  ? 0
                  : 1,
              marginTop:
                this.props.websites.customDomainUpdateErrorMessage === undefined
                  ? "0"
                  : "3px",
            }}
          >
            {this.props.websites.customDomainUpdateErrorMessage}
          </div>
        </div>
      );
    };

    return (
      <form
        onSubmit={this.handleCustomDomainUpdate}
        className="settings-box__form"
        id="custom_domain_form"
      >
        {returnCustomDomainField()}
      </form>
    );
  }
}

const mapActionsToProps = {
  saveUserEventsInStore,
};
export default connect(null, mapActionsToProps)(UpdateCustomDomainForm);
