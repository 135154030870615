import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import _ from "lodash";

export const updateTitle = (componentData, completionContent) => {
  if (!("TITLE" in completionContent)) {
    return;
  }
  const modifiedTitle = stringifyObjectsAndNumbers(completionContent.TITLE);
  componentData.title.content.markup = modifiedTitle || "";
};
