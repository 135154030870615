import React, { Component } from "react";
import { Button, Divider, Icon, Popover, Table, Tag } from "antd";
import { thirdLevelDomainZones } from "../../data/thirdLevelDomainZones";
import { Typography } from "antd";
import { MetaState } from "../../store/meta/types";
import _ from "lodash";
import "./DnsRecordsTable.scss";
import { getSubdomain } from "../../helpers/custom_domain/getSubdomain";
import { SetupVersions } from "./CustomDomainSettingsNew";
const { Paragraph } = Typography;

interface Props {
  currentWebsite: any;
  meta: MetaState;
}

class DnsRecordsTable extends Component<Props> {
  renderV1Table = ({ data, currentCustomDomain, DNSrecordIP }) => {
    const subdomain = getSubdomain(currentCustomDomain);
    data.push({
      hostname: subdomain || "@",
      value: <span style={{ userSelect: "all" }}>{DNSrecordIP}</span>,
      type: "A",
    });
    if (!subdomain) {
      data.push({
        hostname: "www",
        value: <span style={{ userSelect: "all" }}>{DNSrecordIP}</span>,
        type: "A",
      });
    }
  };
  renderV2Table = ({ data, currentCustomDomain }) => {
    const subdomain = getSubdomain(currentCustomDomain);
    if (!subdomain) {
      const aRecords = _.get(
        this.props.currentWebsite,
        "domain_data.domains_a",
        []
      );
      aRecords.forEach((record: string) => {
        data.push({
          hostname: "@",
          value: <span style={{ userSelect: "all" }}>{record}</span>,
          type: "A",
        });
      });
    }
    const cnameRecords = _.get(
      this.props.currentWebsite,
      "domain_data.domains_cname",
      []
    );
    cnameRecords.forEach((record: string) => {
      data.push({
        hostname: subdomain || "www",
        value: <span style={{ userSelect: "all" }}>{record}</span>,
        type: "CNAME",
      });
    });
  };

  render() {
    let currentCustomDomain = this.props.currentWebsite.custom_domain;
    let shouldDisplayWWWtableRow = true;
    let isItApexDomain = true;
    let DNSrecordIP = _.get(this.props.meta, "items[0].default_ip", "");
    const domainSetupVersion = _.get(
      this.props.currentWebsite,
      "domain_setup_version",
      SetupVersions.LEGACY
    );

    let wordsBetweenDotsCount = currentCustomDomain.split(".").length;
    if (wordsBetweenDotsCount === 3) {
      let isThirdLevelDomain = false;
      for (let i = 0; i < thirdLevelDomainZones.length; i++) {
        if (currentCustomDomain.indexOf(thirdLevelDomainZones[i]) !== -1) {
          isThirdLevelDomain = true;
          break;
        }
      }

      // means it is a subdomain (get.domain.com)
      if (isThirdLevelDomain === false) {
        shouldDisplayWWWtableRow = false;
        isItApexDomain = false;
      }
    } else if (wordsBetweenDotsCount > 3) {
      // means it is a subdomain (get.domain.co.uk)
      shouldDisplayWWWtableRow = false;
      isItApexDomain = false;
    }
    const columns = [
      {
        title: "Name",
        dataIndex: "hostname",
        key: "hostname",
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
      },
      {
        title: "Value",
        dataIndex: "value",
        key: "value",
        render: (value: any) => {
          const text = _.get(value, "props.children", "");
          return (
            <Paragraph
              style={{ margin: 0, display: "flex", alignItems: "center" }}
              copyable={{ text }}
            >
              <div
                style={{
                  maxWidth: "calc(100% - 32px)",
                }}
              >
                {value}
              </div>
            </Paragraph>
          );
        },
      },
    ];
    let data = [];
    if (domainSetupVersion !== SetupVersions.APEX_TO_WWW) {
      this.renderV1Table({
        data,
        currentCustomDomain,
        DNSrecordIP,
      });
    } else {
      this.renderV2Table({ data, currentCustomDomain });
    }

    return (
      <div className="dns-table">
        <Table
          size="small"
          pagination={false}
          columns={columns}
          dataSource={data}
          rowKey={(record, i) => {
            return record.hostname + i;
          }}
        />
      </div>
    );
  }
}

export default DnsRecordsTable;
