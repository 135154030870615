import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { pricingItemDetailIconOptions } from "../../data/pricingItemDetailIconOptions";
import isNumericOrDashOrPlus from "../../helpers/editor/isNumericOrDashOrPlus";
import generateRandomNumerousId from "../../helpers/generateRandomNumerousId";
import Emoji from "../component_molecules/Emoji";
import WebsiteButton from "../component_molecules/WebsiteButton";
import PricingDetailsIconSet from "../component_molecules/PricingDetailsIconSet";
import { checkIfPricingTabsAreHidden } from "../../helpers/pricing/checkIfPricingTabsAreHidden";
import { checkIfPricingTabHasCustomText } from "../../helpers/pricing/checkIfPricingTabHasCustomText";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Pricing03 extends Component<Props> {
  generator = new generateContentDataObject();
  randomIndex = generateRandomNumerousId(); //because each Pricing component needs a unique index to switch the 'monthly/yearly' tabs.

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpPricing({
          maxItems: 1,
          hasHighlight: false,
          hasPrimaryInfo: true,
          hasSecondaryInfo: true,
          hasPictogram: false,
          hasDetailsSet: true,

          hasYearlyPrice: true,
          items: [
            this.generator.createPricingContentObject({
              title: !isDefaultEmpty ? "Simple and Fair Pricing" : "",
              primaryInfo: !isDefaultEmpty
                ? "Axona noster historia est. Est clemens eleates, cesaris. Planeta de altus orexis, magicae ionicis tormento! Cum particula crescere, omnes glutenes attrahendam neuter, teres decores."
                : "",
              secondaryInfo: !isDefaultEmpty ? "Perfect for beginners" : "",
              monthlyPrice: !isDefaultEmpty ? 9 : 0,
              yearlyPrice: "",
              monthlyPeriod: !isDefaultEmpty ? "per user" : "",
              yearlyPeriod: !isDefaultEmpty ? "per user" : "",
              button: this.generator.createButtonContentObject({
                buttonType: linkOrButtonOptions.link,
                buttonTitle: !isDefaultEmpty ? "Start now" : "",
                buttonHref: "#",
                buttonStyleType: buttonStyles.regular.typeTitle,
                buttonColor: buttonStyles.regular.accentBg,
                buttonIsTargetBlank: true,
              }),
              detailsSet: !isDefaultEmpty
                ? [
                    this.generator.createPricingItemDetailContentObject({
                      title: "Create multiple websites",
                      icon: pricingItemDetailIconOptions.greenTick,
                      hoverInfo: "",
                    }),
                    this.generator.createPricingItemDetailContentObject({
                      title: "Use all integrations",
                      icon: pricingItemDetailIconOptions.greenTick,
                      hoverInfo: "",
                    }),
                    this.generator.createPricingItemDetailContentObject({
                      title: "Invite collaborators",
                      icon: pricingItemDetailIconOptions.greenTick,
                      hoverInfo: "",
                    }),
                    this.generator.createPricingItemDetailContentObject({
                      title: "Enjoy free SSL",
                      icon: pricingItemDetailIconOptions.greenTick,
                      hoverInfo: "",
                    }),

                    this.generator.createPricingItemDetailContentObject({
                      title: "",
                    }), //ghost item
                  ]
                : [],
            }),
          ],
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let pricing = this.props.componentItem.componentData.pricing.content
      .items[0]; // This component has only 1 pricing card.

    let displayDetailsSet = (pricingObject: any) => {
      let detailsSet = pricingObject.detailsSet;

      if (detailsSet.length === 0) {
        return null;
      } else {
        const detailsItemsJSX = detailsSet.map(
          (detailItem: any, detailKey: any) => {
            if (detailItem.title && detailItem.title !== "") {
              return (
                <li
                  className="pricing-03__benefit"
                  key={"details-item_pricing-03-" + detailKey}
                >
                  <PricingDetailsIconSet
                    detailObj={detailItem}
                    iconClassname="pricing-03__benefit_icon"
                  />

                  <div className="content-text pricing-03__benefit_text js-hover-to-show-sibling">
                    {detailItem.title}
                  </div>
                  {detailItem.hoverInfo && detailItem.hoverInfo !== "" && (
                    <div className="content-text pricing-03__hover js-content-to-show">
                      {detailItem.hoverInfo}
                    </div>
                  )}
                </li>
              );
            } else {
              return null;
            }
          }
        );

        return <ul className="pricing-03__benefits_list">{detailsItemsJSX}</ul>;
      }
    };

    // if (pricing === undefined){
    if (pricing === false) {
      return null;
    } else {
      let pricingMontlyAmount = (pricing && pricing.monthlyPrice) || "";
      let pricingYearlyAmount = (pricing && pricing.yearlyPrice) || "";
      let isEnteredMonthlyAmountNumeric = isNumericOrDashOrPlus(
        pricingMontlyAmount
      ); // we hide the currency symbol and period for non-numeric values (e.g. "contact us")
      let isEnteredYearlyAmountNumeric = isNumericOrDashOrPlus(
        pricingYearlyAmount
      ); // we hide the currency symbol and period for non-numeric values (e.g. "contact us")

      let currencySymbol: string;
      if (isEnteredMonthlyAmountNumeric) {
        currencySymbol = pricing ? pricing.currencySymbol : "?";
      } else {
        currencySymbol = "";
      }

      let monthlyPeriod: string;
      if (isEnteredMonthlyAmountNumeric) {
        monthlyPeriod = pricing ? pricing.monthlyPeriod : "";
      } else {
        monthlyPeriod = "";
      }
      let yearlyPeriod: string;
      if (isEnteredYearlyAmountNumeric) {
        yearlyPeriod = pricing ? pricing.yearlyPeriod : "";
      } else {
        yearlyPeriod = "";
      }

      const { randomIndex } = this;

      let isYearlyTabActiveByDefault = this.props.componentItem.componentData
        .pricing.isYearlyActiveByDefault;
      // we added this property on 11 Sep 2020, so it if undefined for old components
      if (isYearlyTabActiveByDefault === undefined) {
        isYearlyTabActiveByDefault = false;
      }
      const displaySecondaryInfo = () => {
        if (
          pricing.hasIndividualInfo &&
          pricing &&
          pricing.yearlyPrice !== ""
        ) {
          return (
            <>
              {pricing &&
                pricing.secondaryInfo &&
                pricing.secondaryInfo !== "" && (
                  <div
                    className={`content-text def-14 pricing-03__card_text pricing-03__individual-text js-tab-content ${
                      isYearlyTabActiveByDefault === false
                        ? "state-active-tab"
                        : ""
                    }`}
                    data-index="monthly-price-1"
                    data-group={"my-pricing-group-" + randomIndex}
                  >
                    {pricing.secondaryInfo}
                  </div>
                )}
              {pricing &&
                pricing.secondaryInfoYearly &&
                pricing.secondaryInfoYearly !== "" && (
                  <div
                    className={`content-text def-14 pricing-03__card_text pricing-03__individual-text js-tab-content ${
                      isYearlyTabActiveByDefault ? "state-active-tab" : ""
                    }`}
                    data-index="yearly-price-1"
                    data-group={"my-pricing-group-" + randomIndex}
                  >
                    {pricing.secondaryInfoYearly}
                  </div>
                )}
            </>
          );
        }
        return (
          pricing &&
          pricing.secondaryInfo &&
          pricing.secondaryInfo !== "" && (
            <div className="content-text def-14 pricing-03__card_text">{pricing.secondaryInfo}</div>
          )
        );
      };
      return (
        <section>
          <div className="pricing-03">
            <div className="pricing-03__container container">
              <div className="pricing-03__wrapper">
                <div className="pricing-03__info">
                  <h2
                    className={
                      "title-text heading " + (this.props.isDarkBg ? "text-white" : "")
                    }
                  >
                    {(pricing && pricing.title) || "?"}
                  </h2>
                  <div
                    className={
                      "subtitle-text pricing-03__info_text " +
                      (this.props.isDarkBg ? "text-white" : "")
                    }
                    dangerouslySetInnerHTML={{
                      __html: (pricing && pricing.primaryInfo) || "?",
                    }}
                  />
                </div>
                <div
                  className="pricing-03__illustration js-toggle-animation"
                  title="Release sunlight"
                >
                  <Emoji
                    emojiSrc="2601.svg"
                    className="pricing-03__main_img"
                    alt="Emoji cloud"
                  />
                  <Emoji
                    emojiSrc="2600.svg"
                    className="pricing-03__secondary_img"
                    alt="Emoji sun"
                  />
                </div>
                <div className="pricing-03__price__wrapper">
                  {pricing !== undefined &&
                  pricing.yearlyPrice !== "" &&
                  !checkIfPricingTabsAreHidden(
                    this.props.componentItem.componentData.pricing
                  ) && ( //if the pricing item has a yearly price, then display the switcher.
                      <div className="pricing-03__buttons">
                        <ul className="pricing-03__buttons_list">
                          <li className="tab">
                            <button
                              className={
                                "content-text def-12 tab__button js-open-tab " +
                                (isYearlyTabActiveByDefault === false
                                  ? " state-active-tab"
                                  : "")
                              }
                              type="button"
                              data-index="monthly-price-1"
                              data-group={"my-pricing-group-" + randomIndex}
                            >
                              {checkIfPricingTabHasCustomText(
                                this.props.componentItem.componentData.pricing,
                                "left"
                              )
                                ? this.props.componentItem.componentData.pricing
                                    .leftTabText
                                : "Monthly"}
                            </button>
                          </li>
                          <li className="tab">
                            <button
                              className={
                                "content-text def-12 tab__button js-open-tab " +
                                (isYearlyTabActiveByDefault
                                  ? " state-active-tab"
                                  : "")
                              }
                              type="button"
                              data-index="yearly-price-1"
                              data-group={"my-pricing-group-" + randomIndex}
                            >
                              {checkIfPricingTabHasCustomText(
                                this.props.componentItem.componentData.pricing,
                                "right"
                              )
                                ? this.props.componentItem.componentData.pricing
                                    .rightTabText
                                : "Yearly"}
                            </button>
                          </li>
                        </ul>
                        <div
                          className={
                            "pricing-03__badge js-tab-content " +
                            (isYearlyTabActiveByDefault
                              ? " state-active-tab"
                              : "")
                          }
                          data-index="yearly-price-1"
                          data-group={"my-pricing-group-" + randomIndex}
                        >
                          {this.props.componentItem.componentData.pricing
                            .yearlyBonusText &&
                            this.props.componentItem.componentData.pricing
                              .yearlyBonusText !== "" && (
                              <span className="pointed-badge   pointed-badge--acid-bg pointed-badge--top">
                                <span className="pointed-badge__text">
                                  {
                                    this.props.componentItem.componentData
                                      .pricing.yearlyBonusText
                                  }
                                </span>
                                <Emoji
                                  emojiSrc="1f381.svg"
                                  className="emoji"
                                  alt="Emoji Gift"
                                />
                              </span>
                            )}
                        </div>
                      </div>
                    )}
                  <div className="sh-4 pricing-03__price card-container">
                    <div className="pricing-03__price_top">
                      <div className="pricing-03__price_holder">
                        <h2
                          data-index="monthly-price-1"
                          data-group={"my-pricing-group-" + randomIndex}
                          className={
                            "content-text def-32 pricing-03__price_text heading js-tab-content " +
                            (isYearlyTabActiveByDefault === false
                              ? " state-active-tab"
                              : "")
                          }
                        >
                          {currencySymbol}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: pricingMontlyAmount + " " + monthlyPeriod,
                            }}
                          />
                        </h2>
                        <h2
                          data-index="yearly-price-1"
                          data-group={"my-pricing-group-" + randomIndex}
                          className={
                            "content-text def-32 pricing-03__price_text heading js-tab-content" +
                            (isYearlyTabActiveByDefault
                              ? " state-active-tab"
                              : "")
                          }
                        >
                          {currencySymbol}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: pricingYearlyAmount + " " + yearlyPeriod,
                            }}
                          />
                        </h2>
                        {/* this tag is hidden. it is needed to give the parent (pricing-03__price_holder) the same vertical as the title */}
                        <h2
                          data-index="monthly-price-1"
                          data-group={"my-pricing-group-" + randomIndex}
                          style={{ opacity: 0 }}
                          className={
                            "heading js-tab-content " +
                            (isYearlyTabActiveByDefault === false
                              ? " state-active-tab"
                              : "")
                          }
                        >
                          {currencySymbol}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: pricingMontlyAmount + " " + monthlyPeriod,
                            }}
                          />
                        </h2>
                      </div>
                      {displaySecondaryInfo()}
                    </div>
                    <div className="pricing-03__price_bottom">
                      {pricing &&
                        pricing.detailsSet &&
                        pricing.detailsSet.length !== 1 &&
                        displayDetailsSet(pricing)}
                      <div className="pricing-03__button">
                        {pricing && pricing.button.title !== "" && (
                          <WebsiteButton
                            config={{
                              buttonStyleType:
                                pricing.button.settings.appearance.styleType,
                              buttonColor:
                                pricing.button.settings.appearance.color,
                              buttonType: linkOrButtonOptions.link,
                              buttonTitle: pricing.button.title,
                              buttonHref: pricing.button.href,
                              buttonIsTargetBlank: pricing.button.isTargetBlank,
                              pillPrefix: pricing.button.pillPrefix,
                              pillColor:
                                pricing.button.settings.appearance.pillColor,
                              ghostColor:
                                pricing.button.settings.appearance.ghostColor,
                              buttonObject: pricing.button,
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
  }
}

export default Pricing03;
