export const getWebsiteObjectFromID = (websiteId: number|undefined, websitesArray: any) => {
    if (websiteId === undefined){
        return undefined;
    }

    let website = websitesArray.filter((item: any) => {
        // find the given ID website among the given websites list
        // returns an array with one element
        return (item.id === websiteId)
    });
    return website[0];
};