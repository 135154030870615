import { ComponentTypes } from "../enums/ComponentTypes";
import { Components } from "../enums/Components";
import { getActualComponentType } from "../helpers/getActualComponentType";
import { getActualComponentName } from "../helpers/getActualComponentName";

export class UnicornComponent {
  readonly id: string; // e.g header-44-658771
  readonly name: Components; // e.g header-44
  readonly type: ComponentTypes; // e.g. header
  constructor(component) {
    this.id = component.id;
    this.name = getActualComponentName(component);
    this.type = getActualComponentType(component);
  }
}
