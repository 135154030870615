import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { pictogramOptions } from "../../data/pictogramOptions";
import isNumericOrDashOrPlus from "../../helpers/editor/isNumericOrDashOrPlus";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Emoji from "../component_molecules/Emoji";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { checkIfPricingTabsAreHidden } from "../../helpers/pricing/checkIfPricingTabsAreHidden";
import { checkIfPricingTabHasCustomText } from "../../helpers/pricing/checkIfPricingTabHasCustomText";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Pricing01 extends Component<Props> {
  generator = new generateContentDataObject();
  randomIndex = Math.floor(Math.random() * 9999); //because each Pricing component needs a unique index to switch the 'monthly/yearly' tabs.

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({
          titleText: "Affordable Pricing for Everyone",
          isDefaultEmpty,
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({
          subtitleText:
            "No nickel & dime. Raptus silva una contactuss mortem est. Cum assimilatio crescere, omnes absolutioes reperire rusticus, ferox burguses.",
          isDefaultEmpty,
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a CTA (call to action) to the bottom of the pricing component to offer more ways to get your product. E.g. a sales contact link.",
            default:
              "Add a CTA (call to action) to the bottom of the pricing component to offer more ways to get your product. E.g. a sales contact link.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "BTW",
            }),
          ],
          priority: 10,
          isDefaultEmpty,
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpPricing({
          maxItems: 64,
          hasHighlight: false,
          hasSecondaryInfo: false,
          hasDetailsSet: false,
          items: [
            this.generator.createPricingContentObject({
              title: "Freelancer",
              primaryInfo:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim veniam.",
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f6f4.svg",
              }),
              monthlyPrice: 9,
              button: this.generator.createButtonContentObject({
                buttonType: linkOrButtonOptions.link,
                buttonTitle: "Start now",
                buttonHref: "#",
                buttonStyleType: buttonStyles.regular.typeTitle,
                buttonColor: buttonStyles.regular.accentOutline,
                buttonIsTargetBlank: true,
              }),
            }),
            this.generator.createPricingContentObject({
              title: "Team",
              primaryInfo:
                "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa saint.",
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f3cd.svg",
              }),
              monthlyPrice: 19,
              button: this.generator.createButtonContentObject({
                buttonType: linkOrButtonOptions.link,
                buttonTitle: "Start now",
                buttonHref: "#",
                buttonStyleType: buttonStyles.regular.typeTitle,
                buttonColor: buttonStyles.regular.accentBg,
                buttonIsTargetBlank: true,
              }),
            }),
          ],
          isDefaultEmpty
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let pricing = this.props.componentItem.componentData.pricing;

    const { randomIndex } = this;
    let isYearlyTabActiveByDefault = pricing.isYearlyActiveByDefault;
    // we added this property on 11 Sep 2020, so it if undefined for old components
    if (isYearlyTabActiveByDefault === undefined) {
      isYearlyTabActiveByDefault = false;
    }

    return (
      <section>
        <div className="pricing-01">
          <TitleBox
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />
          <div className="container">
            {pricing.content.items[0] &&
            pricing.content.items[0].yearlyPrice !== "" &&
            !checkIfPricingTabsAreHidden(pricing) && ( //if the first pricing item has a yearly price, then display the switcher.
                <div className="pricing-01__buttons">
                  <ul className="pricing-01__buttons_list">
                    <li className="tab">
                      <button
                        className={
                          "content-text def-12 tab__button js-open-tab " +
                          (isYearlyTabActiveByDefault === false
                            ? " state-active-tab"
                            : "")
                        }
                        type="button"
                        data-index="monthly-price-1"
                        data-group={"my-pricing-group-" + randomIndex}
                      >
                        {checkIfPricingTabHasCustomText(pricing, "left")
                          ? pricing.leftTabText
                          : "Monthly"}
                      </button>
                    </li>
                    <li className="tab">
                      <button
                        className={
                          "content-text def-12 tab__button js-open-tab " +
                          (isYearlyTabActiveByDefault
                            ? " state-active-tab"
                            : "")
                        }
                        type="button"
                        data-index="yearly-price-1"
                        data-group={"my-pricing-group-" + randomIndex}
                      >
                        {checkIfPricingTabHasCustomText(pricing, "right")
                          ? pricing.rightTabText
                          : "Yearly"}
                      </button>
                    </li>
                  </ul>

                  <div
                    className={
                      "pricing-01__badge js-tab-content " +
                      (isYearlyTabActiveByDefault ? " state-active-tab" : "")
                    }
                    data-index="yearly-price-1"
                    data-group={"my-pricing-group-" + randomIndex}
                  >
                    {pricing.yearlyBonusText && pricing.yearlyBonusText !== "" && (
                      <span className="pointed-badge   pointed-badge--acid-bg pointed-badge--left">
                        <span className="pointed-badge__text">
                          {pricing.yearlyBonusText}
                        </span>
                        <Emoji
                          emojiSrc="1f381.svg"
                          className="emoji"
                          alt="Emoji Gift"
                        />
                      </span>
                    )}
                  </div>
                </div>
              )}
          </div>
          <div className="container">
            <div className="pricing-01__cards_container">
              <ul className="pricing-01__cards_list">
                {pricing.content.items &&
                  pricing.content.items.map((item: any, key: number) => {
                    if (
                      item.monthlyPrice !== undefined &&
                      item.monthlyPrice !== ""
                    ) {
                      //'' when no price is entered by a user.
                      let isEnteredMonthlyAmountNumeric = isNumericOrDashOrPlus(
                        item.monthlyPrice
                      ); // we hide the currency symbol and period for non-numeric values (e.g. "contact us")

                      let currencySymbol: string;
                      if (isEnteredMonthlyAmountNumeric) {
                        currencySymbol = item.currencySymbol;
                      } else {
                        currencySymbol = "";
                      }

                      return (
                        <li
                          className="pricing-01__card_box card-container sh-4"
                          key={"pricing-table-item-" + key + "-" + randomIndex}
                        >
                          <div className="pricing-01__card">
                            {item.pictogram.type && item.pictogram.type !== "" && (
                              <div className="pricing-01__card_top">
                                <Pictogram
                                  pictogramObject={item.pictogram}
                                  className="pricing-01__card_img"
                                />
                              </div>
                            )}

                            <div className="pricing-01__card_bottom">
                              <div className="pricing-01__card_title_box">
                                {item.title && item.title !== "" && (
                                  <h3 className="def-22 title-text--inner pricing-01__card_title">
                                    {item.title}
                                  </h3>
                                )}
                                <div className="pricing-01__card_price_box">
                                  <div
                                    className={
                                      "content-text def-22 pricing-01__card_price js-tab-content " +
                                      (isYearlyTabActiveByDefault
                                        ? " state-active-tab"
                                        : "")
                                    }
                                    data-index="yearly-price-1"
                                    data-group={
                                      "my-pricing-group-" + randomIndex
                                    }
                                  >
                                    {currencySymbol}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          item.yearlyPrice === ""
                                            ? "?"
                                            : item.yearlyPrice,
                                      }}
                                    />
                                    {/*If item.yearlyPrice is an empty string, display '?' to the template so the user notices this and fills in a value*/}
                                    {isEnteredMonthlyAmountNumeric && (
                                      <div
                                        className="content-text def-12 pricing-01__card_period"
                                        dangerouslySetInnerHTML={{
                                          __html: item.yearlyPeriod,
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div
                                    className={
                                      "content-text def-22 pricing-01__card_price js-tab-content " +
                                      (isYearlyTabActiveByDefault === false
                                        ? " state-active-tab"
                                        : "")
                                    }
                                    data-index="monthly-price-1"
                                    data-group={
                                      "my-pricing-group-" + randomIndex
                                    }
                                  >
                                    {currencySymbol}
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: item.monthlyPrice,
                                      }}
                                    />
                                    {isEnteredMonthlyAmountNumeric && (
                                      <div
                                        className="pricing-01__card_period content-text def-12"
                                        dangerouslySetInnerHTML={{
                                          __html: item.monthlyPeriod,
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="content-text pricing-01__card_info text content_box"
                                dangerouslySetInnerHTML={{
                                  __html: item.primaryInfo,
                                }}
                              />
                              <div className="pricing-01__card_button">
                                {item.button.title !== "" && (
                                  <WebsiteButton
                                    config={{
                                      buttonStyleType:
                                        item.button.settings.appearance
                                          .styleType,
                                      buttonColor:
                                        item.button.settings.appearance.color,
                                      buttonType: linkOrButtonOptions.link,
                                      buttonTitle: item.button.title,
                                      buttonHref: item.button.href,
                                      buttonIsTargetBlank:
                                        item.button.isTargetBlank,
                                      pillPrefix: item.button.pillPrefix,
                                      pillColor:
                                        item.button.settings.appearance
                                          .pillColor,
                                      ghostColor:
                                        item.button.settings.appearance
                                          .ghostColor,
                                      buttonObject: item.button,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    }
                  })}
              </ul>
            </div>
          </div>

          <div className="bottom_cta">
            {this.props.componentItem.componentData.cta && (
              <WebsiteCTA componentItem={this.props.componentItem} />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Pricing01;
