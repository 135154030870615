import actionsNames from "./actions";
import { SharedAccessesState } from "./types";

let initialState: SharedAccessesState = {
  items: [],
  dataFetched: false,
  isWaitingForChangeSharedAccessResponse: false,
};

export const sharedAccessesReducer = (
  state: SharedAccessesState = initialState,
  action: any
) => {
  switch (action.type) {
    case actionsNames.SAVE_SHARED_ACCESS_DATA:
      return {
        ...state,
        items: [...action.payload],
      };

    case actionsNames.ADD_NEW_SHARED_ACCESS_ITEM:
      let updatedItems = [...state.items];
      updatedItems.push(action.payload);
      return {
        ...state,
        items: updatedItems,
      };

    case actionsNames.REMOVE_SHARED_ACCESS_ITEM:
      let itemIdToBeRemoved = action.payload;
      let allItems = [...state.items];
      let allItemsExceptTheGivenOne = allItems.filter((item: any) => {
        // return all items except the one which has the ID of the item to be removed
        return !(item.id === itemIdToBeRemoved);
      });

      return {
        ...state,
        items: allItemsExceptTheGivenOne,
      };

    case actionsNames.TOGGLE_SHARED_ACCESS_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };
    case actionsNames.TOGGLE_CHANGE_SHARED_ACCESS_WAITING_FOR_SERVER:
      return {
        ...state,
        isWaitingForChangeSharedAccessResponse: action.payload,
      };

    default:
      return state;
  }
};
