import {
  AddChatMessage,
  SetCompletionStatus,
  SetIsLoading,
  SetIsOpenedOnce,
  SetIsTyping,
  DeleteCompletionProgress,
  AddModifiedComponent,
  DeleteModifiedComponent,
  SetChangeHistoryData,
  AddComponentId,
  EditChatMessage,
  SetMode,
  EditInputData,
  AddPromptHistoryItem,
  DeletePromptHistoryItem,
  AddPromptHistoryForAiItem,
} from "./types";

export enum ActionNames {
  ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE",
  SET_IS_TYPING = "SET_IS_TYPING",
  SET_IS_OPENED_ONCE = "SET_IS_OPENED_ONCE",
  SET_IS_LOADING = "SET_IS_LOADING",
  SET_COMPLETION_STATUS = "SET_COMPLETION_STATUS",
  DELETE_COMPLETION_PROGRESS = "DELETE_COMPLETION_PROGRESS",
  ADD_MODIFIED_COMPONENT = "ADD_MODIFIED_COMPONENT",
  DELETE_MODIFIED_COMPONENT = "DELETE_MODIFIED_COMPONENT",
  SET_CHANGE_HISTORY_DATA = "SET_CHANGE_HISTORY_DATA",
  ADD_COMPONENT_ID = "ADD_COMPONENT_ID",
  EDIT_CHAT_MESSAGE = "EDIT_CHAT_MESSAGE",
  SET_MODE = "SET_MODE",

  // Contextual inputs
  SET_IS_INPUT_LOADING = "SET_IS_INPUT_LOADING",
  EDIT_INPUT_DATA = "EDIT_INPUT_DATA",
  ADD_PROMPT_HISTORY_ITEM = "ADD_PROMPT_HISTORY_ITEM",
  DELETE_PROMPT_HISTORY_ITEM = "DELETE_PROMPT_HISTORY_ITEM",
  ADD_PROMPT_HISTORY_FOR_AI_ITEM = "ADD_PROMPT_HISTORY_FOR_AI_ITEM",
}

export const addChatMessage: AddChatMessage = (payload) => {
  return {
    type: ActionNames.ADD_CHAT_MESSAGE,
    payload,
  };
};
export const setIsTyping: SetIsTyping = (payload) => {
  return {
    type: ActionNames.SET_IS_TYPING,
    payload,
  };
};
export const setIsOpenedOnce: SetIsOpenedOnce = (payload) => {
  return {
    type: ActionNames.SET_IS_OPENED_ONCE,
    payload,
  };
};
export const setIsLoading: SetIsLoading = (payload) => {
  return {
    type: ActionNames.SET_IS_LOADING,
    payload,
  };
};
export const setCompletionStatus: SetCompletionStatus = (payload) => {
  return {
    type: ActionNames.SET_COMPLETION_STATUS,
    payload,
  };
};
export const deleteCompletionProgress: DeleteCompletionProgress = (payload) => {
  return {
    type: ActionNames.DELETE_COMPLETION_PROGRESS,
    payload,
  };
};
export const addModifiedComponent: AddModifiedComponent = (payload) => {
  return {
    type: ActionNames.ADD_MODIFIED_COMPONENT,
    payload,
  };
};
export const deleteModifiedComponent: DeleteModifiedComponent = (payload) => {
  return {
    type: ActionNames.DELETE_MODIFIED_COMPONENT,
    payload,
  };
};
export const setChangeHistoryData: SetChangeHistoryData = (payload) => {
  return {
    type: ActionNames.SET_CHANGE_HISTORY_DATA,
    payload,
  };
};
export const addComponentId: AddComponentId = (payload) => {
  return {
    type: ActionNames.ADD_COMPONENT_ID,
    payload,
  };
};
export const editChatMessage: EditChatMessage = (payload) => {
  return {
    type: ActionNames.EDIT_CHAT_MESSAGE,
    payload,
  };
};
export const setMode: SetMode = (payload) => {
  return {
    type: ActionNames.SET_MODE,
    payload,
  };
};

// Contextual inputs
export const editInputData: EditInputData = (payload) => {
  return {
    type: ActionNames.EDIT_INPUT_DATA,
    payload,
  };
};
export const addPromptHistoryItem: AddPromptHistoryItem = (payload) => {
  return {
    type: ActionNames.ADD_PROMPT_HISTORY_ITEM,
    payload,
  };
};
export const deletePromptHistoryItem: DeletePromptHistoryItem = (payload) => {
  return {
    type: ActionNames.DELETE_PROMPT_HISTORY_ITEM,
    payload,
  };
};
export const addPromptHistoryForAiItem: AddPromptHistoryForAiItem = (
  payload
) => {
  return {
    type: ActionNames.ADD_PROMPT_HISTORY_FOR_AI_ITEM,
    payload,
  };
};
