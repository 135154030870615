import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Photos01 extends Component<Props> {
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if (this.props.checkIsEmptyContent()) {
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "We Never Stop Smiling!"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: 'Hi and nice to meet you! This is our brave team. We work very hard to provide an awesome experience for you.'}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,

                fieldTitle: {default: "Photos"},
                label: {default: "Edit the photos:"},
                fieldInfo: {default: "Recommended: square 560x560px, JPG or PNG."},
                labelTooltip: {default: "Show your team's beautiful faces to establish an emotional connection with your audience."},

                maxItems: 66,
                hasThumb: true,
                hasSubtitle: false,
                hasTitle: false,
                items: [
                    this.generator.createPostsContentObject({
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PERSON_17,
                            width: 400,
                            height: 400,
                        }),
                    }),
                    this.generator.createPostsContentObject({
                        thumb: this.generator.createGraphicsContentObject({
                          imageUrl: DEFAULT_IMAGES_CDN.PERSON_18,
                          width: 400,
                          height: 400,
                        }),
                    }),
                    this.generator.createPostsContentObject({
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PERSON_19,
                            width: 400,
                            height: 400,
                        }),
                    }),
                    this.generator.createPostsContentObject({
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PERSON_20,
                            width: 400,
                            height: 400,
                        }),
                    }),
                    this.generator.createPostsContentObject({
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PERSON_21,
                            width: 358,
                            height: 358,
                        }),
                    }),
                    this.generator.createPostsContentObject({
                        thumb: this.generator.createGraphicsContentObject({
                          imageUrl: DEFAULT_IMAGES_CDN.PERSON_22,
                          width: 396,
                          height: 396,
                        }),
                    }),
                    this.generator.createPostsContentObject({
                        thumb: this.generator.createGraphicsContentObject({
                          imageUrl: DEFAULT_IMAGES_CDN.PERSON_23,
                          width: 400,
                          height: 400,
                        }),
                    })
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the photos component to offer more photos to see.",
                    default: "Add a button to the bottom of the photos component to offer more photos to see.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render() {
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        return (
            <section>
                <div className="photos-01">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container container--large">
                        <div className="photos-01__images_row">
                            {posts && posts.map((item: any, key: number) => {
                                let photosItemJSX = (
                                    <span className="photos-01__person" key={'photos-01-' + key}>
                                        <div className="photos-01__image_box">
                                            <img 
                                            src={formatImageUrl(item.thumb.image.url)} 
                                            alt={item.thumb.image.alt} 
                                            className="photos-01__image js-lightbox-single-image"
                                            data-width={item.thumb.image.width ? item.thumb.image.width : 400}
                                            data-height={item.thumb.image.height ? item.thumb.image.height : 400}
                                            />
                                        </div>
                                    </span>);

                                if(item.href && item.href !==''){
                                    photosItemJSX = (
                                        <a className="photos-01__person" key={'photos-01-' + key}
                                           href={returnHref(item.href)}>
                                            <div className="photos-01__image_box">
                                                <img src={formatImageUrl(item.thumb.image.url)} alt={item.thumb.image.alt} className="photos-01__image photos-01__link"/>
                                            </div>
                                        </a>);
                                }

                                if (item !== undefined && item.thumb !== undefined && item.thumb.image !== undefined && item.thumb.image.url !== '') {
                                    return (photosItemJSX)
                                } else {
                                    return (null);
                                }
                            })}
                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Photos01;
