import React from "react";
import { getOauth2AppLogoUrl } from "../../../../helpers/auth/getOauth2AppLogoUrl";
import { AccessToken } from "../../../../store/oauth2/types";

interface Props {
  token: AccessToken;
}

const Oauth2AppName = (props: Props): JSX.Element => {
  const { token } = props;
  const appLogoUrl = getOauth2AppLogoUrl(token.application);

  return (
    <div className="application_menu__title--apps">
      {appLogoUrl && (
        <img className="application_menu__logo" src={appLogoUrl} />
      )}{" "}
      <b>{token.application}</b>
    </div>
  );
};

export default Oauth2AppName;
