import { isKeyValue } from "./../../../../helpers/isKeyValue";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemUrl = stringifyObjectsAndNumbers(modifiedItem.URL) || "";
  return {
    itemId,
    itemPosition,
    itemUrl,
  };
};

const particleModifyOriginalItem = (
  content,
  originalItem,
  modifiedItem,
  itemPosition,
  itemUrl
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(content.items, item);
      }
    });
  }
  if ("URL" in modifiedItem) {
    originalItem.href = itemUrl;
  }
};

export const updateSocialLinks = (componentData, completionContent) => {
  const content = componentData.socialLinks.content;
  if (!("SOCIAL_LINKS" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.SOCIAL_LINKS)) {
    if (completionContent.SOCIAL_LINKS === null) {
      content.items = [];
      content.title = "";
    }
    return;
  }
  if ("TITLE" in completionContent.SOCIAL_LINKS) {
    content.title =
      stringifyObjectsAndNumbers(completionContent.SOCIAL_LINKS.TITLE) || "";
  }
  if (!("LINKS" in completionContent.SOCIAL_LINKS)) {
    return;
  }
  if (!Array.isArray(completionContent.SOCIAL_LINKS.LINKS)) {
    if (completionContent.SOCIAL_LINKS.LINKS === null) {
      content.items = [];
    }
    return;
  }
  content.items = deleteEmptyItems(content.items, "href");
  addDefaultValuesToItems(content.items);
  completionContent.SOCIAL_LINKS.LINKS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const { itemId, itemPosition, itemUrl } = particleGetModifiedValues(
      modifiedItem
    );
    if (itemPosition === null) {
      content.items = deleteItem(content.items, itemId);
      return;
    }
    const isItemNew = getIsItemNew(content.items, itemId);
    if (isItemNew) {
      const newItem = {
        href: "",
      };
      addNewItem(content.items, newItem, itemId, itemPosition);
    }
    const originalItem = getOriginalItem(content.items, itemId);
    particleModifyOriginalItem(
      content,
      originalItem,
      modifiedItem,
      itemPosition,
      itemUrl
    );
  });
  sortItemsByPosition(content.items);
};
