import { getPageArray } from "../../../components/editor/ai2/getPageArray";
import { editInputData } from "../actions";
import { GptState, HandleOnUnmount } from "../types";
import { handleOnComponentUpdateFinished } from "./handleOnComponentUpdateFinished";
import { updateComponentData } from "./updateComponentData";

export const handleOnUnmount: HandleOnUnmount = ({
  intervalRef,
  forceStopAllFlagRef,
  queueDataRef,
  currentWebsitePage,
  currentWebsite,
  currentPageIndex,
  abortControllersRef,
}) => {
  return (dispatch, getState) => {
    const pageId = currentWebsitePage.id;
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    forceStopAllFlagRef.current = true;
    const pageArray = getPageArray(currentWebsitePage, currentWebsite);
    pageArray.forEach((component) => {
      const componentId = component.id;
      const queueItem = queueDataRef.current.find(
        (item) => item.componentId === componentId
      );
      if (queueItem) {
        dispatch(
          updateComponentData({
            currentWebsitePage,
            currentWebsite,
            componentId,
            queueDataRef,
            currentPageIndex,
          })
        );
        dispatch(
          handleOnComponentUpdateFinished({
            pageId,
            currentWebsitePage,
            currentWebsite,
            currentPageIndex,
            componentId,
            queueDataRef,
          })
        );
      }
    });
    abortControllersRef.current.forEach((controllerItem) => {
      controllerItem.abortController.abort();
    });
    abortControllersRef.current = [];
    const gptState: GptState = getState().gpt;
    gptState.contextualInputs.forEach((input) => {
      dispatch(
        editInputData({
          componentId: input.componentId,
          newData: {
            isLoading: false,
          },
        })
      );
    });
  };
};
