export const generateUniqueUrl = (
  itemName: string,
  items: any[],
  i = 1
): string => {
  const newName = i === 1 ? itemName : `${itemName}-${i}`;
  if (items.some((item) => item.url === newName)) {
    return generateUniqueUrl(itemName, items, i + 1);
  }
  return newName;
};
