import React from "react";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import BottomCtaInfo from "./BottomCtaInfo";
import WebsiteButton from "./WebsiteButton";

interface Props {
  buttons: any;
  isForcedWhiteButtonElements?: boolean;
  bottomInfoMarkup?: string;
  isDarkBg?: boolean;
}

const LinkButtons = (props: Props): JSX.Element => {
  const {
    buttons,
    isForcedWhiteButtonElements,
    bottomInfoMarkup,
    isDarkBg,
  } = props;

  // This function displays link-buttons in a component: it takes the buttons obj, parces it and applies settings (target, style, text, href etc) to the buttons before rendering JSX.
  // Link-buttons are buttons placed anywhere but in forms: nav, features, headers, CTA buttons.
  // These buttons have more settings: they can be, e.g., App Store/Google Play buttons
  // Link buttons can have specific logic: it can be just a href to external service or they can scroll to a page component if they have a "#component-id" type href.

  if (buttons.length === 1 && buttons[0].title === "") {
    // If have only 1 button and it's a ghost button, do not display.
    return null;
  } else {
    let buttonsJSX = buttons.map((item: any, key: number) => {
      if (item.title !== "") {
        //Sometimes we need to force white buttons. E.g. when user had black buttons in the nav, but on a new page the first component turned out to be black too. That looks awful. That is why we display all buttons in the nav white when the first component is black, accent or mature bg.

        let buttonColor = buttons[key].settings.appearance.color;
        let isButtonColorForcedToWhite = false;

        if (
          isForcedWhiteButtonElements !== undefined &&
          isForcedWhiteButtonElements
        ) {
          isButtonColorForcedToWhite = true;
        }

        if (isButtonColorForcedToWhite) {
          if (buttonColor.indexOf("Outline") !== -1) {
            // If current button has outline color, keep it outline, but white.
            buttonColor = buttonStyles.regular.whiteOutline;
          } else {
            buttonColor = buttonStyles.regular.whiteBg;
          }
        }

        return (
          <li
            className="buttons-set__item"
            key={"button-item-" + key.toString()}
          >
            <WebsiteButton
              config={{
                buttonStyleType: buttons[key].settings.appearance.styleType,
                buttonColor: buttonColor,
                buttonType: linkOrButtonOptions.link,
                buttonTitle: buttons[key].title,
                buttonHref: buttons[key].href,
                buttonIsTargetBlank: buttons[key].isTargetBlank,
                buttonMobileAppStoreType:
                  buttons[key].settings.appearance.mobileAppStoreType,
                pillPrefix: isButtonColorForcedToWhite
                  ? buttonStyles.pill.white
                  : buttons[key].pillPrefix,
                pillColor: isButtonColorForcedToWhite
                  ? buttonStyles.pill.white
                  : buttons[key].settings.appearance.pillColor,
                ghostColor: isButtonColorForcedToWhite
                  ? buttonStyles.ghost.whiteOutline
                  : buttons[key].settings.appearance.ghostColor,
              }}
            />
          </li>
        );
      } else {
        return null;
      }
    });

    let isAllButtonsHidden = true; //if this true we hide the whole buttons component. This can happen if we have 2, 3 or more ghost buttons (e.g. user created some buttons and them removed titles of ALL buttons).
    buttons.map((item: any, key: number) => {
      if (item.title !== "") {
        isAllButtonsHidden = false;
      }
    });

    if (isAllButtonsHidden) {
      return null;
    } else {
      return (
        <div className="buttons-set">
          <ul className="buttons-set__list">{buttonsJSX}</ul>
          <BottomCtaInfo markup={bottomInfoMarkup} isDarkBg={isDarkBg} />
        </div>
      );
    }
  }
};

export default LinkButtons;
