import React, { Component } from "react";
import { urls } from "./urls";
export const pricingTiersFeatures = [
  {
    'title': (<b>Published websites</b>),
    'info': (<span>The amount of websites you are able to publish live</span>),
    'lurker': 1,
    'maker': 1,
    'startup': 1,
    'business': 3,
    'freelancer': 8,
    'agency': 20,
    'major': 100
  },

  {
    'title': (<span>Pages per website</span>),
    'info': (<span>The amount of pages per website you can create.</span>),
    'lurker': 10,
    'maker': '∞',
    'startup': '∞',
    'business': '∞',
    'freelancer': '∞',
    'agency': '∞',
    'major': '∞'
  },

  {
    'title': (<span>Blog posts</span>),
    'info': (<span>The amount of blog posts you are able to create.</span>),
    'lurker': 10,
    'maker': 10,
    'startup': '∞',
    'business': '∞',
    'freelancer': '∞',
    'agency': '∞',
    'major': '∞'
  },

  {
    'title': (<span>Free SSL & CDN</span>),
    'info': (<span>Each website is powered with an SSL certificate and CDN.</span>),
    'lurker': true,
    'maker': true,
    'startup': true,
    'business': true,
    'freelancer': true,
    'agency': true,
    'major': true
  },

  {
    'title': (<span>Custom code</span>),
    'info': (<span>Add your own HTML/CSS/JS code (such as Google Analytics, live chat etc) on any page or blog.</span>),
    'lurker': true,
    'maker': true,
    'startup': true,
    'business': true,
    'freelancer': true,
    'agency': true,
    'major': true
  },

  {
    'title': (<span>Form integrations</span>),
    'info': (<span>Power up your forms with integrations (Zapier, Mailchimp etc). The "Google Sheet" and "Send to Email" are available only on paid plans.</span>),
    'lurker': true,
    'maker': true,
    'startup': true,
    'business': true,
    'freelancer': true,
    'agency': true,
    'major': true
  },

  {
    'title': (<b>Custom domain</b>),
    'info': (<span>Connect your website to a custom domain or subdomain.</span>),
    'lurker': false,
    'maker': true,
    'startup': true,
    'business': true,
    'freelancer': true,
    'agency': true,
    'major': true
  },

  {
    'title': (<span>HTML export</span>),
    'info': (<span>Export whole website as HTML to host it on your own server.</span>),
    'lurker': false,
    'maker': true,
    'startup': true,
    'business': true,
    'freelancer': true,
    'agency': true,
    'major': true
  },

  {
    'title': (<span>Remove branding</span>),
    'info': (<span>Remove 'Runs on Unicorn Platform badge'.</span>),
    'lurker': false,
    'maker': false,
    'startup': true,
    'business': true,
    'freelancer': true,
    'agency': true,
    'major': true
  },
  
  {
    'title': (<span>reCAPTCHA</span>),
    'info': (<span>Protect your contact forms with invisible reCAPTCHA.</span>),
    'lurker': false,
    'maker': false,
    'startup': true,
    'business': true,
    'freelancer': true,
    'agency': true,
    'major': true
  },

  {
    'is_xyz': true,
    'title': (<b>Free .xyz domain 🎁&nbsp;</b>),
    'info': (<span>Get a free .xyz domain for 1 year. <a href={urls.guides.xyz} target="_blank">Learn more</a></span>),
    'lurker': false,
    'maker': false,
    'startup': true,
    'business': true,
    'freelancer': true,
    'agency': true,
    'major': true
  },

  {
    'title': (<span>Collaborators</span>),
    'info': (<span>Add editors to manage your website with your partners or friends.</span>),
    'lurker': false,
    'maker': false,
    'startup': '1 collaborator',
    'business': '∞',
    'freelancer': '∞',
    'agency': '∞',
    'major': '∞'
  },  

  {
    'is_deals': true,
    'title': (<span>Deals from partners</span>),
    'info': (<span>Get access to exclusive deals from our partners.</span>),
    'lurker': false,
    'maker': false,
    'startup': true,
    'business': true,
    'freelancer': true,
    'agency': true,
    'major': true
  },
];