import { Button } from "antd";
import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  saveContentInStore: any;
  generator: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  toggleNewEditionsDetected: (value: boolean) => void;
  content: any;
}

const AddNewStepButton = (props: Props): JSX.Element => {
  const {
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
    content,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const maxItemsReached = currentInputDataRef.current.maxItems === content.items.length;

  const handleAddNewStep = () => {
    toggleNewEditionsDetected(true);
    saveContentInStore(
      generator.addEmptyStepIfNeeded({
        [contentType]: currentInputDataRef.current,
      })
    );
  };

  if (maxItemsReached) return null;

  return (
    <Button size="small" onClick={handleAddNewStep} icon="plus">
      Add new step
    </Button>
  );
};

export default AddNewStepButton;
