import _ from "lodash";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";

const generator = new generateContentDataObject();

const particleUpdateButton = (
  originalItem,
  modifiedItem,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if (modifiedItem.BUTTON === null) {
    originalItem.button.title = "";
    originalItem.button.href = "";
    originalItem.button.isTargetBlank = false;
  }
  if (!isKeyValue(modifiedItem.BUTTON)) {
    return;
  }
  if ("TEXT" in modifiedItem.BUTTON) {
    originalItem.button.title = itemButtonText;
  }
  if ("URL" in modifiedItem.BUTTON) {
    originalItem.button.href = itemButtonUrl;
  }
  if ("TARGET" in modifiedItem.BUTTON) {
    originalItem.button.isTargetBlank = itemButtonTarget === "_blank";
  }
};

const particleModifyOriginalItem = (
  originalItem,
  modifiedItem,
  itemTitle,
  itemSubtitle,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("SUBTITLE" in modifiedItem) {
    originalItem.subtitle = itemSubtitle;
  }
  if ("BUTTON" in modifiedItem) {
    particleUpdateButton(
      originalItem,
      modifiedItem,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget
    );
  }
};

const particleGetModifiedValues = (modifiedItem) => {
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  const itemSubtitle = stringifyObjectsAndNumbers(modifiedItem.SUBTITLE) || "";
  const itemButtonText =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TEXT"])) || "";
  const itemButtonUrl =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "URL"])) || "";
  const itemButtonTarget =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TARGET"])) ||
    "_self";
  return {
    itemTitle,
    itemSubtitle,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
  };
};

export const updateForm4BottomInfo = (componentData, completionContent) => {
  if (!("BOTTOM_INFO" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.BOTTOM_INFO)) {
    if (completionContent.BOTTOM_INFO === null) {
      componentData.posts.content.items = [];
    }
    return;
  }
  if (!componentData.posts.content.items[0]) {
    const newItem = generator.createPostsContentObject();
    newItem.title = "";
    newItem.subtitle = "";
    newItem.button.title = "";
    newItem.button.href = "";
    componentData.posts.content.items = [newItem];
  }
  const originalItem = componentData.posts.content.items[0];
  const modifiedItem = completionContent.BOTTOM_INFO;
  originalItem.IS_BEING_MODIFIED_BY_AI = true;
  const {
    itemTitle,
    itemSubtitle,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
  } = particleGetModifiedValues(modifiedItem);
  particleModifyOriginalItem(
    originalItem,
    modifiedItem,
    itemTitle,
    itemSubtitle,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget
  );
};
