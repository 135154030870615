import React, { useEffect, useState } from "react";
import UpdateCustomDomainForm from "./UpdateCustomDomainForm";
import { GetCurrentStoreData, WebsitesState } from "../../store/websites/types";
import { AuthState } from "../../store/auth/types";
import { UserState } from "../../store/user/types";
import { connect } from "react-redux";
import {
  customDomainUpdateErrorMessage,
  customDomainUpdateToggleInvalidAttempt,
  customDomainUpdateWaitingForServer,
  sslToggleActivationWaitingForServer,
  toggleCloudflareProtectionWaitingForServer,
} from "../../store/websites/actions";
import { Alert, Button, Icon, Steps } from "antd";
import "./CustomDomainSettings.css";
import DnsRecordsTable from "./DnsRecordsTable";
import { ActiveMenu } from "./SettingsDrawerContent";
import _ from "lodash";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { urls } from "../../data/urls";
import { MetaState } from "../../store/meta/types";
import addNoCacheParamToURL from "../../helpers/editor/addNoCacheParamToURL";
import { getSubdomain } from "../../helpers/custom_domain/getSubdomain";

export enum SetupVersions {
  LEGACY = "legacy",
  APEX_TO_WWW = "apex_to_www",
}

const SaveButton = (props: {
  customDomainInputValue: string;
  websites: WebsitesState;
  currentWebsite: any;
}): JSX.Element => {
  const { customDomainInputValue, websites, currentWebsite } = props;

  if (
    currentWebsite.custom_domain === customDomainInputValue &&
    !customDomainInputValue
  ) {
    return null;
  }

  return (
    <Button
      type="primary"
      htmlType="submit"
      form="custom_domain_form"
      loading={websites.isWaitingForCustomDomainUpdateResponse}
      hidden={currentWebsite.custom_domain === customDomainInputValue}
      icon="save"
    />
  );
};

interface Props {
  websites: WebsitesState;
  auth: AuthState;
  user: UserState;
  meta: MetaState;
  customDomainUpdateToggleInvalidAttempt: (payload: boolean) => void;
  customDomainUpdateErrorMessage: (payload: string) => void;
  customDomainUpdateWaitingForServer: (payload: boolean) => void;

  history: any;
  currentWebsite: any;
  saveWebsiteDataInStore: (payload: any, subdomain?: string) => void;
  doesCurrentUserHaveAccessToPaidFeatures: boolean;
  toggleCloudflareProtectionWaitingForServer: (payload: boolean) => void;
  sslToggleActivationWaitingForServer: (payload: boolean) => void;
  setActiveMenu: React.Dispatch<React.SetStateAction<ActiveMenu>>;
  getCurrentStoreData: GetCurrentStoreData;
}
const CustomDomainSettingsNew = (props: Props): JSX.Element => {
  const {
    currentWebsite,
    websites,
    auth,
    user,
    history,
    customDomainUpdateToggleInvalidAttempt,
    customDomainUpdateErrorMessage,
    customDomainUpdateWaitingForServer,
    saveWebsiteDataInStore,
    doesCurrentUserHaveAccessToPaidFeatures,
    toggleCloudflareProtectionWaitingForServer,
    sslToggleActivationWaitingForServer,
    meta,
  } = props;

  const [customDomainInputValue, setCustomDomainInputValue] = useState<string>(
    currentWebsite.custom_domain
  );

  const clearUpdateCustomDomainInput = () => {
    customDomainUpdateToggleInvalidAttempt(false);
    customDomainUpdateErrorMessage(undefined);
  };

  useEffect(() => {
    clearUpdateCustomDomainInput();
  }, []);

  useEffect(() => {
    setCustomDomainInputValue(currentWebsite.custom_domain);
  }, [currentWebsite.id]);

  const handleUpgradeToUnlock = (e: any): void => {
    props.setActiveMenu("account__plan");
  };

  if (!doesCurrentUserHaveAccessToPaidFeatures) {
    return (
      <div className="custom-domain-settings">
        <p className="custom-domain-settings__subtitle">
          Please upgrade to a paid plan to connect custom domain.
        </p>
        <Button
          onClick={handleUpgradeToUnlock}
          icon="star"
          type="primary"
          size="large"
          htmlType="button"
          style={{ marginTop: 10 }}
        >
          Upgrade
        </Button>
      </div>
    );
  }

  let wwwString = "";
  if (
    !getSubdomain(currentWebsite.custom_domain) &&
    currentWebsite.domain_setup_version === SetupVersions.APEX_TO_WWW
  ) {
    wwwString = "www.";
  }

  return (
    <div className="custom-domain-settings">
      <div className="settings-box__input">
        <p className="custom-domain-settings__subtitle">
          Enter a domain name that you own:
        </p>
        <div className="custom-domain-settings__content">
          <UpdateCustomDomainForm
            currentWebsiteCustomDomain={currentWebsite.custom_domain}
            currentWebsiteSubdomain={currentWebsite.subdomain}
            websites={websites}
            clearUpdateCustomDomainInput={clearUpdateCustomDomainInput}
            auth={auth}
            user={user}
            history={history}
            customDomainUpdateWaitingForServer={
              customDomainUpdateWaitingForServer
            }
            customDomainUpdateToggleInvalidAttempt={
              customDomainUpdateToggleInvalidAttempt
            }
            customDomainUpdateErrorMessage={customDomainUpdateErrorMessage}
            saveWebsiteDataInStore={saveWebsiteDataInStore}
            doesCurrentUserHaveAccessToPaidFeatures={
              doesCurrentUserHaveAccessToPaidFeatures
            }
            currentWebsite={currentWebsite}
            customDomainInputValue={customDomainInputValue}
            setCustomDomainInputValue={setCustomDomainInputValue}
          />
          <SaveButton
            customDomainInputValue={customDomainInputValue}
            websites={websites}
            currentWebsite={currentWebsite}
          />
        </div>
        {!!currentWebsite.custom_domain && (
          <div style={{ marginTop: 30 }}>
            <p className="custom-domain-settings__subtitle">
              Set up DNS records with the following values (
              <a
                className="underlined_link"
                target={"_blank"}
                href={urls.guides.connectCustomDomain}
              >
                learn more
              </a>
              ):
            </p>
            <div style={{ marginTop: 10 }}>
              <DnsRecordsTable currentWebsite={currentWebsite} meta={meta} />
            </div>
            <p
              className="custom-domain-settings__subtitle"
              style={{ marginTop: 10 }}
            >
              Once you've added the DNS records, please allow up to an hour for
              the domain to initialize.
            </p>
            <p
              className="custom-domain-settings__subtitle"
              style={{
                marginTop: 30,
                maxWidth: "100%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              Your website will be available at{" "}
              <a
                className="underlined_link"
                target={"_blank"}
                href={`https://${wwwString}${currentWebsite.custom_domain}`}
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    addNoCacheParamToURL(
                      `https://${wwwString}${currentWebsite.custom_domain}`
                    )
                  );
                }}
              >
                https://{wwwString}{currentWebsite.custom_domain}
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    websites: state.websites,
    auth: state.auth,
    user: state.user,
    meta: state.meta,
  };
};
const mapActionsToProps = {
  customDomainUpdateToggleInvalidAttempt,
  customDomainUpdateErrorMessage,
  customDomainUpdateWaitingForServer,
  toggleCloudflareProtectionWaitingForServer,
  sslToggleActivationWaitingForServer,
  getCurrentStoreData,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(CustomDomainSettingsNew);
