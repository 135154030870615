import React from "react";
import { WebsitesItem } from "../store/websites/types";
import _ from "lodash";
import { validateStyle } from "../helpers/editor/validateStyle";

interface Props {
  currentWebsite: WebsitesItem;
}

const CustomStyles = (props: Props): JSX.Element => {
  const { currentWebsite } = props;
  if (!currentWebsite) {
    return null;
  }
  const styles = _.get(currentWebsite, "custom_styles", {});
  const {
    buttonRadius,
    borderSize,
    buttonHorizontalPadding,
    buttonVerticalPadding,
    buttonFontSize,
    buttonFontWeight,
    buttonShadow,
    fontSizeParagraphs,
    fontSizeH1,
    fontSizeH2,
    inputRadius,
    inputBorderSize,
    inputVerticalPadding,
    inputHorizontalPadding,
    inputFontSize,
    inputFontWeight,
    fontSizeH3,
    inputLabelFontSize,
    inputLabelFontWeight,
    searchRadius,
    searchBorderSize,
    searchHorizontalPadding,
    searchVerticalPadding,
    searchFontSize,
    searchFontWeight,
    cardRadius,
    cardShadow,
    fontSizeContent,
    additionalVerticalSpace,
    inputLabelMarginBottom,
  } = styles;

  return (
    <>
      <style>
        {`html {
            --card-shadow-1-none: none;
            --card-shadow-1-small: 0 6px 22px 0 rgba(0,0,0,0.1);
            --card-shadow-1-default: 0 12px 44px 0 rgba(0,0,0,0.1);
            --card-shadow-1-large: 0 24px 88px 0 rgba(0,0,0,0.1);
            --card-shadow-1-borderThin: 0 0 0 1px #EEEEEE;
            --card-shadow-1-borderThick: 0 0 0 2px #EEEEEE;


            --card-shadow-2-none: none;
            --card-shadow-2-small: 0 2.5px 7.5px 0 rgba(37,44,97,0.15);
            --card-shadow-2-default: 0 5px 15px 0 rgba(37,44,97,0.15);
            --card-shadow-2-large: 0 10px 30px 0 rgba(37,44,97,0.15);
            --card-shadow-2-borderThin: 0 0 0 1px #EEEEEE;
            --card-shadow-2-borderThick: 0 0 0 2px #EEEEEE;


            --card-shadow-3-none: none;
            --card-shadow-3-small: 0 1px 2px 0 rgba(136,144,195,0.2);
            --card-shadow-3-default: 0 2px 4px 0 rgba(136,144,195,0.2);
            --card-shadow-3-large: 0 4px 8px 0 rgba(136,144,195,0.2);
            --card-shadow-3-borderThin: 0 0 0 1px #EEEEEE;
            --card-shadow-3-borderThick: 0 0 0 2px #EEEEEE;

            --card-shadow-4-none: none;
            --card-shadow-4-small: 0 1px 2px 0 rgba(136,144,195,0.2), 0 2.5px 7.5px 0 rgba(37,44,97,0.15);
            --card-shadow-4-default: 0 2px 4px 0 rgba(136,144,195,0.2), 0 5px 15px 0 rgba(37,44,97,0.15);
            --card-shadow-4-large: 0 4px 8px 0 rgba(136,144,195,0.2), 0 10px 30px 0 rgba(37,44,97,0.15);
            --card-shadow-4-borderThin: 0 0 0 1px #EEEEEE;
            --card-shadow-4-borderThick: 0 0 0 2px #EEEEEE;

            --card-shadow-hover-none: none;
            --card-shadow-hover-default: 0 2px 8px 0 rgba(136,144,195,0.22),0 8px 15px 0 rgba(37,44,97,0.17);
            --card-shadow-hover-small: 0 1px 4px 0 rgba(136,144,195,0.22), 0 4px 7.5px 0 rgba(37,44,97,0.17);
            --card-shadow-hover-large: 0 4px 16px 0 rgba(136,144,195,0.22), 0 16px 30px 0 rgba(37,44,97,0.17);
            --card-shadow-hover-borderThin: 0 0 0 1px #EEEEEE;
            --card-shadow-hover-borderThick: 0 0 0 2px #EEEEEE;
        }`}
      </style>
      <style>
        {`html {
            --button-shadow-none: none;
            --button-shadow-small: 0 1px 2px 0 rgba(136,144,195,0.2),0 2px 5px 0 rgba(37,44,97,0.15);
            --button-shadow-default: 0 2px 4px 0 rgba(136,144,195,0.2),0 5px 15px 0 rgba(37,44,97,0.15);
            --button-shadow-large: 0 4px 8px 0 rgba(136,144,195,0.2),0 10px 20px 0 rgba(37,44,97,0.15);

            --button-shadow-hover-none: none;
            --button-shadow-hover-small: 0 1px 4px 0 rgba(136,144,195,0.22),0 4px 8px 0 rgba(37,44,97,0.17);
            --button-shadow-hover-default: 0 2px 8px 0 rgba(136,144,195,0.22),0 8px 15px 0 rgba(37,44,97,0.17);
            --button-shadow-hover-large: 0 4px 16px 0 rgba(136,144,195,0.22),0 16px 30px 0 rgba(37,44,97,0.17);
        }`}
      </style>
      {validateStyle(buttonRadius) && (
        <style>
          {`
          html {
            --button-radius: ${buttonRadius}px;
          }
          body .comps .button { 
            border-radius: var(--button-radius); 
          }
          `}
        </style>
      )}
      {validateStyle(borderSize) && (
        <style>
          {`
            html {
              --border-size: ${borderSize}px;
            }
            body .comps .button { 
              border-width: var(--border-size); 
            }
            body .comps .button {
              display: inline-flex;
              align-items: center;
              justify-content: center;
            }
          `}
        </style>
      )}
      {validateStyle(buttonHorizontalPadding) && (
        <style>
          {`
          html {
            --button-horizontal-padding: ${buttonHorizontalPadding}px;
          }
          body .comps .button { 
            padding-left: var(--button-horizontal-padding);
            padding-right: var(--button-horizontal-padding);
            ${
              buttonHorizontalPadding < 20
                ? `min-width: calc( 80px + var(--button-horizontal-padding) * 2);`
                : ""
            }
          }
          `}
        </style>
      )}
      {validateStyle(buttonVerticalPadding) && (
        <style>
          {`
          html {
            --button-vertical-padding: ${buttonVerticalPadding}px;
          }
          body .comps .button { 
            padding-top: var(--button-vertical-padding);
            padding-bottom: var(--button-vertical-padding);
            ${
              buttonVerticalPadding < 12
                ? `min-height: calc( 20px + var(--button-vertical-padding) * 2);`
                : ""
            }
          }
          `}
        </style>
      )}
      {validateStyle(buttonFontSize) && (
        <style>
          {`
          html {
            --button-font-size: ${buttonFontSize}px;
          }
          body .comps .button .button__text { 
            font-size: var(--button-font-size);
          }
          body .comps .button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
          body .comps .pill-link__text { 
            font-size: calc(var(--button-font-size) / 0.875);
          }
          body .comps .pill-link__pill { 
            font-size: calc(var(--button-font-size) / 1.4);
            display: flex;
            align-items: center;
            line-height: 1;
            height: 2.4em;
            padding: 0 0.7em;
          }
          `}
        </style>
      )}
      {validateStyle(buttonFontWeight) && (
        <style>
          {`
          html {
            --button-font-weight: ${buttonFontWeight};
          }
          body .editor .comps .button, body .settings-box .comps .button {
            font-weight: var(--button-font-weight);
          }
          `}
        </style>
      )}
      {validateStyle(buttonShadow) && (
        <style>
          {`
          body .comps .button {
            box-shadow: var(--button-shadow-${buttonShadow});
          }
          body .comps .button:hover {
            box-shadow: var(--button-shadow-hover-${buttonShadow});
          }
          `}
        </style>
      )}
      {validateStyle(fontSizeParagraphs) && (
        <style>
          {`
          html {
            --font-size-subtitles: ${fontSizeParagraphs}px;
          }
          body .comps .subtitle-text { 
            font-size: var(--font-size-subtitles) !important;
          }
          `}
        </style>
      )}
      {validateStyle(fontSizeH1) && (
        <style>
          {`
            html {
              --font-size-h1: ${fontSizeH1}px;
            }
            body .comps .is-first-component .title-text{ 
              font-size: calc(var(--font-size-h1) / 1.5) !important;
              margin-top: 0.5em !important;
              margin-bottom: 0.5em !important;
            }
            body .comps .is-first-component .title-text.heading--accent, .heading-example-h1 { 
              font-size: var(--font-size-h1) !important;
            }
            body .comps .is-first-component .title-text.cta_form-03__heading { 
              font-size: calc(var(--font-size-h1) / 2) !important;
              margin-top: 0 !important;
            }
            body .comps .is-first-component .title-text.clients-02__title { 
              font-size: calc(var(--font-size-h1) / 2) !important;
              margin-top: 0 !important;
              margin-bottom: 40px !important;
            }
            body .comps .is-first-component .title-text.cta_button-03__title { 
              font-size: calc(var(--font-size-h1) / 2.4) !important;
              margin-top: 5px !important;
            }
            body .comps .is-first-component .text--02__content_box .title-text { 
              font-size: calc(var(--font-size-h1) / 2.6) !important;
            }
            body .comps .is-first-component .title-text.text--03__big_text { 
              font-size: calc(var(--font-size-h1) / 1.5) !important;
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
            body .comps .is-first-component .title-text.text--04__big_text { 
              font-size: calc(var(--font-size-h1)) !important;
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
            body .comps .blog-post-editor__main-title .blog-post-editor__main-title_input { 
              font-size: calc(var(--font-size-h1) / 0.96) !important;
              height: calc(var(--font-size-h1) / 0.64) !important;
            }
          `}
        </style>
      )}
      {validateStyle(fontSizeH2) && (
        <style>
          {`
          html {
            --font-size-h2: ${fontSizeH2}px;
          }
          body .comps .is-not-first-component .title-text.heading--accent,
            .heading-example-h2 { 
            font-size: var(--font-size-h2) !important;
            margin-top: 0.5em !important;
            margin-bottom: 0.5em !important;
          }
          body .comps .is-not-first-component .title-text{ 
            font-size: calc(var(--font-size-h2) / 1.5) !important;
            margin-top: 0.5em !important;
            margin-bottom: 0.5em !important;
          }
          body .comps .is-not-first-component .title-text.cta_form-03__heading { 
            font-size: calc(var(--font-size-h2) / 2) !important;
            margin-top: 0 !important;
          }
          body .comps .is-not-first-component .title-text.clients-02__title { 
            font-size: calc(var(--font-size-h2) / 2) !important;
            margin-top: 0 !important;
            margin-bottom: 40px !important;
          }
          body .comps .is-not-first-component .title-text.cta_button-03__title { 
            font-size: calc(var(--font-size-h2) / 2.4) !important;
            margin-top: 5px !important;
          }
          body .comps .is-not-first-component .text--02__content_box .title-text { 
            font-size: calc(var(--font-size-h2) / 2.6) !important;
          }
          body .comps .is-not-first-component .title-text.text--03__big_text { 
            font-size: calc(var(--font-size-h2) / 1.5) !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
          body .comps .is-not-first-component .title-text.text--04__big_text { 
            font-size: calc(var(--font-size-h2)) !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
          }
          body .comps .codex-editor h2.ce-header { 
            font-size: calc(var(--font-size-h2) / 1.9) !important;
          }
        `}
        </style>
      )}
      {validateStyle(fontSizeH3) && (
        <style>
          {`
          html {
            --font-size-h3: ${fontSizeH3}px;
          }
          body .comps .title-text--inner,
          .heading-example-h3 { 
            font-size: var(--font-size-h3) !important;
          }
          body .comps .title-text--inner.def-18_72
          { 
            font-size: calc(var(--font-size-h3) / 1.28) !important;
          }
          body .comps .title-text--inner.def-16
          { 
            font-size: calc(var(--font-size-h3) / 1.5) !important;
          }
          body .comps .title-text--inner.def-12
          { 
            font-size: calc(var(--font-size-h3) / 2) !important;
          }
          body .comps .title-text--inner.def-20
          { 
            font-size: calc(var(--font-size-h3) / 1.2) !important;
          }
          body .comps .title-text--inner.def-32
          { 
            font-size: calc(var(--font-size-h3) / 0.75) !important;
          }
          body .comps .title-text--inner.def-22
          { 
            font-size: calc(var(--font-size-h3) / 1.09) !important;
          }
          body .comps .title-text--inner.def-14
          { 
            font-size: calc(var(--font-size-h3) / 1.71) !important;
          }
          body .comps .title-text--inner.def-50
          { 
            font-size: calc(var(--font-size-h3) / 0.48) !important;
          }
          body .comps .title-text--inner.def-18
          { 
            font-size: calc(var(--font-size-h3) / 1.33) !important;
          }
          body .comps .title-text--inner.def-22_4
          { 
            font-size: calc(var(--font-size-h3) / 1.07) !important;
          }
          body .comps .codex-editor h3.ce-header { 
            font-size: calc(var(--font-size-h3) / 1.11) !important;
          }
        `}
        </style>
      )}
      {validateStyle(inputRadius) && (
        <style>
          {`
          html {
            --input-radius: ${inputRadius}px;
          }
          body .comps .text-input:not(.directory-01__search-input) { border-radius: var(--input-radius); }
          body .comps .form__input .chosen-container .chosen-choices { border-radius: var(--input-radius); }
          body .comps .textarea { 
            border-radius: ${
              inputRadius < 22 ? `var(--input-radius)` : "22px"
            }; 
          }
        `}
        </style>
      )}
      {validateStyle(inputBorderSize) && (
        <style>
          {`
          html {
            --input-border-size: ${inputBorderSize}px;
          }
          body .comps .text-input:not(.directory-01__search-input), body .comps .form__input .chosen-container .chosen-choices, body .comps .textarea { 
            border-width: var(--input-border-size);
          }
        `}
        </style>
      )}
      {validateStyle(inputVerticalPadding) && (
        <style>
          {`
          html {
            --input-vertical-padding: ${inputVerticalPadding}px;
          }
          body .comps .text-input:not(.directory-01__search-input) { 
            height: calc(14px + var(--input-vertical-padding) * 2);
          }
          body .comps .textarea { 
            padding-top: var(--input-vertical-padding);
            padding-bottom: var(--input-vertical-padding);
            min-height: calc(70px + var(--input-vertical-padding) * 2);
          }
          body .comps .form__input .chosen-container .chosen-choices { 
            padding-top: ${
              inputVerticalPadding >= 11
                ? `calc(var(--input-vertical-padding) - 11px)`
                : 0
            };
            padding-bottom: ${
              inputVerticalPadding >= 11
                ? `calc(var(--input-vertical-padding) - 11px)`
                : 0
            };
            min-height: calc(14px + var(--input-vertical-padding) * 2);
          }
          `}
        </style>
      )}
      {validateStyle(inputHorizontalPadding) && (
        <style>
          {`
          html {
            --input-horizontal-padding: ${inputHorizontalPadding}px;
          }
          body .comps .text-input:not(.directory-01__search-input) { 
            padding-left: var(--input-horizontal-padding);
            padding-right: var(--input-horizontal-padding);
          }
          body .comps .textarea { 
            padding-left: var(--input-horizontal-padding);
            padding-right: var(--input-horizontal-padding);
          }
          body .comps .form__input .chosen-search-input:not(.chosen-search-input:focus) { 
            ${
              inputHorizontalPadding >= 4
                ? `
              padding-left: calc(var(--input-horizontal-padding) - 4px);
              `
                : `
              padding-left: 0;
              `
            }
          }
          `}
        </style>
      )}
      {validateStyle(inputFontSize) && (
        <style>
          {`
          html {
            --input-font-size: ${inputFontSize}px;
          }
          body .comps .text-input:not(.directory-01__search-input) { 
            font-size: var(--input-font-size);
          }
          body .comps .textarea { 
            font-size: var(--input-font-size);
          }
          body .comps .chosen-search-input { 
            font-size: var(--input-font-size);
          }
          `}
        </style>
      )}
      {validateStyle(inputFontWeight) && (
        <style>
          {`
          html {
            --input-font-weight: ${inputFontWeight};
          }
          body .comps .text-input:not(.directory-01__search-input) { 
            font-weight: var(--input-font-weight);
          }
          body .comps .textarea { 
            font-weight: var(--input-font-weight);
          }
          body .comps .chosen-search-input { 
            font-weight: var(--input-font-weight);
          }
          `}
        </style>
      )}
      {validateStyle(inputLabelFontSize) && (
        <style>
          {`
          html {
            --input-label-font-size: ${inputLabelFontSize}px;
          }
          body .comps .form__input__label { 
            font-size: calc(var(--input-label-font-size) / 0.9375);
          }
          `}
        </style>
      )}
      {validateStyle(inputLabelFontWeight) && (
        <style>
          {`
          html {
            --input-label-font-weight: ${inputLabelFontWeight};
          }
          body .comps .form__input__label { 
            font-weight: var(--input-label-font-weight);
          }
          `}
        </style>
      )}
      {validateStyle(inputLabelMarginBottom) && (
        <style>
          {`
          html {
            --input-label-margin-bottom: ${inputLabelMarginBottom}px;
          }
          body .comps .form__input__label_box { 
            margin-bottom: var(--input-label-margin-bottom);
          }
          `}
        </style>
      )}
      {validateStyle(searchRadius) && (
        <style>
          {`
          html {
            --search-border-radius: ${searchRadius}px;
          }
          body .comps .directory-01__search-input { 
            border-radius: var(--search-border-radius);
          }
          `}
        </style>
      )}
      {validateStyle(searchBorderSize) && (
        <style>
          {`
          html {
            --search-border-size: ${searchBorderSize}px;
          }
          body .comps .directory-01__search-input { 
            border-width: var(--search-border-size);
          }
          `}
        </style>
      )}
      {validateStyle(searchHorizontalPadding) && (
        <style>
          {`
          html {
            --search-horizontal-padding: ${searchHorizontalPadding}px;
          }
          body .comps .directory-01__search-input { 
            padding-left: var(--search-horizontal-padding);
            padding-right: var(--search-horizontal-padding);
          }
          `}
        </style>
      )}
      {validateStyle(searchVerticalPadding) && (
        <style>
          {`
          html {
            --search-vertical-padding: ${searchVerticalPadding}px;
          }
          body .comps .directory-01__search-input { 
            height: calc(14px + var(--search-vertical-padding) * 2);
          }
          `}
        </style>
      )}
      {validateStyle(searchFontSize) && (
        <style>
          {`
          html {
            --search-font-size: ${searchFontSize}px;
          }
          body .comps .directory-01__search-input { 
            font-size: var(--search-font-size);
          }
          `}
        </style>
      )}
      {validateStyle(searchFontWeight) && (
        <style>
          {`
          html {
            --search-font-weight: ${searchFontWeight};
          }
          body .comps .directory-01__search-input { 
            font-weight: var(--search-font-weight);
          }  
          `}
        </style>
      )}
      {validateStyle(cardRadius) && (
        <style>
          {`
          html {
            --card-radius: ${cardRadius}px;
          }
          body .comps .card-container { 
            border-radius: var(--card-radius);
          }
          body .comps .card-container.sc-br-0_5 { 
            border-radius: calc(var(--card-radius) / 0.5);
          }
          `}
        </style>
      )}
      {validateStyle(cardShadow) && (
        <style>
          {`
          body .comps .card-container.sh-1 {
            box-shadow: var(--card-shadow-1-${cardShadow});
          }
          body .comps .card-container.sh-2 {
            box-shadow: var(--card-shadow-2-${cardShadow});
          }
          body .comps .card-container.sh-3 {
            box-shadow: var(--card-shadow-3-${cardShadow});
          }
          body .comps .card-container.sh-4, body .comps .bg-white-color .posts-02__item--accent {
            box-shadow: var(--card-shadow-4-${cardShadow});
          }
          body .comps .card-container.sh-has-hover:hover {
            box-shadow: var(--card-shadow-hover-${cardShadow});
          }
          `}
        </style>
      )}
      {validateStyle(fontSizeContent) && (
        <style>
          {`
          html {
            --font-size-content: ${fontSizeContent}px;
          }
          body .comps .content-text { 
            font-size: var(--font-size-content) !important;
          }
          body .comps .content-text.def-12_8 {   
            font-size: calc(var(--font-size-content) / 1.25) !important;
          }
          body .comps .content-text.def-14 {
            font-size: calc(var(--font-size-content) / 1.14) !important;   
          }
          body .comps .content-text.def-12 {   
            font-size: calc(var(--font-size-content) / 1.33) !important;   
          }
          body .comps .content-text.def-10 {   
            font-size: calc(var(--font-size-content) / 1.6) !important;   
          }
          body .comps .content-text.def-20 {   
            font-size: calc(var(--font-size-content) / 0.8) !important;   
          }
          body .comps .content-text.def-22 {   
            font-size: calc(var(--font-size-content) / 0.72) !important;   
          }
          body .comps .content-text.def-32 {   
            font-size: calc(var(--font-size-content) / 0.5) !important;
          }
          body .comps .content-text.def-48 {   
            font-size: calc(var(--font-size-content) / 0.33) !important;   
          }
          body .comps .content-text.def-64 {   
            font-size: calc(var(--font-size-content) / 0.25) !important;   
          }
          body .comps .directory-01__rating {
            align-items: center;
          }
          body .comps .checkbox__check {
            top: 50%;
            transform: translateY(-50%);
          }

          body .comps .codex-editor .ce-paragraph {
            font-size: calc(var(--font-size-content) / 0.88) !important;  
          }
          body .comps .codex-editor .cdx-list {
            font-size: calc(var(--font-size-content) / 0.88) !important;  
          }
          body .comps .codex-editor .ce-code .ce-code__textarea {
            font-size: calc(var(--font-size-content) / 1.04) !important;  
          }
          body .comps .codex-editor .cdx-quote {
            font-size: calc(var(--font-size-content) / 0.88) !important;  
          }
          body .comps .codex-editor .cdx-checklist {
            font-size: calc(var(--font-size-content) / 0.88) !important;  
          }
          body .comps .codex-editor .cdx-checklist__item {
            align-items: center;
          }
          body .comps .codex-editor .cdx-checklist__item-checkbox {
            margin-top: 0;
            margin-bottom: 0;
          }
        `}
        </style>
      )}
      {validateStyle(additionalVerticalSpace) && (
        <style>
          {`
          html {
            --extra-vertical-space: ${additionalVerticalSpace}px;
          }
        `}
        </style>
      )}
    </>
  );
};

export default CustomStyles;
