import React from "react";
import { Button } from "antd";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  saveContentInStore: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  toggleNewEditionsDetected: (value: boolean) => void;
  content: any;
  maxItemsReached: boolean;
}

const GalleryAddNewSlideButton = (props: Props): JSX.Element => {
  const {
    saveContentInStore,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
    maxItemsReached,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const generator = new generateContentDataObject();

  if (maxItemsReached) return null;

  return (
    <Button
      size="small"
      onClick={() => {
        toggleNewEditionsDetected(true);
        saveContentInStore(
          generator.addEmptyGalleryIfNeeded({
            [contentType]: currentInputDataRef.current,
          })
        );
      }}
      icon="plus"
    >
      Add new slide
    </Button>
  );
};

export default GalleryAddNewSlideButton;
