import React, { Component } from "react";
import axios from "axios";
import "./BlogPostEditor.scss";
import { Input, Button, Icon, Empty, Tooltip, Badge, Popconfirm } from "antd";
import { Prompt } from "react-router";
import * as _ from "lodash";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import { getCurrentBlog } from "../../helpers/blog/getCurrentBlog";
import { applyCustomColorsClassnames } from "../../helpers/editor/applyCustomColorsClassnames";
import { getFirstImageFromBlogPostJson } from "../../helpers/blog/getFirstImageFromBlogPostJson";
import { getExcerptFromBlogPostJson } from "../../helpers/blog/getExcerptFromBlogPostJson";
import { Link } from "react-router-dom";
import { getCurrentBlogPost } from "../../helpers/blog/getCurrentBlogPost";

import { api, apiUrlBase, urls } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { getErrorValue } from "../../helpers/editor/getErrorValue";
import { getErrorKey } from "../../helpers/editor/getErrorKey";
import { openNotification } from "../../helpers/openNotification";
import SpinnerBox from "../dashboard/SpinnerBox";
import CustomColorsStyles from "../editor/CustomColorsStyles";
import CustomFontsStyles from "../editor/CustomFontsStyles";

import SlateEditor from "./SlateEditor/SlateEditor";
import CodexEditor from "./CodexEditor/CodexEditor";
import PlainHtml from "./PlainHtml/PlainHtml";
import DisplayBadWebsiteOverlay from "../../helpers/DisplayBadWebsiteOverlay";
import { handleBadWebsiteError } from "../../helpers/handleBadWebsiteError";
import PopupComponents from "../editor/popups/PopupComponents";
import PopupMask from "../editor/popups/PopupMask";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import WebsiteNav from "../../pageComponents/component_molecules/WebsiteNav";
import WebsiteFooter from "../../pageComponents/component_molecules/WebsiteFooter";
import { BlogDataStatus } from "../../enums/BlogDataStatus";
import { getBlogDataStatus } from "../../helpers/blog/getBlogDataStatus";
import { PATH_HISTORY_LS_KEY } from "../../data/constants";
import trimStringTo from "../../helpers/strings/trimStringTo";
import { ChangeBlogPostData } from "../../store/blogPosts/types";
import CustomStyles from "../CustomStyles";
import { getCustomStyleClassNames } from "../../helpers/editor/getCustomStyleClassNames";
import { GetCurrentStoreData, SaveWebsiteBackup } from "../../store/websites/types";

const { TextArea } = Input;

interface Props {
  auth: any;
  websites: any;
  blogs: any;
  blogPosts: any;

  history: any;

  locationPostUrl: string;
  isBlogUrlBlank: boolean;

  editBlogPostMainTitleSchemeToggleWaitingForServer: any;
  toggleNewBlogPostMainTitleEditionsDetected: any;

  toggleWaitingForResponseOnBlogNavEditions: any;
  toggleWaitingForResponseOnBlogFooterEditions: any;

  toggleBlogNavEditionsDetected: any;
  toggleBlogFooterEditionsDetected: any;

  toggleBlogPostChangeInProgress: any;

  changePostEditorType: any;
  changePostEditorTypeWaitingForServer: any;

  changeBlogPostBody: any;
  changeBlogPostTitle: any;

  editBlogPostSchemeToggleWaitingForServer: any;
  toggleNewBlogPostEditionsDetected: any;

  toggleWebsiteCaptchaEditionsDetected: any;
  toggleWaitingForResponseOnWebsiteCaptchaEditions: any;

  toggleWebsitePopupEditionsDetected: any;
  toggleWaitingForResponseOnWebsitePopupEditions: any;

  saveImageInfoInDB: any;
  removeImageFromDB: any;
  saveWebsiteDataInStore: any;

  navEditionFieldsVisible: boolean;
  showNavEditionFieldsDrawer: () => void;
  hideNavEditionFieldsDrawer: () => void;
  displayNavOrFooterEditionBox: any;

  saveBlogNavInState: any;
  saveWebsiteNavInState: any;
  toggleWebsiteNavEditionsDetected: any;

  footerEditionFieldsVisible: boolean;
  saveBlogFooterInState: any;
  showFooterEditionFieldsDrawer: any;
  hideFooterEditionFieldsDrawer: any;
  saveWebsiteFooterInState: any;
  toggleWebsiteFooterEditionsDetected: any;

  editLoadedBlogsData: (payload: {
    blogId: number;
    status: BlogDataStatus;
  }) => void;
  saveBlogPostContentInState: any;
  changeBlogPostData: ChangeBlogPostData;
  saveWebsiteBackup: SaveWebsiteBackup;
  getCurrentStoreData: GetCurrentStoreData;
}

type State = {
  editorType: "editorjs" | "slatejs" | "plain_html";
  showSuccessSaveButton: boolean;
  plainHtmlPostBody: string;
  countClickOnSecretField: number;
  changesDetected: boolean;
  focusOnCodexEditor: boolean;
};

class BlogPostEditor extends Component<Props, State> {
  DEBOUNCE_TIME_FOR_SAVING_TO_SERVER = 500;
  isBackupSavedRef: React.MutableRefObject<number>;
  constructor(props: Props) {
    super(props);
    this.isBackupSavedRef = React.createRef();
  }

  fetchPageComponents = () => {
    const {
      websites,
      blogs,
      blogPosts,
      editLoadedBlogsData,
      saveBlogPostContentInState,
      auth,
    } = this.props;

    const currentDataStatus = getBlogDataStatus(websites, blogs, blogPosts);

    if (currentDataStatus !== BlogDataStatus.notLoaded) return;

    const currentWebsiteItems = _.get(websites, "items");
    const currentWebsite = getCurrentWebsite(currentWebsiteItems);
    const currentBlogItems = _.get(blogs, "items");
    const currentBlog = getCurrentBlog(currentBlogItems, currentWebsite);
    const blogId = _.get(currentBlog, "id");
    const currentWebsiteSubdomain = _.get(currentWebsite, "subdomain");

    editLoadedBlogsData({
      blogId,
      status: BlogDataStatus.inProgress,
    });

    const accessToken = auth.accessToken;
    const apiUrl =
      apiUrlBase + api.blogPosts.base + currentWebsiteSubdomain + "/";
    axios
      .get(apiUrl, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        saveBlogPostContentInState(response.data);

        editLoadedBlogsData({
          blogId,
          status: BlogDataStatus.loaded,
        });
      })
      .catch((error) => {
        const errorMessage = `Error message: "${error.message}". Please try to reload the page. If it doesn't help, contact us.`;
        openNotification("Internal error", errorMessage, "OK", "error");
        console.log(error);

        editLoadedBlogsData({
          blogId,
          status: BlogDataStatus.error,
        });
      });
  };

  componentWillMount(): void {
    this.setState({
      showSuccessSaveButton: false,
      plainHtmlPostBody: "",
      countClickOnSecretField: 0,
      changesDetected: false,
    });
  }

  componentDidMount(): void {
    const path = this.props.history.location.pathname;
    localStorage.setItem(PATH_HISTORY_LS_KEY, path);
    this.fetchPageComponents();
  }

  componentDidUpdate(prevProps): void {
    this.fetchPageComponents();
    let isSaveButtonActive: boolean = false;
    isSaveButtonActive =
      this.props.blogPosts.blogPostNewEditionsDetected ||
      this.props.blogs.isFooterEditionsDetected ||
      this.props.blogs.isNavEditionsDetected ||
      this.props.websites.isCaptchaEditionsDetected ||
      this.props.websites.isPopupEditionsDetected;

    if (isSaveButtonActive || this.state.changesDetected) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  }

  launchSuccessfulSaveAnimation = () => {
    this.setState({
      showSuccessSaveButton: true,
    });

    setTimeout(() => {
      this.setState({
        showSuccessSaveButton: false,
      });
    }, 1000);
  };

  // START nav & footer
  saveNavInServer = (currentBlog: any) => {
    // getCurrentWebsite(props.websites.items);

    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.blogs.updateBlog.prefix +
      currentBlog.id +
      api.blogs.updateBlog.postfix;

    axios
      .patch(
        apiUrl,
        { nav: currentBlog.nav },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        // Tell a user that the operation is successful.

        // let message = 'Successfully saved website navigation.';

        fireAnalyticsEvent.fireCrisp(CrispEvents.savePageEditions, {
          type: "Blog navigation",
          blog_id: response.data.id,
        });

        // openNotification('Done', message, 'Close', 'success', 5);
        this.launchSuccessfulSaveAnimation();
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.savePageEditionsError,
            {
              type: "Blog navigation",
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              blog_id: currentBlog.id,
            },
            true
          );

          handleBadWebsiteError(errorData);

          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleBlogNavEditionsDetected(false);
        this.props.toggleWaitingForResponseOnBlogNavEditions(false);
      });
  };
  saveFooterInServer = (currentBlog: any) => {
    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.blogs.updateBlog.prefix +
      currentBlog.id +
      api.blogs.updateBlog.postfix;

    axios
      .patch(
        apiUrl,
        { footer: currentBlog.footer },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        // Tell a user that the operation is successful.

        // let message = 'Website footer has been successfully saved.';
        fireAnalyticsEvent.fireCrisp(CrispEvents.savePageEditions, {
          type: "Blog footer",
          blog_id: response.data.id,
        });

        // openNotification('Done', message, 'Close', 'success', 5);
        this.launchSuccessfulSaveAnimation();
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.savePageEditionsError,
            {
              type: "Blog footer",
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              blog_id: currentBlog.id,
            },
            true
          );

          handleBadWebsiteError(errorData);

          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleBlogFooterEditionsDetected(false);
        this.props.toggleWaitingForResponseOnBlogNavEditions(false);
      });
  };
  saveCaptchaInServer = (currentWebsite: any) => {
    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websites.updateWebsite.prefix +
      currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;

    axios
      .patch(
        apiUrl,
        { recaptcha: currentWebsite.recaptcha },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        // Tell a user that the operation is successful.

        // let message = 'Website footer has been successfully saved.';

        fireAnalyticsEvent.fireCrisp(CrispEvents.savePageEditions, {
          type: "reCAPTCHA",
          subdomain: response.data.subdomain,
        });

        // openNotification('Done', message, 'Close', 'success', 5);
        this.launchSuccessfulSaveAnimation();
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.savePageEditionsError,
            {
              type: "reCAPTCHA",
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              subdomain: currentWebsite.subdomain,
            },
            true
          );

          handleBadWebsiteError(errorData);

          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWebsiteCaptchaEditionsDetected(false);
        this.props.toggleWaitingForResponseOnBlogNavEditions(false);
      });
  };
  savePopupInServer = (currentWebsite: any) => {
    let accessToken = this.props.auth.accessToken;
    let apiUrl =
      api.websites.updateWebsite.prefix +
      currentWebsite.subdomain +
      api.websites.updateWebsite.postfix;

    axios
      .patch(
        apiUrl,
        { popups: currentWebsite.popups },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        // Tell a user that the operation is successful.

        // let message = 'Website footer has been successfully saved.';

        fireAnalyticsEvent.fireCrisp(CrispEvents.savePageEditions, {
          type: "Popup",
          subdomain: response.data.subdomain,
        });

        // openNotification('Done', message, 'Close', 'success', 5);
        this.launchSuccessfulSaveAnimation();
      })
      .catch((error) => {
        // handle error
        if (error.response) {
          let errorData = error.response.data;

          let errorObjectKey = getErrorKey(errorData);
          let errorObjectValue = getErrorValue(errorData, errorObjectKey);

          fireAnalyticsEvent.fireCrisp(
            CrispEvents.savePageEditionsError,
            {
              type: "Popup",
              error_type: errorObjectKey,
              error_message: errorObjectValue,
              subdomain: currentWebsite.subdomain,
            },
            true
          );

          handleBadWebsiteError(errorData);

          if (errorData.not_pro !== undefined) {
            let message = errorData.not_pro;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.locked !== undefined) {
            let message = errorData.locked;
            openNotification("Denied", message, "OK", "warn");
          }

          if (errorData.detail !== undefined) {
            let message = errorData.detail;

            // This happens when user tries to change a website which doesn't exist or doesn't belong to the user.
            openNotification(
              "Server error",
              'Error message: "' +
                message +
                '". This should not have happened. Please contact us.',
              "OK",
              "error"
            );
          }
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleWebsitePopupEditionsDetected(false);
        this.props.toggleWaitingForResponseOnBlogNavEditions(false);
      });
  };
  saveNavAndFooterChanges = async (currentBlog: any, currentWebsite: any) => {
    this.props.toggleWaitingForResponseOnBlogNavEditions(true);
    const dataToBackup = {}
    if (this.props.blogs.isNavEditionsDetected) {
      dataToBackup["has_blog_nav"] = true;
      dataToBackup["is_blog "] = true;
    }
    if (this.props.blogs.isFooterEditionsDetected) {
      dataToBackup["has_blog_footer"] = true;
      dataToBackup["is_blog "] = true;
    }
    if (this.props.websites.isCaptchaEditionsDetected) {
      dataToBackup["has_recaptcha"] = true;
    }
    if (this.props.websites.isPopupEditionsDetected) {
      dataToBackup["has_popups"] = true;
    }
    try {
      await this.props.saveWebsiteBackup(dataToBackup);
    } catch(e) {
      console.error(_.get(e, "response.data"));
    }
    //One button saves 2 types of changes: nav (any changes) and footer (also any changes).

    //if we detected some nav changes
    if (this.props.blogs.isNavEditionsDetected) {
      this.saveNavInServer(currentBlog);
    }
    //if we detected some footer changes
    if (this.props.blogs.isFooterEditionsDetected) {
      this.saveFooterInServer(currentBlog);
    }

    if (this.props.websites.isCaptchaEditionsDetected) {
      this.saveCaptchaInServer(currentWebsite);
    }

    if (this.props.websites.isPopupEditionsDetected) {
      this.savePopupInServer(currentWebsite);
    }

    // If there are changes of 2 or more types, the button will have the 'loading' state until the last AJAX request is responded.
  };
  // END nav & footer

  updateEditorTypeToStore = (currentPostIndex, editorType) => {
    this.props.changePostEditorType({
      currentPostIndex: currentPostIndex,
      editor_type: editorType,
    });
  };

  updateEditorType = (
    currentWebsiteSubdomain,
    currentPostUrl,
    postIndex,
    newEditorType
  ) => {
    this.updateEditorTypeToStore(postIndex, newEditorType);

    this.props.changePostEditorTypeWaitingForServer(true);
    this.props.toggleNewBlogPostEditionsDetected(true);

    let apiUrl =
      api.blogPosts.updateBlogPost.prefix +
      currentWebsiteSubdomain +
      "/" +
      currentPostUrl +
      api.blogPosts.updateBlogPost.postfix;

    axios
      .patch(
        apiUrl,
        {
          editor_type: newEditorType,
          body_json: [],
        },
        { ...returnAuthHeaderForAJAX(this.props.auth.accessToken) }
      )
      .then((response) => {
        fireAnalyticsEvent.fireCrisp(CrispEvents.savePostEditions, {
          type: "Blog post editor type",
          blog_id: response.data.blog,
          post_url: response.data.url,
        });
      })
      .catch((error) => {
        let errorMessage = `Something went wrong. Your post was not saved.`;
        if (error.response) {
          let errorData = error.response.data;

          if (errorData.editor_type !== undefined) {
            let errorObjectKey = getErrorKey(errorData);
            let errorObjectValue = getErrorValue(errorData, errorObjectKey);

            fireAnalyticsEvent.fireCrisp(
              CrispEvents.savePostEditionsError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                type: "Blog post editor type",
                post_url: currentPostUrl,
              },
              true
            );
          }
        }

        // @todo saving data to LS, then fetch from LS and comparing with DB post data https://trello.com/c/439MCvqS/332-blogs-comparing-ls-app-store-server-post-data
        openNotification(
          "Internal error",
          errorMessage +
            " This should not have happened. Sorry. Please contact us.",
          "OK",
          "error",
          30
        );
      })
      .then((response) => {
        // always executed
        this.props.changePostEditorTypeWaitingForServer(false);
        this.props.toggleNewBlogPostEditionsDetected(false);
      });
  };

  // START switch to Plain HTML feature
  displaySecretButtonPlainHtml = () => {
    if (this.state.countClickOnSecretField < 2) {
      this.setState({
        countClickOnSecretField: this.state.countClickOnSecretField + 1,
      });
    }
  };

  revertToPlainHTMLBlogEditor = (website, post, postIndex) => {
    this.setState({
      editorType: "plain_html",
      plainHtmlPostBody: post.body_html,
    });

    this.updateEditorType(
      website.subdomain,
      this.props.locationPostUrl,
      postIndex,
      "plain_html"
    );
  };

  setToPlainHTMLBlogEditor = (website, post, postIndex) => {
    return (
      <Popconfirm
        title={
          <div style={{ width: 500 }}>
            Do you want to convert this post into plain HTML field? It will give
            you more flexibility but requires HTML coding skills. This action is
            irreversible.
          </div>
        }
        onConfirm={
          this.revertToPlainHTMLBlogEditor
            ? () => this.revertToPlainHTMLBlogEditor(website, post, postIndex)
            : () => {}
        }
        okText="Yes, make this post to plain HTML"
        cancelText="Cancel"
        placement="top"
      >
        <Button
          style={{
            marginTop: 10,
            display: this.state.countClickOnSecretField > 1 ? "block" : "none",
          }}
          type="danger"
          size="small"
          icon="code"
        />
      </Popconfirm>
    );
  };
  // END switch to Plain HTML feature

  // START Main Title
  saveMainTitleToStore = (currentPostIndex, titleValue) => {
    this.props.changeBlogPostTitle({
      currentPostIndex: currentPostIndex,
      title: titleValue,
    });
  };

  // Save Main Title to Server
  saveMainTitleToServer = async (currentWebsiteSubdomain, currentPostUrl, title) => {
    this.props.editBlogPostMainTitleSchemeToggleWaitingForServer(true);

    const { currentBlogPost } = this.props.getCurrentStoreData();
    if (this.isBackupSavedRef.current !== _.get(currentBlogPost, "id")) {
      this.isBackupSavedRef.current = _.get(currentBlogPost, "id");
      try {
        await this.props.saveWebsiteBackup({ is_blog: true, has_blog_post_content: true, blog_post_id: _.get(currentBlogPost, "id") });
      } catch (e) {
        console.error(_.get(e, "response.data"));
      }
    }

    this.setState({
      changesDetected: true,
    });

    this.props.toggleNewBlogPostMainTitleEditionsDetected(true);

    let apiUrl =
      api.blogPosts.updateBlogPost.prefix +
      currentWebsiteSubdomain +
      "/" +
      currentPostUrl +
      api.blogPosts.updateBlogPost.postfix;

    axios
      .patch(
        apiUrl,
        { title: title },
        { ...returnAuthHeaderForAJAX(this.props.auth.accessToken) }
      )
      .then((response) => {
        fireAnalyticsEvent.fireCrisp(CrispEvents.savePostEditions, {
          type: "Blog post title",
          blog_id: response.data.blog,
          post_url: response.data.url,
        });
        // Tell a user that the operation is successful.
        // openNotification('Done', <span>Components changes successfully saved.</span>, 'Close', 'success', 2)

        // startOnboardingTour('publish', props.user);
      })
      .catch((error) => {
        let errorMessage = `Something went wrong. Your post was not saved.`;
        if (error.response) {
          let errorData = error.response.data;
          handleBadWebsiteError(errorData);

          if (errorData.post_html !== undefined) {
            let errorObjectKey = getErrorKey(errorData);
            let errorObjectValue = getErrorValue(errorData, errorObjectKey);

            fireAnalyticsEvent.fireCrisp(
              CrispEvents.savePostEditionsError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                type: "Blog post title",
                post_url: currentPostUrl,
              },
              true
            );
          }
        } else {
          // @todo saving data to LS, then fetch from LS and comparing with DB post data https://trello.com/c/439MCvqS/332-blogs-comparing-ls-app-store-server-post-data
          openNotification(
            "Internal error",
            errorMessage +
              " This should not have happened. Sorry. Please contact us.",
            "OK",
            "error",
            30
          );
        }
      })
      .then((response) => {
        // always executed
        this.props.toggleNewBlogPostMainTitleEditionsDetected(false);
        this.props.editBlogPostMainTitleSchemeToggleWaitingForServer(false);
        this.setState({
          changesDetected: false,
        });
      });
  };
  debounceSaveMainTitleToServer = _.debounce(
    this.saveMainTitleToServer,
    this.DEBOUNCE_TIME_FOR_SAVING_TO_SERVER
  );

  setFocusOnCodexEditor = (value) => {
    this.setState({
      focusOnCodexEditor: value,
    });
  };

  changeMainTitleFocus = (event) => {
    const setFocusOnCodexEditor = this.setFocusOnCodexEditor.bind(this);

    if (event.key === "Enter" && !event.shiftKey) {
      // Set cursor focus on the first block in js-codex-editor
      setFocusOnCodexEditor(true);

      setTimeout(() => {
        setFocusOnCodexEditor(false);
      }, 500);
      event.preventDefault();
    }
  };

  onChangeMainTitle = (event, subdomain, postIndex) => {
    let titleValue = event.currentTarget.value;

    this.saveMainTitleToStore(postIndex, titleValue);
    this.debounceSaveMainTitleToServer(
      subdomain,
      this.props.locationPostUrl,
      titleValue
    );
  };
  // END Main Title

  // START Post Body
  savePostBodyToStore = (postIndex, bodyJson, bodyHtml) => {
    this.props.changeBlogPostBody({
      currentPostIndex: postIndex,
      body_json: bodyJson,
      body_html: bodyHtml,
    });
  };

  savePostBodyToServer = async (
    currentWebsiteSubdomain,
    currentPostUrl,
    editorType,
    bodyJson,
    bodyHtml
  ) => {
    this.props.editBlogPostSchemeToggleWaitingForServer(true);

    const { currentBlogPost } = this.props.getCurrentStoreData();
    if (this.isBackupSavedRef.current !== _.get(currentBlogPost, "id")) {
      this.isBackupSavedRef.current = _.get(currentBlogPost, "id");

      try {
        await this.props.saveWebsiteBackup({ is_blog: true, has_blog_post_content: true, blog_post_id: _.get(currentBlogPost, "id") });
      } catch (e) {
        console.error(_.get(e, "response.data"));
      }
    }

    let firstImageObject = {
      url: "",
      width: null,
      height: null,
    }; // used to blog post first_image_url. display as thumb if no OG image set.
    let postExcerpt;
    let firstParagraphText;

    if (editorType === "slatejs") {
      firstImageObject = getFirstImageFromBlogPostJson(bodyJson, "slatejs");
      postExcerpt = getExcerptFromBlogPostJson(bodyJson);
    } else if (editorType === "editorjs") {
      firstImageObject = getFirstImageFromBlogPostJson(bodyJson, "editorjs");
      const blocks = _.get(bodyJson, "blocks");
      if (blocks && Array.isArray(blocks)) {
        const firstParagraph = blocks.find((block) => {
          return _.get(block, "type") === "paragraph";
        });
        const firstParagraphContent = _.get(firstParagraph, "data.text", "")
          .trim()
          .substring(0, 1000);
        if (firstParagraphContent) {
          const parser = new DOMParser();
          firstParagraphText = parser.parseFromString(
            firstParagraphContent,
            "text/html"
          ).body.textContent;
          firstParagraphText = trimStringTo(firstParagraphText, 500);
        }
      }
    }

    firstParagraphText = firstParagraphText || "";

    let apiUrl =
      api.blogPosts.updateBlogPost.prefix +
      currentWebsiteSubdomain +
      "/" +
      currentPostUrl +
      api.blogPosts.updateBlogPost.postfix;

    let data = {
      body_html: bodyHtml,
      body_json: bodyJson,
      first_image_url: firstImageObject.url,
      first_image_width: firstImageObject.width || null,
      first_image_height: firstImageObject.height || null,
      first_paragraph_text: firstParagraphText,
    };

    if (editorType === "slatejs") {
      data["excerpt"] = postExcerpt;
    }

    axios
      .patch(apiUrl, data, {
        ...returnAuthHeaderForAJAX(this.props.auth.accessToken),
      })
      .then((response) => {
        fireAnalyticsEvent.fireCrisp(CrispEvents.savePostEditions, {
          type: "Blog post content",
          blog_id: response.data.blog,
          post_url: response.data.url,
        });
        this.props.changeBlogPostData({
          postId: response.data.id,
          data: {
            first_paragraph_text: response.data.first_paragraph_text,
          },
        });
      })
      .catch((error) => {
        let errorMessage = `Something went wrong. Your post was not saved.`;
        if (error.response) {
          let errorData = error.response.data;
          handleBadWebsiteError(errorData);

          if (errorData.post_html !== undefined) {
            let errorObjectKey = getErrorKey(errorData);
            let errorObjectValue = getErrorValue(errorData, errorObjectKey);

            fireAnalyticsEvent.fireCrisp(
              CrispEvents.savePostEditionsError,
              {
                error_type: errorObjectKey,
                error_message: errorObjectValue,
                type: "Blog post content",
                post_url: currentPostUrl,
              },
              true
            );
          }
        } else {
          // @todo saving data to LS, then fetch from LS and comparing with DB post data https://trello.com/c/439MCvqS/332-blogs-comparing-ls-app-store-server-post-data
          openNotification(
            "Not saved, sorry",
            errorMessage +
              " Please copy-paste your content to your computer and contact us. Also check your network connection.",
            "OK",
            "error",
            60
          );
        }
      })
      .then((response) => {
        // always executed
        this.props.editBlogPostSchemeToggleWaitingForServer(false);
        this.props.toggleNewBlogPostEditionsDetected(false);
        this.setState({
          changesDetected: false,
        });
      });
  };
  debounceSavePostBodyToServer = _.debounce(
    this.savePostBodyToServer,
    this.DEBOUNCE_TIME_FOR_SAVING_TO_SERVER
  );

  // END Post Body

  // Plain Html
  onChangePlainHtmlPostBody = (value) => {
    this.setState({
      plainHtmlPostBody: value,
    });
  };

  handleChangesDetected = (value) => {
    this.setState({
      changesDetected: value,
    });
  };

  render() {
    const currentDataStatus = getBlogDataStatus(
      this.props.websites,
      this.props.blogs,
      this.props.blogPosts
    );
    if (currentDataStatus !== BlogDataStatus.loaded) {
      return (
        <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
          <SpinnerBox text="Getting the post..." />
        </div>
      );
    }

    let currentWebsite = getCurrentWebsite(this.props.websites.items);

    if (currentWebsite === undefined) {
      return (
        <div style={{ paddingTop: "80px", paddingBottom: "40px" }}>
          <Empty description={<span>Website not found</span>}>
            <Link to="/">
              <Button type="primary">Back to your websites</Button>
            </Link>
          </Empty>
        </div>
      );
    } else {
      let currentWebsiteSubdomain = currentWebsite.subdomain;

      let currentBlog = getCurrentBlog(this.props.blogs.items, currentWebsite);
      let blogPostsArray = this.props.blogPosts.items;
      let currentBlogPost = getCurrentBlogPost(
        this.props.locationPostUrl,
        blogPostsArray,
        currentBlog
      );
      let currentPostIndex;

      let blogPostEditorJSX = null; // Post editor

      if (currentBlogPost !== undefined) {
        blogPostsArray.map((item) => {
          if (item.id === currentBlogPost.id) {
            currentPostIndex = blogPostsArray.indexOf(item);
          }
        });

        if (currentBlogPost.editor_type === "editorjs") {
          blogPostEditorJSX = (
            <CodexEditor
              auth={this.props.auth}
              currentBlogPost={currentBlogPost}
              currentPostIndex={currentPostIndex}
              currentWebsite={currentWebsite}
              currentPostUrl={this.props.locationPostUrl}
              blogPosts={this.props.blogPosts}
              toggleNewBlogPostEditionsDetected={
                this.props.toggleNewBlogPostEditionsDetected
              }
              savePostBodyToStore={this.savePostBodyToStore}
              savePostBodyToServer={this.savePostBodyToServer}
              changesDetected={this.state.changesDetected}
              handleChangesDetected={this.handleChangesDetected}
              focusOnCodexEditor={this.state.focusOnCodexEditor}
            />
          );
        } else if (currentBlogPost.editor_type === "slatejs") {
          blogPostEditorJSX = (
            <SlateEditor
              currentWebsite={currentWebsite}
              currentBlog={currentBlog}
              currentBlogPost={currentBlogPost}
              currentPostIndex={currentPostIndex}
              websites={this.props.websites}
              blogs={this.props.blogs}
              auth={this.props.auth}
              blogPosts={this.props.blogPosts}
              currentPostUrl={this.props.locationPostUrl}
              isBlogUrlBlank={this.props.isBlogUrlBlank}
              history={this.props.history}
              toggleWaitingForResponseOnBlogNavEditions={
                this.props.toggleWaitingForResponseOnBlogNavEditions
              }
              toggleWaitingForResponseOnBlogFooterEditions={
                this.props.toggleWaitingForResponseOnBlogFooterEditions
              }
              toggleBlogNavEditionsDetected={
                this.props.toggleBlogNavEditionsDetected
              }
              toggleBlogFooterEditionsDetected={
                this.props.toggleBlogFooterEditionsDetected
              }
              editBlogPostSchemeToggleWaitingForServer={
                this.props.editBlogPostSchemeToggleWaitingForServer
              }
              toggleNewBlogPostEditionsDetected={
                this.props.toggleNewBlogPostEditionsDetected
              }
              changeBlogPostBody={this.props.changeBlogPostBody}
              changeBlogPostTitle={this.props.changeBlogPostTitle}
              editBlogPostMainTitleSchemeToggleWaitingForServer={
                this.props.editBlogPostMainTitleSchemeToggleWaitingForServer
              }
              toggleNewBlogPostMainTitleEditionsDetected={
                this.props.toggleNewBlogPostMainTitleEditionsDetected
              }
              toggleBlogPostChangeInProgress={
                this.props.toggleBlogPostChangeInProgress
              }
              changePostEditorType={this.props.changePostEditorType}
              changePostEditorTypeWaitingForServer={
                this.props.changePostEditorTypeWaitingForServer
              }
              savePostBodyToStore={this.savePostBodyToStore}
              debounceSavePostBodyToServer={this.debounceSavePostBodyToServer}
              changesDetected={this.state.changesDetected}
              handleChangesDetected={this.handleChangesDetected}
            />
          );
        } else if (currentBlogPost.editor_type === "plain_html") {
          blogPostEditorJSX = (
            <PlainHtml
              currentWebsite={currentWebsite}
              currentPostUrl={this.props.locationPostUrl}
              currentBlogPost={currentBlogPost}
              currentPostIndex={currentPostIndex}
              blogPosts={this.props.blogPosts}
              changesDetected={this.state.changesDetected}
              handleChangesDetected={this.handleChangesDetected}
              plainHtmlPostBody={this.state.plainHtmlPostBody}
              onChangePlainHtmlPostBody={this.onChangePlainHtmlPostBody}
              savePostBodyToStore={this.savePostBodyToStore}
              savePostBodyToServer={this.savePostBodyToServer}
              toggleNewBlogPostEditionsDetected={
                this.props.toggleNewBlogPostEditionsDetected
              }
            />
          );
        }

        if (_.get(currentBlogPost, "is_seobot_post")) {
          return (
            <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
              <Empty description="SEObot posts are not editable in the app">
                <Button type="primary" href={urls.seobotEditor} target="_blank">
                  Edit post in SEObot
                </Button>
              </Empty>
            </div>
          );
        }
        if (currentBlogPost === undefined && currentWebsite !== undefined) {
          // If no such blog post, offer to go to the website pages list.
          return (
            <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
              <Empty description={<span>Blog post not found</span>}>
                <Link to={"/" + currentWebsite.subdomain}>
                  <Button type="primary">
                    {'View all posts of the "' +
                      currentWebsite.subdomain +
                      '" website blog'}
                  </Button>
                </Link>
              </Empty>
            </div>
          );
        } else {
          let leavePagePromptMessage =
            "You have unsaved post changes. Are you sure you want to leave?"; //default value - when both nav and footer are changed
          if (
            (this.props.blogs.isFooterEditionsDetected &&
              this.props.blogs.isNavEditionsDetected) ||
            this.props.websites.isCaptchaEditionsDetected ||
            this.props.websites.isPopupEditionsDetected
          ) {
            // keep it default
          } else if (this.props.blogs.isFooterEditionsDetected) {
            leavePagePromptMessage =
              "You have unsaved blog footer changes. Are you sure you want to leave?";
          } else if (this.props.blogs.isNavEditionsDetected) {
            leavePagePromptMessage =
              "You have unsaved blog navigation bar changes. Are you sure you want to leave?";
          }

          let saveChangesBadgeMessage = "Unsaved changes detected."; //default value - when both nav and footer are changed
          if (
            (this.props.blogs.isFooterEditionsDetected &&
              this.props.blogs.isNavEditionsDetected) ||
            this.props.websites.isCaptchaEditionsDetected ||
            this.props.websites.isPopupEditionsDetected
          ) {
            // keep it default
          } else if (this.props.blogs.isFooterEditionsDetected) {
            saveChangesBadgeMessage = "Unsaved footer changes detected.";
          } else if (this.props.blogs.isNavEditionsDetected) {
            saveChangesBadgeMessage =
              "Unsaved navigation bar changes detected.";
          }

          let saveChangesButtonTooltip =
            "Click to save the blog navigation bar / blog footer changes. Blog post body is autosaved."; //default value - when both nav and footer are changed
          if (
            (this.props.blogs.isFooterEditionsDetected &&
              this.props.blogs.isNavEditionsDetected) ||
            this.props.websites.isCaptchaEditionsDetected ||
            this.props.websites.isPopupEditionsDetected
          ) {
            // keep it default
          } else if (this.props.blogs.isFooterEditionsDetected) {
            saveChangesButtonTooltip =
              "Click to save the blog footer changes. Blog post body is autosaved.";
          } else if (this.props.blogs.isNavEditionsDetected) {
            saveChangesButtonTooltip =
              "Click to save the blog navigation bar changes. Blog post body is autosaved.";
          }

          let saveChangesButtonTitle = "Save changes"; //default value - when both nav and footer are changed
          if (
            (this.props.blogs.isFooterEditionsDetected &&
              this.props.blogs.isNavEditionsDetected) ||
            this.props.websites.isCaptchaEditionsDetected ||
            this.props.websites.isPopupEditionsDetected
          ) {
            // keep it default
          } else if (this.props.blogs.isFooterEditionsDetected) {
            saveChangesButtonTitle = "Save blog footer";
          } else if (this.props.blogs.isNavEditionsDetected) {
            saveChangesButtonTitle = "Save blog navigation";
          }

          let isSaveButtonActive: boolean = false;
          isSaveButtonActive =
            this.props.blogPosts.websitePageNewEditionsDetected ||
            this.props.websites.isFooterEditionsDetected ||
            this.props.websites.isNavEditionsDetected ||
            this.props.websites.isCaptchaEditionsDetected ||
            this.props.websites.isPopupEditionsDetected;

          let isSaveButtonLoading: boolean = false;
          isSaveButtonLoading =
            this.props.blogPosts.isWaitingForSaveWebsitePageSchemeResponse ||
            this.props.websites.isWaitingForFooterEditionsServerResponse ||
            this.props.websites.isWaitingForNavEditionsServerResponse ||
            this.props.websites.isWaitingForCaptchaEditionsServerResponse ||
            this.props.websites.isWaitingForPopupEditionsServerResponse;

          let customColorsActiveClassnames =
            applyCustomColorsClassnames(currentWebsite);

          return (
            <div className={`blog-post-editor ${getCustomStyleClassNames(currentWebsite)}`}>
              <Prompt
                when={isSaveButtonActive || this.state.changesDetected}
                message="You have unsaved page changes. Are you sure you want to leave?"
              />
              {
                <DisplayBadWebsiteOverlay
                  website={currentWebsite}
                  history={this.props.history}
                />
              }

              <div className="blog-post-editor__wrapper">
                <div className="comps custom_fonts">
                  <div
                    className={
                      currentWebsite.color_classname +
                      " " +
                      customColorsActiveClassnames +
                      " " +
                      currentWebsite.active_font_classname
                    }
                  >
                    <WebsiteNav
                      currentWebsite={currentWebsite}
                      componentsArray={[]}
                      saveNavInServer={this.saveNavInServer}
                      isBlogNavEdited={true}
                      navEditionFieldsVisible={
                        this.props.navEditionFieldsVisible
                      }
                      showNavEditionFieldsDrawer={
                        this.props.showNavEditionFieldsDrawer
                      }
                      hideNavEditionFieldsDrawer={
                        this.props.hideNavEditionFieldsDrawer
                      }
                      displayNavOrFooterEditionBox={
                        this.props.displayNavOrFooterEditionBox
                      }
                      blogs={this.props.blogs}
                      saveBlogNavInState={this.props.saveBlogNavInState}
                      saveWebsiteNavInState={this.props.saveWebsiteNavInState}
                      toggleBlogNavEditionsDetected={
                        this.props.toggleBlogNavEditionsDetected
                      }
                      toggleWebsiteNavEditionsDetected={
                        this.props.toggleWebsiteNavEditionsDetected
                      }
                    />
                    <h1 className="blog-post-editor__main-title">
                      <TextArea
                        autosize
                        className="blog-post-editor__main-title_input"
                        value={currentBlogPost.title}
                        placeholder="Enter title"
                        onChange={(event) =>
                          this.onChangeMainTitle(
                            event,
                            currentWebsiteSubdomain,
                            currentPostIndex
                          )
                        }
                        onKeyDown={(e) => this.changeMainTitleFocus(e)}
                      />
                    </h1>
                    {blogPostEditorJSX}
                    <PopupComponents
                      currentPageIndex={currentPostIndex}
                      saveImageInfoInDB={this.props.saveImageInfoInDB}
                      removeImageFromDB={this.props.removeImageFromDB}
                      saveWebsiteDataInStore={this.props.saveWebsiteDataInStore}
                    />
                    <WebsiteFooter
                      currentWebsite={currentWebsite}
                      saveFooterInServer={this.saveFooterInServer}
                      isBlogFooterEdited={true}
                      blogs={this.props.blogs}
                      footerEditionFieldsVisible={
                        this.props.footerEditionFieldsVisible
                      }
                      saveBlogFooterInState={this.props.saveBlogFooterInState}
                      saveWebsiteFooterInState={
                        this.props.saveWebsiteFooterInState
                      }
                      showFooterEditionFieldsDrawer={
                        this.props.showFooterEditionFieldsDrawer
                      }
                      hideFooterEditionFieldsDrawer={
                        this.props.hideFooterEditionFieldsDrawer
                      }
                      displayNavOrFooterEditionBox={
                        this.props.displayNavOrFooterEditionBox
                      }
                      toggleBlogFooterEditionsDetected={
                        this.props.toggleBlogFooterEditionsDetected
                      }
                      toggleWebsiteFooterEditionsDetected={
                        this.props.toggleWebsiteFooterEditionsDetected
                      }
                    />
                    <div
                      className={
                        "blog-post-editor__save_button_box " +
                        (isSaveButtonActive && !isSaveButtonLoading
                          ? " blog-post-editor__save_button_box--accent "
                          : "")
                      }
                    >
                      {this.state.showSuccessSaveButton && (
                        <div className="blog-post-editor__save_button_tick">
                          <Icon
                            type="check-circle"
                            theme="twoTone"
                            twoToneColor="#52c41a"
                          />
                        </div>
                      )}
                      <Badge
                        dot={isSaveButtonActive}
                        title={saveChangesBadgeMessage}
                      >
                        {isSaveButtonActive && (
                          <Button
                            onClick={() => {
                              this.saveNavAndFooterChanges(
                                currentBlog,
                                currentWebsite
                              );
                            }}
                            title={
                              isSaveButtonActive
                                ? saveChangesButtonTooltip
                                : "No changes to save"
                            }
                            disabled={!isSaveButtonActive}
                            htmlType="button"
                            type="primary"
                            icon="save"
                            size="large"
                            loading={isSaveButtonLoading}
                          >
                            {saveChangesButtonTitle}
                          </Button>
                        )}
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>
              <div className="blog-post-editor__bottom-center">
                <div
                  className="blog-post-editor__designed_with"
                  onClick={
                    currentBlogPost.editor_type !== "plain_html"
                      ? this.displaySecretButtonPlainHtml
                      : () => {}
                  }
                >
                  Your article is amazing <Icon type="smile" />
                </div>
                {currentBlogPost.editor_type !== "plain_html" &&
                  this.setToPlainHTMLBlogEditor(
                    currentWebsite,
                    currentBlogPost,
                    currentPostIndex
                  )}
              </div>
              <PopupMask />
              <CustomColorsStyles currentWebsite={currentWebsite} />
              <CustomFontsStyles currentWebsite={currentWebsite} />
              <CustomStyles currentWebsite={currentWebsite}/>
            </div>
          );
        }
      } else {
        return (
          <div style={{ paddingTop: "80px", paddingBottom: "40px" }}>
            <Empty description={<span>Blog post not found</span>}>
              <Link to={`/${currentWebsite.subdomain}/blog`}>
                <Button type="primary">Back to blog posts</Button>
              </Link>
            </Empty>
          </div>
        );
      }
    }
  }
}

export default BlogPostEditor;
