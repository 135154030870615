import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {Icon, Tooltip} from "antd";

interface Props {
    prefix?: any,
    toolTipMessage?: any,
    settingsLink?: any,
}

class NoTitleWarning extends Component<Props>{
    componentDidMount(): void {
    }


    render(){
        let settingsLink = undefined as any;
        if(this.props.settingsLink){
            settingsLink = this.props.settingsLink;
        }

        let toolTipMessage = this.props.toolTipMessage;
        if(toolTipMessage === undefined){
            toolTipMessage = <span>It's recommended so set up a title. Do it in the SEO tab of Page settings.</span>;
            if(settingsLink){
                toolTipMessage = <span>It's recommended so set up a title. Do it in the SEO tab of Page settings.</span>;
            }
        }

        return (
            <span style={{textTransform: "uppercase", fontSize: 12}}>{this.props.prefix} &lt;No title&gt;
                <Tooltip title={toolTipMessage}>
                    <Icon type="warning" style={{marginLeft: '5px', color: 'var(--orange)'}} />
                </Tooltip>
            </span>
        )
    }
}

export default NoTitleWarning;
