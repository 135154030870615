export const freePlanTitle = "lurker";
export const makerPlanTitle = "maker";
export const ltdPlanTitle = "LTD";
export const appsumoPlanTitle = "appsumo";
export const paddleVendorId = 34354;

export let projectUrl =
  process.env.REACT_APP_PROJECT_URL || "unicornplatform.page"; //we serve free websites with a subdomain from a special domain - unicornplatform.page
if (window.location.host === 'app.stage-unicorn.win') {
  projectUrl = "microapps.technology";
}
if (window.location.host === 'app2.stage-unicorn.win') {
  projectUrl = "microapps.technology";
}
if (window.location.host === 'localhost:3000') {
  projectUrl = "unicorn.local:8000";
}

export const projectTitle = "Unicorn Platform";
export const indexWebsitePageDashboardUrl = "home"; // Our API is based on the URL. When a website page is the index page and has an URL of '', we display it as 'home'. Only in the user dashboard. For regular visitors from the Internet - it is ''.
export const defaultBlogUrl = "blog";

export const defaultLogoHeight = 20; //px

export const defaultUploadedPictogramHeight = 30; //px

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const betaDiscountCouponCode = "betaluck50"; //Beta period discount
export const phDiscountCouponCode = "meow33";

export const lightboxActivationWidth = 300;

export const draftJsEditorTitle = "draftjs";
export const plainHTMLEditorTitle = "plain_html";

export const recaptachav3SiteKey = "6Ld6uLwUAAAAANEsm49Lx9SB_sviJ8Tr2OTyx_MO"; //v3
export const recaptachav2SiteKey = "6LcNtfIUAAAAACa2LKz2DkZSML1e9sYmHGkGYaR9";

export const googleFontsAPIkey = "AIzaSyBpLoF5xNKI-FRoVPf0_Qa2c1kH09b-t3c"; // https://developers.google.com/fonts/docs/developer_api

export const googleFontsEnabledClassname = "custom-google-fonts-enabled";

export const GOOGLE_OAUTH_CLIENT_ID =
  "332126932228-ig9ubmobk74g5a3a4c66rbd48gudq89p.apps.googleusercontent.com";

export const blogPostPreDefaultUrl = "untitled-post";

export const copiedComponentDataSessionStorageKey = "uni-copied-component";

export const defaultBgImageOverlayOpacity = 0.8;

export const defaultScreenshotURL =
  "https://uni-pages-screenshots.s3.amazonaws.com/empty_thumbnail.jpg"; // https://s3.console.aws.amazon.com/s3/upload/uni-pages-screenshots?region=us-east-1

export const marketingParams = [
  "ref",
  "utm_source",
  "utm_medium",
  "utm_content",
  "utm_campaign",
];

export const DISCORD_COLOR_PURPLE = 10181046;

export const SECONDS_BEFORE_TIMEOUT = 90;

export const notificationDuration = {
  short: 5,
  medium: 15,
  long: 30,
};

export const UCARE_CDN = "ucarecdn.com";
export const BUNNY_CDN = "unicorn-cdn.b-cdn.net";
export const S3_BLOG = "up-blog-prod.s3.amazonaws.com";
export const BUNNY_S3_CDN = "unicorn-s3.b-cdn.net";

export const GPT_ASSISTANT_PROMPT_LENGTH_LIMIT = 1000;
export const GPT_ASSISTANT_MAX_MESSAGE_HISTORY = 5;

// Defines the frequency of data updates for GPT Assistant. Larger value means less frequent updates which is better for performance.
export const GPT_RENDER_DATA_INTERVAL_MS = 100;
export const GPT_PROMPT_HISTORY_MAX_LENGTH = 5;
export const GPT_CHARACTERS_PER_TICK_EDIT = 10;
export const GPT_CHARACTERS_PER_TICK_TEMPLATE = 20;
export const GPT_FILL_TEMPLATE_RETRY_ATTEMPTS = 5;

export const DEFAULT_ERROR_TITLE = "Server error";
export const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please contact us.";

export const PATH_HISTORY_LS_KEY = "uniPathHistory";

export const DEFAULT_DIRECTORY_ITEM_HTML = `<div class="directory-01__item card-container sc-br-0_5">
<a target="{{dynamicTarget}}" href="{{dynamicUrl}}">
   <div class="directory-01__title-box">
      <h3 class="title-text--inner directory-01__title def-16">{{title}}</h3>
      <div class="directory-01__rating">
         <span class="directory-01__rating-text content-text def-14">{{rank}}</span>
         <svg
            viewBox="0 0 16 16"
            height="16"
            width="16"
            class="directory-01__rating-icon"
            >
            <path d="M8 .25a.75.75 0 0 1 .673.418l1.882 3.815 4.21.612a.75.75 0 0 1 .416 1.279l-3.046 2.97.719 4.192a.751.751 0 0 1-1.088.791L8 12.347l-3.766 1.98a.75.75 0 0 1-1.088-.79l.72-4.194L.818 6.374a.75.75 0 0 1 .416-1.28l4.21-.611L7.327.668A.75.75 0 0 1 8 .25Zm0 2.445L6.615 5.5a.75.75 0 0 1-.564.41l-3.097.45 2.24 2.184a.75.75 0 0 1 .216.664l-.528 3.084 2.769-1.456a.75.75 0 0 1 .698 0l2.77 1.456-.53-3.084a.75.75 0 0 1 .216-.664l2.24-2.183-3.096-.45a.75.75 0 0 1-.564-.41L8 2.694Z"></path>
         </svg>
      </div>
   </div>
   <div class="directory-01__text content_box content-text def-14">
      <p>{{text}}</p>
   </div>
</a>
</div>`

export const DEFAULT_DIRECTORY_2_ITEM_HTML = `<div class="directory-01__item card-container sc-br-0_5">
<a target="_blank" href="{{url}}">
   <div class="directory-01__title-box">
      <div class="directory-01__title-inner-box">
         <img class="directory-01__logo" src="{{imgUrl}}" alt="{{title}} logo"/>
         <h3 class="title-text--inner directory-01__title def-16">{{title}}</h3>
      </div>
      <div class="directory-01__rating">
         <span class="directory-01__rating-text content-text def-14">{{rank}}</span>
         <svg
            viewBox="0 0 16 16"
            height="16"
            width="16"
            class="directory-01__rating-icon"
            >
            <path d="M8 .25a.75.75 0 0 1 .673.418l1.882 3.815 4.21.612a.75.75 0 0 1 .416 1.279l-3.046 2.97.719 4.192a.751.751 0 0 1-1.088.791L8 12.347l-3.766 1.98a.75.75 0 0 1-1.088-.79l.72-4.194L.818 6.374a.75.75 0 0 1 .416-1.28l4.21-.611L7.327.668A.75.75 0 0 1 8 .25Zm0 2.445L6.615 5.5a.75.75 0 0 1-.564.41l-3.097.45 2.24 2.184a.75.75 0 0 1 .216.664l-.528 3.084 2.769-1.456a.75.75 0 0 1 .698 0l2.77 1.456-.53-3.084a.75.75 0 0 1 .216-.664l2.24-2.183-3.096-.45a.75.75 0 0 1-.564-.41L8 2.694Z"></path>
         </svg>
      </div>
   </div>
   <div class="directory-01__text content_box content-text def-14">
      <p>{{text}}</p>
   </div>
</a>
</div>`

export const DEFAULT_DIRECTORY_3_ITEM_HTML = `<div class="directory-01__item card-container sc-br-0_5">
<a target="_blank" href="{{url}}">
   <div class="directory-01__top-box">
      <img class="directory-01__thumbnail" src="{{imgUrl}}" alt="{{title}} thumbnail"/>
   </div>
   <div class="directory-01__bottom-box">
      <div class="directory-01__title-box">
         <h3 class="title-text--inner directory-01__title def-16">{{title}}</h3>
         <div class="directory-01__rating">
            <span class="directory-01__rating-text content-text def-14">{{rank}}</span>
            <svg
               viewBox="0 0 16 16"
               height="16"
               width="16"
               class="directory-01__rating-icon"
               >
               <path d="M8 .25a.75.75 0 0 1 .673.418l1.882 3.815 4.21.612a.75.75 0 0 1 .416 1.279l-3.046 2.97.719 4.192a.751.751 0 0 1-1.088.791L8 12.347l-3.766 1.98a.75.75 0 0 1-1.088-.79l.72-4.194L.818 6.374a.75.75 0 0 1 .416-1.28l4.21-.611L7.327.668A.75.75 0 0 1 8 .25Zm0 2.445L6.615 5.5a.75.75 0 0 1-.564.41l-3.097.45 2.24 2.184a.75.75 0 0 1 .216.664l-.528 3.084 2.769-1.456a.75.75 0 0 1 .698 0l2.77 1.456-.53-3.084a.75.75 0 0 1 .216-.664l2.24-2.183-3.096-.45a.75.75 0 0 1-.564-.41L8 2.694Z"></path>
            </svg>
         </div>
      </div>
      <div class="directory-01__text content_box content-text def-14">
         <p>{{text}}</p>
      </div>         
   </div>
</a>
</div>`

export const GOOGLE_OAUTH2_CLIENT_ID = "1030351919949-aqjjdc4kfbb59b9ufpis3hldqv2mqb51.apps.googleusercontent.com";
