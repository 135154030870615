import React from "react";
import { connect } from "react-redux";
import { getCurrentWebsite } from "../../../helpers/getCurrentWebsite";

interface Props {
  websites?: any;
}

const PopupMask = (props: Props): JSX.Element => {
  const currentWebsite = getCurrentWebsite(props.websites.items);
  const popupItems = currentWebsite.popups.items;

  if (!popupItems) return null;
  if (popupItems.length === 0) return null;

  return (
    <div className="popup-component__mask js-close-custom-popup-button js-custom-popup-mask"></div>
  );
};

const mapStateToProps = (state) => {
  const { websites } = state;
  return {
    websites,
  };
};
export default connect(mapStateToProps, null)(PopupMask);
