import React, { useEffect } from "react";
import { Alert, Button, Icon } from "antd";
import { connect } from "react-redux";
import { SaveUserEventsInStore, UserState } from "../../../../store/user/types";
import { AuthState } from "../../../../store/auth/types";
import { saveUserEventsInStore } from "../../../../store/user/actions";
import { setUserEventsAsync } from "../../../../helpers/user/setUserEventsAsync";
import { UserEvents } from "../../../../enums/UserEvents";
import { ActiveMenu } from "../../SettingsDrawerContent";

interface Props {
  user: UserState;
  auth: AuthState;
  saveUserEventsInStore: SaveUserEventsInStore;

  history: any;
  setActiveMenu: React.Dispatch<React.SetStateAction<ActiveMenu>>;
}

const ExportNotAvailable = (props: Props): JSX.Element => {
  const { history, user, auth, saveUserEventsInStore } = props;

  useEffect(() => {
    setUserEventsAsync(
      {
        [UserEvents.ExportNotAvailable]: new Date(),
      },
      auth.accessToken,
      user.data.events
    ).then((response) => {
      if (response) {
        saveUserEventsInStore(response.data);
      }
    });
  }, []);

  const handleUpgradeToUnlock = (e: any): void => {
    props.setActiveMenu("account__plan");
  };

  return (
    <div>
      <Alert
        style={{ width: "100%", marginBottom: 20 }}
        message="Locked"
        description={
          <span>
            HTML export is available on all paid plans. Upgrade your account to
            access the export feature and download an unlimited amount of pages
            per month.
          </span>
        }
        type="info"
        icon={<Icon type="download" />}
        showIcon
      />

      <Button
        onClick={handleUpgradeToUnlock}
        icon="star"
        type="primary"
        size="large"
        htmlType="button"
      >
        Upgrade
      </Button>
    </div>
  );
};

const mapStateToProps = ({ user, auth }) => {
  return {
    user,
    auth,
  };
};
const mapActionsToProps = {
  saveUserEventsInStore,
};
export default connect(mapStateToProps, mapActionsToProps)(ExportNotAvailable);
