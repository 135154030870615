import _ from "lodash";
import { pricingItemDetailIconOptions } from "../../../../data/pricingItemDetailIconOptions";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleDeleteAllRows = (componentData) => {
  componentData.pricing.content.comparisionTableHeadersSet = [];
  componentData.pricing.content.items.forEach((column) => {
    column.comparisionTableDetailsSet = [];
  });
};

const particleDeleteRow = (componentData, itemId) => {
  componentData.pricing.content.items.forEach((column) => {
    addDefaultValuesToItems(column.comparisionTableDetailsSet);
  });
  componentData.pricing.content.comparisionTableHeadersSet = deleteItem(
    componentData.pricing.content.comparisionTableHeadersSet,
    itemId
  );
  componentData.pricing.content.items.forEach((column) => {
    column.comparisionTableDetailsSet = deleteItem(
      column.comparisionTableDetailsSet,
      itemId
    );
  });
};

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TEXT) || "";
  const itemHoverInfo =
    stringifyObjectsAndNumbers(modifiedItem.HOVER_INFO) || "";
  return {
    itemId,
    itemPosition,
    itemTitle,
    itemHoverInfo,
  };
};

const particleModifyOriginalItem = (
  originalItem,
  modifiedItem,
  componentData,
  itemPosition,
  itemTitle,
  itemHoverInfo
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.pricing.content.comparisionTableHeadersSet.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(
          componentData.pricing.content.comparisionTableHeadersSet,
          item
        );
      }
    });

    componentData.pricing.content.items.forEach((column) => {
      const correspondingItem = column.comparisionTableDetailsSet.find(
        (item) => item.READONLY_ID === originalItem.READONLY_ID
      );
      correspondingItem.POSITION_IN_ARRAY = itemPosition;
      column.comparisionTableDetailsSet.forEach((item) => {
        if (
          item.POSITION_IN_ARRAY === itemPosition &&
          item.READONLY_ID !== originalItem.READONLY_ID
        ) {
          pushFromItem(column.comparisionTableDetailsSet, item);
        }
      });
    });
  }
  if ("TEXT" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("HOVER_INFO" in modifiedItem) {
    originalItem.hoverInfo = itemHoverInfo;
  }
};

export const updatePricingTableRowTitles = (
  componentData,
  completionContent
) => {
  if (!("ROW_TITLES" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.ROW_TITLES)) {
    if (completionContent.ROW_TITLES === null) {
      particleDeleteAllRows(componentData);
    }
    return;
  }
  addDefaultValuesToItems(
    componentData.pricing.content.comparisionTableHeadersSet
  );
  componentData.pricing.content.items.forEach((column) => {
    addDefaultValuesToItems(column.comparisionTableDetailsSet);
  });
  completionContent.ROW_TITLES.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemTitle,
      itemHoverInfo,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      particleDeleteRow(componentData, itemId);
      return;
    }
    const isItemNew = getIsItemNew(
      componentData.pricing.content.comparisionTableHeadersSet,
      itemId
    );
    if (isItemNew) {
      const newItem = generator.createPlansComparisionTableRowsHeadersObject();
      newItem.title = "";
      componentData.pricing.content.items.forEach((column) => {
        const newRowItem = generator.createPlansComparisionTableDetailObject();
        newRowItem.title = "";
        newRowItem.icon = pricingItemDetailIconOptions.none;
        addNewItem(
          column.comparisionTableDetailsSet,
          newRowItem,
          itemId,
          itemPosition
        );
      });
      addNewItem(
        componentData.pricing.content.comparisionTableHeadersSet,
        newItem,
        itemId,
        itemPosition
      );
    }
    const originalItem = getOriginalItem(
      componentData.pricing.content.comparisionTableHeadersSet,
      itemId
    );
    particleModifyOriginalItem(
      originalItem,
      modifiedItem,
      componentData,
      itemPosition,
      itemTitle,
      itemHoverInfo
    );
  });
  sortItemsByPosition(componentData.pricing.content.comparisionTableHeadersSet);
  componentData.pricing.content.items.forEach((column) => {
    sortItemsByPosition(column.comparisionTableDetailsSet);
  });
};
