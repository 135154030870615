export const getIdAndPosition = (
  modifiedItem,
  idKey: string = "READONLY_ID",
  positionKey: string = "POSITION_IN_ARRAY"
) => {
  let itemId = modifiedItem[idKey];
  let itemPosition = modifiedItem[positionKey];
  if (typeof itemId !== "number") {
    itemId = 0;
  }
  if (typeof itemPosition !== "number" && itemPosition !== null) {
    itemPosition = 999;
  }
  return [itemId, itemPosition];
};
