import React, { Component } from 'react';
import { Slider } from 'antd';
import { SliderValue } from 'antd/lib/slider';

interface Props {
    step: number;
    value: number;
    tipFormatter: (value: number) => string;
    onChange: (value: SliderValue) => void;
    defaultValue: number;
    min: number;
    max: number;
    label?: string;
};

class BackgroundTransparencySlider extends Component<Props> {
    render() {
        const props = this.props;
        return (
            <div className="background_image_picker__transparency_box">
                {props.label && <label className="content-input__label">{props.label}</label>}
                <div className="background_image_picker__transparency_slider">
                    <Slider
                        step={props.step}
                        value={props.value}
                        tipFormatter={props.tipFormatter}
                        onChange={props.onChange}
                        defaultValue={props.defaultValue}
                        min={props.min}
                        max={props.max}
                    />
                </div>
            </div>
        );
    }
}

export default BackgroundTransparencySlider;