import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Features02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Created by Makers for Makers"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                maxItems: 16,

                hasSubtitle: true,
                hasText: true,
                hasPictogram: false,
                hasHighlight: false,
                hasHighlightInfo: false,
                hasButton: false,

                items: [
                    this.generator.createFeatureContentObject({
                        title: '39%',
                        subtitle: 'faster',
                        text: 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium vol.'
                    }),
                    this.generator.createFeatureContentObject({
                        title: '20%',
                        subtitle: 'cooler',
                        text: 'Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nob.'
                    }),
                    this.generator.createFeatureContentObject({
                        title: '99.98%',
                        subtitle: 'uptime',
                        text: 'Et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae si.'
                    }),


                    // The last ghost item which is used to add new items.
                    this.generator.createFeatureContentObject({
                        title: '',
                        text: ''
                    }),
                ]

            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the features component to offer more to read about your project.",
                    default: "Add a CTA (call to action) to the bottom of the features component to offer more to read about your project.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'See also'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let features = this.props.componentItem.componentData.features.content.items;

        return (
            <section>

                <div className="features-02">
                    
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    
                    <div className="container">
                        <ul className="features-02__items">
                            {features && features.map((item:any, key:number) => {
                                if(item.title && item.title !== ''){
                                    let titleClass = 'title-text--inner def-50 ';
                                    if(item.title.length > 30){
                                        titleClass = ' title-text--inner def-16 features-02__number--small ';
                                    }else if(item.title.length > 16){
                                        titleClass = ' title-text--inner def-24 features-02__number--medium ';
                                    }

                                    return (
                                        <li className="features-02__item" key={'features-02-' + key}>
                                            <div className="features-02__item_box">
                                                <div className={"features-02__number " + titleClass + (this.props.isDarkBg ? ' text-white ' : ' ')}>{item.title}<span
                                                    className={"content-text features-02__word " + (this.props.isDarkBg ? 'text-white' : '')}>{item.subtitle}</span></div>
                                                <div className={"content-text features-02__description content_box " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: item.text}}/>
                                            </div>
                                        </li>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </ul>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>

                    </div>
                </div>

            </section>
        )
    }
}

export default Features02;
