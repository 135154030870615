import filterOnlyCurrentWebsiteSharedAccesses from "./filterOnlyCurrentWebsiteSharedAccesses";

function isWebsiteSharedWithCurrentUser(sharedAccessesObject:any, currentWebsiteId:number, currentUserEmail:string):any {
  // N.B.: we expect sharedAccessesObject not .items
  let listOfSharedAccessOfThisWebsiteOnly = filterOnlyCurrentWebsiteSharedAccesses(sharedAccessesObject, currentWebsiteId);

  let doesCurrentUserEmailIsInTheSharedAccessesList = false;
  let ownerEmail = '';

  for (let index = 0; index < listOfSharedAccessOfThisWebsiteOnly.length; index++) {
    if(listOfSharedAccessOfThisWebsiteOnly[index].manager_email === currentUserEmail){
      doesCurrentUserEmailIsInTheSharedAccessesList = true;
      ownerEmail = listOfSharedAccessOfThisWebsiteOnly[index]['owner_email'];
      break;
    }
  }

  if(doesCurrentUserEmailIsInTheSharedAccessesList){
    return {
      isTrue: true,
      ownerEmail: ownerEmail
    }
  }else{
    return {
      isTrue: false,
    }
  }



}

export default isWebsiteSharedWithCurrentUser;