import React from "react";
import { emojiPath, publicUrl } from "../../data/urls";

interface Props {
  emojiSrc: string;
  className?: string;
  alt?: string;
  height?: number;
}

const Emoji = (props: Props): JSX.Element => {
  const { emojiSrc, className, alt, height } = props;

  return (
    <img
      src={publicUrl + emojiPath.prefix + emojiSrc}
      height={height}
      className={className ? className : ""}
      alt={alt ? alt : ""}
    />
  );
};

export default Emoji;
