import axios from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { setIsProPlan } from "../../helpers/user/setIsProPlan";
import {
  toggleUserLtd,
  toggleUserPro,
  toggleUserAppsumo,
  setUserStateData,
} from "../user/actions";
import { setIsLtdPlan } from "../../helpers/user/setIsLtdPlan";
import { setIsAppsumoPlan } from "../../helpers/user/setIsAppsumoPlan";
import { UserState } from "../user/types";
import { PlansState } from "./types";
import { getCurrentPlan } from "../../helpers/plans/getCurrentPlan";
import { CrispEvents, PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { getUrlParamsAsObject } from "../../helpers/getUrlParamsAsObject";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { format } from "date-fns";

const actionsNames = {
  SAVE_PLANS_DATA: "SAVE_PLANS_DATA" as string,
  PLANS_DATA_FETCHED: "PLANS_DATA_FETCHED" as string,
};

export const fetchPlans = () => {
  return (dispatch: any, getState: any) => {
    const cacheParam = getState().meta.cache;
    const url = `${api.plans.fetchPlans}?v=${cacheParam}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(savePlansInState(response.data));
        dispatch(togglePlansFetched(true));

        setIsProPlan(getState().user, getState().plans, (isPro: any) => {
          dispatch(toggleUserPro(isPro));
        });

        setIsLtdPlan(getState().user, getState().plans, (isLtd: any) => {
          dispatch(toggleUserLtd(isLtd));
        });

        setIsAppsumoPlan(
          getState().user,
          getState().plans,
          (isAppsumo: any) => {
            dispatch(toggleUserAppsumo(isAppsumo));
          }
        );

        const user: UserState = getState().user;
        const plans: PlansState = getState().plans;
        const currentPlan = getCurrentPlan(user, plans);
        const urlParams = getUrlParamsAsObject();
        window.posthog.identify(`id:${user.data.id}`, {
          email: user.data.email,
          first_name: user.data.first_name,
          last_name: user.data.last_name,
          plan: currentPlan.title,
          plan_period: currentPlan.period,
          referrer_url: sessionStorage.getItem('referrer'),
        });
        if (user.isLoggedIn) {
          window.posthog.capture(PosthogEvents.LOGIN, {
            auth_type: user.authType || "default",
          });
          dispatch(setUserStateData({ isLoggedIn: false }));
        }
        if (user.isSignedUp) {
          window.posthog.capture(PosthogEvents.SIGNUP, {
            auth_type: user.authType || "default",
            $set_once: {
              initial_referrer_url: sessionStorage.getItem('referrer'),
              signup_date: format(new Date(), "YYYY-MM-DD"),
              ...urlParams,
            },
          });
          const referrerHostname = new URL(sessionStorage.getItem('referrer')).hostname;
          if (referrerHostname === "unicornplatform.com") {
            window.posthog.capture(PosthogEvents.SIGN_UP_FULL);
          }
          fireAnalyticsEvent.fireCrisp(CrispEvents.signUp);
          dispatch(setUserStateData({ isSignedUp: false }));
        }
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

export const savePlansInState = (data: string) => {
  // get user data and store it
  return {
    type: actionsNames.SAVE_PLANS_DATA as string,
    payload: data as string,
  };
};

export const togglePlansFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.PLANS_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export default actionsNames;
