import _ from "lodash";
import { getPageArray } from "../../../components/editor/ai2/getPageArray";
import { deleteModifiedComponent, editInputData } from "../actions";
import { GptState, HandleOnComponentUpdateFinished } from "../types";
import { handleCleanUpAiData } from "./handleCleanUpAiData";
import { getCurrentInputData } from "./getCurrentInputData";

export const handleOnComponentUpdateFinished: HandleOnComponentUpdateFinished = ({
  pageId,
  currentWebsitePage,
  currentWebsite,
  currentPageIndex,
  componentId,
  queueDataRef,
  isTemplate,
}) => {
  return (dispatch, getState) => {
    const pageArray = getPageArray(currentWebsitePage, currentWebsite);
    const component = pageArray.find((pageComponent) => {
      return pageComponent.id === componentId;
    });
    dispatch(
      editInputData({
        componentId: component.id,
        newData: {
          status: "finished",
        },
      })
    );
    dispatch(
      deleteModifiedComponent({
        componentId: component.id,
      })
    );
    const queueItem = queueDataRef.current.find(
      (item) => item.componentId === componentId
    );
    const parsedCompletionData = _.get(queueItem, "parsedCompletionData");
    const inputData = dispatch(getCurrentInputData(componentId));
    if (!isTemplate) {
      dispatch(
        editInputData({
          componentId,
          newData: {
            isLoading: false,
            completionResultData: {
              ...inputData.completionResultData,
              hasChanges: !!parsedCompletionData,
              isSessionStarted: true,
            },
          },
        })
      );
    }
    dispatch(
      handleCleanUpAiData({
        currentWebsitePage,
        currentWebsite,
        componentId,
        currentPageIndex,
      })
    );
  };
};
