import React, { useEffect } from "react";
import generateContentDataObject from "../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../data/callToActionOptions";
import { GraphicsOptions } from "../components/editor/component_graphics/data/componentGraphicsData";
import _ from "lodash";
import GraphicsJSX from "../pageComponents/GraphicsJSX";
import { displayImageUrl } from "../helpers/content_displayers/displayImageUrl";
import { displayTitleMarkupText } from "../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../pageComponents/component_molecules/WebsiteCTA";
import { DEFAULT_IMAGES_CDN } from "../data/urls";

interface Props {
  componentItem: any;
  isDarkBg: boolean;
  popupComponentHasImage: boolean;

  checkIsEmptyContent: (payload: any) => boolean;
  connectDefaultFormIntegration: (componentItem: any) => void;
  saveContentInStore: (payload: any) => void;

  setPopupComponentHasImage: (newValue: boolean) => void;
}

const popup01 = (props: Props): JSX.Element => {
  const generator = new generateContentDataObject();

  const setUpInitialComponentData = () => {
    if (props.checkIsEmptyContent(props.componentItem)) {
      props.saveContentInStore(
        generator.setUpGraphics({
          activeOption: GraphicsOptions.image,
          userCanAddItems: false,
          maxItems: 1,
          fieldInfo: {
            [GraphicsOptions.image]: "",
            [GraphicsOptions.video]: "",
            default: "",
          },
          items: [
            generator.createGraphicsContentObject({
              imageUrl: DEFAULT_IMAGES_CDN.ABSTRACT_05,
              videoUrl: "https://www.youtube.com/watch?v=lfucQM07tGQ",
              width: 700,
              height: 532
            }),
          ],
        })
      );

      props.saveContentInStore(
        generator.setUpTitle({
          titleText: "Get in Touch",
        })
      );

      props.saveContentInStore(
        generator.setUpSubtitleText({
          subtitleText: "Our excellent customer support team is ready to help.",
        })
      );

      props.saveContentInStore(
        generator.setUpCta({
          activeOption: callToActionOptions.buttons,
          buttons: [
            generator.createButtonContentObject({
              buttonTitle: "Contact us",
            }),
          ],
        })
      );
    }
  };

  setUpInitialComponentData();

  props.connectDefaultFormIntegration(props.componentItem);

  const componentData = props.componentItem.componentData;
  const title = displayTitleMarkupText(componentData.title.content.markup);
  const subtitleText = displaySubtitleMarkupText(
    componentData.subtitleText.content.markup
  );
  const imageUrl = displayImageUrl(
    componentData.graphics.content.items[0][GraphicsOptions.image]
  );
  const currentActiveOption: GraphicsOptions = _.get(props.componentItem, [
    "componentData",
    "graphics",
    "activeOption",
  ]);
  const videoUrl: string = _.get(props.componentItem, [
    "componentData",
    "graphics",
    "content",
    "items",
    "0",
    GraphicsOptions.video,
    "youtube",
    "url",
  ]);
  const codeSnippet: string = _.get(props.componentItem, [
    "componentData",
    "graphics",
    "content",
    "items",
    "0",
    GraphicsOptions.HTML,
    "codeSnippet",
  ]);
  const componentHasImage =
    (currentActiveOption === GraphicsOptions.image && !!imageUrl) ||
    (currentActiveOption === GraphicsOptions.video && !!videoUrl) ||
    currentActiveOption === GraphicsOptions.HTML;

  const displayTitle = () => {
    if (title.length > 0) {
      return (
        <h2
          className={`title-text heading popup-01__heading ${
            props.isDarkBg ? " text-white" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      );
    }
    return null;
  };
  const displaySubtitle = () => {
    if (subtitleText.length > 0) {
      return (
        <p
          className={`subtitle-text content_box popup-01__text ${
            props.isDarkBg ? "text-white" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: subtitleText }}
        />
      );
    }
    return null;
  };
  const displayImage = () => {
    if (componentHasImage) {
      return (
        <div className="popup-01__right">
          <GraphicsJSX
            currentActiveOption={currentActiveOption}
            defaultGraphicsOption={GraphicsOptions.image}
            isDarkBg={props.isDarkBg}
            imageUrl={imageUrl}
            videoUrl={videoUrl}
            codeSnippet={codeSnippet}
            classNames={{
              image: "popup-01__img",
              video: "popup-01__video",
              HTML: "popup-01__HTML popup-01__editor-HTML",
            }}
            permanentSpinnerColor
            isEditorHTMLBackgroundAlwaysLight
          />
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const booleanComponentHasImage = componentHasImage === true;
    if (booleanComponentHasImage !== props.popupComponentHasImage) {
      props.setPopupComponentHasImage(booleanComponentHasImage);
    }
  });

  return (
    <div
      className={`popup-01 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image}`}
    >
      <div className="container container--premid popup-01__container">
        <div className="popup-01__left">
          <div className="popup-01__content">
            {displayTitle()}
            {displaySubtitle()}
            <div className="popup-01__cta-box">
              <WebsiteCTA
                componentItem={props.componentItem}
                isDarkBg={props.isDarkBg}
              />
            </div>
          </div>
        </div>
        {displayImage()}
      </div>
    </div>
  );
};

export default popup01;
