import React from "react";
import { Slider } from "antd";
import { InputProps } from "./interfaces";
import DebouncedInput from "../../DebouncedInput";
import ImagePicker from "../ImagePicker";
import capitalizeString from "../../../helpers/strings/capitalizeString";
import { useCurrentInputData } from "./useCurrentInputData";
import InputLabel from "../InputLabel";

const NavLogo = (props: InputProps): JSX.Element => {
  // isBlogObjectEdited is needed to detect when we call displayNav in the <BlogEditor>. We need another "save in state" function and we also need to get the nav object not from website but from blog model.
  // if editing blog nav - isBlogObjectEdited=true
  // if editing website nav - isBlogObjectEdited=false
  const {
    content,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    currentWebsite,
    saveImageInfoInDB,
    removeImageFromDB,
    isBlogObjectEdited,
    currentBlog,
  } = props;
  
  let navObject: any;
  
  // navObject contains links, buttons, logo and any other nav-related objects. We have different nav bars for website and blog.
  if (isBlogObjectEdited) {
    navObject = currentBlog.nav;
  } else {
    navObject = currentWebsite.nav;
  }

  const navObjectRef = useCurrentInputData(navObject);

  let currentBlogId = undefined;
  if (isBlogObjectEdited && currentBlog) {
    currentBlogId = currentBlog.id;
  }
  let onChangeNavCompanyTitleHandler = (value: any) => {
    /*
     * saveContentInStore - stores the change in the Redux store
     * generator.changeNavCompanyTitleText - generates a new nav object
     * */
    if (isBlogObjectEdited) {
      saveContentInStore({
        navObject: generator.changeNavCompanyTitleText(navObjectRef.current, value),
        currentBlogId: currentBlogId,
      });
    } else {
      saveContentInStore({
        navObject: generator.changeNavCompanyTitleText(navObjectRef.current, value),
        currentWebsiteSubdomain: currentWebsite.subdomain,
      });
    }
    toggleNewEditionsDetected(true);
  };

  // NAVIGATION SPECIFIC FIELD:
  return (
    <div className="content-input__group">
      <div className="content-input__input">
        <ImagePicker
          currentImgAlt={content.alt}
          saveImageAltInReduxStore={(newAlt: string) => {
            if (isBlogObjectEdited) {
              saveContentInStore({
                navObject: generator.changeNavLogoAlt(navObjectRef.current, newAlt),
                currentBlogId: currentBlogId,
              });
            } else {
              saveContentInStore({
                navObject: generator.changeNavLogoAlt(navObjectRef.current, newAlt),
                currentWebsiteSubdomain: currentWebsite.subdomain,
              });
            }

            toggleNewEditionsDetected(true);
          }}
          currentImgSrc={content.src}
          currentImgUUID={content.uuid}
          inputId={contentType}
          height={content.logoHeight}
          saveImageInfoInDB={saveImageInfoInDB}
          removeImageFromDB={removeImageFromDB}
          saveImageInReduxStore={(newSrc: string, newUUID: any, width, height) => {
            if (isBlogObjectEdited) {
              saveContentInStore({
                navObject: generator.changeNavLogoSrc(navObjectRef.current, newSrc, width, height), //'' for no image
                currentBlogId: currentBlogId,
              });
              saveContentInStore({
                navObject: generator.changeNavLogoUUID(navObjectRef.current, newUUID), //'' for no image
                currentBlogId: currentBlogId,
              });
            } else {
              saveContentInStore({
                navObject: generator.changeNavLogoSrc(navObjectRef.current, newSrc, width, height), //'' for no image
                currentWebsiteSubdomain: currentWebsite.subdomain,
              });
              saveContentInStore({
                navObject: generator.changeNavLogoUUID(navObjectRef.current, newUUID), //'' for no image
                currentWebsiteSubdomain: currentWebsite.subdomain,
              });
            }

            toggleNewEditionsDetected(true);
          }}
        />
      </div>

      <div className="content-input__input">
        {/*value = 30 is the default logo height. used in ContentInput (changeNavLogoHeight), in the Editor (displayNav) and in the render nav.html*/}
        {content.src !== "" && (
          <Slider
            min={5}
            max={100}
            step={1}
            value={content.logoHeight || 30}
            tipFormatter={(value: number) => `Logo height: ${value}px`}
            onChange={(value: any) => {
              if (isBlogObjectEdited) {
                saveContentInStore(
                  generator.changeNavLogoHeight(currentBlog.nav, value)
                );
              } else {
                saveContentInStore(
                  generator.changeNavLogoHeight(currentWebsite.nav, value)
                );
              }
              toggleNewEditionsDetected(true);
            }}
          />
        )}
      </div>
      
      <div className="content-input__input" style={{marginTop: "20px"}}>
        <InputLabel>Enter text:</InputLabel>
        <DebouncedInput
          className="content-input__input"
          onChange={onChangeNavCompanyTitleHandler}
          defaultValue={content.companyTitle.text}
          placeholder={
            isBlogObjectEdited
              ? capitalizeString(currentWebsite.subdomain) + " blog"
              : capitalizeString(currentWebsite.subdomain) + " project"
          }
          id={contentType}
        />
      </div> 
    </div>
  );
};

export default NavLogo;
