import React from "react";

interface Props {
  title: string;
  subtitleText: string;
  isDarkBg: boolean;
  isH1Tag?: boolean;
}

const TitleBox = (props: Props): JSX.Element => {
  const { title, subtitleText, isDarkBg, isH1Tag } = props;

  // This return <h2>. If you need <h1>, add new argument.
  let isSubtitleTextEmpty =
    subtitleText.length === 0 || subtitleText === "<p></p>"; //because draftjs leaves empty <p>. N.B.: This logic is skipped in the component of the 'Header' type because Header's are designed to have a title and we do not care if one of 10000 users leave it blank and it will look slightly inaccurate in this one rare case.

  if (title.length === 0 && isSubtitleTextEmpty) {
    return null;
  } else {
    let headerTag = (
      <h2
        className={"title-text heading " + (isDarkBg ? "text-white" : "")}
        dangerouslySetInnerHTML={{ __html: title }}
      />
    );
    if (isH1Tag !== undefined && isH1Tag === true) {
      <h1
        className={"title-text heading " + (isDarkBg ? "text-white" : "")}
        dangerouslySetInnerHTML={{ __html: title }}
      />;
    }

    return (
      <div className="container container--small">
        <div className="title-box title-box--center">
          {title.length > 0 && headerTag}
          {!isSubtitleTextEmpty && (
            <p
              className={
                "subtitle-text title-box__text content_box " + (isDarkBg ? "text-white" : "")
              }
              dangerouslySetInnerHTML={{ __html: subtitleText }}
            />
          )}
        </div>
      </div>
    );
  }
};

export default TitleBox;
