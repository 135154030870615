import React, { useEffect } from "react";
import { animated, config, useSpring } from "@react-spring/web";
import classNames from "classnames";
import sendIcon from "../../../img/icons/material_icon_send.svg";
import { ModeStatus } from "../../../store/gpt/types";

interface Props {
  inputValue: string;
  isLoading: boolean;
  className?: string;
}

const SendButton = (props: Props): JSX.Element => {
  const { inputValue, isLoading, className } = props;
  if (isLoading) return null;

  const [springFadeIn, setSpringFadeIn] = useSpring(() => ({
    opacity: 0,
    scale: 0.8,
    config: config.wobbly,
  }));

  useEffect(() => {
    if (!!inputValue.trim()) {
      setSpringFadeIn({ opacity: 1, scale: 1 });
      return;
    }
    setSpringFadeIn({ opacity: 0, scale: 0.8 });
  }, [inputValue]);

  return (
    <animated.button type="submit" className={className} style={springFadeIn}>
      <img src={sendIcon} />
    </animated.button>
  );
};

export default SendButton;
