import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { useCurrentInputData } from "../useCurrentInputData";
import GalleryTabPictogram from "./GalleryTabPictogram";
import GalleryTabSubtitle from "./GalleryTabSubtitle";
import GalleryTabTitle from "./GalleryTabTitle";

interface Props {
  item: any;
  saveContentInStore: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: (value: boolean) => void;
  displayPictogramEditorOpener: (pictogram: any, key: number) => JSX.Element;
}

{/*This group is needed for styling a Tab. A Tab may have a title, a pictogram etc. However, Sliders are not likely to have such attributes.*/}
const GalleryTabField = (props: Props): JSX.Element => {
  const {
    item,
    saveContentInStore,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
    displayPictogramEditorOpener,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  if (
    !currentInputDataRef.current.tabHasPictogram &&
    !currentInputDataRef.current.tabHasTitle &&
    !currentInputDataRef.current.tabHasSubtitle
  ) {
    return null;
  }

  return (
    <div className="content-input__group">
      <GalleryTabPictogram
        item={item}
        currentInputData={currentInputData}
        keyProp={keyProp}
        displayPictogramEditorOpener={displayPictogramEditorOpener}
      />
      <GalleryTabTitle
        item={item}
        saveContentInStore={saveContentInStore}
        contentType={contentType}
        currentInputData={currentInputData}
        keyProp={keyProp}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
      />
      <GalleryTabSubtitle
        item={item}
        saveContentInStore={saveContentInStore}
        contentType={contentType}
        currentInputData={currentInputData}
        keyProp={keyProp}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
      />
    </div>
  );
};

export default GalleryTabField;
