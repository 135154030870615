import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import _ from "lodash";
import "./Other02.scss";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Other02 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({
          isDefaultEmpty,
          titleText: "",
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({
          isDefaultEmpty,
          subtitleText: "",
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpWidget({
          isDefaultEmpty,
          fieldTitle: { default: "Testimonials" },
          label: { default: "Testimonials by Senja:" },
          labelTooltip: {
            default: "Add testimonials widget by senja.io.",
          },
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a button to the bottom of the testimonial component to link to more testimonials.",
            default:
              "Add a button to the bottom of the testimonial component to link to more testimonials.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "Read more",
            }),
          ],

          priority: 10,
        })
      );
    }
  }

  componentDidMount(): void {
    // Check if the script is already in the head of the dom
    let script = document.querySelector(
      `script[src="https://static.senja.io/dist/platform.js"]`
    ) as HTMLScriptElement;
    if (script) return;
    script = document.createElement("script");
    script.src = "https://static.senja.io/dist/platform.js";
    script.async = true;
    script.type = "text/javascript";
    document.body.append(script);
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );
    const widgetHtml = _.get(
      this.props.componentItem,
      "componentData.widget.content.code",
      ""
    );

    return (
      <section>
        <div className="other-02">
          <TitleBox
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />
          <div
            className="other-02__widget-container"
            dangerouslySetInnerHTML={{ __html: widgetHtml }}
          />
          <div className="bottom_cta">
            {this.props.componentItem.componentData.cta && (
              <WebsiteCTA componentItem={this.props.componentItem} />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Other02;
