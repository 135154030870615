import React, {Component} from "react";
import generateContentDataObject from '../../helpers/editor/generateContentDataObject'
import {callToActionOptions} from "../../data/callToActionOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import { displayImageUrl } from "../../helpers/content_displayers/displayImageUrl";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import { publicUrl } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Header01 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpGraphics({
                activeOption: GraphicsOptions.image,
                userCanAddItems: false,
                maxItems: 1,
                fieldInfo: {
                    [GraphicsOptions.image]: "Recommended size: 1440x900px",
                    [GraphicsOptions.video]: "",
                    'default': "",
                },
                items: [
                    this.generator.createGraphicsContentObject({
                        imageUrl: publicUrl + "/img/screenshots/dashboard-concept.png",
                    })
                ]
            }));
            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({subtitleText: '',isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpCta({
                activeOption: callToActionOptions.buttons,
                isDefaultEmpty
            }));
        }
    }

    render(){
        this.props.connectDefaultFormIntegration(this.props.componentItem);

        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);

        let subtitleTextObject = this.props.componentItem.componentData.subtitleText;
        let subtitleText = subtitleTextObject ? displaySubtitleMarkupText(subtitleTextObject.content.markup) : undefined;
        let imageUrl = displayImageUrl(this.props.componentItem.componentData.graphics.content.items[0][GraphicsOptions.image]); //This component has exactly one item and more can not be added (see createGraphicsContentObject() above).

        return (

            <header className="header-01">
                <div className="header-01__box">
                    <div className="container">
                        <div className="header-01__heading">
                            {title.length > 0 && <h1 className={"title-text heading heading--accent " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: title}}/>}
                            {subtitleText && subtitleText.length > 0 && <p className={"subtitle-text header-01__text "  + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: subtitleText}}/>}
                        </div>

                        <div className="header-01__buttons">
                            {/*header-01__buttons actually holds any type of CTA: buttons or form*/}
                            <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={this.props.isDarkBg} />
                        </div>
                        <div className="header-01__mockup">
                            <div className="mockup  mockup--white mockup--macbook mockup--landscape ">
                                <div className="mockup__container">
                                    <div className="mockup__case">
                                        <div className="mockup__spacer"></div>
                                        <div className="mockup__screen">
                                            <div className="mockup__screenshot" style={{'backgroundImage': 'url(' + imageUrl + ')'}}/>
                                        </div>
                                    </div><img className="mockup__bottom" src={publicUrl + "/img/other/macbook-mockup-shadow.png"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        )
    }
}

export default Header01;
