import React, {Component} from "react";
import {googleFontsEnabledClassname} from "../../data/constants";
import {checkIfObjectEmpty} from "../../helpers/checkIfObjectEmpty";


interface Props {
    currentWebsite: any,
}

class CustomFontsStyles extends Component<Props>{
    componentDidMount(): void {
    }

    render(){
        if(
            this.props.currentWebsite
            && this.props.currentWebsite.active_font_classname === googleFontsEnabledClassname
            && checkIfObjectEmpty(this.props.currentWebsite.custom_fonts_set) === false
            && this.props.currentWebsite.custom_fonts_set.title
            && this.props.currentWebsite.custom_fonts_set.text
            && this.props.currentWebsite.custom_fonts_set.title !== ''
            && this.props.currentWebsite.custom_fonts_set.text !== ''
        ){

            let titlesFontName = this.props.currentWebsite.custom_fonts_set.title;
            let textFontName = this.props.currentWebsite.custom_fonts_set.text;

            let updatedTitlesFontName = titlesFontName.split(' ').join('+');
            let updatedTextFontName = textFontName.split(' ').join('+');

            return (
                <div>
                    <link href={`https://fonts.googleapis.com/css?family=${updatedTitlesFontName}:700|${updatedTextFontName}:400,700&display=swap`} rel="stylesheet"/>

                    <style dangerouslySetInnerHTML={{__html: `
                        .custom_fonts .${googleFontsEnabledClassname} * {
                            font-family: '${textFontName}', Helvetica, sans-serif;
                        }
                        .custom_fonts .${googleFontsEnabledClassname} h1,
                        .custom_fonts .${googleFontsEnabledClassname} h2,
                        .custom_fonts .${googleFontsEnabledClassname} h3,
                        .custom_fonts .${googleFontsEnabledClassname} h4,
                        .custom_fonts .${googleFontsEnabledClassname} h5,
                        .custom_fonts .${googleFontsEnabledClassname} h6{
                            font-family: '${titlesFontName}', Helvetica, serif;
                        }
                    `}}>
                    </style>
                </div>
            )
        }else{
            return (null);
        }
    }
}

export default CustomFontsStyles;