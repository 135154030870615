function addProtocolToLink(href:string):string {
    let newHref = href;

    if(newHref.indexOf("http://") === 0){
        newHref = newHref.replace("http://", "https://")
    }
    if(newHref.indexOf("https://") === -1){
        newHref = "https://" + newHref;
    }

    return newHref;
}

export default addProtocolToLink;