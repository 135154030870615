import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { displayMarkupText } from "../../helpers/content_displayers/displayMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}

class Text03 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpText(
                {
                    fieldTitle: {default: "Text columns"},
                    label: {default: "Fill in the left and the right column:"},
                    items: [
                        {"markup": isDefaultEmpty ? "" : "Cur clinias peregrinationes? Abactus emeritis adelphis est. Demolitione de emeritis elogium, examinare solitudo!"},

                        {"markup": isDefaultEmpty ? "" : "A falsis, agripeta castus torquis. Cur luba potus? Victrixs manducare! Fermiums sunt vortexs de nobilis particula. Nunquam transferre bulla. Cur usus manducare? A falsis, barcas pius ratione. Genetrixs studere in cubiculum! Velum satis ducunt ad regius domina. Gemnas persuadere in domesticus vasa! Cum cotta nocere, omnes decores consumere dexter, bassus caesiumes. Eleatess velum in albus cella! Heu, gratis capio! Cum ausus nocere, omnes nuclear vexatum iacerees amor varius, lotus cottaes."}
                    ]}
            ));
            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the text component to offer more to read.",
                    default: "Add a CTA (call to action) to the bottom of the text component to offer more to read.",
                },


                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'More'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts

        // In this Text component there is only one text element.
        let leftText = displayMarkupText(this.props.componentItem.componentData.text.content.items[0].markup);
        let rightText = displayMarkupText(this.props.componentItem.componentData.text.content.items[1].markup);

        return (
            <section>
                <div className="text--03">
                    <div className="container">
                        <div className="text--03__wrapper">

                            <div className="text--03__left">
                                <div className={"content_box " + (this.props.isDarkBg ? 'text-white' : '')}>
                                    {leftText.length > 0 && <div className={"title-text content_box text--03__big_text " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: leftText}}/>}
                                </div>
                            </div>
                            <div className="text--03__right">
                                <div className="content_box">
                                    {rightText.length > 0 && <p className={"content-text content_box " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: rightText}}/>}
                                </div>
                            </div>

                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Text03;
