export const emoji = {
    categories: [
        {
            title: 'people',
            items: [

                {
                    src: '1f4aa.svg',
                    keywords: ['bicep', 'strength', 'muscles', 'arm', '💪'],
                },
                {
                    src: '1f938.svg',
                    keywords: ['🤸‍', 'woman', 'female', 'girl', 'cartwheeling'],
                },

                {
                    src: '1f914.svg',
                    keywords: ['thinking', '🤔', 'chin', 'confused'],
                },
                {
                    src: '1f60f.svg',
                    keywords: ['smirking', '😏', 'flirting', 'suggestive'],
                },
                {
                    src: '1f600.svg',
                    keywords: ['laughing', '😀', 'grinning', 'smile'],
                },
                {
                    src: '1f44d.svg',
                    keywords: ['like', '👍', 'thumbs', 'up', 'yes'],
                },
                {
                    src: '1f93e-200d-2642-fe0f.svg',
                    keywords: ['🤾‍', 'man', 'handball', 'play'],
                },
                {
                    src: '1f60d.svg',
                    keywords: ['😍', 'heart', 'smiling', 'high_voltage'],
                },
                {
                    src: '1f911.svg',
                    keywords: ['🤑', 'money', 'smiling', 'dollar'],
                },
                {
                    src: '2764.svg',
                    keywords: ['❤️', 'heart', 'red', 'love'],
                },

                {
                    src: '1f44b.svg',
                    keywords: ['👋️', 'waving', 'wave', 'hand', 'hi', 'hello'],
                },
                {
                    src: '1f642.svg',
                    keywords: ['🙂️', 'smiling', 'slightly', 'find'],
                },
                {
                    src: '1f590.svg',
                    keywords: ['🖐️️', 'hand', 'five', 'fingers'],
                },

                {
                    src: '1f64c.svg',
                    keywords: ['🙌️️', 'hands', 'raising', 'praise'],
                },


                {
                    src: '1f913.svg',
                    keywords: ['🤓️️', 'nerd', 'geek', 'smiling'],
                },
                {
                    src: '1f917.svg',
                    keywords: ['🤗️️', 'hug', 'happy', 'praise'],
                },

                {
                    src: '1f3a2.svg',
                    keywords: ['rollercoaster', 'fun'],
                },
                {
                    src: '1f3c3-1f3fd-200d-2640-fe0f.svg',
                    keywords: ['runner', 'woman', 'girl', 'sport'],
                },
                {
                    src: '1f3c4-1f3fd-200d-2642-fe0f.svg',
                    keywords: ['surfing', 'ocean', 'sport', 'man'],
                },
                {
                    src: '1f3c7-1f3fd.svg',
                    keywords: ['horse', 'ride', 'man'],
                },
                {
                    src: '1f3cc-1f3fb.svg',
                    keywords: ['gold', 'sport', 'man'],
                },
                {
                    src: '1f6b4-1f3fd.svg',
                    keywords: ['cycling', 'sport', 'man'],
                },
                {
                    src: '1f6b6.svg',
                    keywords: ['man', 'walking'],
                },
                {
                    src: '1f9b8-1f3fd.svg',
                    keywords: ['super', 'woman'],
                },
                {
                    src: '1f9b8-1f3fd-200d-2642-fe0f.svg',
                    keywords: ['super', 'man'],
                },
                {
                    src: '1f9ce-1f3fd-200d-2640-fe0f.svg',
                    keywords: ['knee', 'woman'],
                },
                {
                    src: '1f9d5-1f3fc.svg',
                    keywords: ['hijab', 'woman'],
                },
                {
                    src: '1f9d6-1f3fc-200d-2642-fe0f.svg',
                    keywords: ['shower', 'man'],
                },
                {
                    src: '1f9d8-1f3fd-200d-2640-fe0f.svg',
                    keywords: ['woman', 'meditation', 'yoga'],
                },
                {
                    src: '1f9d9-1f3fc-200d-2642-fe0f.svg',
                    keywords: ['man', 'magic'],
                },
                {
                    src: '1f9da-1f3fb.svg',
                    keywords: ['fairy', 'woman' +
                    ''],
                },
                {
                    src: '1f9de.svg',
                    keywords: ['genie', 'man'],
                },
                {
                    src: '1f44f.svg',
                    keywords: ['clap', 'hand'],
                },
                {
                    src: '1f60e.svg',
                    keywords: ['sunglasses'],
                },
                {
                    src: '1f61d.svg',
                    keywords: ['happy'],
                },
                {
                    src: '1f62a.svg',
                    keywords: ['meh'],
                },
                {
                    src: '1f61b.svg',
                    keywords: ['happy'],
                },
                {
                    src: '1f62c.svg',
                    keywords: ['clenched', 'teeth'],
                },
                {
                    src: '1f63c.svg',
                    keywords: ['asserted', 'cat'],
                },
                {
                    src: '1f64e-1f3fc-200d-2642-fe0f.svg',
                    keywords: ['man', 'wow'],
                },
                {
                    src: '1f64f.svg',
                    keywords: ['pray'],
                },
                {
                    src: '1f91a.svg',
                    keywords: ['stop', 'hand'],
                },
                {
                    src: '1f91f.svg',
                    keywords: ['hand', 'rock'],
                },
                {
                    src: '1f92d.svg',
                    keywords: ['fun'],
                },
                {
                    src: '1f93c-200d-2640-fe0f.svg',
                    keywords: ['fight'],
                },
                {
                    src: '1f93e-1f3fc.svg',
                    keywords: ['hand'],
                },
                {
                    src: '1f97a.svg',
                    keywords: ['sad'],
                },
                {
                    src: '1f446.svg',
                    keywords: ['hand'],
                },
                {
                    src: '1f447.svg',
                    keywords: ['hand'],
                },
                {
                    src: '1f448.svg',
                    keywords: ['hand'],
                },
                {
                    src: '1f449.svg',
                    keywords: ['hand'],
                },

                {
                    src: '1f9df-200d-2640-fe0f.svg',
                    keywords: ['zombie', 'woman'],
                },

                {
                    src: '1f46e-1f3fb-200d-2640-fe0f.svg',
                    keywords: ['police', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f3a4.svg',
                    keywords: ['singer', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f3a8.svg',
                    keywords: ['artist', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f3eb.svg',
                    keywords: ['teacher', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f3ed.svg',
                    keywords: ['woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f4bb.svg',
                    keywords: ['laptop', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f4bc.svg',
                    keywords: ['analyst', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f33e.svg',
                    keywords: ['farm', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f52c.svg',
                    keywords: ['lab', 'science', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f373.svg',
                    keywords: ['cook', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f393.svg',
                    keywords: ['student', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f527.svg',
                    keywords: ['worker', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f680.svg',
                    keywords: ['space', 'astro', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-1f692.svg',
                    keywords: ['fire', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-2695-fe0f.svg',
                    keywords: ['doc', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-2696-fe0f.svg',
                    keywords: ['law', 'woman'],
                },
                {
                    src: '1f469-1f3fb-200d-2708-fe0f.svg',
                    keywords: ['woman', 'office'],
                },


                {
                    src: '1f9df-200d-2642-fe0f.svg',
                    keywords: ['zombie', 'man'],
                },
                {
                    src: '1f46e-1f3fc.svg',
                    keywords: ['police', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f3a4.svg',
                    keywords: ['singer', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f3a8.svg',
                    keywords: ['artist', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f3eb.svg',
                    keywords: ['teacher', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f3ed.svg',
                    keywords: ['man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f4bb.svg',
                    keywords: ['laptop', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f4bc.svg',
                    keywords: ['analyst', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f33e.svg',
                    keywords: ['farm', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f52c.svg',
                    keywords: ['lab', 'science', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f373.svg',
                    keywords: ['cook', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f393.svg',
                    keywords: ['student', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f527.svg',
                    keywords: ['worker', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-1f680.svg',
                    keywords: ['space', 'astro'],
                },
                {
                    src: '1f468-1f3fb-200d-1f692.svg',
                    keywords: ['fire', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-2695-fe0f.svg',
                    keywords: ['doc', 'man'],
                },
                {
                    src: '1f468-1f3fb-200d-2696-fe0f.svg',
                    keywords: ['law', 'man'],
                },
                {
                    src: '1f477-1f3fd-200d-2642-fe0f.svg',
                    keywords: ['man', 'builder'],
                },
                {
                    src: '1f468-1f3fb-200d-2708-fe0f.svg',
                    keywords: ['man', 'office'],
                },





                {
                    src: '1f475-1f3fc.svg',
                    keywords: ['grand'],
                },
                {
                    src: '1f596.svg',
                    keywords: ['nad'],
                },
                {
                    src: '1f601.svg',
                    keywords: ['smile'],
                },
                {
                    src: '1f605.svg',
                    keywords: ['sweat'],
                },
                {
                    src: '1f607.svg',
                    keywords: ['bliss'],
                },
                {
                    src: '1f609.svg',
                    keywords: ['wink'],
                },
                {
                    src: '1f610.svg',
                    keywords: ['meh'],
                },
                {
                    src: '1f611.svg',
                    keywords: ['meh'],
                },
                {
                    src: '1f615.svg',
                    keywords: ['sad'],
                },
                {
                    src: '1f617.svg',
                    keywords: ['kiss'],
                },
                {
                    src: '1f632.svg',
                    keywords: ['wow'],
                },
                {
                    src: '1f634.svg',
                    keywords: ['zzz', 'sleep'],
                },
                {
                    src: '1f637.svg',
                    keywords: ['mask'],
                },
                {
                    src: '1f643.svg',
                    keywords: ['smile'],
                },
                {
                    src: '1f645-1f3fb.svg',
                    keywords: ['stop', 'woman'],
                },
                {
                    src: '1f647-1f3fd.svg',
                    keywords: ['man', 'warning'],
                },
                {
                    src: '1f924.svg',
                    keywords: ['happy'],
                },
                {
                    src: '1f929.svg',
                    keywords: ['star'],
                },
                {
                    src: '1f935-1f3fc.svg',
                    keywords: ['man'],
                },
                {
                    src: '1f935-1f3fd-200d-2640-fe0f.svg',
                    keywords: ['woman'],
                },
                {
                    src: '26f9-1f3fd-200d-2640-fe0f.svg',
                    keywords: ['woman'],
                },
                {
                    src: '270c.svg',
                    keywords: ['finger'],
                },
                {
                    src: '270d.svg',
                    keywords: ['pen', 'write'],
                },

            ]
        },
        {
            title: 'objects',
            items: [
                {
                    src: '1f4e3.svg',
                    keywords: ['cheering', 'megaphone', '📣'],
                },

                {
                    src: '1f4de.svg',
                    keywords: ['phone', 'mobile', 'tel'],
                },

                {
                    src: '1f3a9.svg',
                    keywords: ['hat', 'formal', '🎩'],
                },

                {
                    src: '1f451.svg',
                    keywords: ['hat', 'crown', 'king', 'luxury', 'gold', 'queen', 'royal', '👑'],
                },

                {
                    src: '1f381.svg',
                    keywords: ['birthday', 'present', 'christmas', 'present', '🎁', 'gift', 'box'],
                },
                {
                    src: '1f36a.svg',
                    keywords: ['cookie', 'biscuit', '🍪', 'cookie', 'chip'],
                },
                {
                    src: '1f510.svg',
                    keywords: ['Lock', 'key', 'closed', '🔐'],
                },
                {
                    src: '1f680.svg',
                    keywords: ['🚀', 'rocket', 'space', 'shuttle'],
                },
                {
                    src: '1f6e0.svg',
                    keywords: ['hammer', 'wrench', 'repair', 'tools', '🛠️'],
                },
                {
                    src: '1f50c.svg',
                    keywords: ['🔌', 'ac', 'adaptor', 'power', 'cable', 'plug'],
                },
                {
                    src: '1f6f4.svg',
                    keywords: ['scooter', '🛴', 'kick'],
                },
                {
                    src: '1f3cd.svg',
                    keywords: ['motorbike', 'motorcycle', '🏍', 'racing'],
                },
                {
                    src: '1f4d2.svg',
                    keywords: ['📒', 'ledger', 'book', 'high_voltage'],
                },
                {
                    src: '1f5a5.svg',
                    keywords: ['imac', 'mac', 'pc', 'screen', 'desktop', 'computer', '🖥️', 'monitor'],
                },
                {
                    src: '1f6e9.svg',
                    keywords: ['🛩️', 'jet', 'airplane', 'plane', 'aircraft'],
                },
                {
                    src: '1f5fa.svg',
                    keywords: ['🗺️', 'world', 'map'],
                },
                {
                    src: '1f4bc.svg',
                    keywords: ['briefcase', 'suitcase', '💼', 'case'],
                },
                {
                    src: '1f3e2.svg',
                    keywords: ['building', 'house', 'business', 'office', '🏢', 'city'],
                },

                {
                    src: '26f5.svg',
                    keywords: ['⛵', 'dinghy', 'sailboat', 'boat', 'ship'],
                },

                {
                    src: '1f4c3.svg',
                    keywords: ['📃', 'page', 'sheet'],
                },
                {
                    src: '1f4d1.svg',
                    keywords: ['📑', 'page', 'sheet', 'bookmarks'],
                },

                {
                    src: '1f4d8.svg',
                    keywords: ['📘', 'blue', 'sheet', 'book'],
                },
                {
                    src: '1f3a5.svg',
                    keywords: ['video', 'camera', 'youtube', 'motion'],
                },
                {
                    src: '1f3a7.svg',
                    keywords: ['headphones', 'music'],
                },
                {
                    src: '1f3a8.svg',
                    keywords: ['palette', 'color'],
                },
                {
                    src: '1f3ac.svg',
                    keywords: ['video', 'movie', 'director', 'cinema'],
                },
                {
                    src: '1f3ae.svg',
                    keywords: ['play', 'ps', 'xbox', 'game', 'joystick'],
                },
                {
                    src: '1f3a1.svg',
                    keywords: ['fun', 'wheel'],
                },
                {
                    src: '1f3b1.svg',
                    keywords: ['8', 'ball'],
                },
                {
                    src: '1f3ad.svg',
                    keywords: ['drama', 'theatre'],
                },
                // {
                    // src: '1f3ad.svg',
                    // keywords: ['target', 'arrow', 'goal'],
                // },
                {
                    src: '1f3b8.svg',
                    keywords: ['guitar', 'music'],
                },
                {
                    src: '1f3b9.svg',
                    keywords: ['piano', 'music'],
                },
                {
                    src: '1f3c6.svg',
                    keywords: ['trophy', 'victory'],
                },
                {
                    src: '1f3c5.svg',
                    keywords: ['medal', 'victory'],
                },
                {
                    src: '1f3d7.svg',
                    keywords: ['building'],
                },
                {
                    src: '1f3d8.svg',
                    keywords: ['house', 'home', 'building'],
                },
                {
                    src: '1f3d9.svg',
                    keywords: ['city', 'town', 'house'],
                },
                {
                    src: '1f3db.svg',
                    keywords: ['building'],
                },
                {
                    src: '1f3e1.svg',
                    keywords: ['house', 'home', 'building'],
                },
                {
                    src: '1f3e5.svg',
                    keywords: ['house', 'home', 'building', 'doc', 'hospital'],
                },
                {
                    src: '1f3f0.svg',
                    keywords: ['castle', 'building'],
                },
                {
                    src: '1f3f7.svg',
                    keywords: ['tag'],
                },
                {
                    src: '1f4a1.svg',
                    keywords: ['bulb', 'idea'],
                },
                {
                    src: '1f4a6.svg',
                    keywords: ['drops'],
                },
                {
                    src: '1f4ab.svg',
                    keywords: ['star', 'magic'],
                },
                {
                    src: '1f4b0.svg',
                    keywords: ['money', 'bag'],
                },
                {
                    src: '1f4b3.svg',
                    keywords: ['bank', 'atm', 'cc', 'card'],
                },
                {
                    src: '1f4b5.svg',
                    keywords: ['$', 'dollar'],
                },
                {
                    src: '1f4b6.svg',
                    keywords: ['euro'],
                },
                {
                    src: '1f4bb.svg',
                    keywords: ['pc', 'laptop'],
                },
                {
                    src: '1f4ca.svg',
                    keywords: ['graph', 'data'],
                },
                {
                    src: '1f4ce.svg',
                    keywords: ['pin'],
                },
                {
                    src: '1f4d6.svg',
                    keywords: ['book'],
                },
                {
                    src: '1f4da.svg',
                    keywords: ['book'],
                },
                {
                    src: '1f4dd.svg',
                    keywords: ['pen', 'sheet'],
                },
                {
                    src: '1f4e2.svg',
                    keywords: ['megaphone'],
                },
                {
                    src: '1f4e6.svg',
                    keywords: ['box', 'pack'],
                },
                {
                    src: '1f4e8.svg',
                    keywords: ['email', 'letter'],
                },
                {
                    src: '1f4ec.svg',
                    keywords: ['email', 'letter', 'inbox'],
                },
                {
                    src: '1f4f7.svg',
                    keywords: ['photo', 'camera'],
                },
                {
                    src: '1f5c3.svg',
                    keywords: ['archive', 'files'],
                },
                {
                    src: '1f5dd.svg',
                    keywords: ['key'],
                },
                {
                    src: '1f6a4.svg',
                    keywords: ['boat'],
                },
                {
                    src: '1f6a7.svg',
                    keywords: ['stop'],
                },
                {
                    src: '1f6a8.svg',
                    keywords: ['signal'],
                },
                {
                    src: '1f6cd.svg',
                    keywords: ['shopping', 'bag'],
                },
                {
                    src: '1f6e1.svg',
                    keywords: ['protection', 'shield'],
                },
                {
                    src: '1f6ce.svg',
                    keywords: ['bell', 'service'],
                },
                {
                    src: '1f6d2.svg',
                    keywords: ['shopping', 'cart'],
                },
                {
                    src: '1f6f0.svg',
                    keywords: ['space', 'sputnik'],
                },
                {
                    src: '1f6f8.svg',
                    keywords: ['ufo'],
                },
                {
                    src: '1f9af.svg',
                    keywords: ['stick', 'blind'],
                },
                {
                    src: '1f9bd.svg',
                    keywords: ['wheelchair'],
                },
                {
                    src: '1f9c0.svg',
                    keywords: ['cheese'],
                },
                {
                    src: '1f9c1.svg',
                    keywords: ['cake'],
                },
                {
                    src: '1f9c7.svg',
                    keywords: ['pancake'],
                },
                {
                    src: '1f9ca.svg',
                    keywords: ['ice', 'cube'],
                },
                {
                    src: '1f9e0.svg',
                    keywords: ['brain'],
                },
                {
                    src: '1f9ec.svg',
                    keywords: ['dna'],
                },
                {
                    src: '1f9ea.svg',
                    keywords: ['lab'],
                },
                {
                    src: '1f9f2.svg',
                    keywords: ['magnet'],
                },
                {
                    src: '1f9ff.svg',
                    keywords: ['3rd', 'eye', 'om'],
                },
                {
                    src: '1f34c.svg',
                    keywords: ['banana'],
                },
                {
                    src: '1f36c.svg',
                    keywords: ['candy'],
                },
                {
                    src: '1f39e.svg',
                    keywords: ['tape', 'video'],
                },
                {
                    src: '1f48e.svg',
                    keywords: ['diamond'],
                },
                {
                    src: '1f50e.svg',
                    keywords: ['magnifier', 'zoom'],
                },
                {
                    src: '1f52c.svg',
                    keywords: ['microscope'],
                },
                {
                    src: '231a.svg',
                    keywords: ['time', 'watch'],
                },
                {
                    src: '1f58c.svg',
                    keywords: ['brush'],
                },
                {
                    src: '1f58a.svg',
                    keywords: ['pen'],
                },
                {
                    src: '1f58d.svg',
                    keywords: ['marker'],
                },
                {
                    src: '1f69d.svg',
                    keywords: ['train', 'sinkansen'],
                },
                {
                    src: '1f94b.svg',
                    keywords: ['dogi', 'kimono', 'belt'],
                },
                {
                    src: '1f96c.svg',
                    keywords: ['veg'],
                },
                {
                    src: '1f96a.svg',
                    keywords: ['sandwich'],
                },
                {
                    src: '1f95d.svg',
                    keywords: ['kiwi'],
                },
                {
                    src: '1f95e.svg',
                    keywords: ['pancakes'],
                },
                {
                    src: '1f97c.svg',
                    keywords: ['robe'],
                },
                {
                    src: '1f195.svg',
                    keywords: ['new', 'sign'],
                },
                {
                    src: '1f301.svg',
                    keywords: ['sf', 'bridge', 'san fran'],
                },
                {
                    src: '1f303.svg',
                    keywords: ['city', 'night'],
                },
                {
                    src: '1f307.svg',
                    keywords: ['city', 'day'],
                },
                {
                    src: '1f308.svg',
                    keywords: ['rainbow'],
                },
                {
                    src: '1f320.svg',
                    keywords: ['star'],
                },
                {
                    src: '1f359.svg',
                    keywords: ['onigiri'],
                },
                {
                    src: '1f368.svg',
                    keywords: ['ice'],
                },
                {
                    src: '1f366.svg',
                    keywords: ['ice'],
                },
                {
                    src: '1f369.svg',
                    keywords: ['donut'],
                },
                {
                    src: '1f380.svg',
                    keywords: ['stripe'],
                },
                {
                    src: '1f384.svg',
                    keywords: ['xmas'],
                },
                {
                    src: '1f382.svg',
                    keywords: ['cake'],
                },
                {
                    src: '1f389.svg',
                    keywords: ['fun'],
                },
                {
                    src: '1f396.svg',
                    keywords: ['medal'],
                },
                {
                    src: '1f397.svg',
                    keywords: ['stripe'],
                },
                {
                    src: '1f399.svg',
                    keywords: ['mic'],
                },
                {
                    src: '1f440.svg',
                    keywords: ['eyes'],
                },
                {
                    src: '1f454.svg',
                    keywords: ['shirt'],
                },
                {
                    src: '1f528.svg',
                    keywords: ['hammer'],
                },
                {
                    src: '1f573.svg',
                    keywords: ['hole'],
                },
                {
                    src: '1f957.svg',
                    keywords: ['veg'],
                },
                {
                    src: '1f967.svg',
                    keywords: ['pie'],
                },
                {
                    src: '1f966.svg',
                    keywords: ['veg'],
                },
                {
                    src: '1fa78.svg',
                    keywords: ['drop'],
                },
                {
                    src: '26cf.svg',
                    keywords: ['mine'],
                },
                {
                    src: '26f3.svg',
                    keywords: ['golf', 'hole'],
                },
                {
                    src: '26fd.svg',
                    keywords: ['gas'],
                },
                {
                    src: '2604.svg',
                    keywords: ['snowball'],
                },
                {
                    src: '2614.svg',
                    keywords: ['umbrella'],
                },
                {
                    src: '2708.svg',
                    keywords: ['plane'],
                },
                {
                    src: '2709.svg',
                    keywords: ['letter', 'email'],
                },
            ]
        },
        {
            title: 'nature',
            items: [
                {
                    src: '26a1.svg',
                    keywords: ['lightning', 'bolt', 'thunderbolt', 'high', 'voltage', '⚡'],
                },
                {
                    src: '1f423.svg',
                    keywords: ['hatching', 'chicken', '🐣', 'baby'],
                },
                {
                    src: '1f324.svg',
                    keywords: ['🌤️', 'sun', 'cloud', 'behind'],
                },
                {
                    src: '1f984.svg',
                    keywords: ['🦄', 'unicorn', 'magic', 'horse'],
                },
                {
                    src: '2600.svg',
                    keywords: ['☀️️', 'sun', 'sunshine'],
                },
                {
                    src: '2601.svg',
                    keywords: ['☁️', 'cloud', 'overcast'],
                },
                {
                    src: '1f432.svg',
                    keywords: ['🐲', 'dragon', 'myth', 'power'],
                },
                {
                    src: '1f340.svg',
                    keywords: ['luck', 'clover', 'shamrock', '🍀'],
                },

                {
                    src: '1f3d6.svg',
                    keywords: ['beach', 'umbrella', '🏖️', 'rest'],
                },

                {
                    src: '1f332.svg',
                    keywords: ['pine', 'tree', '🌲', 'forest'],
                },

                {
                    src: '1f3a3.svg',
                    keywords: ['fish', 'bite'],
                },
                {
                    src: '1f3d4.svg',
                    keywords: ['yama', 'mountain'],
                },
                {
                    src: '1f3d5.svg',
                    keywords: ['camp'],
                },
                {
                    src: '1f3f5.svg',
                    keywords: ['flower'],
                },
                {
                    src: '1f9a0.svg',
                    keywords: ['amoeba'],
                },
                {
                    src: '1f9a2.svg',
                    keywords: ['swan'],
                },
                {
                    src: '1f9a5.svg',
                    keywords: ['lazy'],
                },
                {
                    src: '1f9ae.svg',
                    keywords: ['dog', 'blind'],
                },
                {
                    src: '1f9a6.svg',
                    keywords: ['vydra'],
                },
                {
                    src: '1f30b.svg',
                    keywords: ['volcano'],
                },
                {
                    src: '1f32a.svg',
                    keywords: ['tornado'],
                },
                {
                    src: '1f33a.svg',
                    keywords: ['flower'],
                },
                {
                    src: '1f33f.svg',
                    keywords: ['leaves'],
                },
                {
                    src: '1f40c.svg',
                    keywords: ['snail'],
                },
                {
                    src: '1f41e.svg',
                    keywords: ['bug'],
                },
                {
                    src: '1f47d.svg',
                    keywords: ['alien'],
                },
                {
                    src: '1f54a.svg',
                    keywords: ['dove'],
                },
                {
                    src: '1f64a.svg',
                    keywords: ['monkey'],
                },
                {
                    src: '1f648.svg',
                    keywords: ['monkey'],
                },
                {
                    src: '1f649.svg',
                    keywords: ['monkey'],
                },

                {
                    src: '1f98b.svg',
                    keywords: ['butterfly'],
                },
                {
                    src: '1f98f.svg',
                    keywords: ['rhino'],
                },
                {
                    src: '1f98c.svg',
                    keywords: ['deer'],
                },
                {
                    src: '1f334.svg',
                    keywords: ['palm', 'tree'],
                },
                {
                    src: '1f341.svg',
                    keywords: ['canada'],
                },
                {
                    src: '1f343.svg',
                    keywords: ['leaf'],
                },
                {
                    src: '1f408.svg',
                    keywords: ['cat'],
                },
                {
                    src: '1f415.svg',
                    keywords: ['dog'],
                },
                {
                    src: '1f435.svg',
                    keywords: ['monkey'],
                },
                {
                    src: '1f436.svg',
                    keywords: ['dog'],
                },
                {
                    src: '1f437.svg',
                    keywords: ['pig'],
                },
                {
                    src: '1f438.svg',
                    keywords: ['frog'],
                },
                {
                    src: '1f578.svg',
                    keywords: ['net', 'spider'],
                },
            ]
        },
        {
            title: 'symbols',
            items: [
                {
                    src: '2705.svg',
                    keywords: ['tick', '✅', 'check', 'green'],
                },
                {
                    src: '1f3b5.svg',
                    keywords: ['note'],
                },
                {
                    src: '1f4a2.svg',
                    keywords: ['angry'],
                },
                {
                    src: '1f4b1.svg',
                    keywords: ['currency', 'exchange'],
                },
                {
                    src: '1f4e5.svg',
                    keywords: ['download'],
                },
                {
                    src: '1f4e4.svg',
                    keywords: ['upload'],
                },
                {
                    src: '1f5e3.svg',
                    keywords: ['speak', 'loud'],
                },
                {
                    src: '1f6ab.svg',
                    keywords: ['stop'],
                },
                {
                    src: '1f47e.svg',
                    keywords: ['android'],
                },
                {
                    src: '1f90e.svg',
                    keywords: ['heart'],
                },
                {
                    src: '1f90d.svg',
                    keywords: ['heart'],
                },
                {
                    src: '1f496.svg',
                    keywords: ['heart'],
                },
                {
                    src: '1f499.svg',
                    keywords: ['heart'],
                },
                {
                    src: '1f495.svg',
                    keywords: ['heart'],
                },
                {
                    src: '1f497.svg',
                    keywords: ['heart'],
                },
                {
                    src: '1f525.svg',
                    keywords: ['fire'],
                },
                {
                    src: '2b50.svg',
                    keywords: ['star'],
                },
                {
                    src: '2728.svg',
                    keywords: ['stars'],
                },
                {
                    src: '3030.svg',
                    keywords: ['wave', 'sine'],
                },
            ]
        },
        {
            title: 'flags',
            items: [
                {
                    src: '1f1ea-1f1fa.svg',
                    keywords: ['eu', 'europe', 'gdpr'],
                },
                {
                    src: '1f3c1.svg',
                    keywords: ['finish', 'check'],
                },
                {
                    src: '1f3f3-fe0f-200d-1f308.svg',
                    keywords: ['lgbt'],
                },
                {
                    src: '1f3f4-200d-2620-fe0f.svg',
                    keywords: ['pirate'],
                },
                {
                    src: '1f3f3.svg',
                    keywords: ['white'],
                },
                {
                    src: '1f1fa-1f1e6.svg',
                    keywords: ['ukraine'],
                },
                {
                    src: 'flag-of-india.svg',
                    keywords: ['india'],
                },
            ]
        },
        {
            title: 'math',
            items: [
                {
                    src: '2797.svg',
                    keywords: ['divide'],
                },
                {
                  src: '2716.svg',
                  keywords: ['multiply'],
                },
                {
                  src: '2795.svg',
                  keywords: ['plus'],
                },
                {
                  src: '2796.svg',
                  keywords: ['minus'],
                },
                {
                  src: '30-20e3.svg',
                  keywords: ['0'],
                },
                {
                  src: '31-20e3.svg',
                  keywords: ['1'],
                },
                {
                  src: '32-20e3.svg',
                  keywords: ['2'],
                },
                {
                  src: '33-20e3.svg',
                  keywords: ['3'],
                },
                {
                  src: '34-20e3.svg',
                  keywords: ['4'],
                },
                {
                  src: '35-20e3.svg',
                  keywords: ['5'],
                },
                {
                  src: '36-20e3.svg',
                  keywords: ['6'],
                },
                {
                  src: '37-20e3.svg',
                  keywords: ['7'],
                },
                {
                  src: '38-20e3.svg',
                  keywords: ['8'],
                },
                {
                  src: '39-20e3.svg',
                  keywords: ['9'],
                },
            ]
        },
        {
            title: 'other',
            items: [
                {
                    src: '1f4a0.svg',
                    keywords: ['diamond', '💠', 'flower', '4'],
                },

                {
                    src: '1f4ac.svg',
                    keywords: ['speech', '💬', 'bubble', 'chat'],
                },

                {
                    src: '1f30e.svg',
                    keywords: ['globe', '🌎', 'world'],
                },
            ]
        }

    ]
};
