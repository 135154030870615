import React, {Component} from "react";
import './CustomColorPalette.css'
import {Button, Checkbox, Icon, Input, Popover, Radio} from "antd";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import SpinnerBox from "./SpinnerBox";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {projectUrl} from "../../data/constants";
import {openNotification} from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {websiteColors} from "../../data/websiteColors";
import getContrast from "../../helpers/editor/getContrast";
import {applyCustomColorsClassnames} from "../../helpers/editor/applyCustomColorsClassnames";
import CustomColorsStyles from "../editor/CustomColorsStyles";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { formatHex } from "../../helpers/editor/formatHex";
import { validateHex } from "../../helpers/editor/validateHex";
import _ from "lodash";

interface Props {
    auth: any,
    currentWebsite: any,
    saveWebsiteDataInStore: any,
    websites: any,
    toggleWaitingForResponseOnColorPaletteChange: any,
    isCurrentWebsiteHasCustomPaletteActive: any,
}
interface State {
  beingActivated: boolean,
  currentPrimaryButtonColor: string,
  currentSecondaryButtonColor: string,
  currentAccentColor: string,
  isSaveButtonVisible: boolean,
}

class CustomColorPalette extends Component<Props>{
    state: State = {
        beingActivated: false,
        currentPrimaryButtonColor: '',
        currentSecondaryButtonColor: '',
        currentAccentColor: '',
        isSaveButtonVisible: false,
    };

    componentDidMount(): void {
        if(this.props.currentWebsite && this.props.currentWebsite.custom_color_palette){
            this.setState({
                currentPrimaryButtonColor: this.props.currentWebsite.custom_color_palette.primaryButton,
                currentSecondaryButtonColor: this.props.currentWebsite.custom_color_palette.secondaryButton,
                currentAccentColor: this.props.currentWebsite.custom_color_palette.accent,
            })
        }
    }

    getCurrentPaletteConstantColorsObject = (currentWebsite:any) => {
        //websiteColors is a list of pre designed colors
        let websiteColorsCount = websiteColors.length;
        for(let i = 0; i < websiteColorsCount; i++){
            let currentColorsConstantsObject = websiteColors[i];

            if(currentColorsConstantsObject.styleClassname === currentWebsite.color_classname){
                return currentColorsConstantsObject;
            }
        }
    };

    handleCurrentPrimaryButtonColorInputChange = (e:any) => {
        this.setState({
            currentPrimaryButtonColor: e.target.value,
            isSaveButtonVisible: true
        });
    };
    handleCurrentSecondaryButtonColorInputChange = (e:any) => {
        this.setState({
            currentSecondaryButtonColor: e.target.value,
            isSaveButtonVisible: true
        });
    };
    handleCurrentAccentColorInputChange = (e:any) => {
        this.setState({
            currentAccentColor: e.target.value,
            isSaveButtonVisible: true
        });
    };

    activateCustomPalette = () => {
        let setDefaultCustomPaletteColorsIfNeeded = (newCustomColorPaletteObject:any) => {
            // Check if custom colors were set previously. If not - apply default colors from current color palette and generate additional colors (hovers).

            // If isPrimaryButtonColorBright === undefined this means we did not set the custom colors object yet.
            if(newCustomColorPaletteObject.isPrimaryButtonColorBright !== undefined){
                return newCustomColorPaletteObject;
            }else{
                let currentPaletteColorsObject = this.getCurrentPaletteConstantColorsObject(this.props.currentWebsite);

                let updatedCustomColorPaletteObject = this.generateNewCustomPaletteObject(
                    newCustomColorPaletteObject,
                    currentPaletteColorsObject.primaryButton,
                    currentPaletteColorsObject.secondaryButton,
                    currentPaletteColorsObject.accent);

                this.setState({
                    currentPrimaryButtonColor: currentPaletteColorsObject.primaryButton,
                    currentSecondaryButtonColor: currentPaletteColorsObject.secondaryButton,
                    currentAccentColor: currentPaletteColorsObject.accent,
                });

                return (updatedCustomColorPaletteObject);
            }
        };

        let currentCustomColorPaletteObject = this.props.currentWebsite.custom_color_palette;
        let newCustomColorPaletteObject = {
            ...currentCustomColorPaletteObject,
            isCustomPaletteActivated: true
        };

        newCustomColorPaletteObject = setDefaultCustomPaletteColorsIfNeeded(newCustomColorPaletteObject);

        this.applyNewCustomPaletteConfig(newCustomColorPaletteObject, 'Colors overriding has been enabled.')
    };

    handleSaveButton = (e:any) => {
        let newPrimaryButtonColor = formatHex(this.state.currentPrimaryButtonColor, true);
        let newSecondaryButtonColor = formatHex(this.state.currentSecondaryButtonColor, true);
        let newAccentColor = formatHex(this.state.currentAccentColor, true);

        if (!validateHex(newPrimaryButtonColor)) {
          newPrimaryButtonColor = _.get(this.props.currentWebsite.custom_color_palette, "primaryButton", "")
        }
        if (!validateHex(newSecondaryButtonColor)) {
          newSecondaryButtonColor = _.get(this.props.currentWebsite.custom_color_palette, "secondaryButton", "")
        }
        if (!validateHex(newAccentColor)) {
          newAccentColor = _.get(this.props.currentWebsite.custom_color_palette, "accent", "")
        }
      

        this.setState({
          currentPrimaryButtonColor: newPrimaryButtonColor,
          currentSecondaryButtonColor: newSecondaryButtonColor,
          currentAccentColor: newAccentColor
        })

        let newCustomPalette = this.generateNewCustomPaletteObject(
            this.props.currentWebsite.custom_color_palette,
            newPrimaryButtonColor,
            newSecondaryButtonColor,
            newAccentColor);

        let message = 'Custom colors have been successfully saved.';
        this.applyNewCustomPaletteConfig(newCustomPalette, message, this.setState({isSaveButtonVisible: false}));
    };

    generateNewCustomPaletteObject = (currentCustomColorPaletteObject:any, primaryButton:string, secondaryButton:string, accent:string) => {
        //User input
        // primaryButton: '#4D61FC',
        // secondaryButton: '#F90473',
        // accent: '#4D61FC',
        //
        // //Generated
        // primaryButtonHover: '#344bfc',
        // secondaryButtonHover: '#e00467',

        // Calculated
        // isPrimaryButtonColorBright
        // isSecondaryButtonColorBright
        // isAccentColorBright

        // we use  '(window as any).changeBrightness' to fire the 'changeBrightness' funtion which is placed inside index.html. Why? Because I'm not skilled enough to run the function from within an external JS file because it fails to compile.

        let updatedCustomColorPaletteObject = {
            ...currentCustomColorPaletteObject,

            primaryButton: primaryButton,
            secondaryButton: secondaryButton,
            accent: accent,

            primaryButtonHover: (window as any)['changeBrightness'](-0.2, primaryButton),
            secondaryButtonHover: (window as any)['changeBrightness'](-0.2, secondaryButton),

            // We need to detect contrast to change button text color (if too bright - use black button text)
            isPrimaryButtonColorBright: getContrast(primaryButton),
            isSecondaryButtonColorBright: getContrast(secondaryButton),
            isAccentColorBright: getContrast(accent),
        };

        return updatedCustomColorPaletteObject;
    };

    disableCustomPalette = () => {
        let currentCustomColorPaletteObject = this.props.currentWebsite.custom_color_palette;
        let newCustomColorPaletteObject = {
            ...currentCustomColorPaletteObject,
            isCustomPaletteActivated: false
        };

        this.applyNewCustomPaletteConfig(newCustomColorPaletteObject, 'Custom colors palette has been disabled.')
    };

    handleActivateOverride = (event:any) => {
        let isActive = this.props.isCurrentWebsiteHasCustomPaletteActive;
        if(isActive){
            this.disableCustomPalette();
        }else{
            this.activateCustomPalette();
        }

    };

    applyNewCustomPaletteConfig = (customColorPaletteObject:any, message?:string, callback?:any) => {
        this.props.toggleWaitingForResponseOnColorPaletteChange(true);
        this.setState({beingActivated: true});

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.websites.updateWebsite.prefix + this.props.currentWebsite.subdomain + api.websites.updateWebsite.postfix;
        axios.patch(apiUrl, {custom_color_palette: customColorPaletteObject}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(response => {
                const dataToStore = {
                  data: {
                    ...this.props.currentWebsite,
                    custom_color_palette: _.get(response, "data.custom_color_palette")
                  }
                }
                this.props.saveWebsiteDataInStore(dataToStore, this.props.currentWebsite.subdomain);

                // The server returns the full website object.
                let updatedWebsiteData = response.data;

                // Tell a user that the operation is successful.
                let fullWebsiteUrl = 'https://' + this.props.currentWebsite.subdomain + '.' + projectUrl;

                if(updatedWebsiteData.is_custom_domain_active){
                    fullWebsiteUrl = 'https://' + updatedWebsiteData["custom_domain"];
                }
                let newColorMessage = (<span>Successfully changed the color palette of your website: <a target="_blank" href={fullWebsiteUrl}>{fullWebsiteUrl}</a></span>);
                if(message){
                    newColorMessage = (<span>{message}</span>);
                }

                fireAnalyticsEvent.fireCrisp(CrispEvents.changeColorPalette, {
                  subdomain: response.data.subdomain,
                  new_color_palette: response.data.color_classname,
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changeColorPaletteError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          new_color_palette: customColorPaletteObject,
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.custom_color_palette !== undefined){
                        let message = errorData.subdomain.join(' ');
                        openNotification('Denied', message, 'OK', 'warn');
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.toggleWaitingForResponseOnColorPaletteChange(false);
                this.setState({beingActivated: false});
                if(callback){
                    callback();
                }
            });
    };

    render(){
        let isActive = this.props.isCurrentWebsiteHasCustomPaletteActive;
        let isLoading = this.props.websites.isWaitingForChangeColorPaletteServerResponse;

        let customColorsActiveClassnames = applyCustomColorsClassnames(this.props.currentWebsite);


        // let primaryButtonColor = '';
        // let secondaryButtonColor = '';
        // let accentColor = '';
        //
        // if(this.props.currentWebsite.custom_color_palette){
        //     if(this.props.currentWebsite.custom_color_palette.primaryButton){
        //         primaryButtonColor = this.props.currentWebsite.custom_color_palette.primaryButton;
        //     }
        //     if(this.props.currentWebsite.custom_color_palette.secondaryButton){
        //         secondaryButtonColor = this.props.currentWebsite.custom_color_palette.secondaryButton;
        //     }
        //     if(this.props.currentWebsite.custom_color_palette.accent){
        //         accentColor = this.props.currentWebsite.custom_color_palette.accent;
        //     }
        // }

        return (
            <div
                className={isActive ? "custom-color-palette custom-color-palette--active" : "custom-color-palette custom-color-palette--disabled"}>
                <div className="comps">
                    <div className={"custom-color-palette__card "}>
                        <div className="custom-color-palette__wrapper">
                            <div className={"custom-color-palette__inputs"}>
                                <div className="custom-color-palette__activator">
                                    <Checkbox
                                        disabled={isLoading}
                                        checked={isActive}
                                        onChange={this.handleActivateOverride}>Override colors {isLoading && <Icon type="loading"/>}</Checkbox>
                                </div>
                                <div>
                                    <div className="custom-color-palette__inputs_box">
                                        {/*<div>Set up your brand colors</div>*/}

                                        <div className="custom-color-palette__inputs_row">
                                            <span style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                                                <Popover content={<div style={{width: 300}}>
                                                    The main button color. It will only affect buttons.
                                                    <br/><br/>
                                                    You want your visitors to notice your sweet buttons. So use a high-contrast bright color.
                                                </div>}>
                                                    <Icon type="info-circle" />
                                                </Popover>
                                                <span className="custom-color-palette__inputs_label">Primary button color:</span>
                                            </span>

                                            <Input
                                                className="custom-color-palette__input"
                                                disabled={!isActive || isLoading}
                                                type="text"
                                                size="small"
                                                placeholder="#4D61FC"
                                                value={this.state.currentPrimaryButtonColor}
                                                onChange={this.handleCurrentPrimaryButtonColorInputChange}
                                            />
                                        </div>

                                        <div className="custom-color-palette__inputs_row">
                                            <span style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                                                <Popover content={<div style={{width: 300}}>
                                                    This color is important. It affects many parts of your website: icons, backgrounds, links, small decorations.
                                                    <br/><br/>
                                                    <Icon type="smile" theme="twoTone" /> Our designer recommends you to copy the accent color from "Primary button color" for a better color consistency.
                                                </div>}>
                                                    <Icon type="info-circle" />
                                                </Popover>
                                                <span className="custom-color-palette__inputs_label">Accent color:</span>
                                            </span>

                                            <Input
                                                className="custom-color-palette__input"
                                                disabled={!isActive || isLoading}
                                                type="text"
                                                size="small"
                                                placeholder="#4D61FC"
                                                value={this.state.currentAccentColor}
                                                onChange={this.handleCurrentAccentColorInputChange}
                                            />
                                        </div>

                                        <div className="custom-color-palette__inputs_row">
                                            <span style={{display: "flex", alignItems: "center", marginBottom: "10px"}}>
                                                <Popover content={<div style={{width: 300}}>
                                                    A secondary button color is useful when you need to draw additional attention on your call-to-actions.
                                                    <br/><br/>
                                                    <Icon type="heart" theme="twoTone" /> The default value will do the job so you do not need to pick a secondary color if you don't have one in your brand book.
                                                </div>}>
                                                    <Icon type="info-circle" />
                                                </Popover>
                                                <span className="custom-color-palette__inputs_label">Secondary button color:</span>
                                            </span>

                                            <Input
                                                className="custom-color-palette__input"
                                                disabled={!isActive || isLoading}
                                                type="text"
                                                size="small"
                                                placeholder="#F90473"
                                                value={this.state.currentSecondaryButtonColor}
                                                onChange={this.handleCurrentSecondaryButtonColorInputChange}
                                            />
                                        </div>

                                        {this.state.isSaveButtonVisible && <div className="custom-color-palette__inputs_row">
											<Button
												loading={isLoading}
												onClick={this.handleSaveButton}
												type="primary"
												icon="save">Save colors</Button>
										</div>}

                                    </div>
                                </div>
                            </div>

                            {isActive && <div className={"custom-color-palette__example_box " + customColorsActiveClassnames + ' ' + this.props.currentWebsite.color_classname}>
                                <div className="custom-color-palette__example bg-accent-color">
                                    <div className="custom-color-palette__example_body page-component__bg_overlay_box">
                                        <div className="custom-color-palette__example_info"><div className="custom-color-palette__example_info_text">Preview</div></div>
                                        <div className="custom-color-palette__example_center">
                                            <div className="custom-color-palette__example_buttons">
                                                <div className="custom-color-palette__example_button button button--accent-bg">
                                                    <span className="button__text">Sign up</span>
                                                </div>
                                                <div className="custom-color-palette__example_button button button--alt-accent-outline">
                                                    <span className="button__text">Discover</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>

                    <div className="custom-color-palette__button_box">
                        <div className="custom-color-palette__button">Custom colors</div>
                    </div>
                </div>

                <CustomColorsStyles
                      currentWebsite={this.props.currentWebsite}
                    />

            </div>
        )
    }
}

export default CustomColorPalette;
