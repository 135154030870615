class templatePluginsInitializerClass{

    static subscribeMailchimpForm = () =>{
        try{
            //Initiate the form. It's window-scoped because we want to init this component also after it's dynamically added in the Unicorn Platform builder app.
            (window as any)['unicornplatform']['subscribeMailchimpForm'].init();
        }catch(e){
            console.log(e);
        }
    };
    static subscribeZapierForm = () =>{
        try{
            //Initiate the form. It's window-scoped because we want to init this component also after it's dynamically added in the Unicorn Platform builder app.
            (window as any)['unicornplatform']['subscribeZapierForm'].init();
        }catch(e){
            console.log(e);
        }
    };

    static initTabs = () =>{
        try{
            (window as any)['unicornplatform']['tabs'].init();
        }catch(e){
            console.log(e);
        }
    };
    static setAllTabs = () =>{
        try{
            (window as any)['unicornplatform']['tabs'].setAll();
        }catch(e){
            console.log(e);
        }
    };


    static initLightbox = () =>{
        // try{
        //     (window as any)['unicornplatform']['lightbox'].bind();
        // }catch(e){
        //     console.log(e);
        // }
    };

}

export default templatePluginsInitializerClass;