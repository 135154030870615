import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    currentWebsite: any,
    connectDefaultFormIntegration: any,
    isDarkBg: boolean,
}

class CtaForm01 extends Component<Props>{
    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject className;
            let generator = new generateContentDataObject();
            this.props.saveContentInStore(generator.setUpTitle({isDefaultEmpty,'titleText': 'Join Our Cozy Newsletter'}));
            this.props.saveContentInStore(generator.setUpSubtitleText({isDefaultEmpty,"subtitleText": ""}));
            this.props.saveContentInStore(generator.setUpCta({
                activeOption: callToActionOptions.form,
                formButtonStyleColor: buttonStyles.regular.whiteBg,
                isDefaultEmpty
            }));

            this.props.connectDefaultFormIntegration(this.props.componentItem);
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        return (
            <section>
                <div className="cta_form-01">
                    <div className="container cta_form-01__container">

                        <TitleBox 
                          title={title}
                          subtitleText={subtitleText}
                          isDarkBg={this.props.isDarkBg}
                        />

                        <div className="cta_form-01__form_box">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={this.props.isDarkBg}/>}
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default CtaForm01;
