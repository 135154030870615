import { stringifyObjectsAndNumbers } from "./../stringifyObjectsAndNumbers";
import _ from "lodash";
import { isKeyValue } from "./../../../../helpers/isKeyValue";
export const updateNavLogo = (componentData, completionContent) => {
  const content = componentData.navLogo.content;
  if (!("NAVIGATION_LOGO" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.NAVIGATION_LOGO)) {
    if (completionContent.NAVIGATION_LOGO === null) {
      content.companyTitle.text = "";
    }
    return;
  }
  const itemCompanyTitle =
    stringifyObjectsAndNumbers(
      completionContent.NAVIGATION_LOGO.COMPANY_NAME
    ) || "";

  let itemHeight = completionContent.NAVIGATION_LOGO.LOGO_HEIGHT_PX || 0;
  if (typeof itemHeight !== "number" || isNaN(itemHeight) || itemHeight < 5) {
    itemHeight = 5;
  }
  if (itemHeight > 100) {
    itemHeight = 100;
  }
  if ("COMPANY_NAME" in completionContent.NAVIGATION_LOGO) {
    content.companyTitle.text = itemCompanyTitle;
  }
  if ("LOGO_HEIGHT_PX" in completionContent.NAVIGATION_LOGO) {
    content.logoHeight = itemHeight;
  }
};
