import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  displayMoveUpDownButtons: (
    index: number,
    itemsCount: number,
    moveUp: any,
    moveDown: any
  ) => JSX.Element;
  keyProp: number;
  content: any;
  saveContentInStore: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
}

const GalleryMoveButtons = (props: Props): JSX.Element => {
  const {
    displayMoveUpDownButtons,
    keyProp,
    content,
    saveContentInStore,
    contentType,
    currentInputData,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const generator = new generateContentDataObject();

  return displayMoveUpDownButtons(
    keyProp,
    content.items.length,
    () => {
      saveContentInStore(
        generator.moveGalleryItemUp(
          { [contentType]: currentInputDataRef.current },
          keyProp
        )
      );
    },
    () => {
      saveContentInStore(
        generator.moveGalleryItemDown(
          { [contentType]: currentInputDataRef.current },
          keyProp
        )
      );
    }
  );
};

export default GalleryMoveButtons;
