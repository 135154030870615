import actionsNames from "./actions";
import { PageComponentCategoriesState } from "./types";

let initialState: PageComponentCategoriesState = {
  items: [],
  dataFetched: false,
};

export const pageComponentCategoriesReducer = (
  state: PageComponentCategoriesState = initialState,
  action: any
) => {
  switch (action.type) {
    case actionsNames.SAVE_PAGE_COMPONENT_CATEGORIES_DATA:
      return {
        ...state,
        items: [
          // ...state.items,
          ...action.payload,
        ],
      };

    case actionsNames.PAGE_COMPONENT_CATEGORIES_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };

    default:
      return state;
  }
};
