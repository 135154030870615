import React from "react";
import { callToActionOptions } from "../../data/callToActionOptions";
import { getCurrentWebsite } from "../../helpers/getCurrentWebsite";
import LinkButtons from "./LinkButtons";
import WebsiteForm from "./WebsiteForm";
import { connect } from "react-redux";

interface Props {
  componentItem: any;
  isDarkBg?: boolean;
  isForcedWhiteButtonElements?: boolean;

  websites: any;
  websitesPages: any;
}

const WebsiteCTA = (props: Props): JSX.Element => {
  const {
    componentItem,
    isDarkBg,
    isForcedWhiteButtonElements,
    websites,
  } = props;

  let currentActiveCtaOption = componentItem.componentData.cta.activeOption;

  let bottomInfoText = componentItem.componentData.cta.ctaBottomInfo.markup;
  if (currentActiveCtaOption === callToActionOptions.buttons) {
    let buttons = componentItem.componentData.cta.content.buttons;
    return (
      <LinkButtons
        buttons={buttons}
        isForcedWhiteButtonElements={isForcedWhiteButtonElements}
        bottomInfoMarkup={bottomInfoText}
        isDarkBg={isDarkBg}
      />
    );
  } else if (currentActiveCtaOption === callToActionOptions.form) {
    let currentWebsite = getCurrentWebsite(websites.items);
    return (
      <WebsiteForm
        componentItem={componentItem}
        currentWebsite={currentWebsite}
        isWhiteFormInputs={isDarkBg}
        bottomInfoMarkup={bottomInfoText}
      />
    );
  }
};

const mapStateToProps = ({ websites, websitesPages }) => {
  return {
    websites,
    websitesPages,
  };
};
export default connect(mapStateToProps, null)(WebsiteCTA);
