import React, {Component} from "react";
import generateContentDataObject from '../../helpers/editor/generateContentDataObject'
import {callToActionOptions} from "../../data/callToActionOptions";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import { displayImageUrl } from "../../helpers/content_displayers/displayImageUrl";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import { publicUrl } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Header08 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpGraphics({
                activeOption: GraphicsOptions.image,
                userCanAddItems: false,
                maxItems: 2,

                label: {
                    [GraphicsOptions.image]: "Upload the images:",
                    [GraphicsOptions.video]: "Enter URL to Youtube video:",
                    'default': "Upload the images:",
                },

                fieldTitle: {
                    [GraphicsOptions.image]: "Edit the screenshots",
                    [GraphicsOptions.video]: "Video",
                    'default': "Edit the app screenshots",
                },
                labelTooltip: {
                    [GraphicsOptions.image]: "Recommended formats: 750x1334px for mobile screenshot and 1440x900px for desktop screenshot.",
                    [GraphicsOptions.video]: "Video",
                    'default': "Recommended formats: 750x1334px for mobile screenshot and 1440x900px for desktop screenshot.",
                },
                fieldInfo: {
                    [GraphicsOptions.image]: "Upload images for mobile and desktop apps",
                    [GraphicsOptions.video]: "",
                    default: 'Upload images for mobile and desktop apps'
                },
                items: [
                    this.generator.createGraphicsContentObject({
                        imageUrl: publicUrl + "/img/screenshots/app2.png",
                        uploadButtonInfo: 'mobile version'
                    }),
                    this.generator.createGraphicsContentObject({
                        imageUrl: publicUrl + "/img/screenshots/dashboard-concept.png",
                        uploadButtonInfo: 'desktop version'
                    }),
                ]
            }));
            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: 'Because Your Product Deserves the Best',
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpCta({
                activeOption: callToActionOptions.buttons,
                isDefaultEmpty,

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Get it",
                        buttonHref: '/download-ios',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonMobileAppStoreType: buttonStyles.mobileAppStore.ios,
                        buttonColor: buttonStyles.regular.blackBg,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'Whoa!'
                    }),

                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Learn more",
                        buttonHref: '/download-android',
                        buttonStyleType: buttonStyles.regular.typeTitle,
                        buttonMobileAppStoreType: buttonStyles.mobileAppStore.android,
                        buttonColor: buttonStyles.regular.blackOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'New'
                    }),

                    // ghost object
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        buttonColor: buttonStyles.ghost.accentOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'View'
                    })
                ]

            }));
        }
    }

    render(){
        this.props.connectDefaultFormIntegration(this.props.componentItem);

        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);
        let frontImageUrl = displayImageUrl(this.props.componentItem.componentData.graphics.content.items[0][GraphicsOptions.image]);
        let backImageUrl = displayImageUrl(this.props.componentItem.componentData.graphics.content.items[1][GraphicsOptions.image]);

        return (
            <header className="header-08">
                <div className="container container--large header-08__container">
                    <div className="header-08__left">
                        <div className="header-08__left_content">
                            <div className="header-08__left_content_box">
                                {title.length > 0 && <h1 className={"title-text heading header-08__heading " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: title}}/>}

                                {subtitleText.length > 0 && <p className={"subtitle-text header-08__text content_box "  + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: subtitleText}}/>}
                                <div className="header-08__cta_box">
                                    <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={this.props.isDarkBg} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header-08__right">
                    <div className="header-08__right_wrapper">
                        <div className="header-08__big_mockup">
                            <div className="mockup  mockup--white mockup--macbook mockup--portrait ">
                                <div className="mockup__container">
                                    <div className="mockup__case">
                                        <div className="mockup__spacer"/>
                                        <div className="mockup__screen">
                                            <div className="mockup__screenshot"
                                                 style={{'backgroundImage': 'url(' + backImageUrl + ')'}}
                                            />
                                        </div>
                                    </div>
                                    <img className="mockup__bottom" src={publicUrl + "/img/other/macbook-mockup-shadow.png"}/>
                                </div>
                            </div>
                        </div>
                        <div className="header-08__small_mockup">
                            <div className="mockup  mockup--black mockup--iphone mockup--portrait ">
                                <div className="mockup__case">
                                    <div className="mockup__spacer"/>
                                    <div className="mockup__speaker"/>
                                    <div className="mockup__screen">
                                        <div className="mockup__screenshot"
    style={{'backgroundImage': 'url(' + frontImageUrl + ')'}}
    />
                                    </div>
                                    <div className="mockup__button"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header08;
