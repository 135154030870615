import React, {Component} from "react";
import generateContentDataObject from '../../helpers/editor/generateContentDataObject'
import {callToActionOptions} from "../../data/callToActionOptions";
import {pressLogos} from "../../data/urls";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {pictogramOptions} from "../../data/pictogramOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteButton from "../component_molecules/WebsiteButton";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class CtaButton12 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: 'Our Support Champions Are Happy to Help Ya',
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({
                subtitleText: 'The friendly support crew is hungry for your questions!',
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,

                priority: 200,
                maxItems: 12,

                hasSubtitle: false,
                hasText: true,
                hasPictogram: true,
                hasHighlight: false,
                hasHighlightInfo: false,
                hasButton: true,

                buttonTypes: [buttonStyles.ghost.typeTitle, buttonStyles.regular.typeTitle, buttonStyles.pill.typeTitle, buttonStyles.mobileAppStore.typeTitle],

                items: [
                    this.generator.createFeatureContentObject({
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f44b.svg',
                        }),
                        title: 'Online support',
                        text: 'Ecce, lotus orexis! Burguss messis! Mens peregrinationes, tanquam magnum abactor. Sunt caculaes acquirere emeritis, raptus spatiies.',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "Open chat",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.regular.typeTitle,
                            buttonColor: buttonStyles.regular.accentOutline,
                        })
                    }),
                    this.generator.createFeatureContentObject({
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f4d8.svg',
                        }),
                        title: 'Rich helpdesk',
                        text: 'A falsis, elogium domesticus glos. Clabulares ortum, tanquam raptus vortex. Cum triticum manducare, omnes liberies vitare magnum, camerarius uriaes.',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "View knowledge",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.regular.typeTitle,
                            buttonColor: buttonStyles.regular.accentOutline,
                        })
                    }),
                    this.generator.createFeatureContentObject({
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f36a.svg',
                        }),
                        title: '',
                        text: 'A falsis, elogium domesticus glos. Clabulares ortum, tanquam raptus vortex. Cum triticum manducare, omnes liberies vitare magnum, camerarius uriaes.\n',
                        button: this.generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: "Read in Blog",
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.regular.typeTitle,
                            buttonColor: buttonStyles.regular.accentOutline,
                        })
                    }),
                ]

            }));

        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);
        let features = this.props.componentItem.componentData.features.content.items;

        return (

            <div className="cta_button-12">
                <div className="cta_button-12__content">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--mid">
                        <div className="cta_button-12__wrapper">

                            {features && features.map((item:any, key:number) => {
                                if(item.title && item.title !== ''){
                                    return (

                                        <div className="sh-1 card-container cta_button-12__box" key={'feature-cta-12-' + key}>
                                            <Pictogram 
                                              pictogramObject={item.pictogram}
                                              className='cta_button-12__emoji'
                                            />
                                            <h3 className="title-text--inner def-18_72">{item.title}</h3>
                                            <div className="content-text cta_button-12__text content_box" dangerouslySetInnerHTML={{__html: item.text}}/>
                                            <div className="cta_button-12__button">
                                                {item.button.href !== '' && item.button.title !== '' && <WebsiteButton config={{
                                                    buttonStyleType: item.button.settings.appearance.styleType,
                                                    buttonColor: item.button.settings.appearance.color,
                                                    buttonType: linkOrButtonOptions.link,
                                                    buttonTitle: item.button.title,
                                                    buttonHref: item.button.href,
                                                    buttonIsTargetBlank: item.button.isTargetBlank,
                                                    buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                                    pillPrefix: item.button.pillPrefix,
                                                    pillColor: item.button.settings.appearance.pillColor,
                                                    ghostColor: item.button.settings.appearance.ghostColor,
                                                }} />}
                                            </div>
                                        </div>

                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CtaButton12;
