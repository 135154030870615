import React from "react";

interface Props {
  markup: any;
  isDarkBg: boolean;
}

const BottomCtaInfo = (props: Props): JSX.Element => {
  const { markup, isDarkBg } = props;

  if (markup && markup !== "") {
    return (
      <div
        className={
          "def-12_8 content-text content_box cta_bottom_info " + (isDarkBg ? " text-white " : " ")
        }
        dangerouslySetInnerHTML={{ __html: markup }}
      />
    );
  } else {
    return null;
  }
};

export default BottomCtaInfo;
