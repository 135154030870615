import React, { useEffect, useRef } from "react";
import { Button, Checkbox, Icon, Input, Tooltip } from "antd";
import { InputProps } from "./interfaces";
import DebouncedInput from "../../DebouncedInput";
import DebouncedTextarea from "../../DebouncedTextarea";
import checkIsButtonHrefDisabled from "../../../helpers/editor/checkIsButtonHrefDisabled";
import isNumericOrDashOrPlus from "../../../helpers/editor/isNumericOrDashOrPlus";
import Emoji from "../../../pageComponents/component_molecules/Emoji";
import Pictogram from "../../../pageComponents/component_molecules/Pictogram";
import PricingDetailsIconSet from "../../../pageComponents/component_molecules/PricingDetailsIconSet";
import { PricingPeriodTabPlacement } from "../../../helpers/types/globalTypes";
import { checkIfPricingTabHasCustomText } from "../../../helpers/pricing/checkIfPricingTabHasCustomText";
import { useCurrentInputData } from "./useCurrentInputData";
import { Components } from "../../../enums/Components";
import {
  pricingWithOneItem,
  pricingWithoutIndividualInfo,
} from "../../../data/componentExceptions";

const Pricing = (props: InputProps): JSX.Element => {
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    currentWebsite,
    displayPictogramEditorOpener,
    displayButtonStyleEditorOpener,
    setState,
    state,
    displayButtonPluginsDrawerOpener,
    componentTitle,
    componentType,
  } = props;
  const InputGroup = Input.Group;
  const currentComponent = `${componentType}-${componentTitle}` as Components;
  const isSingleItemPricing = pricingWithOneItem.includes(currentComponent);

  const currentInputDataRef = useCurrentInputData(currentInputData);

  let pricingCount = content.items.length;
  let maxItemsReached =
    currentInputDataRef.current.maxItems === content.items.length;

  let displayDetailsSetInputs = (pricingItem: any, pricingTableKey: number) => {
    let detailsSet = pricingItem.detailsSet;

    let detailsInputsInputs = detailsSet.map(
      (detail: any, detailKey: number) => {
        let onChangePricingDetailTitleHandler = (value: any) => {
          saveContentInStore(
            generator.changePricingDetailTitle(
              { [contentType]: currentInputDataRef.current },
              value,
              detailKey,
              pricingTableKey
            )
          );
          toggleNewEditionsDetected(true);
        };
        let onChangePricingDetailHoverInfo = (value: any) => {
          saveContentInStore(
            generator.changePricingDetailHoverInfo(
              { [contentType]: currentInputDataRef.current },
              value,
              detailKey,
              pricingTableKey
            )
          );
          toggleNewEditionsDetected(true);
        };

        return (
          <div
            className="content-input__cluster content-input__cluster--with-ghost"
            data-add-new="Add a new plan benefit ⤵"
            key={"plan-benefits-fields-" + detailKey}
          >
            <Button
              onClick={(event: any) => {
                toggleNewEditionsDetected(true);
                saveContentInStore(
                  generator.deleteDetail(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    detailKey,
                    pricingTableKey
                  )
                );
              }}
              htmlType="button"
              className="content-input__control_cluster"
              shape="circle"
              title="Delete this benefit"
              icon="cross"
              size="small"
            />
            {displayMoveUpDownButtons(
              detailKey,
              detailsSet.length - 1,
              () => {
                saveContentInStore(
                  generator.moveDetailUp(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    detailKey,
                    pricingTableKey
                  )
                );
              },
              () => {
                saveContentInStore(
                  generator.moveDetailDown(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    detailKey,
                    pricingTableKey
                  )
                );
              }
            )}
            <div>
              <div className="content-input__input">
                <InputGroup compact>
                  {currentInputDataRef.current.hasEditableIcon && (
                    <Button
                      className="content-input__input"
                      htmlType="button"
                      style={{ width: "15%" }}
                      onClick={(event: any) => {
                        saveContentInStore(
                          generator.changePricingDetailIcon(
                            {
                              [contentType]: currentInputDataRef.current,
                            },
                            detail.icon,
                            detailKey,
                            pricingTableKey
                          )
                        );
                        toggleNewEditionsDetected(true);
                      }}
                    >
                      <PricingDetailsIconSet
                        detailObj={detail}
                        iconClassname="content-input__input__detail_icon"
                      />
                    </Button>
                  )}
                  <DebouncedInput
                    onChange={onChangePricingDetailTitleHandler}
                    style={{
                      width: currentInputDataRef.current.hasEditableIcon
                        ? "85%"
                        : "100%",
                    }}
                    defaultValue={detail.title}
                    placeholder="Enter benefit title"
                    className="content-input__input"
                  />
                </InputGroup>
                {detail.title &&
                  detail.title !== "" &&
                  detail.hoverInfo === "" && (
                    <a
                      style={{ fontSize: "12px" }}
                      href="#"
                      onClick={(event: any) => {
                        event.preventDefault();
                        saveContentInStore(
                          generator.changePricingDetailHoverInfo(
                            {
                              [contentType]: currentInputDataRef.current,
                            },
                            "Further details on " + detail.title,
                            detailKey,
                            pricingTableKey
                          )
                        );
                        toggleNewEditionsDetected(true);
                      }}
                    >
                      Add more info
                    </a>
                  )}
              </div>
              {detail.title &&
                detail.title !== "" &&
                detail.hoverInfo !== "" && (
                  <DebouncedTextarea
                    className="content-input__input"
                    onChange={onChangePricingDetailHoverInfo}
                    defaultValue={detail.hoverInfo}
                    autosize={{ minRows: 1, maxRows: 6 }}
                    placeholder="More info when cursor over"
                  />
                )}
            </div>
          </div>
        );
      }
    );

    return detailsInputsInputs;
  };

  let pricingInputs = content.items.map((item: any, key: number) => {
    if (isSingleItemPricing && key > 0) {
      return null;
    }
    let doesButtonHavePluginActionInsteadOfHref = checkIsButtonHrefDisabled(
      item.button,
      currentWebsite
    );
    let doesYearlyButtonHavePluginActionInsteadOfHref =
      checkIsButtonHrefDisabled(item.yearlyButton, currentWebsite);
    let isEnteredMonthlyAmountNumeric = isNumericOrDashOrPlus(
      item.monthlyPrice
    ); // we hide the currency symbol and period for non-numeric values (e.g. "contact us")

    let onChangePricingCurrencySymbolHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingCurrencySymbol(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingMonthlyPriceHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingMonthlyPrice(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingMonthlyPeriodHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingMonthlyPeriod(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingYearlyPriceHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingYearlyPrice(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingYearlyPeriodHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingYearlyPeriod(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingTitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingPrimaryInfoHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingPrimaryInfo(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingSecondaryInfoHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingSecondaryInfo(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingSecondaryInfoYearlyHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingSecondaryInfoYearly(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingButtonTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingButtonTitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingButtonHrefHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingButtonHref(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangePricingYearlyButtonHrefHandler = (value: any) => {
      saveContentInStore(
        generator.changePricingYearlyButtonHref(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };

    return (
      <div
        className={
          "content-input__cluster " +
          (item.isHighlighted ? " content-input__cluster--accent " : "")
        }
        key={"pricing-" + key}
      >
        {!isSingleItemPricing && (
          <Button
            onClick={(event: any) => {
              toggleNewEditionsDetected(true);
              saveContentInStore(
                generator.deletePricing(
                  { [contentType]: currentInputDataRef.current },
                  key
                )
              );
            }}
            htmlType="button"
            className="content-input__control_cluster"
            shape="circle"
            title="Delete this pricing plan"
            icon="cross"
            size="small"
          />
        )}

        {!isSingleItemPricing &&
          displayMoveUpDownButtons(
            key,
            pricingCount,
            () => {
              saveContentInStore(
                generator.movePricingUp(
                  { [contentType]: currentInputDataRef.current },
                  key
                )
              );
            },
            () => {
              saveContentInStore(
                generator.movePricingDown(
                  { [contentType]: currentInputDataRef.current },
                  key
                )
              );
            }
          )}

        <div className="content-input__group">
          {currentInputDataRef.current.hasHighlight &&
            item.monthlyPrice !== "" && (
              <Checkbox
                className="content-input__input"
                checked={item.isHighlighted}
                onChange={(event: any) => {
                  saveContentInStore(
                    generator.changePricingIsHighlighted(
                      {
                        [contentType]: currentInputDataRef.current,
                      },
                      event.target.checked,
                      key
                    )
                  );
                  toggleNewEditionsDetected(true);
                }}
              >
                Highlighted pricing plan
              </Checkbox>
            )}
          <div className="content-input__input">
            <InputGroup compact>
              <DebouncedInput
                disabled={!isEnteredMonthlyAmountNumeric}
                placeholder="$"
                style={{ width: "20%" }}
                defaultValue={item.currencySymbol}
                onChange={onChangePricingCurrencySymbolHandler}
              />

              {/*This input (monthlyPrice) is responsible for adding new items:*/}
              <DebouncedInput
                placeholder="19"
                style={{ width: "20%" }}
                defaultValue={item.monthlyPrice}
                onChange={onChangePricingMonthlyPriceHandler}
              />
              <DebouncedInput
                disabled={!isEnteredMonthlyAmountNumeric}
                placeholder="/month"
                style={{ width: "60%" }}
                defaultValue={item.monthlyPeriod}
                onChange={onChangePricingMonthlyPeriodHandler}
              />
            </InputGroup>

            {currentInputDataRef.current.hasYearlyPrice &&
              item.monthlyPrice !== "" &&
              item.yearlyPrice === "" && (
                <a
                  style={{
                    fontSize: "12px",
                    float: "right",
                    marginTop: "7px",
                  }}
                  href="#"
                  onClick={(event: any) => {
                    event.preventDefault();
                    saveContentInStore(
                      generator.changePricingYearlyPrice(
                        {
                          [contentType]: currentInputDataRef.current,
                        },
                        key,
                        0
                      )
                    ); //we set the changePricingYearlyPrice to zero (0) so the changePricingYearlyPrice field appears; this button is needed only to active the yearly price option.
                    toggleNewEditionsDetected(true);
                  }}
                >
                  <Icon type="plus" /> add yearly price
                </a>
              )}
          </div>

          {currentInputDataRef.current.hasYearlyPrice &&
            item.monthlyPrice !== "" &&
            item.yearlyPrice !== "" && (
              <div className="content-input__input content-input__input--removable">
                <InputGroup compact>
                  <DebouncedInput
                    disabled={!isEnteredMonthlyAmountNumeric}
                    placeholder="$"
                    style={{ width: "20%" }}
                    defaultValue={item.currencySymbol}
                    onChange={onChangePricingCurrencySymbolHandler}
                  />
                  <DebouncedInput
                    placeholder="190"
                    style={{ width: "20%" }}
                    defaultValue={item.yearlyPrice}
                    onChange={onChangePricingYearlyPriceHandler}
                  />
                  <DebouncedInput
                    disabled={!isEnteredMonthlyAmountNumeric}
                    placeholder="/year"
                    style={{ width: "60%" }}
                    defaultValue={item.yearlyPeriod}
                    onChange={onChangePricingYearlyPeriodHandler}
                  />
                </InputGroup>
                <Button
                  title="Remove the yearly price option from this plan."
                  icon="cross"
                  shape="circle"
                  className="content-input__remove_input_button"
                  size="small"
                  onClick={() => {
                    toggleNewEditionsDetected(true);
                    onChangePricingYearlyPriceHandler("");
                  }}
                ></Button>
              </div>
            )}
        </div>

        {item.monthlyPrice !== "" && (
          <div className="content-input__group">
            {currentInputDataRef.current.hasPictogram &&
              item.monthlyPrice !== "" &&
              displayPictogramEditorOpener(item.pictogram, key)}
            {currentInputDataRef.current.hasTitle && (
              <DebouncedInput
                className="content-input__input"
                onChange={onChangePricingTitleHandler}
                defaultValue={item.title}
                placeholder="Enter plan title"
              />
            )}

            {currentInputDataRef.current.hasPrimaryInfo && (
              <DebouncedTextarea
                className="content-input__input"
                onChange={onChangePricingPrimaryInfoHandler}
                defaultValue={item.primaryInfo}
                autosize={{ minRows: 1, maxRows: 6 }}
                placeholder="Enter the pricing plan primary info"
              />
            )}

            {currentInputDataRef.current.hasSecondaryInfo && (
              <DebouncedTextarea
                className="content-input__input"
                onChange={onChangePricingSecondaryInfoHandler}
                defaultValue={item.secondaryInfo}
                autosize={{ minRows: 1, maxRows: 6 }}
                placeholder="Enter the pricing plan secondary info"
              />
            )}
            {item.hasIndividualInfo &&
              currentInputDataRef.current.hasYearlyPrice &&
              item.monthlyPrice !== "" &&
              item.yearlyPrice !== "" &&
              !pricingWithoutIndividualInfo.includes(currentComponent) && (
                <div className="content-input__input content-input__input--removable">
                  <DebouncedTextarea
                    className="content-input__input"
                    onChange={onChangePricingSecondaryInfoYearlyHandler}
                    defaultValue={item.secondaryInfoYearly}
                    autosize={{ minRows: 1, maxRows: 6 }}
                    placeholder="Enter individual info for yearly plan"
                  />
                  <Button
                    title="Remove individual yearly plan info."
                    icon="cross"
                    shape="circle"
                    className="content-input__remove_input_button"
                    size="small"
                    onClick={() => {
                      toggleNewEditionsDetected(true);
                      saveContentInStore(
                        generator.changePricingHasIndividualInfo(
                          {
                            [contentType]: currentInputDataRef.current,
                          },
                          false,
                          key
                        )
                      );
                    }}
                  ></Button>
                </div>
              )}
            {currentInputDataRef.current.hasYearlyPrice &&
              item.monthlyPrice !== "" &&
              item.yearlyPrice !== "" &&
              currentInputDataRef.current.hasSecondaryInfo &&
              !item.hasIndividualInfo && (
                <a
                  style={{
                    fontSize: "12px",
                    marginTop: "-4px",
                    marginBottom: "11px",
                    float: "right",
                  }}
                  href="#"
                  onClick={(event: any) => {
                    event.preventDefault();
                    saveContentInStore(
                      generator.changePricingHasIndividualInfo(
                        {
                          [contentType]: currentInputDataRef.current,
                        },
                        true,
                        key
                      )
                    );
                    toggleNewEditionsDetected(true);
                  }}
                >
                  <Icon type="plus" /> add individual info for yearly price
                </a>
              )}
          </div>
        )}

        {item.monthlyPrice !== "" && (
          <div className="content-input__group">
            {/* if href is not empty OR if there is a special action enabled (doesButtonHavePluginActionInsteadOfHref)*/}
            {item.button.title !== "" &&
              displayButtonStyleEditorOpener(item.button, () => {
                setState({
                  ...state,
                  currentPricingIndex: key, //we need to know the index of a pricing plan to edit it's button
                  currentButtonObject: item.button,
                  buttonStylesOptions: currentInputDataRef.current.buttonTypes,
                });
              })}
            {
              <DebouncedInput
                className="content-input__input"
                onChange={onChangePricingButtonTitleHandler}
                defaultValue={item.button.title}
                placeholder="Get it"
              />
            }
            {
              <div className="content-input__input">
                <DebouncedInput
                  className=""
                  onChange={onChangePricingButtonHrefHandler}
                  disabled={doesButtonHavePluginActionInsteadOfHref}
                  addonAfter={
                    // Adds plugins (payment, UTM relay etc) to a button.
                    displayButtonPluginsDrawerOpener(item, key)
                  }
                  defaultValue={item.button.href}
                  placeholder="/sign-up"
                />
              </div>
            }
            {item.isMonthlyButtonSameAsYearly === false &&
              currentInputDataRef.current.hasYearlyPrice &&
              item.monthlyPrice !== "" &&
              item.yearlyPrice !== "" && (
                <div className="content-input__input content-input__input--removable">
                  <DebouncedInput
                    className=""
                    onChange={onChangePricingYearlyButtonHrefHandler}
                    disabled={doesYearlyButtonHavePluginActionInsteadOfHref}
                    addonAfter={
                      // Adds plugins (payment, UTM relay etc) to a button.
                      displayButtonPluginsDrawerOpener(item, key, 0, true) //3rd argument is a random number. it is ignored in this component (pricing). We needed to enter it to be able to reach the 4th argument (which is true here). Read more in the displayButtonPluginsDrawerOpener() function
                    }
                    defaultValue={
                      item.yearlyButton ? item.yearlyButton.href : ""
                    }
                    placeholder="/sign-up#yearly"
                  />
                  <Button
                    title="Remove individual yearly action and keep a single button for both monthly and yearly tabs."
                    icon="cross"
                    shape="circle"
                    className="content-input__remove_input_button"
                    size="small"
                    onClick={() => {
                      toggleNewEditionsDetected(true);
                      saveContentInStore(
                        generator.changePricingIsMonthlyButtonSameAsYearly(
                          {
                            [contentType]: currentInputDataRef.current,
                          },
                          true,
                          key
                        )
                      );
                    }}
                  ></Button>
                </div>
              )}
            {
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                {/*If button acts not as link (doesButtonHavePluginActionInsteadOfHref) there is no 'open in a new tab' tick.*/}
                {doesButtonHavePluginActionInsteadOfHref === false &&
                  item.button.href !== "" &&
                  item.button.title !== "" && (
                    <Checkbox
                      className="content-input__input"
                      checked={item.button.isTargetBlank}
                      onChange={(event: any) => {
                        saveContentInStore(
                          generator.changePricingButtonIsTargetBlank(
                            {
                              [contentType]: currentInputDataRef.current,
                            },
                            key,
                            event.target.checked
                          )
                        );
                        toggleNewEditionsDetected(true);
                      }}
                    >
                      Open in a new tab
                    </Checkbox>
                  )}
                {item.button.title !== "" &&
                  currentInputDataRef.current.hasYearlyPrice &&
                  item.monthlyPrice !== "" &&
                  item.yearlyPrice !== "" &&
                  (item.isMonthlyButtonSameAsYearly === true ||
                    item.isMonthlyButtonSameAsYearly === undefined) && (
                    <a
                      style={{
                        fontSize: "12px",
                        marginTop: "2px",
                        marginBottom: "11px",
                      }}
                      href="#"
                      onClick={(event: any) => {
                        event.preventDefault();
                        saveContentInStore(
                          generator.changePricingIsMonthlyButtonSameAsYearly(
                            {
                              [contentType]: currentInputDataRef.current,
                            },
                            false,
                            key
                          )
                        );
                        toggleNewEditionsDetected(true);
                      }}
                    >
                      <Icon type="plus" /> add individual action for yearly
                      price
                    </a>
                  )}
              </div>
            }

            {item.monthlyPrice !== "" &&
              currentInputDataRef.current.hasDetailsSet && (
                <div className="content-input__group">
                  {displayDetailsSetInputs(item, key)}
                </div>
              )}
          </div>
        )}
      </div>
    );
  });

  let checkIfPricingPlanNotGhost = (pricingObject: any) => {
    return (
      pricingObject.monthlyPrice !== undefined &&
      pricingObject.monthlyPrice !== ""
    ); //Empty string ('') when no price is entered by a user.
  };
  let getPricingPlansCount = (pricing: any) => {
    let count = pricing.content.items.length;

    if (
      pricing.maxItems === count &&
      checkIfPricingPlanNotGhost(pricing.content.items[pricing.maxItems - 1])
    ) {
      // if the limit is reached, but
      return count;
    } else {
      /*-1 because the last ghost one is not visible*/
      return pricing.content.items.length - 1;
    }
  };

  let displayComparisionTableInputs = () => {
    let comparisionTableHeadersSet = content.comparisionTableHeadersSet;

    let detailsInputsInputs = comparisionTableHeadersSet.map(
      (header: any, headerKey: number) => {
        let pricingPlanDetailsInputs = content.items.map(
          (pricingPlan: any, pricingPlanKey: number) => {
            let comparisionTableDetail =
              pricingPlan.comparisionTableDetailsSet[headerKey];
            if (checkIfPricingPlanNotGhost(pricingPlan)) {
              let onChangePlansComparisionTableDetailTitleHandler = (
                value: any
              ) => {
                saveContentInStore(
                  generator.changePlansComparisionTableDetailTitle(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    value,
                    headerKey,
                    pricingPlanKey
                  )
                );
                toggleNewEditionsDetected(true);
              };

              return (
                <InputGroup
                  key={"pricing-plan-row-item-" + pricingPlanKey}
                  compact
                  style={{
                    width: "200px",
                    display: "flex",
                    marginRight: 10,
                  }}
                >
                  <Button
                    className="content-input__input"
                    htmlType="button"
                    style={{ width: "30%" }}
                    onClick={(event: any) => {
                      saveContentInStore(
                        generator.changePlansComparisionTableDetailIcon(
                          {
                            [contentType]: currentInputDataRef.current,
                          },
                          comparisionTableDetail.icon,
                          headerKey,
                          pricingPlanKey
                        )
                      );
                      toggleNewEditionsDetected(true);
                    }}
                  >
                    <PricingDetailsIconSet
                      detailObj={comparisionTableDetail}
                      iconClassname="content-input__input__detail_icon"
                    />
                  </Button>
                  <div
                    className="content-input__input"
                    style={{ width: "70%" }}
                  >
                    <DebouncedInput
                      onChange={onChangePlansComparisionTableDetailTitleHandler}
                      defaultValue={comparisionTableDetail.title}
                      placeholder="Enter benefit"
                      className=""
                    />
                  </div>
                </InputGroup>
              );
            } else {
              return null;
            }
          }
        );

        let onChangePricingComparisionTableRowsHeaderTitleHandler = (
          value: any
        ) => {
          saveContentInStore(
            generator.changePricingComparisionTableRowsHeaderTitle(
              { [contentType]: currentInputDataRef.current },
              headerKey,
              value
            )
          );
          toggleNewEditionsDetected(true);
        };
        let onChangePricingComparisionTableRowsHeaderHoverInfoHandler = (
          value: any
        ) => {
          saveContentInStore(
            generator.changePricingComparisionTableRowsHeaderHoverInfo(
              { [contentType]: currentInputDataRef.current },
              headerKey,
              value
            )
          );
          toggleNewEditionsDetected(true);
        };

        return (
          <div
            className="content-input__cluster"
            key={"plan-benefits-fields-" + headerKey}
          >
            <Button
              onClick={(event: any) => {
                toggleNewEditionsDetected(true);
                saveContentInStore(
                  generator.deletePricingComparisionTableRowsHeader(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    headerKey
                  )
                );
              }}
              htmlType="button"
              className="content-input__control_cluster content-input__control_cluster--left"
              shape="circle"
              title="Delete this row"
              icon="cross"
              size="small"
            />
            {headerKey !== 0 && (
              <Button
                onClick={() => {
                  toggleNewEditionsDetected(true);
                  saveContentInStore(
                    generator.movePricingRowUp(
                      {
                        [contentType]: currentInputDataRef.current,
                      },
                      headerKey
                    )
                  );
                }}
                htmlType="button"
                className="content-input__control_cluster content-input__control_cluster--move-up content-input__control_cluster--top content-input__move_place-left"
                shape="circle"
                title="Move this row up"
                icon="up"
                size="small"
              />
            )}
            {headerKey !== comparisionTableHeadersSet.length - 1 && (
              <Button
                onClick={() => {
                  toggleNewEditionsDetected(true);
                  saveContentInStore(
                    generator.movePricingRowDown(
                      {
                        [contentType]: currentInputDataRef.current,
                      },
                      headerKey
                    )
                  );
                }}
                htmlType="button"
                className="content-input__control_cluster content-input__control_cluster--move-down content-input__control_cluster--bottom content-input__move_place-left"
                shape="circle"
                title="Move this row down"
                icon="down"
                size="small"
              />
            )}
            <div>
              <div className="content-input__input" style={{ display: "flex" }}>
                <div style={{ width: "150px", marginRight: 20 }}>
                  {/*Row header input*/}
                  <DebouncedInput
                    onChange={
                      onChangePricingComparisionTableRowsHeaderTitleHandler
                    }
                    defaultValue={header.title}
                    placeholder="Enter row title"
                    className="content-input__input"
                  />

                  {header.title &&
                    header.title !== "" &&
                    header.hoverInfo === "" && (
                      <a
                        style={{ fontSize: "12px" }}
                        href="#"
                        onClick={(event: any) => {
                          event.preventDefault();
                          saveContentInStore(
                            generator.changePricingComparisionTableRowsHeaderHoverInfo(
                              {
                                [contentType]: currentInputDataRef.current,
                              },
                              headerKey,
                              "Further details on " + header.title
                            )
                          );
                          toggleNewEditionsDetected(true);
                        }}
                      >
                        Add more info
                      </a>
                    )}

                  {header.title &&
                    header.title !== "" &&
                    header.hoverInfo !== "" && (
                      <DebouncedTextarea
                        className="content-input__input"
                        onChange={
                          onChangePricingComparisionTableRowsHeaderHoverInfoHandler
                        }
                        defaultValue={header.hoverInfo}
                        autosize={{ minRows: 1, maxRows: 6 }}
                        placeholder="More info when cursor over"
                      />
                    )}
                </div>

                {/*Iterate through all pricing plans and add a set of inputs per each plan*/}
                {pricingPlanDetailsInputs}
              </div>
            </div>
          </div>
        );
      }
    );

    let pricingPlanInfo = content.items.map(
      (pricingPlan: any, pricingPlanKey: number) => {
        // we want to display pricing plan info on top of each column in the comparision table
        if (checkIfPricingPlanNotGhost(pricingPlan)) {
          let isEnteredMonthlyAmountNumeric = isNumericOrDashOrPlus(
            pricingPlan.monthlyPrice
          ); // we hide the currency symbol and period for non-numeric values (e.g. "contact us")
          return (
            <div
              key={"pricing-plan-info-" + pricingPlanKey}
              style={{ width: 188, textAlign: "center" }}
            >
              {/*must be 200px + 10px but in reality flex gives this amount - see inspector on .ant-input-group.ant-input-group-compact*/}
              <div>
                {currentInputDataRef.current.hasPictogram && (
                  <Pictogram
                    pictogramObject={pricingPlan.pictogram}
                    className="content-input__emoji_image"
                  />
                )}
              </div>
              <span>
                {pricingPlan.title}
                <span style={{ opacity: 0.4 }}>
                  {" "}
                  {isEnteredMonthlyAmountNumeric && pricingPlan.currencySymbol}
                  {pricingPlan.monthlyPrice}
                </span>
              </span>
            </div>
          );
        } else {
          return null;
        }
      }
    );

    return (
      <div>
        <div className="content-input__label_box">
          <label htmlFor="" className="content-input__label">
            Edit the features comparision table:
          </label>
        </div>
        <div className="content-input__cluster" style={{ overflow: "auto" }}>
          <div
            style={{
              width:
                getPricingPlansCount(currentInputDataRef.current) * 200 + 170,
              position: "relative",
              paddingRight: "30px",
            }}
          >
            {content.comparisionTableHeadersSet.length > 0 && (
              <div
                style={{
                  paddingLeft: 170,
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: 20,
                }}
              >
                {pricingPlanInfo}
              </div>
            )}
            {detailsInputsInputs}
            <Button
              size="small"
              icon="vertical-align-bottom"
              onClick={() => {
                toggleNewEditionsDetected(true);
                saveContentInStore(
                  generator.addEmptyPricingComparisionTableRowsHeader({
                    [contentType]: currentInputDataRef.current,
                  })
                );
              }}
            >
              Add a new features row
            </Button>
          </div>
        </div>
      </div>
    );
  };

  let onChangePricingYearlyBonusTextHandler = (value: any) => {
    saveContentInStore(
      generator.changePricingYearlyBonusText(
        { [contentType]: currentInputDataRef.current },
        value
      )
    );
    toggleNewEditionsDetected(true);
  };

  const handlePricingPeriodTabChange = (
    value: string,
    tab: PricingPeriodTabPlacement
  ) => {
    saveContentInStore(
      generator.changePricingTabText(
        { [contentType]: currentInputDataRef.current },
        value,
        tab
      )
    );
    toggleNewEditionsDetected(true);
  };

  return (
    <div>
      <div className="content-input__input">
        {/*hide addonBefore if no yearly bonus info entered.*/}
        {currentInputDataRef.current.hasYearlyPrice &&
          content.items[0] &&
          content.items[0].yearlyPrice !== "" && (
            <div className="content-input__cluster content-input__cluster--single">
              <InputGroup compact className="content-input__period-group">
                <DebouncedInput
                  defaultValue={
                    checkIfPricingTabHasCustomText(
                      currentInputDataRef.current,
                      "left"
                    )
                      ? currentInputDataRef.current.leftTabText
                      : "Monthly"
                  }
                  placeholder="Monthly"
                  className="content-input__period-input"
                  onChange={(value: string) =>
                    handlePricingPeriodTabChange(value, "left")
                  }
                />
                <DebouncedInput
                  defaultValue={
                    checkIfPricingTabHasCustomText(
                      currentInputDataRef.current,
                      "right"
                    )
                      ? currentInputDataRef.current.rightTabText
                      : "Yearly"
                  }
                  placeholder="Yearly"
                  className="content-input__period-input"
                  onChange={(value: string) =>
                    handlePricingPeriodTabChange(value, "right")
                  }
                />
              </InputGroup>
              <DebouncedInput
                className=""
                addonBefore={
                  currentInputDataRef.current.yearlyBonusText !== "" && (
                    <div style={{ paddingLeft: "10px", maxWidth: "150px" }}>
                      <span className="pointed-badge   pointed-badge--acid-bg pointed-badge--left">
                        <span className="pointed-badge__text pointed-badge__text--input">
                          {currentInputDataRef.current.yearlyBonusText}
                        </span>
                        <Emoji
                          emojiSrc="1f381.svg"
                          className="emoji"
                          alt="Emoji Gift"
                        />
                      </span>
                    </div>
                  )
                }
                addonAfter={
                  <Tooltip
                    title={
                      "Make the yearly prices active (visible) by default."
                    }
                  >
                    <Checkbox
                      className=""
                      checked={
                        currentInputDataRef.current.isYearlyActiveByDefault
                      }
                      onChange={(event: any) => {
                        saveContentInStore(
                          generator.changePricingIsYearlyActiveByDefault(
                            {
                              [contentType]: currentInputDataRef.current,
                            },
                            event.target.checked
                          )
                        );
                        toggleNewEditionsDetected(true);
                      }}
                    >
                      Default
                    </Checkbox>
                  </Tooltip>
                }
                onChange={onChangePricingYearlyBonusTextHandler}
                defaultValue={currentInputDataRef.current.yearlyBonusText}
                placeholder="Enter yearly bonus info"
              />
            </div>
          )}
      </div>
      <div>
        {pricingInputs}
        {maxItemsReached === false && (
          <div className="content-input__group">
            <Button
              size="small"
              style={{ marginBottom: 40 }}
              onClick={(event: any) => {
                toggleNewEditionsDetected(true);
                saveContentInStore(
                  generator.addEmptyPricingIfNeeded({
                    [contentType]: currentInputDataRef.current,
                  })
                );
              }}
              icon="plus"
            >
              Add pricing plan
            </Button>
          </div>
        )}
      </div>

      {currentInputDataRef.current.hasComparisionTableRows &&
        displayComparisionTableInputs()}
    </div>
  );
};

export default Pricing;
