import {popularLanguagesWithEmoji} from "../../data/popularLanguagesWithEmoji";
import capitalizeString from "../strings/capitalizeString";

export const getLanguageTitleAndEmojiFromCode = (code:string) => {
    let languageTitle = ''

    for (let i = 0; i < popularLanguagesWithEmoji.length; i++) {
        if(popularLanguagesWithEmoji[i]['code'] === code){
            languageTitle = capitalizeString(popularLanguagesWithEmoji[i]['english']) + ' ' + popularLanguagesWithEmoji[i]['flag'];
        }
    }

    return languageTitle;
};
