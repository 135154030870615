import _ from "lodash";
import { UnicornComponent } from "../../../../classes/UnicornComponent";
import { Components } from "../../../../enums/Components";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { checkIfEmptyValue } from "./updaterFunctions.ts/checkIfEmptyValue";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getArrayOfStrings } from "./updaterFunctions.ts/getArrayOfStrings";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";
import { DEFAULT_IMAGES_CDN } from "../../../../data/urls";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemName = stringifyObjectsAndNumbers(modifiedItem.FULL_NAME) || "";
  const itemHref = stringifyObjectsAndNumbers(modifiedItem.LINK) || "";
  const itemUsername = stringifyObjectsAndNumbers(modifiedItem.LINK_TEXT) || "";
  const itemJobTitle = stringifyObjectsAndNumbers(modifiedItem.ROLE) || "";
  const itemInfo = stringifyObjectsAndNumbers(modifiedItem.INFO) || "";
  const itemSocialNetworksStrings = getArrayOfStrings(
    modifiedItem.SOCIAL_NETWORKS
  );
  const itemSocialNetworks = itemSocialNetworksStrings.map((option) => {
    return {
      href: option,
    };
  });
  return {
    itemId,
    itemPosition,
    itemName,
    itemHref,
    itemUsername,
    itemJobTitle,
    itemInfo,
    itemSocialNetworks,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemName,
  itemHref,
  itemUsername,
  itemJobTitle,
  itemInfo,
  itemSocialNetworks
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.team.content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.team.content.items, item);
      }
    });
  }
  if ("FULL_NAME" in modifiedItem) {
    originalItem.name = itemName;
  }
  if ("LINK" in modifiedItem) {
    originalItem.href = itemHref;
  }
  if ("LINK_TEXT" in modifiedItem) {
    originalItem.username = itemUsername;
  }
  if ("ROLE" in modifiedItem) {
    originalItem.jobTitle = itemJobTitle;
  }
  if ("INFO" in modifiedItem) {
    originalItem.info = itemInfo;
  }
  if ("SOCIAL_NETWORKS" in modifiedItem) {
    originalItem.socialNetworks = itemSocialNetworks;
  }
};

export const updateTeam = (
  componentData,
  completionContent,
  currentComponentInfo: UnicornComponent
) => {
  if (!("TEAM_MEMBERS" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.TEAM_MEMBERS)) {
    if (completionContent.TEAM_MEMBERS === null) {
      componentData.team.content.items = [];
    }
    return;
  }
  if (currentComponentInfo.name == Components.team01) {
    componentData.team.content.items = componentData.team.content.items.filter(
      (item) => {
        const isLookupFieldEmpty = checkIfEmptyValue(item.graphics.image.url);
        return !isLookupFieldEmpty || item.IS_BEING_MODIFIED_BY_AI;
      }
    );
  }
  if (currentComponentInfo.name == Components.team03) {
    componentData.team.content.items = componentData.team.content.items.filter(
      (item) => {
        const isLookupFieldEmpty =
          checkIfEmptyValue(item.name) && checkIfEmptyValue(item.username);
        return !isLookupFieldEmpty || item.IS_BEING_MODIFIED_BY_AI;
      }
    );
  }
  addDefaultValuesToItems(componentData.team.content.items);
  completionContent.TEAM_MEMBERS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemName,
      itemHref,
      itemUsername,
      itemJobTitle,
      itemInfo,
      itemSocialNetworks,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      componentData.team.content.items = deleteItem(
        componentData.team.content.items,
        itemId
      );
      return;
    }
    const isItemNew = getIsItemNew(componentData.team.content.items, itemId);
    if (isItemNew) {
      const newItem = generator.createTeamMemberContentObject();
      newItem.name = "";
      newItem.jobTitle = "";
      newItem.href = "";
      newItem.username = "";
      newItem.info = "";
      newItem.socialNetworks = [];
      newItem.graphics.image.url = DEFAULT_IMAGES_CDN.UNICORN_AI;
      newItem.graphics.image.width = 512;
      newItem.graphics.image.height = 512;
      addNewItem(
        componentData.team.content.items,
        newItem,
        itemId,
        itemPosition
      );
    }
    const originalItem = getOriginalItem(
      componentData.team.content.items,
      itemId
    );
    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemName,
      itemHref,
      itemUsername,
      itemJobTitle,
      itemInfo,
      itemSocialNetworks
    );
  });
  sortItemsByPosition(componentData.team.content.items);
};
