import React from "react";
import { Button, Checkbox, Popconfirm, Tooltip } from "antd";
import { InputProps } from "./interfaces";
import DebouncedInput from "../../DebouncedInput";
import { urls } from "../../../data/urls";
import { useCurrentInputData } from "./useCurrentInputData";

const NavLinks = (props: InputProps): JSX.Element => {
  // nav items can be either regular links or dropdown holders (submenu)
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  let allTopLevelNavLinksItems = content.topLevelItems; //[] - array of links
  let allDropdownNavLinksItems =
    content.dropdownItems; /*
                    {
                        "3": [{},{},],
                        "2": [{},{},]
                    } - map of arrays where "3" and "2" are unique indexes of links
                */

  let allTopLevelLinksInputs = allTopLevelNavLinksItems.map(
    (topLevelLinkItem: any, topLevelLinkKey: number) => {
      let currentTopLevelLinkDropdownItemsSet =
        allDropdownNavLinksItems[topLevelLinkItem.id];

      let doesCurrentTopLevelLinkHaveDropdownItems =
        !!currentTopLevelLinkDropdownItemsSet &&
        currentTopLevelLinkDropdownItemsSet.length !== 0;

      let currentTopLevelLinkInputs = () => {
        //each top level link can has dropdown children inside it. let's check if this particular top level link has any dropdown items and display them if they exist.

        let dropdownItemsInputs: any;

        if (doesCurrentTopLevelLinkHaveDropdownItems) {
          dropdownItemsInputs = currentTopLevelLinkDropdownItemsSet.map(
            (dropdownItem: any, dropdownItemIndex: number) => {
              let onChangeDropdownItemTitleHandler = (value: any) => {
                saveContentInStore(
                  generator.changeNavDropdownLinksItemTitle(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    dropdownItemIndex,
                    value,
                    topLevelLinkItem.id
                  )
                );
                toggleNewEditionsDetected(true);
              };
              let onChangeDropdownItemHrefHandler = (value: any) => {
                saveContentInStore(
                  generator.changeNavDropdownLinksItemHref(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    dropdownItemIndex,
                    value,
                    topLevelLinkItem.id
                  )
                );
                toggleNewEditionsDetected(true);
              };

              let currentTopLevelLinkDropdownItemsSetLength =
                currentTopLevelLinkDropdownItemsSet.length;

              return (
                <div
                  className={
                    "content-input__cluster content-input__cluster--hide-move-buttons "
                  }
                  key={dropdownItemIndex}
                >
                  <Button
                    onClick={() => {
                      toggleNewEditionsDetected(true);
                      saveContentInStore(
                        generator.deleteNavDropdownLinksItem(
                          {
                            [contentType]: currentInputDataRef.current,
                          },
                          dropdownItemIndex,
                          topLevelLinkItem.id
                        )
                      );
                    }}
                    htmlType="button"
                    className="content-input__control_cluster"
                    shape="circle"
                    title="Delete this submenu item"
                    icon="cross"
                    size="small"
                  />

                  {currentTopLevelLinkDropdownItemsSetLength > 1 && (
                    <Button
                      onClick={() => {
                        toggleNewEditionsDetected(true);
                        saveContentInStore(
                          generator.moveNavDropdownItemLinksItemUp(
                            {
                              [contentType]: currentInputDataRef.current,
                            },
                            topLevelLinkItem.id,
                            dropdownItemIndex
                          )
                        );
                      }}
                      htmlType="button"
                      className="content-input__control_cluster content-input__control_cluster--move-up content-input__control_cluster--top"
                      shape="circle"
                      title="Move this submenu item up"
                      icon="up"
                      size="small"
                    />
                  )}

                  {currentTopLevelLinkDropdownItemsSetLength > 1 && (
                    <Button
                      onClick={() => {
                        toggleNewEditionsDetected(true);
                        saveContentInStore(
                          generator.moveNavDropdownItemLinksItemDown(
                            {
                              [contentType]: currentInputDataRef.current,
                            },
                            topLevelLinkItem.id,
                            dropdownItemIndex
                          )
                        );
                      }}
                      htmlType="button"
                      className="content-input__control_cluster content-input__control_cluster--move-down content-input__control_cluster--bottom"
                      shape="circle"
                      title="Move this submenu item down"
                      icon="down"
                      size="small"
                    />
                  )}

                  <DebouncedInput
                    className="content-input__input"
                    onChange={onChangeDropdownItemTitleHandler}
                    defaultValue={dropdownItem.title}
                    placeholder="Enter submenu item title"
                  />

                  <DebouncedInput
                    className="content-input__input"
                    onChange={onChangeDropdownItemHrefHandler}
                    defaultValue={dropdownItem.href}
                    placeholder="/features"
                  />

                  {dropdownItem.href !== "" && dropdownItem.title !== "" && (
                    <Checkbox
                      className="content-input__input"
                      checked={dropdownItem.isTargetBlank}
                      onChange={(event: any) => {
                        saveContentInStore(
                          generator.changeNavDropdownLinksItemIsTargetBlank(
                            {
                              [contentType]: currentInputDataRef.current,
                            },
                            dropdownItemIndex,
                            event.target.checked,
                            topLevelLinkItem.id
                          )
                        );
                        toggleNewEditionsDetected(true);
                      }}
                    >
                      Open in a new tab
                    </Checkbox>
                  )}
                </div>
              );
            }
          );
        }

        let onChangeNavListItemTitleHandler = (value: any) => {
          saveContentInStore(
            generator.changeTopLevelNavLinksItemTitle(
              { [contentType]: currentInputDataRef.current },
              topLevelLinkKey,
              value
            )
          );
          toggleNewEditionsDetected(true);
        };
        let onChangeNavListItemHrefHandler = (value: any) => {
          saveContentInStore(
            generator.changeTopLevelNavLinksItemHref(
              { [contentType]: currentInputDataRef.current },
              topLevelLinkKey,
              value
            )
          );
          toggleNewEditionsDetected(true);
        };

        return (
          <div key={topLevelLinkKey}>
            <DebouncedInput
              addonAfter={
                <Tooltip
                  placement="topRight"
                  title={
                    <span>
                      Add a submenu item and covert this link to a dropdown:{" "}
                      <br />
                      <br />
                      <img
                        style={{ maxWidth: "100%" }}
                        src={urls.assets.submenuExample}
                        alt="Submenu example"
                      />
                    </span>
                  }
                >
                  <Button
                    onClick={() => {
                      toggleNewEditionsDetected(true);
                      saveContentInStore(
                        generator.addEmptyDropdownNavLinksItem(
                          {
                            [contentType]: currentInputDataRef.current,
                          },
                          topLevelLinkItem.id
                        )
                      );
                    }}
                    shape="circle"
                    icon="plus"
                    size="small"
                  />
                </Tooltip>
              }
              className={
                "content-input__input content-input__input--no-bottom-margin"
              }
              onChange={onChangeNavListItemTitleHandler}
              defaultValue={topLevelLinkItem.title}
              placeholder="Enter menu item title"
            />
            {doesCurrentTopLevelLinkHaveDropdownItems === false && (
              <DebouncedInput
                style={{ marginTop: 10 }}
                className="content-input__input"
                onChange={onChangeNavListItemHrefHandler}
                defaultValue={topLevelLinkItem.href}
                placeholder="/learn-more"
              />
            )}
            {topLevelLinkItem.href !== "" &&
              topLevelLinkItem.title !== "" &&
              doesCurrentTopLevelLinkHaveDropdownItems === false && (
                <Checkbox
                  className="content-input__input"
                  checked={topLevelLinkItem.isTargetBlank}
                  onChange={(event: any) => {
                    saveContentInStore(
                      generator.changeTopLevelNavLinksItemIsTargetBlank(
                        {
                          [contentType]: currentInputDataRef.current,
                        },
                        topLevelLinkKey,
                        event.target.checked
                      )
                    );
                    toggleNewEditionsDetected(true);
                  }}
                >
                  Open in a new tab
                </Checkbox>
              )}
            {doesCurrentTopLevelLinkHaveDropdownItems && (
              <div style={{ marginTop: 20 }}> {dropdownItemsInputs}</div>
            )}
            {/*{doesCurrentTopLevelLinkHaveDropdownItems && dropdownItemsInputs}*/}
          </div>
        );
      };

      return (
        <div className={"content-input__cluster"} key={topLevelLinkKey}>
          {doesCurrentTopLevelLinkHaveDropdownItems === true && (
            <Popconfirm
              placement="bottomRight"
              title={
                <span>
                  Delete the submenu{" "}
                  <b>
                    {topLevelLinkItem.title && topLevelLinkItem.title !== ""
                      ? topLevelLinkItem.title
                      : ""}
                  </b>{" "}
                  and all its items?
                </span>
              }
              onConfirm={() => {
                toggleNewEditionsDetected(true);
                saveContentInStore(
                  generator.deleteTopLevelNavLinksItem(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    topLevelLinkKey
                  )
                );
              }}
              // onCancel={}
              okText="Delete"
              cancelText="Cancel"
            >
              <Button
                htmlType="button"
                className="content-input__control_cluster content-input__control_cluster--center"
                shape="circle"
                title="Delete this menu dropdown item and all its children"
                icon="cross"
                size="small"
              />
            </Popconfirm>
          )}

          {doesCurrentTopLevelLinkHaveDropdownItems === false && (
            <Button
              htmlType="button"
              className="content-input__control_cluster content-input__control_cluster--center"
              shape="circle"
              onClick={() => {
                toggleNewEditionsDetected(true);
                saveContentInStore(
                  generator.deleteTopLevelNavLinksItem(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    topLevelLinkKey
                  )
                );
              }}
              title="Delete this menu link"
              icon="cross"
              size="small"
            />
          )}

          {displayMoveUpDownButtons(
            topLevelLinkKey,
            content.topLevelItems.length,
            () => {
              saveContentInStore(
                generator.moveTopLevelNavLinksItemUp(
                  {
                    [contentType]: currentInputDataRef.current,
                  },
                  topLevelLinkKey
                )
              );
            },
            () => {
              saveContentInStore(
                generator.moveTopLevelNavLinksItemDown(
                  {
                    [contentType]: currentInputDataRef.current,
                  },
                  topLevelLinkKey
                )
              );
            }
          )}

          {/*currentTopLevelLinkInputs - inputs of a list element*/}
          <div>{currentTopLevelLinkInputs()}</div>
        </div>
      );
    }
  );

  return (
    <div>
      {allTopLevelLinksInputs}
      {
        <Button
          size="small"
          onClick={() => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.addEmptyTopLevelNavLinksItem({
                [contentType]: currentInputDataRef.current,
              })
            );
          }}
          icon="plus"
        >
          Add new nav menu link
        </Button>
      }
    </div>
  );
};

export default NavLinks;
