import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import _ from "lodash";
import { updateCtaButtons } from "./updateCtaButtons";
import { updateCtaForm } from "./updateCtaForm";
import { isKeyValue } from "../../../../helpers/isKeyValue";

const particleUpdateBottomInfo = (completionContent, componentData) => {
  if (!("CTA" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.CTA)) {
    if (completionContent.CTA === null) {
      componentData.cta.ctaBottomInfo.markup = "";
    }
    return;
  }
  if (!("CTA_BOTTOM_INFO" in completionContent.CTA)) {
    return;
  }
  componentData.cta.ctaBottomInfo.markup =
    stringifyObjectsAndNumbers(completionContent.CTA.CTA_BOTTOM_INFO) || "";
};

const particleUpdateActiveOption = (completionContent, componentData) => {
  if (!("CTA" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.CTA)) {
    return;
  }
  if (!("CTA_TYPE" in completionContent.CTA)) {
    return;
  }
  const itemType = stringifyObjectsAndNumbers(completionContent.CTA.CTA_TYPE);
  if (itemType === "buttons") {
    componentData.cta.activeOption = "buttons";
    return;
  }
  if (itemType === "form") {
    componentData.cta.activeOption = "form";
    return;
  }
};

export const updateCta = (componentData, completionContent) => {
  updateCtaButtons(componentData, completionContent);
  updateCtaForm(componentData, completionContent);
  particleUpdateBottomInfo(completionContent, componentData);
  particleUpdateActiveOption(completionContent, componentData);
};
