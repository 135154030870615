import React, { Component } from "react";
import AuthorizationField from "./AuthorizationField";
import DeleteAccountField from "./DeleteAccountField";
import "./AccountFields.css";
import SpinnerBox from "../../SpinnerBox";
import { Divider, Switch } from "antd";
import _ from "lodash";
import axios from "axios";
import { api } from "../../../../data/urls";
import returnAuthHeaderForAJAX from "../../../../helpers/auth/returnAuthHeaderForAJAX";
import { connect } from "react-redux";
import {
  saveUserDataInState,
  setUserStateData,
} from "../../../../store/user/actions";
import {
  DEFAULT_ERROR_TITLE,
  DEFAULT_ERROR_MESSAGE,
  notificationDuration,
} from "../../../../data/constants";
import { openNotification } from "../../../../helpers/openNotification";

interface Props {
  user: any;
  auth: any;

  setUserStateData: any;
  saveUserDataInState: any;
}

class AccountFields extends Component<Props> {
  handleOnChange = (value) => {
    const { setUserStateData, saveUserDataInState } = this.props;

    setUserStateData({
      isChangeAgreedToShareWebsitesInProgress: true,
    });

    const apiUrl =
      api.account.updateUser + "/" + _.get(this.props.user, "data.id");
    const { accessToken } = this.props.auth;

    axios
      .patch(
        apiUrl,
        { is_agreed_to_share_website: value },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then(({ data }) => {
        saveUserDataInState({
          is_agreed_to_share_website: data.is_agreed_to_share_website,
        });
      })
      .catch((error) => {
        console.error(error.response);
        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      })
      .finally(() => {
        setUserStateData({
          isChangeAgreedToShareWebsitesInProgress: false,
        });
      });
  };
  render() {
    if (!this.props.user.dataFetched)
      return <SpinnerBox text="Loading account info..." />;
    return (
      <div>
        <AuthorizationField />
        <Divider className="settings__divider" />
        <div className="account_fields__block">
          <div className="account_fields__rows">
            <div
              className="account_fields__row"
              style={{ marginBottom: "20px" }}
            >
              <div>
                <div className="account_fields__title">Support access</div>
                <div className="account_fields__value">
                  <div className="account_fields__value_wrapper">
                    Allow Unicorn Platform support to access your websites so we
                    can troubleshoot issues.
                  </div>
                </div>
              </div>
              <div className="account_fields__action">
                <Switch
                  checked={_.get(
                    this.props.user,
                    "data.is_agreed_to_share_website"
                  )}
                  onChange={this.handleOnChange}
                  loading={
                    this.props.user.isChangeAgreedToShareWebsitesInProgress
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <Divider className="settings__divider" />
        <DeleteAccountField />
      </div>
    );
  }
}

export default connect(null, {
  setUserStateData,
  saveUserDataInState,
})(AccountFields);
