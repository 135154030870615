import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Links04 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Our main Products"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({
                subtitleText: '',
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                fieldTitle: {default: "Links"},
                label: {default: "Edit the links list:"},
                labelTooltip: {default: "Leave the title field blank to remove a link."},
                maxItems: 24,
                hasPictogram: false,
                hasSubtitle: true,
                hasText: false,
                hasHighlight: false,
                hasHighlightInfo: false,
                items: [
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Website Builder',
                            subtitle: 'Ferox candidatus cito consumeres apolloniates est. Observare patienter ducunt ad fidelis glos.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Learn more", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline})
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Email Newsletter',
                            subtitle: 'Nunquam imperium adelphis. Fidelis, varius cedriums interdum transferre de nobilis, pius itineris tramitem.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Learn more", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline})
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Corporate Blog',
                            subtitle: 'Tus, hilotae, et turpis. Cum parma potus, omnes toruses fallere rusticus, grandis mensaes.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Learn more", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline})
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Integrated CRM',
                            subtitle: 'Abactuss velum in cirpi! Nunquam contactus torus. A falsis.',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Learn more", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline})
                        }
                    ),


                    // The last ghost item which is used to add new items.
                    this.generator.createFeatureContentObject({
                        title: '',
                        text: '',
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Send CV", buttonHref: '/', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline})
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the links list component to show more products of your company.",
                    default: "Add a CTA (call to action) to the bottom of the links list component to show more products of your company.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'More'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let features = this.props.componentItem.componentData.features.content.items;

        return (
            <section>
                <div className="links-04">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--mid">
                        <ul className="links-04__list">
                            {features && features.map((item:any, key:number) => {
                                if(item.title && item.title !== ''){
                                    return (
                                        <li className="sh-4 card-container links-04__item" key={'links-03-' + key}>
                                            <h3 className="def-18_72 title-text--inner links-04__title">{item.title}</h3>
                                            <div className="content-text links-04__text">{item.subtitle}
                                            </div>
                                            <div className="links-04__link feature__button_box">
                                                {item.button.title !== '' && item.button.href !== '' && <WebsiteButton config={{
                                                    buttonStyleType: item.button.settings.appearance.styleType,
                                                    buttonColor: item.button.settings.appearance.color,
                                                    buttonType: linkOrButtonOptions.link,
                                                    buttonTitle: item.button.title,
                                                    buttonHref: item.button.href,
                                                    buttonIsTargetBlank: item.button.isTargetBlank,
                                                    buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                                    pillPrefix: item.button.pillPrefix,
                                                    pillColor: item.button.settings.appearance.pillColor,
                                                    ghostColor: item.button.settings.appearance.ghostColor,
                                                }} />}
                                            </div>
                                        </li>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </ul>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Links04;
