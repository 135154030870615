import React, {Component} from 'react'
import {render} from "react-dom";
import {myImagesValidator} from "../../helpers/myImagesValidator";
// const uploadcare = require('uploadcare-widget');

const uploadcare: any = require('uploadcare-widget');
// import uploadcare from 'uploadcare-widget';



interface Props {
    id: any,
    name: any,
    onChange: any,
    value?: any,
    onUploadComplete: any,
    className?: any,
}

class Uploader extends Component<Props>{
    private uploader: any;
    private dialog: any;
    private files: any;

    componentDidMount() {
        // function imagesAndSVGOnly(fileInfo:any) {
        //     if (fileInfo.mimeType !== "image/svg+xml" && fileInfo.isImage === false) {
        //         throw new Error('image');
        //     }
        // }

        const widget = uploadcare.Widget(this.uploader);
        widget.validators.push(myImagesValidator);


        const {value, onChange, onUploadComplete} = this.props;

        if (typeof value !== 'undefined') {
            widget.value(value)
        }
        if (typeof onChange === 'function') {
            widget.onChange((files:any) => {
                if (files) {
                    this.files = (this.files && this.files.files) ? this.files.files() : [this.files];
                }
                else {
                    this.files = null
                }

                onChange(files);
            })
        }
        if (typeof onUploadComplete === 'function') {
            widget.onUploadComplete(onUploadComplete)
        }
        widget.onDialogOpen((dialog:any) => this.dialog = dialog)
    }
    componentWillUnmount() {
        if (this.dialog) {
            this.dialog.reject()
        }

        // console.log('this.files', this.files);
        if (this.files && this.files[0] !== undefined) {
            try{
                uploadcare.jQuery.when.apply(null, this.files).cancel()
            }catch(e) {
                console.log('Trying Uploadcare apply. Got error: ', e);
            }
        }

        const widgetElement = uploadcare.jQuery(this.uploader).next('.uploadcare--widget');
        const widget = widgetElement.data('uploadcareWidget');

        if (widget && widget.inputElement === this.uploader) {
            widgetElement.remove()
        }
    }

    //we do not use getInputAttributes for setting the input attributes because it also applies onUploadComplete which throws an error "Unknown event handler property onUploadComplete. It will be ignored "
    // getInputAttributes() {
    //     let attributes = Object.assign({}, this.props);

        // attributes.value = undefined;
        // attributes.onChange = undefined;
        // attributes.onUploadComplete = undefined;

        // return attributes
    // }

    render() {
        // const attributes = this.getInputAttributes();

        return (
            <input
                type='hidden'
                ref={input => this.uploader = input}
                id={this.props.id}
                name={this.props.name}
                onChange={this.props.onChange}
                value={this.props.value}
                className={this.props.className}
            />
        )
    }
}

export default Uploader;