import React from "react";
import { InputProps } from "./interfaces";

const Pictograms = (props: InputProps): JSX.Element => {
  const { content, displayPictogramEditorOpener } = props;

  let pictogramsInputs = content.items.map((item: any, key: number) => {
    return displayPictogramEditorOpener(item.pictogram, key);
  });

  return pictogramsInputs;
};

export default Pictograms;
