import { UnicornComponent } from "../../../../classes/UnicornComponent";
import { Components } from "../../../../enums/Components";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  const itemUrl = stringifyObjectsAndNumbers(modifiedItem.URL) || "";
  return {
    itemId,
    itemPosition,
    itemTitle,
    itemUrl,
  };
};

const particleModifyOriginalItem = (
  content,
  originalItem,
  modifiedItem,
  itemPosition,
  itemTitle,
  itemUrl
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(content.items, item);
      }
    });
  }
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("URL" in modifiedItem) {
    originalItem.body = itemUrl;
  }
};

export const updateContacts = (
  componentData,
  completionContent,
  currentComponentInfo: UnicornComponent
) => {
  const content = componentData.contacts.content;
  if (!("CONTACTS" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.CONTACTS)) {
    if (completionContent.CONTACTS === null) {
      content.items = [];
      content.map.url = "";
      content.map.mapTitle = "";
    }
    return;
  }
  if ("MAP" in completionContent.CONTACTS) {
    if (!isKeyValue(completionContent.CONTACTS.MAP)) {
      if (completionContent.CONTACTS.MAP === null) {
        content.map.url = "";
        content.map.mapTitle = "";
      }
      return;
    }
    if ("URL" in completionContent.CONTACTS.MAP) {
      content.map.url =
        stringifyObjectsAndNumbers(completionContent.CONTACTS.MAP.URL) || "";
    }
    if ("TITLE" in completionContent.CONTACTS.MAP) {
      content.map.mapTitle =
        stringifyObjectsAndNumbers(completionContent.CONTACTS.MAP.TITLE) || "";
    }
  }
  if (!("LINKS" in completionContent.CONTACTS)) {
    return;
  }
  if (!Array.isArray(completionContent.CONTACTS.LINKS)) {
    if (completionContent.CONTACTS.LINKS === null) {
      content.items = [];
    }
    return;
  }
  content.items = deleteEmptyItems(content.items, "body");
  if (currentComponentInfo.name === Components.footer) {
    content.items = deleteEmptyItems(content.items, "title");
  }
  addDefaultValuesToItems(content.items);
  completionContent.CONTACTS.LINKS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemTitle,
      itemUrl,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      content.items = deleteItem(content.items, itemId);
      return;
    }
    const isItemNew = getIsItemNew(content.items, itemId);
    if (isItemNew) {
      const newItem = {
        title: "",
        body: "#",
      };
      addNewItem(content.items, newItem, itemId, itemPosition);
    }
    const originalItem = getOriginalItem(content.items, itemId);
    particleModifyOriginalItem(
      content,
      originalItem,
      modifiedItem,
      itemPosition,
      itemTitle,
      itemUrl
    );
  });
  sortItemsByPosition(content.items);
};
