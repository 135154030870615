import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import _ from "lodash";

export const updateSubtitle = (componentData, completionContent) => {
  if (!("SUBTITLE" in completionContent)) {
    return;
  }
  const modifiedSubtitle = stringifyObjectsAndNumbers(completionContent.SUBTITLE);
  componentData.subtitleText.content.markup = modifiedSubtitle || "";
};
