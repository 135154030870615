import ContentInputsTypesEnum from "../../../enums/ContentInputsTypesEnum";
import Title from "./Title";
import SubtitleText from "./SubtitleText";
import Text from "./Text";
import Code from "./Code";
import Pictograms from "./Pictograms";
import Steps from "./Steps/Steps";
import Team from "./Team";
import Pricing from "./Pricing";
import Features from "./Features";
import Posts from "./Posts";
import Gallery from "./Gallery/Gallery";
import Faq from "./Faq";
import List from "./List";
import Graphics from "./Graphics/Graphics";
import SocialLinks from "./SocialLinks";
import Contacts from "./Contacts";
import NavLinks from "./NavLinks";
import Logos from "./Logos";
import Mockups from "./Mockups";
import Cta from "./Cta";
import NavLogo from "./NavLogo";
import Directory from "./Directory";
import Widget from "./Widget";
import RapidForms from "./RapidForms";
import Marketsy from "./Marketsy";

export const ContentInputsMap = {
  [ContentInputsTypesEnum.title]: Title,
  [ContentInputsTypesEnum.subtitleText]: SubtitleText,
  [ContentInputsTypesEnum.text]: Text,
  [ContentInputsTypesEnum.code]: Code,
  [ContentInputsTypesEnum.pictograms]: Pictograms,
  [ContentInputsTypesEnum.steps]: Steps,
  [ContentInputsTypesEnum.team]: Team,
  [ContentInputsTypesEnum.pricing]: Pricing,
  [ContentInputsTypesEnum.features]: Features,
  [ContentInputsTypesEnum.posts]: Posts,
  [ContentInputsTypesEnum.gallery]: Gallery,
  [ContentInputsTypesEnum.faq]: Faq,
  [ContentInputsTypesEnum.list]: List,
  [ContentInputsTypesEnum.graphics]: Graphics,
  [ContentInputsTypesEnum.socialLinks]: SocialLinks,
  [ContentInputsTypesEnum.contacts]: Contacts,
  [ContentInputsTypesEnum.navLinks]: NavLinks,
  [ContentInputsTypesEnum.logos]: Logos,
  [ContentInputsTypesEnum.mockups]: Mockups,
  [ContentInputsTypesEnum.cta]: Cta,
  [ContentInputsTypesEnum.navLogo]: NavLogo,
  [ContentInputsTypesEnum.directory]: Directory,
  [ContentInputsTypesEnum.widget]: Widget,
  [ContentInputsTypesEnum.rapidForms]: RapidForms,
  [ContentInputsTypesEnum.marketsy]: Marketsy,
};
