import { formatImageUrl } from "./../helpers/strings/formatImageUrl";
import _ from "lodash";
import { Oauth2Apps } from "../enums/Oauth2Apps";

//urls of external services and pages
export const urls = {
  homeLandingPage: "https://unicornplatform.com/" as string,
  blog: "https://unicornplatform.com/blog" as string,
  supportUs: "https://unicornplatform.com/support-us/" as string,
  homeDashboard: "https://app.unicornplatform.com" as string,
  tos: "https://unicornplatform.com/terms-of-service/" as string,
  privacyPolicy: "https://unicornplatform.com/privacy-policy/" as string,
  affiliate: "https://unicornplatform.com/earn" as string,
  help: "https://help.unicornplatform.com/" as string,
  slackChannel:
    "https://join.slack.com/t/unicornplatform/shared_invite/enQtMzc3NjEzMDM3ODYzLTA4NmM5OTI1Nzc1MjdlNWZlYjQ1Y2NhNzdjYTA0MTZkMjA4MDQzMWY5NTI1OTI4ZDRmNzRhYjYxZTY3NjhlMWE" as string,
  changelog:
    "https://www.notion.so/Release-Notes-568b4245b18542dd95a95370c0623ba9" as string,
  roadmap:
    "https://trello.com/b/P5mOquUI/unicorn-platform-feature-requests" as string,
  broadwiseSpace: "https://broadwise.org/c/unicorn-platform/105" as string,
  contactUsEmail: "support@unicornplatform.com" as string,
  twitter: "https://twitter.com/johnrushx" as string,
  facebook: "https://www.fb.com/unicornplatform/" as string,
  instagram: "https://www.instagram.com/unicornplatform/" as string,
  productHuntUpcoming:
    "https://producthunt.com/upcoming/unicorn-platform/" as string,
  productHuntV1:
    "https://www.producthunt.com/posts/unicorn-platform/" as string,
  productHuntV2:
    "https://www.producthunt.com/products/unicorn-platform" as string,
  askLargerVolumeForm: "https://forms.gle/jLeJKQqL34ikr8pX7" as string,
  error404: "http://unicornplatform.com/404/" as string,
  guides: {
    mailchimpIntegration:
      "https://help.unicornplatform.com/en/article/guide-how-to-activate-mailchimp-integration-3-min-als2bn/",
    emailIntegration:
      "https://help.unicornplatform.com/en/article/guide-send-form-data-to-email-196gsz9/",
    zapierIntegration:
      "https://help.unicornplatform.com/en/article/guide-setting-up-zapier-form-integration-10-min-psthbr/",
    utmRelay:
      "https://help.unicornplatform.com/en/article/utm-tags-relay-15rut8m/",
    stripeCheckoutGuide:
      "https://help.unicornplatform.com/en/article/stripe-checkout-integration-1ji5u1/",
    googleSheetsIntegration:
      "https://help.unicornplatform.com/en/article/guide-connect-google-sheets-integration-to-a-form-2-min-hwbzux/",
    connectCustomDomain:
      "https://help.unicornplatform.com/en/article/how-to-connect-custom-domain-1s7kkrb/?1606827718770",
    cloudflareSSL:
      "https://help.unicornplatform.com/en/article/connect-cloudflare-and-issue-a-free-ssl-certificate-1b82fv0/?1606917071663",
    multilanguage:
      "https://help.unicornplatform.com/en/article/guide-multi-language-website-10alhky/?bust=1639646422334",
    xyz: "https://help.unicornplatform.com/en/article/how-to-get-and-register-a-free-domain-on-xyz-1t19vgy/",
    recaptcha:
      "https://help.unicornplatform.com/en/article/recaptcha-1a7drr7/?bust=1651669196603",
    mailerliteIntegration:
      "https://help.unicornplatform.com/en/article/how-to-connect-mailerlite-form-integration-1gu63u2/",
    dynamicData:
      "https://help.unicornplatform.com/en/article/wip-how-to-add-dynamic-data-source-1sdv73f/",
    dynamicDataGoogleSheets:
      "https://help.unicornplatform.com/en/article/dynamic-data-from-google-sheets-spreadsheet-19iv9mw/",
  },
  stripeApiKeys: {
    dashboard: "https://dashboard.stripe.com/test/apikeys",
  },
  assets: {
    mailchimpIntegrationAction:
      "https://hkk-radio.s3.amazonaws.com/step-6-copy-action-1024x603.jpg",
    stripePublicApiKey:
      "https://hkk-radio.s3.amazonaws.com/stripePublicApiKey.jpg",
    stripeSecretApiKey:
      "https://hkk-radio.s3.amazonaws.com/stripeSecretApiKey.jpg",
    defaultProductImage:
      "https://ucarecdn.com/cdbade74-194f-4fad-9d58-c1f319853305/box.png",
    copyStripeSKU: "https://hkk-radio.s3.amazonaws.com/copyStripeSKU.jpg",
    copyStripePricingId:
      "https://hkk-radio.s3.amazonaws.com/copyStripePricingId.png",
    openStripePlan: "https://hkk-radio.s3.amazonaws.com/openStripePlan.jpg",
    addStripePlan: "https://hkk-radio.s3.amazonaws.com/addStripePlan.jpg",
    createStripeProduct:
      "https://hkk-radio.s3.amazonaws.com/createStripeProduct.jpg",
    diaformGif:
      "https://hkk-radio.s3.amazonaws.com/diaform-example-800x600.gif",
    mailerliteIntegrationAction:
      "https://hkk-radio.s3.amazonaws.com/mailerlite-integration.png",
    submenuExample: "https://hkk-radio.s3.amazonaws.com/SubmenuExample.jpg",
    ogImage: "https://hkk-radio.s3.amazonaws.com/ogImage.png",
    oauth2: {
      zapier: "https://up-blog-prod.s3.amazonaws.com/zapier_access-nyhfw.png",
    },
    oauth2Logos: {
      [Oauth2Apps.Zapier]:
        "https://up-blog-prod.s3.amazonaws.com/zapier-logo-rxajw.png",
    },
  },
  download: {
    assetsArchive:
      "https://website-export-assets.s3.amazonaws.com/exported_website.zip",
  },
  stripe: {
    products: "https://dashboard.stripe.com/products",
  },
  thirdparty: {
    googleTerms: "https://policies.google.com/terms",
    googlePrivacy: "https://policies.google.com/privacy",
    captchaBadgeFAQ:
      "https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed",
    zapierEditor: "https://zapier.com/editor",
  },
  discordWebhooks: {
    leads:
      "https://discord.com/api/webhooks/1243567401195278347/7qNvKDV2a6vVDad_gsWMJW3-b6pcrYS4BTQ793o0CRWuCoMBUL-U2pc7wj6bpTKo-2Tr",
  },
  seobotEditor: "https://seobotai.com/",
  seobotLanding: "https://seobotai.com/",
};

const appRootUrl = `${window.location.protocol}//${window.location.host}`;

const PROD_OLD_CONFIG = {
  apiUrlBase: process.env.REACT_APP_API_URL || appRootUrl,
  appUrl: process.env.REACT_APP_URL || appRootUrl,
  publicUrl: process.env.PUBLIC_URL || "https://dvzvtsvyecfyp.cloudfront.net/static",
  cachedApiUrlBase: "https://d26xhijj6ckg9n.cloudfront.net/api/v1"
}
const STAGE_OLD_CONFIG = {
  apiUrlBase: process.env.REACT_APP_API_URL || appRootUrl,
  appUrl: process.env.REACT_APP_URL || appRootUrl,
  publicUrl: process.env.PUBLIC_URL || "https://dvzvtsvyecfyp.cloudfront.net/static",
  cachedApiUrlBase: "https://d26oca1e0cx1i.cloudfront.net/api/v1"
}
const PROD_NEW_CONFIG = {
  apiUrlBase: "https://api.unicornplatform.com",
  appUrl: "https://app.unicornplatform.com",
  publicUrl: "https://cdn.unicornplatform.com/static",
  cachedApiUrlBase: "https://api-cache.unicornplatform.com/api/v1",
}
const PROD_NEW2_CONFIG = {
  apiUrlBase: "https://api.unicornplatform.com",
  appUrl: "https://app2.unicornplatform.com",
  publicUrl: "https://cdn2.unicornplatform.com/static",
  cachedApiUrlBase: "https://api-cache.unicornplatform.com/api/v1",
}
const STAGE_NEW_CONFIG = {
  apiUrlBase: "https://api.stage-unicorn.win",
  appUrl: "https://app.stage-unicorn.win",
  publicUrl: "https://cdn.stage-unicorn.win/static",
  cachedApiUrlBase: "https://api-cache.stage-unicorn.win/api/v1",
}
const STAGE_NEW2_CONFIG = {
  apiUrlBase: "https://api.stage-unicorn.win",
  appUrl: "https://app2.stage-unicorn.win",
  publicUrl: "https://cdn2.stage-unicorn.win/static",
  cachedApiUrlBase: "https://api-cache.stage-unicorn.win/api/v1",
}
const LOCALHOST_CONFIG = {
  apiUrlBase: process.env.REACT_APP_API_URL || appRootUrl,
  appUrl: process.env.REACT_APP_URL || appRootUrl,
  publicUrl: process.env.PUBLIC_URL || "https://dvzvtsvyecfyp.cloudfront.net/static",
  cachedApiUrlBase: `${process.env.REACT_APP_API_URL || appRootUrl}/api/v1`
}

const IS_NEW_SETUP = process.env.PUBLIC_URL === "__STATIC_HOST_PLACEHOLDER__/static";
const hostSpecificConfigs = {
  defaults: PROD_OLD_CONFIG,
  "https://app.unicornplatform.com": IS_NEW_SETUP ? PROD_NEW_CONFIG : PROD_OLD_CONFIG,
  "https://app.stage-unicorn.win": IS_NEW_SETUP ? STAGE_NEW_CONFIG : STAGE_OLD_CONFIG,
  "https://app2.unicornplatform.com": PROD_NEW2_CONFIG,
  "https://app2.stage-unicorn.win": STAGE_NEW2_CONFIG,
  "http://localhost:3000": LOCALHOST_CONFIG
}
const hostConfig = hostSpecificConfigs[appRootUrl] || hostSpecificConfigs.defaults
export const apiUrlBase = hostConfig.apiUrlBase
export const appUrl = hostConfig.appUrl
export const publicUrl = hostConfig.publicUrl
export const cachedApiUrlBase = hostConfig.cachedApiUrlBase

export const pageComponentThumb = {
  prefix: "/img/thumbs/",
  postfix: ".jpg",
};
export const companyLogos = {
  prefix: "/img/logos/companies/",
};
export const pressLogos = {
  prefix: "/img/logos/press/",
};
export const abstractIcons = {
  prefix: "/img/icons/abstract_icons/",
};
export const emojiPath = {
  prefix: "/img/twemoji/",
};

export const otherImgPath = {
  prefix: "/img/other/",
};
export const iconsImgPath = {
  prefix: "/img/icons/",
};

export const changePasswordLink =
  "https://app.unicornplatform.com/auth/restore-pass";

export const api = {
  auth: {
    // URLs that do not require a session or valid token
    sendPasswordResetLink: apiUrlBase + "/rest-auth/password/reset/",
    setNewPasswordAfterReset: apiUrlBase + "/rest-auth/password/reset/confirm/",
    login: apiUrlBase + "/rest-auth/login/",
    verifyEmailAfterRegistration:
      apiUrlBase + "/rest-auth/registration/verify-email/",
    registration: apiUrlBase + "/rest-auth/registration/",

    // URLs that require a user to be logged in with a valid session / token.
    logout: apiUrlBase + "/rest-auth/logout/",

    user:
      apiUrlBase +
      "/rest-auth/user/" /*get a little of user data: email, pk name. provided by the rest-auth module. We need more info: a lot of custom fields we have. We will them later.*/,
  },
  account: {
    passwordChange: apiUrlBase + "/rest-auth/password/change/",
    cancelPlan: apiUrlBase + "/api/v1/users/cancel",
    getCurrentUserInfo: apiUrlBase + "/api/v1/users/current/", // get the custom fields of user model.
    upgradeFromFreeToPro: {
      // e.g.: /api/v1/users/551168/upgrade
      prefix: apiUrlBase + "/api/v1/users/",
      // here comes an ID
      postfix: "/upgrade",
    },
    changeProPlan: {
      // e.g.: /api/v1/users/551168/change
      prefix: apiUrlBase + "/api/v1/users/",
      // here comes an ID
      postfix: "/change",
    },
    resendVerifyEmail: apiUrlBase + "/api/v1/users/resend_confirm_email/",
    getSubscriptionInfo: apiUrlBase + "/api/v1/users/get_subscription_info/",
    getPaymentsHistory: apiUrlBase + "/api/v1/users/get_payments_history/",
    changeEmail: apiUrlBase + "/api/v1/users/change_email/",
    deleteAccount: apiUrlBase + "/api/v1/users/delete_account/",
    setUserEvents: apiUrlBase + "/api/v1/users/set_user_events",
    updateUser: apiUrlBase + "/api/v1/users/update_user",
  },
  plans: {
    fetchPlans: cachedApiUrlBase + "/plans/",
  },
  templates: {
    fetchTemplates: cachedApiUrlBase + "/templates/",
  },
  formsIntegrations: {
    fetch: cachedApiUrlBase + "/form_integrations/",
  },
  sharedAccess: {
    fetch: apiUrlBase + "/api/v1/shared_accesses/all",
    create: apiUrlBase + "/api/v1/shared_accesses/create",
    delete: {
      // example: apiUrlBase + '/api/v1/shared_accesses/12/delete',
      prefix: apiUrlBase + "/api/v1/shared_accesses/",
      // here comes an ID
      postfix: "/delete",
    },
    createAdmin: apiUrlBase + "/api/v1/shared_accesses/create_admin",
    deleteAdmin: apiUrlBase + "/api/v1/shared_accesses/delete_admin",
  },
  meta: {
    fetch: cachedApiUrlBase + "/meta/all",
    fetchCache: apiUrlBase + "/api/v1/meta/cache",
    validateSheet: apiUrlBase + "/api/v1/meta/validate_sheet",
    saveBackup: apiUrlBase + "/api/v1/meta/save_backup",
  },
  pageComponentCategories: {
    fetch: cachedApiUrlBase + "/page_component_categories/",
  },
  pageComponents: {
    fetch: cachedApiUrlBase + "/page_components/",
  },
  websites: {
    fetchWebsites: apiUrlBase + "/api/v1/websites/", // takes ?id parameter to filter the websites array by website id
    createWebsite: apiUrlBase + "/api/v1/websites/create",
    updateWebsite: {
      // e.g.: /api/v1/my-startup-site/update
      prefix: apiUrlBase + "/api/v1/websites/",
      // here comes a subdomain
      postfix: "/update",
    },
    validateDns: {
      // e.g.: /api/v1/my-startup-site/validate_dns
      prefix: apiUrlBase + "/api/v1/websites/",
      // here comes a subdomain
      postfix: "/validate_dns",
    },
    addCustomDomain: {
      prefix: apiUrlBase + "/api/v1/websites/",
      // here comes a subdomain
      postfix: "/add_custom_domain",
    },
    cloneWebsite: {
      // e.g.: /api/v1/my-startup-site/clone
      prefix: apiUrlBase + "/api/v1/websites/",
      // here comes a subdomain
      postfix: "/clone",
    },
    createWebsiteFromTemplate: {
      // e.g.: /api/v1/my-startup-site/create_from_template
      prefix: apiUrlBase + "/api/v1/websites/",
      // here comes a subdomain
      postfix: "/create_from_template",
    },
    deleteWebsite: {
      // e.g.: /api/v1/my-startup-site/delete
      prefix: apiUrlBase + "/api/v1/websites/",
      // here comes a subdomain
      postfix: "/delete",
    },
    passFormDataToGoogleSheet:
      apiUrlBase + "/api/v1/websites/send_to_google_sheet",
    passFormDataToEmail: apiUrlBase + "/api/v1/websites/send_to_email",
    passFormDataToZapier: apiUrlBase + "/api/v1/websites/send_to_zapier",
    manageWebhooks: apiUrlBase + "/api/v1/websites/manage_webhooks",
    connectCustomDomain: apiUrlBase + "/api/v1/websites/connect_custom_domain",
  },
  websitePages: {
    base: "/api/v1/website_pages/",
    getAllUsersWebsitesPages: apiUrlBase + "/api/v1/website_pages/all/",
    getWebsitesPagesWithoutComponents:
      apiUrlBase + "/api/v1/website_pages/all/?exclude_page_components",
    createWebsitePage: {
      // e.g.: /api/v1/website_pages/my-startup-site/create
      prefix: apiUrlBase + "/api/v1/website_pages/",
      // here comes a subdomain
      postfix: "/create",
    },
    fetchWebsitePagesUrl: getFetchWebsitePagesURL,
    updateWebsitePage: {
      // e.g.: /api/v1/website_pages/my-startup-site/about/update
      prefix: apiUrlBase + "/api/v1/website_pages/",
      // here comes a subdomain
      // here comes '/'
      // here comes a page URL
      postfix: "/update",
    },
    cloneWebsitePage: {
      // e.g.: /api/v1/my-startup-site/about/clone
      prefix: apiUrlBase + "/api/v1/website_pages/",
      // here comes a subdomain
      // here comes '/'
      // here comes a page URL
      postfix: "/clone",
    },
    moveWebsitePageToAnotherWebsite: {
      // e.g.: /api/v1/12/55/move
      prefix: apiUrlBase + "/api/v1/website_pages/",
      // here comes a subdomain
      // here comes '/'
      // here comes the moved page ID
      // here comes '/'
      // here comes the new website ID
      // here comes '/'
      postfix: "/move",
    },
    deleteWebsitePage: {
      // e.g.: /api/v1/my-startup-site/about/delete
      prefix: apiUrlBase + "/api/v1/website_pages/",
      // here comes a subdomain
      // here comes '/'
      // here comes a page URL
      postfix: "/delete",
    },
    fetchAdvancedComponents: apiUrlBase + "/api/v1/website_pages/advanced",
    fetchPageTemplates: apiUrlBase + "/api/v1/website_pages/page-templates",

  },
  blogs: {
    base: "/api/v1/blogs/",
    fetchBlogs: apiUrlBase + "/api/v1/blogs/",
    createBlog: apiUrlBase + "/api/v1/blogs/create",
    updateBlog: {
      // e.g.: /api/v1/613/update
      prefix: apiUrlBase + "/api/v1/blogs/",
      // here comes a blog id <int>
      postfix: "/update",
    },
    cloneBlog: {
      // e.g.: /api/v1/613/clone
      prefix: apiUrlBase + "/api/v1/blogs/",
      // here comes a blog id <int>
      postfix: "/clone",
    },
    deleteBlog: {
      // e.g.: /api/v1/613/delete
      prefix: apiUrlBase + "/api/v1/blogs/",
      // here comes a blog id <int>
      postfix: "/delete",
    },
  },
  blogPosts: {
    base: "/api/v1/blog_posts/",
    getAllUsersBlogsPosts: apiUrlBase + "/api/v1/blog_posts/all/",
    getAllUsersBlogsPostsWithoutContent:
      apiUrlBase + "/api/v1/blog_posts/all/?exclude_post_content",
    createBlogPost: {
      // e.g.: /api/v1/website_pages/my-startup-site/create
      prefix: apiUrlBase + "/api/v1/blog_posts/",
      // here comes a subdomain
      postfix: "/create",
    },
    updateBlogPost: {
      // e.g.: /api/v1/website_pages/my-startup-site/blog-post-5881982905/update
      prefix: apiUrlBase + "/api/v1/blog_posts/",
      // here comes a subdomain
      // here comes '/'
      // here comes a page URL
      postfix: "/update",
    },
    cloneBlogPost: {
      // e.g.: /api/v1/my-startup-site/blog-post-5881982905/clone
      prefix: apiUrlBase + "/api/v1/blog_posts/",
      // here comes a subdomain
      // here comes '/'
      // here comes a page URL
      postfix: "/clone",
    },
    deleteBlogPost: {
      // e.g.: /api/v1/my-startup-site/blog-post-5881982905/delete
      prefix: apiUrlBase + "/api/v1/blog_posts/",
      // here comes a subdomain
      // here comes '/'
      // here comes a page URL
      postfix: "/delete",
    },
  },
  images: {
    base: "/api/v1/images/",
    getAllImages: apiUrlBase + "/api/v1/images/all/",
    createImage: {
      // e.g.: /api/v1/images/my-startup-site/create
      prefix: apiUrlBase + "/api/v1/images/",
      // here comes a subdomain
      postfix: "/create",
    },
    deleteImage: {
      // e.g.: /api/v1/my-startup-site/b402c953-e3fe-4950-975d-3a4f0d665d2c/delete
      prefix: apiUrlBase + "/api/v1/images/",
      // here comes a subdomain
      // here comes '/'
      // here comes an image UUID
      postfix: "/delete",
    },
    uploadImagesAsFile: apiUrlBase + "/api/v1/images/upload_by_file/",
  },
  oauth2: {
    fetchAccessTokens: `${apiUrlBase}/api/v1/oauth2/get_access_tokens`,
    createGrant: apiUrlBase + "/api/v1/oauth2/create_grant",
    deleteAccessToken: `${apiUrlBase}/api/v1/oauth2/delete_access_token`,
  },
  gpt: {
    editComponent: apiUrlBase + "/api/v1/gpt/edit_component",
    fillTemplate: apiUrlBase + "/api/v1/gpt/fill_template",
  },
};

function getFetchWebsitePagesURL(subdomain: string): string {
  return apiUrlBase + api.websitePages.base + subdomain + "/";
}

const MOCKUP_CDN_URLS = {
  desktop: [
    "https://ucarecdn.com/12ffa08a-09fe-4902-9454-8c5b7c8a4295/microsoft_surface_book.png",
    "https://ucarecdn.com/531f9eac-c5c4-47ce-abef-e245ec82777b/safari_dark.png",
    "https://ucarecdn.com/14130136-3531-4415-b3c8-4712c025c94c/safari_clay.png",
    "https://ucarecdn.com/c5adde7e-37c8-49b1-83d4-27ed953431de/dell_xps_15.png",
    "https://ucarecdn.com/65cc0efe-b6ca-4f41-a8a3-a0739f438a6a/dell_ultrasharp_27.png",
    "https://ucarecdn.com/53323f38-206b-4753-89de-4d5109740f82/chrome_clay.png",
    "https://ucarecdn.com/49c2b9a7-4557-419d-949e-bfc289e2b782/apple_thunderbolt_display.png",
    "https://ucarecdn.com/ae4aed1c-fa39-4f95-961b-f3f9273bf1e9/chrome_dark.png",
    "https://ucarecdn.com/0e8e8674-a59a-4fca-af68-ff6949495a46/empty.png",
    "https://ucarecdn.com/84b6a1af-6ccd-4b37-8644-1e6c54851d62/apple_imac.png",
    "https://ucarecdn.com/c77c0ba4-45b8-41ed-9977-8f65dde5393e/apple_macbook_pro_space_grey.png",
    "https://ucarecdn.com/96d7548b-4c4f-4b5a-b00c-708bbdf58ecb/apple_macbook_air_space_grey.png",
    "https://ucarecdn.com/8aa9d78a-71b3-4746-a3e0-f0d9983c9f13/apple_imac_pro.png",
    "https://ucarecdn.com/d747c450-b97a-41bf-9a3d-3cfb2bc8a3c7/apple_macbook_pro_silver.png",
    "https://ucarecdn.com/c88e30f8-12cd-4e69-b059-1e59c668b1ed/apple_macbook_air_silver.png",
    "https://ucarecdn.com/31420c85-6343-4d22-b2ea-60f5222b9fb3/apple_macbook_air_gold.png",
  ],
  mobile: [
    "https://ucarecdn.com/a6b30838-1d92-4049-894c-fcb540d54087/nokia_3310.png",
    "https://ucarecdn.com/4ca34390-33da-442f-8355-ec603ef73a6f/samsung_galaxy_note10+_aura_black.png",
    "https://ucarecdn.com/422a1178-de82-41ec-b0a9-e88acdc52899/google_pixel_4_xl_just_black.png",
    "https://ucarecdn.com/39cbb255-eb57-45de-a347-6be48908cbeb/xiaomi_mi_mix_alpha_front.png",
    "https://ucarecdn.com/96c9487e-1cf6-4d8f-a292-b59be4b54410/samsung_galaxy_s20_utra_cosmic_black.png",
    "https://ucarecdn.com/94614a25-0f72-48c1-a129-b6c0c2b4cc09/apple_iphone_xr_space_grey.png",
    "https://ucarecdn.com/89ec9b84-4ad3-4f83-8ded-76e2d7b9586a/apple_iphone_13_pro_sierra_blue.png",
    "https://ucarecdn.com/06484869-ace8-43e9-8a02-378793263094/apple_iphone_xr_yellow.png",
    "https://ucarecdn.com/1fd18353-c64d-4b6c-8700-ed75ff1ec9a7/apple_iphone_xr_silver.png",
    "https://ucarecdn.com/be6f2e63-f2f9-4de7-8f02-49a662b41bbf/apple_iphone_xr_coral.png",
    "https://ucarecdn.com/68d61907-fbe0-47ab-9356-4a5f1d70116d/empty.png",
    "https://ucarecdn.com/955f1184-9b97-47bf-a0ab-6186f23e3e3d/apple_iphone_se_2020_silver.png",
    "https://ucarecdn.com/3162b53c-ec75-4774-9df6-6a1ce38e5456/apple_iphone_xr_red.png",
    "https://ucarecdn.com/820d418a-18e0-4d54-8e22-d4c304dc0527/apple_iphone_13_pro_gold.png",
    "https://ucarecdn.com/d1b08a5f-6d7d-46f6-b975-ca7c72cd25db/apple_iphone_se_2020_red.png",
    "https://ucarecdn.com/9904c702-74f2-4848-92ba-5b4b40fc5d50/apple_iphone_xr_blue.png",
    "https://ucarecdn.com/551dab50-4b53-44e4-98b5-bbc925c173a5/apple_iphone_13_pro_silver.png",
    "https://ucarecdn.com/16ae4188-db77-4847-b050-ec9748b3ce2c/apple_iphone_13_pro_graphite.png",
    "https://ucarecdn.com/f8c4871c-db7a-4f44-acb0-6e4854a590ed/apple_iphone_se_2020_black.png",
    "https://ucarecdn.com/04f7baaf-d013-4e30-86b3-623511b983f7/apple_iphone_11_pro_max_space_grey.png",
    "https://ucarecdn.com/563f9dcf-7055-4f94-8e87-06587f9a83d1/apple_iphone_11_pro_max_midnight_green.png",
    "https://ucarecdn.com/bd9bfdc4-eda8-4861-9544-4c1f88e1bbe4/apple_iphone_8_plus_gold.png",
    "https://ucarecdn.com/dc1f4167-f66f-47fc-8758-b4a518c6d7f2/apple_iphone_11_pro_max_gold.png",
    "https://ucarecdn.com/c401c5b8-1ed3-403d-8ff6-5bb624dd113f/apple_iphone_11_pro_max_silver.png",
    "https://ucarecdn.com/eb6d6bdd-1089-47e3-8321-7ceb215b97f3/apple_iphone_8_plus_silver.png",
    "https://ucarecdn.com/55d338da-3ba7-4608-b5a6-9b63fe8fb39c/apple_iphone_8_plus_space_grey.png",
    "https://ucarecdn.com/d82680ff-9aec-49ee-b089-9234ba07c803/iphone_15_pro_max_white.png",
    "https://ucarecdn.com/ea7288ad-4512-42af-8e19-515ee60788a9/iphone_15_pro_max_black.png",
    "https://ucarecdn.com/e28edce9-c436-4466-afaf-7c6e3124289b/iphone_15_pro_max_blue.png",
    "https://ucarecdn.com/44207e16-4c8b-47c6-8045-67ac611f9e18/iphone_15_pro_max_natural.png",
  ],
  tablet: [
    "https://ucarecdn.com/359e4bbc-91a4-4f52-837d-37ab47c4bcca/apple_ipad_pro_13_silver_-_portrait.png",
    "https://ucarecdn.com/0be20d79-0699-42de-92cd-af06aef4baef/apple_ipad_air_space_grey.png",
    "https://ucarecdn.com/5c000206-d521-44ca-9845-a0cee08c5b1d/apple_ipad_pro_13_silver_-_landscape.png",
    "https://ucarecdn.com/9d61043b-c14c-4e50-bf2b-a32765f0f2b4/apple_ipad_air_silver.png",
    "https://ucarecdn.com/2d0fdd14-9ebb-41df-acb7-47b839ddbfbc/apple_ipad_air_gold.png",
    "https://ucarecdn.com/a63954c3-a1f0-4095-a910-f876c9623354/microsoft_surface_pro_4.png",
  ],
};

function getMockupDeviceRenderImageFullFilepath(mockupObject: any): string {
  return formatImageUrl(
    _.get(MOCKUP_CDN_URLS, mockupObject.type, []).find((url: string) =>
      url.includes(mockupObject.fileName)
    )
  );
}
export default getMockupDeviceRenderImageFullFilepath;

export const DEFAULT_IMAGES_CDN = {
  UI_DESKTOP_CALENDAR:
    "https://ucarecdn.com/8e751f29-c040-499a-9879-badbca6adccc/06000.png",
  UI_DESKTOP_FINANCE:
    "https://ucarecdn.com/b811221c-fa32-4743-b462-9fb9a65a505a/05000.png",
  UI_DESKTOP_DASHBOARD_1:
    "https://ucarecdn.com/9f6b2eed-52fd-4a68-bc09-decc507b6896/00000.png",
  UI_DESKTOP_CRM:
    "https://ucarecdn.com/eeed521e-d75c-45f2-ba9f-f6a4d02e3c0e/04000.png",
  UI_DESKTOP_DASHBOARD_3_DARK:
    "https://ucarecdn.com/867d8ee5-5bf9-480b-a9d8-875c7761df24/02100.png",

  UI_MOBILE_FINANCE:
    "https://ucarecdn.com/b86b8131-91c1-47f9-9284-88ca0178ddb0/16000.png",
  UI_MOBILE_DASHBOARD:
    "https://ucarecdn.com/13bd29b7-d71c-47fc-980f-bffbfc627ba1/14000.png",
  UI_MOBILE_PROFILE:
    "https://ucarecdn.com/6084c37c-849f-4e2b-a8b9-9258ef8b2ea3/12000.png",
  UI_MOBILE_ECOMMERCE:
    "https://ucarecdn.com/8ff58890-5879-4ebe-ba9f-cb8e493e75eb/15000.png",

  PERSON_1:
    "https://ucarecdn.com/7e28887f-19cb-4ab6-a2d1-fcd311e8f5bd/alexanderisora.jpg",
  PERSON_2:
    "https://ucarecdn.com/515fd2e1-cd71-4b5f-abd2-54b884354b12/femaleviadorothypuscas.jpg",
  PERSON_3:
    "https://ucarecdn.com/b85136b3-726b-4da0-9483-a205a5413292/viaalexbrisbey.jpg",
  PERSON_4:
    "https://ucarecdn.com/6a6d4612-33fc-43a2-87b0-dfd067b6bcae/via35mm.jpg",
  PERSON_5:
    "https://ucarecdn.com/91cb81ff-709b-44f4-bf75-d84e156ba109/sethdoyle_girl.jpg",
  PERSON_6:
    "https://ucarecdn.com/1dc6f8c1-873a-4e4e-ac36-44f7e6722f63/warrenwong.jpg",
  PERSON_7:
    "https://ucarecdn.com/a9716716-e315-409c-9a6a-c0f9673f8377/tiljentzsch.jpg",
  PERSON_8:
    "https://ucarecdn.com/53f942da-82b0-461b-9690-37ddc09cd712/viasharongarcia.jpg",
  PERSON_9:
    "https://ucarecdn.com/56187064-286a-4c64-80fa-23b84ae8d5e5/viabewakoffcom.jpg",
  PERSON_10:
    "https://ucarecdn.com/cc74b20c-3ab5-4f18-888f-6d9d72acfc5f/viabrookecagle.jpg",
  PERSON_11:
    "https://ucarecdn.com/99d6c014-df13-427e-aa20-081cbd6f7686/viacristiannewman.jpg",
  PERSON_12:
    "https://ucarecdn.com/732b2c9f-9415-44bd-9ecf-14200b05c844/viadrewpatrickmiller.jpg",
  PERSON_13:
    "https://ucarecdn.com/8eee7f2e-c143-483e-88dc-3c197d12947b/viaestebangiacobbe.jpg",
  PERSON_14:
    "https://ucarecdn.com/b15719c1-17ab-47b1-8d3b-7a419790b791/viagifthabeshaw.jpg",
  PERSON_15:
    "https://ucarecdn.com/232a601e-5722-4460-bc1c-8f56237c912e/viahannamorris.jpg",
  PERSON_16:
    "https://ucarecdn.com/eb44e34a-1eb6-46fb-8fcf-2c378a6fdbb9/viajakeyoung.jpg",
  PERSON_17:
    "https://ucarecdn.com/dcb9f239-9926-4af0-abd1-50760af1c430/albertdera.jpg",
  PERSON_18:
    "https://ucarecdn.com/cbf7ea2c-847a-4b32-8de3-86b2ac0cff48/coreymotta.jpg",
  PERSON_19:
    "https://ucarecdn.com/971713e3-6424-4252-84ee-d677767b4957/freestocksorg.jpg",
  PERSON_20:
    "https://ucarecdn.com/114f15fa-df96-4f1e-9c11-23484c8646ec/thesollers.jpg",
  PERSON_21:
    "https://ucarecdn.com/6158fe80-d25b-466a-897a-ec4f241c23a6/miguelruna.jpg",
  PERSON_22:
    "https://ucarecdn.com/dedddd26-eeb9-4ab7-9ac2-665024e3bc14/princeakachi.jpg",
  PERSON_23:
    "https://ucarecdn.com/2416ffe0-dedc-4b16-b297-b15e1f8e1149/sethdoyle.jpg",
  PERSON_JOHN_RUSH:
    "https://ucarecdn.com/4237612d-190c-42b6-b817-cdffeb444cb2/john_rush.jpeg",


  PHOTO_01:
    "https://ucarecdn.com/64ece1b9-e6f3-430e-8501-7f54a2b5581f/alexanderandrewsfsH1KjbdjE8unsplash.jpg",
  PHOTO_02:
    "https://ucarecdn.com/5ac37e0a-8f19-4452-a50c-7179a565859c/photo1532450622434f7751ee89ee1.png",
  PHOTO_03:
    "https://ucarecdn.com/e6f75832-67a1-4581-964c-dc3fef86cf92/photo149595438065501609180eda3.png",
  PHOTO_04:
    "https://ucarecdn.com/13304a27-1aa1-4203-b994-9c89e65a2874/photo1524721696987b9527df9e512.jpeg",
  PHOTO_05:
    "https://ucarecdn.com/d0f0b0fe-f94b-4aa8-bb9c-cc82751eed8f/photo1512799906445d591d53082c0.jpeg",
  PHOTO_06:
    "https://ucarecdn.com/011e3fa9-bcbd-4049-a007-4acf11187f7c/photo1532012197267da84d127e765.jpeg",
  PHOTO_07:
    "https://ucarecdn.com/c446c4c3-6ca4-4a8d-aadf-cd9d16960cac/photo1519260621058467ee6ae40a8.jpeg",
  PHOTO_08:
    "https://ucarecdn.com/ceb84f6c-562c-4e00-b585-d60cbf22ba4a/photo1510255182624e9d7a4647bea.jpg",

  ABSTRACT_01:
    "https://ucarecdn.com/383349b3-0799-4413-8e40-918e8f2e851e/photo15331583263397f3cf2404354.jpg",
  ABSTRACT_02:
    "https://ucarecdn.com/415007de-44eb-47ce-a2de-0f82694290a6/photo15238956659367bfe172b757d.jpg",
  ABSTRACT_03:
    "https://ucarecdn.com/d6a56c6e-8abe-4eaf-81e5-954eff814092/photo1535370976884f4376736ab06.jpg",
  ABSTRACT_04:
    "https://ucarecdn.com/6dcbce2c-e03b-421c-bcec-de382b410416/photo1483959651481dc75b89291f1.jpg",
  ABSTRACT_05:
    "https://ucarecdn.com/8d25a862-aa71-413f-ae9a-a91bf04c90b9/photo1618005182384a83a8bd57fbe.jpg",

  UNICORN_AI:
    "https://ucarecdn.com/5c5210a3-7fa0-45b4-889e-d1d1708a3532/unicorn_ai.png",
};

export const COMPONENT_THUMBNAILS_CDN = [
  "https://ucarecdn.com/4d229862-3365-4c9d-863c-e79b063cb9e0/uni_thumb_video_03.jpg",
  "https://ucarecdn.com/865b185b-d459-476b-8d6b-892655af6c03/uni_thumb_video_02.jpg",
  "https://ucarecdn.com/0a93fef6-cc04-46f1-9126-8bab27b47fcc/uni_thumb_video_04.jpg",
  "https://ucarecdn.com/6cf8f3e8-ac22-490b-ad77-ea3e44fc700e/uni_thumb_video_01.jpg",
  "https://ucarecdn.com/56b9e957-8ea2-41d4-839f-c46de117cb78/uni_thumb_text_07.jpg",
  "https://ucarecdn.com/3364e485-c3a7-42d5-8a9d-da0d954e9a13/uni_thumb_text_08.jpg",
  "https://ucarecdn.com/8afa7894-ad95-4bcc-aa4e-a6018f2077bc/uni_thumb_text_06.jpg",
  "https://ucarecdn.com/5c7d4a5c-a91a-4ff2-bcb3-6d85d77f5e34/uni_thumb_text_02.jpg",
  "https://ucarecdn.com/b1a11f01-484e-4374-83c7-a7368474f2ac/uni_thumb_text_01.jpg",
  "https://ucarecdn.com/6635be4a-89a3-447e-ac66-8cf7df44063f/uni_thumb_testimonials_05.jpg",
  "https://ucarecdn.com/13601755-fd41-46f3-b5c2-82ff5409dfba/uni_thumb_testimonials_06.jpg",
  "https://ucarecdn.com/1ae9d483-7240-4a00-a357-cb8efa227eef/uni_thumb_testimonials_04.jpg",
  "https://ucarecdn.com/166f1fbd-7831-4bf6-b9f1-e938b8c9516b/uni_thumb_text_03.jpg",
  "https://ucarecdn.com/418409ad-8cb0-48c5-9aae-b0c5bb50f9cc/uni_thumb_text_04.jpg",
  "https://ucarecdn.com/f563ccdc-21ab-4267-bc4b-c42abcc47b01/uni_thumb_text_05.jpg",
  "https://ucarecdn.com/8d0709e7-1771-4b56-aaca-d13ba8a51a49/uni_thumb_testimonials_03.jpg",
  "https://ucarecdn.com/ffbbd21d-1187-48e0-a454-fd38ca5fe9a8/uni_thumb_testimonials_02.jpg",
  "https://ucarecdn.com/3a6a0a9d-02cc-4dde-9707-b93f70701287/uni_thumb_testimonials_01.jpg",
  "https://ucarecdn.com/b5935bd2-28c7-4cef-af84-ad4969ab57cd/uni_thumb_team_05.jpg",
  "https://ucarecdn.com/fb1eb94c-2cea-4e49-8872-94f3b05d1f35/uni_thumb_tabs_10.jpg",
  "https://ucarecdn.com/bbcd278d-f7cf-4d93-96f3-c671a46582ac/uni_thumb_team_04.jpg",
  "https://ucarecdn.com/61659be8-68ab-483b-af78-e45ede195d57/uni_thumb_tabs_04.jpg",
  "https://ucarecdn.com/1a10dcac-3c0a-404a-9259-7c459d2abb55/uni_thumb_tabs_03.jpg",
  "https://ucarecdn.com/0cf18839-844f-4767-b932-387793570651/uni_thumb_team_03.jpg",
  "https://ucarecdn.com/270e348f-b6e9-49a7-b8b8-625c201c9707/uni_thumb_team_02.jpg",
  "https://ucarecdn.com/22fc29c1-9d0d-4581-b855-7dad4a9474ea/uni_thumb_team_01.jpg",
  "https://ucarecdn.com/6b40bc92-114f-44cd-ad9e-d4078208b7c0/uni_thumb_tabs_02.jpg",
  "https://ucarecdn.com/7338d350-3de8-4d9c-acc9-1747db8fa177/uni_thumb_tabs_01.jpg",
  "https://ucarecdn.com/13f05a37-4a6a-468f-a081-778848de0362/uni_thumb_steps_02.jpg",
  "https://ucarecdn.com/61d61fa1-5345-438c-8c20-5b92117c33dd/uni_thumb_steps_03.jpg",
  "https://ucarecdn.com/63c0f7e9-fe6f-43cb-97ca-d7ffe0fedc41/uni_thumb_steps_01.jpg",
  "https://ucarecdn.com/7a6265f0-280d-49d4-9b73-1cf46a19e72d/uni_thumb_slider_04.jpg",
  "https://ucarecdn.com/4be2ed94-749f-49e9-b20e-48ac23dbf215/uni_thumb_slider_10.jpg",
  "https://ucarecdn.com/e1b3d5c8-b877-4ebd-863b-8fc1a69a9841/uni_thumb_slider_06.jpg",
  "https://ucarecdn.com/87679d84-1591-41e8-a6d8-6e09cea96d2d/uni_thumb_slider_05.jpg",
  "https://ucarecdn.com/eb432cfd-3f14-4f86-8912-04f3663aa20b/uni_thumb_slider_03.jpg",
  "https://ucarecdn.com/cefc0b84-62e9-4c18-88f3-64649cce2759/uni_thumb_slider_07.jpg",
  "https://ucarecdn.com/0159f339-d9d7-4ef1-b856-4a53d4ea42b1/uni_thumb_roadmap_02.jpg",
  "https://ucarecdn.com/58f56f20-981f-4fba-9942-b4e4d5f6aba8/uni_thumb_slider_02.jpg",
  "https://ucarecdn.com/13e7870f-3590-4000-a07f-c0d5fef91d20/uni_thumb_slider_01.jpg",
  "https://ucarecdn.com/fc7a9a91-161c-4a6c-ba02-8492bc620939/uni_thumb_roadmap_01.jpg",
  "https://ucarecdn.com/c469ca2e-975b-4bf1-ba69-b2466fd8c148/uni_thumb_pricing_01.jpg",
  "https://ucarecdn.com/aa054a95-6d1f-4b1f-b9ae-568e85eb8a58/uni_thumb_pricing_02.jpg",
  "https://ucarecdn.com/7b4d1d7f-ce2b-498e-90be-377a9124538d/uni_thumb_press_03.jpg",
  "https://ucarecdn.com/d181102b-0308-4f36-9d33-0dd1d7f66e62/uni_thumb_pricing_05.jpg",
  "https://ucarecdn.com/78a7116c-57c3-4162-b87c-d11e0d043dd9/uni_thumb_press_02.jpg",
  "https://ucarecdn.com/efc8ad55-3932-4ecf-b7bb-3f14306892fb/uni_thumb_pricing_04.jpg",
  "https://ucarecdn.com/023c34cc-8517-435a-b1f1-0239235ac91b/uni_thumb_press_01.jpg",
  "https://ucarecdn.com/201a6ac6-2130-4a5b-aacb-e2e19bad6420/uni_thumb_pricing_03.jpg",
  "https://ucarecdn.com/836ce0f9-0fb2-4e0e-8ced-6be3e5b6ba87/uni_thumb_posts_06.jpg",
  "https://ucarecdn.com/8f9d78d6-e2ed-4981-89d6-a0c7cbe44c31/uni_thumb_posts_05.jpg",
  "https://ucarecdn.com/f03ad03a-2b0c-4844-8c82-abe5b557509a/uni_thumb_posts_04.jpg",
  "https://ucarecdn.com/03582207-bc6c-41ae-aa9a-784dd69f02f8/uni_thumb_posts_03.jpg",
  "https://ucarecdn.com/e546917a-d266-48f4-87ca-bd2f75960e19/uni_thumb_photos_05.jpg",
  "https://ucarecdn.com/8011f882-dd78-4124-989d-91b6bdc5cc3f/uni_thumb_photos_03.jpg",
  "https://ucarecdn.com/383052ff-30da-4fd0-be08-f4710da90aa8/uni_thumb_posts_02.jpg",
  "https://ucarecdn.com/16d03848-bc88-455b-af01-6f54aa831919/uni_thumb_photos_04.jpg",
  "https://ucarecdn.com/17f65f11-a0d8-49de-b4e8-7ae1964451b2/uni_thumb_posts_01.jpg",
  "https://ucarecdn.com/a6c1c5e6-8257-4ae6-91f4-48ab43bd5727/uni_thumb_popups_01.jpg",
  "https://ucarecdn.com/1406da13-aad8-4fe5-9151-c37022a734ac/uni_thumb_photos_02.jpg",
  "https://ucarecdn.com/6ee5387f-a017-4934-b290-fc7a68a6c3af/uni_thumb_photos_01.jpg",
  "https://ucarecdn.com/c1d2eaff-4ca9-49b7-a0e3-2c1a706ae9ad/uni_thumb_links_06.jpg",
  "https://ucarecdn.com/b9a6d0fe-ead6-488b-a3f2-582cb7fb89a0/uni_thumb_other_01.jpg",
  "https://ucarecdn.com/606ca28d-be0d-4d3a-96ca-3f698769f271/uni_thumb_links_05.jpg",
  "https://ucarecdn.com/ab885478-8da4-48b9-bf99-6b7f02d43725/uni_thumb_links_04.jpg",
  "https://ucarecdn.com/2dcc50e2-4377-4c77-bb64-383d2fcc9601/uni_thumb_links_03.jpg",
  "https://ucarecdn.com/318a0827-f502-4e26-89d1-c1ce9527de51/uni_thumb_links_01.jpg",
  "https://ucarecdn.com/cb73ab55-63cd-4290-b014-2d4017b50cf5/uni_thumb_links_02.jpg",
  "https://ucarecdn.com/e0726690-c508-430e-87c5-eb2596a8dad4/uni_thumb_header_65.jpg",
  "https://ucarecdn.com/7209510c-7e11-49e5-8fac-c33e4195816e/uni_thumb_header_64.jpg",
  "https://ucarecdn.com/d5b3cbdc-13b9-4e0f-ae63-d3a725724d93/uni_thumb_header_63.jpg",
  "https://ucarecdn.com/242d0935-5256-44bd-8ea9-6d3f197ca88b/uni_thumb_header_62.jpg",
  "https://ucarecdn.com/96c2f492-c9dc-4d87-8844-0a994aa248c6/uni_thumb_header_61.jpg",
  "https://ucarecdn.com/6fc2b023-0269-4402-972e-67caca5caf20/uni_thumb_header_60.jpg",
  "https://ucarecdn.com/8ce3ff20-31ad-4487-b45a-8c23d4733716/uni_thumb_header_59.jpg",
  "https://ucarecdn.com/8e12c598-5e28-4153-9512-7653d128e4be/uni_thumb_header_58.jpg",
  "https://ucarecdn.com/6f156566-7ffb-4e0a-bf4b-6133a66db648/uni_thumb_header_54.jpg",
  "https://ucarecdn.com/a43e0e30-3ded-4cff-8696-f4a35b84311c/uni_thumb_header_53.jpg",
  "https://ucarecdn.com/e876ee28-7a0e-4b97-b5e0-e3946f87d092/uni_thumb_header_57.jpg",
  "https://ucarecdn.com/9e8682de-7f99-43e7-a3ff-c0c901f5af7c/uni_thumb_header_56.jpg",
  "https://ucarecdn.com/7183d9b9-9184-409f-9a1f-93d97c68b18f/uni_thumb_header_52.jpg",
  "https://ucarecdn.com/1ebfc5b2-6bf6-491f-a0d9-2c9141cdacfd/uni_thumb_header_55.jpg",
  "https://ucarecdn.com/55ba6dc5-eb98-4ccf-b8d3-2f1209bde240/uni_thumb_header_51.jpg",
  "https://ucarecdn.com/05626ff8-3b24-4d29-aff2-e2d9c41e4613/uni_thumb_header_50.jpg",
  "https://ucarecdn.com/db981929-267e-4f14-ad47-067dcc1beba9/uni_thumb_header_49.jpg",
  "https://ucarecdn.com/0707c493-6d99-4c3d-aef2-48a3d0449abd/uni_thumb_header_48.jpg",
  "https://ucarecdn.com/91f069a4-ba85-4e9d-aeb1-b68367ef4589/uni_thumb_header_46.jpg",
  "https://ucarecdn.com/b6592540-7b84-42e1-b056-c001f51365ba/uni_thumb_header_47.jpg",
  "https://ucarecdn.com/637b48fc-df79-4a54-8f99-85fe5b367a6e/uni_thumb_header_45.jpg",
  "https://ucarecdn.com/47af6855-3697-454c-917d-ef09660cb8ed/uni_thumb_header_44.jpg",
  "https://ucarecdn.com/7e4a2e72-1694-4a8a-9c1e-33117d8f6f02/uni_thumb_header_40.jpg",
  "https://ucarecdn.com/a0a957ff-2b79-43c9-a452-84f40c2f82d0/uni_thumb_header_43.jpg",
  "https://ucarecdn.com/03a70b90-9c15-4d37-9a37-5263600afeff/uni_thumb_header_42.jpg",
  "https://ucarecdn.com/6fd55048-7a21-4079-8284-c69acc9f8f5a/uni_thumb_header_41.jpg",
  "https://ucarecdn.com/d5d3dc69-f07e-4982-846e-d751aef19e81/uni_thumb_header_38.jpg",
  "https://ucarecdn.com/fff24be3-26a5-492a-8eb0-d141a61f8813/uni_thumb_header_39.jpg",
  "https://ucarecdn.com/b18803e9-6088-4d37-873c-df82d3dc3d2b/uni_thumb_header_37.jpg",
  "https://ucarecdn.com/32875dd8-dfff-4a22-9318-f53bd646f52b/uni_thumb_header_35.jpg",
  "https://ucarecdn.com/c2a0c4ff-257c-40ee-ae48-06e44646982b/uni_thumb_header_34.jpg",
  "https://ucarecdn.com/fbb062f5-32bb-4c34-aad9-2f93d4ec3512/uni_thumb_header_33.jpg",
  "https://ucarecdn.com/11257191-4645-46d6-8f06-9245bd49c159/uni_thumb_header_32.jpg",
  "https://ucarecdn.com/f1c45659-1f2c-4bd6-9b44-cb35ec22f0ad/uni_thumb_header_30.jpg",
  "https://ucarecdn.com/8557cd9b-64d3-445d-adf3-adf762e9b59c/uni_thumb_header_36.jpg",
  "https://ucarecdn.com/e635db91-5ee6-4192-9795-385481119983/uni_thumb_header_31.jpg",
  "https://ucarecdn.com/ee77168a-1438-4793-9405-08d38a5ecd13/uni_thumb_header_29.jpg",
  "https://ucarecdn.com/d3bf3dcf-efed-423f-8211-d0808205cc70/uni_thumb_header_28.jpg",
  "https://ucarecdn.com/0a90aa04-ac8c-49bd-9680-33554a6312a9/uni_thumb_header_27.jpg",
  "https://ucarecdn.com/bdb9439a-5033-4e12-a2a0-9333fe4eb355/uni_thumb_header_26.jpg",
  "https://ucarecdn.com/5ebe88a1-1e62-4fe7-a6a7-646717ab1153/uni_thumb_header_23.jpg",
  "https://ucarecdn.com/66c4e3c4-14b8-4250-8c2a-5bf5001a247a/uni_thumb_header_21.jpg",
  "https://ucarecdn.com/8705ecae-7e60-4235-96f6-821812fb4333/uni_thumb_header_22.jpg",
  "https://ucarecdn.com/05eec58a-021b-42e7-8085-4d0fe4dc67d0/uni_thumb_header_20.jpg",
  "https://ucarecdn.com/a36e9f55-c8f4-4d82-9d44-95f488196bad/uni_thumb_header_19.jpg",
  "https://ucarecdn.com/4f98db8e-b88d-4bb4-98a9-c47a5a95cf16/uni_thumb_header_25.jpg",
  "https://ucarecdn.com/6be522ef-a418-440a-b210-791d1c202ee3/uni_thumb_header_18.jpg",
  "https://ucarecdn.com/a8498a53-390d-440c-833b-f1b425f73834/uni_thumb_header_24.jpg",
  "https://ucarecdn.com/807de13c-72af-4792-aaa2-927fa0cc9d88/uni_thumb_header_17.jpg",
  "https://ucarecdn.com/15f8c9a1-9ae2-4b1b-a9d2-c752c61c5f48/uni_thumb_header_16.jpg",
  "https://ucarecdn.com/17c69cff-35bb-4259-a1e4-061e3b6c2145/uni_thumb_header_14.jpg",
  "https://ucarecdn.com/e01e65da-473b-4729-b43e-9b8509a66afc/uni_thumb_header_13.jpg",
  "https://ucarecdn.com/1e0daede-6383-43dd-84b7-64ffed63861c/uni_thumb_header_11.jpg",
  "https://ucarecdn.com/87bc0f74-7098-41d8-9a1a-74e330be2e23/uni_thumb_header_12.jpg",
  "https://ucarecdn.com/150f2e49-b5e1-421b-b095-6e7dddfe7d1c/uni_thumb_header_09.jpg",
  "https://ucarecdn.com/190a462d-0a6e-41ee-acd7-54b970ebd8bb/uni_thumb_header_08.jpg",
  "https://ucarecdn.com/0f3bd71e-59ce-4e77-8805-a20f444cd591/uni_thumb_header_10.jpg",
  "https://ucarecdn.com/bf2e92a0-1013-42c6-873f-eb4eeda73c9d/uni_thumb_header_15.jpg",
  "https://ucarecdn.com/9e69e37c-e264-41ca-805b-2d452873be2e/uni_thumb_header_07.jpg",
  "https://ucarecdn.com/6faa8f1d-a539-47fa-b7ed-b1fdf8b3ddca/uni_thumb_header_06.jpg",
  "https://ucarecdn.com/5d55e9ad-b19c-455d-bd73-3f9a1c8e8708/uni_thumb_header_05.jpg",
  "https://ucarecdn.com/b3e7f986-1c33-4e37-ad80-54741e2c4e5c/uni_thumb_header_01.jpg",
  "https://ucarecdn.com/2a9fad6e-4203-46c5-bfbf-09c1485df1ba/uni_thumb_footer_04.jpg",
  "https://ucarecdn.com/9a3c427e-eb8b-4dd9-8e19-8004cba2fe5a/uni_thumb_footer_03.jpg",
  "https://ucarecdn.com/fbeed743-06f3-464b-b779-47b80e8b4120/uni_thumb_header_03.jpg",
  "https://ucarecdn.com/3adc26dd-2847-4d67-9797-80d9f0299256/uni_thumb_header_04.jpg",
  "https://ucarecdn.com/d39716b2-5bc7-4960-91c7-7ffe58d0e36e/uni_thumb_features_17.jpg",
  "https://ucarecdn.com/e9388591-fb72-4003-ab03-ceff4bea875d/uni_thumb_header_02.jpg",
  "https://ucarecdn.com/14a46de5-30d9-42bf-b501-0bc59a2d7c0e/uni_thumb_footer_01.jpg",
  "https://ucarecdn.com/d8d9427d-26dc-444a-a516-9d693df15dfb/uni_thumb_footer_02.jpg",
  "https://ucarecdn.com/1436442c-e819-4e08-ac8d-e9f138cd92c9/uni_thumb_features_14.jpg",
  "https://ucarecdn.com/7ed37ae2-fdb9-4725-838d-94363d54a8cd/uni_thumb_features_12.jpg",
  "https://ucarecdn.com/922ed6c2-83db-4345-87af-bff4413b86a9/uni_thumb_features_11.jpg",
  "https://ucarecdn.com/ca2f9b05-5892-4872-8794-cc44be589be5/uni_thumb_features_10.jpg",
  "https://ucarecdn.com/73cfd192-a016-4b4f-9e35-12787aa3586a/uni_thumb_features_09.jpg",
  "https://ucarecdn.com/032878ae-11e6-4f19-a86f-e36fc1f5bf7d/uni_thumb_features_08.jpg",
  "https://ucarecdn.com/3d28b38c-8908-413e-bacc-1b7b46e196d4/uni_thumb_features_13.jpg",
  "https://ucarecdn.com/b2dedd8d-f4b9-4736-bde2-1776e64ac70d/uni_thumb_features_07.jpg",
  "https://ucarecdn.com/f53490f7-6745-4fe7-a18f-9594aebaf5ae/uni_thumb_features_06.jpg",
  "https://ucarecdn.com/7856670f-e613-4d29-9a60-f75a7475e960/uni_thumb_features_05.jpg",
  "https://ucarecdn.com/83e273c3-9163-4aa7-8623-42df0aba25f1/uni_thumb_features_04.jpg",
  "https://ucarecdn.com/53d67929-a1c2-41da-8464-636db8948cf0/uni_thumb_faq_05.jpg",
  "https://ucarecdn.com/ed653c86-23e5-418b-9eba-82c4132fd953/uni_thumb_faq_02.jpg",
  "https://ucarecdn.com/26e821e1-8dd1-4001-b90c-77783dac7937/uni_thumb_features_01.jpg",
  "https://ucarecdn.com/d5606977-61a6-4940-9f98-8f4ddd2d1781/uni_thumb_faq_04.jpg",
  "https://ucarecdn.com/3422e8e9-5d67-4d51-a2f1-221e76337179/uni_thumb_features_03.jpg",
  "https://ucarecdn.com/02c9b2cd-ce8c-4e18-b815-5de337460f04/uni_thumb_features_02.jpg",
  "https://ucarecdn.com/9b9f7cc5-ef5a-470b-b088-9d5a4c87825f/uni_thumb_faq_03.jpg",
  "https://ucarecdn.com/6583ba3d-544d-4ab6-b54b-c8b9213b3347/uni_thumb_faq_01.jpg",
  "https://ucarecdn.com/491c6137-aadd-4ea2-83c5-df741b4ef52b/uni_thumb_cta_form_11.jpg",
  "https://ucarecdn.com/f983540e-a303-41d7-87d0-a67303c17769/uni_thumb_cta_form_08.jpg",
  "https://ucarecdn.com/e4167523-5240-463a-bec4-38f3d5d0f8a3/uni_thumb_cta_form_06.jpg",
  "https://ucarecdn.com/c43c458c-e5f6-48e8-9f4f-3596319d36ce/uni_thumb_cta_form_07.jpg",
  "https://ucarecdn.com/b548c136-90dc-43ca-b8fe-3635186ab453/uni_thumb_cta_form_05.jpg",
  "https://ucarecdn.com/43da9478-c524-4eb8-ba6b-135fb4cea2ba/uni_thumb_cta_form_03.jpg",
  "https://ucarecdn.com/6fcc41f4-cd97-4ae1-be2c-5bf4f19a2d89/uni_thumb_cta_form_04.jpg",
  "https://ucarecdn.com/be8c5d61-5689-4475-aabf-0b114a3b964c/uni_thumb_cta_form_02.jpg",
  "https://ucarecdn.com/5ab17e9a-239b-4b29-a0b2-ae29e756a5ec/uni_thumb_cta_form_01.jpg",
  "https://ucarecdn.com/ad919658-9d5b-4cd1-982b-252e5e0e657f/uni_thumb_cta_button_31.jpg",
  "https://ucarecdn.com/b110ac9c-5415-4c32-970d-514de3f1f37a/uni_thumb_cta_button_30.jpg",
  "https://ucarecdn.com/12bada03-8bfd-4f8f-b51b-6ce5f076938b/uni_thumb_cta_button_28.jpg",
  "https://ucarecdn.com/ebdb28e3-320a-4e87-8400-e6244fb63cad/uni_thumb_cta_button_29.jpg",
  "https://ucarecdn.com/7ecc91f5-43cd-493d-a1f6-494d7045d53b/uni_thumb_cta_button_27.jpg",
  "https://ucarecdn.com/5da1b3f4-7b39-4a7c-9c3a-5579e6e86e7a/uni_thumb_cta_button_26.jpg",
  "https://ucarecdn.com/fd85342b-38b1-49c8-a60b-edf711db58f4/uni_thumb_cta_button_25.jpg",
  "https://ucarecdn.com/e6647071-6b0a-46ea-b223-162d0de2ad47/uni_thumb_cta_button_22.jpg",
  "https://ucarecdn.com/91db0150-42de-41ab-85ed-0a31e07abae9/uni_thumb_cta_button_23.jpg",
  "https://ucarecdn.com/57b868a6-9a4f-4af9-869a-98b2a9056223/uni_thumb_cta_button_20.jpg",
  "https://ucarecdn.com/a40a87fe-8593-4627-abe3-ba394ef43b02/uni_thumb_cta_button_21.jpg",
  "https://ucarecdn.com/099fe633-dfb8-40b5-8fc2-4efc0c6c14f5/uni_thumb_cta_button_18.jpg",
  "https://ucarecdn.com/ccb7efac-ac28-49d5-bc86-102ea49a61c4/uni_thumb_cta_button_19.jpg",
  "https://ucarecdn.com/eece4612-a0a7-4015-8433-5a1e78274c03/uni_thumb_cta_button_17.jpg",
  "https://ucarecdn.com/6b853c6c-483b-4dc5-a901-4a1afbb6c4ee/uni_thumb_cta_button_24.jpg",
  "https://ucarecdn.com/db1120a8-f12c-45e6-8bbf-5a6a9c8a3d81/uni_thumb_cta_button_16.jpg",
  "https://ucarecdn.com/8e911460-1a39-4128-b5c2-c9b67f624a15/uni_thumb_cta_button_15.jpg",
  "https://ucarecdn.com/f41e2783-03df-4bc3-adc5-1afffdbff636/uni_thumb_cta_button_12.jpg",
  "https://ucarecdn.com/f4f36385-f68a-4c7c-8b41-ef1784bee1d5/uni_thumb_cta_button_09.jpg",
  "https://ucarecdn.com/828d5419-e5eb-4338-9b9d-ea2543cb33eb/uni_thumb_cta_button_11.jpg",
  "https://ucarecdn.com/a01806ee-a80f-4f41-842d-6da9cd88b5c2/uni_thumb_cta_button_10.jpg",
  "https://ucarecdn.com/3a599600-c91b-4ba7-9d0e-fbbd944019f5/uni_thumb_cta_button_14.jpg",
  "https://ucarecdn.com/5b8d319e-33ec-4169-b557-d6b58d355ec1/uni_thumb_cta_button_13.jpg",
  "https://ucarecdn.com/3ca9604f-43cf-45d3-996b-c57157f70cd1/uni_thumb_cta_button_07.jpg",
  "https://ucarecdn.com/8d40f19d-8af4-4070-9ba9-2f9caece9062/uni_thumb_cta_button_08.jpg",
  "https://ucarecdn.com/0c3e86ce-7613-4fcf-8389-977d60a2ed1d/uni_thumb_cta_button_06.jpg",
  "https://ucarecdn.com/70203479-26e4-498e-9e79-bfac97583f5f/uni_thumb_cta_button_04.jpg",
  "https://ucarecdn.com/0bf59a54-e639-41d9-8a2a-a4104c2c1cc5/uni_thumb_cta_button_01.jpg",
  "https://ucarecdn.com/b36680a2-c0b4-4b2c-8d01-205e25c88184/uni_thumb_clients_02.jpg",
  "https://ucarecdn.com/85463a88-943b-4ca5-b562-58993298474b/uni_thumb_clients_03.jpg",
  "https://ucarecdn.com/d0c87ad4-8312-49b3-ab1a-3af554aa28ed/uni_thumb_code_01.jpg",
  "https://ucarecdn.com/773e7c9b-4e34-4da7-a536-f4c2221c4a45/uni_thumb_contact_01.jpg",
  "https://ucarecdn.com/0acc2dfb-fae4-4342-94cf-794c1ade2ea5/uni_thumb_code_02.jpg",
  "https://ucarecdn.com/83e3a5ff-329e-40bc-9eeb-1934129145c6/uni_thumb_cta_button_02.jpg",
  "https://ucarecdn.com/98335112-ac60-4bbd-861f-b189594dd956/uni_thumb_clients_01.jpg",
  "https://ucarecdn.com/fb43574c-bed2-40de-befa-c768f5f36852/uni_thumb_contact_02.jpg",
  "https://ucarecdn.com/3e717d6e-722d-49df-9af0-536bc9646772/uni_thumb_cta_button_03.jpg",
  "https://ucarecdn.com/3a7583a6-f766-4d32-b753-567d32542aa6/uni_thumb_clients_02.jpg",
  "https://ucarecdn.com/6ff99ece-29e5-4b70-91fc-404c4d672634/uni_thumb_clients_01.jpg",
  "https://ucarecdn.com/e397d0cf-87c4-4c28-9cc5-c954d3a76abd/uni_thumb_clients_03.jpg",
  "https://ucarecdn.com/7dfc74d2-1f03-4e4d-b402-d0cc308717b0/uni_thumb_clients_01.jpg",
  "https://ucarecdn.com/d9b2daa0-087a-4197-b43a-455c38b6c130/uni_thumb_directory_01.jpg",
  "https://ucarecdn.com/9002a421-4a97-466f-bd73-1dac9231edee/uni_thumb_directory_02.jpg",
  "https://ucarecdn.com/fc6bf6fc-677c-49cb-bacb-98f4714a0592/uni_thumb_directory_03.jpg",
  "https://ucarecdn.com/b797dc12-0aa7-4611-a98f-31323a30f24c/uni_thumb_other_02.jpg",
  "https://ucarecdn.com/27fb0693-9ee6-4594-8ca8-d90cd1244084/uni_thumb_other_03.jpg",
  "https://ucarecdn.com/77f7c265-3059-4764-bbe4-ee4284cc0995/uni_thumb_other_04.jpg",
];
