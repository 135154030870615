import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import StepDescriptionField from "./StepDescriptionField";
import StepSubtitleField from "./StepSubtitleField";
import StepTitleField from "./StepTitleField";

interface Props {
  item: any;
  keyProp: number;
  saveContentInStore: any;
  generator: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  toggleNewEditionsDetected: (value: boolean) => void;
  textEditorType: string;
  revertToPlainHTML: () => void;
}

const StepTextSection = (props: Props): JSX.Element => {
  const {
    item,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
    textEditorType,
    revertToPlainHTML,
  } = props;

  return (
    <div className="content-input__group">
      <StepTitleField
        item={item}
        keyProp={keyProp}
        saveContentInStore={saveContentInStore}
        generator={generator}
        contentType={contentType}
        currentInputData={currentInputData}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
      />
      <StepSubtitleField
        item={item}
        keyProp={keyProp}
        saveContentInStore={saveContentInStore}
        generator={generator}
        contentType={contentType}
        currentInputData={currentInputData}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
      />
      <StepDescriptionField
        item={item}
        keyProp={keyProp}
        textEditorType={textEditorType}
        revertToPlainHTML={revertToPlainHTML}
        saveContentInStore={saveContentInStore}
        generator={generator}
        contentType={contentType}
        currentInputData={currentInputData}
        toggleNewEditionsDetected={toggleNewEditionsDetected}
      />
    </div>
  );
};

export default StepTextSection;
