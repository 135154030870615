import { GraphicsOptions } from "../components/editor/component_graphics/data/componentGraphicsData";
import { callToActionOptions } from "./callToActionOptions";

// This const declares possible options for a ContentInput of a component. E.g., a header can have a CTA buttons or CTA form. Or a header can have a video or an images a graphics. Some new types can be added in the future to this list.
// Also please note that there may be exceptions. Let's say, a header-23 may do NOT have a video graphics option.

// Important! It muse be obvious that we MUST NEVER delete available options from the lists once added, because users may already used it somewhere and it will break their UX.
export const availableOptions: any = {
  nav: {
    //type of a component
    cta: {
      //type of a field (ContentInput)
      default: [callToActionOptions.buttons, callToActionOptions.form], // if we don't specify a specific nav, use the default value for all navs. Same for other components
    },
  },
  header: {
    cta: {
      //type of a field (ContentInput)
      // '01': [callToActionOptions.buttons], // example of how to add exceptions for specific components
      default: [callToActionOptions.buttons, callToActionOptions.form], // if we don't specify a specific header, use the default value for all headers
    },
    graphics: {
      default: [GraphicsOptions.image],
    },
  },
  cta_button: {
    cta: {
      default: [callToActionOptions.buttons],
    },
  },
  text: {
    cta: {
      default: [callToActionOptions.buttons],
    },
  },
  cta_form: {
    cta: {
      default: [callToActionOptions.form],
    },
  },
  pricing: {
    cta: {
      default: [callToActionOptions.buttons],
    },
  },
  photos: {
    graphics: {
      default: [GraphicsOptions.image],
    },
    cta: {
      default: [callToActionOptions.buttons],
    },
  },
  video: {
    graphics: {
      default: [GraphicsOptions.video],
    },
    cta: {
      default: [callToActionOptions.buttons],
    },
  },
  tabs: {
    graphics: {
      default: [GraphicsOptions.image],
    },
    cta: {
      default: [callToActionOptions.buttons], // Good to have: [callToActionOptions.buttons, callToActionOptions.form]
    },
  },
  slider: {
    graphics: {
      default: [GraphicsOptions.image],
    },
    cta: {
      default: [callToActionOptions.buttons], // Good to have: [callToActionOptions.buttons, callToActionOptions.form]
    },
  },
  popups: {
    cta: {
      default: [callToActionOptions.buttons, callToActionOptions.form],
    },
  },
};
