export const logoOptions = {
    'press': {
        title: 'press',
        humanTitle: 'Pressq'
    },
    'tech': {
        title: 'tech',
        humanTitle: 'Tech'
    },
    'companies': {
        title: 'companies',
        humanTitle: 'Companies'
    },
    'uploaded': {
        title: 'uploaded',
        humanTitle: 'Upload'
    }
};