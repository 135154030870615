import React, { useEffect, useRef } from "react";
import { Input } from "antd";

interface Props {
  currentColor: string;
  setCurrentColor: React.Dispatch<React.SetStateAction<string>>;
  isPopconfirmOpened: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
}

const Name = (props: Props): JSX.Element => {
  const {
    currentColor,
    setCurrentColor,
    isPopconfirmOpened,
    handleConfirm,
    handleCancel,
  } = props;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentColor(event.target.value);
  };

  const inputRef = useRef<Input>(null);

  useEffect(() => {
    if (isPopconfirmOpened) {
      inputRef.current.select();
    }
  }, [isPopconfirmOpened]);

  const handleOnKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleConfirm();
    }
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  return (
    <Input
      size="small"
      addonBefore="#"
      onChange={handleOnChange}
      value={currentColor}
      ref={inputRef}
      onKeyDown={handleOnKeyDown}
      placeholder="FFFFFF"
    />
  );
};

export default Name;
