import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import templatePluginsInitializerClass from "../../../../helpers/editor/templatePluginsInitializerClass";
import { GraphicsOptions } from "../../component_graphics/data/componentGraphicsData";
import ImagePicker from "../../ImagePicker";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  item: any;
  saveContentInStore: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: (value: boolean) => void;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
}

const GalleryImageInput = (props: Props): JSX.Element => {
  const {
    item,
    saveContentInStore,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
    saveImageInfoInDB,
    removeImageFromDB,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const generator = new generateContentDataObject();

  if (!currentInputDataRef.current.contentHasGraphic) return null;

  return (
    <div className="content-input__input">
      {/*// '0' for currentImgSrc and currentImgUUID is because we currently support only one item per graphic object inside a gallery item ATM.*/}
      <ImagePicker
        currentImgAlt={item.contentGraphic[0][GraphicsOptions.image].alt}
        saveImageAltInReduxStore={(newAlt: string) => {
          saveContentInStore(
            generator.changeGalleryImageAlt(
              {
                [contentType]: currentInputDataRef.current,
              },
              newAlt,
              keyProp,
              0
            )
          );

          toggleNewEditionsDetected(true);
        }}
        currentImgSrc={item.contentGraphic[0][GraphicsOptions.image].url}
        currentImgUUID={item.contentGraphic[0][GraphicsOptions.image].uuid}
        inputId={contentType}
        saveImageInfoInDB={saveImageInfoInDB}
        removeImageFromDB={removeImageFromDB}
        saveImageInReduxStore={
          // The last argument here is '0' because we currently support only one item per graphic object inside a gallery item ATM.
          (newSrc: string, newUUID: any, width: any, height: any) => {
            saveContentInStore(
              generator.changeGalleryImageUrl(
                {
                  [contentType]: currentInputDataRef.current,
                },
                newSrc,
                keyProp,
                0
              )
            );
            saveContentInStore(
              generator.changeGalleryImageUUID(
                {
                  [contentType]: currentInputDataRef.current,
                },
                newUUID,
                keyProp,
                0
              )
            ); //'' for no image

            saveContentInStore(
              generator.changeGalleryImageWidth(
                {
                  [contentType]: currentInputDataRef.current,
                },
                width,
                keyProp,
                0
              )
            );
            saveContentInStore(
              generator.changeGalleryImageHeight(
                {
                  [contentType]: currentInputDataRef.current,
                },
                height,
                keyProp,
                0
              )
            );

            toggleNewEditionsDetected(true);

            setTimeout(() => {
              // We do not know when the new image will be loaded to re-calc. So we just wait a little (2 sec).
              templatePluginsInitializerClass.setAllTabs();
            }, 2000);
          }
        }
      />
    </div>
  );
};

export default GalleryImageInput;
