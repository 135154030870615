import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import getLightboxClassname from "../../helpers/editor/getLightboxClassname";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}

class Steps03 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "4 Simple Steps"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));

            this.props.saveContentInStore(this.generator.setUpSteps({
              isDefaultEmpty,
                hasTitle: true,
                hasSubtitle: false,
                hasText: false,
                hasButton: false,
                hasImage: true,

                maxItems: 5,
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                buttons: [
                    // ghost object
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        buttonColor: buttonStyles.ghost.accentOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'View'
                    })
                ],
                priority: 200,
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts

        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let steps = this.props.componentItem.componentData.steps.content.items;

        let stepsItemsCount = steps.length;

        let sizeClassname = '';
        if(stepsItemsCount === 5){
            sizeClassname = 'steps-03__item--1-5';
        }else if(stepsItemsCount === 4){
            sizeClassname = 'steps-03__item--1-4';
        }else if(stepsItemsCount === 3){
            sizeClassname = 'steps-03__item--1-3';
        }else if(stepsItemsCount === 2){
            sizeClassname = 'steps-03__item--1-2';
        }else{
            sizeClassname = 'steps-03__item--1-2';
        }

        return (
            <section>
                <div className="steps-03">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container">
                        <ul className="steps-03__list">
                            {steps && steps.map((item:any, key:number) => {
                                let lightBoxClassname = getLightboxClassname(item.graphics.image.width);
                                let isLastItem = stepsItemsCount === (key + 1);

                                if(item !== undefined){
                                    return (
                                        <li className={"steps-03__item " + sizeClassname} key={'steps-03-' + key}>
                                            <div className="steps-03__img_box">
                                                <img className={"steps-03__img " + lightBoxClassname}
                                                     data-width={item.graphics.image.width ? item.graphics.image.width : 1440}
                                                     data-height={item.graphics.image.height ? item.graphics.image.height : 900}
                                                     src={formatImageUrl(item.graphics.image.url)}
                                                />
                                                {isLastItem === false && <div className="steps-03__icon">
                                                    <span className="icon">
                                                        <svg
	                                                        viewBox="0 0 13 10" xmlns="http://www.w3.org/2000/svg"><path
	                                                        d="M12.823 4.164L8.954.182a.592.592 0 0 0-.854 0 .635.635 0 0 0 0 .88l2.836 2.92H.604A.614.614 0 0 0 0 4.604c0 .344.27.622.604.622h10.332L8.1 8.146a.635.635 0 0 0 0 .88.594.594 0 0 0 .854 0l3.869-3.982a.635.635 0 0 0 0-.88z"
	                                                        fillRule="nonzero" fill="#00396B"
	                                                        className="fill-main"/>
                                                        </svg>
                                                    </span>
												</div>}
                                            </div>

                                            <div className={"content-text steps-03__text " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: item.title}}/>
                                        </li>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </ul>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Steps03;
