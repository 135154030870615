import { addChatMessage } from "../actions";
import { AddChatMessagePayload, ChatMessage, GptState } from "../types";

export const addChatMessageWithDelay = (
  message: AddChatMessagePayload,
  delay: number = 0
) => {
  return (dispatch, getState) => {
    const onTimeout = () => {
      dispatch(addChatMessage(message));
    };
    return new Promise<ChatMessage>((resolve) => {
      setTimeout(() => {
        onTimeout();
        const gptState: GptState = getState().gpt;
        const currentChat = gptState.chats.find(
          (chat) => chat.pageId === message.pageId
        );
        const lastMessage =
          currentChat.chatMessages[currentChat.chatMessages.length - 1];
        resolve(lastMessage);
      }, delay);
    });
  };
};
