import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Team03 extends Component<Props> {
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;

        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if (this.props.checkIsEmptyContent()) {
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Well hello there!"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: 'Hi and nice to meet you! This is our brave team. We work very hard to provide an awesome experience for you.'}));
            this.props.saveContentInStore(this.generator.setUpTeamMembers(
                {
                  isDefaultEmpty,
                    hasPhoto: true,
                    hasName: true,
                    hasJobTitle: true,
                    hasUsername: true,
                    hasSocialNetworks: false,
                    hasHref: true,
                    hasInfo: false,

                    items: [
                        this.generator.createTeamMemberContentObject(),
                        this.generator.createTeamMemberContentObject({
                          jobTitle: "Founder",
                          name: "Alexander Isora",
                          username: "@alexanderisora",
                          graphics: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PERSON_1,
                            width: 400,
                            height: 400,
                          }),
                          info: "Alexander is passionate about web, tech and the startup industry.",
                        }),
                        this.generator.createTeamMemberContentObject(),
                        this.generator.createTeamMemberContentObject({
                          jobTitle: "Founder",
                          name: "Alexander Isora",
                          username: "@alexanderisora",
                          graphics: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PERSON_1,
                            width: 400,
                            height: 400,
                          }),
                          info: "Alexander is passionate about web, tech and the startup industry.",
                        }),
                        this.generator.createTeamMemberContentObject(),
                        this.generator.createTeamMemberContentObject({
                          jobTitle: "Founder",
                          name: "Alexander Isora",
                          username: "@alexanderisora",
                          graphics: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PERSON_1,
                            width: 400,
                            height: 400,
                          }),
                          info: "Alexander is passionate about web, tech and the startup industry.",
                        }),
                        this.generator.createTeamMemberContentObject(),
                    ]
                }
            ));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the team component to tell more about your company.",
                    default: "Add a button to the bottom of the team component to tell more about your company.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render() {
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let team = this.props.componentItem.componentData.team.content.items;

        return (
            <section>
                <div className="team-03">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--large">
                        <ul className="team-03__list">
                            {team && team.map((teamMember: any, teamMemberKey: number) => {
                                let teamItemJSX = (
                                    <li className="sh-2 card-container team-03__person" key={'team-03-' + teamMemberKey}>
                                        <div className="team-03__person_box">
                                            <div className="team-03__person_top">
                                                {teamMember && teamMember.graphics && teamMember.graphics.image && teamMember.graphics.image.url !== '' && <div className="team-03__person_img_box"><img className="team-03__person_img" src={formatImageUrl(teamMember.graphics.image.url)} alt={teamMember.graphics.image.alt}/></div>}
                                                <div className="team-02__person_name_box">
                                                    {teamMember.name !== '' && <div className="def-14 title-text--inner team-03__person_name color-secondary">{teamMember.name}</div>}
                                                    {teamMember.username !== '' && teamMember.href !== '' && <a className="content-text def-14 team-03__person_mention"
													                                                            href={returnHref(teamMember.href)}>{teamMember.username}</a>}
                                                </div>
                                            </div>
                                            {teamMember.jobTitle !== '' && <div className="content-text def-14 team-03__person_title color-main">{teamMember.jobTitle}</div>}
                                        </div>
                                    </li>);

                                // has at least name or username
                                if (teamMember !== undefined && (teamMember.name !== '' || teamMember.username !== '')) {
                                    return (teamItemJSX)
                                } else {
                                    return (null);
                                }
                            })}

                        </ul>
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Team03;
