import { ComponentTypes } from "../enums/ComponentTypes";

export const getActualComponentType = (component) => {
  const { actualComponentName } = component;
  if (actualComponentName) {
    return actualComponentName.toLowerCase().split("-")[0] as ComponentTypes;
  }
  return component.id
    .replace("#", "")
    .toLowerCase()
    .split("-")[0] as ComponentTypes;
};
