import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import { AccessToken } from "../../store/oauth2/types";
import returnAuthHeaderForAJAX from "./returnAuthHeaderForAJAX";

export const fetchOauth2AccessTokensAsync = (
  accessToken: string
): Promise<AxiosResponse<AccessToken[]>> => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.oauth2.fetchAccessTokens, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
