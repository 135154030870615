import _ from "lodash";
import { UnicornComponent } from "../../../../classes/UnicornComponent";
import { Components } from "../../../../enums/Components";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleUpdateButton = (
  originalItem,
  modifiedItem,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if (modifiedItem.BUTTON === null) {
    originalItem.button.title = "";
    originalItem.button.href = "";
    originalItem.button.isTargetBlank = false;
  }
  if (!isKeyValue(modifiedItem.BUTTON)) {
    return;
  }
  if ("TEXT" in modifiedItem.BUTTON) {
    originalItem.button.title = itemButtonText;
  }
  if ("URL" in modifiedItem.BUTTON) {
    originalItem.button.href = itemButtonUrl;
  }
  if ("TARGET" in modifiedItem.BUTTON) {
    originalItem.button.isTargetBlank = itemButtonTarget === "_blank";
  }
};

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  const itemText = stringifyObjectsAndNumbers(modifiedItem.TEXT) || "";
  const itemButtonText =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TEXT"])) || "";
  const itemButtonUrl =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "URL"])) || "";
  const itemButtonTarget =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TARGET"])) ||
    "_self";
  return {
    itemId,
    itemPosition,
    itemTitle,
    itemText,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
  };
};

const particleModifyOriginalItem = (
  content,
  originalItem,
  modifiedItem,
  itemPosition,
  itemTitle,
  itemText,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(content.items, item);
      }
    });
  }
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("TEXT" in modifiedItem) {
    originalItem.text = itemText;
  }
  if ("BUTTON" in modifiedItem) {
    particleUpdateButton(
      originalItem,
      modifiedItem,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget
    );
  }
};

export const updateSteps = (
  componentData,
  completionContent,
  currentComponentInfo: UnicornComponent
) => {
  const content = componentData.steps.content;
  if (!("STEPS" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.STEPS)) {
    if (completionContent.STEPS === null) {
      content.items = [];
    }
    return;
  }
  if (currentComponentInfo.name === Components.steps01) {
    content.items = deleteEmptyItems(content.items, "title");
  }
  addDefaultValuesToItems(content.items);
  completionContent.STEPS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemTitle,
      itemText,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      content.items = deleteItem(content.items, itemId);
      return;
    }
    const isItemNew = getIsItemNew(content.items, itemId);
    if (isItemNew) {
      const newItem = generator.createStepContentObject();
      newItem.title = "";
      newItem.text = "";
      addNewItem(content.items, newItem, itemId, itemPosition);
    }
    const originalItem = getOriginalItem(content.items, itemId);
    particleModifyOriginalItem(
      content,
      originalItem,
      modifiedItem,
      itemPosition,
      itemTitle,
      itemText,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget
    );
  });
  sortItemsByPosition(content.items);
};
