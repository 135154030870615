import {
  ComponentDataPaths,
  ComponentDataPathsInputState,
} from "../types/popupTypes";

export const generateComponentDataPath = (
  inputState: ComponentDataPathsInputState
): ComponentDataPaths => {
  const {
    currentCtaButtonIndex,
    currentPricingIndex,
    isYearlyPricingButtonPassed,
    currentPostIndex,
    currentGalleryIndex,
    currentGalleryButtonIndex,
    currentStepsItemIndex,
  } = inputState;

  return {
    form: {
      cta: `cta.content.form.settings`,
    },
    button: {
      cta: `cta.content.buttons[${currentCtaButtonIndex}].settings`,
      pricing: `${
        isYearlyPricingButtonPassed
          ? `pricing.content.items[${currentPricingIndex}].yearlyButton.settings`
          : `pricing.content.items[${currentPricingIndex}].button.settings`
      }`,
      posts: `posts.content.items[${currentPostIndex}].button.settings`,
      gallery: `gallery.content.items[${currentGalleryIndex}].contentCta.buttons[${currentGalleryButtonIndex}].settings`,
      steps: `steps.content.items[${currentStepsItemIndex}].button.settings`,
    },
  };
};
