import React, {Component} from "react";
import "./SettingsBox.css"
import { Divider } from "antd";

interface Props {
    titleText: string
}

//User for settings of any kind: account, website. Adds vertical margins between inputs.
class SettingsBox extends Component<Props>{
    render(){
        return (
            <div className="settings-box">
                <h2 className="settings-box__title">{this.props.titleText}</h2>
                <Divider className="settings__divider" />
                {this.props.children}
            </div>

        )
    }
}

export default SettingsBox;
