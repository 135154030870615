import React from "react";
import { connect } from "react-redux";
import { ChangeComponentGlobalData } from "../../../../store/websitePages/types";
import {
  changeComponentGlobalData,
  toggleNewWebsitePageEditionsDetected,
} from "../../../../store/websitePages/actions";
import DebouncedInput from "../../../DebouncedInput";

interface Props {
  componentItem: any;
  currentWebsitePage: any;

  changeComponentGlobalData: ChangeComponentGlobalData;
  toggleNewWebsitePageEditionsDetected: (payload: boolean) => void;
}

const AiDescriptionInputComponent = (props: Props): JSX.Element => {
  const {
    componentItem,
    changeComponentGlobalData,
    toggleNewWebsitePageEditionsDetected,
    currentWebsitePage,
  } = props;

  const handleOnChange = (value: string) => {
    changeComponentGlobalData({
      componentId: componentItem.id,
      pageId: currentWebsitePage.id,
      data: {
        aiDescription: value,
      },
    });
    toggleNewWebsitePageEditionsDetected(true);
  };

  return (
    <DebouncedInput
      placeholder="Final call to action with multiple options"
      onChange={handleOnChange}
      defaultValue={
        "aiDescription" in componentItem
          ? componentItem.aiDescription
          : "Page component"
      }
    />
  );
};

const mapActionsToProps = {
  changeComponentGlobalData,
  toggleNewWebsitePageEditionsDetected,
};
export default connect(null, mapActionsToProps)(AiDescriptionInputComponent);
