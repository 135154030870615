import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import './NotFound.css'
import {Button} from "antd";


interface Props {
}

class notFound extends Component<Props>{
    componentDidMount(): void {
    }

    render(){
        return (
            <div>
                <div className="not-found">
                    <div className="not-found__box">
                        <div className="not-found__error_code">404</div>
                        <h1 className="not-found__title">Page not found</h1>
                        <Link to="/" className="not-found__bottom_button">
                            <Button type="primary" htmlType="button" className="" icon="home">
                                Back home
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default notFound;