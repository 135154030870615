import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Team01 extends Component<Props> {
    generator = new generateContentDataObject();

    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if (this.props.checkIsEmptyContent()) {
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Our brave support champions"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: 'Hi and nice to meet you! This is our brave team. We work very hard to provide an awesome experience for you.'}));
            this.props.saveContentInStore(this.generator.setUpTeamMembers(
                {
                  isDefaultEmpty,
                    hasPhoto: true,
                    hasName: false,
                    hasJobTitle: false,
                    hasUsername: false,
                    hasSocialNetworks: false,
                    hasHref: true,
                    hasInfo: false,

                    items: [
                        this.generator.createTeamMemberContentObject(),
                        this.generator.createTeamMemberContentObject({
                          jobTitle: "Founder",
                          name: "Alexander Isora",
                          username: "@alexanderisora",
                          graphics: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PERSON_1,
                            width: 400,
                            height: 400,
                          }),
                          info: "Alexander is passionate about web, tech and the startup industry.",
                        }),
                        this.generator.createTeamMemberContentObject(),
                        this.generator.createTeamMemberContentObject({
                          jobTitle: "Founder",
                          name: "Alexander Isora",
                          username: "@alexanderisora",
                          graphics: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PERSON_1,
                            width: 400,
                            height: 400,
                          }),
                          info: "Alexander is passionate about web, tech and the startup industry.",
                        }),
                    ],
                }
            ));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the team component to tell more about your company.",
                    default: "Add a button to the bottom of the team component to tell more about your company.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render() {
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let team = this.props.componentItem.componentData.team.content.items;

        return (
            <section>
                <div className="team-01">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container">
                        <div className="team-01__images_row">

                            {team && team.map((item: any, key: number) => {
                                let photosItemJSX = (
                                    <span className="team-01__person" key={'team-01-' + key}>
                                        <div className="team-01__image_box">
                                            <img src={formatImageUrl(item.graphics.image.url)} className="team-01__image"/>
                                        </div>
                                    </span>);

                                if(item.href && item.href !==''){
                                    photosItemJSX = (
                                        <a className="team-01__person" key={'team-01-' + key}
                                           href={returnHref(item.href)}>
                                            <div className="team-01__image_box team-01__link">
                                                <img src={formatImageUrl(item.graphics.image.url)} className="team-01__image"/>
                                            </div>
                                        </a>);
                                }

                                if (item !== undefined && item.graphics !== undefined && item.graphics.image !== undefined && item.graphics.image.url !== '') {
                                    return (photosItemJSX)
                                } else {
                                    return (null);
                                }
                            })}

                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Team01;
