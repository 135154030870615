import React, { useEffect, useRef, useState } from "react";
import {
  Anchor,
  Button,
  Empty,
  Icon,
  Input,
  Layout,
  message,
  Modal,
  Tag,
  Tooltip,
} from "antd";
import "./AddPageComponent.css";
import SpinnerBox from "../dashboard/SpinnerBox";
import capitalizeString from "../../helpers/strings/capitalizeString";
import { api } from "../../data/urls";
import { checkIfPageHasComponents } from "../../helpers/editor/checkIfPageHasComponents";
import {
  copiedComponentDataSessionStorageKey,
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  notificationDuration,
} from "../../data/constants";
import { handleUpgradeToUnlock } from "../../helpers/editor/handleUpgradeToUnlock";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { CrispEvents, PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { fuzzySearch } from "../../helpers/fuzzySearch";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {
  ChangeComponentGlobalData,
  ChangeWebsitePagesStateGlobalData,
  WebsitePagesState,
} from "../../store/websitePages/types";
import classNames from "classnames";
import { openNotification } from "../../helpers/openNotification";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import { getComponentThumbnail } from "../../helpers/getComponentThumbnail";
import { getDisplayedNumberString } from "../../helpers/getDisplayedNumber";
import { createAdvancedComponentAsync } from "../../helpers/async/createAdvancedComponentAsync";
import fuzzysort from "fuzzysort";
import { sortObjectsByScoresFn } from "../../helpers/sortObjectsByScoresFn";

const { Content, Sider } = Layout;

interface Props {
  pageComponents: any;
  pageComponentCategories: any;
  user: any;
  auth: any;
  websitesPages: WebsitePagesState;
  history: any;
  currentPageComponents: any;
  isAddComponentWindowVisible: boolean;
  handleCloseAddComponent: any;
  newComponentPosition: number; // we track the index
  addWebsitePageComponent: any;
  pasteCopiedWebsitePageComponent: any;
  currentPageIndex: number | undefined;
  toggleNewWebsitePageEditionsDetected: any;
  currentWebsitePageUrl: any;
  currentWebsiteSubdomain: any;
  doesComponentClipboardHaveItem: boolean;
  changeComponentGlobalData: ChangeComponentGlobalData;
  changeWebsitePagesStateGlobalData: ChangeWebsitePagesStateGlobalData;
}

const AddPageComponent = (props: Props) => {
  const {
    doesComponentClipboardHaveItem,
    currentPageComponents,
    history,
    user,
    addWebsitePageComponent,
    currentWebsiteSubdomain,
    currentWebsitePageUrl,
    handleCloseAddComponent,
    toggleNewWebsitePageEditionsDetected,
    websitesPages,
    changeWebsitePagesStateGlobalData,
    auth,
    isAddComponentWindowVisible,
    pageComponents,
    pageComponentCategories,
    pasteCopiedWebsitePageComponent,
    newComponentPosition,
    currentPageIndex,
  } = props;

  const displayedCategories = useRef([]);
  const inputRef = useRef<Input>();
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const menu = document.getElementById(
      "js-add-page-component-categories-bound"
    );
    if (menu) {
      menu.scrollTo(0, 0);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (websitesPages.advancedComponentsLoadedStatus !== "not_loaded") {
      return;
    }
    changeWebsitePagesStateGlobalData({
      data: {
        advancedComponentsLoadedStatus: "loading",
      },
    });
    const url = api.websitePages.fetchAdvancedComponents;
    axios
      .get(url, { ...returnAuthHeaderForAJAX(auth.accessToken) })
      .then(({ data }) => {
        changeWebsitePagesStateGlobalData({
          data: {
            advancedComponentsLoadedStatus: "loaded",
            advancedComponentsArray: data,
          },
        });
      })
      .catch((error) => {
        changeWebsitePagesStateGlobalData({
          data: {
            advancedComponentsLoadedStatus: "error",
          },
        });
        console.error(error.response);
        openNotification(
          DEFAULT_ERROR_TITLE,
          "Couldn't load advanced components. Please reload the page. If it didn't help, contact us.",
          "OK",
          "error",
          notificationDuration.medium
        );
      });
  }, []);

  useEffect(() => {
    const handleClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      let element = e.target;
      if (element.tagName !== "A") return;
      const container = document.getElementById(
        "js-add-page-component-categories-bound"
      );
      if (!container) return
      const target = element.getAttribute("href");
      const targetElement = document.querySelector(target);
      if (targetElement) {
        container.scrollTo({
          top: targetElement.offsetTop - 124,
          behavior: "smooth",
        });
      }
    }
    if (isAddComponentWindowVisible) {
      if (inputRef && inputRef.current && inputRef.current.select) {
        inputRef.current.select();
      }
      setTimeout(() => {
        const menu = document.querySelector(".add-page-component__menu");
        if (menu) {
          menu.addEventListener("click", handleClick);
        }
      }, 0);
    } else {
      setTimeout(() => {
        const menu = document.querySelector(".add-page-component__menu");
        if (menu) {
          menu.removeEventListener("click", handleClick);
        }
      }, 0);
    }
  }, [isAddComponentWindowVisible]);

  const displaySider = () => {
    if (pageComponents.dataFetched && pageComponentCategories.dataFetched) {
      const { Link } = Anchor;

      const categoriesArray = [
        ...pageComponentCategories.items,
        {
          description: "Components enhanced with custom code.",
          human_friendly_title: "Advanced",
          id: 9999,
          is_hidden: false,
          is_pro_only: false,
          priority: 5,
          title: "advanced",
        },
      ];
      const menuItems = displayedCategories.current
        .sort((a, b) => sortObjectsByScoresFn(a, b, "scores"))
        .map((item: any, key: number) => {
          let linkTitle = capitalizeString(
            item.human_friendly_title ? item.human_friendly_title : item.title
          );
          return (
            <Link
              title={linkTitle}
              key={"category-menu-" + key}
              href={"#category-" + item.title}
            />
          );
        });

      //Docs: https://ant.design/components/anchor/#API
      if (displayedCategories.current.length === 0) {
        return null;
      }
      return (
        <Anchor
          offsetTop={70}
          showInkInFixed={true}
          getContainer={() =>
            document.getElementById("js-add-page-component-categories-bound")
          }
          affix={false}
        >
          {menuItems}
        </Anchor>
      );
    } else {
      return <SpinnerBox text="Categories are loading..." />;
    }
  };

  const pasteCopiedComponent = (copiedComponentJSON: any) => {
    // add a component to the redux store
    pasteCopiedWebsitePageComponent({
      pastedComponentData: copiedComponentJSON,
      indexToPlace: newComponentPosition,
      currentPageIndex: currentPageIndex,
    });

    //The save button activated
    toggleNewWebsitePageEditionsDetected(true);

    //close the "add component" modal
    handleCloseAddComponent();

    fireAnalyticsEvent.fireCrisp(CrispEvents.pasteCopiedComponent, {
      subdomain: currentWebsiteSubdomain,
      page_url: currentWebsitePageUrl,
      component_title: copiedComponentJSON.title,
      component_category_id: copiedComponentJSON.category,
    });
  };

  const addComponent = (
    componentCategoryId: number,
    componentCategoryTitle: string,
    componentTitle: string,
    pageComponentItem,
    originalAdvancedComponent?: any
  ) => {
    addWebsitePageComponent({
      currentPageIndex: currentPageIndex,
      componentCategoryId:
        pageComponentItem.original_component_category_id || componentCategoryId,
      componentCategoryTitle:
        pageComponentItem.original_component_category_title ||
        componentCategoryTitle,
      componentTitle:
        pageComponentItem.original_component_title || componentTitle,
      indexToPlace: newComponentPosition,
      originalAdvancedComponent,
    });

    const componentName = `${componentCategoryTitle}-${componentTitle}`;
    window.posthog.capture(PosthogEvents.ADD_COMPONENT, {
      component: componentName,
      component_index: newComponentPosition,
    });

    //The save button activated
    toggleNewWebsitePageEditionsDetected(true);

    //close the "add component" modal
    handleCloseAddComponent();

    // startOnboardingTour('press_edit_component', this.props.user);

    if (componentCategoryTitle === "cta_form") {
      fireAnalyticsEvent.fireCrisp(CrispEvents.addFormComponent, {
        subdomain: currentWebsiteSubdomain,
        page_url: currentWebsitePageUrl,
      });
    }
    fireAnalyticsEvent.fireCrisp(CrispEvents.addComponent, {
      subdomain: currentWebsiteSubdomain,
      page_url: currentWebsitePageUrl,
      component_title: componentTitle,
      component_category_title: componentCategoryTitle,
    });
  };

  const displayComponents = () => {
    let displayPageComponentCategories = () => {
      let displayPageComponents = (categoryItem: any) => {
        let counter = 1;
        let pageComponentsArray = [
          ...pageComponents.items.filter((item) => item && !item.is_hidden),
          ...websitesPages.advancedComponentsArray,
        ];

        pageComponentsArray = pageComponentsArray.filter((item: any) => {
          if (item.native_category_id) {
            return parseInt(item.native_category_id) === categoryItem.id;
          }
          return item.category === categoryItem.id;
        });

        pageComponentsArray.forEach((item) => {
          if (item.is_advanced) {
            item.displayed_title = getDisplayedNumberString(counter);
            if (item.native_category_id) {
              item.displayed_title = "a" + item.displayed_title;
            }
            counter++;
          }
          let pageComponentHumanFriendlyTitle = (
            categoryItem.title +
            "-" +
            item.title
          ).replace(" ", "-");

          if (item.displayed_title) {
            pageComponentHumanFriendlyTitle = (
              categoryItem.title +
              "-" +
              item.displayed_title
            ).replace(" ", "-");
          }
          item.titleForDisplay = pageComponentHumanFriendlyTitle;
          item.searchKeywords = pageComponentHumanFriendlyTitle;
          if (categoryItem.keywords) {
            item.searchKeywords += ` ${categoryItem.keywords}`;
          }
          if (item.keywords) {
            item.searchKeywords += ` ${item.keywords}`;
          }

          item.score = null;
        });

        const results = fuzzysort.go(searchQuery, pageComponentsArray, {
          keys: ["searchKeywords"],
        });

        if (searchQuery) {
          pageComponentsArray = results.map((result) => {
            const { obj } = result;
            obj.score = result.score;
            return obj;
          });
        }

        const pageComponentsJsx = pageComponentsArray.map(
          (pageComponentItem: any, pageComponentKey: number) => {
            let pageComponentThumbUrl = getComponentThumbnail(
              pageComponentItem,
              categoryItem
            );

            if (pageComponentItem.thumbnail_url) {
              pageComponentThumbUrl = pageComponentItem.thumbnail_url;
            }

            pageComponentThumbUrl = formatImageUrl(pageComponentThumbUrl);

            let isLocked =
              pageComponentItem.is_pro_only && user.isPro === false;

            const handleAddComponent = () => {
              if (websitesPages.isFullAdvancedComponentBeingFetched) {
                return;
              }
              if (!pageComponentItem.is_advanced) {
                addComponent(
                  categoryItem.id,
                  categoryItem.title,
                  pageComponentItem.title,
                  pageComponentItem
                );
                return;
              }
              changeWebsitePagesStateGlobalData({
                data: {
                  isFullAdvancedComponentBeingFetched: true,
                },
              });
              createAdvancedComponentAsync({
                accessToken: auth.accessToken,
                pageComponentItem,
              })
                .then(({ data }) => {
                  addComponent(
                    categoryItem.id,
                    categoryItem.title,
                    pageComponentItem.title,
                    pageComponentItem,
                    data[0]
                  );
                })
                .catch((error) => {
                  console.error(error.response);
                  openNotification(
                    DEFAULT_ERROR_TITLE,
                    DEFAULT_ERROR_MESSAGE,
                    "OK",
                    "error",
                    notificationDuration.medium
                  );
                })
                .finally(() => {
                  changeWebsitePagesStateGlobalData({
                    data: {
                      isFullAdvancedComponentBeingFetched: false,
                    },
                  });
                });
            };

            if (pageComponentItem.is_hidden) {
              return null;
            } else {
              return (
                <li
                  key={"page-component-" + pageComponentKey}
                  className="add-page-component__component_item js-intercom-tour__add_component"
                  data-score={pageComponentItem.score}
                >
                  {isLocked === true && (
                    <span className="add-page-component__lock">
                      <Icon type="lock" theme="filled" />
                    </span>
                  )}

                  {pageComponentItem.is_pro_only && user.isPro && (
                    <span className="add-page-component__premium_mark">
                      <Tooltip title="Premium component.">
                        <Icon type="star" theme="twoTone" />
                      </Tooltip>
                    </span>
                  )}

                  <button
                    type="button"
                    className={classNames("add-page-component__button", {
                      "is-loading":
                        websitesPages.isFullAdvancedComponentBeingFetched,
                    })}
                    onClick={() => {
                      if (isLocked) {
                        let messageMarkup = (
                          <span>
                            Please{" "}
                            <Button
                              onClick={() => {
                                handleUpgradeToUnlock(history);
                              }}
                              htmlType="button"
                              type="primary"
                              style={{ color: "white" }}
                              size="small"
                            >
                              Upgrade
                            </Button>{" "}
                            to any paid plan to use this premium component.
                          </span>
                        );
                        message.success(messageMarkup, 10);
                      } else {
                        handleAddComponent();
                      }
                    }}
                  >
                    <img
                      src={pageComponentThumbUrl}
                      alt={
                        pageComponentItem.titleForDisplay +
                        " component thumbnail."
                      }
                      className="add-page-component__thumb"
                    />
                    {websitesPages.isFullAdvancedComponentBeingFetched && (
                      <span className="add-page-component__loading-overlay">
                        <Icon type="loading" />
                      </span>
                    )}
                    {!pageComponentItem.advanced_description && (
                      <span className="add-page-component__label">
                        {pageComponentItem.titleForDisplay}
                      </span>
                    )}
                    {pageComponentItem.advanced_description && (
                      <div className="add-page-component__description">
                        <span>{pageComponentItem.advanced_description}</span>
                      </div>
                    )}
                  </button>
                </li>
              );
            }
          }
        );

        return pageComponentsJsx;
      };

      let displayAdviceToPickHeaderFirst = (categoryTitle: string) => {
        if (checkIfPageHasComponents(currentPageComponents) === false) {
          if (categoryTitle === "header") {
            // return (<Tooltip visible={true} placement="right" title=""></Tooltip>);
            return (
              <Tag style={{ cursor: "default" }} color="green">
                We recommend to start with a Hero <Icon type="arrow-down" />
              </Tag>
            );
          }
        }
      };

      displayedCategories.current = [];

      const categoriesArray = [
        ...pageComponentCategories.items,
        {
          description: "Components enhanced with custom code.",
          human_friendly_title: "Advanced",
          id: 9999,
          is_hidden: false,
          is_pro_only: false,
          priority: 5,
          title: "advanced",
        },
      ];

      const categories = categoriesArray
        .map((categoryItem: any, categoryKey: number) => {
          const componentsArray = displayPageComponents(categoryItem);
          if (componentsArray.length === 0) {
            return null;
          }
          const scores = componentsArray
            .map((component) => component.props["data-score"])
            .filter((score) => score !== null);

          displayedCategories.current.push({
            ...categoryItem,
            scores,
          });
          return (
            <li
              key={categoryKey}
              className="add-page-component__category"
              data-scores={scores}
            >
              <h2
                className="add-page-component__category_title"
                id={"category-" + categoryItem.title}
              >
                {capitalizeString(
                  categoryItem.human_friendly_title
                    ? categoryItem.human_friendly_title
                    : categoryItem.title.replace("_", " ")
                )}
                <span className="add-page-component__info">
                  {categoryItem.description}
                </span>
                {displayAdviceToPickHeaderFirst(categoryItem.title)}
              </h2>

              <ul className="add-page-component__components_list js-intercom-tour__components_collection_window">
                {componentsArray}
              </ul>
            </li>
          );
        })
        .filter((categoryJSX) => categoryJSX !== null)
        .sort((a, b) => sortObjectsByScoresFn(a, b, "props.data-scores"));

      let pasteComponentFromClipboardButtonJSX = null;

      if (doesComponentClipboardHaveItem === true) {
        let copiedComponentJSON = JSON.parse(
          sessionStorage.getItem(copiedComponentDataSessionStorageKey)
        );
        pasteComponentFromClipboardButtonJSX = (
          <Button
            onClick={() => pasteCopiedComponent(copiedComponentJSON)}
            type="primary"
            icon="plus"
            className="add-page-component__paste_component_button"
          >
            Paste from clipboard
          </Button>
        );
      }

      return (
        <ul className="add-page-component__categories">
          {categories}
          {categories.length === 0 && (
            <Empty
              style={{ marginTop: "60px" }}
              description="No components match your search query"
            />
          )}
          {pasteComponentFromClipboardButtonJSX}
        </ul>
      );
    };

    if (pageComponents.dataFetched && pageComponentCategories.dataFetched) {
      return <div>{displayPageComponentCategories()}</div>;
    } else {
      return <SpinnerBox text="Components are loading..." />;
    }
  };

  // this.displayComponents must be called before this.displaySider because it sets this.displayedCategories
  const displayedComponents = displayComponents();
  const displayedSlider = displaySider();

  return (
    <div>
      <Modal
        className="add-page-component"
        title="Add a component"
        visible={isAddComponentWindowVisible}
        onCancel={handleCloseAddComponent}
        footer={null}
        width={1200}
        style={{ top: 20 }}
      >
        <Layout>
          <Sider
            width={200}
            style={{
              overflow: "auto",
              position: "absolute",
              left: 0,
              borderRight: "1px solid #e8e8e8",
            }}
          >
            <div className="add-page-component__menu">{displayedSlider}</div>
          </Sider>
          <Content id="js-add-page-component-categories-bound">
            <div className="add-page-component__search-bar-container">
              <div>
                <Input
                  placeholder="Search components"
                  prefix={
                    <Icon
                      type="search"
                      style={{ cursor: "default" }}
                      onClick={() => {
                        if (
                          inputRef &&
                          inputRef.current &&
                          inputRef.current.focus
                        ) {
                          inputRef.current.focus();
                        }
                      }}
                    />
                  }
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  autoFocus
                  ref={inputRef}
                  className="add-page-component__search-bar-input"
                />
              </div>
            </div>
            <div className="add-page-component__container">
              {displayedComponents}
            </div>
          </Content>
        </Layout>
      </Modal>
    </div>
  );
};

export default AddPageComponent;
