import _ from "lodash";
import { validateStyle } from "./validateStyle";

export const getCustomStyleClassNames = (currentWebsite) => {
  const styles = _.get(currentWebsite, "custom_styles");
  if (!styles) return "";
  const stylesArray = Object.keys(styles)
    .filter((style) => {
      return validateStyle(styles[style]);
    })
    .map((style) => {
      return `has-${style}`;
    });
  let styleString = stylesArray.join(" ");
  if (validateStyle(styles["buttonShadow"])) {
    styleString += ` has-buttonShadow--${styles["buttonShadow"]}`;
  }
  if (validateStyle(styles["cardShadow"])) {
    styleString += ` has-cardShadow--${styles["cardShadow"]}`;
  }
  return styleString;
};
