export const getArrayOfStrings = (modifiedArray): string[] => {
  let arrayOfStrings = modifiedArray || [];
  if (!Array.isArray(arrayOfStrings)) {
    arrayOfStrings = [];
  }
  arrayOfStrings = arrayOfStrings.filter((item) => {
    return typeof item === "string";
  });
  return arrayOfStrings;
};
