import { Button, notification } from "antd";
import React, { ReactNode } from "react";

//https://ant.design/components/notification/

export const openNotification = (
  title: string,
  description: string | ReactNode,
  buttonText: string | undefined = "close",
  type: string = "info",
  duration: number = 30,
  icon?: any
) => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      {buttonText}
    </Button>
  );
  let config: any = {
    message: title,
    description: description,
    key,
    placement: "bottomRight",
    duration: duration,
    // onClose: close,
    btn,
    className: "primary-layout__notification",
  };

  if (icon !== undefined) {
    config["icon"] = icon;
  }

  const currentNotification = (notification as any)[type as string];
  currentNotification(config);
};
