import React, { useCallback, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { Prompt } from "react-router";
import isHotkey from "is-hotkey";
import axios from "axios";
import * as _ from "lodash";
import imageExtensions from "image-extensions";
import isUrl from "is-url";
import { Editor, Transforms, createEditor, Range, Text } from "slate";
import { Slate, Editable, useEditor, useSlate, withReact } from "slate-react";
import serializeNodeToHtml from "./slateSerializeNodeToHtml";
import { withHistory } from "slate-history";
import { Element, Leaf } from "./slateRender";
import { Input, Button, Icon, Empty, Tooltip, Badge, Popconfirm } from "antd";
import { api, urls } from "../../../data/urls";
import { openNotification } from "../../../helpers/openNotification";
import SpinnerBox from "../../dashboard/SpinnerBox";
import "./SlateEditor.scss";
import Uploader from "../../editor/Uploader";
import { defaultBlogUrl } from "../../../data/constants";
import { deserialize } from "./slateDeserialize";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
  "mod+k": "link",
};

// START: Toolbar
const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const LIST_TYPES = ["numbered-list", "bulleted-list"];
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type as string),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};
const withLinks = (editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, { match: (n) => n.type === "link" });
  return !!link;
};

const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, { match: (n) => n.type === "link" });
};

const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

const LinkButton = () => {
  const editor = useSlate();
  return (
    <div className="slate-editor__toolbar-item">
      <Button
        title={
          isLinkActive(editor)
            ? "Unlink the selection."
            : "Create a link to another page or website."
        }
        type={isLinkActive(editor) ? "primary" : "default"}
        className="slate-editor__toolbar-item-btn"
        onMouseDown={(event) => {
          event.preventDefault();
          if (isLinkActive(editor)) {
            unwrapLink(editor);
          } else {
            const url = window.prompt("Enter the URL of the link:");
            if (!url) {
              return;
            }
            insertLink(editor, url);
          }
        }}
      >
        <Icon
          className="button-icon"
          type={isLinkActive(editor) ? "disconnect" : "link"}
        />
      </Button>
    </div>
  );
};
const BlockButton = ({
  format,
  buttonIcon = null,
  buttonText = null,
  label,
}) => {
  const editor = useSlate();

  let buttonIconJSX;
  let buttonTextJSX;
  if (buttonIcon !== null) {
    buttonIconJSX = <Icon className="button-icon" type={buttonIcon} />;
  }
  if (buttonText !== null) {
    buttonTextJSX = <span className="button-text">{buttonText}</span>;
  }

  return (
    <div className="slate-editor__toolbar-item">
      <Button
        onMouseDown={(event) => {
          event.preventDefault();
          toggleBlock(editor, format);
        }}
        className="slate-editor__toolbar-item-btn"
        type={isBlockActive(editor, format) ? "primary" : "default"}
      >
        {buttonIconJSX}
        {buttonTextJSX}
      </Button>
      <span className="slate-editor__toolbar-item-label">{label}</span>
    </div>
  );
};

const MarkButton = ({
  format,
  buttonIcon = null,
  buttonText = null,
  label,
}) => {
  const editor = useSlate();

  let buttonIconJSX;
  let buttonTextJSX;
  if (buttonIcon !== null) {
    buttonIconJSX = <Icon className="button-icon" type={buttonIcon} />;
  }
  if (buttonText !== null) {
    buttonTextJSX = <span className="button-text">{buttonText}</span>;
  }

  return (
    <div className="slate-editor__toolbar-item">
      <Button
        className="toolbar-btn"
        type={isMarkActive(editor, format) ? "primary" : "default"}
        onMouseDown={(event) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        {buttonIconJSX}
        {buttonTextJSX}
      </Button>
      <span className="slate-editor__toolbar-item-label">{label}</span>
    </div>
  );
};

const insertImage = (
  editor,
  url,
  fileName?: any,
  imageWidth?: any,
  imageHeight?: any
) => {
  if (url.indexOf("data:") === 0) {
    openNotification(
      "Not ready yet",
      'I\'m sorry but the "drag and drop" and "paste an image" functionality are not available yet.',
      "I see",
      "info",
      10
    );
    return;
  } else {
    const text = { text: "" };
    const image = [
      {
        type: "image",
        url,
        fileName,
        imageWidth,
        imageHeight,
        children: [text],
      },
      {
        type: "paragraph",
        children: [text],
      },
    ];
    Transforms.insertNodes(editor, image);
  }
};

const InsertImageButton = ({ label }) => {
  const editor = useEditor();
  return (
    <div className="slate-editor__toolbar-item">
      {/*this button opens a drawer with image upload options*/}
      <Button
        className="toolbar-btn"
        onMouseDown={(event) => {
          event.preventDefault();
          // const url = window.prompt('Enter the URL of the image:')
          // if (!url) return
          // insertImage(editor, url)
          if ((window as any).jQuery) {
            (window as any)
              .jQuery(".uploadcare--widget__button_type_open")
              .trigger("click");
          } else {
            openNotification(
              "Error",
              "Something went wrong with the images ;( Please contact us so we can help you.",
              "OK",
              "info",
              10
            );
          }
        }}
      >
        <Icon type="picture" />
      </Button>
      <span className="slate-editor__toolbar-item-label">{label}</span>

      {/*This uploader is invisible and only placed to enable global drang and drop*/}
      <div className="slate-editor__invisible_drag_ang_drop">
        <Uploader
          id={"blog-post-body-image-hidden-uploader"}
          name={"blog-post-body-image-hidden-uploader"}
          data-tabs="file url gdrive gphotos dropbox onedrive box"
          data-preview-step={false}
          data-image-shrink="1600x1600"
          data-system-dialog={true}
          data-images-only={true}
          onChange={(file: any) => {
            // console.log('File changed: ', file);
            // if (file) {
            // file.progress((info:any) => console.log('File progress: ', info.progress));
            // file.done((info:any) => console.log('File uploaded: ', info));
            // }
          }}
          onUploadComplete={(info: any) => {
            // console.log('Upload completed:', info);
            let uploadedImgSrc = info.cdnUrl;
            let fileName = info.name.toLowerCase();
            fileName = fileName.replace(" ", "-");
            fileName = fileName.replace("/", "-");

            let imageWidth = undefined;
            let imageHeight = undefined;
            if (
              info.originalImageInfo &&
              info.originalImageInfo.width &&
              info.originalImageInfo.height
            ) {
              imageWidth = info.originalImageInfo.width;
              imageHeight = info.originalImageInfo.height;
            }

            uploadedImgSrc = uploadedImgSrc + fileName;

            if (uploadedImgSrc) {
              insertImage(
                editor,
                uploadedImgSrc,
                fileName,
                imageWidth,
                imageHeight
              );
            } else {
              openNotification(
                "Error :(",
                "There was an unknown error during uploading the favicon. Please refresh the page and try again.",
                "OK",
                "warn"
              );
            }
          }}
        />
      </div>
    </div>
  );
};
// END: Toolbar
const withEmbeds = (editor) => {
  const { isVoid } = editor;
  editor.isVoid = (element) =>
    element.type === "embed" ? true : isVoid(element);
  return editor;
};
const insertEmbed = (editor, src, width, height) => {
  const text = { text: "" };
  const embed = [
    {
      type: "embed",
      src,
      width,
      height,
      children: [text],
    },
    {
      type: "paragraph",
      children: [text],
    },
  ];
  Transforms.insertNodes(editor, embed);
};
const InsertEmbedButton = ({ label }) => {
  const editor = useEditor();
  return (
    <div className="slate-editor__toolbar-item">
      <Tooltip
        placement={"top"}
        title={
          <div>
            Embed an <code>&lt;iframe&gt;</code>.<br />
            <br />
            It can be a YouTube, Vimeo video, or any other embed.
          </div>
        }
      >
        <Button
          className="toolbar-btn"
          onMouseDown={(event) => {
            event.preventDefault();
            let code = window.prompt("Enter the <iframe> code:");

            if (!code) return; //if entered nothing - do nothing

            if (code.indexOf("iframe") === -1) {
              openNotification(
                "<iframe>s only",
                "Please copy-paste an <iframe></iframe>.",
                "OK, thanks",
                "info",
                10
              );
              return;
            }

            code = code.split(">")[0]; //we cut everything after the opening <iframe> tag because some service add additional markup to their embeds (e.g. Codepen)

            let src = code.split('src="')[1];
            if (src) {
              src = src.split('"')[0];
            } else {
              src = urls.error404;
            }

            // an iframe might not have width and height - so we set default values
            let width = code.split('width="')[1];
            if (width) {
              width = width.split('"')[0];
            } else {
              width = "";
            }

            let height = code.split('height="')[1];
            if (height) {
              height = height.split('"')[0];
            } else {
              height = "315"; //default YouTube height
            }
            insertEmbed(editor, src, width, height);
          }}
        >
          <Icon type="play-circle" />
        </Button>
        <span className="slate-editor__toolbar-item-label">{label}</span>
      </Tooltip>
    </div>
  );
};

const withHtml = (editor) => {
  const { insertData, isInline, isVoid } = editor;

  editor.insertData = (data) => {
    const html = data.getData("text/html");

    if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html");
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};

const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};

const withImages = (editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split("/");

        if (mime === "image") {
          reader.addEventListener("load", () => {
            const url = reader.result;
            insertImage(editor, url);
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};
interface Props {
  websites: any;
  blogs: any;
  blogPosts: any;
  auth: any;
  currentPostUrl: any;
  history: any;
  editBlogPostSchemeToggleWaitingForServer: any;
  toggleNewBlogPostEditionsDetected: any;
  changeBlogPostBody: any;
  editBlogPostMainTitleSchemeToggleWaitingForServer: any;
  toggleNewBlogPostMainTitleEditionsDetected: any;
  toggleBlogNavEditionsDetected: any;
  toggleBlogFooterEditionsDetected: any;
  toggleWaitingForResponseOnBlogNavEditions: any;
  toggleWaitingForResponseOnBlogFooterEditions: any;
  isBlogUrlBlank: boolean;
  toggleBlogPostChangeInProgress: any;
  changeBlogPostTitle: any;
  changePostEditorType: any;
  changePostEditorTypeWaitingForServer: any;

  // From Blog Post Editor Wrapper
  currentWebsite: any;
  currentBlog: any;
  currentBlogPost: any;
  currentPostIndex: number;

  debounceSavePostBodyToServer: any;
  savePostBodyToStore: any;

  changesDetected: any;
  handleChangesDetected: any;
}

// START: SlateEditor component
const SlateEditor: React.FC<Props> = (props: Props) => {
  // START: State
  const [postBodyJson, setPostBodyJson] = useState([]);
  // END: State

  const editor = useMemo(
    () =>
      withHtml(
        withEmbeds(
          withImages(withLinks(withHistory(withReact(createEditor()))))
        )
      ),
    []
  );

  useEffect(() => {
    // if (props.blogs.dataFetched && props.blogPosts.allPostsFetched && props.auth.accessTokenValidated && props.auth.isAccessTokenValid) {
    //     setAllDataFetched(true);
    // }

    const blogPostsArray = props.blogPosts.items;
    let postIndex;

    blogPostsArray.map((item) => {
      if (item.id === props.currentBlogPost.id) {
        postIndex = blogPostsArray.indexOf(item);
      }
    });

    props.blogPosts.items.map((post) => {
      if (
        post.blog === props.currentBlog.id &&
        post.url === props.currentPostUrl
      ) {
        // setCurrentBlogPost(post);
        setPostBodyJson(post.body_json);
      }
    });

    if (props.isBlogUrlBlank === true) {
      props.history.replace(
        "/" +
          props.currentWebsite.url +
          "/" +
          defaultBlogUrl +
          "/" +
          props.currentBlogPost.url
      );
    }

    if (props.changesDetected) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
  });

  // On Change Post Body by SlateEditor editor
  const onChangeSlateEditor = (valueBodyJson) => {
    setPostBodyJson(valueBodyJson);

    const valueBodyHtml = valueBodyJson
      .map((n) => serializeNodeToHtml(n))
      .join(""); // Serialize post from JSON to HTML

    props.savePostBodyToStore(
      props.currentPostIndex,
      valueBodyJson,
      valueBodyHtml
    );
    props.debounceSavePostBodyToServer(
      props.currentWebsite.subdomain,
      props.currentPostUrl,
      props.currentBlogPost.editor_type,
      valueBodyJson,
      valueBodyHtml
    );
  };

  // On Change Post Body by PlainHtml editor

  let savingStatusJSX = null;
  if (
    props.blogPosts.isWaitingForSaveBlogPostSchemeResponse === true ||
    props.blogPosts.isWaitingForSaveBlogPostMainTitleSchemeResponse ||
    props.blogPosts.isWaitingForEditorTypeUpdateResponce
  ) {
    savingStatusJSX = (
      <div className="slate-editor__saving-status" style={{ width: "40px" }}>
        {" "}
        <Tooltip
          placement={"top"}
          title={
            "The content is being saved right now. Please do not close the window."
          }
        >
          <Icon style={{ color: "#1890ff" }} type="loading" />
        </Tooltip>
      </div>
    );
  } else if (props.changesDetected) {
    savingStatusJSX = (
      <div className="slate-editor__saving-status" style={{ width: "40px" }}>
        {" "}
        <Tooltip
          placement={"top"}
          title={
            "New changes detected. We will autosave it in a few moments. Keep creating!"
          }
        >
          <Icon theme="twoTone" twoToneColor="#1890ff" type="clock-circle" />
        </Tooltip>{" "}
      </div>
    );
  } else {
    savingStatusJSX = (
      <div className="slate-editor__saving-status" style={{ width: "40px" }}>
        {" "}
        <Tooltip
          placement={"top"}
          title={
            "Good. The changes have been successfully saved in our server."
          }
        >
          <Icon theme="twoTone" twoToneColor="#1890ff" type="check-circle" />
        </Tooltip>{" "}
      </div>
    );
  }

  const editorToolbarJSX = (
    <div className="slate-editor__editor-toolbar">
      <div className="slate-editor__format-buttons">
        <LinkButton />
        <InsertImageButton label="Image" />
        <InsertEmbedButton label="Embed" />
        <MarkButton format="bold" buttonIcon="bold" label="Bold" />
        <MarkButton format="italic" buttonIcon="italic" label="Italic" />
        <MarkButton
          format="underline"
          buttonIcon="underline"
          label="Underline"
        />
        <MarkButton format="code" buttonIcon="code" label="Code" />
        <BlockButton format="heading-two" buttonText="H2" label="Heading 2" />
        <BlockButton format="heading-three" buttonText="H3" label="Heading 3" />
        <BlockButton
          format="numbered-list"
          buttonIcon="ordered-list"
          label="Ordered list"
        />
        <BlockButton
          format="bulleted-list"
          buttonIcon="unordered-list"
          label="Unordered list"
        />
        <BlockButton format="quote" buttonText={`"`} label="Quote" />
      </div>
      {savingStatusJSX}
    </div>
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const renderElement = useCallback((props) => <Element {...props} />, []);

  if (
    props.currentWebsite === undefined ||
    props.currentBlog === undefined ||
    props.currentBlogPost === undefined
  ) {
    return (
      <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
        <div>slate post</div>
        <SpinnerBox text="Getting the post..." />
      </div>
    );
  } else {
    if (
      props.currentBlogPost === undefined &&
      props.currentWebsite !== undefined
    ) {
      // If no such blog post, offer to go to the website pages list.
      return (
        <div style={{ paddingTop: "40px", paddingBottom: "40px" }}>
          <Empty description={<span>Blog post not found</span>}>
            <Link to={"/" + props.currentWebsite.subdomain}>
              <Button type="primary">
                {'View all posts of the "' +
                  props.currentWebsite.subdomain +
                  '" website blog'}
              </Button>
            </Link>
          </Empty>
        </div>
      );
    } else {
      const { selection } = editor;

      return (
        <Slate
          editor={editor}
          value={postBodyJson}
          onChange={(value) => {
            onChangeSlateEditor(value);
          }}
          onKeyDown={(value) => {
            // we launch setChangesDetected when onKeyDown event fires, not onChange because onChange fires on mouse click =>  changes detected when user inputs nothing and it creates confusion.
            props.handleChangesDetected(true);
          }}
        >
          <Editable
            className="slate-editor__post-body content_box content_box--blog content_box--blog-slatejs"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Create a story"
            spellCheck
            onKeyDown={(event) => {
              if (event.shiftKey && event.key == "Enter") {
                event.preventDefault();
                editor.insertText("\n");
              }

              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event as any)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }

              // Disable Blocks or Marks by Enter
              if (
                event.key === "Enter" &&
                !event.shiftKey &&
                (isBlockActive(editor, "heading-one") ||
                  isBlockActive(editor, "heading-two") ||
                  isBlockActive(editor, "heading-three") ||
                  isBlockActive(editor, "quote") ||
                  isMarkActive(editor, "code"))
              ) {
                event.preventDefault();
                const newLine = {
                  type: "paragraph",
                  children: [
                    {
                      text: "",
                      marks: [],
                    },
                  ],
                };
                Transforms.insertNodes(editor, newLine);
              }

              // Disable lists by Enter, if last item is empty
              let isSelectionEmpty;
              if (selection !== null) {
                if (selection.anchor.offset === 0) {
                  isSelectionEmpty = true;
                }
              }
              if (
                event.key === "Enter" &&
                !event.shiftKey &&
                (isBlockActive(editor, "numbered-list") ||
                  isBlockActive(editor, "bulleted-list")) &&
                isSelectionEmpty
              ) {
                event.preventDefault();
                if (isBlockActive(editor, "numbered-list")) {
                  toggleBlock(editor, "numbered-list");
                }

                if (isBlockActive(editor, "bulleted-list")) {
                  toggleBlock(editor, "bulleted-list");
                }
              }
            }}
            onDOMBeforeInput={(event) => {
              props.toggleNewBlogPostEditionsDetected(true);
              props.handleChangesDetected(true);
            }}
          />
          {editorToolbarJSX}
        </Slate>
      );
    }
  }
};
// END: SlateEditor component

export default SlateEditor;
