function getHelloGetParam():string {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const hello = urlParams.get('hello')
    if (hello){
        return hello;
    }else{
        return undefined
    }
}

export default getHelloGetParam;