export const websiteFontOptions = [
    {
        fontClassname: 'font-lato',
        fontTitle: 'Lato',
        fontInfo: 'is a neutral font. It is powerful yet elegant. A good choice for any innovative startup.',
    },
    {
        fontClassname: 'font-work-sans',
        fontTitle: 'Work Sans',
        fontInfo: 'is a friendly font. Use it if you want your project to speak on a human language.',
    },
    {
        fontClassname: 'font-source-code-pro',
        fontTitle: 'Source Code Pro',
        fontInfo: 'is a hacker style monospace font. Use it if your project is mostly for coders.',
    },
];