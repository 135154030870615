import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type CreateBlogAsync = (payload: {
  accessToken: string;
  subdomain: string;
  newBlogUrl: string;
  websiteId: number;
}) => Promise<AxiosResponse<any>>;

export const createBlogAsync: CreateBlogAsync = ({
  accessToken,
  subdomain,
  newBlogUrl,
  websiteId,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = api.blogs.createBlog;
    axios
      .post(
        apiUrl,
        {
          website_subdomain: subdomain,
          url: newBlogUrl,
          website: websiteId,
        },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
