import {
  DeleteOauth2AccessToken,
  SaveOauth2AccessTokensInState,
  SetIsAccessTokenDataFetched,
  SetAccessTokenRevocationInProgress,
} from "./types";

export enum ActionNames {
  SAVE_OAUTH2_ACCESS_TOKENS_IN_STATE = "SAVE_OAUTH2_ACCESS_TOKENS_IN_STATE",
  SET_IS_ACCESS_TOKEN_DATA_FETCHED = "SET_IS_ACCESS_TOKEN_DATA_FETCHED",
  DELETE_OAUTH2_ACCESS_TOKEN = "DELETE_OAUTH2_ACCESS_TOKEN",
  SET_IS_ACCESS_TOKEN_REVOCATION_IN_PROGRESS = "SET_IS_ACCESS_TOKEN_REVOCATION_IN_PROGRESS",
}

export const saveOauth2AccessTokensInState: SaveOauth2AccessTokensInState = (
  payload
) => {
  return {
    type: ActionNames.SAVE_OAUTH2_ACCESS_TOKENS_IN_STATE,
    payload,
  };
};

export const setIsAccessTokenDataFetched: SetIsAccessTokenDataFetched = (
  payload
) => {
  return {
    type: ActionNames.SET_IS_ACCESS_TOKEN_DATA_FETCHED,
    payload,
  };
};

export const deleteOauth2AccessToken: DeleteOauth2AccessToken = (payload) => {
  return {
    type: ActionNames.DELETE_OAUTH2_ACCESS_TOKEN,
    payload,
  };
};

export const setAccessTokenRevocationInProgress: SetAccessTokenRevocationInProgress = (
  payload
) => {
  return {
    type: ActionNames.SET_IS_ACCESS_TOKEN_REVOCATION_IN_PROGRESS,
    payload,
  };
};
