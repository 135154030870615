import { ColorOption } from "../helpers/types/globalTypes";

export const componentsBackgroundColorsOptions = {
  // universal for all components:
  white: "bg-white-color",
  black: "bg-black-color", //white text/icons
  light_gray: "bg-light_gray-color",

  // palette-specific colors:
  accent: "bg-accent-color", //white text/icons
  mature: "bg-mature-color", //white text/icons

  light: "bg-light-color",
  medium: "bg-medium-color", // deprecated

  custom: "bg-custom-color",
};

export const deprecatedBackgroundColorOptions: ColorOption[] = ["medium"];

// the color hex values are store in the public/css, base/background_colors.scss because public/css is also user in the Django render and we need to render colors not only in the editor.
