import actionsNames from "./actions";
import { PaymentState } from "./types";

let initialState: PaymentState = {
  isProcessingSystemReady: false,
};

export const paymentReducer = (
  state: PaymentState = initialState,
  action: any
) => {
  switch (action.type) {
    case actionsNames.PROCESSING_SYSTEM_READY:
      return {
        ...state,
        isProcessingSystemReady: action.payload,
      };

    default:
      return state;
  }
};
