import { stringifyObjectsAndNumbers } from "./../stringifyObjectsAndNumbers";
import { isKeyValue } from "../../../../helpers/isKeyValue";

export const updatePricingTabs = (componentData, completionContent) => {
  if (!("PRICING" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.PRICING)) {
    if (completionContent.PRICING === null) {
      componentData.pricing.leftTabText = "";
      componentData.pricing.rightTabText = "";
      componentData.pricing.yearlyBonusText = "";
    }
    return;
  }
  if (!("TWO_PRICING_TABS" in completionContent.PRICING)) {
    return;
  }
  if (!isKeyValue(completionContent.PRICING.TWO_PRICING_TABS)) {
    if (completionContent.PRICING.TWO_PRICING_TABS === null) {
      componentData.pricing.leftTabText = "";
      componentData.pricing.rightTabText = "";
      componentData.pricing.yearlyBonusText = "";
    }
    return;
  }
  const completionTabs = completionContent.PRICING.TWO_PRICING_TABS;
  const itemLeftTab = stringifyObjectsAndNumbers(completionTabs.LEFT) || "";
  const itemRightTab = stringifyObjectsAndNumbers(completionTabs.RIGHT) || "";
  const itemYearlyBanner =
    stringifyObjectsAndNumbers(completionTabs.RIGHT_TAB_BANNER) || "";
  if ("LEFT" in completionTabs) {
    componentData.pricing.leftTabText = itemLeftTab;
  }
  if ("RIGHT" in completionTabs) {
    componentData.pricing.rightTabText = itemRightTab;
  }
  if ("RIGHT_TAB_BANNER" in completionTabs) {
    componentData.pricing.yearlyBonusText = itemYearlyBanner;
  }
};
