import { COMPONENT_THUMBNAILS_CDN } from "../data/urls";
import { formatImageUrl } from "./strings/formatImageUrl";

export const getComponentThumbnail = (pageComponentItem, categoryItem) => {
  const thumbUrl = COMPONENT_THUMBNAILS_CDN.find((thumbnailUrl: string) => {
    const category =
      pageComponentItem.original_component_category_title || categoryItem.title;
    const title =
      pageComponentItem.original_component_title || pageComponentItem.title;
    return thumbnailUrl.includes(`${category}_${title}`);
  });
  return formatImageUrl(thumbUrl);
};
