import { QueueItem } from "../../../components/editor/ai2/types";
import { editInputData } from "../actions";
import { HandleOnCompletionFinished } from "../types";

export const handleOnCompletionFinished: HandleOnCompletionFinished = ({
  hasErrors,
  pageId,
  componentId,
  queueDataRef,
}) => {
  return (dispatch) => {
    if (hasErrors) {
      dispatch(
        editInputData({
          componentId,
          newData: {
            status: "error",
          },
        })
      );
      console.error("ERROR, could not complete request");
    }
    const queueItem: QueueItem = queueDataRef.current.find(
      (item) => item.componentId === componentId
    );
    if (queueItem) {
      queueItem.isCompletionFinished = true;
    }
  };
};
