import React from "react";
import { Button } from "antd";
import { InputProps } from "./interfaces";
import DebouncedInput from "../../DebouncedInput";
import { useCurrentInputData } from "./useCurrentInputData";

const SocialLinks = (props: InputProps): JSX.Element => {
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    currentWebsite,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  let socialLinksInputs = content.items.map((item: any, key: number) => {
    let lastItem = content.items[content.items.length - 1];
    let maxItemsReached = lastItem.href !== "";
    let socialLinksCount = maxItemsReached
      ? content.items.length
      : content.items.length - 1; //if max not reached the last item is ghost

    let onChangeSocialLinkHrefHandler = (value: any) => {
      saveContentInStore(
        generator.changeSocialLinkHref(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };

    return (
      <div
        className={
          "content-input__cluster content-input__cluster--small " +
          (maxItemsReached === false
            ? " content-input__cluster--with-ghost "
            : " ")
        }
        data-add-new="Add new social network ⤵"
        key={"social-link-" + key}
      >
        <Button
          onClick={() => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.deleteSocialLink(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          }}
          htmlType="button"
          className="content-input__control_cluster"
          shape="circle"
          title="Delete this social link"
          icon="cross"
          size="small"
        />

        {displayMoveUpDownButtons(
          key,
          socialLinksCount,
          () => {
            saveContentInStore(
              generator.moveSocialLinkUp(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          },
          () => {
            saveContentInStore(
              generator.moveSocialLinkDown(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          }
        )}

        <DebouncedInput
          className="content-input__input"
          onChange={onChangeSocialLinkHrefHandler}
          defaultValue={item.href}
          placeholder={"https://twitter.com/" + currentWebsite.subdomain}
        />
      </div>
    );
  });

  let onChangeSocialLinksTitleHandler = (value: any) => {
    saveContentInStore(
      generator.changeSocialLinksTitle(
        { [contentType]: currentInputDataRef.current },
        value
      )
    );
    toggleNewEditionsDetected(true);
  };

  return (
    <div>
      {/*if has title and not single ghost left*/}
      {currentInputDataRef.current.hasTitle &&
        (content.items.length === 1 && content.items[0].href === "") ===
          false && (
          <div className="content-input__group">
            <DebouncedInput
              className="content-input__input"
              onChange={onChangeSocialLinksTitleHandler}
              defaultValue={content.title}
              placeholder={"Follow us:"}
            />
          </div>
        )}
      {socialLinksInputs}
    </div>
  );
};

export default SocialLinks;
