import React from 'react';
import { Icon, Tooltip } from 'antd';

interface Props {
    title: string | JSX.Element;
};

class ContentInputLabel extends React.Component<Props> {
    render() {
        return (
            <div className="content-input__label_box">
                <div className="content-input__tooltip_box">
                    <Tooltip placement="top" title={this.props.title}>
                        <Icon className="content-input__tooltip_icon" type="info-circle" />
                    </Tooltip>
                </div>
                {this.props.children}
            </div>
        );
    }
}

export default ContentInputLabel;