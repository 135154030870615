import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {pictogramOptions} from "../../data/pictogramOptions";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Links05 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Et tu Unicorn"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({
              isDefaultEmpty,
                subtitleText: 'Cum cursus ortum, omnes hilotaees fallere emeritis, noster deuses.'
            }));
            this.props.saveContentInStore(this.generator.setUpList({
              isDefaultEmpty,
                fieldTitle: {default: "Links"},
                label: {default: "Edit the links list:"},
                labelTooltip: {default: "Leave the title field blank to remove a link."},
                maxItems: 36,
                hasGroups: true,
                maxGroups: 12,
                groupTitleHasTitle: true,
                groupTitleHasPictogram: true,
                groupTitleHasSubtitle: false,
                groups: [
                    this.generator.createGroupContentObject({
                        title: 'Our Products',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f30e.svg'
                        })
                    }),
                    this.generator.createGroupContentObject({
                        title: 'Security Points',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f510.svg'
                        })
                    }),
                    this.generator.createGroupContentObject({
                        title: 'About Us',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f984.svg'
                        })
                    }),
                ],
                items: [
                    this.generator.createListItemContentObject({
                        title: 'Website Builder',
                        href: '#',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Corporate Blog',
                        href: '#',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Email Newsletter',
                        href: '#',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: 'CRM',
                        href: '#',
                        groupIndex: 0
                    }),
                    this.generator.createListItemContentObject({
                        title: 'The Radar',
                        href: '#',
                        groupIndex: 0
                    }),

                    // The last ghost item which is used to add new items.
                    this.generator.createListItemContentObject({
                        title: '',
                        href: '#',
                        groupIndex: 0
                    }),


                    this.generator.createListItemContentObject({
                        title: 'Free SSL',
                        href: '#',
                        groupIndex: 1
                    }),
                    this.generator.createListItemContentObject({
                        title: '2FA Options',
                        href: '#',
                        groupIndex: 1
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Access logging',
                        href: '#',
                        groupIndex: 1
                    }),

                    // The last ghost item which is used to add new items.
                    this.generator.createListItemContentObject({
                        title: '',
                        href: '#',
                        groupIndex: 1
                    }),


                    this.generator.createListItemContentObject({
                        title: 'Our Mission',
                        href: '#',
                        groupIndex: 2
                    }),
                    this.generator.createListItemContentObject({
                        title: 'The Team Behind',
                        href: '#',
                        groupIndex: 2
                    }),
                    this.generator.createListItemContentObject({
                        title: 'Customer Care',
                        href: '#',
                        groupIndex: 2
                    }),

                    // The last ghost item which is used to add new items.
                    this.generator.createListItemContentObject({
                        title: '',
                        href: '#',
                        groupIndex: 2
                    }),
                ],
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the component to offer more to read about your project.",
                    default: "Add a CTA (call to action) to the bottom of the component to offer more to read about your project.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'More'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let listItems = this.props.componentItem.componentData.list.content.items;
        let listGroups = this.props.componentItem.componentData.list.content.groups;

        return (
            <section>
                <div className="links-05">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container">
                        <div className="links-05__wrapper">
                            {listGroups && listGroups.map((group:any, groupKey:number) => {
                                if(group.title && group.title !== ''){
                                    return (
                                        <div className="links-05__list_box" key={'links-group-05-' + groupKey}>
                                            <div className="links-05__list_img">
                                                {group.pictogram.type && group.pictogram.type !== '' && <Pictogram pictogramObject={group.pictogram} className='' />}
                                            </div>
                                            <h3 className={"def-18_72 title-text--inner links-05__list_title " + (this.props.isDarkBg ? 'text-white' : '')}>{group.title}</h3>
                                            <ul className="links-05__list">
                                                {listItems && listItems.map((listItem:any, listItemKey:number) => {
                                                    if(listItem.title && listItem.title !== ''
                                                        &&
                                                        //Show only items of this group
                                                        listItem.groupIndex === groupKey){

                                                        return (
                                                            <li className="links-05__item" key={'links-item-05-' + listItemKey}>
                                                                <div className="links-05__item_content">
                                                                    <a
                                                                        href={returnHref(listItem.href)}
                                                                        className={"content-text links-05__link " + (this.props.isDarkBg ? 'text-white' : 'link')} >{listItem.title}</a>
                                                                </div>
                                                            </li>
                                                        )
                                                    }else{
                                                        return (null);
                                                    }
                                                })}

                                                {/*Flex box alignment hack*/}
                                                <li className="links-05__item"/>
                                            </ul>
                                        </div>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

export default Links05;
