import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

interface Data {
  email: string;
}

export const sendPasswordResetAsync = (
  accessToken: string,
  data: Data
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    axios
      .post(api.auth.sendPasswordResetLink, data, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => resolve(response))
      .catch((error) => {
        reject(error);
      });
  });
};
