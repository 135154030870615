import axios from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";

const actionsNames = {
  SAVE_PAGE_COMPONENTS_DATA: "SAVE_PAGE_COMPONENTS_DATA" as string,
  PAGE_COMPONENTS_DATA_FETCHED: "PAGE_COMPONENTS_DATA_FETCHED" as string,
};

export const fetchPageComponents = () => {
  return (dispatch: any, getState: any) => {
    const cacheParam = getState().meta.cache;
    const url = `${api.pageComponents.fetch}?v=${cacheParam}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(togglePageComponentsFetched(true));
        dispatch(savePageComponentsInState(response.data));
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

export const savePageComponentsInState = (data: string) => {
  // get data and store it
  return {
    type: actionsNames.SAVE_PAGE_COMPONENTS_DATA as string,
    payload: data as string,
  };
};

export const togglePageComponentsFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.PAGE_COMPONENTS_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export default actionsNames;
