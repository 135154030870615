import React, { useEffect } from "react";
import "./SocialPreview.scss";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { ChangePageItemGlobalData, WebsitePagesState } from "../../store/websitePages/types";
import { WebsitesState, GetCurrentStoreData } from "../../store/websites/types";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";
import classNames from "classnames";
import _ from "lodash";
import { getMetaDataFromComponentsArray } from "../../helpers/getMetaDataFromComponentsArray";
import { changePageItemGlobalData } from "../../store/websitePages/actions";

interface Props {
  websites: WebsitesState;
  websitesPages: WebsitePagesState;
  getCurrentStoreData: GetCurrentStoreData;
  changePageItemGlobalData: ChangePageItemGlobalData;

  setIsImageLoaded: (isImageLoaded: boolean) => void;
  isImageLoaded: boolean;
  settingsType: "website" | "blog" | "blogPost" | "page";
  displayedSettingsType?: "website" | "blog" | "blogPost" | "page";
  deleteButtonJsx: JSX.Element;
  overrideTitle?: string;
  overrideDescription?: string;
  disableLiveUpdate?: boolean;
}

const SocialPreview = (props: Props): JSX.Element => {
  const {
    getCurrentStoreData,
    websitesPages,
    websites,
    overrideTitle,
    overrideDescription,
    setIsImageLoaded,
    isImageLoaded,
    deleteButtonJsx,
    changePageItemGlobalData,
    disableLiveUpdate
  } = props;

  const { currentWebsitePage, currentWebsite, currentBlog, currentBlogPost } =
    getCurrentStoreData();

    useEffect(() => {
      // Update data on load, otherwise the data won't be relevant unless the user presses "Save changes"
      if (disableLiveUpdate) return; // This isn't required in every case, so we can disable it for performance
      if (!currentWebsitePage) return;
  
      const pageComponentItems = currentWebsitePage.page_components;
  
      if (!pageComponentItems) return;
  
      const {
        first_page_title,
        first_page_subtitle,
        hero_image_url,
        hero_image_uuid,
      } = getMetaDataFromComponentsArray(pageComponentItems);
  
      changePageItemGlobalData({
        pageId: currentWebsitePage.id,
        data: {
          first_page_title,
          first_page_subtitle,
          hero_image_url,
          hero_image_uuid,
        },
      });
    }, []);

  const getCurrentTitle = () => {
    let currentMetaTitle;
    let settingsType = props.displayedSettingsType || props.settingsType;

    const pageTitle = _.get(currentWebsitePage, "meta_title");
    const pageFirstTitle = _.get(currentWebsitePage, "first_page_title");

    currentMetaTitle = pageTitle;
    if (overrideTitle !== undefined) {
      currentMetaTitle = overrideTitle;
    }

    if (settingsType === "website" || settingsType === "page") {
      if (currentMetaTitle) {
        return currentMetaTitle;
      }
      if (pageFirstTitle) {
        return pageFirstTitle;
      }
    }

    const blogTitle = _.get(currentBlog, "meta_title");
    currentMetaTitle = blogTitle;
    if (overrideTitle !== undefined) {
      currentMetaTitle = overrideTitle;
    }

    if (settingsType === "blog") {
      if (currentMetaTitle) {
        return currentMetaTitle;
      }
      const companyName = _.get(
        currentWebsite,
        "nav.componentData.navLogo.content.companyTitle.text"
      );
      if (companyName) return `Blog | ${companyName}`;
      return "Blog";
    }

    const blogPostTitle = _.get(currentBlogPost, "meta_title");
    const blogPostRegularTitle = _.get(currentBlogPost, "title");
    currentMetaTitle = blogPostTitle;
    if (overrideTitle !== undefined) {
      currentMetaTitle = overrideTitle;
    }

    if (settingsType === "blogPost") {
      if (currentMetaTitle) {
        return currentMetaTitle;
      }
      if (blogPostRegularTitle) {
        return blogPostRegularTitle;
      }
    }

    return "No title";
  };

  const getCurrentDescription = () => {
    let currentMetaDescription;
    let settingsType = props.displayedSettingsType || props.settingsType;

    const pageDescription = _.get(currentWebsitePage, "meta_description");
    const pageFirstSubtitle = _.get(currentWebsitePage, "first_page_subtitle");

    currentMetaDescription = pageDescription;
    if (overrideDescription !== undefined) {
      currentMetaDescription = overrideDescription;
    }

    if (settingsType === "website" || settingsType === "page") {
      if (currentMetaDescription) {
        return currentMetaDescription;
      }
      if (pageFirstSubtitle) {
        return pageFirstSubtitle;
      }
    }

    const blogDescription = _.get(currentBlog, "meta_description");
    currentMetaDescription = blogDescription;
    if (overrideDescription !== undefined) {
      currentMetaDescription = overrideDescription;
    }

    if (settingsType === "blog") {
      if (currentMetaDescription) {
        return currentMetaDescription;
      }
      return "Explore a wide range of topics, insights, and ideas.";
    }

    const blogPostDescription = _.get(currentBlogPost, "meta_description");
    const blogPostFirstParagraph = _.get(
      currentBlogPost,
      "first_paragraph_text"
    );
    currentMetaDescription = blogPostDescription;
    if (overrideDescription !== undefined) {
      currentMetaDescription = overrideDescription;
    }

    if (settingsType === "blogPost") {
      if (currentMetaDescription) {
        return currentMetaDescription;
      }
      if (blogPostFirstParagraph) {
        return blogPostFirstParagraph;
      }
    }

    return "No description";
  };

  const getCurrentOgImageUrl = () => {
    if (props.settingsType === "page") {
      return _.get(currentWebsitePage, "og_image_url");
    }
    if (props.settingsType === "website") {
      return _.get(currentWebsite, "og_image_url");
    }
    if (props.settingsType === "blog") {
      return _.get(currentBlog, "og_image_url");
    }
    if (props.settingsType === "blogPost") {
      return _.get(currentBlogPost, "og_image_url");
    }
  };

  const ogImageUrl = formatImageUrl(getCurrentOgImageUrl());
  if (!ogImageUrl) return null;

  return (
    <div className="social-preview">
      <div
        className={classNames("social-preview__image-box", {
          "is-loading": !isImageLoaded,
          "is-empty": !ogImageUrl,
        })}
      >
        <img
          className="social-preview__image"
          src={ogImageUrl}
          onLoad={() => setIsImageLoaded(true)}
          style={{ opacity: !isImageLoaded ? 0 : 1 }}
        />
        {isImageLoaded && deleteButtonJsx}
      </div>
      <div className="social-preview__text-box">
        <h3 className="social-preview__title">{getCurrentTitle()}</h3>
        <div className="social-preview__subtitle">
          {getCurrentDescription()}
        </div>
        <div className="social-preview__url">
          {getActiveDomain(currentWebsite)}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  websitesPages: state.websitesPages,
  websites: state.websites,
});
export default connect(mapStateToProps, { getCurrentStoreData, changePageItemGlobalData })(SocialPreview);
