import React, {Component} from "react";
import { displayMarkupText } from "../../helpers/content_displayers/displayMarkupText";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import EditorCodeSnippet from "../EditorCodeSnippet";
import './Other01.css';

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}

class Other01 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;

            this.props.saveContentInStore(this.generator.setUpCode(
                {
                    priority: 300,
                    fieldTitle: {default: "HTML code"},
                    label: {default: "Enter HTML code:"},
                    labelTooltip: {default: "You can paste HTML code. If you need JS or CSS, use the <script\> and <style\> tags. Use  '.container' class to wrap your embed in a container. Use '.text-center' to align horizontaly."},
                    fieldInfo: {default: "Publish and open the page to view the result."},
                    hasGistUrl: false,
                    hasSubtitle: false,
                    hasTitle: false,
                    hasCodeSnippet: true,
                    codeSnippet: '',
                    isDefaultEmpty
                }
            ));

        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts


        let codeSnippet = displayMarkupText(this.props.componentItem.componentData.code.content.codeSnippet);

        return (
            <section>
                <div>
                    {/*<div dangerouslySetInnerHTML={{__html: codeSnippet}}/>*/}
                    {/* We do not run this code here because we can not be sure it will work properly and be safe. */}
                    <EditorCodeSnippet 
                      isDarkBg={this.props.isDarkBg}
                      codeSnippet={codeSnippet}
                    />
                </div>
            </section>
        )
    }
}

export default Other01;
