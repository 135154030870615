import React from "react";
import ConditionalDiv from "../components/ConditionalDiv";
import { publicUrl } from "../data/urls";

interface Props {
  className?: string;
  isDarkBg: boolean;
  codeSnippet: string;
  isBackgroundAlwaysLight?: boolean;
  containerClassName?: string;
}

const EditorCodeSnippet = (props: Props): JSX.Element => {
  const {
    isDarkBg,
    codeSnippet,
    className,
    isBackgroundAlwaysLight,
    containerClassName,
  } = props;

  return (
    <ConditionalDiv
      condition={!!containerClassName}
      className={containerClassName}
    >
      <div className={`other-01 ${className || ""}`}>
        <div className="other-01__logo_box">
          <img
            className="other-01__logo"
            src={
              publicUrl + "/img/logos/unicorn-platform-logo.svg"
            }
            alt="Unicorn Platform logo"
          />
        </div>
        <div
          className={
            "other-01__wrapper " +
            (codeSnippet ? " other-01__wrapper-has_content " : " ")
          }
        >
          <div className="other-01__text">
            <div
              className={
                "other-01__title " +
                (isDarkBg && !isBackgroundAlwaysLight ? "text-white" : "")
              }
            >
              Your code is not executed in the Unicorn&nbsp;Platform page
              editor.
            </div>
            <div
              className={
                "other-01__info " +
                (isDarkBg && !isBackgroundAlwaysLight ? "text-white" : "")
              }
            >
              Due to security reasons.
            </div>
          </div>
          {codeSnippet && (
            <div
              className={
                "other-01__code " +
                (isDarkBg && !isBackgroundAlwaysLight ? "text-white" : "")
              }
            >
              {codeSnippet}
            </div>
          )}
        </div>
      </div>
    </ConditionalDiv>
  );
};

export default EditorCodeSnippet;
