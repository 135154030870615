import axios from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";

const actionsNames = {
  SAVE_FORM_INTEGRATIONS_DATA: "SAVE_FORM_INTEGRATIONS_DATA" as string,
  FORM_INTEGRATIONS_DATA_FETCHED: "FORM_INTEGRATIONS_DATA_FETCHED" as string,
};

export const fetchFormIntegrations = () => {
  return (dispatch: any, getState: any) => {
    const cacheParam = getState().meta.cache;
    const url = `${api.formsIntegrations.fetch}?v=${cacheParam}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(toggleFormIntegrationsFetched(true));
        dispatch(saveFormIntegrationsInState(response.data));
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

export const saveFormIntegrationsInState = (data: string) => {
  // get data and store it
  return {
    type: actionsNames.SAVE_FORM_INTEGRATIONS_DATA as string,
    payload: data as string,
  };
};

export const toggleFormIntegrationsFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.FORM_INTEGRATIONS_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export default actionsNames;
