import React from "react";
import { Button, Popconfirm } from "antd";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  item: any;
  saveContentInStore: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: (value: boolean) => void;
}

const GalleryDeleteSlideButton = (props: Props): JSX.Element => {
  const {
    item,
    saveContentInStore,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);
  const generator = new generateContentDataObject();

  return (
    <Popconfirm
      placement="bottomRight"
      title={
        <span>
          Delete the slide{" "}
          <b>
            {item.tabTitle &&
            item.tabTitle !== "" &&
            currentInputDataRef.current.tabHasSubtitle
              ? item.tabTitle
              : "#" + (keyProp + 1)}
          </b>{" "}
          and all its content?
        </span>
      }
      onConfirm={() => {
        toggleNewEditionsDetected(true);
        saveContentInStore(
          generator.deleteGalleryItem(
            { [contentType]: currentInputDataRef.current },
            keyProp
          )
        );
      }}
      okText="Delete"
      cancelText="Cancel"
    >
      <Button
        htmlType="button"
        className="content-input__control_cluster"
        shape="circle"
        title="Delete this item and content"
        icon="cross"
        size="small"
      />
    </Popconfirm>
  );
};

export default GalleryDeleteSlideButton;
