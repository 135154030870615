import React from "react";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  item: any;
  currentInputData: any;
  keyProp: number;
  displayPictogramEditorOpener: (pictogram: any, key: number) => JSX.Element;
}

const GalleryTabPictogram = (props: Props): JSX.Element => {
  const {
    item,
    currentInputData,
    keyProp,
    displayPictogramEditorOpener,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  if (!currentInputDataRef.current.tabHasPictogram) return null;

  return displayPictogramEditorOpener(item.tabPictogram, keyProp);
};

export default GalleryTabPictogram;
