import _ from "lodash";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.QUESTION) || "";
  const itemText = stringifyObjectsAndNumbers(modifiedItem.ANSWER) || "";
  return {
    itemId,
    itemPosition,
    itemTitle,
    itemText,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemTitle,
  itemText
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.faq.content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.faq.content.items, item);
      }
    });
  }
  if ("QUESTION" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("ANSWER" in modifiedItem) {
    originalItem.text = itemText;
  }
};

export const updateFaq = (componentData, completionContent) => {
  if (!("FAQ" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.FAQ)) {
    if (completionContent.FAQ === null) {
      componentData.faq.content.items = [];
    }
    return;
  }
  componentData.faq.content.items = deleteEmptyItems(
    componentData.faq.content.items,
    "title"
  );
  addDefaultValuesToItems(componentData.faq.content.items);
  completionContent.FAQ.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemTitle,
      itemText,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      componentData.faq.content.items = deleteItem(
        componentData.faq.content.items,
        itemId
      );
      return;
    }
    const isItemNew = getIsItemNew(componentData.faq.content.items, itemId);
    if (isItemNew) {
      const newItem = generator.createFaqContentObject();
      newItem.title = "";
      newItem.text = "";
      addNewItem(
        componentData.faq.content.items,
        newItem,
        itemId,
        itemPosition
      );
    }
    const originalItem = getOriginalItem(
      componentData.faq.content.items,
      itemId
    );
    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemTitle,
      itemText
    );
  });
  sortItemsByPosition(componentData.faq.content.items);
};
