import React, { useEffect, useState } from "react";
import { Empty, Table } from "antd";
import { connect } from "react-redux";
import {
  Oauth2State,
  SaveOauth2AccessTokensInState,
} from "../../../../store/oauth2/types";
import { saveOauth2AccessTokensInState } from "../../../../store/oauth2/actions";
import { fetchOauth2AccessTokensAsync } from "../../../../helpers/auth/fetchOauth2AccessTokensAsync";
import SpinnerBox from "../../SpinnerBox";
import { format, parse } from "date-fns";
import DeleteOauth2TokenButton from "./DeleteOauth2TokenButton";
import Oauth2AppName from "./Oauth2AppName";
import "./ApplicationsMenu.css";

interface Props {
  oauth2: Oauth2State;
  auth: any;
  saveOauth2AccessTokensInState: SaveOauth2AccessTokensInState;
}

const ApplicationsMenu = (props: Props): JSX.Element => {
  const { oauth2, auth } = props;
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    if (!isDataFetched) {
      fetchOauth2AccessTokensAsync(auth.accessToken).then((response) => {
        props.saveOauth2AccessTokensInState(response.data);
        setIsDataFetched(true);
      });
    }
  }, []);

  if (!isDataFetched) {
    return <SpinnerBox />;
  }

  const dataSource = oauth2.accessTokens.map((token, index) => {
    const date = format(parse(token.created), "MMM DD, YYYY, hh:mm a");
    return {
      key: index,
      name: <Oauth2AppName token={token} />,
      delete: (
        <DeleteOauth2TokenButton token={token}>Delete</DeleteOauth2TokenButton>
      ),
      date,
    };
  });

  const columns = [
    {
      title: "App",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Date added",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
    },
  ];

  return (
    <>
      <h4>Authorized apps</h4>
      <Table
        className="application_menu__table"
        dataSource={dataSource.reverse()}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              className="applications_menu__empty_image"
              description="Third party applications that have been granted access to your account will appear here."
            />
          ),
        }}
      />
    </>
  );
};

const mapStateToProps = ({ oauth2, auth }) => {
  return { oauth2, auth };
};
const mapActionsToProps = {
  saveOauth2AccessTokensInState,
};
export default connect(mapStateToProps, mapActionsToProps)(ApplicationsMenu);
