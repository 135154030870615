import {projectUrl} from "../../data/constants";

export const getFullTemplatePageUrl = (currentTemplateSubdomain:any, websitePageUrl:string) => {
    let subdomainUrl = 'https://' + currentTemplateSubdomain + '.' + projectUrl + '/' + websitePageUrl;

    let fullTemplatePageUrl = subdomainUrl;

    if (websitePageUrl !== '') {
        fullTemplatePageUrl = fullTemplatePageUrl + '/';
    }

    return fullTemplatePageUrl;
};