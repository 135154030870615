import React from "react";
import { Button } from "antd";
import { draftJsEditorTitle } from "../../../data/constants";
import { InputProps } from "./interfaces";
import DebouncedInput from "../../DebouncedInput";
import DebouncedTextarea from "../../DebouncedTextarea";
import MyRichTextEditor from "../../MyRichTextEditor";
import ImagePicker from "../ImagePicker";
import { GraphicsOptions } from "../component_graphics/data/componentGraphicsData";
import { useCurrentInputData } from "./useCurrentInputData";

const Team = (props: InputProps): JSX.Element => {
  const {
    currentInputData,
    content,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    textEditorType,
    revertToPlainHTML,
    displayMoveUpDownButtons,
    saveImageInfoInDB,
    removeImageFromDB,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  let maxItemsReached =
    currentInputDataRef.current.maxItems === content.items.length;

  let teamInputs = content.items.map((teamMemberItem: any, teamKey: number) => {
    let socialLinksInputs = teamMemberItem.socialNetworks.map(
      (socialLink: any, socialNetworkKey: number) => {
        let lastItem =
          teamMemberItem.socialNetworks[
            teamMemberItem.socialNetworks.length - 1
          ];
        let maxItemsReached = lastItem.href !== "";

        let onChangeSocialNetworkHrefHandler = (value: any) => {
          saveContentInStore(
            generator.changeTeamMemberSocialNetworkHref(
              { [contentType]: currentInputDataRef.current },
              teamKey,
              socialNetworkKey,
              value
            )
          );
          toggleNewEditionsDetected(true);
        };

        return (
          <div
            className={
              "content-input__cluster " +
              (maxItemsReached === false
                ? " content-input__cluster--with-ghost "
                : " ")
            }
            data-add-new="Add new social network ⤵"
            key={"team-member-social-link-" + socialNetworkKey}
          >
            <Button
              onClick={(event: any) => {
                toggleNewEditionsDetected(true);
                saveContentInStore(
                  generator.deleteTeamMemberSocialNetwork(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    teamKey,
                    socialNetworkKey
                  )
                );
              }}
              htmlType="button"
              className="content-input__control_cluster"
              shape="circle"
              title="Delete this social link"
              icon="cross"
              size="small"
            />

            <DebouncedInput
              className="content-input__input"
              onChange={onChangeSocialNetworkHrefHandler}
              defaultValue={socialLink.href}
              placeholder={"https://twitter.com/johnrushx"}
            />
          </div>
        );
      }
    );

    let onChangeTeamMemberNameHandler = (value: any) => {
      saveContentInStore(
        generator.changeTeamMemberName(
          { [contentType]: currentInputDataRef.current },
          teamKey,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeTeamMemberJobTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changeTeamMemberJobTitle(
          { [contentType]: currentInputDataRef.current },
          teamKey,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeTeamMemberInfoHandler = (value: any) => {
      saveContentInStore(
        generator.changeTeamMemberInfo(
          { [contentType]: currentInputDataRef.current },
          teamKey,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeTeamMemberUsernameHandler = (value: any) => {
      saveContentInStore(
        generator.changeTeamMemberUsername(
          { [contentType]: currentInputDataRef.current },
          teamKey,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeTeamMemberHrefHandler = (value: any) => {
      saveContentInStore(
        generator.changeTeamMemberHref(
          { [contentType]: currentInputDataRef.current },
          teamKey,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };

    let returnTeamMemberInfoField = () => {
      if (textEditorType && textEditorType === draftJsEditorTitle) {
        return (
          <div className="content-input__input">
            <MyRichTextEditor
              revertToPlainHTML={revertToPlainHTML}
              placeholder={"Enter the bio of " + teamMemberItem.name}
              defaultValue={teamMemberItem.info}
              onChange={onChangeTeamMemberInfoHandler}
            />
          </div>
        );
      } else {
        return (
          <DebouncedTextarea
            className="content-input__input"
            onChange={onChangeTeamMemberInfoHandler}
            defaultValue={teamMemberItem.info}
            autosize={{ minRows: 1, maxRows: 6 }}
            placeholder={"Enter the bio of " + teamMemberItem.name}
          />
        );
      }
    };

    return (
      <div
        className={"content-input__cluster "}
        key={"team-members-" + teamKey}
      >
        {displayMoveUpDownButtons(
          teamKey,
          content.items.length,
          () => {
            saveContentInStore(
              generator.moveTeamMemberUp(
                {
                  [contentType]: currentInputDataRef.current,
                },
                teamKey
              )
            );
          },
          () => {
            saveContentInStore(
              generator.moveTeamMemberDown(
                {
                  [contentType]: currentInputDataRef.current,
                },
                teamKey
              )
            );
          }
        )}
        <Button
          onClick={(event: any) => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.deleteTeamMember(
                {
                  [contentType]: currentInputDataRef.current,
                },
                teamKey
              )
            );
          }}
          htmlType="button"
          className="content-input__control_cluster"
          shape="circle"
          title="Remove this member"
          icon="cross"
          size="small"
        />

        {currentInputDataRef.current.hasPhoto && (
          <div className="content-input__input">
            <ImagePicker
              currentImgAlt={teamMemberItem.graphics[GraphicsOptions.image].alt}
              saveImageAltInReduxStore={(newAlt: string) => {
                saveContentInStore(
                  generator.changeTeamMemberImageAlt(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    newAlt,
                    teamKey
                  )
                );

                toggleNewEditionsDetected(true);
              }}
              currentImgSrc={teamMemberItem.graphics[GraphicsOptions.image].url}
              currentImgUUID={
                teamMemberItem.graphics[GraphicsOptions.image].uuid
              }
              inputId={contentType}
              saveImageInfoInDB={saveImageInfoInDB}
              removeImageFromDB={removeImageFromDB}
              saveImageInReduxStore={(
                newSrc: string,
                newUUID: any,
                width: any,
                height: any
              ) => {
                saveContentInStore(
                  generator.changeTeamMemberImageUrl(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    newSrc,
                    teamKey
                  )
                );
                saveContentInStore(
                  generator.changeTeamMemberImageUUID(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    newUUID,
                    teamKey
                  )
                ); //'' for no image

                saveContentInStore(
                  generator.changeTeamMemberImageWidth(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    width,
                    teamKey
                  )
                );
                saveContentInStore(
                  generator.changeTeamMemberImageHeight(
                    {
                      [contentType]: currentInputDataRef.current,
                    },
                    height,
                    teamKey
                  )
                );

                toggleNewEditionsDetected(true);
              }}
            />
          </div>
        )}

        <div className="content-input__group">
          {currentInputDataRef.current.hasName && (
            <DebouncedInput
              className="content-input__input"
              onChange={onChangeTeamMemberNameHandler}
              defaultValue={teamMemberItem.name}
              placeholder="Enter name"
            />
          )}

          {currentInputDataRef.current.hasJobTitle && (
            <DebouncedInput
              className="content-input__input"
              onChange={onChangeTeamMemberJobTitleHandler}
              defaultValue={teamMemberItem.jobTitle}
              placeholder="Enter job title"
            />
          )}

          {currentInputDataRef.current.hasInfo &&
            teamMemberItem.name !== "" &&
            returnTeamMemberInfoField()}
        </div>

        <div className="content-input__group">
          {currentInputDataRef.current.hasUsername && (
            <DebouncedInput
              className="content-input__input"
              onChange={onChangeTeamMemberUsernameHandler}
              defaultValue={teamMemberItem.username}
              placeholder="@alexanderisora"
            />
          )}
          {currentInputDataRef.current.hasHref && (
            <DebouncedInput
              className="content-input__input"
              onChange={onChangeTeamMemberHrefHandler}
              defaultValue={teamMemberItem.href}
              placeholder="https://isora.me"
            />
          )}

          {currentInputDataRef.current.hasSocialNetworks && socialLinksInputs}
        </div>
      </div>
    );
  });

  return (
    <div>
      {teamInputs}

      {maxItemsReached === false && (
        <Button
          size="small"
          onClick={(event: any) => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.addEmptyTeamMembersIfNeeded({
                [contentType]: currentInputDataRef.current,
              })
            );
          }}
          icon="plus"
        >
          Add new member
        </Button>
      )}
    </div>
  );
};

export default Team;
