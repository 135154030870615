import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import axios from "axios";
import {api} from "../../data/urls";
import objectToFormData from "../../helpers/objectToFormData";
import {openNotification} from "../../helpers/openNotification";
import {message, Icon} from "antd";

interface Props {
    auth: any,
    emailConfirmationToggleServerResponded: any,
    fetchCurrentUserInfo: any,
}

class ConfirmEmail extends Component<Props>{
    componentDidMount(): void {
        this.confirmEmail();
    }

    confirmEmail(): void{
        //    url example: "/rest-auth/registration/account-confirm-email/NTQ:1h9Bk6:FkmihG5Wl0ph6Vy4OBl5-LkmIPs/"
        //    key: "NTQ:1h9Bk6:FkmihG5Wl0ph6Vy4OBl5-LkmIPs"
        let key = window.location.pathname.split('/')[4];
        let valuesToServer = {key: key};

        message.loading('The email confirmation is in process...', 0);

        axios.post(api.auth.verifyEmailAfterRegistration, objectToFormData(valuesToServer) )
            .then(response => {
                // handle success

                // We need to re-fetch user data because it became verified now and we need to update state.
                this.props.fetchCurrentUserInfo();

                openNotification('Success!', 'Your email has been confirmed. Thanks and enjoy!', 'Close', 'success', 5);
            })
            .catch(error => {
                // handle error
                openNotification('Error occurred!', 'Your email was not activated. Please contact us to activate your account. Really sorry for that 😐', 'Close', 'error', 0);
            })
            .then(response => {
                // always executed

                // after we tried to confirm - we always redirect to the home dashboard URL.
                this.props.emailConfirmationToggleServerResponded(true);
                message.destroy();
            });
    }

    render(){
        if (this.props.auth.emailConfirmation.serverResponded){
            return (
                <Redirect to='/'/>
            )
        }

        return (
            <div>
            </div>
        )
    }
}

export default ConfirmEmail;