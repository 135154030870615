import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { resendVerificationLinkAsync } from "../../helpers/account/resendVerificationLinkAsync";
import { openNotification } from "../../helpers/openNotification";
import { toggleResendEmailConfirmationWaiting } from "../../store/auth/actions";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { notificationDuration } from "../../data/constants";
import { Button, Icon } from "antd";

interface Props {
  auth: any;
  user: any;
  children: React.ReactNode;
  toggleResendEmailConfirmationWaiting: (payload: boolean) => void;
  type?: "button" | "link";
}

const ResendConfirmationButton = (props: Props): JSX.Element => {
  const { children, auth, user, type } = props;

  const handleOnResend = () => {
    if (auth.resendEmailVerifyWaiting) return;
    props.toggleResendEmailConfirmationWaiting(true);
    resendVerificationLinkAsync(auth.accessToken)
      .then(() => {
        openNotification(
          "Check your inbox!",
          <>
            A new verification email has just been sent to{" "}
            <b>{user.data.email}</b>. Please open your inbox and click the link.
            Check the spam folder too!
          </>,
          "OK",
          "info",
          15
        );
        fireAnalyticsEvent.fireCrisp(CrispEvents.resentVerificationEmail, {
          email: user.data.email,
        });
      })
      .catch((error) => {
        let errorData = error.response.data;
        if (errorData.error !== undefined) {
          fireAnalyticsEvent.fireCrisp(
            CrispEvents.resentVerificationEmailError,
            {
              email: user.data.email,
              error_message: errorData.error,
            },
            true
          );
          openNotification(
            "Nope",
            errorData.error,
            "OK",
            "error",
            notificationDuration.medium
          );
        }
      })
      .finally(() => {
        props.toggleResendEmailConfirmationWaiting(false);
      });
  };

  if (type === "link") {
    return (
      <button
        className={classNames("content-input__tooltip-link", {
          "content-input__tooltip-link--disabled":
            auth.resendEmailVerifyWaiting,
        })}
        onClick={handleOnResend}
      >
        {children}
        {auth.resendEmailVerifyWaiting && (
          <Icon
            type="loading"
            className="content-input__tooltip-link-icon--loading"
          />
        )}
      </button>
    );
  }

  return (
    <Button
      loading={auth.resendEmailVerifyWaiting}
      onClick={handleOnResend}
      icon="redo"
      size="small"
    >
      {children}
    </Button>
  );
};

const mapStateToProps = ({ auth, user }) => {
  return {
    auth,
    user,
  };
};
const mapActionsToProps = {
  toggleResendEmailConfirmationWaiting,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(ResendConfirmationButton);
