import React from "react";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import DebouncedInput from "../../../DebouncedInput";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  item: any;
  saveContentInStore: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: (value: boolean) => void;
}

const GallerySliderTitle = (props: Props): JSX.Element => {
  const {
    item,
    saveContentInStore,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const generator = new generateContentDataObject();

  let onChangeGalleryContentTitleHandler = (value: any) => {
    saveContentInStore(
      generator.changeGalleryContentTitle(
        { [contentType]: currentInputDataRef.current },
        keyProp,
        value
      )
    );
    toggleNewEditionsDetected(true);
  };

  if (!currentInputDataRef.current.contentHasTitle) return null;

  return (
    <DebouncedInput
      className="content-input__input"
      onChange={onChangeGalleryContentTitleHandler}
      defaultValue={item.contentTitle}
      placeholder="Enter slide title"
    />
  );
};

export default GallerySliderTitle;
