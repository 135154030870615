import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

export const cancelPlanAsync = (
  accessToken: string
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    const apiUrl = api.account.cancelPlan;
    axios
      .post(apiUrl, {}, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
