import { formatImageUrl } from "./strings/formatImageUrl";

export const formatAndResizeImageUrl = (url: string, width: number) => {
  let formattedUrl = formatImageUrl(url);

  if (formattedUrl.includes("?width")) {
    return formattedUrl;
  }

  if (
    formattedUrl.endsWith(".png") ||
    formattedUrl.endsWith(".jpg") ||
    formattedUrl.endsWith(".jpeg") ||
    formattedUrl.endsWith(".gif") ||
    formattedUrl.endsWith(".webp")
  ) {
    return `${formattedUrl}?width=${width}`;
  }

  return `${formattedUrl}image.png?width=${width}`;
};
