import React from "react";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import getSocialNetworkFromUrl from "../../helpers/editor/getSocialNetworkFromUrl";
import getSocialNetworkIcon from "../../helpers/editor/getSocialNetworkIcon";
import addProtocolToLink from "../../helpers/strings/addProtocolToLink";

interface Props {
  links: any;
  colorType: string;
  direction: string;
}

const WebsiteSocialNetworkLinks = (props: Props): JSX.Element => {
  const { links, colorType, direction } = props;

  // colorType may be: 'white', 'black' or 'color'
  let socialLinks = links.map((item: any, key: number) => {
    let href = item.href;

    if (href && href !== "") {
      let socialNetworkTitle = getSocialNetworkFromUrl(href);
      return (
        <li className="social-buttons__item" key={key}>
          <a
            className={
              "social-buttons__link social-buttons__link--" + socialNetworkTitle
            }
            href={returnHref(addProtocolToLink(href))}
          >
            <img
              alt={socialNetworkTitle + " logo"}
              className="social-buttons__icon"
              src={getSocialNetworkIcon(socialNetworkTitle, colorType)}
            />
          </a>
        </li>
      );
    } else {
      return null;
    }
  });

  let directionClassName = "";
  if (direction === "right") {
    directionClassName = " social-buttons--right ";
  } else if (direction === "left") {
    directionClassName = " social-buttons--left ";
  }

  return (
    <div className={"social-buttons " + directionClassName}>
      <ul className="social-buttons__list">{socialLinks}</ul>
    </div>
  );
};

export default WebsiteSocialNetworkLinks;
