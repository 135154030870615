import { sharedAccessesReducer } from "./sharedAccesses/reducers";
import { combineReducers } from "redux";
import { authReducer } from "./auth/reducers";
import { userReducer } from "./user/reducers";
import { plansReducer } from "./plans/reducers";
import { templatesReducer } from "./templates/reducers";
import { websitesReducer } from "./websites/reducers";
import { websitePagesReducer } from "./websitePages/reducers";
import { blogsReducer } from "./blogs/reducers";
import { blogPostsReducer } from "./blogPosts/reducers";
import { paymentReducer } from "./payment/reducers";
import { pageComponentsReducer } from "./pageComponents/reducers";
import { pageComponentCategoriesReducer } from "./pageComponentCategories/reducers";
import { formIntegrationsReducer } from "./formIntegrations/reducers";
import { MetaReducer } from "./meta/reducers";
import { oauth2Reducer } from "./oauth2/reducers";
import { gptReducer } from "./gpt/reducers";

export default combineReducers({
  auth: authReducer as any,
  user: userReducer as any,
  plans: plansReducer as any,
  templates: templatesReducer as any,
  websites: websitesReducer as any,
  websitesPages: websitePagesReducer as any,
  blogs: blogsReducer as any,
  sharedAccesses: sharedAccessesReducer as any,
  meta: MetaReducer as any,
  blogPosts: blogPostsReducer as any,
  payment: paymentReducer as any,
  pageComponents: pageComponentsReducer as any,
  pageComponentCategories: pageComponentCategoriesReducer as any,
  formIntegrations: formIntegrationsReducer as any,
  oauth2: oauth2Reducer,
  gpt: gptReducer,
});
