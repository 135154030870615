export enum Components {
  header65 = "header-65",
  header64 = "header-64",
  header63 = "header-63",
  header43 = "header-43",
  header35 = "header-35",
  header27 = "header-27",
  header03 = "header-03",
  header44 = "header-44",
  header39 = "header-39",
  header29 = "header-29",
  header62 = "header-62",
  header49 = "header-49",
  header61 = "header-61",
  header28 = "header-28",
  header04 = "header-04",
  header60 = "header-60",
  header23 = "header-23",
  pricing05 = "pricing-05",
  team03 = "team-03",
  team02 = "team-02",
  team01 = "team-01",
  other01 = "other-01",
  other02 = "other-02",
  other03 = "other-03",
  steps03 = "steps-03",
  steps01 = "steps-01",
  contact02 = "contact-02",
  contact01 = "contact-01",
  testimonials05 = "testimonials-05",
  testimonials02 = "testimonials-02",
  testimonials01 = "testimonials-01",
  clients03 = "clients-03",
  clients02 = "clients-02",
  photos01 = "photos-01",
  video04 = "video-04",
  faq03 = "faq-03",
  faq02 = "faq-02",
  code02 = "code-02",
  code01 = "code-01",
  press03 = "press-03",
  press02 = "press-02",
  posts05 = "posts-05",
  posts04 = "posts-04",
  posts03 = "posts-03",
  posts02 = "posts-02",
  links06 = "links-06",
  links05 = "links-05",
  links04 = "links-04",
  links03 = "links-03",
  links02 = "links-02",
  links01 = "links-01",
  text08 = "text-08",
  text07 = "text-07",
  text06 = "text-06",
  text01 = "text-01",
  pricing03 = "pricing-03",
  pricing04 = "pricing-04",
  pricing02 = "pricing-02",
  ctaButton25 = "cta_button-25",
  ctaButton16 = "cta_button-16",
  ctaButton12 = "cta_button-12",
  ctaForm04 = "cta_form-04",
  features09 = "features-09",
  features07 = "features-07",
  features06 = "features-06",
  features05 = "features-05",
  video03 = "video-03",
  video02 = "video-02",
  photos02 = "photos-02",
  clients01 = "clients-01",
  ctaForm01 = "cta_form-01",
  ctaButton03 = "cta_button-03",
  ctaButton21 = "cta_button-21",
  features03 = "features-03",
  features02 = "features-02",
  text03 = "text-03",
  text02 = "text-02",
  slider07 = "slider-07",
  slider05 = "slider-05",
  slider10 = "slider-10",
  tabs04 = "tabs-04",
  tabs03 = "tabs-03",
  tabs10 = "tabs-10",
  tabs01 = "tabs-01",
  slider01 = "slider-01",
  posts01 = "posts-01",
  faq01 = "faq-01",
  pricing01 = "pricing-01",
  features01 = "features-01",
  press01 = "press-01",
  ctaForm03 = "cta_form-03",
  text04 = "text-04",
  photos03 = "photos-03",
  video01 = "video-01",
  ctaButton01 = "cta_button-01",
  popups01 = "popups-01",
  footer = "footer",
  navigation = "navigation",
}
