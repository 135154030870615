import React from "react";
import InputLabel from "../InputLabel";
import AddPopupButton from "./AddPopupButton";
import PopupSwitch from "./PopupSwitch";
import PopupItems from "./PopupItems";
import "./PopupMenu.css";
import { getPopupDataFromInputData } from "../../../helpers/editor/getPopupDataFromInputData";
import {
  ComponentDataPaths,
  PopupType,
} from "../../../helpers/types/popupTypes";

interface Props {
  className?: string;
  popupType: PopupType;

  contentType: any;
  currentInputData: any;
  buttonIndex?: number;

  saveContentInStore: (payload: object) => void;
  toggleNewEditionsDetected: (payload: boolean) => void;

  componentDataPath: ComponentDataPaths;
}

const PopupMenu = (props: Props): JSX.Element => {
  const { currentInputData, popupType, componentDataPath, contentType } = props;
  const popupData = getPopupDataFromInputData(
    currentInputData,
    popupType,
    componentDataPath,
    contentType
  );
  const generateTextInfo = () => {
    if (props.popupType === "form") {
      return {
        label: "Open a popup:",
        tooltip:
          "Create a popup window and open it after this form is submitted.",
      };
    }
    return {
      label: "Open a popup on button click:",
      tooltip: "Create a popup window and open it when this button is clicked.",
    };
  };
  const textInfo = generateTextInfo();
  return (
    <div className={`popup-menu ${props.className || ""}`}>
      <InputLabel className="popup-menu__label" tooltip={textInfo.tooltip}>
        {textInfo.label}
      </InputLabel>
      <div className="popup-menu__content">
        <PopupSwitch
          className="popup-menu__switch"
          buttonIndex={props.buttonIndex}
          contentType={props.contentType}
          currentInputData={props.currentInputData}
          popupType={props.popupType}
          saveContentInStore={props.saveContentInStore}
          toggleNewEditionsDetected={props.toggleNewEditionsDetected}
          popupData={popupData}
          componentDataPath={props.componentDataPath}
        />
        <PopupItems
          className="popup-menu__items"
          popupData={popupData}
          popupType={props.popupType}
          buttonIndex={props.buttonIndex}
          contentType={props.contentType}
          currentInputData={props.currentInputData}
          saveContentInStore={props.saveContentInStore}
          toggleNewEditionsDetected={props.toggleNewEditionsDetected}
          componentDataPath={props.componentDataPath}
        />
        <AddPopupButton className="popup-menu__button" />
      </div>
    </div>
  );
};

export default PopupMenu;
