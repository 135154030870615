import React from "react";
import { Button, Drawer, Icon, Tag } from "antd";
import IntegrationsEmpty from "../formIntegration/IntegrationsEmpty";
import ContentInputLabel from "../ContentInputLabel";
import CaptchaDrawer from "./CaptchaDrawer";
import { checkIfCaptchaEnabled } from "../../../helpers/captcha/checkIfCaptchaEnabled";
import "./CaptchaConfig.css";
import { urls } from "../../../data/urls";
import { generatePopupDrawerClass } from "../../../helpers/generatePopupDrawerClass";
import { connect } from "react-redux";
import { checkIfCaptchaAvailableForUser } from "../../../helpers/captcha/checkIfCaptchaAvailableForUser";

interface Props {
  currentWebsite: any;
  componentType: string;
  user?: any;
  plans?: any;
  sharedAccesses?: any;
}

interface State {
  isCaptchaDrawerVisible: boolean;
}

class CaptchaConfig extends React.Component<Props, State> {
  info = {
    title: "Invisible reCAPTCHA:",
    popover: (
      <div className="content-input__tooltip-content">
        <p>
          Activate invisible reCAPTCHA v2 to protect your contact forms from
          spam.
        </p>
        <p>
          Check out{" "}
          <a href={urls.guides.recaptcha} target="_blank">
            our simple guide
          </a>{" "}
          to learn more.
        </p>
      </div>
    ),
  };

  constructor(props) {
    super(props);
    this.state = {
      isCaptchaDrawerVisible: false,
    };
  }

  openCaptchaDrawer = () => {
    this.setState({
      isCaptchaDrawerVisible: true,
    });
  };

  closeCaptchaDrawer = () => {
    this.setState({
      isCaptchaDrawerVisible: false,
    });
  };

  handleOnClick = () => {
    this.openCaptchaDrawer();
  };

  displayContent = () => {
    const { user, plans, sharedAccesses, currentWebsite } = this.props;
    const isCaptchaEnabled = checkIfCaptchaEnabled(this.props.currentWebsite);
    const isCaptchaAvailable = checkIfCaptchaAvailableForUser(
      user,
      plans,
      sharedAccesses,
      currentWebsite
    );

    if (isCaptchaEnabled && isCaptchaAvailable) {
      return (
        <div className="captcha-config__active">
          <Button
            htmlType="button"
            type="default"
            icon="edit"
            onClick={this.openCaptchaDrawer}
            size="small"
          >
            Manage reCAPTCHA
          </Button>
          <Tag className="captcha-config__tag" color="green">
            Active <Icon type="check" />
          </Tag>
        </div>
      );
    }
    return (
      <IntegrationsEmpty
        buttonText="Activate reCAPTCHA"
        onClick={this.handleOnClick}
      >
        reCAPTCHA is not activated.
      </IntegrationsEmpty>
    );
  };

  render() {
    return (
      <div className="captcha-config">
        <div style={{ marginTop: "40px" }}>
          <ContentInputLabel title={this.info.popover}>
            {this.info.title}
          </ContentInputLabel>
          {this.displayContent()}
        </div>
        <Drawer
          title="Manage reCAPTCHA"
          onClose={this.closeCaptchaDrawer}
          visible={this.state.isCaptchaDrawerVisible}
          width={400}
          className={generatePopupDrawerClass(this.props.componentType)}
        >
          <CaptchaDrawer currentWebsite={this.props.currentWebsite} />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  const { user, plans, sharedAccesses } = state;
  return {
    user,
    plans,
    sharedAccesses,
  };
};
export default connect(mapStateToProps, null)(CaptchaConfig);
