import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {pictogramOptions} from "../../data/pictogramOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Features03 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Strong Base for Sustainable Growth"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                maxItems: 16,

                labelTooltip: {default: "Leave the title field blank to remove a feature."},

                hasSubtitle: true,
                hasText: false,
                hasPictogram: true,
                hasHighlight: false,
                hasHighlightInfo: false,
                hasButton: false,

                items: [
                    this.generator.createFeatureContentObject({
                        title: '100+',
                        subtitle: 'configurations',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f938.svg'
                        })
                    }),
                    this.generator.createFeatureContentObject({
                        title: '3',
                        subtitle: 'security layers',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f510.svg'
                        })
                    }),
                    this.generator.createFeatureContentObject({
                        title: '4',
                        subtitle: 'servers network',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f4a0.svg'
                        })
                    }),

                    // The last ghost item which is used to add new items.
                    this.generator.createFeatureContentObject({
                        title: '',
                        subtitle: ''
                    }),
                ]

            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the features component to offer more to read about your project.",
                    default: "Add a CTA (call to action) to the bottom of the features component to offer more to read about your project.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Product'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let features = this.props.componentItem.componentData.features.content.items;

        return (
            <section>
                <div className="features-03">
                    
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    
                    <div className="container">
                        <ul className="features-03__items">
                            {features && features.map((item:any, key:number) => {

                                if(item.title && item.title !== ''){
                                    let titleClass = ' def-50 title-text--inner ';
                                    if(item.title.length > 18){
                                        titleClass = ' def-16 title-text--inner features-03__number--small ';
                                    }else if(item.title.length > 7){
                                        titleClass = ' def-24 title-text--inner features-03__number--medium ';
                                    }

                                    return (
                                        <li className="features-03__item" key={'features-03-' + key}>
                                            <div className="features-03__img_box">
                                                <div className="features-03__img">
                                                    <Pictogram 
                                                      pictogramObject={item.pictogram}
                                                      className=''
                                                    />
                                                </div>
                                            </div>
                                            <div className={"features-03__number " + titleClass + (this.props.isDarkBg ? 'text-white' : '')}>{item.title}</div>
                                            <div className={"content-text features-03__word " + (this.props.isDarkBg ? 'text-white' : '')}>{item.subtitle}</div>
                                        </li>
                                    )
                                }else{
                                    return (null);
                                }

                            })}
                        </ul>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>

                    </div>
                </div>

            </section>
        )
    }
}

export default Features03;
