import { checkIfPopupExists } from "../checkIfPopupExists";

function checkIsButtonHrefDisabled(objectWithButtonProperties: any, currentWebsite:any){
    //button href is disabled if a button has a special action added such as Stripe checkout or popup.

    // objectWithButtonProperties can be a button object of a feature object. Some components has buttons some just href and title instead of full buttons.

    let buttonHrefDisabled = false;

    // Check if Stripe connected
    if(objectWithButtonProperties && objectWithButtonProperties.stripeProductId !== undefined && objectWithButtonProperties.stripeProductId !== ''){
        // Is the button has stripeProductId set up
        if(currentWebsite.connected_payment_integrations && currentWebsite.connected_payment_integrations.stripe_public_key && currentWebsite.connected_payment_integrations.stripe_public_key !== ''){
            // And also if Stripe integration is set up (API key is entered)
            buttonHrefDisabled = true;
        }
    }

    // Check if popup connected
    if (objectWithButtonProperties && objectWithButtonProperties.settings && objectWithButtonProperties.settings.popup && objectWithButtonProperties.settings.popup.isActive && objectWithButtonProperties.settings.popup.popupId) {
        const popupId =  objectWithButtonProperties.settings.popup.popupId;
        if (checkIfPopupExists(popupId, currentWebsite)) {
          buttonHrefDisabled = true;
        }
    }
    // Other plugins checks...

    return buttonHrefDisabled;

}

export default checkIsButtonHrefDisabled;