import axios, { AxiosResponse, CancelToken } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type ValidateGoogleSheetAsync = (payload: {
  accessToken: string;
  sheetUrl: string;
  cancelToken: CancelToken;
  purgeCacheParameter: string;
}) => Promise<AxiosResponse<any>>;

export const validateGoogleSheetAsync: ValidateGoogleSheetAsync = ({
  accessToken,
  sheetUrl,
  cancelToken,
  purgeCacheParameter
}) => {
  return new Promise((resolve, reject) => {
    const url =
      api.meta.validateSheet + "?sheet_url=" + encodeURIComponent(sheetUrl) + "&omit_items=true"
    axios
      .get(url, {
        ...returnAuthHeaderForAJAX(accessToken),
        cancelToken,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          console.log(`Request canceled: "${error.message}"`);
        }
        reject(error);
      });
  });
};
