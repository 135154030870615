import {checkIfObjectEmpty} from "../checkIfObjectEmpty";

export const checkIfThisWebsiteLanguageOptionOfAnotherWebsite = (websites:any, currentWebsite: any,) => {
    // if the website is a language option of another website, it can not have languages options
    let isThisWebsiteLanguageOptionOfAnotherWebsite = false;
    let websiteWhichHasThisWebsiteAsLanguageOption = {};
    let websitesList = websites.items.filter((item: any) => {
        //all websites excluding the website which settings are being edited
        return (item.id !== currentWebsite.id)
    });
    for (let i = 0; i < websitesList.length; i++) {
        let websiteObject = websitesList[i];
        // if the website has the languages object (by default all websites has it = {})
        // if it is not empty (not {})
        // it the languages object has items (e.g. language options)
        if (websiteObject.languages && checkIfObjectEmpty(websiteObject.languages) === false && websiteObject.languages.items && websiteObject.languages.items.length > 0) {
            //    check if there is a language option which has the ID of the current website
            //    if yes, the current website can not have any language options - because it is a language option of another website.
            for (let j = 0; j < websiteObject.languages.items.length; j++) {
                if (websiteObject.languages.items[j]['websiteId'] === currentWebsite.id) {
                    websiteWhichHasThisWebsiteAsLanguageOption = websiteObject;
                    isThisWebsiteLanguageOptionOfAnotherWebsite = true;
                    break;
                }
            }
        }
    }

    return {isTrue: isThisWebsiteLanguageOptionOfAnotherWebsite, website: websiteWhichHasThisWebsiteAsLanguageOption};
};