import React, { Component } from "react";
import './ButtonSettings.css'
import { Button, Icon, Input, Tooltip } from "antd";
import DebouncedInput from "../DebouncedInput";
import DebouncedTextarea from "../DebouncedTextarea";

interface Props {
  buttonObject: any,
  changeButtonButtonID: any,
  changeButtonClass: any,
  changeButtonOnClick: any,
  changeButtonAttributes: any,
}
type MyState = {
  buttonSettingsVisible: boolean,
};
class ButtonSettings extends Component<Props, MyState>{
  state = {
    buttonSettingsVisible: false,
  };

  componentWillMount(): void {
  }

  render() {
    const inputWidth = 340;
    return (
      <div className="form_input_picker__field_box__advanced_settings">
        <div className="form_input_picker__setting_box">
          <div className="content-input__tooltip_box">
            <Tooltip placement="topRight" title={<div>Set custom ID attribute.</div>}>
              <Icon className="content-input__tooltip_icon" type="info-circle" />
            </Tooltip>
            ID:
          </div>
          <DebouncedInput
            title=""
            onChange={this.props.changeButtonButtonID}
            size="small"
            placeholder="Enter custom ID"
            style={{width: inputWidth}}
            defaultValue={this.props.buttonObject.settings.custom_id ? this.props.buttonObject.settings.custom_id : ''}
          // value={}
          />
        </div>

        <div className="form_input_picker__setting_box">
          <div className="content-input__tooltip_box">
            <Tooltip placement="topRight" title={<div>The button's HTML classes. Separated by spaces.</div>}>
              <Icon className="content-input__tooltip_icon" type="info-circle" />
            </Tooltip>
            Class(-es):
          </div>
          <DebouncedInput
            title=""
            onChange={this.props.changeButtonClass}
            size="small"
            placeholder="Enter class"
            style={{width: inputWidth}}
            defaultValue={this.props.buttonObject.settings.classes ? this.props.buttonObject.settings.classes : ''}
          // value={}
          />
        </div>

        <div className="form_input_picker__setting_box">
          <div className="content-input__tooltip_box">
            <Tooltip placement="topRight" title={<div>
              Launch custom JS code on button click event (onclick). Commonly used to send events to a marketing platform. <br /> <br />
              See various guides: <br /> 
              <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/sending-hits" target="_blank">Google Analytics</a> <br />
              <a href="https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack" target="_blank">Mixpanel</a> <br />
              <a href="https://help.amplitude.com/hc/en-us/articles/115000959052-For-developers-Getting-started#h_b936a8b5-f869-413e-b673-90c2c99b4c29" target="_blank">Amplitude</a> <br />
            </div>}>
              <Icon className="content-input__tooltip_icon" type="info-circle" />
            </Tooltip>
            onclick:
          </div>
          <DebouncedInput
            title=""
            onChange={this.props.changeButtonOnClick}
            size="small"
            placeholder="ga('send', 'event', 'Video', 'play', 'cats.mp4');"
            style={{width: inputWidth}}
            defaultValue={this.props.buttonObject.settings.onclick ? this.props.buttonObject.settings.onclick : ''}
          // value={}
          />
        </div>

        <div className="form_input_picker__setting_box">
          <div className="content-input__tooltip_box">
            <Tooltip placement="topRight" title={<div>Custom HTML attributes. Example: <br/> 
                      <code style={{"backgroundColor": "rgba(255,255,255,0.17)", "padding": "3px"}}>data-my-attr="value"<br/>my-input-attr="goal"</code>
                      </div>}>
              <Icon className="content-input__tooltip_icon" type="info-circle" />
            </Tooltip>
            Attributes:
          </div>
          <DebouncedTextarea
            onChange={this.props.changeButtonAttributes}
            placeholder='data-attr-1="cat"&#10;data-attr-2="dog"'
            style={{width: inputWidth}}
            defaultValue={this.props.buttonObject.settings.attributes ? this.props.buttonObject.settings.attributes : ''}
          // value={}
          />
        </div>

      </div>
    )
  }
}

export default ButtonSettings;