import React, { Component } from "react";
import "./BillingData.css";
import {
  setPaymentMethodDataStatus,
  setPaymentHistoryDataStatus,
  savePaymentMethodInState,
  savePaymentsHistoryInState,
} from "../../../../store/user/actions";
import { connect } from "react-redux";
import {
  SavePaymentMethodInState,
  SavePaymentsHistoryInState,
  SetPaymentHistoryDataStatus,
  SetPaymentMethodDataStatus,
} from "../../../../store/user/types";
import { BillingDataStatus } from "../../../../enums/BillingDataStatus";
import axios from "axios";
import { api } from "../../../../data/urls";
import returnAuthHeaderForAJAX from "../../../../helpers/auth/returnAuthHeaderForAJAX";
import BillingDataEmpty from "./BillingDataEmpty";
import PaymentMethod from "./PaymentMethod";
import PaymentHistory from "./PaymentHistory";
import { openNotification } from "../../../../helpers/openNotification";
import { getPaymentErrorMessage } from "../../../../helpers/getPaymentErrorMessage";
import {
  notificationDuration,
  SECONDS_BEFORE_TIMEOUT,
} from "../../../../data/constants";
import { isBillingHidden } from "../../../../helpers/user/isBillingHidden";
import _ from "lodash";
import fireAnalyticsEvent from "../../../../helpers/editor/fireAnalyticsEvent";
import { CrispEvents } from "../../../../enums/AnalyticsEventsEnums";
import SpinnerBox from "../../SpinnerBox";

interface Props {
  user: any;
  plans: any;
  auth: any;

  setPaymentMethodDataStatus: SetPaymentMethodDataStatus;
  setPaymentHistoryDataStatus: SetPaymentHistoryDataStatus;
  savePaymentMethodInState: SavePaymentMethodInState;
  savePaymentsHistoryInState: SavePaymentsHistoryInState;
}
interface State {
  paymentMethodInterval: NodeJS.Timeout;
  paymentHistoryInterval: NodeJS.Timeout;
}
type BillingDataType = "method" | "history";

class BillingData extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethodInterval: null,
      paymentHistoryInterval: null,
    };
  }

  setIntrevalToState = (
    billingDataType: BillingDataType,
    callback: () => void,
    ms: number
  ) => {
    if (billingDataType === "method") {
      this.setState({
        paymentMethodInterval: setInterval(callback, ms),
      });
    }
    if (billingDataType === "history") {
      this.setState({
        paymentHistoryInterval: setInterval(callback, ms),
      });
    }
  };

  clearIntervalFromState = (billingDataType: BillingDataType) => {
    if (billingDataType === "method") {
      clearInterval(this.state.paymentMethodInterval);
    }
    if (billingDataType === "history") {
      clearInterval(this.state.paymentHistoryInterval);
    }
  };

  fetchBillingData = (billingDataType: BillingDataType) => {
    const { user, plans } = this.props;

    const billingDataByType = {
      method: {
        billingDataStatus: user.paymentMethodDataStatus as BillingDataStatus,
        setBillingDataStatus: this.props.setPaymentMethodDataStatus,
        saveBillingInState: this.props.savePaymentMethodInState,
        apiUrl: api.account.getSubscriptionInfo,
        errorMessage: "We failed to load the payment method info",
      },
      history: {
        billingDataStatus: user.paymentHistoryDataStatus as BillingDataStatus,
        setBillingDataStatus: this.props.setPaymentHistoryDataStatus,
        saveBillingInState: this.props.savePaymentsHistoryInState,
        apiUrl: api.account.getPaymentsHistory,
        errorMessage: "We failed to load the payments history",
      },
    };

    const {
      billingDataStatus,
      setBillingDataStatus,
      saveBillingInState,
      apiUrl,
      errorMessage,
    } = billingDataByType[billingDataType];

    if (
      billingDataStatus !== BillingDataStatus.notLoaded ||
      isBillingHidden(user, plans)
    ) {
      return;
    }

    setBillingDataStatus(BillingDataStatus.inProgress);

    const { accessToken } = this.props.auth;
    let counter = 0;

    const requestDataFromServer = () => {
      axios
        .get(apiUrl, {
          ...returnAuthHeaderForAJAX(accessToken),
        })
        .then((response) => {
          counter++;
          if (!!response.data) {
            saveBillingInState(response.data);
            this.clearIntervalFromState(billingDataType);
            setBillingDataStatus(BillingDataStatus.loaded);
          }
          if (counter >= SECONDS_BEFORE_TIMEOUT) {
            openNotification(
              "Sorry",
              <div>
                {errorMessage}: <b>Connection timed out</b>. Please ask for help
                in the live chat.
              </div>,
              "Close",
              "error",
              notificationDuration.long
            );
            fireAnalyticsEvent.fireCrisp(
              CrispEvents.loadBillingDataError,
              {
                billing_data_type: billingDataType,
                error: "Connection timed out",
              },
              true
            );
            this.clearIntervalFromState(billingDataType);
            setBillingDataStatus(BillingDataStatus.error);
          }
        })
        .catch((error) => {
          openNotification(
            "Sorry",
            <div>
              {errorMessage}: <b>{getPaymentErrorMessage(error)}</b>. Please ask
              for help in the live chat.
            </div>,
            "Close",
            "error",
            notificationDuration.long
          );
          fireAnalyticsEvent.fireCrisp(
            CrispEvents.loadBillingDataError,
            {
              billing_data_type: billingDataType,
              error: getPaymentErrorMessage(error),
            },
            true
          );
          this.clearIntervalFromState(billingDataType);
          setBillingDataStatus(BillingDataStatus.error);
        });
    };

    this.setIntrevalToState(billingDataType, requestDataFromServer, 1000);
  };

  componentDidMount() {
    this.fetchBillingData("method");
    this.fetchBillingData("history");
  }

  componentDidUpdate() {
    this.fetchBillingData("method");
    this.fetchBillingData("history");
  }

  componentWillUnmount() {
    const {
      paymentMethodDataStatus,
      paymentHistoryDataStatus,
    } = this.props.user;
    const {
      setPaymentMethodDataStatus,
      setPaymentHistoryDataStatus,
    } = this.props;

    if (paymentMethodDataStatus === BillingDataStatus.inProgress) {
      this.clearIntervalFromState("method");
      setPaymentMethodDataStatus(BillingDataStatus.notLoaded);
    }
    if (paymentHistoryDataStatus === BillingDataStatus.inProgress) {
      this.clearIntervalFromState("history");
      setPaymentHistoryDataStatus(BillingDataStatus.notLoaded);
    }
  }

  render() {
    const { user, plans } = this.props;
    const { paymentMethodDataStatus, paymentHistoryDataStatus } = user;

    if (
      paymentMethodDataStatus === BillingDataStatus.inProgress &&
      paymentHistoryDataStatus === BillingDataStatus.inProgress
    ) {
      return (
        <SpinnerBox text="Loading billing data. It might take a minute..." />
      );
    }

    if (isBillingHidden(user, plans)) {
      return <BillingDataEmpty />;
    }

    return (
      <div className="payment_info__billing_box">
        <PaymentMethod />
        <PaymentHistory />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};
const mapActionsToProps = {
  setPaymentMethodDataStatus,
  setPaymentHistoryDataStatus,
  savePaymentMethodInState,
  savePaymentsHistoryInState,
};
export default connect(mapStateToProps, mapActionsToProps)(BillingData);
