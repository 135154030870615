import { Button, Icon, Tooltip } from "antd";
import classNames from "classnames";
import React, { useRef } from "react";
import { connect } from "react-redux";
import { WebsitePagesState } from "../../store/websitePages/types";
import { formatAndResizeImageUrl } from "../../helpers/formatAndResizeImageUrl";
import {
  createWebsitePageWaitingForServer,
  pageUrlUpdateErrorMessage,
} from "../../store/websitePages/actions";
import { createAndSavePageAsync } from "../../store/websitePages/thunks";
import {
  EditFillTemplateData,
  GetCurrentStoreData,
  WebsitesState,
} from "../../store/websites/types";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { openNotification } from "../../helpers/openNotification";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  notificationDuration,
} from "../../data/constants";
import _ from "lodash";
import { editFillTemplateData } from "../../store/websites/actions";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { formatToURLPath } from "../../helpers/strings/formatToURLPath";

interface Props extends RouteComponentProps {
  currentPageUrl: string;
  onSuccess?: (payload?: any) => any;
  setUrlInputValue?: (url: string) => void;

  websitesPages: WebsitePagesState;
  websites: WebsitesState;
  createWebsitePageWaitingForServer: (isWaiting: boolean) => void;
  createAndSavePageAsync: any;
  pageUrlUpdateErrorMessage: (message: string) => void;
  editFillTemplateData: EditFillTemplateData;
  getCurrentStoreData: GetCurrentStoreData;
}

interface PageTemplateProps extends Props {
  title: string;
  thumbnailUrl?: string;
  templateId?: number;
  description?: string;
}
const PageTemplate = (props: PageTemplateProps) => {
  const {
    thumbnailUrl,
    title,
    websitesPages,
    templateId,
    description,
    createWebsitePageWaitingForServer,
    createAndSavePageAsync,
    currentPageUrl,
    onSuccess,
    websites,
    history,
    pageUrlUpdateErrorMessage,
    editFillTemplateData,
    getCurrentStoreData,
  } = props;
  const itemContainerRef = useRef<HTMLDivElement>(null);
  const { currentWebsite } = getCurrentStoreData();

  const handleCreatePage = (templateId?: number) => {
    if (websitesPages.isWaitingForCreateWebsitePageResponse) return;
    createWebsitePageWaitingForServer(true);
    createAndSavePageAsync(formatToURLPath(currentPageUrl), templateId)
      .then(({ data }) => {
        if (onSuccess) {
          onSuccess();
        }

        if (!templateId) {
          window.posthog.capture(PosthogEvents.USE_PAGE_TEMPLATE, {
            template_name: "Blank page",
            template_page_url: null,
            template_page_id: null,
          });
        } else {
          const usedTemplate = websitesPages.pageTemplatesArray.find(
            (template) => template.page_id === templateId
          );
          if (usedTemplate) {
            window.posthog.capture(PosthogEvents.USE_PAGE_TEMPLATE, {
              template_name: usedTemplate.title,
              template_page_url: usedTemplate.page_url,
              template_page_id: usedTemplate.page_id,
            });
          }
        }

        window.posthog.capture(PosthogEvents.SET_PAGE_URL, {
          page_url: data.url,
        });

        const subdomain = websites.items.find((website) => {
          return website.id === data.website;
        }).subdomain;
        history.push(`/${subdomain}/pages/${data.id}`);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error(error);

        const urlValidationError = _.get(error, "response.data.url");
        if (urlValidationError) {
          pageUrlUpdateErrorMessage(urlValidationError);
          if (props.setUrlInputValue) {
            props.setUrlInputValue(formatToURLPath(currentPageUrl));
          }
          return;
        }

        const warningMessage =
          _.get(error, "response.data.limit") ||
          _.get(error, "response.data.not_found");
        if (warningMessage) {
          openNotification(
            "Error",
            warningMessage,
            "OK",
            "warning",
            notificationDuration.medium
          );
          return;
        }

        openNotification(
          DEFAULT_ERROR_TITLE,
          DEFAULT_ERROR_MESSAGE,
          "OK",
          "error",
          notificationDuration.medium
        );
      })
      .finally(() => {
        createWebsitePageWaitingForServer(false);
      });
  };

  return (
    <div
      className={classNames("page-template", {
        "has-thumbnaul": !!thumbnailUrl,
      })}
      ref={itemContainerRef}
    >
      <div
        className="page-template__top"
        onClick={() => handleCreatePage(templateId)}
      >
        <Button
          type="primary"
          className="page-template__button"
          loading={websitesPages.isWaitingForCreateWebsitePageResponse}
          size="small"
        >
          Add page
        </Button>
        {!!thumbnailUrl && (
          <img
            className="page-template__thumbnail"
            src={formatAndResizeImageUrl(thumbnailUrl, 604)}
          />
        )}
        {!thumbnailUrl && (
          <div className="page-template__text-blank">Blank</div>
        )}
      </div>
      <Tooltip
        title={
          !!description && (
            <div
              className="page-template__description-container"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )
        }
        overlayStyle={{ zIndex: 1300, maxWidth: 280 }}
        align={{ offset: [-13, 0] }}
        mouseLeaveDelay={0}
        placement="topLeft"
        getPopupContainer={() => itemContainerRef.current}
      >
        <div className="page-template__bottom">
          {!!description && <Icon type="info-circle" />}
          <h3
            className="page-template__title"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        </div>
      </Tooltip>
    </div>
  );
};
const PageTemplateLoading = () => {
  return (
    <div className="page-template-loading">
      <div className="page-template-loading__top"></div>
      <div className="page-template-loading__bottom"></div>
    </div>
  );
};

const PageTemplatesList = (props: Props): JSX.Element => {
  const { websitesPages } = props;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        gap: 20,
        rowGap: 40,
      }}
      className="page-templates__list"
    >
      <PageTemplate title="Blank page" {...props} />
      {websitesPages.pageTemplatesLoadedStatus === "loading" && (
        <>
          <PageTemplateLoading />
          <PageTemplateLoading />
        </>
      )}
      {websitesPages.pageTemplatesArray.map((template, i) => {
        return (
          <PageTemplate
            key={i}
            title={template.title || `Template ${i + 1}`}
            thumbnailUrl={template.thumbnail}
            templateId={template.page_id}
            description={template.description}
            {...props}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    websitesPages: state.websitesPages,
    websites: state.websites,
  };
};
export default connect(mapStateToProps, {
  createWebsitePageWaitingForServer,
  createAndSavePageAsync,
  pageUrlUpdateErrorMessage,
  editFillTemplateData,
  getCurrentStoreData,
})(withRouter(PageTemplatesList));
