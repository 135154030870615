export function convertToNumber(str) {
  if (typeof str !== "string") return parseFloat(str);
  let resultString = str.replace(/KKK$/i, "B").replace(/KK$/i, "M").replace(/,/g, "");
  if (resultString.toLowerCase().endsWith("k")) {
    return parseFloat(resultString.slice(0, -1)) * 1000;
  }
  if (resultString.toLowerCase().endsWith("m")) {
    return parseFloat(resultString.slice(0, -1)) * 1000000;
  }
  if (resultString.toLowerCase().endsWith("b")) {
    return parseFloat(resultString.slice(0, -1)) * 1000000000;
  }
  return parseFloat(resultString);
}
