import React, { Component } from "react";
import SpinnerBox from "./SpinnerBox";
import { getFullTemplatePageUrl } from "../../helpers/editor/getFullTemplatePageUrl";
import "./TemplateSelect.css";
import { Button, Icon, Popover } from "antd";
import capitalizeString from "../../helpers/strings/capitalizeString";
import { PosthogEvents } from "../../enums/AnalyticsEventsEnums";
import { UserState } from "../../store/user/types";
import _ from "lodash";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
  websites;
  templates;
  activeTemplate?: any;
  changeActiveTemplate?: any;
  onSubmit;
  classNameForCreateButton?: any;
  isLoading;
  user: UserState;
}

type State = {
  selectedTag: string;
};

class TemplateSelect extends Component<Props, State> {
  componentWillMount() {
    this.setState({
      selectedTag: "all",
    });
  }

  handleChangeSelectedTag(tag) {
    this.setState({
      selectedTag: tag,
    });
  }

  render() {
    const renderTemplatesList = () => {
      let templatesItemsJSX;
      const userId = _.get(this.props, "user.data.id", "");

      // Sort by priority field
      let templatesByPriority = this.props.templates.items.sort(function (
        a,
        b
      ) {
        return a.priority - b.priority;
      });

      if (this.props.templates.dataFetched) {
        templatesItemsJSX = templatesByPriority.map((item) => {
          let templateSubdomain = item.template_subdomain;
          let fullTemplatePageUrl = getFullTemplatePageUrl(
            templateSubdomain,
            ""
          );

          let templateTags = item.tags.toLowerCase();

          if (
            templateTags.includes(this.state.selectedTag) ||
            this.state.selectedTag === "all"
          ) {
            return (
              <li
                className="template-select__item"
                key={item.website + "_" + item.template_subdomain}
              >
                <div className="template-select__item_header">
                  <h3 className="template-select__item_title">{item.label}</h3>
                  {item.description && (
                    <Popover
                      trigger="hover"
                      placement="top"
                      content={
                        <div dangerouslySetInnerHTML={{__html: item.description}} />
                      }
                      overlayStyle={{zIndex: 1220}}
                    >
                      <Icon
                        type="info-circle"
                        className="template-select__item_card_info"
                      />
                    </Popover>
                  )}
                </div>

                <div
                  className="template-select__item_card template-select__item_card--hover-effect"
                  style={{
                    backgroundImage: `url(${formatImageUrl(item.image)})`,
                  }}
                >
                  <a
                    className="template-select__open-preview"
                    href={`${fullTemplatePageUrl}?user_id=${userId}`}
                    target="_blank"
                    onClick={() => {
                      window.posthog.capture(
                        PosthogEvents.CLICK_CREATE_OR_PREVIEW_TEMPLATE,
                        {
                          template_subdomain: item.template_subdomain,
                        }
                      );
                    }}
                  ></a>
                  <div className="template-select__item_card-controls">
                    <Button
                      size="small"
                      onClick={(e) => {
                        window.posthog.capture(
                          PosthogEvents.CLICK_CREATE_OR_PREVIEW_TEMPLATE,
                          {
                            template_subdomain: item.template_subdomain,
                          }
                        );
                        this.props.onSubmit(
                          e,
                          item.template_subdomain,
                          item.website
                        );
                      }}
                      loading={this.props.isLoading}
                      className={
                        `template-select__item-card_button-create ` +
                        (this.props.classNameForCreateButton &&
                          this.props.classNameForCreateButton)
                      }
                      type="primary"
                      htmlType="submit"
                      icon="plus"
                    >
                      Create
                    </Button>
                  </div>
                </div>
              </li>
            );
          }
        });
      } else {
        return (
          <div style={{ marginTop: "64px" }}>
            <SpinnerBox text={"Loading templates..."} />
          </div>
        );
      }

      return (
        <ul className="template-select__list">
          <li className="template-select__item">
            <div className="template-select__item_header">
              <h3 className="template-select__item_title">Blank</h3>
            </div>

            <div
              className="template-select__item_card"
              style={{ background: "#fff" }}
            >
              <svg
                className="template-select__blank_icon"
                width="60px"
                height="60px"
                viewBox="0 0 60 60"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Builder"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  opacity="0.049999997"
                >
                  <g
                    id="Templates"
                    transform="translate(-209.000000, -306.000000)"
                    fillRule="nonzero"
                    fill="#000000"
                  >
                    <g
                      id="Group-3"
                      transform="translate(57.000000, 224.000000)"
                    >
                      <g id="item">
                        <g
                          id="Group"
                          transform="translate(19.000000, 19.000000)"
                        >
                          <g
                            id="command"
                            transform="translate(133.000000, 63.000000)"
                          >
                            <path
                              d="M49,38 L42,38 L42,22 L49,22 C55.065,22 60,17.065 60,11 C60,4.935 55.065,0 49,0 C42.935,0 38,4.935 38,11 L38,18 L22,18 L22,11 C22,4.935 17.065,0 11,0 C4.935,0 0,4.935 0,11 C0,17.065 4.935,22 11,22 L18,22 L18,38 L11,38 C4.935,38 0,42.935 0,49 C0,55.065 4.935,60 11,60 C17.065,60 22,55.065 22,49 L22,42 L38,42 L38,49 C38,55.065 42.935,60 49,60 C55.065,60 60,55.065 60,49 C60,42.935 55.065,38 49,38 Z M42,11 C42,7.141 45.14,4 49,4 C52.86,4 56,7.141 56,11 C56,14.859 52.86,18 49,18 L42,18 L42,11 Z M11,18 C7.14,18 4,14.859 4,11 C4,7.141 7.14,4 11,4 C14.86,4 18,7.141 18,11 L18,18 L11,18 Z M18,49 C18,52.859 14.86,56 11,56 C7.14,56 4,52.859 4,49 C4,45.141 7.14,42 11,42 L18,42 L18,49 Z M22,38 L22,22 L38,22 L38,38 L22,38 Z M49,56 C45.14,56 42,52.859 42,49 L42,42 L49,42 C52.86,42 56,45.141 56,49 C56,52.859 52.86,56 49,56 Z"
                              id="Shape"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <div className="template-select__item_card-controls">
                <Button
                  size="small"
                  onClick={(e) => this.props.onSubmit(e)}
                  loading={this.props.isLoading}
                  className={
                    `template-select__item-card_button-create ` +
                    (this.props.classNameForCreateButton &&
                      this.props.classNameForCreateButton)
                  }
                  type="primary"
                  htmlType="submit"
                  icon="plus"
                >
                  Create
                </Button>
              </div>
            </div>
          </li>

          {templatesItemsJSX}
        </ul>
      );
    };

    const renderTags = () => {
      const allTags = [];
      const countTags = {};
      let allDuplicatedTags = [];
      this.props.templates.items.map((item) => {
        let templateTags = item.tags.split(",");

        templateTags.map((tag) => {
          allDuplicatedTags.push(tag);

          if (!allTags.includes(tag) && tag !== "") {
            allTags.push(tag);
          }
        });
      });
      allDuplicatedTags.forEach((i) => {
        countTags[i] = (countTags[i] || 0) + 1;
      });

      let allTagsButtonsJSX;
      if (this.props.templates.dataFetched) {
        let formatTagTitle = (tagTitle: string) => {
          // all tags need capitalizeString()
          // some tags need a special formating (saas -> SaaS)
          if (tagTitle === "saas") {
            return "SaaS";
          } else {
            return capitalizeString(tagTitle);
          }
        };

        allTagsButtonsJSX = allTags.map((tag) => {
          return (
            <li key={tag} className="template-select__tags_item">
              <Button
                size="small"
                className={
                  "template-select__tags_item_button " +
                  (this.state.selectedTag === tag
                    ? "template-select__tags_item_button--active"
                    : " ")
                }
                type="default"
                onClick={() => this.handleChangeSelectedTag(tag)}
              >
                <span>{formatTagTitle(tag)}</span>
              </Button>
            </li>
          );
        });
      } else {
        return null;
      }

      return (
        <ul className="template-select__tags">
          <li className="template-select__tags_item">
            <Button
              size="small"
              className={
                "template-select__tags_item_button " +
                (this.state.selectedTag === "all"
                  ? "template-select__tags_item_button--active"
                  : " ")
              }
              onClick={() => this.handleChangeSelectedTag("all")}
            >
              <span>All</span>
              {/* <span className="template-select__tags_item_count">{this.props.templates.items.length}</span> */}
            </Button>
          </li>
          {allTagsButtonsJSX}
        </ul>
      );
    };

    return (
      <div className="template-select">
        {renderTags()}
        {renderTemplatesList()}
      </div>
    );
  }
}

export default TemplateSelect;
