enum ContentInputsTypesEnum {
  title = 'title',
  subtitleText = 'subtitleText',
  text = 'text',
  code = 'code',
  pictograms = 'pictograms',
  steps = 'steps',
  team = 'team',
  pricing = 'pricing',
  features = 'features',
  posts = 'posts',
  gallery = 'gallery',
  faq = 'faq',
  list = 'list',
  graphics = 'graphics',
  socialLinks = 'socialLinks',
  contacts = 'contacts',
  navLinks = 'navLinks',
  logos = 'logos',
  mockups = 'mockups',
  cta = 'cta',
  navLogo = 'navLogo',
  directory = 'directory',
  widget = 'widget',
  rapidForms = 'rapidForms',
  marketsy = 'marketsy',
}

export default ContentInputsTypesEnum;
