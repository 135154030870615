import React, { Component } from "react";
import { Button, Icon, Popover, Radio, Switch, Tag, Tooltip } from "antd";
import { checkIfObjectEmpty } from "../../../helpers/checkIfObjectEmpty";
import generateRandomNumerousId from "../../../helpers/generateRandomNumerousId";
import DebouncedTextarea from "../../DebouncedTextarea";
import DebouncedInput from "../../DebouncedInput";
import { getLanguageTitleAndEmojiFromCode } from "../../../helpers/editor/getLanguageTitleAndEmojiFromCode";

interface Props {
  toggleWaitingForServer: any;
  isLanguagesWidgetActive: boolean;
  waitingForServer: boolean;
  currentWebsite: any;
  saveChanges: any;
  activateLangWidget: any;
  currentWebsiteWidgetsObject: any;
  changeLangWidgetStyle: any;
  changeLangWidgetPlacement: any;
  langWidgetChangesDetected: boolean;
  websites: any;
}

class LanguagesSwitch extends Component<Props> {
  returnFields = () => {
    if (checkIfObjectEmpty(this.props.currentWebsiteWidgetsObject)) {
      return null;
    } else {
      // another check to make sure the object is formed (forms when the widget is activated)
      if (
        this.props.currentWebsiteWidgetsObject["items"] === undefined ||
        this.props.currentWebsiteWidgetsObject["items"]["lang"] === undefined
      ) {
        return null;
      } else {
        let langWidgetContentObject = this.props.currentWebsiteWidgetsObject[
          "items"
        ]["lang"];
        let returnCurrentWebsiteLanguage = () => {
          if (
            langWidgetContentObject["content"] &&
            langWidgetContentObject["content"]["thisWebsiteCode"] &&
            langWidgetContentObject["content"]["thisWebsiteCode"] !== "none"
          ) {
            // learn why 'none' in <WebsiteLanguageSettings>
            return (
              <Tag className={"not_closable_tag"}>
                {getLanguageTitleAndEmojiFromCode(
                  langWidgetContentObject["content"]["thisWebsiteCode"]
                )}
              </Tag>
            );
          } else {
            return (
              <Tooltip
                title={"Please set this website language in the SEO settings."}
              >
                <Tag className={"not_closable_tag"}>
                  <Icon type="close-circle" /> Not set
                </Tag>
              </Tooltip>
            );
          }
        };
        let returnWebsiteLanguageOptions = () => {
          if (
            langWidgetContentObject["content"] &&
            langWidgetContentObject["content"]["options"] &&
            langWidgetContentObject["content"]["options"].length > 0
          ) {
            let optionsList = langWidgetContentObject["content"]["options"];
            console.log("langWidgetContentObject", langWidgetContentObject);
            let optionsJXS = [];

            for (let i = 0; i < optionsList.length; i++) {
              if (
                optionsList[i]["websiteHomePageURL"] &&
                optionsList[i]["websiteHomePageURL"] !== ""
              ) {
                optionsJXS.push(
                  <Tooltip
                    key={optionsList[i]["code"] + "-" + i}
                    title={
                      <a
                        style={{ color: "white", textDecoration: "underline" }}
                        href={optionsList[i]["websiteHomePageURL"]}
                        target={"_blank"}
                      >
                        {optionsList[i]["websiteHomePageURL"]}{" "}
                        <Icon type="link" />
                      </a>
                    }
                  >
                    <Tag className={"not_closable_tag"}>
                      {getLanguageTitleAndEmojiFromCode(optionsList[i]["code"])}
                    </Tag>
                  </Tooltip>
                );
              }
            }

            if (optionsJXS.length > 0) {
              return optionsJXS;
            } else {
              return (
                <Tag className={"not_closable_tag"}>
                  <Icon type="close-circle" /> Not set
                </Tag>
              );
            }
          } else {
            return (
              <Tag className={"not_closable_tag"}>
                <Icon type="close-circle" /> Not set
              </Tag>
            );
          }
        };
        return (
          this.props.isLanguagesWidgetActive && (
            <div className="website_widgets__inputs">
              <div className="settings-box">
                <div
                  className="settings-box__input_label"
                  style={{ marginTop: "10px" }}
                >
                  <div>
                    <Popover
                      content={
                        <div>
                          The default language and language options set. <br />{" "}
                          Add or remove languages in the SEO settings and
                          re-activate the widget.{" "}
                        </div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Languages:
                  </div>
                </div>
                <div
                  className="settings-box__input_box"
                  style={{ marginBottom: 10 }}
                >
                  This website language: {returnCurrentWebsiteLanguage()}
                </div>
                <div
                  className="settings-box__input_box"
                  style={{ marginBottom: "20px" }}
                >
                  Added language options: {returnWebsiteLanguageOptions()}
                </div>
              </div>

              <div className="settings-box">
                <div className="settings-box__input_label">
                  <div>
                    <Popover
                      content={
                        <div>The position of the widget on the screen.</div>
                      }
                    >
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">
                    Placement:
                  </div>
                </div>
                <div className="settings-box__input_box">
                  <Radio.Group
                    size="small"
                    value={langWidgetContentObject["placement"]}
                    onChange={this.props.changeLangWidgetPlacement}
                    style={{ marginBottom: "20px" }}
                  >
                    <Radio.Button value="bottom_left">
                      <Icon type="radius-bottomleft" /> Bottom left
                    </Radio.Button>
                    <Radio.Button value="bottom_right">
                      <Icon type="radius-bottomright" /> Bottom right
                    </Radio.Button>
                  </Radio.Group>
                </div>
              </div>

              <div className="settings-box">
                <div className="settings-box__input_label">
                  <div>
                    <Popover content={<div>The widget color scheme.</div>}>
                      <Icon type="info-circle" />
                    </Popover>
                  </div>
                  <div className="settings-box__input_info_text">Style:</div>
                </div>
                <div className="settings-box__input_box">
                  <div
                    className={
                      "checkered-bg background-color-picker " +
                      this.props.currentWebsite.color_classname
                    }
                  >
                    <button
                      onClick={() => this.props.changeLangWidgetStyle("white")}
                      className={
                        "bg-white-color background-color-picker__item  background-color-picker__item--border " +
                        (langWidgetContentObject["style"] === "white"
                          ? " background-color-picker__item--small "
                          : " ")
                      }
                    >
                      <span className="background-color-picker__circle"></span>
                    </button>
                    <button
                      onClick={() => this.props.changeLangWidgetStyle("black")}
                      className={
                        "bg-black-color background-color-picker__item " +
                        (langWidgetContentObject["style"] === "black"
                          ? " background-color-picker__item--small "
                          : " ")
                      }
                    >
                      <span className="background-color-picker__circle"></span>
                    </button>
                    <button
                      onClick={() => this.props.changeLangWidgetStyle("gray")}
                      className={
                        "bg-light_gray-color background-color-picker__item " +
                        (langWidgetContentObject["style"] === "gray"
                          ? " background-color-picker__item--small "
                          : " ")
                      }
                    >
                      <span className="background-color-picker__circle"></span>
                    </button>
                    <button
                      onClick={() =>
                        this.props.changeLangWidgetStyle("midnight")
                      }
                      className={
                        "background-color-picker__item " +
                        (langWidgetContentObject["style"] === "midnight"
                          ? " background-color-picker__item--small "
                          : " ")
                      }
                    >
                      <span
                        className="background-color-picker__circle"
                        style={{ backgroundColor: "#031a4e" }}
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
              {
                <Button
                  style={{ marginTop: 20 }}
                  onClick={() =>
                    this.props.saveChanges(this.props.currentWebsite.widgets)
                  }
                  disabled={!this.props.langWidgetChangesDetected}
                  type="primary"
                >
                  Save language widget
                  <Icon
                    type={this.props.waitingForServer ? "loading" : "save"}
                  />
                </Button>
              }
            </div>
          )
        );
      }
    }
  };

  render() {
    return (
      <div>
        <h3 className="website_widgets__title">
          Languages selector{" "}
          <Switch
            loading={
              this.props.isLanguagesWidgetActive === false &&
              this.props.waitingForServer
            }
            onChange={(event) => this.props.activateLangWidget(event)}
            checkedChildren="on"
            unCheckedChildren="off"
            checked={this.props.isLanguagesWidgetActive}
          />
        </h3>
        {this.props.isLanguagesWidgetActive && this.returnFields()}
      </div>
    );
  }
}

export default LanguagesSwitch;
