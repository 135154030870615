import { UnicornComponent } from "../../../../classes/UnicornComponent";
import { publicUrl } from "../../../../data/urls";
import { Components } from "../../../../enums/Components";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { updateGalleryButtons } from "./updateGalleryButtons";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTabTitle = stringifyObjectsAndNumbers(modifiedItem.TAB_TITLE) || "";
  const itemTabSubtitle =
    stringifyObjectsAndNumbers(modifiedItem.TAB_SUBTITLE) || "";
  const itemContentTitle =
    stringifyObjectsAndNumbers(modifiedItem.CONTENT_TITLE) || "";
  const itemContentText =
    stringifyObjectsAndNumbers(modifiedItem.CONTENT_TEXT) || "";
  return {
    itemId,
    itemPosition,
    itemTabTitle,
    itemTabSubtitle,
    itemContentTitle,
    itemContentText,
  };
};

const particleModifyOriginalItem = (
  content,
  originalItem,
  modifiedItem,
  itemPosition,
  itemTabTitle,
  itemTabSubtitle,
  itemContentTitle,
  itemContentText
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(content.items, item);
      }
    });
  }
  if ("TAB_TITLE" in modifiedItem) {
    originalItem.tabTitle = itemTabTitle;
  }
  if ("TAB_SUBTITLE" in modifiedItem) {
    originalItem.tabSubtitle = itemTabSubtitle;
  }
  if ("CONTENT_TITLE" in modifiedItem) {
    originalItem.contentTitle = itemContentTitle;
  }
  if ("CONTENT_TEXT" in modifiedItem) {
    originalItem.contentText = itemContentText;
  }
  if ("BUTTONS" in modifiedItem) {
    updateGalleryButtons(originalItem, modifiedItem);
  }
};

export const updateGallery = (
  componentData,
  completionContent,
  currentComponentInfo: UnicornComponent
) => {
  const content = componentData.gallery.content;
  if (!("TABS" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.TABS)) {
    if (completionContent.TABS === null) {
      content.items = [];
    }
    return;
  }
  content.items = deleteEmptyItems(content.items, "tabTitle");
  addDefaultValuesToItems(content.items);
  completionContent.TABS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemTabTitle,
      itemTabSubtitle,
      itemContentTitle,
      itemContentText,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      content.items = deleteItem(content.items, itemId);
      return;
    }
    const isItemNew = getIsItemNew(content.items, itemId);
    if (isItemNew) {
      const newItem = generator.createGalleryContentObject();
      newItem.tabTitle = "";
      newItem.tabSubtitle = "";
      newItem.contentTitle = "";
      newItem.contentText = "";
      newItem.contentSubtitle = "";
      newItem.contentCta.buttons = [];
      let imageUrl =
      publicUrl + "/img/screenshots/chart_app_1.png";
      let videoUrl =
        "https://up-blog-prod.s3.amazonaws.com/waterfall_compressed.mp4";
      if (
        currentComponentInfo.name === Components.tabs01 ||
        currentComponentInfo.name === Components.tabs03 ||
        currentComponentInfo.name === Components.tabs04
      ) {
        imageUrl =
        publicUrl +
          "/img/screenshots/dashboard_ui_1.png";
        videoUrl = "https://www.youtube.com/watch?v=tw4jkyfY4HE";
      }
      newItem.contentGraphic[0].image.url = imageUrl;
      newItem.contentGraphic[0].video.youtube.url = videoUrl;
      addNewItem(content.items, newItem, itemId, itemPosition);
    }
    const originalItem = getOriginalItem(content.items, itemId);
    particleModifyOriginalItem(
      content,
      originalItem,
      modifiedItem,
      itemPosition,
      itemTabTitle,
      itemTabSubtitle,
      itemContentTitle,
      itemContentText
    );
  });
  sortItemsByPosition(content.items);
};
