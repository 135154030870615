import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {companyLogos, pressLogos, publicUrl} from "../../data/urls";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import WebsiteLogo from "../component_molecules/WebsiteLogo";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Clients02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Top companies work with us"}));

            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                labelTooltip: {default: "Design hint: put longer items at the top and form a ladder."},
                maxItems: 40,
                priority: 500,
                hasPictogram: false,
                hasText: false,
                hasButton: false,
                items: [
                    this.generator.createFeatureContentObject({title: 'More than 20% of our customers are from Forbes 500.'}),
                    this.generator.createFeatureContentObject({title: 'Over 65% of our clients recommend us to friends.'}),
                    this.generator.createFeatureContentObject({title: 'Our longest relations is 8 years.'}),

                    // The last ghost item which is used to add new items.
                    this.generator.createFeatureContentObject({
                        title: '',
                        text: ''
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpLogos({
              isDefaultEmpty,
                maxItems: 24,
                priority: 600,
                items: [
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'facebook.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'ibm.svg',
                        height: 20,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'dribbble.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'microsoft.svg',
                        height: 20,
                    }),

                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'adobe.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'invision.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'apple.svg',
                        height: 30,
                    }),
                    this.generator.createLogoContentObject({
                        uploadedSrc: publicUrl + companyLogos.prefix + 'virgin.svg',
                        height: 40,
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the clients component to offer to learn more cases.",
                    default: "Add a CTA (call to action) to the bottom of the clients component to offer to learn more cases.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'See also'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);

        let logos = this.props.componentItem.componentData.logos.content.items;
        let features = this.props.componentItem.componentData.features.content.items;

        return (
            <section>

                <div className="clients-02">
                    <div className="container container--large">
                        <div className="clients-02__wrapper">
                            <div className="card-container clients-02__logos sh-4">
                                {title && title !== '' && <h2 className="title-text clients-02__title">{title}</h2>}
                                <ul className="clients-02__list">
                                    {logos && logos.map((item:any, key:number) => {
                                        let logoItemContent;

                                        if(item.href && item.href !== ''){
                                            logoItemContent =
                                                (<a className="clients-02__link">
                                                    <WebsiteLogo 
                                                      logoObject={item}
                                                      className='clients-02__logo'
                                                    />
                                                </a>)
                                        }else{
                                            logoItemContent =
                                                (<span className="">
                                                    <WebsiteLogo 
                                                      logoObject={item}
                                                      className='clients-02__logo'
                                                    />
                                                </span>)
                                        }

                                        if(item.uploadedSrc && item.uploadedSrc !== ''){
                                            return (
                                                <li key={"clients-01-" + key} className="clients-02__item">
                                                    {logoItemContent}
                                                </li>
                                            )
                                        }else{
                                            return (null);
                                        }

                                    })}
                                </ul>
                            </div>
                            {/*If only ghost item is left*/}
                            {((features.length === 1 && features[0].title === '') === false) && <ul className="clients-02__text_box">
                                {features && features.map((item:any, key:number) => {
                                    if(item.title && item.title !== ''){
                                        return (
                                            <li className="clients-02__text_item" key={'features-clients-02-' + key}>
                                                <span className="content-text clients-02__text bg-light" dangerouslySetInnerHTML={{__html: item.title}} />
                                            </li>
                                        )
                                    }else{
                                        return (null);
                                    }
                                })}
                            </ul>}
                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default Clients02;
