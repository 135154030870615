import { parseDomain } from "parse-domain";

export const getSubdomain = (domain: string) => {
  if (!domain) return "";
  try {
    const parseResult: any = parseDomain(domain);
    const { subDomains } = parseResult;
    if (!subDomains) return "";
    return subDomains.join('.');
  } catch(e) {
    console.log('Error in getSubdomain: ', e);
    return ""
  }
};
