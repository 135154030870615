import _ from "lodash";
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_TITLE,
  defaultBlogUrl,
  notificationDuration,
} from "../../data/constants";
import { createBlogAsync } from "../../helpers/async/createBlogAsync";
import { AuthState } from "../auth/types";
import { saveSingleBlogInState } from "../blogs/actions";
import { BlogsState } from "../blogs/types";
import { getCurrentStoreData } from "../websites/thunks";
import { openNotification } from "../../helpers/openNotification";
import {
  createBlogPostWaitingForServer,
  saveBlogsPostsInState,
} from "./actions";
import { createPostAsync } from "../../helpers/async/createPostAsync";
import { format } from "fecha";
import { generateUniqueUrl } from "../../helpers/generateUniqueUrl";
import { BlogPostsState } from "./types";

export const createAndSavePost = () => {
  return (dispatch, getState) => {
    const auth: AuthState = getState().auth;
    const blogs: BlogsState = getState().blogs;
    const blogPosts: BlogPostsState = getState().blogPosts;

    const { currentWebsite } = dispatch(getCurrentStoreData());
    let currentBlog = blogs.items.find((blog) => {
      return blog.website === _.get(currentWebsite, "id");
    });

    const createBlog = async () => {
      return await createBlogAsync({
        accessToken: auth.accessToken,
        subdomain: currentWebsite.subdomain,
        newBlogUrl: defaultBlogUrl,
        websiteId: currentWebsite.id,
      })
        .then(({ data }) => {
          dispatch(saveSingleBlogInState([data]));
          currentBlog = data;
          return data;
        })
        .catch((error) => {
          console.error(error.response);
          openNotification(
            DEFAULT_ERROR_TITLE,
            DEFAULT_ERROR_MESSAGE,
            "OK",
            "error",
            notificationDuration.medium
          );
        });
    };

    return new Promise(async (resolve, reject) => {
      dispatch(createBlogPostWaitingForServer(true));

      if (!currentBlog) {
        const response = await createBlog();
        if (!response) {
          dispatch(createBlogPostWaitingForServer(false));
          return;
        }
      }

      const defaultEditorData = {
        time: new Date().getTime(),
        blocks: [],
        version: "2.11.10",
      };

      const currentBlogPosts = blogPosts.items.filter(
        (post) => post.blog === currentBlog.id
      );
      const newPostUrl = generateUniqueUrl(
        "untitled-post",
        currentBlogPosts
      );

      createPostAsync({
        accessToken: auth.accessToken,
        subdomain: currentWebsite.subdomain,
        newPostUrl,
        blogId: currentBlog.id,
        body_json: defaultEditorData,
        published_on: format(new Date(), "YYYY-MM-DD HH:mm:ss"),
      })
        .then(({ data }) => {
          dispatch(saveBlogsPostsInState([data]));
          resolve({ currentBlog, postData: data });
        })
        .catch((error) => {
          console.error(error.response);
          const warningMessage = _.get(error, "response.data.limit");
          if (warningMessage) {
            openNotification(
              "Error",
              warningMessage,
              "OK",
              "warning",
              notificationDuration.medium
            );
            return;
          }
          openNotification(
            DEFAULT_ERROR_TITLE,
            DEFAULT_ERROR_MESSAGE,
            "OK",
            "error",
            notificationDuration.medium
          );
          reject(error);
        })
        .finally(() => {
          dispatch(createBlogPostWaitingForServer(false));
        });
    });
  };
};
