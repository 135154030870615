import React, { Component } from "react";
import { Input } from "antd";
import * as PropTypes from "prop-types";
import * as _ from "lodash";

// props copied from unicornplatform/frontend/node_modules/antd/lib/input/Input.d.ts
declare const InputSizes: ["small", "default", "large"];
type MyState = { currentInputValue: string };

interface Props {
  prefixCls?: string;
  size?: typeof InputSizes[number];
  debounceDelay?: number;
  onPressEnter?: any;
  addonBefore?: any;
  addonAfter?: any;
  prefix?: any;
  suffix?: any;
  allowClear?: boolean;
  onChange?: any;

  type?: string;
  title?: string;
  disabled?: boolean;
  placeholder?: any;
  style?: any;

  id?: any;
  maxLength?: any;
  value?: any;
  defaultValue?: any;
  className?: any;
  onKeyDown?: any;
  onKeyUp?: any;
  onFocus?: any;
  onBlur?: any;
}

class DebouncedInput extends Component<Props, MyState> {
  componentWillMount(): void {
    this.setState({
      currentInputValue: this.props.defaultValue,
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultValue !== this.props.defaultValue) {
      // Forcibly overwrite input value to new default if the default ever changes
      this.setState({ currentInputValue: nextProps.defaultValue });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      currentInputValue: "",
    };
  }

  handleChange = (event: any) => {
    //First, change the value of this input by changing the state
    this.setState({
      currentInputValue: event.target.value,
    });

    // Then call the Redux Store update (to repaint the edited page) if the function is passed (sometimes we use onBlur and there is no need in debounce and therefore in onChange.
    if (this.props.onChange) {
      this.changeReduxStore(event.target.value);
    }
  };

  handleBlur = (event: any) => {
    if (this.props.onBlur) {
      this.props.onBlur(event.target.value);
    }
  };

  // We HAVE TO pass function even if an input doesn't have an this.props.onChange function.
  // So we protect from exception by checking this.props.onChange and passing a do-nothing function in case if this.props.onChange === undefined
  changeReduxStore = _.debounce(
    this.props.onChange ? this.props.onChange : () => {},
    this.props.debounceDelay ? this.props.debounceDelay : 500
  );

  render() {
    return (
      <Input
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        title={this.props.title}
        value={this.state.currentInputValue}
        // defaultValue={this.props.defaultValue}

        prefixCls={this.props.prefixCls}
        size={this.props.size}
        onPressEnter={this.props.onPressEnter}
        addonBefore={this.props.addonBefore}
        addonAfter={this.props.addonAfter}
        prefix={this.props.prefix}
        suffix={this.props.suffix}
        allowClear={this.props.allowClear}
        type={this.props.type}
        id={this.props.id}
        maxLength={this.props.maxLength}
        disabled={this.props.disabled}
        className={this.props.className}
        onKeyDown={this.props.onKeyDown}
        onKeyUp={this.props.onKeyUp}
        onFocus={this.props.onFocus}
        placeholder={this.props.placeholder}
        style={this.props.style}
      />
    );
  }
}

export default DebouncedInput;
