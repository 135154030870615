function formatVideoSourceUrl(videoUrl: string) {
  let formattedVideoUrl = videoUrl;

  // first, let's detect the video source. it can be: youtube, vimeo, wistia or an mp4 file
  // we need to format only youtube and vimeo URLs.

  // if Vimeo
  if (videoUrl.indexOf('vimeo') !== -1) {
    // convert this https://vimeo.com/78033713 into this -> https://player.vimeo.com/video/78033713
    if (videoUrl.indexOf('player') === -1) {
      formattedVideoUrl = formattedVideoUrl.replace("https://vimeo", "https://player.vimeo");
    }
    if (videoUrl.indexOf('video') === -1) {
      formattedVideoUrl = formattedVideoUrl.replace("vimeo.com", "vimeo.com/video");
    }
    return formattedVideoUrl
  } 
  // if YouTube 
  else if ((videoUrl.indexOf('https://youtu.be') !== -1) || videoUrl.indexOf('https://www.youtube.com') !== -1) {
    if (videoUrl.indexOf('https://youtu.be') === 0) {
      /*
      * we get this: https://youtu.be/BMYZiz4FdjQ
      * we want this: https://www.youtube.com/embed/BMYZiz4FdjQ
      * */
      formattedVideoUrl = videoUrl.replace("https://youtu.be/", "https://www.youtube.com/embed/");
    } else if (videoUrl.indexOf('https://www.youtube.com/watch?v=') === 0) {
      /*
      * we get this: https://www.youtube.com/watch?v=7wtfhZwyrcc
      * we want this: https://www.youtube.com/embed/7wtfhZwyrcc
      * */
      formattedVideoUrl = videoUrl.replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/");
    }
    // remove timecodes. A youtube url with a timecode looks like this: https://www.youtube.com/watch?v=BMYZiz4FdjQ&t=0s. We need to remove &t=0s
    let timecode = formattedVideoUrl.split('&')[1];
    if (timecode !== undefined) { // if we have a timecode, take everything except the timecode
      formattedVideoUrl = formattedVideoUrl.split('&')[0];
    }

    if (formattedVideoUrl !== '' && formattedVideoUrl.indexOf("?rel=0") === -1) {
      formattedVideoUrl = formattedVideoUrl + "?rel=0";
      return formattedVideoUrl;
    } else {
      return formattedVideoUrl; // return ''
    }
  }
  // else if everything else (e.g. Wistia or direct mp4 file - do not formatting and just return the original link)
  else{
    return formattedVideoUrl;
  }
}

export default formatVideoSourceUrl;