export const checkIfPageHasComponents = (currentPageComponents:any) => {
    if ((currentPageComponents === undefined || Object.getOwnPropertyNames(currentPageComponents).length === 0) // if the page components object is empty ({}) or undefined
        ||
        // or in case it is not empty - the page components has .data.schema but with zero items
        (currentPageComponents.data !== undefined && currentPageComponents.data.schema !== undefined && currentPageComponents.data.schema.length === 0)){
        // has no components
        return false;
    }else{
        // has some components
        return true;
    }
};