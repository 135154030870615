import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

interface Data {
  email: string;
}

interface ResponseData {
  message: string;
  new_email: string;
  old_email: string;
}

export const changeEmailAsync = (
  accessToken: string,
  data: Data
): Promise<AxiosResponse<ResponseData>> => {
  return new Promise((resolve, reject) => {
    axios
      .patch(api.account.changeEmail, data, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
