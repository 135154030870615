import _ from "lodash";
import { api } from "../../../data/urls";

export async function callFillTemplateStreamAsync({
  handleOnNewCompletionData,
  handleOnCompletionFinished,
  accessToken,
  forceStopAllFlagRef,
  cleanedInputValue,
  abortController,
  templateSubdomain,
  pageArray,
  interruptedCompletion = "",
  skipNav,
  skipFooter,
}) {
  const setValueDebounced = handleOnNewCompletionData; // _.debounce(setValue, 50);
  try {
    const response = await fetch(api.gpt.fillTemplate, {
      method: "POST",
      headers: {
        Authorization: "Token " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cleanedInputValue,
        templateSubdomain,
        interruptedCompletion,
        pageArray,
        skipNav,
        skipFooter,
      }),
      signal: abortController.signal,
    });
    if (!response.status.toString().startsWith("2")) {
      handleOnCompletionFinished();
      let responseData = { error: "" };
      try {
        responseData = await response.json();
      } catch (e) {
        console.error(e);
      }
      return { isError: true, responseData };
    }

    let responseText = "";

    const reader = response.body.getReader();
    return new ReadableStream({
      start(controller) {
        const pump = () => {
          return reader.read().then(({ done, value }) => {
            try {
              // When no more data needs to be consumed, close the stream
              if (done) {
                console.log("FINISH REASON: if (done) === true");
                handleOnCompletionFinished();
                controller.close();
                return;
              }

              if (forceStopAllFlagRef.current) {
                console.log("FINISH REASON: if (forceStopAllFlagRef.current)");
                handleOnCompletionFinished();
                controller.close();
                return;
              }

              // Enqueue the next data chunk into our target stream

              const textDecoder = new TextDecoder();
              const chunkText = textDecoder.decode(value);

              if (chunkText === "[DONE]\n\n") {
                console.log(
                  "FINISH REASON: if (chunkText === '[DONE]\\n\\n') === true"
                );
                handleOnCompletionFinished();
                return;
              }

              responseText += chunkText;
              try {
                setValueDebounced(responseText);
              } catch (e) {
                console.log("FINISH REASON: error in setValueDebounced");
                console.error(e);
                handleOnCompletionFinished();
                controller.close();
                return;
              }
              controller.enqueue(value);
              return pump();
            } catch (e) {
              console.log("FINISH REASON: error everywhere");
              console.error(e);
              handleOnCompletionFinished();
              controller.close();
              return;
            }
          });
        };

        return pump();
      },
    });
  } catch (e) {
    console.log("FINISH REASON: catch (e)");
    console.error(e);
    handleOnCompletionFinished();
  }
}
