import React from "react";
import { Checkbox, Popconfirm } from "antd";
import { urls } from "../../../data/urls";
import { connect } from "react-redux";
import {
  toggleWebsiteCaptchaEditionsDetected,
  saveWebsiteCaptchaInState,
} from "../../../store/websites/actions";

interface Props {
  currentWebsite: any;
  toggleWebsiteCaptchaEditionsDetected?: (payload: boolean) => void;
  saveWebsiteCaptchaInState?: (payload: object) => void;
}

class CaptchaHideBadge extends React.Component<Props> {
  getConfirmationInfo = () => {
    if (!this.isBadgeHidden()) {
      return {
        popover: (
          <div>
            Are you sure you want to hide the reCAPTCHA badge? According to the{" "}
            <a href={urls.thirdparty.captchaBadgeFAQ} target="_blank">
              reCAPTCHA FAQ
            </a>
            , you will have to manually include the reCAPTCHA branding visibly
            in the user flow.
          </div>
        ),
        okText: "Hide the badge",
        cancelText: "Cancel",
      };
    }
    return {
      popover: (
        <div>Display the reCAPTCHA badge across your entire website?</div>
      ),
      okText: "Display the badge",
      cancelText: "Cancel",
    };
  };

  isBadgeHidden = () => {
    if (
      this.props.currentWebsite.recaptcha &&
      this.props.currentWebsite.recaptcha.is_badge_hidden
    ) {
      return true;
    }
    return false;
  };

  handleConfirm = (e: any) => {
    this.props.toggleWebsiteCaptchaEditionsDetected(true);
    const captchaObject = {
      ...this.props.currentWebsite.recaptcha,
      is_badge_hidden: !this.isBadgeHidden(),
    };
    const currentWebsiteSubdomain = this.props.currentWebsite.subdomain;
    this.props.toggleWebsiteCaptchaEditionsDetected(true);
    this.props.saveWebsiteCaptchaInState({
      currentWebsiteSubdomain,
      captchaObject,
    });
  };

  render() {
    return (
      <Popconfirm
        placement="bottomLeft"
        title={
          <div style={{ width: 350 }}>{this.getConfirmationInfo().popover}</div>
        }
        okText={this.getConfirmationInfo().okText}
        cancelText={this.getConfirmationInfo().cancelText}
        onConfirm={this.handleConfirm}
      >
        <Checkbox
          checked={!this.isBadgeHidden()}
        >
          Show "protected by reCAPTCHA" badge.
        </Checkbox>
      </Popconfirm>
    );
  }
}

export default connect(null, {
  toggleWebsiteCaptchaEditionsDetected,
  saveWebsiteCaptchaInState,
})(CaptchaHideBadge);
