import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Links03 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Open Roles"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({
              isDefaultEmpty,
                subtitleText: ''
            }));
            this.props.saveContentInStore(this.generator.setUpFeatures({
              isDefaultEmpty,
                fieldTitle: {default: "Open roles"},
                label: {default: "Edit the open roles:"},
                labelTooltip: {default: "Leave the title field blank to remove a role."},
                maxItems: 24,
                hasPictogram: false,
                hasSubtitle: true,
                hasText: false,
                hasHighlight: false,
                hasHighlightInfo: false,
                buttonTypes: [buttonStyles.ghost.typeTitle, buttonStyles.regular.typeTitle],
                items: [
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Web Designer',
                            subtitle: 'SF Bay Area or Remote',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Send CV", buttonHref: '/', buttonStyleType: buttonStyles.regular.typeTitle, buttonColor: buttonStyles.regular.accentBg})
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Middle Backend Developer',
                            subtitle: 'SF Bay Area or Remote',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Send CV", buttonHref: '/', buttonStyleType: buttonStyles.regular.typeTitle, buttonColor: buttonStyles.regular.accentBg})
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Senior Frontend Developer',
                            subtitle: 'SF Bay Area or Remote',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Send CV", buttonHref: '/', buttonStyleType: buttonStyles.regular.typeTitle, buttonColor: buttonStyles.regular.accentBg})
                        }
                    ),
                    this.generator.createFeatureContentObject(
                        {
                            title: 'Middle Backend Developer',
                            subtitle: 'SF Bay Area or Remote',
                            button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Send CV", buttonHref: '/', buttonStyleType: buttonStyles.regular.typeTitle, buttonColor: buttonStyles.regular.accentBg})
                        }
                    ),


                    // The last ghost item which is used to add new items.
                    this.generator.createFeatureContentObject({
                        title: '',
                        text: '',
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Send CV", buttonHref: '/', buttonStyleType: buttonStyles.regular.typeTitle, buttonColor: buttonStyles.regular.accentBg})
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the links list component to offer more open positions.",
                    default: "Add a CTA (call to action) to the bottom of the links list component to offer more open positions.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'More'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let features = this.props.componentItem.componentData.features.content.items;

        return (
            <section>
                <div className="links-03">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--mid">
                        <ul className="links-03__list">
                            {features && features.map((item:any, key:number) => {
                                if(item.title && item.title !== ''){
                                    return (
                                        <li className={"links-03__item " + (item.isHighlighted ? ' links-03__item--accent ' : ' ')} key={'links-03-' + key}>
                                            <div className={"title-text--inner links-03__title def-16" + (this.props.isDarkBg ? 'text-white' : '')}>{item.title}</div>

                                            <div className="links-03__right">
                                                <div className={"links-03__location " + (this.props.isDarkBg ? 'text-white' : '')}>
                                                    <div className="links-03__icon">
                                                        <span className="icon">
                                                            <svg width="10px" height="15px" viewBox="0 0 10 15" version="1.1"
                                                                 xmlns="http://www.w3.org/2000/svg">
            <g id="Components-Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Links-list" transform="translate(-704.000000, -3284.000000)">
            <g id="04" transform="translate(198.000000, 3023.000000)">
            <g id="Group-11" transform="translate(0.000000, 212.000000)">
            <g id="Group-10">
            <g id="Group-9" transform="translate(0.000000, 37.000000)">
            <g id="placeholder" transform="translate(506.000000, 12.000000)">
            <path
                d="M5,0 C2.243,0 0,2.243 0,5 C0,5.9315 0.258027778,6.84055556 0.746361111,7.62922222 L4.71538889,14.02525 C4.79141667,14.1477778 4.92536111,14.2222222 5.06941667,14.2222222 C5.07052778,14.2222222 5.07161111,14.2222222 5.07272222,14.2222222 C5.21802778,14.2210833 5.35225,14.1443333 5.42688889,14.0196667 L9.29475,7.56166667 C9.75613889,6.78966667 10,5.90386111 10,5 C10,2.243 7.757,0 5,0 Z"
                id="Shape" fill="#ffffff" fillRule="nonzero" className={ (this.props.isDarkBg ? '' : 'fill-main')}/>
            <path
                d="M8.57961111,7.13383333 L5.06311111,13.0051667 L1.45463889,7.19016667 C1.04816667,6.53372222 0.827777778,5.77638889 0.827777778,5 C0.827777778,2.7025 2.7025,0.827777778 5,0.827777778 C7.2975,0.827777778 9.16944444,2.7025 9.16944444,5 C9.16944444,5.75336111 8.96358333,6.49133333 8.57961111,7.13383333 Z"
                id="Path" fill="#ffffff" className={ (this.props.isDarkBg ? '' : 'fill-main')}/>
            <path
                d="M5,2.5 C3.6215,2.5 2.5,3.6215 2.5,5 C2.5,6.36969444 3.60325,7.5 5,7.5 C6.41397222,7.5 7.5,6.35463889 7.5,5 C7.5,3.6215 6.3785,2.5 5,2.5 Z"
                id="Shape" fill="#ffffff" className={ (this.props.isDarkBg ? '' : 'fill-main')} fillRule="nonzero"/>
            <path
                d="M5.00069281,7.50694117 C3.6161363,7.50694117 2.49444444,6.38150243 2.49444444,5.00069281 C2.49444444,3.62333864 3.62333864,2.49444444 5.00069281,2.49444444 C6.37804698,2.49444444 7.50277797,3.62333864 7.50277797,5.00069281 C7.50277797,6.36135253 6.4071894,7.50694117 5.00069281,7.50694117 Z"
                id="Path" fill={(this.props.isDarkBg ? '#000000' : '#ffffff')}/>
            </g>
            </g>
            </g>
            </g>
            </g>
            </g>
            </g>
            </svg>
                                                        </span>
                                                    </div>
                                                    <span className="content-text links-03__location__text">{item.subtitle}</span>
                                                </div>

                                                <div className="links-03__button_box">
                                                    {item.button.title !== '' && item.button.href !== '' && <WebsiteButton config={{
                                                        buttonStyleType: item.button.settings.appearance.styleType,
                                                        buttonColor: item.button.settings.appearance.color,
                                                        buttonType: linkOrButtonOptions.link,
                                                        buttonTitle: item.button.title,
                                                        buttonHref: item.button.href,
                                                        buttonIsTargetBlank: item.button.isTargetBlank,
                                                        buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                                        pillPrefix: item.button.pillPrefix,
                                                        pillColor: item.button.settings.appearance.pillColor,
                                                        ghostColor: item.button.settings.appearance.ghostColor,
                                                    }} />}
                                                </div>
                                            </div>

                                        </li>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </ul>
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Links03;
