import { Chat, GptState } from "../types";

export const getCurrentChatData = (pageId: number) => {
  return (dispatch, getState) => {
    const gptState: GptState = getState().gpt;
    const currentChat = gptState.chats.find((chat) => chat.pageId === pageId);
    if (!currentChat) {
      return {
        pageId,
        chatMessages: [],
        isTyping: "none",
        isOpenedOnce: false,
        totalMessagesCreated: 0,
        isLoading: "none",
        mode: "edit",
      } as Chat;
    }
    return currentChat;
  };
};
