import React from "react";
import { freePlanTitle } from "../../../../../data/constants";

interface Props {
  title: string;
  description: string;
  activePricingPeriod: string;
  price_per_website_per_month: number;
}

const PlanDescription = (props: Props): JSX.Element => {
  const {
    title,
    description,
    activePricingPeriod,
    price_per_website_per_month,
  } = props;

  if (title === freePlanTitle) {
    return (
      <span className="">
        {" "}
        <span className=""> {description}</span>{" "}
      </span>
    );
  } else if (title === "maker" || title === "startup") {
    return (
      <span className="plan__description">
        {" "}
        <span className="plan__description_info"> {description}</span>{" "}
        <span className="plan__period">Billed {activePricingPeriod}</span>{" "}
      </span>
    );
  } else {
    return (
      <span className="plan__description">
        {" "}
        <span className="plan__description_info">
          {" "}
          ${price_per_website_per_month} per website
        </span>{" "}
        <span className="plan__period">Billed {activePricingPeriod}</span>{" "}
      </span>
    );
  }
};

export default PlanDescription;
