function composeSortedArray(object: any): any {
  // We have an object of fields which looks like this:
  /*
        {
            'cta': {'priority': 150, ...},
            'title': {'priority': 170, ...},
            ...
        }

        We need to display the object sorted: by priority descending. But we can not sort an object, we need an array.
        So we create an array which looks like this:
        [
            ['cta', {'priority': 150}],
            ['title', {'priority': 170}]
        ]

        and apply .sort() to sort by priority.

        Kudos: https://stackoverflow.com/a/1359895/8276455
    */

  let arrayOfObjectItems = [],
    i;
  for (i in object) {
    if (object.hasOwnProperty(i)) {
      arrayOfObjectItems.push([i, object[i]]);
    }
  }
  arrayOfObjectItems.sort(function (item1, item2) {
    if (item1[0] === "directory") return -1;
    if (item2[0] === "directory") return 1;
    return item1[1].priority < item2[1].priority ? 1 : -1;
  });
  return arrayOfObjectItems;
}

export default composeSortedArray;
