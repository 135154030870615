import _ from "lodash";
import { ComponentDataPaths, PopupType } from "../types/popupTypes";

export const getPopupDataFromInputData = (
  currentInputData: any,
  popupType: PopupType,
  componentDataPath: ComponentDataPaths,
  contentType: string
) => {
  const popupData = {
    isActive: false,
    popupId: "",
  };
  const currentInputObject = { [contentType || ""]: currentInputData || {} };

  const path = _.get(componentDataPath, [`${popupType}`, `${contentType}`]);

  if (!path) return popupData;

  popupData.isActive = _.get(
    currentInputObject,
    `${path}.popup.isActive`,
    false
  );
  popupData.popupId = _.get(currentInputObject, `${path}.popup.popupId`, "");

  return popupData;
};
