import React from "react";
import ChangePasswordMenu from "./ChangePasswordMenu";
import ChangeEmailMenu from "./ChangeEmailMenu";

const AuthorizationField = (): JSX.Element => {
  return (
    <div className="account_fields__block">
      <div className="account_fields__rows">
        <ChangeEmailMenu />
        <ChangePasswordMenu />
      </div>
    </div>
  );
};

export default AuthorizationField;
