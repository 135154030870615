import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import { fetchConnectedIntegrationsAsync } from "./fetchConnectedIntegrationsAsync";

export const addIntegrationAsync = (
  accessToken: string,
  newIntegrationObject: any,
  currentWebsite: any
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    fetchConnectedIntegrationsAsync(accessToken, currentWebsite.id)
      .then((response) => {
        const apiUrl =
          api.websites.updateWebsite.prefix +
          currentWebsite.subdomain +
          api.websites.updateWebsite.postfix;

        const integrationsToServer = {
          ...response,
          connected: response.connected
            ? [...response.connected, newIntegrationObject]
            : [newIntegrationObject],
        };
        axios
          .patch(
            apiUrl,
            {
              connected_form_integrations: integrationsToServer,
            },
            { ...returnAuthHeaderForAJAX(accessToken) }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};
