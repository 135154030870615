import { UnicornComponent } from "./../../../../classes/UnicornComponent";
import { isKeyValue } from "./../../../../helpers/isKeyValue";
import _ from "lodash";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { Components } from "../../../../enums/Components";
import { buttonStyles } from "../../../../data/buttonStyles";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  const itemDescription =
    stringifyObjectsAndNumbers(modifiedItem.DESCRIPTION) || "";
  const itemIsHighlighted =
    stringifyObjectsAndNumbers(modifiedItem.IS_HIGHLIGHTED) === true;
  const itemBadgeText =
    stringifyObjectsAndNumbers(modifiedItem.HIGHLIGHT_BADGE_TEXT) || "";
  const itemButtonText =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TEXT"])) || "";
  const itemButtonUrl =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "URL"])) || "";
  const itemButtonTarget =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TARGET"])) ||
    "_self";
  const itemSubtitle = stringifyObjectsAndNumbers(modifiedItem.SUBTITLE) || "";
  return {
    itemId,
    itemPosition,
    itemTitle,
    itemIsHighlighted,
    itemBadgeText,
    itemDescription,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
    itemSubtitle,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemIsHighlighted,
  itemBadgeText,
  itemTitle,
  itemDescription,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget,
  itemSubtitle
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.features.content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.features.content.items, item);
      }
    });
  }
  if ("IS_HIGHLIGHTED" in modifiedItem) {
    originalItem.isHighlighted = itemIsHighlighted;
  }
  if ("HIGHLIGHT_BADGE_TEXT" in modifiedItem) {
    originalItem.highlightedInfo = itemBadgeText;
  }
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("DESCRIPTION" in modifiedItem) {
    originalItem.text = itemDescription;
  }
  if ("SUBTITLE" in modifiedItem) {
    originalItem.subtitle = itemSubtitle;
  }
  if ("BUTTON" in modifiedItem) {
    if (modifiedItem.BUTTON === null) {
      originalItem.button.title = "";
      originalItem.button.href = "";
      originalItem.button.isTargetBlank = false;
      return;
    }
    if ("TEXT" in modifiedItem.BUTTON) {
      originalItem.button.title = itemButtonText;
    }
    if ("URL" in modifiedItem.BUTTON) {
      originalItem.button.href = itemButtonUrl;
    }
    if ("TARGET" in modifiedItem.BUTTON) {
      originalItem.button.isTargetBlank = itemButtonTarget === "_blank";
    }
  }
};

export const updateFeatures = (
  componentData,
  completionContent,
  currentComponentInfo: UnicornComponent
) => {
  if (!("FEATURES" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.FEATURES)) {
    if (completionContent.FEATURES === null) {
      componentData.features.content.items = [];
    }
    return;
  }

  componentData.features.content.items = deleteEmptyItems(
    componentData.features.content.items,
    "title"
  );
  addDefaultValuesToItems(componentData.features.content.items);

  completionContent.FEATURES.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }

    let {
      itemId,
      itemPosition,
      itemTitle,
      itemIsHighlighted,
      itemBadgeText,
      itemDescription,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget,
      itemSubtitle,
    } = particleGetModifiedValues(modifiedItem);

    if (itemPosition === null) {
      componentData.features.content.items = deleteItem(
        componentData.features.content.items,
        itemId
      );
      return;
    }

    const isItemNew = getIsItemNew(
      componentData.features.content.items,
      itemId
    );

    if (isItemNew) {
      const newItem = generator.createFeatureContentObject();
      newItem.title = "";
      newItem.text = "";
      newItem.subtitle = "";
      newItem.button.title = "";
      if (currentComponentInfo.name === Components.header28) {
        newItem.button.settings.appearance.styleType =
          buttonStyles.regular.typeTitle;
        newItem.button.settings.appearance.color =
          buttonStyles.regular.whiteOutline;
      }
      if (currentComponentInfo.name === Components.links03) {
        newItem.button.settings.appearance.styleType =
          buttonStyles.regular.typeTitle;
        newItem.button.settings.appearance.color =
          buttonStyles.regular.accentBg;
      }
      addNewItem(
        componentData.features.content.items,
        newItem,
        itemId,
        itemPosition
      );
    }

    const originalItem = getOriginalItem(
      componentData.features.content.items,
      itemId
    );

    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemIsHighlighted,
      itemBadgeText,
      itemTitle,
      itemDescription,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget,
      itemSubtitle
    );
  });

  sortItemsByPosition(componentData.features.content.items);
};
