import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type ManageWebhooksAsync = (payload: {
  webhook_name: string;
  accessToken: string;
  websiteId: number;
}) => Promise<AxiosResponse<any>>;

export const manageWebhooksAsync: ManageWebhooksAsync = ({
  accessToken,
  webhook_name,
  websiteId,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${api.websites.manageWebhooks}/${websiteId}`;
    axios
      .post(
        apiUrl,
        {
          webhook_name,
        },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
