export const thirdLevelDomainZones = [
    //some more; https://publicsuffix.org/list/public_suffix_list.dat
    'com.au',
    'com.ua',
    'co.uk',
    'co.in',
    'com.au',
    'net.au',
    'org.au',
    'com.br',
];