export enum LegacyComponents {
  header05 = "header-05",
  header06 = "header-06",
  header02 = "header-02",
  header08 = "header-08",
  header09 = "header-09",
  header01 = "header-01",
  slider03 = "slider-03",
  slider04 = "slider-04",
  slider06 = "slider-06",
  tabs02 = "tabs-02",
}
