import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

interface ResponseData {
  message: string;
}

export const deleteAccountAsync = (
  accessToken: string
): Promise<AxiosResponse<ResponseData>> => {
  return new Promise((resolve, reject) => {
    axios
      .delete(api.account.deleteAccount, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => reject(error));
  });
};
