import React from "react";

interface Props {
  isLoading: boolean;
}

const LoadingIndicator = (props: Props): JSX.Element => {
  const { isLoading } = props;

  if (!isLoading) return null;

  return (
    <div className="gpt-assistant__loading-indicator-wrapper">
      <span className="gpt-assistant__loading-indicator-text">
        AI is working
      </span>
      <div className="gpt-assistant__loading-indicator">
        <span className="gpt-assistant__loading-indicator-dot"></span>
        <span className="gpt-assistant__loading-indicator-dot"></span>
        <span className="gpt-assistant__loading-indicator-dot"></span>
      </div>
    </div>
  );
};

export default LoadingIndicator;
