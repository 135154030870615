import { ltdPlanTitle } from '../../data/constants';
export const setIsLtdPlan = (user:any, plans:any, toggleUserLtd:any) => {
    let userPlanId = user.data.current_plan;
    let plansCount = plans.items.length;
    for(let i = 0; i < plansCount; i++){
        let currentPlan = plans.items[i];
        if(currentPlan.id === userPlanId && currentPlan.title === ltdPlanTitle){
            toggleUserLtd(true);
        }
    }
};