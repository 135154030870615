import { GptState, ChangeHistoryItem } from "../types";

export const getChangeHistoryItem = (componentId: string) => {
  return (dispatch, getState) => {
    const gptState: GptState = getState().gpt;
    const currentChangeHistoryItem = gptState.changeHistory.find(
      (change) => change.componentId === componentId
    );
    if (!currentChangeHistoryItem) {
      return {
        componentId,
        before: {},
        after: {},
        availableAction: "undo",
      } as ChangeHistoryItem;
    }
    return currentChangeHistoryItem;
  };
};
