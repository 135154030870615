import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import { ZapierIntegrationStatus } from "./../../enums/ZapierIntegrationStatus";
import { api } from "../../data/urls";
import _ from "lodash";
import axios from "axios";

export const fetchZapierStatusAsync = (
  accessToken: string,
  websiteId: number,
  integrationId: string
): Promise<ZapierIntegrationStatus> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.websites.fetchWebsites}?id=${websiteId}`, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        const website = response.data[0];
        const integrationsArray = _.get(
          website,
          ["connected_form_integrations", "connected"],
          []
        );
        const currentIntegration = _.find(integrationsArray, {
          id: integrationId,
        });
        if (!currentIntegration) {
          resolve(ZapierIntegrationStatus.notFound);
          return;
        }
        resolve(currentIntegration.data.status);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
