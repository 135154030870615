import React from "react";
import _ from "lodash";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import DebouncedTextarea from "../../../DebouncedTextarea";
import { GraphicsOptions } from "../../component_graphics/data/componentGraphicsData";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  saveContentInStore: (payload: any) => void;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  keyProp: number;
  toggleNewEditionsDetected: any;
  content: any;
}

const StepHTMLInput = (props: Props): JSX.Element => {
  const {
    saveContentInStore,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
    content,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);
  const generator = new generateContentDataObject();

  const handleOnChange = (value: string) => {
    saveContentInStore(
      generator.changeStepCodeSnippet(
        { [contentType]: currentInputDataRef.current },
        value,
        keyProp
      )
    );
    toggleNewEditionsDetected(true);
  };

  return (
    <div className="content-input__input">
      <DebouncedTextarea
        className="content-input__input content-input__input--monospace"
        placeholder={"Enter any HTML code. Examples: <iframe> <script> etc."}
        autosize={{ minRows: 2, maxRows: 20 }}
        onChange={handleOnChange}
        defaultValue={_.get(content, [
          "items",
          keyProp,
          "graphics",
          GraphicsOptions.HTML,
          "codeSnippet",
        ])}
        id={contentType}
      />
    </div>
  );
};

export default StepHTMLInput;
