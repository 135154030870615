import {pricingTiersFeatures} from "../../data/pricingTiersFeatures";
import {appsumoPlanTitle, ltdPlanTitle} from "../../data/constants";

export const checkIfCurrentUserPlanSupportsDeals = (user:any, plans:any):boolean => {
    let userPlanId = user.data.current_plan;
    let plansCount = plans.items.length;
    
    for(let i = 0; i < plansCount; i++){
        let currentPlan = plans.items[i];

        if(currentPlan.id === userPlanId){ //found current active plan

            // give the deals to the appsumo and the ltd users
            if(currentPlan.title === ltdPlanTitle || currentPlan.title === appsumoPlanTitle){
                return true;
            }

            //check if this plan supports deals
            for (let j = 0; j < pricingTiersFeatures.length; j++) {
                if(pricingTiersFeatures[j]['is_deals'] && pricingTiersFeatures[j][currentPlan.title]){
                    return true
                }
            }
        }
    }
};