import { QueueItem } from "./../../../components/editor/ai2/types";
import { addKeysToArrays } from "../../../components/editor/ai2/addKeysToArrays";
import { callFillTemplateStreamAsync } from "../../../components/editor/ai2/callFillTemplateStreamAsync";
import { getPageArray } from "../../../components/editor/ai2/getPageArray";
import { saveComponentDataToQueue } from "../../../components/editor/ai2/saveComponentDataToQueue";
import { uppercaseKeys } from "../../../components/editor/ai2/uppercaseKeys";
import {
  GPT_CHARACTERS_PER_TICK_TEMPLATE,
  GPT_RENDER_DATA_INTERVAL_MS,
  notificationDuration,
} from "../../../data/constants";
import { AuthState } from "../../auth/types";
import { addModifiedComponent } from "../actions";
import { handleCleanUpAiData } from "./handleCleanUpAiData";
import { handleOnInterval } from "./handleOnInterval";
import { FillTemplateAsyncPayload } from "../types";
import { editFillTemplateData } from "../../websites/actions";
import _ from "lodash";
import { openNotification } from "../../../helpers/openNotification";
import yaml from "js-yaml";

export const fillTemplateAsync = ({
  currentWebsitePage,
  currentWebsite,
  currentPageIndex,
  cleanedInputValue,
  queueDataRef,
  intervalRef,
  forceStopAllFlagRef,
  abortControllersRef,
  templateSubdomain,
  setIsFillTemplateFormLoading,
  deleteModifiedComponent,
  completionRef,
  skipNav,
  skipFooter
}: FillTemplateAsyncPayload) => {
  return async (dispatch, getState) => {
    const pageArray = getPageArray(currentWebsitePage, currentWebsite);
    const fullPageContext = pageArray.map((pageComponent) => {
      return pageComponent.id;
    });
    dispatch(
      editFillTemplateData({
        websiteId: currentWebsite.id,
        newData: {
          componentsTotal: fullPageContext.length,
        },
      })
    );

    const handleOnCompletionFinished = async () => {
      queueDataRef.current.forEach((queueItem) => {
        queueItem.isCompletionFinished = true;
        if (!queueItem.completionData) {
          queueItem.completionData = "{error:true}";
        }
      });
    };

    const handleOnNewCompletionData = (completionData) => {
      const fullCompletionData = `blocks:
- block_type: "Navigation bar"
  navigation_logo:
    company_name: "${completionData}`;
      console.log("fullCompletionData: ", fullCompletionData);
      let parsedResult = null;
      try {
        parsedResult = yaml.load(fullCompletionData);
      } catch (e) {}
      if (!parsedResult) return;
      console.log("parsedResult: ", parsedResult);
      addKeysToArrays(parsedResult);
      const uppercasedParsedResult = uppercaseKeys(parsedResult);
      uppercasedParsedResult.BLOCKS.forEach((block, index) => {
        const component = pageArray[index];
        const previousComponent = pageArray[index - 1];
        let queueItem: QueueItem = null;
        if (previousComponent) {
          queueItem = queueDataRef.current.find((queueItem) => {
            return previousComponent.id === queueItem.componentId;
          });
        }
        if (queueItem && !queueItem.isCompletionFinished) {
          queueItem.isCompletionFinished = true;
        }
        if (component) {
          saveComponentDataToQueue({
            newCompletionJson5: JSON.stringify(block),
            componentId: component.id,
            currentWebsitePage,
            currentWebsite,
            queueDataRef,
          });
        }
      });
    };

    fullPageContext.forEach((componentId: string) => {
      const component = pageArray.find((pageComponent) => {
        return pageComponent.id === componentId;
      });
      dispatch(
        addModifiedComponent({
          componentId: component.id,
        })
      );
      dispatch(
        handleCleanUpAiData({
          componentId,
          currentPageIndex,
          currentWebsitePage,
          currentWebsite,
        })
      );
    });

    fullPageContext.forEach((componentId: string) => {
      queueDataRef.current.push({
        componentId,
        completionData: "",
        parsedCompletionData: "",
        isCompletionFinished: false,
        reasoning: "",
        counter: 0,
        slicedCompletionData: "",
      });
    });

    const auth: AuthState = getState().auth;

    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => {
        dispatch(
          handleOnInterval({
            intervalRef,
            forceStopAllFlagRef,
            queueDataRef,
            currentWebsitePage,
            currentWebsite,
            currentPageIndex,
            charactersPerTick: GPT_CHARACTERS_PER_TICK_TEMPLATE,
            isTemplate: true,
          })
        );
      }, GPT_RENDER_DATA_INTERVAL_MS);
    }
    abortControllersRef.current = abortControllersRef.current.filter((item) => {
      return item.componentId !== "fill-template";
    });
    const abortController = new AbortController();
    abortControllersRef.current.push({
      componentId: "fill-template",
      abortController,
    });
    const response = await callFillTemplateStreamAsync({
      handleOnNewCompletionData,
      handleOnCompletionFinished,
      accessToken: auth.accessToken,
      forceStopAllFlagRef,
      cleanedInputValue,
      abortController,
      templateSubdomain,
      pageArray,
      skipNav,
      skipFooter,
    });
    const isError = _.get(response, ["isError"]);
    if (isError) {
      const errorMessage = _.get(
        response,
        ["responseData", "error"],
        "Sorry, there was an error. Please try again."
      );
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      queueDataRef.current = [];
      fullPageContext.forEach((componentId: string) => {
        deleteModifiedComponent({
          componentId,
        });
      });
      openNotification(
        "Error",
        errorMessage,
        "OK",
        "error",
        notificationDuration.medium
      );
    }
    return response;
  };
};
