import React from "react";
import classNames from "classnames";
import { StopCompletion } from "../types";

interface Props {
  isLoading: boolean;
  stopCompletion: StopCompletion;
  componentId: string;
}

const StopGeneratingButton = (props: Props): JSX.Element => {
  const { isLoading, stopCompletion, componentId } = props;

  if (!isLoading) return null;

  return (
    <button
      className={classNames(
        "gpt-assistant__input-button",
        "gpt-assistant__stop-button",
        {
          "gpt-assistant__input-button--hidden": !isLoading,
        }
      )}
      onClick={() => {
        stopCompletion(componentId);
      }}
    >
      Stop
    </button>
  );
};

export default StopGeneratingButton;
