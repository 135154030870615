import React from "react";
import { Button, Checkbox } from "antd";
import { InputProps } from "./interfaces";
import { getFullWebsiteUrl } from "../../../helpers/editor/getFullWebsiteUrl";
import DebouncedInput from "../../DebouncedInput";
import { useCurrentInputData } from "./useCurrentInputData";

const Logos = (props: InputProps): JSX.Element => {
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    currentWebsite,
    displayLogoEditor,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  let logosCount = content.items.length;
  let maxItemsReached = logosCount >= currentInputDataRef.current.maxItems; //count could not be bigger than maxItems in theory
  let logosInputs = content.items.map((item: any, key: number) => {
    let onChangeLogosItemHrefHandler = (value: any) => {
      saveContentInStore(
        generator.changeLogosItemHref(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };

    return (
      <div
        className={
          "content-input__cluster " +
          (maxItemsReached === false
            ? " content-input__cluster--with-ghost "
            : " ")
        }
        data-add-new="Add new logo ⤵"
        key={key}
      >
        <Button
          onClick={() => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.deleteLogoItem({ [contentType]: currentInputDataRef.current }, key)
            );
          }}
          htmlType="button"
          className="content-input__control_cluster"
          shape="circle"
          title="Delete this item"
          icon="cross"
          size="small"
        />

        {displayMoveUpDownButtons(
          key,
          logosCount,
          () => {
            saveContentInStore(
              generator.moveLogoItemUp({ [contentType]: currentInputDataRef.current }, key)
            );
          },
          () => {
            saveContentInStore(
              generator.moveLogoItemDown(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          }
        )}

        {displayLogoEditor(item, key)}

        {item.type && item.type !== "" && (
          <DebouncedInput
            style={{ marginTop: 40 }}
            className="content-input__input"
            onChange={onChangeLogosItemHrefHandler}
            defaultValue={item.href}
            placeholder={
              getFullWebsiteUrl(currentWebsite) +
              "/case-" +
              Math.floor(Math.random() * 20 + 10)
            } //e.g. https://stripe.com/case-12
          />
        )}
        {item.type && item.type !== "" && item.href !== "" && (
          <Checkbox
            className="content-input__input"
            checked={item.isTargetBlank}
            onChange={(event: any) => {
              saveContentInStore(
                generator.changeLogosItemIsTargetBlank(
                  {
                    [contentType]: currentInputDataRef.current,
                  },
                  key,
                  event.target.checked
                )
              );
              toggleNewEditionsDetected(true);
            }}
          >
            Open link in a new tab
          </Checkbox>
        )}
      </div>
    );
  });

  return (
    <div>
      {logosInputs}
      {maxItemsReached === false && (
        <Button
          size="small"
          style={{ marginBottom: 40 }}
          onClick={() => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.addEmptyLogoIfNeeded({
                [contentType]: currentInputDataRef.current,
              }),
              ""
            );
          }}
          icon="plus"
        >
          Add logo
        </Button>
      )}
    </div>
  );
};

export default Logos;
