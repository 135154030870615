import React from "react";
import { logoOptions } from "../../data/logoOptions";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
  logoObject: any;
  className?: string;
}

const WebsiteLogo = (props: Props): JSX.Element => {
  const { logoObject, className } = props;

  if (logoObject.type === logoOptions.uploaded.title) {
    return (
      <img
        className={className}
        src={formatImageUrl(logoObject.uploadedSrc)}
        height={logoObject.height}
      />
    );
  }

  return null;
};

export default WebsiteLogo;
