import { isKeyValue } from "../../../../helpers/isKeyValue";
import { updateListGroups } from "./updateListGroups";
import { updateListItems } from "./updateListItems";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addTemporaryIds } from "./updaterFunctions.ts/addTemporaryIds";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteListItems } from "./updaterFunctions.ts/deleteListItems";
import { moveListItems } from "./updaterFunctions.ts/moveListitems";

export const updateList = (componentData, completionContent) => {
  if (!("LIST" in completionContent)) {
    return;
  }
  if (!isKeyValue(completionContent.LIST)) {
    if (completionContent.LIST === null) {
      componentData.list.content.items = [];
      componentData.list.content.groups = [];
    }
    return;
  }
  
  componentData.list.content.items = deleteEmptyItems(
    componentData.list.content.items,
    "title"
  );

  addTemporaryIds(componentData.list.content.groups);
  componentData.list.content.groups = deleteEmptyItems(
    componentData.list.content.groups,
    "title"
  );
  componentData.list.content.items = deleteListItems(componentData);
  moveListItems(componentData);
  addDefaultValuesToItems(componentData.list.content.groups);
  addDefaultValuesToItems(componentData.list.content.items);

  updateListGroups(componentData, completionContent.LIST);
  updateListItems(componentData, completionContent.LIST);
};
