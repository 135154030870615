import generateRandomNumerousId from "../generateRandomNumerousId";

function addNoCacheParamToURL(url: any){
    // We need to add ?nochache=any_value to a page if we want to see updates.
    // We need to generate random value each time: ?nochache=31231 ?nochache=123 ?nochache=51

    let newUrl = url;

    if(newUrl.endsWith('/') === false){
        newUrl = newUrl + '/'
    }

    newUrl = newUrl + '?nocache=' + generateRandomNumerousId();


    return newUrl;

}

export default addNoCacheParamToURL;