import { Tooltip, Icon, Input, Button } from "antd";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  ChangeWebsitesStateGlobalData,
  GetCurrentStoreData,
  SavePartialWebsiteData,
  WebsitesState,
} from "../../store/websites/types";
import { AuthState } from "../../store/auth/types";
import _ from "lodash";
import {
  getCurrentStoreData,
  savePartialWebsiteData,
} from "../../store/websites/thunks";
import "./CustomSitemapUrlsSettings.scss";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import { changeWebsitesStateGlobalData } from "../../store/websites/actions";
import { updateWebsiteAsync } from "../../helpers/async/updateWebsiteAsync";

interface Props {
  websites: WebsitesState;
  auth: AuthState;
  getCurrentStoreData: GetCurrentStoreData;
  savePartialWebsiteData: SavePartialWebsiteData;
  changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData;
}

interface UrlItemProps extends Props {
  index: number;
  item: string;
}
const UrlItem = (props: UrlItemProps) => {
  const {
    index,
    item,
    websites,
    getCurrentStoreData,
    savePartialWebsiteData,
    changeWebsitesStateGlobalData,
  } = props;
  const [value, setValue] = useState(item);
  const { currentWebsite } = getCurrentStoreData();
  const sitemapUrlsItems = _.get(currentWebsite, "custom_sitemap.items", []);

  const handleOnSave = (e) => {
    e.preventDefault();
    changeWebsitesStateGlobalData({
      isCustomSitemapWaitingForServer: true,
    });
    updateWebsiteAsync({
      accessToken: props.auth.accessToken,
      subdomain: currentWebsite.subdomain,
      data: {
        custom_sitemap: {
          items: [
            ...sitemapUrlsItems.slice(0, index),
            value,
            ...sitemapUrlsItems.slice(index + 1),
          ],
        },
      },
    })
      .then(({ data }) => {
        savePartialWebsiteData({
          websiteId: currentWebsite.id,
          data: {
            custom_sitemap: data.custom_sitemap,
          },
        });
      })
      .finally(() => {
        changeWebsitesStateGlobalData({
          isCustomSitemapWaitingForServer: false,
        });
      });
  };

  const handleOnDelete = () => {
    changeWebsitesStateGlobalData({
      isCustomSitemapWaitingForServer: true,
    });
    updateWebsiteAsync({
      accessToken: props.auth.accessToken,
      subdomain: currentWebsite.subdomain,
      data: {
        custom_sitemap: {
          items: [
            ...sitemapUrlsItems.slice(0, index),
            ...sitemapUrlsItems.slice(index + 1),
          ],
        },
      },
    })
      .then(({ data }) => {
        savePartialWebsiteData({
          websiteId: currentWebsite.id,
          data: {
            custom_sitemap: data.custom_sitemap,
          },
        });
      })
      .finally(() => {
        changeWebsitesStateGlobalData({
          isCustomSitemapWaitingForServer: false,
        });
      });
  };

  return (
    <form className="custom-sitemap-url-settings__item" onSubmit={handleOnSave}>
      <div style={{ flexGrow: 1, position: "relative" }}>
        <Input
          value={value}
          style={{ width: "100%" }}
          onChange={(e) => setValue(e.target.value)}
          placeholder="tools/nocode"
          addonBefore={
            <div
              style={{
                maxWidth: "150px",
                direction: "rtl",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getActiveDomain(currentWebsite)}/&lrm;
            </div>
          }
          disabled={websites.isCustomSitemapWaitingForServer}
        />
        <Button
          className="custom-sitemap-url-settings__delete"
          size="small"
          icon="close"
          shape="circle"
          loading={websites.isCustomSitemapWaitingForServer}
          onClick={handleOnDelete}
        />
      </div>
      {sitemapUrlsItems[index] !== value && (
        <Button
          type="primary"
          icon="save"
          loading={websites.isCustomSitemapWaitingForServer}
          disabled={websites.isCustomSitemapWaitingForServer}
          htmlType="submit"
        />
      )}
    </form>
  );
};

const CustomSitemapUrlsSettings = (props: Props): JSX.Element => {
  const {
    websites,
    auth,
    getCurrentStoreData,
    savePartialWebsiteData,
    changeWebsitesStateGlobalData,
  } = props;

  const { currentWebsite } = getCurrentStoreData();
  const sitemapUrlsItems = _.get(currentWebsite, "custom_sitemap.items", []);

  const handleAddItem = () => {
    changeWebsitesStateGlobalData({
      isCustomSitemapWaitingForServer: true,
    });
    updateWebsiteAsync({
      accessToken: auth.accessToken,
      subdomain: currentWebsite.subdomain,
      data: {
        custom_sitemap: {
          items: [...sitemapUrlsItems, ""],
        },
      },
    })
      .then(({ data }) => {
        savePartialWebsiteData({
          websiteId: currentWebsite.id,
          data: {
            custom_sitemap: data.custom_sitemap,
          },
        });
      })
      .finally(() => {
        changeWebsitesStateGlobalData({
          isCustomSitemapWaitingForServer: false,
        });
      });
  };

  return (
    <div className="settings-box__input custom-sitemap-url-settings">
      <div className="settings-box__input_label">
        <div>
          <Tooltip
            title={
              <div>
                Enter additional pages that will be added to your{" "}
                <a
                  href={`https://${getActiveDomain(
                    currentWebsite
                  )}/sitemap.xml`}
                  target="_blank"
                  className="content-input__tooltip-link"
                >
                  sitemap.xml
                </a>{" "}
                file.
              </div>
            }
          >
            <Icon type="info-circle" />
          </Tooltip>
        </div>
        <div className="settings-box__input_info_text">
          Additional{" "}
          <a
            href={`https://${getActiveDomain(currentWebsite)}/sitemap.xml`}
            target="_blank"
            className="underlined_link"
          >
            sitemap.xml
          </a>{" "}
          pages:
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        {sitemapUrlsItems.length > 0 && (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            {sitemapUrlsItems.map((item: string, index: number) => {
              return (
                <UrlItem
                  key={`${index}-${item}`}
                  index={index}
                  item={item}
                  {...props}
                />
              );
            })}
          </div>
        )}
        <div>
          <Button
            size="small"
            icon="plus"
            onClick={handleAddItem}
            loading={websites.isCustomSitemapWaitingForServer}
          >
            Add new page URL
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  websites: state.websites,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  getCurrentStoreData,
  changeWebsitesStateGlobalData,
  savePartialWebsiteData,
})(CustomSitemapUrlsSettings);
