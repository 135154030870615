import {draftJsEditorTitle} from "../../data/constants";

export const returnTextEditorType = (currentInputData:any) => {
    /*
            Important: we need to somehow detect old text fields and apply DraftJS rich text editor only to new text fields (because old content can break the DraftJS editor because it accepts only html generated by DraftJS).
            Old text fields DOES NOT has "settings" in their object.
            New text fields has settings.editor in their object
            */

    let textEditorType = undefined;
    try{
        if(currentInputData){
            let contentInputSettings = currentInputData.settings;
            if(contentInputSettings && contentInputSettings['editor'] && contentInputSettings['editor'] === draftJsEditorTitle){
                textEditorType = draftJsEditorTitle
            }
        }
    }catch (e) {

    }
    return textEditorType;
};