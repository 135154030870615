export const getCurrentBlogPostUrl = (currentBlog: any, blogPostsItems: any) => {
    // This function checks if it's an url of a real blog post of just a utility page (e.g. '/settings' or '/account').
    // Please pass the currentBlog in the arguments to validate.

    let pathArray = location.pathname.split('/');
    const postId = parseInt(pathArray[pathArray.length - 1]);

    if (!postId) return undefined;

    // See if this url belongs to a real blog post of our currentBlog.

    if(currentBlog === undefined){
        return undefined;
    }
    // Find all posts of the given blog.
    let currentBlogId = currentBlog.id;


    let currentBlogPosts = blogPostsItems.filter((item:any) => {
        return (item.blog === currentBlogId)
    });

    // Find the page with the URL from the browser address bar.
    let currentBlogPost = currentBlogPosts.find((item:any) => {
        return (item.id === postId)
    });
    if(currentBlogPost === undefined){
        return undefined;
    }else{
        return currentBlogPost.url;
    }
};
