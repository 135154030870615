import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { saveNewPopupInState, toggleWebsitePopupEditionsDetected } from '../../../store/websites/actions';
import { getCurrentWebsite } from '../../../helpers/getCurrentWebsite';
import { SaveNewPopupInStatePayloadType } from '../../../helpers/types/popupTypes';
import { getDefaultPopupData } from '../../../data/defaultData';

interface Props {
    className?: string;
    websites?: any;
    saveNewPopupInState?: (payload: SaveNewPopupInStatePayloadType) => void;
    toggleWebsitePopupEditionsDetected?: (websitePopupEditionsExist: boolean) => void;
};

const AddPopupButton = (props: Props): JSX.Element => {
    const currentWebsite = getCurrentWebsite(props.websites.items);
    const currentWebsiteSubdomain = currentWebsite.subdomain;

    const onClick = () => {
        const newPopupObject = getDefaultPopupData();
        props.saveNewPopupInState({
            currentWebsiteSubdomain,
            newPopupObject,
        });
        props.toggleWebsitePopupEditionsDetected(true);
    }

    return (
        <div className={props.className || ""}>
            <Button size='small' icon="plus" onClick={onClick}>
                Create new popup
            </Button>
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        websites: state.websites
    }
}
const actionCreators = {
    saveNewPopupInState,
    toggleWebsitePopupEditionsDetected,
}
export default connect(mapStateToProps, actionCreators)(AddPopupButton);