export const formFields = {
    // Warning: also change placeholders ind types in Python templates!

    // When a new form fields is added we can add only these fields.
    // This list all defines default values for Label, name, dropdown options etc.
    // Also, this list defines hidden field's properties such as placeholder, HTML5 input type,
    'email': {
        type: 'email', //HTML attr
        title: 'email',
        required: true,
        humanTitle: 'Email',
        label: 'Email address',
        name: 'EMAIL',
        iconType: 'mail', // displayed inside the site builder when user picks a type
        placeholder: "your@email.com",
    },
    'full_name': {
        type: 'text',
        title: 'full_name',
        required: false,
        humanTitle: 'Full name',
        label: 'Name',
        name: 'FNAME',
        iconType: 'user',
        placeholder: "Your name",
    },
    'url': {
        type: 'url',
        title: 'url',
        required: false,
        humanTitle: 'Website (URL)',
        label: 'Website URL',
        name: 'WEBSITE',
        iconType: 'link',
        placeholder: "website.com",
    },
    'phone': {
        type: 'tel',
        title: 'phone',
        required: false,
        humanTitle: 'Phone number',
        label: 'Phone number',
        name: 'TEL',
        iconType: 'phone',
        placeholder: "Phone number",
    },
    'message': {
        type: 'textarea',
        title: 'message',
        required: false,
        humanTitle: 'Text message',
        label: 'Message',
        name: 'MESSAGE',
        iconType: 'message',
        placeholder: "Enter text here",
    },
    'dropdown': {
        type: 'select',
        title: 'dropdown',
        required: false,
        humanTitle: 'Dropdown',
        label: 'Category',
        name: 'SELECT',
        iconType: 'database',
        placeholder: "Select...",
    },
    'multiple_choice': {
        type: 'select',
        title: 'multiple_choice',
        required: false,
        humanTitle: 'Multiple choice',
        label: 'Categories',
        name: 'MULTIPLE',
        iconType: 'bars',
        placeholder: "Select...",
    },
    'other_short_text': {
        type: 'text',
        title: 'other_short_text',
        required: false,
        humanTitle: 'Other',
        label: 'Details',
        name: 'OTHER',
        iconType: 'scan',
        placeholder: "Type here",
    },
    'checkbox': { //added Aug 09 2021
      type: 'checkbox',
      title: 'checkbox',
      required: true, // because users will most possibly want their users to agree before submit the form
      humanTitle: 'Checkbox',
      label: 'I agree to the company processing of my personal data.',
      name: 'AGREEMENT',
      iconType: 'check-square',
  },
    'file': {
      type: 'file',
      title: 'file',
      required: false,
      humanTitle: 'File upload',
      label: 'Upload file',
      name: 'FILE',
      iconType: 'cloud-upload',
      placeholder: 'Drag & Drop your file or <span class="filepond--label-action"> Browse </span>',
  },
};
