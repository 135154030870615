export const defaultBackgrounds:any = {
    // Default for all: white BG color without an image:
    // Used when a component is added. Here: 'ADD_WEBSITE_PAGE_COMPONENT' in the /unicornplatform/frontend/src/store/websitePages/reducers.ts file

    // See all colors: data/componentsBackgroundColorsOptions.ts
    
    cta_form: {
        '03': {
            'color': 'accent',
            // 'imageUrl': 'https://unsplash.com/forest.jpg' //can also contain an image URL, so the component will have an image BG by default and the color overlay will act as a transparent overlay for the image.
        },
        '01': {
            'color': 'accent',
            // 'imageUrl': 'https://unsplash.com/forest.jpg' //can also contain an image URL, so the component will have an image BG by default and the color overlay will act as a transparent overlay for the image.
        },
    },
    cta_button: {
        '03': {
            'color': 'accent',
        },
        '12': {
            'color': 'black',
        },
        '16': {
            'color': 'light_gray',
        },
    },
    text: {
        '02': {
            'color': 'accent',
        },
        '01': {
            'color': 'accent',
        },
    },
    posts: {
        '02': {
            'color': 'light_gray',
        },
        '05': {
            'color': 'light_gray',
        },
    },
    links: {
      '06': {
          'color': 'light_gray',
      }
    },
    header: {
        '04': {
            'color': 'accent',
        },
        '28': {
            'color': 'black',
        },
        '29': {
            'color': 'black',
        },
        '49': {
            'color': 'mature',
        },
        '44': {
            'color': 'mature',
        },
        '03': {
            'color': 'accent',
        },
        '27': {
            'color': 'black',
        },
        '35': {
            'color': 'black',
        },
        '43': {
            'color': 'accent',
        },
    },

};
