import { PricingPeriodTabPlacement } from "../types/globalTypes";

// This function checks if the pricing object has a custom text for the tab.
// This is done for old components that don't have the custom text option.
export const checkIfPricingTabHasCustomText = (
  pricingObject: any,
  tabPlacement: PricingPeriodTabPlacement
) => {
  if (tabPlacement === "left" && "leftTabText" in pricingObject) {
    return true;
  }
  if (tabPlacement === "right" && "rightTabText" in pricingObject) {
    return true;
  }
  return false;
};
