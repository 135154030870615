import React from "react";
import { connect } from "react-redux";
import DebouncedInput from "../../../DebouncedInput";
import {
  saveWebsiteFooterInState,
  saveWebsiteNavInState,
  toggleWebsiteFooterEditionsDetected,
  toggleWebsiteNavEditionsDetected,
} from "../../../../store/websites/actions";
import _ from "lodash";

interface Props {
  currentWebsite: any;
  contentType: "nav" | "footer";

  saveWebsiteNavInState: (payload: any) => void;
  toggleWebsiteNavEditionsDetected: (payload: boolean) => void;
  saveWebsiteFooterInState: (payload: any) => void;
  toggleWebsiteFooterEditionsDetected: (payload: boolean) => void;
}

const AiDescriptionInputWebsite = (props: Props): JSX.Element => {
  const {
    currentWebsite,
    contentType,
    saveWebsiteNavInState,
    toggleWebsiteNavEditionsDetected,
    saveWebsiteFooterInState,
    toggleWebsiteFooterEditionsDetected,
  } = props;

  const handleOnChange = (value: string) => {
    if (contentType === "nav") {
      saveWebsiteNavInState({
        currentWebsiteSubdomain: currentWebsite.subdomain,
        navObject: {
          ...currentWebsite.nav,
          aiDescription: value
        },
      });
      toggleWebsiteNavEditionsDetected(true);
    }
    if (contentType === "footer") {
      saveWebsiteFooterInState({
        currentWebsiteSubdomain: currentWebsite.subdomain,
        footerObject: {
          ...currentWebsite.footer,
          aiDescription: value
        },
      });
      toggleWebsiteFooterEditionsDetected(true);
    }
  };

  const getDefaultAiDescription = () => {
    if (contentType === "nav") {
      const aiDescription = _.get(currentWebsite, "nav.aiDescription");
      if (aiDescription !== undefined) return aiDescription;
      return "Navigation bar";
    }
    if (contentType === "footer") {
      const aiDescription = _.get(currentWebsite, "footer.aiDescription");
      if (aiDescription !== undefined) return aiDescription;
      return "Footer";
    }
  };

  return (
    <DebouncedInput
      placeholder="Final call to action with multiple options"
      onChange={handleOnChange}
      defaultValue={getDefaultAiDescription()}
    />
  );
};

const mapActionsToProps = {
  saveWebsiteNavInState,
  toggleWebsiteNavEditionsDetected,
  saveWebsiteFooterInState,
  toggleWebsiteFooterEditionsDetected,
};
export default connect(null, mapActionsToProps)(AiDescriptionInputWebsite);
