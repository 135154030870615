import React from "react";
import { connect } from "react-redux";
import {
  getCurrentStoreData,
  savePartialWebsiteData,
} from "../../../store/websites/thunks";
import {
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
  editCustomStyles,
} from "../../../store/websites/actions";
import _ from "lodash";
import { StyleMenuProps } from "./components/types";
import Item from "./components/Item";
import "./StyleMenu.scss";

const Example = (): JSX.Element => {
  return (
    <div className="comps">
      <div
        className="style-blue-1 checkered-bg checkered-bg--no-hover has-opacity style-menu__preview"
        style={{
          padding: 20,
          display: "flex",
          alignItems: "center",
          maxWidth: 350,
        }}
      >
        <div
          style={{
            zIndex: 10,
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
          className="style-menu__text-preview"
        >
          <div>
            <h1 className="heading heading--accent heading-example-h1">
              H1 Title
            </h1>
            <h2 className="heading heading-example-h2 heading--accent">
              H2 Title
            </h2>
            <h3 className="heading heading-example-h3">H3 Title</h3>
            <p className="subtitle-text">Subtitle text</p>
            <p className="content-text">Content text</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ButtonStyleMenu = (props: StyleMenuProps): JSX.Element => {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Example />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <Item
          customStyleProperty="fontSizeH1"
          tooltip="Change the font size of the first title on each page. It will auto-scale for some components to maintain proportions."
          inputType="number"
          max={99}
          placeholder="48"
          presets={[
            { value: "32", description: "Small" },
            { value: "48", description: "Default" },
            { value: "64", description: "Large" },
            { value: "96", description: "Extra Large" },
          ]}
          {...props}
        >
          H1 title
        </Item>
        <Item
          customStyleProperty="fontSizeH2"
          tooltip="Change the font size of H2 titles on each page. It will auto-scale for some components to maintain proportions."
          inputType="number"
          max={99}
          placeholder="48"
          presets={[
            { value: "32", description: "Small" },
            { value: "48", description: "Default" },
            { value: "64", description: "Large" },
            { value: "80", description: "Extra Large" },
          ]}
          {...props}
        >
          H2 titles
        </Item>
        <Item
          customStyleProperty="fontSizeH3"
          tooltip="Change the font size of H3 titles on each page. It will auto-scale for some components to maintain proportions."
          inputType="number"
          max={99}
          placeholder="24"
          presets={[
            { value: "20", description: "Small" },
            { value: "24", description: "Default" },
            { value: "28", description: "Large" },
            { value: "32", description: "Extra Large" },
          ]}
          {...props}
        >
          H3 titles
        </Item>
        <Item
          customStyleProperty="fontSizeParagraphs"
          tooltip="Change the font size of the subtitle text on each page. It will auto-scale for some components to maintain proportions."
          inputType="number"
          max={99}
          placeholder="16"
          presets={[
            { value: "14", description: "Small" },
            { value: "16", description: "Default" },
            { value: "18", description: "Large" },
            { value: "22", description: "Extra Large" },
          ]}
          {...props}
        >
          Subtitles
        </Item>
        <Item
          customStyleProperty="fontSizeContent"
          tooltip="Change the font size of every text element that is not a title or subtitle. It will auto-scale for some components to maintain proportions."
          inputType="number"
          max={99}
          placeholder="16"
          presets={[
            { value: "14", description: "Small" },
            { value: "16", description: "Default" },
            { value: "18", description: "Large" },
            { value: "22", description: "Extra Large" },
          ]}
          {...props}
        >
          Content
        </Item>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    websites: state.websites,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  editCustomStyles,
  savePartialWebsiteData,
  changeWebsitesStateGlobalData,
  changeWebsiteItemGlobalData,
})(ButtonStyleMenu);
