import _ from "lodash";
import { buttonStyles } from "../../../../data/buttonStyles";
import { pictogramOptions } from "../../../../data/pictogramOptions";
import { pricingItemDetailIconOptions } from "../../../../data/pricingItemDetailIconOptions";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { updatePricingTableRowItems } from "./updatePricingTableRowItems";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  const itemSubtitle = stringifyObjectsAndNumbers(modifiedItem.SUBTITLE) || "";
  const isItemHighlighted =
    stringifyObjectsAndNumbers(modifiedItem.IS_HIGHLIGHTED) === true;
  const itemMonthlyPrice =
    stringifyObjectsAndNumbers(
      _.get(modifiedItem, ["TWO_PRICES", "FIRST_PRICE", "AMOUNT"])
    ) || "";
  const itemMonthlyPeriod =
    stringifyObjectsAndNumbers(
      _.get(modifiedItem, ["TWO_PRICES", "FIRST_PRICE", "PERIOD"])
    ) || "";
  const itemYearlyPrice =
    stringifyObjectsAndNumbers(
      _.get(modifiedItem, ["TWO_PRICES", "SECOND_PRICE", "AMOUNT"])
    ) || "";
  const itemYearlyPeriod =
    stringifyObjectsAndNumbers(
      _.get(modifiedItem, ["TWO_PRICES", "SECOND_PRICE", "PERIOD"])
    ) || "";
  const itemCurrency = stringifyObjectsAndNumbers(modifiedItem.CURRENCY) || "";
  const itemButtonText =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TEXT"])) || "";
  const itemButtonUrl =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "URL"])) || "";
  const itemButtonTarget =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TARGET"])) ||
    "_self";
  return {
    itemId,
    itemPosition,
    itemTitle,
    itemSubtitle,
    isItemHighlighted,
    itemMonthlyPrice,
    itemMonthlyPeriod,
    itemYearlyPrice,
    itemYearlyPeriod,
    itemCurrency,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
  };
};

const particleUpdatePrice = (
  originalItem,
  modifiedItem,
  itemMonthlyPrice,
  itemMonthlyPeriod,
  itemYearlyPrice,
  itemYearlyPeriod
) => {
  if (modifiedItem.TWO_PRICES === null) {
    originalItem.monthlyPrice = "";
    originalItem.monthlyPeriod = "";
    originalItem.yearlyPrice = "";
    originalItem.yearlyPeriod = "";
  }
  if (!isKeyValue(modifiedItem.TWO_PRICES)) {
    return;
  }
  if ("FIRST_PRICE" in modifiedItem.TWO_PRICES) {
    if (modifiedItem.TWO_PRICES.FIRST_PRICE === null) {
      originalItem.monthlyPrice = "";
      originalItem.monthlyPeriod = "";
    }
    if (isKeyValue(modifiedItem.TWO_PRICES.FIRST_PRICE)) {
      if ("AMOUNT" in modifiedItem.TWO_PRICES.FIRST_PRICE) {
        originalItem.monthlyPrice = itemMonthlyPrice;
      }
      if ("PERIOD" in modifiedItem.TWO_PRICES.FIRST_PRICE) {
        originalItem.monthlyPeriod = itemMonthlyPeriod;
      }
    }
  }
  if ("SECOND_PRICE" in modifiedItem.TWO_PRICES) {
    if (modifiedItem.TWO_PRICES.SECOND_PRICE === null) {
      originalItem.yearlyPrice = "";
      originalItem.yearlyPeriod = "";
    }
    if (isKeyValue(modifiedItem.TWO_PRICES.SECOND_PRICE)) {
      if ("AMOUNT" in modifiedItem.TWO_PRICES.SECOND_PRICE) {
        originalItem.yearlyPrice = itemYearlyPrice;
      }
      if ("PERIOD" in modifiedItem.TWO_PRICES.SECOND_PRICE) {
        originalItem.yearlyPeriod = itemYearlyPeriod;
      }
    }
  }
};

const particleUpdateButton = (
  originalItem,
  modifiedItem,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if (modifiedItem.BUTTON === null) {
    originalItem.button.title = "";
    originalItem.button.href = "";
    originalItem.button.isTargetBlank = false;
  }
  if (!isKeyValue(modifiedItem.BUTTON)) {
    return;
  }
  if ("TEXT" in modifiedItem.BUTTON) {
    originalItem.button.title = itemButtonText;
  }
  if ("URL" in modifiedItem.BUTTON) {
    originalItem.button.href = itemButtonUrl;
  }
  if ("TARGET" in modifiedItem.BUTTON) {
    originalItem.button.isTargetBlank = itemButtonTarget === "_blank";
  }
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemTitle,
  itemSubtitle,
  isItemHighlighted,
  itemMonthlyPrice,
  itemMonthlyPeriod,
  itemYearlyPrice,
  itemYearlyPeriod,
  itemCurrency,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.pricing.content.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.pricing.content.items, item);
      }
    });
  }
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("SUBTITLE" in modifiedItem) {
    originalItem.primaryInfo = itemSubtitle;
  }
  if ("IS_HIGHLIGHTED" in modifiedItem) {
    originalItem.isHighlighted = isItemHighlighted;
  }
  if ("TWO_PRICES" in modifiedItem) {
    particleUpdatePrice(
      originalItem,
      modifiedItem,
      itemMonthlyPrice,
      itemMonthlyPeriod,
      itemYearlyPrice,
      itemYearlyPeriod
    );
  }
  if ("CURRENCY" in modifiedItem) {
    originalItem.currencySymbol = itemCurrency;
  }
  if ("ROW_ITEMS" in modifiedItem) {
    updatePricingTableRowItems(originalItem, modifiedItem);
  }
  if ("BUTTON" in modifiedItem) {
    particleUpdateButton(
      originalItem,
      modifiedItem,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget
    );
  }
};

export const updatePricingTableColumns = (componentData, completionContent) => {
  if (!("COLUMNS" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.COLUMNS)) {
    if (completionContent.COLUMNS === null) {
      componentData.pricing.content.items = [];
    }
    return;
  }
  componentData.pricing.content.items = deleteEmptyItems(
    componentData.pricing.content.items,
    "monthlyPrice"
  );
  addDefaultValuesToItems(componentData.pricing.content.items);
  completionContent.COLUMNS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemTitle,
      itemSubtitle,
      isItemHighlighted,
      itemMonthlyPrice,
      itemMonthlyPeriod,
      itemYearlyPrice,
      itemYearlyPeriod,
      itemCurrency,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget,
    } = particleGetModifiedValues(modifiedItem);
    if (itemPosition === null) {
      componentData.pricing.content.items = deleteItem(
        componentData.pricing.content.items,
        itemId
      );
      return;
    }
    const isItemNew = getIsItemNew(componentData.pricing.content.items, itemId);
    if (isItemNew) {
      const newItem = generator.createPricingContentObject();
      newItem.title = "";
      newItem.primaryInfo = "";
      newItem.button.title = "";
      newItem.monthlyPeriod = "";
      newItem.button.settings.appearance.styleType =
        buttonStyles.regular.typeTitle;
      newItem.button.settings.appearance.color =
        buttonStyles.regular.blackOutline;
      const newItemIcon = generator.createPictogramContentObject();
      newItemIcon.type = pictogramOptions.emoji.title;
      newItemIcon.emojiSrc = "2b50.svg";
      newItem.pictogram = newItemIcon;
      newItem.comparisionTableDetailsSet.forEach((detail) => {
        detail.icon = pricingItemDetailIconOptions.none;
      });
      addNewItem(
        componentData.pricing.content.items,
        newItem,
        itemId,
        itemPosition
      );
    }
    const originalItem = getOriginalItem(
      componentData.pricing.content.items,
      itemId
    );
    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemTitle,
      itemSubtitle,
      isItemHighlighted,
      itemMonthlyPrice,
      itemMonthlyPeriod,
      itemYearlyPrice,
      itemYearlyPeriod,
      itemCurrency,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget
    );
  });
  sortItemsByPosition(componentData.pricing.content.items);
};
