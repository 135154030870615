import React from "react";
import PageComponent from "../../../pageComponents/PageComponent";
import { connect } from "react-redux";
import { getCurrentWebsite } from "../../../helpers/getCurrentWebsite";
import {
  toggleWaitingForCommonWebsiteUpdate,
  removeWebsiteFormIntegration,
  toggleWaitingForConnectPaymentIntegration,
  toggleWaitingForChangePaymentIntegrationProductsArray,
  toggleWaitingForEditPaymentIntegrationProductDetails,
  toggleWaitingForConfigurePaymentIntegration,
  saveWebsiteNewFormIntegrationInState,
  toggleWaitingForResponseOnWebsiteFormIntegrationAdd,
  changeStripeSecretKey,
  changeStripePublicKey,
  changeStripeCheckoutProductsArray,
} from "../../../store/websites/actions";
import {
  changeWebsitePageComponentVisibility,
  changeWebsitePageComponentBackgroundColor,
  changeWebsitePageComponentBackgroundImage,
  changeWebsitePageComponentBackgroundImageOverlayOpacity,
  changeWebsitePageComponentPaddingTop,
  changeWebsitePageComponentPaddingBottom,
  editWebsitePageSchemeToggleWaitingForServer,
  toggleNewWebsitePageEditionsDetected,
  moveWebsitePageComponent,
  deleteWebsitePageComponent,
  changeWebsitePageComponentContent,
  toggleIsPageNavHidden,
  toggleIsPageFooterHidden,
} from "../../../store/websitePages/actions";
import { getCurrentWebsitePageUrl } from "../../../helpers/getCurrentWebsitePageUrl";
import { getCurrentWebsitePage } from "../../../helpers/getCurrentWebsitePage";

type Action = (payload: any) => void;

interface Props {
  websites?: any;
  formIntegrations?: any;
  auth?: any;
  user?: any;
  history?: any;
  websitesPages?: any;

  currentPageIndex: any;
  saveImageInfoInDB: any;
  removeImageFromDB: any;
  saveWebsiteDataInStore: any;

  toggleWaitingForCommonWebsiteUpdate?: Action;
  removeWebsiteFormIntegration?: Action;
  changeWebsitePageComponentVisibility?: Action;
  changeWebsitePageComponentBackgroundColor?: Action;
  changeWebsitePageComponentBackgroundImage?: Action;
  changeWebsitePageComponentBackgroundImageOverlayOpacity?: Action;
  changeWebsitePageComponentPaddingTop?: Action;
  changeWebsitePageComponentPaddingBottom?: Action;
  editWebsitePageSchemeToggleWaitingForServer?: Action;
  toggleNewWebsitePageEditionsDetected?: Action;
  moveWebsitePageComponent?: Action;
  deleteWebsitePageComponent?: Action;
  changeWebsitePageComponentContent?: Action;
  toggleWaitingForConnectPaymentIntegration?: Action;
  toggleWaitingForChangePaymentIntegrationProductsArray?: Action;
  toggleWaitingForEditPaymentIntegrationProductDetails?: Action;
  toggleWaitingForConfigurePaymentIntegration?: Action;
  saveWebsiteNewFormIntegrationInState?: Action;
  toggleWaitingForResponseOnWebsiteFormIntegrationAdd?: Action;
  changeStripeSecretKey?: Action;
  changeStripePublicKey?: Action;
  changeStripeCheckoutProductsArray?: Action;
  toggleIsPageNavHidden?: Action;
  toggleIsPageFooterHidden?: Action;
}

const PopupComponents = (props: Props): JSX.Element => {
  const currentWebsite = getCurrentWebsite(props.websites.items);
  const currentWebsitePageUrl = getCurrentWebsitePageUrl(
    currentWebsite,
    props.websitesPages.items
  );
  const currentWebsitePage = getCurrentWebsitePage(
    currentWebsitePageUrl,
    props.websitesPages.items,
    currentWebsite
  );
  const popupItems = currentWebsite.popups.items;

  if (!popupItems) return null;
  if (popupItems.length === 0) return null;

  const popupArray = popupItems.map((popupItem, popupItemIndex) => {
    return (
      <li className="editor__component" key={popupItemIndex}>
        <PageComponent
          toggleWaitingForCommonWebsiteUpdate={
            props.toggleWaitingForCommonWebsiteUpdate
          }
          removeWebsiteFormIntegration={props.removeWebsiteFormIntegration}
          openAddNewComponent={() => null}
          componentCategory="popups"
          componentItem={popupItem}
          componentKey={popupItemIndex}
          componentsArray={popupItems}
          websites={props.websites}
          formIntegrations={props.formIntegrations}
          auth={props.auth}
          user={props.user}
          history={props.history}
          markFirstComponentWasEditedAsTrue={() => null}
          changeWebsitePageComponentVisibility={
            props.changeWebsitePageComponentVisibility
          }
          currentPageIndex={props.currentPageIndex}
          currentWebsite={currentWebsite}
          changeWebsitePageComponentBackgroundColor={
            props.changeWebsitePageComponentBackgroundColor
          }
          changeWebsitePageComponentBackgroundImage={
            props.changeWebsitePageComponentBackgroundImage
          }
          changeWebsitePageComponentBackgroundImageOverlayOpacity={
            props.changeWebsitePageComponentBackgroundImageOverlayOpacity
          }
          changeWebsitePageComponentPaddingTop={
            props.changeWebsitePageComponentPaddingTop
          }
          changeWebsitePageComponentPaddingBottom={
            props.changeWebsitePageComponentPaddingBottom
          }
          editWebsitePageSchemeToggleWaitingForServer={
            props.editWebsitePageSchemeToggleWaitingForServer
          }
          toggleNewWebsitePageEditionsDetected={
            props.toggleNewWebsitePageEditionsDetected
          }
          moveWebsitePageComponent={props.moveWebsitePageComponent}
          deleteWebsitePageComponent={props.deleteWebsitePageComponent}
          changeWebsitePageComponentContent={
            props.changeWebsitePageComponentContent
          }
          toggleWaitingForConnectPaymentIntegration={
            props.toggleWaitingForConnectPaymentIntegration
          }
          toggleWaitingForChangePaymentIntegrationProductsArray={
            props.toggleWaitingForChangePaymentIntegrationProductsArray
          }
          toggleWaitingForEditPaymentIntegrationProductDetails={
            props.toggleWaitingForEditPaymentIntegrationProductDetails
          }
          toggleWaitingForConfigurePaymentIntegration={
            props.toggleWaitingForConfigurePaymentIntegration
          }
          saveWebsiteNewFormIntegrationInState={
            props.saveWebsiteNewFormIntegrationInState
          }
          toggleWaitingForResponseOnWebsiteFormIntegrationAdd={
            props.toggleWaitingForResponseOnWebsiteFormIntegrationAdd
          }
          saveImageInfoInDB={props.saveImageInfoInDB}
          removeImageFromDB={props.removeImageFromDB}
          changeStripeSecretKey={props.changeStripeSecretKey}
          changeStripePublicKey={props.changeStripePublicKey}
          changeStripeCheckoutProductsArray={
            props.changeStripeCheckoutProductsArray
          }
          saveWebsiteDataInStore={props.saveWebsiteDataInStore}
          toggleIsPageNavHidden={props.toggleIsPageNavHidden}
          toggleIsPageFooterHidden={props.toggleIsPageFooterHidden}
          currentWebsitePage={currentWebsitePage}
        />
      </li>
    );
  });

  return popupArray;
};

const mapStateToProps = (state) => {
  const {
    websites,
    formIntegrations,
    auth,
    history,
    user,
    websitesPages,
  } = state;
  return {
    websites,
    formIntegrations,
    auth,
    history,
    user,
    websitesPages,
  };
};
const actionCreators = {
  toggleWaitingForCommonWebsiteUpdate,
  removeWebsiteFormIntegration,
  changeWebsitePageComponentVisibility,
  changeWebsitePageComponentBackgroundColor,
  changeWebsitePageComponentBackgroundImage,
  changeWebsitePageComponentBackgroundImageOverlayOpacity,
  changeWebsitePageComponentPaddingTop,
  changeWebsitePageComponentPaddingBottom,
  editWebsitePageSchemeToggleWaitingForServer,
  toggleNewWebsitePageEditionsDetected,
  moveWebsitePageComponent,
  deleteWebsitePageComponent,
  changeWebsitePageComponentContent,
  toggleWaitingForConnectPaymentIntegration,
  toggleWaitingForChangePaymentIntegrationProductsArray,
  toggleWaitingForEditPaymentIntegrationProductDetails,
  toggleWaitingForConfigurePaymentIntegration,
  saveWebsiteNewFormIntegrationInState,
  toggleWaitingForResponseOnWebsiteFormIntegrationAdd,
  changeStripeSecretKey,
  changeStripePublicKey,
  changeStripeCheckoutProductsArray,
  toggleIsPageNavHidden,
  toggleIsPageFooterHidden,
};

export default connect(mapStateToProps, actionCreators)(PopupComponents);
