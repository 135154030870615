import {pageComponentThumb, publicUrl} from "./urls";

export const footerSchemeOptions = [
    // {/*Footer index: 1 - minimalistic footer*/}
    {
        schemeIndex: 1,
        hasCopyrightMarkup: true,
        hasSocialLinks: true,
        socialLinks: {
            hasTitle: false,
        },
        hasCta: false,
        hasGroupsOfLinks: false, //columns
        hasLogo: false,
        hasPosts: false,
        hasTestimonials: false,
        hasTwitterFeed: false,
        hasContacts: false,
        previewUrl: publicUrl + pageComponentThumb.prefix + 'footer' + '/' + '02' + pageComponentThumb.postfix
    },

    // {/*Footer index: 2 - big footer with many links*/}
    {
        schemeIndex: 2,
        hasCopyrightMarkup: true,
        hasSocialLinks: true,
        socialLinks: {
            hasTitle: true,
        },
        hasCta: true,
        cta: {

        },
        hasGroupsOfLinks: true, //columns
        list: {
            hasGroups: true,
            maxGroups: 10,
            groupTitleHasTitle: true,
            groupTitleHasPictogram: false,
        },
        hasLogo: true,
        hasPosts: false,
        hasTestimonials: false,
        hasTwitterFeed: false,
        hasContacts: true,
        contacts: {
            hasMap: false,
            hasContactsItems: true,
            contactsItemHasTitle: true,
            mapHasTitle: false,
            isMapEmbedded: false, //if false map is a clickable URL
        },
        previewUrl: publicUrl + pageComponentThumb.prefix + 'footer' + '/' + '04' + pageComponentThumb.postfix
    },
];
