import React, { Component } from "react";
import "./Plans.css";
import { Icon, Progress, Radio, Tooltip, Alert, Divider } from "antd";
import Plan from "./plan_item/Plan";
import PaymentLogo from "../../../../img/other/payment.svg";
import { emojiPath, publicUrl } from "../../../../data/urls";
import {
  ltdPlanTitle,
  appsumoPlanTitle,
  freePlanTitle,
} from "../../../../data/constants";
import fireAnalyticsEvent from "../../../../helpers/editor/fireAnalyticsEvent";
import { openChatWidget } from "../../../../helpers/editor/openChatWidget";
import { getCurrentPlanPeriod } from "../../../../helpers/plans/getCurrentPlanPeriod";
import { pricingTiersFeatures } from "../../../../data/pricingTiersFeatures";
import PlansFaq from "./PlansFaq";
import { CrispEvents } from "../../../../enums/AnalyticsEventsEnums";
import { setUserEventsAsync } from "../../../../helpers/user/setUserEventsAsync";
import { UserEvents } from "../../../../enums/UserEvents";
import { saveUserEventsInStore } from "../../../../store/user/actions";
import { connect } from "react-redux";
import { SaveUserEventsInStore } from "../../../../store/user/types";
import { getCurrentPlan } from "../../../../helpers/plans/getCurrentPlan";
import { PaddleSubscriptionStatus } from "../../../../helpers/types/globalTypes";

interface Props {
  plans: any;
  meta: any;
  user: any;
  auth: any;
  payment: any;
  pageComponents: any;
  toggleCancellingPro: any;
  fetchCurrentUserInfo: any;
  toggleUserPro: any;
  toggleProcessingSystemReady: any;
  toggleSwitchingProPlanInProgress: any;
  toggleUpgradingFromFreeToProInProgress: any;

  saveUserEventsInStore: SaveUserEventsInStore;
}

class Plans extends Component<Props> {
  state = {
    activePricingPeriod: "monthly" as "monthly" | "yearly",
    isChatLoading: false,
  };

  openChat() {
    this.setState({
      isChatLoading: true,
    });
    (window as any).$crisp.push([
      "on",
      "chat:opened",
      () => {
        this.setState({
          isChatLoading: false,
        });
      },
    ]);

    openChatWidget();
  }

  componentDidMount() {
    let currentPlanPeriod = getCurrentPlanPeriod(
      this.props.user,
      this.props.plans
    );
    this.setState({
      activePricingPeriod: currentPlanPeriod || "yearly",
    });

    if (this.props.payment.isProcessingSystemReady === false) {
      this.initPaddle();
    }

    fireAnalyticsEvent.fireCrisp(CrispEvents.openPlansScreen);

    const { user, plans, auth } = this.props;
    const currentPlan = getCurrentPlan(user, plans);
    const isFree = currentPlan.title === freePlanTitle;
    if (isFree) {
      setUserEventsAsync(
        {
          [UserEvents.VisitedPlansScreenOnFreeAccount]: new Date(),
        },
        auth.accessToken,
        user.data.events
      ).then((response) => {
        if (response) {
          this.props.saveUserEventsInStore(response.data);
        }
      });
    }
  }
  initPaddle = () => {
    //Init the Paddle after it's loaded from their server. There is no callback or an event so we use intervals to wait until it's loaded. We also must load their script from their servers to get the freshest code.
    let interval = setInterval(() => {
      if (typeof (window as any).Paddle !== "undefined") {
        //Credentials: https://vendors.paddle.com/account
        this.props.toggleProcessingSystemReady(true);
        clearInterval(interval);
      }
    }, 500);
  };

  displayPromoBanner() {
    let today = new Date();
    let todayDate = today.getDate();

    let daysLeft = 20 - todayDate;
    let daysWord = "days";

    if (daysLeft === 1) {
      daysWord = "day";
    }

    let percent = 100 - ((22 - todayDate) / 22) * 100;

    return (
      <div className="plans__banner_box">
        <img
          src={publicUrl + emojiPath.prefix + "1f60f.svg"}
          alt="smirking emoji"
          className="plans__banner_emoji"
        />
        <div className="plans__banner_text">
          <span className="plans__banner_title">
            Product Hunt special: <b>33% OFF</b>
          </span>
          <p>Pay 33% less forever.</p>
          <p>The discount affects the first and all the future payments.</p>
          <p>The offer ends on the 4th of August.</p>
        </div>

        <div className="plans__banner_bar">
          <Progress
            type="circle"
            percent={100}
            strokeColor="#ffcc4d"
            format={(percent) => `Only today`}
          />
          {/*<Progress type="circle" percent={percent} strokeColor="#ffcc4d" format={percent => `${daysLeft} ${daysWord} left`} />*/}
        </div>
      </div>
    );
  }
  displayPlans() {
    let plansArray = this.props.plans.items;
    plansArray = plansArray.sort(
      (prev: any, next: any) => prev.price - next.price
    );

    /*  plansArray - array of plans from the database
    we need a modified version of it to:
    - store paddle_plan_id for both monthly and yearly option
    - store price divied by 12 for yearly option
    - store per website price
    */

    let modifiedPlansObject = {
      lurker: {
        monthly_paddle_plan_id: 0,
        monthly_plan_id: 0,
        monthly_price_per_month: 0,
        monthly_price_per_website_per_month: 0,
        yearly_paddle_plan_id: 0,
        yearly_plan_id: 0,
        yearly_price_per_month: 0,
        yearly_price_per_website_per_month: 0,
        description: "Try the builder with all the core features",
      },
      maker: {},
      startup: {},
      business: {},
      freelancer: {},
      agency: {},
      major: {},
    };

    for (let index = 0; index < plansArray.length; index++) {
      const element = plansArray[index];
      // create an object of all regular plans (not special such as ltd and appsumo)
      if (
        element["title"] !== ltdPlanTitle &&
        element["title"] !== appsumoPlanTitle &&
        element["title"] !== freePlanTitle
      ) {
        modifiedPlansObject[element["title"]]["description"] =
          element["description"];

        if (element["period"] === "monthly") {
          modifiedPlansObject[element["title"]]["monthly_paddle_plan_id"] =
            element["paddle_plan_id"];
          modifiedPlansObject[element["title"]]["monthly_plan_id"] =
            element["id"];
          modifiedPlansObject[element["title"]]["monthly_price_per_month"] =
            element["price"];
          modifiedPlansObject[element["title"]][
            "monthly_price_per_website_per_month"
          ] =
            Math.round((element["price"] / element["websites_limit"]) * 100) /
            100;
        } else if (element["period"] === "yearly") {
          modifiedPlansObject[element["title"]]["yearly_paddle_plan_id"] =
            element["paddle_plan_id"];
          modifiedPlansObject[element["title"]]["yearly_plan_id"] =
            element["id"];
          modifiedPlansObject[element["title"]]["yearly_price_per_month"] =
            element["price"] / 12;
          modifiedPlansObject[element["title"]][
            "yearly_price_per_website_per_month"
          ] =
            Math.round(
              (element["price"] / 12 / element["websites_limit"]) * 100
            ) / 100;
        }
      }
    }

    let plansJSX = [];
    for (const prop in modifiedPlansObject) {
      let item = modifiedPlansObject[prop];
      let paddle_plan_id = undefined;
      let monthly_price = undefined;
      let plan_id = undefined;
      let price_per_website_per_month = undefined;

      if (this.state.activePricingPeriod === "monthly") {
        paddle_plan_id = item["monthly_paddle_plan_id"];
        monthly_price = item["monthly_price_per_month"];
        plan_id = item["monthly_plan_id"];
        price_per_website_per_month =
          item["monthly_price_per_website_per_month"];
      } else if (this.state.activePricingPeriod === "yearly") {
        paddle_plan_id = item["yearly_paddle_plan_id"];
        monthly_price = item["yearly_price_per_month"];
        plan_id = item["yearly_plan_id"];
        price_per_website_per_month =
          item["yearly_price_per_website_per_month"];
      }

      const { user } = this.props;
      const paddleSubscriptionStatus: PaddleSubscriptionStatus =
        user.data.paddle_subscription_status;
      const userHasSubscription =
        paddleSubscriptionStatus === "active" ||
        paddleSubscriptionStatus === "past_due";

      const is_active =
        plan_id === user.data.current_plan && userHasSubscription;

      plansJSX.push(
        <Plan
          key={"plan-" + plan_id}
          meta={this.props.meta}
          activePricingPeriod={this.state.activePricingPeriod}
          paddle_plan_id={paddle_plan_id}
          plan_id={plan_id}
          title={prop}
          description={item.description}
          monthly_price={monthly_price}
          is_active={is_active}
          isFreePlan={prop === freePlanTitle}
          price_per_website_per_month={price_per_website_per_month}
          plans={this.props.plans}
          user={this.props.user}
          payment={this.props.payment}
          userHasSubscription={userHasSubscription}
        />
      );
    }

    return plansJSX;
  }

  displayPeriodPicker = () => {
    const RadioButton = Radio.Button;
    const RadioGroup = Radio.Group;

    return (
      <div className="plans__switch">
        <span className="plans__switch_info">Billing type:</span>
        <RadioGroup
          onChange={(e: any) => {
            this.setState({ activePricingPeriod: e.target.value });
          }}
          // defaultValue="monthly"
          value={this.state.activePricingPeriod}
        >
          <Tooltip
            placement="topRight"
            title={
              <span>
                Pay less by upgrading to a <b>yearly</b> plan.
              </span>
            }
          >
            <RadioButton value="yearly">
              <Icon type="gift" theme="twoTone" /> Yearly
            </RadioButton>
          </Tooltip>
          <RadioButton value="monthly">Monthly</RadioButton>
        </RadioGroup>
        {/*<Tag className="plans__bonus" color="purple"><Icon type="gift" /> +2 free months</Tag>*/}
      </div>
    );
  };

  displayFeatures = () => {
    let featuresJSX = [];
    for (let i = 0; i < pricingTiersFeatures.length; i++) {
      let singleFeatureJSX = (
        <div className="plans__feature_item" key={i}>
          {pricingTiersFeatures[i]["title"]}{" "}
          <Tooltip
            key={i}
            placement="topLeft"
            title={pricingTiersFeatures[i]["info"]}
          >
            <Icon
              className="plans__feature_item_icon"
              style={{ color: "rgba(0, 0, 0, 0.45)" }}
              type="info-circle"
            />
          </Tooltip>
        </div>
      );
      featuresJSX.push(singleFeatureJSX);
    }

    return <div className="plans__features">{featuresJSX}</div>;
  };

  displayBottomInfo = () => {
    return <PlansFaq />;

    // if (this.props.plans.dataFetched && this.props.user.dataFetched) {
    //   if (this.props.user.isPro) {
    //     return (
    //       <div className="plans__corporate">
    //         <div className="plans__corporate__box">
    //           <div className="plans__corporate__text">
    //             <b>Need even more websites?</b> Contact us to get a custom high-volume plan.
    //           </div>
    //           <span className="plans__corporate_link">
    //             <Button
    //               onClick={() => { this.openChat() }}
    //               htmlType="button">Open chat <Icon type={this.state.isChatLoading ? 'loading' : 'message'} /></Button>
    //           </span>
    //         </div>
    //       </div>
    //     );
    //   } else {
    //     return (
    //       <div className="plans__corporate">
    //         <div className="plans__corporate__box">
    //           <div className="plans__corporate__text">
    //             <b>60 days money-back guarantee <Icon type="heart" theme="twoTone" /></b> <br /><br /> Your purchase is backed by our 60 days money-back guarantee. If you are not satisfied with your purchase of any plan, we will gladly issue a full refund. <br /> No strings attached. <br /><br /> <span style={{ opacity: 0.7 }}>Refunds requested so far: 2</span>
    //           </div>
    //           {/*<a href={urls.askLargerVolumeForm} target="_blank" className="plans__corporate_link">*/}
    //           {/*<Button htmlType="button">Contact us</Button>*/}
    //           {/*</a>*/}
    //         </div>
    //       </div>
    //     );
    //   }
    // } else {
    //   return (null);
    // }
  };

  render() {
    return (
      <div className="plans">
        <div className="plans__top">{this.displayPeriodPicker()}</div>

        {this.props.user.isLtd && (
          <Alert
            style={{ marginTop: 20 }}
            message="LTD plan 👑"
            type="success"
            showIcon
            description="You are on the exclusive life-time unlimited plan. Thanks for your support!"
          />
        )}
        {this.props.user.isAppsumo && (
          <Alert
            style={{ marginTop: 20 }}
            message="AppSumo plan 👑"
            type="success"
            showIcon
            description="You are on the life-time exclusive AppSumo plan. Websites limit: 3"
          />
        )}

        <div className="plans__box">
          <div className="plans__wrapper">
            {this.displayFeatures()}
            {this.displayPlans()}
            {/*<Script*/}
            {/*url="https://cdn.paddle.com/paddle/paddle.js"*/}
            {/*onError={this.initPaddle.bind(this)}*/}
            {/*onLoad={this.initPaddle.bind(this)}*/}
            {/*/>*/}
          </div>
        </div>
        <div className="plans__payment">
          <img
            src={PaymentLogo}
            alt="Payment options"
            className="plans__payment_image"
          />
        </div>

        {this.displayBottomInfo()}
      </div>
    );
  }
}

const mapActionsToProps = {
  saveUserEventsInStore,
};
export default connect(null, mapActionsToProps)(Plans);
