import React, {Component} from "react";
import {Alert, Button, Icon, Modal, Tooltip} from "antd";
import {projectTitle} from "../../../../data/constants";
import './DealsModal.css';
import { formatImageUrl } from "../../../../helpers/strings/formatImageUrl";

interface Props {
    hideDealsModal: any,
    isDealsModalVisible: boolean,
    meta: any,
    isCouponVisible: boolean, // is the user has a plan which supports the deals, we allow to copy the coupon code.
}

class DealsModal extends Component<Props> {
    state = {visible: false};

    componentDidMount(): void {
    }

    render() {
        let dealsJSX = [];

        // meta always has only 1 item with all the info
        if (this.props.meta && this.props.meta['items'] && this.props.meta['items'].length === 1 && this.props.meta['items'][0]['deals']) {

            for (let i = 0; i < this.props.meta['items'][0]['deals']['items'].length; i++) {
                let dealItem = this.props.meta['items'][0]['deals']['items'][i];
                let dealItemJSX = (
                    <div className="deals_modal__item" key={i}>
                        <div className="deals_modal__item_top">
                            <img src={formatImageUrl(dealItem['logoSrc'])} alt="" className="deals_modal__item_logo"/>
                        </div>
                        <div className="deals_modal__item_body" style={{backgroundColor: dealItem['bgColor']}}>
                            <div className={"deals_modal__item_info " + (dealItem['isWhiteText'] ? ' text-white' : '')}>
                                <h3 className="deals_modal__item_title">{dealItem['title']}</h3>
                                <p className="deals_modal__item_description">{dealItem['description']}</p>
                            </div>
                            <div className="deals_modal__item_action">
                                <div className={'deals_modal__item_code_box ' + (dealItem['isWhiteText'] ? ' text-white' : '')}>
                                    <b>Promo code</b>: {this.props.isCouponVisible ? <code className={"deals_modal__item_code"}>{dealItem['coupon']}</code> :
                                    <Tooltip title={"Upgrade to a higher plan to unlock the deal."}><span className={"deals_modal__hidden"}>XXXXXXXX</span> <Icon type="lock"/></Tooltip>}
                                </div>
                                <Button className={"deals_modal__item_project_url"} type={"primary"} target="_blank" href={dealItem['url']}>View project <Icon
                                    type="link"/></Button>

                            </div>
                        </div>
                    </div>
                )
                dealsJSX.push(dealItemJSX);
            }

            return (
                <Modal
                    width={1024}
                    title="Deals from our friends 🤑"
                    visible={this.props.isDealsModalVisible}
                    onCancel={this.props.hideDealsModal}
                    footer={(<Button type={"default"} onClick={() => this.props.hideDealsModal()}>Close</Button>)}
                >
                    <Alert
                        style={{marginBottom: 40}}
                        message="About the deals"
                        type="info"
                        showIcon
                        description={<div>These companies are friends of {projectTitle}. They gladly offered
                            exclusive <b>real</b> discounts for our clients. <br/>We hope this list will help you to find a
                            useful tool for your project save some $$$. </div>}/>

                    <div className="deals_modal__items_box">
                        {dealsJSX}
                    </div>
                </Modal>
            )
        } else {
            return (null);
        }
    }
}

export default DealsModal;
