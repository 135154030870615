import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {pictogramOptions} from "../../data/pictogramOptions";
import { displayMarkupText } from "../../helpers/content_displayers/displayMarkupText";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteCTA from "../component_molecules/WebsiteCTA";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    displayButtons: any,
    isDarkBg: boolean,
}

class Text02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;
            this.props.saveContentInStore(this.generator.setUpText(
                {
                    priority: 300,
                    items: [
                        {"markup": isDefaultEmpty ? "" : "Habitio de superbus finis, vitare exemplar! Mons flavum equiso est. Sunt burguses perdere bassus, festus acipenseres. Brevis eleatess ducunt ad solitudo. Elevatus de raptus impositio, locus eleates! Ubi est germanus luna? Bulla, habena, et spatii. Rumor de bi-color extum, attrahendam diatria! A falsis, deus ferox finis. Emeritis, velox nuclear vexatum iaceres recte visum de peritus, teres diatria. Buxum noster lura est."}
                    ]
                }
            ));

            this.props.saveContentInStore(this.generator.setUpTitle({
                titleText: "The most important things to know",
                priority: 400,
                isDefaultEmpty
            }));
            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: "Learn more",
                        buttonHref: '/product',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        buttonColor: buttonStyles.pill.green,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'New'
                    }),

                    // ghost object
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.ghost.typeTitle,
                        buttonColor: buttonStyles.ghost.accentOutline,
                        buttonIsTargetBlank: false,
                        pillPrefix: 'View'
                    })
                ],
                priority: 200,
            }));
            this.props.saveContentInStore(this.generator.setUpPictograms({
                priority: 500,
                items: [
                    {
                        pictogram: this.generator.createPictogramContentObject({
                                type: pictogramOptions.emoji.title,
                                emojiSrc: '1f64c.svg'
                            })
                    }
                ]
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts

        // In this Text component there is only one text element.
        let text = displayMarkupText(this.props.componentItem.componentData.text.content.items[0].markup);

        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);

        let pictogram = this.props.componentItem.componentData.pictograms.content.items[0].pictogram;

        return (
            <section>
                <div className="text--02">
                    <div className="container container--mid">

                        <div className="text--02__box">
                            <div className="text--02__content_box text--02__content_box--bottom"/>
                            <div className="text--02__content_box text--02__content_box--top">

                                <div className="text--02__img">
                                    <Pictogram 
                                      pictogramObject={pictogram}
                                      className=''
                                    />
                                </div>

                                {title.length > 0 && <h3 className={"title-text"} dangerouslySetInnerHTML={{__html: title}}/>}

                                <div className="content_box">
                                    {text.length > 0 && <p className={"content-text "} dangerouslySetInnerHTML={{__html: text}}/>}
                                </div>

                                <div className="text--02__link_box">
                                  <WebsiteCTA componentItem={this.props.componentItem} />
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default Text02;
