import {removeLineBreaks} from "./removeLineBreaks";

export const getExcerptFromBlogPostJson = (bodyJson: any):string => {
    //returns excerpt - first 160 characters of a blog post. Usually less than 160 because we cut double and triple spaces.

    //bodyJson example:
    /*
    *"[
    * {"type":"paragraph","children":[{"text":"sskss"}]},{"url":"https://ucarecdn.com/66c16acb-c385-4a65-940f-31ad4e410c82/cus.jpg", "type":"image","children":[{"text":""}],"fileName":"cus.jpg","imageWidth":1257,"imageHeight":610},
    * {"type":"paragraph","children":[{"text":""}]},{"url":"https://ucarecdn.com/0eacea12-8135-4228-9fd9-20736e962551/-----many-y sd dsa. ._head.png", "type":"image","children":[{"text":""}],"fileName":"-----many-y sd dsa. ._head.png"},
    * {"type":"paragraph","children":[{"text":""}]},
    * {"type":"paragraph","children":[{"text":"yay "}]},{"src":"https://www.youtube.com/embed/BMYZiz4FdjQ", "type":"embed","width":"560","height":"315","children":[{"text":""}]},
    * {"type":"paragraph","children":[{"text":""}]},{"url":"https://ucarecdn.com/a07713c8-6fe4-4fb3-bf0b-343fc2db4162/anon.png", "type":"image","children":[{"text":""}],"fileName":"anon.png"},
    * {"type":"paragraph","children":[{"text":"Some "},{"bold":true,"text":"pretty text "},{"text":"for exceprt"}]}
    * ]"
    * */

    let excerpt = ''; //default value is ''

    //iterate through the array of all post json items
    for (let i = 0; i < bodyJson.length; i++) {
        if(
            (bodyJson[i].type === 'paragraph') //we need paragraphs only
        ){
            //iterate through the array of paragraph text nodes
            for (let j = 0; j < bodyJson[i].children.length; j++) {
                let currentNode = bodyJson[i].children[j];
                if(currentNode && currentNode.text !== undefined && currentNode.text !== ''){ //if has text node and it is not empty
                    if(excerpt.length < 160){ // we have a limit of 160 chars. Let's pick the first 159 to make sure it fits.
                        let appendedText = currentNode.text.slice(0, 160 - excerpt.length); //pick as many characters as we have left until we reach the limit of 160.
                        appendedText = removeLineBreaks(appendedText, ' '); //remove line breaks
                        if(j === 0){ //if it is the first node in a row, it needs a space at the beginning
                            excerpt += ' ' + appendedText;
                        }else{
                            excerpt += appendedText;
                        }
                    }else{
                        break;
                    }
                }
            }
        }
    }

    if(excerpt[0] === ' '){
        // Previously we appended a space at the beginning of a new concatenated node. The first node also got its space which we need to remove now.
        excerpt = excerpt.substring(1);
    }

    // Sometimes we get multiple spaces in a row. Remove double and triple spaces in a row and replace with a single space.
    excerpt = excerpt.replace('   ', ' ').replace('  ', ' ');
    return excerpt
};