import React from "react";
import { Button } from "antd";
import { InputProps } from "./interfaces";
import { draftJsEditorTitle } from "../../../data/constants";
import DebouncedInput from "../../DebouncedInput";
import DebouncedTextarea from "../../DebouncedTextarea";
import MyRichTextEditor from "../../MyRichTextEditor";
import { useCurrentInputData } from "./useCurrentInputData";

const Faq = (props: InputProps): JSX.Element => {
  const {
    content,
    currentInputData,
    saveContentInStore,
    generator,
    contentType,
    toggleNewEditionsDetected,
    displayMoveUpDownButtons,
    textEditorType,
    revertToPlainHTML,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  let lastItem = content.items[content.items.length - 1];
  let maxItemsReached = lastItem.title !== "";
  let faqItemsCount = maxItemsReached
    ? content.items.length
    : content.items.length - 1; //if max not reached the last item is ghost

  let faqInputs = content.items.map((item: any, key: number) => {
    let onChangeFaqTitleHandler = (value: any) => {
      saveContentInStore(
        generator.changeFaqTitle(
          { [contentType]: currentInputDataRef.current },
          key,
          value
        )
      );
      toggleNewEditionsDetected(true);
    };
    let onChangeFaqTextHandler = (value: any) => {
      saveContentInStore(
        generator.changeFaqText({ [contentType]: currentInputDataRef.current }, key, value)
      );
      toggleNewEditionsDetected(true);
    };

    let returnFaqTextField = () => {
      if (textEditorType && textEditorType === draftJsEditorTitle) {
        return (
          <div className="content-input__input">
            <MyRichTextEditor
              revertToPlainHTML={revertToPlainHTML}
              placeholder="Enter answer"
              defaultValue={item.text}
              onChange={onChangeFaqTextHandler}
            />
          </div>
        );
      } else {
        return (
          <DebouncedTextarea
            className="content-input__input"
            onChange={onChangeFaqTextHandler}
            defaultValue={item.text}
            autosize={{ minRows: 1, maxRows: 6 }}
            placeholder="Enter answer"
          />
        );
      }
    };

    return (
      <div
        className={
          "content-input__cluster " +
          (maxItemsReached === false
            ? " content-input__cluster--with-ghost "
            : " ")
        }
        data-add-new="Add new F.A.Q. item ⤵"
        key={"faq-" + key}
      >
        <Button
          onClick={() => {
            toggleNewEditionsDetected(true);
            saveContentInStore(
              generator.deleteFaq({ [contentType]: currentInputDataRef.current }, key)
            );
          }}
          htmlType="button"
          className="content-input__control_cluster"
          shape="circle"
          title="Delete this F.A.Q. item"
          icon="cross"
          size="small"
        />

        {displayMoveUpDownButtons(
          key,
          faqItemsCount,
          () => {
            saveContentInStore(
              generator.moveFaqItemUp({ [contentType]: currentInputDataRef.current }, key)
            );
          },
          () => {
            saveContentInStore(
              generator.moveFaqItemDown(
                { [contentType]: currentInputDataRef.current },
                key
              )
            );
          }
        )}

        <div className="content-input__group">
          <DebouncedInput
            className="content-input__input"
            onChange={onChangeFaqTitleHandler}
            defaultValue={item.title}
            placeholder="Enter question"
          />
          {item.title !== "" && returnFaqTextField()}
        </div>
      </div>
    );
  });

  return faqInputs;
};

export default Faq;
