import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

type DeletePostAsync = (payload: {
  accessToken: string;
  subdomain: string;
  postUrl: string;
}) => Promise<AxiosResponse<any>>;

export const deletePostAsync: DeletePostAsync = ({
  accessToken,
  subdomain,
  postUrl,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl =
      api.blogPosts.deleteBlogPost.prefix +
      subdomain +
      "/" +
      postUrl +
      api.blogPosts.deleteBlogPost.postfix;

    axios
      .delete(apiUrl, { ...returnAuthHeaderForAJAX(accessToken) })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
