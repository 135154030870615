import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

export const resendVerificationLinkAsync = (
  accessToken: string
): Promise<AxiosResponse<any>> => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        api.account.resendVerifyEmail,
        {},
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
