import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Faq01 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "We Have Answered Almost All Your Questions"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpFaq({isDefaultEmpty,maxItems: 16}));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a CTA (call to action) to the bottom of the F.A.Q. component to let your visitors to ask further questions about your project. Add a 'mailto:' link or a link to a survey form (Google Forms, Typeform, JotForm).",
                    default: "Add a CTA (call to action) to the bottom of the F.A.Q. component to let your visitors to ask further questions about your project. Add a 'mailto:' link or a link to a survey form (Google Forms, Typeform, JotForm).",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Welcome'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let faq = this.props.componentItem.componentData.faq.content.items;
        let ctaFirstButton = this.props.componentItem.componentData.cta.content.buttons[0];
        let ctaFirstButtonTitle = ctaFirstButton && ctaFirstButton.title;

        return (
            <section>

                <div className="faq-01">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--small">
                        <ul className="faq-01__list">
                            {faq && faq.map((item:any, key:number) => {
                                if(item.title !== ''){
                                    return (
                                        <li className="faq-01__item" key={'faq-01-' + key}>
                                            <button className="faq-01__question color-main js-open-faq" type="button">
                                                <div className="faq-01__question_text">
                                                    <span className="title-text--inner def-16">
                                                      {item.title}
                                                    </span>
                                                    <div className="faq-01__arrow_icon"><span className="icon">
                                                    <svg width="12px"
                                                         height="7px"
                                                         viewBox="0 0 12 7"
                                                         version="1.1"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <g id="styleguide" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round"
                                                           strokeLinejoin="round">
                                                            <g id="Unicorn-Styleguide" transform="translate(-1182.000000, -2712.000000)" strokeWidth="2" stroke="#4D61FC" className="stroke-main">
                                                                <g id="Messages" transform="translate(81.000000, 2467.000000)">
                                                                    <g id="toolstips" transform="translate(791.000000, 57.000000)">
                                                                        <g id="Tooltip" transform="translate(1.000000, 98.000000)">
                                                                            <polyline id="Line" points="310.5 95.5 315 91 319.5 95.5"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </span>
                                                    </div>
                                                </div>
                                                <div className="faq-01__answer js-faq-item">
                                                    <div className="content_box ">
                                                        {item.text !== '' &&
                                                            <div className="content-text faq-01__answer_text" dangerouslySetInnerHTML={{__html: item.text}}/>}
                                                    </div>
                                                </div>
                                            </button>
                                        </li>
                                    )
                                }else{
                                    return (null)
                                }
                            })}
                        </ul>
                        {this.props.componentItem.componentData.cta && ctaFirstButton && ctaFirstButtonTitle && ctaFirstButtonTitle !== '' && 
                          (<div className="bottom_cta"><WebsiteCTA componentItem={this.props.componentItem} /></div>)
                        }
                    </div>
                </div>

            </section>
        )
    }
}

export default Faq01;
