import axios from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";

const actionsNames = {
  SAVE_TEMPLATES_DATA: "SAVE_TEMPLATES_DATA" as string,
  TEMPLATES_DATA_FETCHED: "TEMPLATES_DATA_FETCHED" as string,
};

export const fetchTemplates = () => {
  return (dispatch: any, getState: any) => {
    const cacheParam = getState().meta.cache;
    const url = `${api.templates.fetchTemplates}?v=${cacheParam}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(saveTemplatesInState(response.data));
        dispatch(toggleTemplatesFetched(true));
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

export const saveTemplatesInState = (data: string) => {
  // get user data and store it
  return {
    type: actionsNames.SAVE_TEMPLATES_DATA as string,
    payload: data as string,
  };
};

export const toggleTemplatesFetched = (isDataFetched: boolean) => {
  return {
    type: actionsNames.TEMPLATES_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export default actionsNames;
