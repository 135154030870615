import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { Components } from "../../../../enums/Components";
import {
  componentsWithGraphicsOptions,
  contentTypesWithGraphicsOptions,
  GraphicsOptions,
} from "../data/componentGraphicsData";
import _ from "lodash";
import { ComponentTypes } from "../../../../enums/ComponentTypes";
import { UITextTypes } from "../../../../enums/UITextTypes";
import { mapTextObjectsToContentTypes } from "../data/defaultUIText";

export const forceNewUITextIfHasMultipleGraphicsOptions = (
  UITextType: UITextTypes,
  currentUIText: string,
  contentType: ContentInputsTypesEnum,
  currentComponent: Components,
  activeOption: GraphicsOptions,
  componentType: ComponentTypes
) => {
  const currentActiveOption = Object.values(GraphicsOptions).includes(
    activeOption
  )
    ? activeOption
    : GraphicsOptions.image;

  const forceNewUIText =
    componentsWithGraphicsOptions.includes(currentComponent) &&
    contentTypesWithGraphicsOptions.includes(contentType);

  if (!forceNewUIText) return currentUIText;

  const pathToValue: [
    ContentInputsTypesEnum,
    UITextTypes,
    GraphicsOptions,
    ComponentTypes?
  ] = [contentType, UITextType, currentActiveOption];

  if (typeof _.get(mapTextObjectsToContentTypes, pathToValue) === "object") {
    pathToValue.push(componentType);
  }

  return _.get(mapTextObjectsToContentTypes, pathToValue);
};
