import React, { useEffect } from "react";
import { formFields } from "../../data/formFields";
import { checkIsFormOld } from "../../helpers/editor/checkIsFormOld";
import generateRandomNumerousId from "../../helpers/generateRandomNumerousId";
import { IMaskInput } from "react-imask";
import WebsiteButton from "./WebsiteButton";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import BottomCtaInfo from "./BottomCtaInfo";
import classNames from "classnames";
import _ from "lodash";
import { publicUrl } from "../../data/urls";

interface Props {
  componentItem: any;
  currentWebsite: any;
  isWhiteFormInputs?: boolean;
  bottomInfoMarkup?: string;
}

interface MultipleSelectProps extends Props {
  i: number;
  inputSizeClassname: string;
  inputHiddenClassname: string;
  item: any;
  shouldDisplayRequiredAsterix: boolean;
}
const MultipleSelect = (props: MultipleSelectProps) => {
  const {
    i,
    inputSizeClassname,
    inputHiddenClassname,
    isWhiteFormInputs,
    item,
    shouldDisplayRequiredAsterix,
    componentItem,
  } = props;
  const id = `${componentItem.id}-${i}-chosen-select`;
  useEffect(() => {
    $(`#${id}`).chosen({
      hide_results_on_select: false,
      width: "100%",
      no_results_text: item.noResultsText || "No results match",
    });
    return () => {
      $(`#${id}`).chosen("destroy");
    };
  }, [item.dropdownItems.join(";"), item.placeholder, item.noResultsText, id]);

  let returnDropdownOptions = (item: any) => {
    let dropdownItems = item.dropdownItems;
    let itemsJSX = [];
    let dropdownItemsCount = dropdownItems.length;
    for (let i = 0; i < dropdownItemsCount; i++) {
      if (dropdownItems[i] !== "") {
        itemsJSX.push(
          <option key={"option-item-" + i} value={dropdownItems[i]}>
            {dropdownItems[i]}
          </option>
        );
      }
    }
    return itemsJSX;
  };

  return (
    <div
      key={"form__input-" + i}
      className={
        "form__input form__input--multiple " +
        inputSizeClassname +
        inputHiddenClassname
      }
    >
      <div className="form__input__label_box">
        <label
          className={
            (isWhiteFormInputs ? "text-white " : " ") + "form__input__label"
          }
          htmlFor={item.name}
        >
          {item.label}
        </label>
      </div>
      <div className="multiple-wrapper">
        <select
          className={classNames("text-input text-input--multiple-default", {
            "text-input--white": isWhiteFormInputs,
          })}
          name={item.name}
          id={id}
          multiple
          data-placeholder={item.placeholder}
        >
          {returnDropdownOptions(item)}
        </select>
      </div>
    </div>
  );
};

interface FileUploadProps extends Props {
  i: number;
  inputSizeClassname: string;
  inputHiddenClassname: string;
  item: any;
  shouldDisplayRequiredAsterix: boolean;
}
const FileUpload = (props: FileUploadProps) => {
  const {
    i,
    inputSizeClassname,
    inputHiddenClassname,
    isWhiteFormInputs,
    item,
    shouldDisplayRequiredAsterix,
    componentItem,
  } = props;
  const id = `${componentItem.id}-${i}-file-upload`;
  useEffect(() => {
    const maxFiles = item.maxFiles || 1;
    const allowedFileTypes = (item.allowedFileTypes || "")
      .toString()
      .toLowerCase()
      .replace(/ /g, "")
      .split(";")
      .filter((i) => !!i);
    const inputElement = document.getElementById(id);
    const pond = (window as any).FilePond.create(inputElement, {
      allowReorder: true,
      allowMultiple: maxFiles > 1,
      credits: false,
      maxFileSize: "10MB",
      labelIdle: item.placeholder,
      maxFiles,
      acceptedFileTypes: allowedFileTypes,
      onwarning: (error) => {
        console.log('error: ', error);
        if (error && error.body === "Max files") {
          alert(`Error: You can upload up to ${maxFiles} files.`);
        }
      }
    });
  }, []);
  return (
    <div key={"form__input-" + i} className={"form__input form__input--full"}>
      <div className="form__input__label_box">
        <label
          className={
            (isWhiteFormInputs ? "text-white " : " ") + "form__input__label"
          }
          htmlFor={item.name}
        >
          {item.label}
        </label>
      </div>
      <input type="file" name={item.name} id={id} />
    </div>
  );
};

const WebsiteForm = (props: Props): JSX.Element => {
  const { componentItem, currentWebsite, isWhiteFormInputs, bottomInfoMarkup } =
    props;

  // We pass all the component info (componentItem) because we may want to extract additional data from it. For example: style (color). Depending on the current background-color display white of black inputs.

  let form = componentItem.componentData.cta.content.form;
  const filteredFieldItems = _.get(form, "fields.items", []).filter(
    (item) => !_.get(item, "settings.isHidden", false)
  );

  let connectedFormIntegrationId = form.connectedIntegrationsIds[0]; // currently we only offer a single integration to a form, so pick the first (user can only connect ony integration in the dashboard)
  // Find current form connected integration ID;

  // If we have a connected integration to a form and the website has an array of available integrations, from all website-scope available integrations, find the connected to this form one and get its 'action' to apply to the <form> tag.
  let formAction: string | undefined = undefined;
  let allWebsiteAvailableIntegrations =
    currentWebsite.connected_form_integrations.connected;
  let integrationTitle = "";

  if (connectedFormIntegrationId && allWebsiteAvailableIntegrations) {
    let allWebsiteAvailableIntegrationsCount =
      allWebsiteAvailableIntegrations.length;
    for (let i = 0; i < allWebsiteAvailableIntegrationsCount; i++) {
      // if the ID of the current activated form integration is the same with the one in the list of all available integration of the website, get its 'action'
      if (
        allWebsiteAvailableIntegrations[i].id === connectedFormIntegrationId
      ) {
        formAction = allWebsiteAvailableIntegrations[i].data.action;
        integrationTitle = allWebsiteAvailableIntegrations[i].integrationTitle;
      }
    }
  }

  // We need to set a unique form ID because our client side script relies on a unique ID when opens a message ('error', 'success', 'engaging')
  let uniqueFormId = "email-form-" + generateRandomNumerousId().toString();

  let buttonObject = form.button;
  let buttonTitle = buttonObject.title;

  let integrationJsClassname = "";
  if (integrationTitle === "zapier") {
    integrationJsClassname = "js-subscribe-zapier-form";
  } else if (integrationTitle === "mailchimp") {
    integrationJsClassname = "js-subscribe-mailchimp-form";
  }

  let isFormOldSingleEmailFieldType = checkIsFormOld(form); //learn more about this check in checkIsFormOld.ts. tl;dr: we add new properties to old form objects. And 'jsCodeOnSuccess' is one of the new props.
  // We need this because if 1 and 2 input are not text area we display them in one row, then all other inputs are going in a separate row.
  let isBothFirstAndSecondInputsAreNotTextarea = false;
  if (
    filteredFieldItems.length === 1 &&
    filteredFieldItems[0].title !== formFields.message.title
  ) {
    isBothFirstAndSecondInputsAreNotTextarea = true;
  } else if (
    filteredFieldItems.length > 1 &&
    filteredFieldItems[0].title !== formFields.message.title &&
    filteredFieldItems[1].title !== formFields.message.title
  ) {
    isBothFirstAndSecondInputsAreNotTextarea = true;
  }
  let isOnlyOneOrTwoFields =
    filteredFieldItems.length > 0 && filteredFieldItems.length < 3;

  let returnFormInputs = () => {
    let returnInputSizeClassname = (key: number) => {
      // if 0 or 1 or 2 fields, display in one row - not full width
      if (isOnlyOneOrTwoFields && isBothFirstAndSecondInputsAreNotTextarea) {
        return " ";
      } else {
        // if isBothFirstAndSecondInputsAreNotTextarea and the input has an index 0 or 1, no additional class added
        if (isBothFirstAndSecondInputsAreNotTextarea) {
          if (key === 0 || key === 1) {
            return " ";
          } else {
            // if one of the first two inputs is not textarea or there is only 1 input, it is displayed in a full width state
            return " form__input--full ";
          }
        } else {
          // if one of the first two inputs is not textarea or there is only 1 input, it is displayed in a full width state
          return " form__input--full ";
        }
      }
    };
    let returnInputHiddenClassname = (item: any) => {
      // if input has 'hidden' attribute, add .form__input--hidden
      const isHidden = _.get(item, "settings.isHidden", false);
      if (
        isHidden ||
        (item.settings !== undefined &&
          item.settings.attributes !== undefined &&
          item.settings.attributes.indexOf("hidden") !== -1)
      ) {
        return " form__input--hidden ";
      } else {
        return " ";
      }
    };
    if (isFormOldSingleEmailFieldType) {
      return (
        <div className="form__input">
          <input
            className={
              "text-input js-form-input " +
              (isWhiteFormInputs ? " text-input--white " : "")
            }
            type="email"
            name="EMAIL"
            placeholder="Enter your email"
          />
        </div>
      );
    } else {
      let returnSingleInput = (item: any, key: number) => {
        let shouldDisplayRequiredAsterix =
          (form.fields.items.length > 1 && item.required) ||
          (form.fields.items.length === 1 &&
            item.required &&
            item.label.length > 0); //if one input we do not display asterix if no label set.

        if (item.title === formFields.dropdown.title) {
          let returnDropdownOptions = (item: any) => {
            let dropdownItems = item.dropdownItems;

            let itemsJSX = [];

            let dropdownItemsCount = dropdownItems.length;
            for (let i = 0; i < dropdownItemsCount; i++) {
              if (dropdownItems[i] !== "") {
                itemsJSX.push(
                  <option key={"option-item-" + i} value={dropdownItems[i]}>
                    {dropdownItems[i]}
                  </option>
                );
              }
            }

            return itemsJSX;
          };

          return (
            <div
              key={"form__input-" + key}
              className={
                "form__input " +
                returnInputSizeClassname(key) +
                returnInputHiddenClassname(item)
              }
            >
              <div className="form__input__label_box">
                <label
                  className={
                    (isWhiteFormInputs ? "text-white " : " ") +
                    "form__input__label"
                  }
                  htmlFor={item.name}
                >
                  {item.label}
                </label>
              </div>
              <select
                className={
                  (isWhiteFormInputs ? "text-input--white " : " ") +
                  " text-input"
                }
                name={item.name}
                id=""
              >
                {/*html5 spec requried an item with val = '' if we need a 'required' behaviour */}
                {item.required && (
                  <option value="">
                    {item.placeholder ? item.placeholder : "Select..."}
                  </option>
                )}
                {returnDropdownOptions(item)}
              </select>
            </div>
          );
        } else if (item.title === formFields.multiple_choice.title) {
          return (
            <MultipleSelect
              i={key}
              inputSizeClassname={returnInputSizeClassname(key)}
              inputHiddenClassname={returnInputHiddenClassname(item)}
              item={item}
              shouldDisplayRequiredAsterix={shouldDisplayRequiredAsterix}
              key={key}
              {...props}
            />
          );
        } else if (item.title === formFields.checkbox.title) {
          return (
            <div
              key={"form__input-" + key}
              className={
                "checkbox form__input  form__input--full " +
                returnInputHiddenClassname(item)
              }
            >
              <input
                type="checkbox"
                className={
                  "checkbox__input js-form-input " +
                  (isWhiteFormInputs ? " text-input--white " : "")
                }
                name={item.name}
                id={uniqueFormId + "-" + key}
              />

              <div className="checkbox__check">
                <img
                  src={publicUrl + "/img/icons/checked--fat--white.svg"}
                  alt="✔"
                  className="checkbox__icon"
                />
              </div>

              <label
                className={
                  (isWhiteFormInputs ? "text-white " : " ") +
                  " checkbox__label content-text"
                }
                htmlFor={uniqueFormId + "-" + key}
                dangerouslySetInnerHTML={{ __html: item.label }}
              ></label>
            </div>
          );
        } else if (item.title === formFields.message.title) {
          return (
            <div
              key={"form__input-" + key}
              className={"form__textarea " + returnInputHiddenClassname(item)}
            >
              <div className="form__input__label_box">
                <label
                  className={
                    (isWhiteFormInputs ? "text-white " : " ") +
                    "form__input__label"
                  }
                  htmlFor={item.name}
                >
                  {item.label}
                </label>
              </div>
              <textarea
                className={
                  "textarea js-form-input " +
                  (isWhiteFormInputs ? " text-input--white " : "")
                }
                name={item.name}
                placeholder={item.placeholder}
                defaultValue={_.get(item, "settings.defaultValue")}
              />
            </div>
          );
        } else if (item.title === formFields.file.title) {
          return (
            <FileUpload
              i={key}
              inputSizeClassname={returnInputSizeClassname(key)}
              inputHiddenClassname={returnInputHiddenClassname(item)}
              item={item}
              shouldDisplayRequiredAsterix={shouldDisplayRequiredAsterix}
              key={`${key}-${item.placeholder}-${item.maxFiles}-${item.allowedFileTypes}`}
              {...props}
            />
          );
        } else {
          let isInputMaskPresented = false;
          let itemMask = item["settings"]["mask"]; // https://github.com/uNmAnNeR/imaskjs
          if (
            itemMask !== undefined &&
            itemMask !== "" &&
            item.title !== formFields.email.title
          ) {
            // if mask setting is set and the field is not email (we do not want to allow users to set masks for email inputs to make sure they will not ruin their email imputs)
            isInputMaskPresented = true;
          }

          return (
            <div
              key={"form__input-" + key}
              className={
                "form__input " +
                returnInputSizeClassname(key) +
                returnInputHiddenClassname(item)
              }
            >
              <div className="form__input__label_box">
                <label
                  className={
                    (isWhiteFormInputs ? "text-white " : " ") +
                    "form__input__label"
                  }
                  htmlFor={item.name}
                >
                  {item.label}
                </label>
              </div>

              {/* same elements, but if mask is presented also add mask={itemMask} and use the special jsx element (IMaskInput) */}
              {isInputMaskPresented && (
                <IMaskInput
                  className={
                    "text-input js-form-input " +
                    (isWhiteFormInputs ? " text-input--white " : "")
                  }
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  mask={itemMask}
                />
              )}
              {isInputMaskPresented === false && (
                <input
                  className={
                    "text-input js-form-input " +
                    (isWhiteFormInputs ? " text-input--white " : "")
                  }
                  type={item.type}
                  name={item.name}
                  placeholder={item.placeholder}
                  defaultValue={_.get(item, "settings.defaultValue")}
                />
              )}
            </div>
          );
        }
      };

      let inputsJSX = [];

      let formFieldsCount = form.fields.items.length;
      for (let i = 0; i < formFieldsCount; i++) {
        let item = form.fields.items[i];
        inputsJSX.push(returnSingleInput(item, i));
      }

      return inputsJSX;
    }
  };

  let returnFormSubmitButton = () => {
    let additionalButtonClassname = " ";

    let formHasCheckbox = false;
    let formFieldsCount = filteredFieldItems.length;
    for (let i = 0; i < formFieldsCount; i++) {
      let item = filteredFieldItems[i];
      if (
        item.title === formFields.checkbox.type ||
        item.title === formFields.file.type
      ) {
        formHasCheckbox = true;
        break;
      }
    }
    if (formHasCheckbox) {
      //if has a checkbox - button always full
      additionalButtonClassname = " form__button--full ";
    } else if (!isFormOldSingleEmailFieldType) {
      // if does not have checkbox - let's check is full or not
      let isFirstInputTextarea = false;
      if (
        filteredFieldItems.length > 0 &&
        filteredFieldItems[0].title === formFields.message.title
      ) {
        isFirstInputTextarea = true;
      }

      additionalButtonClassname = " form__button--full ";
      if (isOnlyOneOrTwoFields) {
        if (isBothFirstAndSecondInputsAreNotTextarea || isFirstInputTextarea) {
          additionalButtonClassname = " ";
        }
      }
    }

    let shouldDisplayButton =
      isFormOldSingleEmailFieldType || filteredFieldItems.length > 0; //if only single field form or new but with any field
    if (shouldDisplayButton) {
      return (
        <div className={"form__button " + additionalButtonClassname}>
          <WebsiteButton
            config={{
              buttonType: linkOrButtonOptions.submit,
              buttonTitle: buttonTitle,
              buttonColor: buttonObject.settings.appearance.color,
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  };

  let returnFormAdditionalClass = () => {
    let additionalFormClassname = " ";
    if (!isFormOldSingleEmailFieldType) {
      let isFirstInputTextarea = false;
      if (
        form.fields.items.length > 0 &&
        form.fields.items[0].title === formFields.message.title
      ) {
        isFirstInputTextarea = true;
      }

      additionalFormClassname = " form--centered-button ";
      if (isOnlyOneOrTwoFields) {
        if (isBothFirstAndSecondInputsAreNotTextarea || isFirstInputTextarea) {
          additionalFormClassname = " ";
        }
      }
    }

    return additionalFormClassname;
  };
  return (
    <form
      className={
        "form " + integrationJsClassname + " " + returnFormAdditionalClass()
      }
      action={formAction}
      method="post"
    >
      <div className="form__inputs">
        {returnFormInputs()}
        {returnFormSubmitButton()}
        <div className="form__messages_box">
          <div className="form__messages">
            <div className="message message--error js-message js-error-message">
              <div className="message__box">
                <button
                  className="message__close js-close-message"
                  type="button"
                >
                  <img
                    className="message__close_icon"
                    src={publicUrl + "/img/icons/corner-top--blue.svg"}
                  />
                </button>
                <div className="message__body">
                  <div className="message__in">
                    <div className="message__bubble">
                      <div className="message__bubble_text">
                        <b>Error.</b> Your form has not been submitted
                        <img
                          className="emoji  "
                          src={publicUrl + "/img/twemoji/1f914.svg"}
                          alt="Emoji"
                        />
                      </div>
                    </div>
                    <div className="message__bubble">
                      <div className="message__bubble_text">
                        This is what the server says:
                        <div className="message__bubble_error js-error-message-text">
                          There must be an @ at the beginning.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="message__out">
                    <div className="message__out_box">
                      <div className="message__bubble">
                        <div className="message__bubble_text message__bubble_text--out js-reaction-text">
                          I will retry
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="message__reply_box">
                  <div className="message__reply_word">Reply</div>
                  <div className="message__options">
                    <div className="message__option">
                      <button
                        className="button js-react-on-message button--ruby-bg "
                        type="submit"
                      >
                        <span className="button__text">Uh oh!</span>
                      </button>
                    </div>
                    <div className="message__option">
                      <button
                        className="button js-react-on-message button--ruby-bg "
                        type="submit"
                      >
                        <span className="button__text">I will retry</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BottomCtaInfo markup={bottomInfoMarkup} isDarkBg={isWhiteFormInputs} />
    </form>
  );
};

export default WebsiteForm;
