import React, {Component} from "react";
import {Switch} from "antd";

interface Props {
    websites: any,
    websitesPages: any,
    auth: any,

    currentWebsite: any,
    currentWebsitePage: any,

    publishPageToggleWaitingForServer: any,

    publishPage: any,
}
class PublishPageForm extends Component<Props>{

    handlePublishChange = (checked:boolean):void => {
        this.props.publishPage(checked, this.props.currentWebsitePage.url, this.props.currentWebsite);
    };

    render(){
        let isPagePublished = this.props.currentWebsitePage.is_published;

        return (
            <div>
                <Switch loading={this.props.websitesPages.isWaitingForPublishPageResponse} checked={isPagePublished} onChange={this.handlePublishChange} />
            </div>
        )
    }
}

export default PublishPageForm;
