import React, {Component} from "react";
import {Button, Icon, Switch, Tooltip} from "antd";
import "./PublishPages.css";
import SpinnerBox from "./SpinnerBox";
import {indexWebsitePageDashboardUrl, projectUrl} from "../../data/constants";
import NoTitleWarning from "./NoTitleWarning";
import trimStringTo from "../../helpers/strings/trimStringTo";
import {getWebsiteProtocol} from "../../helpers/getWebsiteProtocol";

interface Props {
    auth: any,
    currentWebsite: any,
    currentWebsitePages: any,
    toggleWebsitePageChangeInProgress: any,
    websitesPages: any,
    websites: any,
    saveWebsitePageDataInStore: any,
    publishPage: any,

    replaceWebsitesPagesInState: any,
}

class PublishPages extends Component<Props>{
    togglePublishSingePage = (checked:boolean, websitePageUrl:string) => {
        this.props.publishPage(checked, websitePageUrl, this.props.currentWebsite);
    };

    displayWebsitePagesSwitches = () => {
        let displayPageTitle = (item:any) => {
            let prefix = (<span className="publish-pages__page_title_prefix">/{trimStringTo(item.url, 20)}</span>);
            if(item.url === ''){
                prefix = (<span className="publish-pages__page_title_prefix">Home page: </span>)
            }

            if (item.meta_title.length > 0){
                let formattedTitle = item.meta_title;
                let isTitleTooLong = false;
                if (item.meta_title.length > 50){
                    formattedTitle = formattedTitle.slice(0, 47);
                    isTitleTooLong = true;
                }

                return (<span>{prefix} <span className={isTitleTooLong ? 'publish-pages__big_title' : ''}>{formattedTitle}</span></span>)
            }else{
                return (<NoTitleWarning prefix={prefix}/>)
            }
        };
        const websitePagesSwitches = this.props.currentWebsitePages.map((item:any, key:number) =>
            {
                let getCurrentWebsitePageUrl = (websitePageUrl:string) => {
                    let currentWebsitePageUrl = '';
                    if(this.props.currentWebsite.is_custom_domain_active){
                        let websiteProtocol = getWebsiteProtocol(this.props.currentWebsite);
                        currentWebsitePageUrl = websiteProtocol + this.props.currentWebsite.custom_domain + '/' + websitePageUrl;
                    }else{
                        currentWebsitePageUrl = 'https://' + this.props.currentWebsite.subdomain + '.' + projectUrl + '/' + websitePageUrl;
                    }

                    return currentWebsitePageUrl;
                };

                return (
                    <li key={key} className="publish-pages__switch_item">
                        <Switch size="small" data-name="page-url-for-api" loading={this.props.websitesPages.pageChangeInProgress} onChange={(checked:boolean) => {this.togglePublishSingePage(checked, item.url)}} checked={item.is_published}/>
                        <div className="publish-pages__page_title">
                            <div className="publish-pages__page_title_text"> {displayPageTitle(item)}
                                <a hidden={this.props.websitesPages.pageChangeInProgress} title="View the page in a new tab." href={getCurrentWebsitePageUrl(item.url)} target="_blank" className="publish-pages__link">
                                    <Icon type="link" />
                                </a>
                            </div>
                        </div>
                    </li>
                )
            }
        );

        if (this.props.websitesPages.pagesWithoutComponentsFetched === false){
            return (<SpinnerBox text={'Pages are loading...'}/>)
        }else{
            return websitePagesSwitches;
        }
    };

    render(){
        return (
            <div className="publish-pages">
                {/*Todo: */}
                {/*<div className="publish-pages__buttons">*/}
                    {/*<Button style={{marginRight: '10px'}} loading={this.props.websitesPages.pageChangeInProgress} icon="global" size="small" htmlType="button">Publish all</Button>*/}
                    {/*<Button style={{marginRight: '10px'}} loading={this.props.websitesPages.pageChangeInProgress} icon="eye-invisible" size="small" htmlType="button">Unpublish all</Button>*/}
                {/*</div>*/}
                <ul className="publish-pages__switches_list">
                    {this.displayWebsitePagesSwitches()}
                </ul>
            </div>
        )
    }
}

export default PublishPages;
