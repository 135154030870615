import _ from "lodash";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { updatePricingFeature } from "./updatePricingFeature";

const particleUpdatePrice = (
  originalItem,
  modifiedItem,
  itemMonthlyPrice,
  itemMonthlyPeriod,
  itemYearlyPrice,
  itemYearlyPeriod
) => {
  if (modifiedItem.TWO_PRICES === null) {
    originalItem.monthlyPrice = "";
    originalItem.monthlyPeriod = "";
    originalItem.yearlyPrice = "";
    originalItem.yearlyPeriod = "";
  }
  if (!isKeyValue(modifiedItem.TWO_PRICES)) {
    return;
  }
  if ("FIRST_PRICE" in modifiedItem.TWO_PRICES) {
    if (modifiedItem.TWO_PRICES.FIRST_PRICE === null) {
      originalItem.monthlyPrice = "";
      originalItem.monthlyPeriod = "";
    }
    if (isKeyValue(modifiedItem.TWO_PRICES.FIRST_PRICE)) {
      if ("AMOUNT" in modifiedItem.TWO_PRICES.FIRST_PRICE) {
        originalItem.monthlyPrice = itemMonthlyPrice;
      }
      if ("PERIOD" in modifiedItem.TWO_PRICES.FIRST_PRICE) {
        originalItem.monthlyPeriod = itemMonthlyPeriod;
      }
    }
  }
  if ("SECOND_PRICE" in modifiedItem.TWO_PRICES) {
    if (modifiedItem.TWO_PRICES.SECOND_PRICE === null) {
      originalItem.yearlyPrice = "";
      originalItem.yearlyPeriod = "";
    }
    if (isKeyValue(modifiedItem.TWO_PRICES.SECOND_PRICE)) {
      if ("AMOUNT" in modifiedItem.TWO_PRICES.SECOND_PRICE) {
        originalItem.yearlyPrice = itemYearlyPrice;
      }
      if ("PERIOD" in modifiedItem.TWO_PRICES.SECOND_PRICE) {
        originalItem.yearlyPeriod = itemYearlyPeriod;
      }
    }
  }
};

const particleUpdateButton = (
  originalItem,
  modifiedItem,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if (modifiedItem.BUTTON === null) {
    originalItem.button.title = "";
    originalItem.button.href = "";
    originalItem.button.isTargetBlank = false;
  }
  if (!isKeyValue(modifiedItem.BUTTON)) {
    return;
  }
  if ("TEXT" in modifiedItem.BUTTON) {
    originalItem.button.title = itemButtonText;
  }
  if ("URL" in modifiedItem.BUTTON) {
    originalItem.button.href = itemButtonUrl;
  }
  if ("TARGET" in modifiedItem.BUTTON) {
    originalItem.button.isTargetBlank = itemButtonTarget === "_blank";
  }
};

const particleUpdateTabs = (
  componentData,
  modifiedItem,
  itemLeftTab,
  itemRightTab,
  itemBanner
) => {
  if (modifiedItem.TWO_TABS === null) {
    componentData.pricing.leftTabText = "";
    componentData.pricing.rightTabText = "";
    componentData.pricing.yearlyBonusText = "";
  }
  if (!isKeyValue(modifiedItem.TWO_TABS)) {
    return;
  }
  if ("LEFT" in modifiedItem.TWO_TABS) {
    componentData.pricing.leftTabText = itemLeftTab;
  }
  if ("RIGHT" in modifiedItem.TWO_TABS) {
    componentData.pricing.rightTabText = itemRightTab;
  }
  if ("RIGHT_TAB_BANNER" in modifiedItem.TWO_TABS) {
    componentData.pricing.yearlyBonusText = itemBanner;
  }
};

const particleGetModifiedValues = (modifiedItem) => {
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  const itemSubtitle = stringifyObjectsAndNumbers(modifiedItem.SUBTITLE) || "";
  const itemLeftTab = _.get(modifiedItem, ["TWO_TABS", "LEFT"]) || "";
  const itemRightTab = _.get(modifiedItem, ["TWO_TABS", "RIGHT"]) || "";
  const itemBanner =
    _.get(modifiedItem, ["TWO_TABS", "RIGHT_TAB_BANNER"]) || "";
  const itemSecondaryInfo =
    stringifyObjectsAndNumbers(modifiedItem.ADDITIONAL_INFO) || "";
  const itemMonthlyPrice =
    stringifyObjectsAndNumbers(
      _.get(modifiedItem, ["TWO_PRICES", "FIRST_PRICE", "AMOUNT"])
    ) || "";
  const itemMonthlyPeriod =
    stringifyObjectsAndNumbers(
      _.get(modifiedItem, ["TWO_PRICES", "FIRST_PRICE", "PERIOD"])
    ) || "";
  const itemYearlyPrice =
    stringifyObjectsAndNumbers(
      _.get(modifiedItem, ["TWO_PRICES", "SECOND_PRICE", "AMOUNT"])
    ) || "";
  const itemYearlyPeriod =
    stringifyObjectsAndNumbers(
      _.get(modifiedItem, ["TWO_PRICES", "SECOND_PRICE", "PERIOD"])
    ) || "";
  const itemCurrency = stringifyObjectsAndNumbers(modifiedItem.CURRENCY) || "";
  const itemButtonText =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TEXT"])) || "";
  const itemButtonUrl =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "URL"])) || "";
  const itemButtonTarget =
    stringifyObjectsAndNumbers(_.get(modifiedItem, ["BUTTON", "TARGET"])) ||
    "_self";
  return {
    itemTitle,
    itemSubtitle,
    itemLeftTab,
    itemRightTab,
    itemBanner,
    itemSecondaryInfo,
    itemMonthlyPrice,
    itemMonthlyPeriod,
    itemYearlyPrice,
    itemYearlyPeriod,
    itemCurrency,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemTitle,
  itemSubtitle,
  itemLeftTab,
  itemRightTab,
  itemBanner,
  itemSecondaryInfo,
  itemMonthlyPrice,
  itemMonthlyPeriod,
  itemYearlyPrice,
  itemYearlyPeriod,
  itemCurrency,
  itemButtonText,
  itemButtonUrl,
  itemButtonTarget
) => {
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("SUBTITLE" in modifiedItem) {
    originalItem.primaryInfo = itemSubtitle;
  }
  if ("TWO_TABS" in modifiedItem) {
    particleUpdateTabs(
      componentData,
      modifiedItem,
      itemLeftTab,
      itemRightTab,
      itemBanner
    );
  }
  if ("ADDITIONAL_INFO" in modifiedItem) {
    originalItem.secondaryInfo = itemSecondaryInfo;
  }
  if ("TWO_PRICES" in modifiedItem) {
    particleUpdatePrice(
      originalItem,
      modifiedItem,
      itemMonthlyPrice,
      itemMonthlyPeriod,
      itemYearlyPrice,
      itemYearlyPeriod
    );
  }
  if ("FEATURES" in modifiedItem) {
    updatePricingFeature(originalItem, modifiedItem);
  }
  if ("CURRENCY" in modifiedItem) {
    originalItem.currencySymbol = itemCurrency;
  }
  if ("BUTTON" in modifiedItem) {
    particleUpdateButton(
      originalItem,
      modifiedItem,
      itemButtonText,
      itemButtonUrl,
      itemButtonTarget
    );
  }
};

export const updatePricingSingle = (componentData, completionContent) => {
  let completionContentCopy = _.cloneDeep(completionContent);
  const originalItem = componentData.pricing.content.items[0];
  if (!("PRICING" in completionContentCopy)) {
    completionContentCopy = {
      PRICING: completionContentCopy,
    };
  }
  const modifiedItem = completionContentCopy.PRICING;
  if (!isKeyValue(modifiedItem)) {
    if (modifiedItem === null) {
      originalItem.title = "";
      originalItem.primaryInfo = "";
      originalItem.monthlyPrice = "";
      originalItem.yearlyPrice = "";
      originalItem.monthlyPeriod = "";
      originalItem.yearlyPeriod = "";
      originalItem.detailsSet = [];
      originalItem.currencySymbol = "";
      originalItem.secondaryInfo = "";
      originalItem.button.title = "";
      originalItem.button.href = "";
      originalItem.button.isTargetBlank = false;
      componentData.pricing.leftTabText = "";
      componentData.pricing.rightTabText = "";
      componentData.pricing.yearlyBonusText = "";
    }
    return;
  }

  originalItem.IS_BEING_MODIFIED_BY_AI = true;

  const {
    itemTitle,
    itemSubtitle,
    itemLeftTab,
    itemRightTab,
    itemBanner,
    itemSecondaryInfo,
    itemMonthlyPrice,
    itemMonthlyPeriod,
    itemYearlyPrice,
    itemYearlyPeriod,
    itemCurrency,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget,
  } = particleGetModifiedValues(modifiedItem);

  particleModifyOriginalItem(
    componentData,
    originalItem,
    modifiedItem,
    itemTitle,
    itemSubtitle,
    itemLeftTab,
    itemRightTab,
    itemBanner,
    itemSecondaryInfo,
    itemMonthlyPrice,
    itemMonthlyPeriod,
    itemYearlyPrice,
    itemYearlyPeriod,
    itemCurrency,
    itemButtonText,
    itemButtonUrl,
    itemButtonTarget
  );
};
