import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {monthNames} from "../../data/constants";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    currentWebsite: any,
    connectDefaultFormIntegration: any,
    isDarkBg: boolean,
}

class CtaForm04 extends Component<Props>{
    componentWillMount(): void {
      const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject className;
            let generator = new generateContentDataObject();
            this.props.saveContentInStore(generator.setUpTitle({isDefaultEmpty,'titleText': 'Are You With Us?'}));
            this.props.saveContentInStore(generator.setUpSubtitleText({isDefaultEmpty,"subtitleText": ""}));
            this.props.saveContentInStore(generator.setUpCta({
                activeOption: callToActionOptions.form,
                priority: 500,
                isDefaultEmpty
            }));

            this.props.saveContentInStore(generator.setUpFeatures({
                maxItems: 24,
                priority: 400,
                fieldTitle: {default: "Features"},
                label: {default: "Edit the features:"},
                labelTooltip: {default: "Some crucial features to convince your audience join your project. Recommended amount: 3-4 items."},
                hasPictogram: false,
                hasButton: false,
                hasText: false,
                hasHighlight: false,
                hasHighlightInfo: false,
                items: [
                    generator.createFeatureContentObject({title: 'instant sign up'}),
                    generator.createFeatureContentObject({title: 'free 14 days trial',}),
                    generator.createFeatureContentObject({title: 'tech support'}),

                    // The last ghost item which is used to add new items.
                    generator.createFeatureContentObject({
                        title: '',
                        text: ''
                    }),
                ],isDefaultEmpty
            }));
            this.props.saveContentInStore(generator.setUpPosts({
                fieldTitle: {default: "Bottom info"},
                isDefaultEmpty,
                label: {default: "Add an extra offer:"},
                labelTooltip: {default: "You can add an offer for the ones who does not want to convert immediately. E.g. you can show sales contacts or redirect a user to an additional landing page."},
                maxItems: 1,
                items: [
                    generator.createPostsContentObject({
                        title: "Early stage startup?",
                        subtitle: 'Start using Unicorn for just $8 a month',
                        button: generator.createButtonContentObject({
                            buttonType: linkOrButtonOptions.link,
                            buttonTitle: 'View plans',
                            buttonHref: '/',
                            buttonStyleType: buttonStyles.ghost.typeTitle,
                            pillPrefix: 'Learn more'
                        }),
                    }),
                ],
                priority: 300,
                hasThumb: false,
                hasButton: true,
                buttonTypes: [buttonStyles.ghost.typeTitle, buttonStyles.regular.typeTitle, buttonStyles.mobileAppStore.typeTitle, buttonStyles.pill.typeTitle],
            }));

            this.props.connectDefaultFormIntegration(this.props.componentItem);
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let features = this.props.componentItem.componentData.features.content.items;

        let bottomInfo = this.props.componentItem.componentData.posts.content.items[0];

        let displayBottomInfo = (bottomInfo:any) => {
            if(bottomInfo !== undefined && bottomInfo.title && bottomInfo.title !== ''){
                return (
                    <div className="cta_form-04__bottom">
                        <h2 className={"title-text--inner cta_form-04__bottom_heading " + (this.props.isDarkBg ? 'text-white' : '')}>{bottomInfo.title}</h2>
                        <span className={"content-text cta_form-04__bottom_text " + (this.props.isDarkBg ? 'text-white' : '')}>{bottomInfo.subtitle}</span>
                        <div className="cta_form-04__bottom_link">
                            {bottomInfo.button.title !== '' && <WebsiteButton config={{
                                buttonStyleType: bottomInfo.button.settings.appearance.styleType,
                                buttonColor: bottomInfo.button.settings.appearance.color,
                                buttonType: linkOrButtonOptions.link,
                                buttonTitle: bottomInfo.button.title,
                                buttonHref: bottomInfo.button.href,
                                buttonIsTargetBlank: bottomInfo.button.isTargetBlank,
                                buttonMobileAppStoreType: bottomInfo.button.settings.appearance.mobileAppStoreType,
                                pillPrefix: bottomInfo.button.pillPrefix,
                                pillColor: bottomInfo.button.settings.appearance.pillColor,
                                ghostColor: bottomInfo.button.settings.appearance.ghostColor
                            }} />}
                        </div>
                    </div>
                )
            }else{
                return (null);
            }
        };

        return (
            <section>

                <div className="cta_form-04">
                    <div className="container container--mid">
                        <div className="cta_form-04__wrapper">
                            <div className="cta_form-04__top">
                                <div className="cta_form-04__heading_box">
                                    <TitleBox 
                                      title={title}
                                      subtitleText={subtitleText}
                                      isDarkBg={this.props.isDarkBg}
                                    />
                                </div>
                                <div className="cta_form-04__form_box">

                                    {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={this.props.isDarkBg} />}

                                    <ul className="cta_form-04__features">
                                        {features && features.map((item:any, key:number) => {
                                            if(item.title && item.title !== ''){
                                                return (
                                                    <li className={"content-text def-10 cta_form-04__feature " + (this.props.isDarkBg ? 'text-white' : '')} key={'features-form-04-' + key}>
                                                        {item.title}
                                                    </li>
                                                )
                                            }else{
                                                return (null);
                                            }
                                        })}
                                    </ul>
                                </div>
                            </div>

                            {displayBottomInfo(bottomInfo)}

                        </div>
                    </div>
                </div>

            </section>
        )
    }
}

export default CtaForm04;
