import _ from "lodash";
import { formatImageUrl } from "../strings/formatImageUrl";

export const getFirstImageFromBlogPostJson = (
  bodyJson: any,
  editorType: string
) => {
  //returns string - image src

  //bodyJson example:
  /*
   *"[
   * {"type":"paragraph","children":[{"text":"sskss"}]},{"url":"https://ucarecdn.com/66c16acb-c385-4a65-940f-31ad4e410c82/cus.jpg", "type":"image","children":[{"text":""}],"fileName":"cus.jpg","imageWidth":1257,"imageHeight":610},
   * {"type":"paragraph","children":[{"text":""}]},{"url":"https://ucarecdn.com/0eacea12-8135-4228-9fd9-20736e962551/-----many-y sd dsa. ._head.png", "type":"image","children":[{"text":""}],"fileName":"-----many-y sd dsa. ._head.png"},
   * {"type":"paragraph","children":[{"text":""}]},
   * {"type":"paragraph","children":[{"text":"yay "}]},{"src":"https://www.youtube.com/embed/BMYZiz4FdjQ", "type":"embed","width":"560","height":"315","children":[{"text":""}]},
   * {"type":"paragraph","children":[{"text":""}]},{"url":"https://ucarecdn.com/a07713c8-6fe4-4fb3-bf0b-343fc2db4162/anon.png", "type":"image","children":[{"text":""}],"fileName":"anon.png"},
   * {"type":"paragraph","children":[{"text":"Some "},{"bold":true,"text":"pretty text "},{"text":"for exceprt"}]}
   * ]"
   * */

  const firstImageObject = {
    url: "", //default value is '' (set in model)
    width: null,
    height: null,
  };
  let blocksArray = bodyJson; // For slatejs
  if (editorType === "editorjs") {
    blocksArray = _.get(bodyJson, "blocks"); // For editorjs
  }
  if (!blocksArray || !Array.isArray(blocksArray)) {
    return firstImageObject;
  }

  //iterate through the array
  for (let i = 0; i < blocksArray.length; i++) {
    if (editorType === "slatejs") {
      if (
        blocksArray[i].type === "image" && //we need images only
        blocksArray[i].url &&
        blocksArray[i].url !== "" && //the image must have url set
        blocksArray[i].imageWidth &&
        blocksArray[i].imageWidth > 100 && //it should not be too small (e.g. emoji images or decorative horizontal lines are bad first images)
        blocksArray[i].imageHeight &&
        blocksArray[i].imageHeight > 100
      ) {
        firstImageObject.url = blocksArray[i].url;
        break;
      }
    } else if (editorType === "editorjs") {
      if (
        blocksArray[i].type === "image" //we need images only
      ) {
        firstImageObject.url = formatImageUrl(blocksArray[i].data.file.url);
        firstImageObject.width = blocksArray[i].data.file.width;
        firstImageObject.height = blocksArray[i].data.file.height;
        break;
      }
    }
  }
  // if first appeared image has >100px height and >100px width -> store as first image
  return firstImageObject;
};
