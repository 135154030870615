import { useEffect } from "react";

export const useDevKeyboardShortcuts = (
  pageComponentsArray: any,
  currentWebsite
) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "'") {
        // console.log("First component", pageComponentsArray[0]["componentData"]);
      }
      if (event.key === "\\") {
        // console.log("Components array", pageComponentsArray);
      }
      if (event.key === ";") {
        // console.log("Nav", currentWebsite.nav);
        // console.log("Footer", currentWebsite.footer);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};
