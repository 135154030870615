import React, { Component } from "react";
import "./AccountSettings.css";
import { Alert, Button, Icon, Tabs, Tooltip } from "antd";
import Plans from "../../account/plan/Plans";
import SpinnerBox from "../../SpinnerBox";
import BillingData from "../billing_data/BillingData";
import { distanceInWordsToNow, format } from "date-fns";
import { openChatWidget } from "../../../../helpers/editor/openChatWidget";
import AccountFields from "../account/AccountFields";
import ApplicationsMenu from "../applications/ApplicationsMenu";
import { getCurrentPlan } from "../../../../helpers/plans/getCurrentPlan";
import capitalizeString from "../../../../helpers/strings/capitalizeString";
import {
  appsumoPlanTitle,
  freePlanTitle,
  ltdPlanTitle,
} from "../../../../data/constants";
import { connect } from "react-redux";
import { toggleProcessingSystemReady } from "../../../../store/payment/actions";
import {
  toggleCancellingPro,
  fetchCurrentUserInfo,
  toggleUserPro,
  toggleSwitchingProPlanInProgress,
  toggleUpgradingFromFreeToProInProgress,
} from "../../../../store/user/actions";
import { ActiveMenu } from "../../SettingsDrawerContent";
import SettingsBox from "../../SettingsBox";

interface Props {
  meta: any;
  user: any;
  plans: any;
  auth: any;
  pageComponents: any;
  payment: any;
  toggleCancellingPro: any;
  fetchCurrentUserInfo: any;
  toggleUserPro: any;
  toggleProcessingSystemReady: any;
  toggleSwitchingProPlanInProgress: any;
  toggleUpgradingFromFreeToProInProgress: any;

  history: any;
  currentMenuTitle: ActiveMenu;
}

class AccountSettings extends Component<Props> {
  state = {
    isChatLoading: false,
  };
  openChat() {
    this.setState({
      isChatLoading: true,
    });
    (window as any).$crisp.push([
      "on",
      "chat:opened",
      () => {
        this.setState({
          isChatLoading: false,
        });
      },
    ]);
    // (window as any).Intercom('onShow', () => {
    //     this.setState({
    //         isChatLoading: false,
    //     });
    // });

    openChatWidget();
  }
  displayPayment = () => {
    if (
      this.props.plans.dataFetched === false ||
      this.props.user.dataFetched === false
    ) {
      return <SpinnerBox text={"Payment data is being fetched..."} />;
    } else {
      return <BillingData plans={this.props.plans} user={this.props.user} />;
    }
  };
  displayCanceledSubscriptionInfo = () => {
    const { user, plans } = this.props;
    if (!user.dataFetched || !plans.dataFetched) {
      return null;
    }
    let userSubscriptionStatus = this.props.user.data
      .paddle_subscription_status;
    let nowDateObject = new Date(Date.now());

    if (userSubscriptionStatus === "deleted") {
      //https://developer.paddle.com/reference/platform-parameters/event-statuses
      // status == 'deleted' when a user canceled thier susbcription.
      // we need to keep the susbcription active till the end of the paid period. We check if today is later than userCancellationEffectiveDate.

      // if today is earlier than userCancellationEffectiveDate, display a banner with the date. The user will therefore know how long they can use the platform.
      let userCancellationEffectiveDate = this.props.user.data
        .cancellation_effective_date;
      let userCancellationEffectiveDateDateObject = new Date(
        userCancellationEffectiveDate
      );

      let isCancellationEffectiveDateInFuture =
        userCancellationEffectiveDateDateObject >= nowDateObject;
      let distanceFromNowInWords = distanceInWordsToNow(
        userCancellationEffectiveDateDateObject,
        { addSuffix: true }
      );

      const currentPlan = getCurrentPlan(user, plans);
      const capitalizedPlanTitle = capitalizeString(currentPlan.title);
      const planPeriod = currentPlan.period;

      const isCancellationAlertVisible =
        isCancellationEffectiveDateInFuture &&
        currentPlan.title !== freePlanTitle &&
        currentPlan.title !== ltdPlanTitle &&
        currentPlan.title !== appsumoPlanTitle;

      if (isCancellationAlertVisible) {
        let message = (
          <div>
            You will not be charged anymore. The cancellation will come into
            effect{" "}
            <b>
              {distanceFromNowInWords}
              <Tooltip
                title={format(userCancellationEffectiveDate, "Do MMMM YYYY")}
              >
                <Icon style={{ marginLeft: 3 }} type="calendar" />
              </Tooltip>
            </b>{" "}
            We took your most recent payment into account. <br /> If you wish to
            continue using Unicorn Platform after that, kindly re-upgrade to a
            paid plan.
          </div>
        );
        return (
          <Alert
            style={{ maxWidth: 650, marginBottom: 20, marginTop: 20 }}
            message={
              <div>
                Your "{capitalizedPlanTitle} {planPeriod}" subscription was
                canceled{" "}
              </div>
            }
            showIcon
            type="warning"
            description={message}
            closable
          />
        );
      } else {
        return null;
      }
      // if today is later userCancellationEffectiveDate, display no info. The user is a regular free user now.
    } else if (userSubscriptionStatus === "past_due") {
      // 'past_due' status is set when we fail to charge user.
      // we send such users 3 warnings and then cancel their subscription and deactivate their websites manually.
      // to get from the 'past_due' status a user must change their payment details or refill the balance and ask us to re-try to charge #https://paddle.com/blog/succeeding-with-subscriptions/#:~:text=When%20a%20subscriber%20with%20the,method%2C%20they%20are%20billed%20immediately.&text=Paddle%20allows%20you%20to%20set,this%20in%20Vendor%20Settings%20%3E%20Subscriptions.

      // let userNextPaymentDate = this.props.user.data.next_payment_date;
      // let userNextPaymentDateObject = new Date(userNextPaymentDate);

      // let isNextPaymentDateInFuture = userNextPaymentDateObject >= nowDateObject;
      // let distanceFromNowInWords = distanceInWordsToNow(userNextPaymentDateObject, {addSuffix: true});

      // if(isNextPaymentDateInFuture){
      let message = (
        <div>
          <p>
            This could happen because of reasons: the card ran out of funds, the
            bank declined the transaction, the card was blocked etc.
          </p>
          <p>Your websites are in danger of being downgraded now.</p>
          <p>
            If you wish to continue using Unicorn Platform, please do one of the
            following actions:
            <br />
            1. Update{" "}
            <a
              target="_blank"
              href={this.props.user.data.paddle_subscription_update_billing_url}
            >
              payment details
              <Icon style={{ marginLeft: "2px" }} type="link" />
            </a>
            .
            <br />
            2. Or{" "}
            {
              <Button
                size="small"
                type="default"
                onClick={() => {
                  this.openChat();
                }}
                className=""
              >
                contact us{" "}
                <Icon
                  style={{ marginLeft: "4px" }}
                  type={this.state.isChatLoading ? "loading" : "message"}
                />
              </Button>
            }{" "}
            if you need more details or live support.
          </p>
        </div>
      );
      return (
        <Alert
          style={{ maxWidth: 650, marginBottom: 20, marginTop: 20 }}
          message={<div>We failed to charge your bank account</div>}
          showIcon
          type="error"
          description={message}
          closable
        />
      );
      // }else{
      //   return null;
      // }
    }
  };
  displayPlans = () => {
    if (
      this.props.plans.dataFetched === false ||
      this.props.user.dataFetched === false
    ) {
      return <SpinnerBox text={"Account data will be here in a moment..."} />;
    } else {
      return (
        <div className="account-settings__item">
          <Plans
            meta={this.props.meta}
            plans={this.props.plans}
            user={this.props.user}
            fetchCurrentUserInfo={this.props.fetchCurrentUserInfo}
            toggleUserPro={this.props.toggleUserPro}
            toggleCancellingPro={this.props.toggleCancellingPro}
            auth={this.props.auth}
            toggleProcessingSystemReady={this.props.toggleProcessingSystemReady}
            payment={this.props.payment}
            toggleSwitchingProPlanInProgress={
              this.props.toggleSwitchingProPlanInProgress
            }
            toggleUpgradingFromFreeToProInProgress={
              this.props.toggleUpgradingFromFreeToProInProgress
            }
            pageComponents={this.props.pageComponents}
          />
        </div>
      );
    }
  };

  render() {
    let userEmail = "";
    if (this.props.user.dataFetched) {
      userEmail = this.props.user.data.email;
    }

    const { currentMenuTitle } = this.props;

    if (currentMenuTitle === "account") {
      return (
        <div className="settings__container">
          <SettingsBox titleText="My account">
            <AccountFields user={this.props.user} auth={this.props.auth} />
          </SettingsBox>
        </div>
      );
    }
    if (currentMenuTitle === "account__plan") {
      return (
        <div className="settings__container">
          <SettingsBox titleText="Upgrade">
            {this.displayCanceledSubscriptionInfo()}
            {this.displayPlans()}
          </SettingsBox>
        </div>
      );
    }
    if (currentMenuTitle === "account__billing") {
      return (
        <div className="settings__container">
          <SettingsBox titleText="Billing">{this.displayPayment()}</SettingsBox>
        </div>
      );
    }
    if (currentMenuTitle === "account__applications") {
      return (
        <div className="settings__container">
          <SettingsBox titleText="Connections">
            <ApplicationsMenu />
          </SettingsBox>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: any) => {
  return {
    meta: state.meta,
    user: state.user,
    plans: state.plans,
    auth: state.auth,
    pageComponents: state.pageComponents,
    payment: state.payment,
  };
};
const mapActionsToProps = {
  toggleCancellingPro,
  fetchCurrentUserInfo,
  toggleUserPro,
  toggleProcessingSystemReady,
  toggleSwitchingProPlanInProgress,
  toggleUpgradingFromFreeToProInProgress,
};
export default connect(mapStateToProps, mapActionsToProps)(AccountSettings);
