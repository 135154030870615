import React from "react";
import { Button } from "antd";
import { ReactComponent as MaterialWandSm } from "../../../img/icons/material_wand_sm.svg";
import { PosthogEvents } from "../../../enums/AnalyticsEventsEnums";
import _ from "lodash";

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
}

const GenerateButton = (props: Props): JSX.Element => {
  const { children, isLoading } = props;

  return (
    <Button
      type="primary"
      className="fill-template-modal__generate-button"
      htmlType="submit"
      form="fill-template-form"
      loading={isLoading}
    >
      {!isLoading && (
        <MaterialWandSm className="fill-template-modal__generate-button-icon" />
      )}
      {children}
    </Button>
  );
};

export default GenerateButton;
