import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "./returnAuthHeaderForAJAX";

interface Data {
  id: number;
}
interface ResponseData {
  success: boolean;
}
export const deleteOauth2AccessTokenAsync = (
  accessToken: string,
  data: Data
): Promise<AxiosResponse<ResponseData>> => {
  return new Promise((resolve, reject) => {
    axios
      .post(api.oauth2.deleteAccessToken, data, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
