export const presets = {
  improveWriting:
    "Improve writing in all text elements. Replace placeholder content with meaningful relevant content.",

  toneProfessional: "Rewrite all text elements in a more professional tone.",
  toneFriendly: "Rewrite all text elements in a more friendly tone.",
  toneConfident: "Rewrite all text elements in a more confident tone.",
  toneCasual: "Rewrite all text elements in a more casual tone.",
  toneStraightforward:
    "Rewrite all text elements in a more straightforward tone.",

  grammar: "Fix spelling and grammar in all text elements.",

  translateEnglish: "Translate all text elements to English.",
  translateSpanish: "Translate all text elements to Spanish.",
  translateFrench: "Translate all text elements to French.",
  translateJapanese: "Translate all text elements to Japanese.",
  translateHindi: "Translate all text elements to Hindi.",
  translateGerman: "Translate all text elements to German.",
  translatePortuguese: "Translate all text elements to Portuguese.",
  translateChinese: "Translate all text elements to Chinese.",
  translateChineseTraditional: "Translate all text elements to Traditional Chinese.",
  translateMalay: "Translate all text elements to Malay.",
  translateRussian: "Translate all text elements to Russian.",
  translateKorean: "Translate all text elements to Korean.",
  translateItalian: "Translate all text elements to Italian.",
  translateVietnamese: "Translate all text elements to Vietnamese.",

  emojis: "Add a few emojis to the component to lighten the mood a bit.",

  longer: "Rewrite all text elements to be longer.",
  shorter: "Rewrite all text elements to be shorter.",

  randomize: {
    poem: "Rewrite all text elements to turn this page component into a poem.",
    yoda: "All text elements in the style of Yoda rewrite.",
    shakespeare: "Rewrite all text elements in the style of Shakespeare.",
    pirate: "Rewrite all text elements in the style of a pirate.",
    snoop: "Rewrite all text elements in the style of Snoop Dogg.",
    wwe:
      "Rewrite all text elements in the style of a very passionate WWE wrestler.",
    marvin:
      "Rewrite all text elements in the style of Marvin the Paranoid Android.",
  },
};

export const presetsHumanTitles = {
  improveWriting: "Improve writing",
  toneProfessional: "Change tone to professional",
  toneFriendly: "Change tone to friendly",
  toneConfident: "Change tone to confident",
  toneCasual: "Change tone to casual",
  toneStraightforward: "Change tone to straightforward",
  grammar: "Fix grammar",
  translateEnglish: "Translate to English",
  translateSpanish: "Translate to Spanish",
  translateFrench: "Translate to French",
  translateJapanese: "Translate to Japanese",
  translateHindi: "Translate to Hindi",
  translateGerman: "Translate to German",
  translatePortuguese: "Translate to Portuguese",
  translateChinese: "Translate to Chinese",
  translateChineseTraditional: "Translate to Traditional Chinese",
  translateMalay: "Translate to Malay",
  translateRussian: "Translate to Russian",
  translateKorean: "Translate to Korean",
  translateItalian: "Translate to Italian",
  translateVietnamese: "Translate to Vietnamese",
  emojis: "Add emojis",
  longer: "Make longer",
  shorter: "Make shorter",
  randomize: "Randomize",
};
