import { Components } from "../../../../enums/Components";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";

export enum GraphicsOptions {
  image = "image",
  video = "video",
  HTML = "HTML",
}

export const contentTypesWithGraphicsOptions: ContentInputsTypesEnum[] = [
  ContentInputsTypesEnum.graphics,
  ContentInputsTypesEnum.steps,
  ContentInputsTypesEnum.gallery,
];

export const componentsWithGraphicsOptions: Components[] = [
  Components.header23,
  Components.header61,
  Components.header60,
  Components.header63,
  Components.header64,
  Components.header62,
  Components.header65,
  Components.header39,
  Components.header35,

  Components.features06,
  Components.features07,

  Components.popups01,

  Components.faq03,

  Components.steps01,

  Components.slider01,
  Components.slider05,
  Components.slider07,
  Components.slider10,

  Components.tabs01,
  Components.tabs03,
  Components.tabs04,
  Components.tabs10,
];

type AvailableGraphicsOptionExceptions = {
  [key in Components]?: GraphicsOptions[];
};
export const availableGraphicsOptionExceptions: AvailableGraphicsOptionExceptions = {};
