import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import getLightboxClassname from "../../helpers/editor/getLightboxClassname";
import { pictogramOptions } from "../../data/pictogramOptions";
import _ from "lodash";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import GraphicsJSX from "../GraphicsJSX";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Emoji from "../component_molecules/Emoji";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  displayButtons: any;
  isDarkBg: boolean;
}

class Steps01 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({ titleText: "How It Works", isDefaultEmpty })
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({ subtitleText: "", isDefaultEmpty })
      );

      this.props.saveContentInStore(
        this.generator.setUpSteps({
          hasTitle: true,
          hasSubtitle: false,
          hasText: true,
          hasButton: true,
          hasImage: true,
          isDefaultEmpty
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          buttons: [
            // ghost object
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.ghost.typeTitle,
              buttonColor: buttonStyles.ghost.accentOutline,
              buttonIsTargetBlank: false,
              pillPrefix: "View",
            }),
          ],
          priority: 200,
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpPictograms({
          priority: 300,
          items: [
            {
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f917.svg",
              }),
            },
          ],
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts

    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let steps = this.props.componentItem.componentData.steps.content.items;

    let pictogram = undefined;
    if (
      this.props.componentItem.componentData.pictograms &&
      this.props.componentItem.componentData.pictograms.content &&
      this.props.componentItem.componentData.pictograms.content.items[0] &&
      this.props.componentItem.componentData.pictograms.content.items[0]
        .pictogram
    ) {
      pictogram = this.props.componentItem.componentData.pictograms.content
        .items[0].pictogram;
    }
    const currentActiveOption = _.get(
      this.props.componentItem,
      ["componentData", "steps", "activeOption"],
      GraphicsOptions.image
    );

    return (
      <section>
        <div
          className={`steps-01 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image}`}
        >
          <TitleBox 
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />
          <div className="container">
            <ul className="steps-01__list">
              {steps &&
                steps.map((item: any, key: number) => {
                  let lightBoxClassname = getLightboxClassname(
                    item.graphics.image.width
                  );
                  const videoUrl = _.get(item, [
                    "graphics",
                    GraphicsOptions.video,
                    "youtube",
                    "url",
                  ]);
                  const codeSnippet = _.get(item, [
                    "graphics",
                    GraphicsOptions.HTML,
                    "codeSnippet",
                  ]);

                  if (item !== undefined && item.title !== "") {
                    return (
                      <li className="steps-01__item" key={"steps-01-" + key}>
                        <div className="steps-01__number color-main">
                          {key + 1}
                        </div>
                        <div className="steps-01__content">
                          <div className="steps-01__text_box">
                            <h2
                              className={
                                "title-text--inner steps-01__heading " +
                                (this.props.isDarkBg ? "text-white" : "")
                              }
                              dangerouslySetInnerHTML={{ __html: item.title }}
                            />
                            <div
                              className={
                                "content_box " +
                                (this.props.isDarkBg ? "text-white" : "")
                              }
                            >
                              <p
                                className={
                                  "content-text steps-01__text " +
                                  (this.props.isDarkBg ? "text-white" : "")
                                }
                                dangerouslySetInnerHTML={{ __html: item.text }}
                              />
                            </div>
                            {item.button && item.button.title !== "" && (
                              <div className="feature__button_box">
                                <WebsiteButton config={{
                                  buttonStyleType:
                                    item.button.settings.appearance.styleType,
                                  buttonColor:
                                    item.button.settings.appearance.color,
                                  buttonType: linkOrButtonOptions.link,
                                  buttonTitle: item.button.title,
                                  buttonHref: item.button.href,
                                  buttonIsTargetBlank:
                                    item.button.isTargetBlank,
                                  buttonMobileAppStoreType:
                                    item.button.settings.appearance
                                      .mobileAppStoreType,
                                  pillPrefix: item.button.pillPrefix,
                                  pillColor:
                                    item.button.settings.appearance.pillColor,
                                  ghostColor:
                                    item.button.settings.appearance.ghostColor,
                                }} />
                              </div>
                            )}
                          </div>
                          <div className="steps-01__img_box">
                            <GraphicsJSX
                              currentActiveOption={currentActiveOption}
                              defaultGraphicsOption={GraphicsOptions.image}
                              classNames={{
                                image: `steps-01__img ${lightBoxClassname}`,
                                video: "steps-01__video",
                                HTML: "steps-01__HTML",
                              }}
                              isDarkBg={this.props.isDarkBg}
                              imageUrl={item.graphics.image.url}
                              videoUrl={videoUrl}
                              codeSnippet={codeSnippet}
                              imageDataWidth={item.graphics.image.width || 1440}
                              imageDataHeight={
                                item.graphics.image.height || 900
                              }
                            />
                          </div>
                        </div>
                      </li>
                    );
                  } else {
                    return null;
                  }
                })}
            </ul>
            <div className="steps-01__last_bubble">
              {pictogram ? (
                <Pictogram
                  pictogramObject={pictogram}
                  className="steps-01__last_bubble_img"
                />
              ) : (
                <Emoji
                  emojiSrc="1f917.svg"
                  className="steps-01__last_bubble_img"
                  alt="Emoji happy"
                  height={30}
                />
              )}
            </div>

            <div className="bottom_cta">
              {this.props.componentItem.componentData.cta &&
                <WebsiteCTA componentItem={this.props.componentItem} />}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Steps01;
