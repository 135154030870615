import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { postThumbnailAltUpdateWaitingForServer } from "../../store/blogPosts/actions";
import { compareEditedValueWithCurrent } from "../../helpers/editor/compareEditedValueWithCurrent";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import { openNotification } from "../../helpers/openNotification";
import { api } from "../../data/urls";
import { Button, Input } from "antd";
import axios from "axios";
import _ from "lodash";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import trimStringTo from "../../helpers/strings/trimStringTo";

interface Props {
  currentBlogPost: any;
  currentWebsite: any;
  currentBlog: any;
  saveBlogPostDataInStore: (
    response: any,
    currentBlog: any,
    blogPosts: any,
    blogPostUrl: string
  ) => void;

  auth: any;
  blogPosts: any;
  postThumbnailAltUpdateWaitingForServer: (payload: boolean) => void;
}

const UpdatePostThumbnailAlt = (props: Props): JSX.Element => {
  const {
    currentBlogPost,
    auth,
    currentWebsite,
    currentBlog,
    saveBlogPostDataInStore,
    blogPosts,
    postThumbnailAltUpdateWaitingForServer,
  } = props;
  const savedAlt: string = currentBlogPost.thumbnail_alt;
  const [currentAlt, setCurrentAlt] = useState(savedAlt);
  useEffect(() => {
    setCurrentAlt(savedAlt);
  }, [currentBlogPost]);

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    postThumbnailAltUpdateWaitingForServer(true);

    const apiUrl = `${api.blogPosts.updateBlogPost.prefix}${currentWebsite.subdomain}/${currentBlogPost.url}${api.blogPosts.updateBlogPost.postfix}`;

    axios
      .patch(
        apiUrl,
        { thumbnail_alt: currentAlt },
        { ...returnAuthHeaderForAJAX(auth.accessToken) }
      )
      .then((response) => {
        saveBlogPostDataInStore(
          response,
          currentBlog,
          blogPosts.items,
          currentBlogPost.url
        );

        const successMessage = !!currentAlt ? (
          <span>
            Successfully changed the thumbnail alt to <b>{currentAlt}</b>.
          </span>
        ) : (
          <span>Successfully removed the thumbnail alt. Now it's blank.</span>
        );

        fireAnalyticsEvent.fireCrisp(CrispEvents.setThumbnailAlt, {
          blog_id: currentBlog.id,
          post: currentBlogPost.url,
          new_alt: trimStringTo(currentAlt, 100),
        });
      })
      .catch((error) => {
        const errorMessages = {
          detail: _.get(error, "response.data.detail"),
          locked: _.get(error, "response.data.locked"),
        };

        if (errorMessages.detail) {
          openNotification(
            "Server error",
            `Error message: "${errorMessages.detail}" This should not have happened. Please contact us.`,
            "OK",
            "error"
          );
          fireAnalyticsEvent.fireCrisp(
            CrispEvents.setThumbnailAltError,
            {
              blog_id: currentBlog.id,
              post: currentBlogPost.url,
              new_alt: trimStringTo(currentAlt, 100),
              error_message: errorMessages.detail,
            },
            true
          );
          return;
        }

        if (errorMessages.locked) {
          openNotification("Denied", errorMessages.locked, "OK", "warn");
          fireAnalyticsEvent.fireCrisp(
            CrispEvents.setThumbnailAltError,
            {
              blog_id: currentBlog.id,
              post: currentBlogPost.url,
              new_alt: trimStringTo(currentAlt, 100),
              error_message: "Locked",
            },
            true
          );
          return;
        }

        openNotification(
          "Server error",
          "This should not have happened. Please contact us.",
          "OK",
          "error"
        );
      })
      .then(() => {
        postThumbnailAltUpdateWaitingForServer(false);
      });
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentAlt(event.target.value);
  };

  return (
    <form className="settings-box__form" onSubmit={handleOnSubmit}>
      <div className="settings-box__input_box">
        <Input
          name="thumbnail_alt"
          id="thumbnail_alt"
          placeholder="Explain the image"
          type="text"
          onChange={handleOnChange}
          value={currentAlt}
          maxLength={200}
        />
      </div>
      <Button
        className="settings-box__form_save"
        icon="save"
        type="primary"
        htmlType="submit"
        loading={blogPosts.isWaitingForPostThumbnailAltUpdateResponse}
        hidden={compareEditedValueWithCurrent(savedAlt, currentAlt)}
      />
    </form>
  );
};

const mapStateToProps = ({ auth, blogPosts }) => {
  return {
    auth,
    blogPosts,
  };
};
const mapActionsToProps = {
  postThumbnailAltUpdateWaitingForServer,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(UpdatePostThumbnailAlt);
