export const getCurrentBlogPost = (
  currentBlogPostUrl: any,
  blogPostsItems: any,
  currentBlog: any
) => {
  if (!currentBlog) return undefined;
  let currentBlogPost = blogPostsItems.find(
    (blogPost: any) =>
      blogPost.url === currentBlogPostUrl && blogPost.blog === currentBlog.id
  );
  return currentBlogPost;
};
