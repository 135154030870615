import React from 'react';
import { Button } from 'antd';
import EmptyImage from "../../../img/other/empty.svg";

interface Props {
    buttonText: string;
    onClick: () => void;
};

class IntegrationsEmpty extends React.Component<Props> {
    render() {
        return (
            <div className="content-input__integrations_empty">
                <img src={EmptyImage} alt="Empty bin image" style={{ marginBottom: '10px' }} />
                <br />
                <div style={{}}>{this.props.children}</div>
                <br />
                <Button type="default" onClick={this.props.onClick} htmlType="button" size="small" icon="plus">{this.props.buttonText}</Button>
            </div>
        );
    }
}

export default IntegrationsEmpty;

