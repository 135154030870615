import React from "react";
import { Alert, Icon } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

interface Props {
  user: any;
}

const DuePaymentAlert = (props: Props): JSX.Element => {
  const { user } = props;

  if (
    user.dataFetched &&
    user.data &&
    user.data.paddle_subscription_status &&
    user.data.paddle_subscription_status == "past_due"
  ) {
    return (
      <div>
        <Alert
          style={{ maxWidth: "600px", margin: "50px auto 0" }}
          closable
          message="We failed to change your bank account"
          description={
            <div>
              If you want to keep your websites active, kindly navigate to your account settings now to view possible solutions.
            </div>
          }
          type="error"
          showIcon
        />
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = ({ user }) => {
  return {
    user,
  };
};
export default connect(mapStateToProps, null)(DuePaymentAlert);
