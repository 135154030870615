export const pushFromItem = (itemsArray: any[], currentItem) => {
  currentItem.POSITION_IN_ARRAY += 1;
  itemsArray.forEach((item) => {
    if (
      item.POSITION_IN_ARRAY === currentItem.POSITION_IN_ARRAY &&
      item.READONLY_ID !== currentItem.READONLY_ID
    ) {
      pushFromItem(itemsArray, item);
    }
  });
};
