import {indexWebsitePageDashboardUrl} from "../data/constants";

export const getCurrentWebsitePageUrl = (currentWebsite: any, websitePagesItems: any) => {
    // This function checks if it's an url of a real website page of just a utility page (e.g. '/settings' or '/account').
    // Please pass the currentWebsite in the arguments to validate.

    let pathArray = location.pathname.split('/');
    const pageId = parseInt(pathArray[pathArray.length - 1]);
    
    if (!pageId) return undefined;

    // See if this url belongs to a real website page of our currentWebsite.

    if(currentWebsite === undefined){
        return undefined;
    }
    // Find all pages of the given website.
    let currentWebsiteId = currentWebsite.id;

    let currentWebsitePages = websitePagesItems.filter((item:any) => {
        return (item.website === currentWebsiteId)
    });

    // Find the page with the URL from the browser address bar.
    let currentWebsitePage = currentWebsitePages.find((item:any) => {
        return (item.id === pageId)
    });
    
    if(currentWebsitePage === undefined){
        return undefined;
    }else{
        return currentWebsitePage.url;
    }
};
