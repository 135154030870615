import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import { WebsitePagesItem } from "../../store/websitePages/types";
import { indexWebsitePageDashboardUrl } from "../../data/constants";

type UpdatePageAsync = (payload: {
  accessToken: string;
  subdomain: string;
  pageUrl: string;
  data: Partial<WebsitePagesItem>;
}) => Promise<AxiosResponse<any>>;

export const updatePageAsync: UpdatePageAsync = ({
  accessToken,
  subdomain,
  data,
  pageUrl,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl =
      api.websitePages.updateWebsitePage.prefix +
      subdomain +
      "/" +
      (pageUrl || indexWebsitePageDashboardUrl) +
      api.websitePages.updateWebsitePage.postfix;

    axios
      .patch(
        apiUrl,
        { ...data, subdomain },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
