import { buttonStyles } from "../../../../data/buttonStyles";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteEmptyItems } from "./updaterFunctions.ts/deleteEmptyItems";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemText = stringifyObjectsAndNumbers(modifiedItem.TEXT) || "";
  const itemUrl = stringifyObjectsAndNumbers(modifiedItem.URL) || "";
  const itemTarget =
    stringifyObjectsAndNumbers(modifiedItem.TARGET) || "_self";
  return {
    itemId,
    itemPosition,
    itemText,
    itemUrl,
    itemTarget,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemText,
  itemUrl,
  itemTarget
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.cta.content.buttons.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.cta.content.buttons, item);
      }
    });
  }
  if ("TEXT" in modifiedItem) {
    originalItem.title = itemText;
  }
  if ("URL" in modifiedItem) {
    originalItem.href = itemUrl;
  }
  if ("TARGET" in modifiedItem) {
    originalItem.isTargetBlank = itemTarget === "_blank";
  }
};

export const updateCtaButtons = (componentData, completionContent) => {
  if (!("CTA" in completionContent)) {
    return;
  }

  if (!isKeyValue(completionContent.CTA)) {
    if (completionContent.CTA === null) {
      componentData.cta.content.buttons = [];
    }
    return;
  }

  if (!("CTA_BUTTONS" in completionContent.CTA)) {
    return;
  }

  if (!Array.isArray(completionContent.CTA.CTA_BUTTONS)) {
    if (completionContent.CTA.CTA_BUTTONS === null) {
      componentData.cta.content.buttons = [];
    }
    return;
  }

  componentData.cta.content.buttons = deleteEmptyItems(
    componentData.cta.content.buttons,
    "title"
  );

  addDefaultValuesToItems(componentData.cta.content.buttons);

  completionContent.CTA.CTA_BUTTONS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }

    const {
      itemId,
      itemPosition,
      itemText,
      itemUrl,
      itemTarget,
    } = particleGetModifiedValues(modifiedItem);

    if (itemPosition === null) {
      componentData.cta.content.buttons = deleteItem(
        componentData.cta.content.buttons,
        itemId
      );
      return;
    }

    const isItemNew = getIsItemNew(componentData.cta.content.buttons, itemId);

    if (isItemNew) {
      const newItem = generator.createButtonContentObject();
      newItem.title = "";
      newItem.href = "";
      newItem.settings.appearance.styleType = buttonStyles.regular.typeTitle;
      newItem.settings.appearance.color = buttonStyles.regular.accentOutline;
      addNewItem(
        componentData.cta.content.buttons,
        newItem,
        itemId,
        itemPosition
      );
    }

    const originalItem = getOriginalItem(
      componentData.cta.content.buttons,
      itemId
    );

    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemText,
      itemUrl,
      itemTarget
    );
  });

  sortItemsByPosition(componentData.cta.content.buttons);
};
