import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import NavDropdown from "./NavDropdown";
import "./NavDropdownItems.css";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import { GetCurrentStoreData } from "../../store/websites/types";
import _ from "lodash";

interface Props extends RouteComponentProps {
  websites: any;
  blogs: any;
  websitesPages: any;
  blogPosts: any;
  updateActiveDashboardTab: any;
  publishPage: any;
  publishPost: any;

  getCurrentStoreData: GetCurrentStoreData;
}

class NavDropdownItems extends Component<Props> {
  displayWebsitesNav(
    currentWebsite: any,
    currentWebsitePage: any,
    currentBlogPost: any
  ) {
    const websitesArray = _.get(this.props.websites, "items", []);
    if (websitesArray.length === 0) return null;
    return (
      <span>
        <NavDropdown
          navType="websites"
          currentWebsitePage={currentWebsitePage}
          currentWebsite={currentWebsite}
          currentBlogPost={currentBlogPost}
          publishPage={this.props.publishPage}
          publishPost={this.props.publishPost}
        />
      </span>
    );
  }

  displayWebsitePagesNav(
    currentWebsite: any,
    currentWebsitePage: any,
    currentBlogPost: any
  ) {
    if (!currentWebsite) return null;
    return (
      <span>
        <span className="nav-dropdown-items__nav_divider">/</span>
        <NavDropdown
          navType="pages"
          currentWebsitePage={currentWebsitePage}
          currentWebsite={currentWebsite}
          currentBlogPost={currentBlogPost}
          publishPage={this.props.publishPage}
          publishPost={this.props.publishPost}
        />
      </span>
    );
  }

  render() {
    if (
      this.props.websites.dataFetched &&
      this.props.websitesPages.pagesWithoutComponentsFetched &&
      this.props.blogs.dataFetched &&
      this.props.blogPosts.allPostsWithoutContentFetched
    ) {
      const { currentWebsite, currentWebsitePage, currentBlogPost } =
        this.props.getCurrentStoreData();

      const fillTemplateProgress = _.get(
        currentWebsite,
        "fillTemplateData.status"
      );

      if (fillTemplateProgress === "inProgress") return null;

      return (
        <div className="nav-dropdown-items">
          {this.displayWebsitesNav(
            currentWebsite,
            currentWebsitePage,
            currentBlogPost
          )}
          {this.displayWebsitePagesNav(
            currentWebsite,
            currentWebsitePage,
            currentBlogPost
          )}
        </div>
      );
    }
    return null;
  }
}

export default connect(null, {
  getCurrentStoreData,
})(withRouter(NavDropdownItems));
