import { Components } from "../enums/Components";

export const getComponentFromId = (componentId: string) => {
  return componentId
    .replace("#", "")
    .toLowerCase()
    .split("-")
    .slice(0, 2)
    .join("-") as Components;
};
