export const getErrorValue = (errorData:any, errorObjectKey:any) => {
    try{
        if(errorObjectKey !== undefined && errorData[errorObjectKey] !== undefined){
            return JSON.stringify(errorData[errorObjectKey]);
        }else{
            return 'error object is empty';
        }
    }catch (e) {
        return 'error object is not empty, but failed to extract data';
    }
};