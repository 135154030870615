import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";

interface DefaultEditorData {
  time: number;
  blocks: any[];
  version: string;
}
type CreatePostAsync = (payload: {
  accessToken: string;
  subdomain: string;
  newPostUrl: string;
  blogId: number;
  body_json: DefaultEditorData;
  published_on: string;
}) => Promise<AxiosResponse<any>>;

export const createPostAsync: CreatePostAsync = ({
  accessToken,
  subdomain,
  newPostUrl,
  blogId,
  body_json,
  published_on,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${api.blogPosts.createBlogPost.prefix}${subdomain}${api.blogPosts.createBlogPost.postfix}`;
    axios
      .post(
        apiUrl,
        {
          url: newPostUrl,
          subdomain,
          blog: blogId,
          body_json,
          published_on,
          editor_type: "editorjs",
        },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
