export const mockups = {
  categories: {
    phones: [
      {
        humanTitle: "iPhone 15 Pro",
        id: "iphone_15_pro",
        resolution: "390x844",
        options: [
          {
            id: "natural",
            color: "#D5D0C4",
            fileName: "iphone_15_pro_max_natural.png",
          },
          {
            id: "black",
            color: "#494A4C",
            fileName: "iphone_15_pro_max_black.png",
          },
          {
            id: "white",
            color: "#E6E4DD",
            fileName: "iphone_15_pro_max_white.png",
          },
          {
            id: "blue",
            color: "#58637B",
            fileName: "iphone_15_pro_max_blue.png",
          },
        ],
      },
      {
        humanTitle: "iPhone 13 Pro",
        id: "iphone_13_pro",
        resolution: "390x844",
        options: [
          {
            id: "gold",
            color: "#FAE7CF",
            fileName: "apple_iphone_13_pro_gold.png",
          },
          {
            id: "graphite",
            color: "#545250",
            fileName: "apple_iphone_13_pro_graphite.png",
          },
          {
            id: "silver",
            color: "#F1F2EC",
            fileName: "apple_iphone_13_pro_silver.png",
          },
          {
            id: "sierra_blue",
            color: "#A7C0D9",
            fileName: "apple_iphone_13_pro_sierra_blue.png",
          },
        ],
      },
      {
        humanTitle: "iPhone SE 2",
        id: "iphoneapple_iphone_se_2020",
        resolution: "251x444",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "apple_iphone_se_2020_black.png",
          },
          {
            id: "silver",
            color: "#A3A3A3",
            fileName: "apple_iphone_se_2020_silver.png",
          },
          {
            id: "red",
            color: "#9D2630",
            fileName: "apple_iphone_se_2020_red.png",
          },
        ],
      },
      {
        humanTitle: "iPhone 11 Pro Max",
        id: "iphone_11_pro_max",
        resolution: "274x591",
        options: [
          {
            id: "space_grey",
            color: "#303030",
            fileName: "apple_iphone_11_pro_max_space_grey.png",
          },
          {
            id: "silver",
            color: "#A3A3A3",
            fileName: "apple_iphone_11_pro_max_silver.png",
          },
          {
            id: "gold",
            color: "#B4947B",
            fileName: "apple_iphone_11_pro_max_gold.png",
          },
          {
            id: "green",
            color: "#8CBFAB",
            fileName: "apple_iphone_11_pro_max_midnight_green.png",
          },
        ],
      },
      {
        humanTitle: "iPhone XR",
        id: "iphone_xr",
        resolution: "261x565",
        options: [
          {
            id: "space_grey",
            color: "#303030",
            fileName: "apple_iphone_xr_space_grey.png",
          },
          {
            id: "silver",
            color: "#A3A3A3",
            fileName: "apple_iphone_xr_silver.png",
          },
          {
            id: "blue",
            color: "#98BFDB",
            fileName: "apple_iphone_xr_blue.png",
          },
          {
            id: "red",
            color: "#9D2630",
            fileName: "apple_iphone_xr_red.png",
          },
          {
            id: "yellow",
            color: "#E0C998",
            fileName: "apple_iphone_xr_yellow.png",
          },
          {
            id: "coral",
            color: "#E9ABA1",
            fileName: "apple_iphone_xr_coral.png",
          },
        ],
      },
      {
        humanTitle: "iPhone 8 Plus",
        id: "iphone_8_plus",
        resolution: "251x444",
        options: [
          {
            id: "space_grey",
            color: "#303030",
            fileName: "apple_iphone_8_plus_space_grey.png",
          },
          {
            id: "silver",
            color: "#A3A3A3",
            fileName: "apple_iphone_8_plus_silver.png",
          },
          {
            id: "gold",
            color: "#B4947B",
            fileName: "apple_iphone_8_plus_gold.png",
          },
        ],
      },
      {
        humanTitle: "Google Pixel 4 XL",
        id: "pixel_4_xl",
        resolution: "289x612",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "google_pixel_4_xl_just_black.png",
          },
        ],
      },
      {
        humanTitle: "Samsung Galaxy s20 Ultra",
        id: "samsung_galaxy_s20_ultra_cosmic_black",
        resolution: "260x575",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "samsung_galaxy_s20_utra_cosmic_black.png",
          },
        ],
      },
      {
        humanTitle: "Samsung Galaxy Note 10+",
        id: "samsung_galaxy_note10_plus_aura_black",
        resolution: "280x612",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "samsung_galaxy_note10+_aura_black.png",
          },
        ],
      },
      {
        humanTitle: "Xiaomi Mi Mix ⍺",
        id: "xiaomi_mi_mix_alpha_front",
        resolution: "279x579",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "xiaomi_mi_mix_alpha_front.png",
          },
        ],
      },
      {
        humanTitle: "Nokia 3310",
        id: "nokia_3310",
        resolution: "136x98",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "nokia_3310.png",
          },
        ],
      },
      {
        humanTitle: "Empty",
        id: "Empty",
        options: [
          {
            id: "empty",
            color: "empty",
            fileName: "empty.png",
          },
        ],
      }
    ],
    desktops: [
      {
        humanTitle: "MacBook Pro",
        id: "apple_macbook_pro",
        resolution: "864x558",
        options: [
          {
            id: "space_grey",
            color: "#303030",
            fileName: "apple_macbook_pro_space_grey.png",
          },
          {
            id: "silver",
            color: "#A3A3A3",
            fileName: "apple_macbook_pro_silver.png",
          },
        ],
      },
      {
        humanTitle: "MacBook Air",
        id: "apple_macbook_air",
        resolution: "815x510",
        options: [
          {
            id: "space_grey",
            color: "#303030",
            fileName: "apple_macbook_air_space_grey.png",
          },
          {
            id: "silver",
            color: "#A3A3A3",
            fileName: "apple_macbook_air_silver.png",
          },
          {
            id: "gold",
            color: "#B4947B",
            fileName: "apple_macbook_air_gold.png",
          },
        ],
      },
      {
        humanTitle: "Chrome window",
        id: "chrome_window",
        resolution: "1050x610",
        options: [
          {
            id: "clay",
            color: "#A3A3A3",
            fileName: "chrome_clay.png",
          },
          {
            id: "dark",
            color: "#303030",
            fileName: "chrome_dark.png",
          },
        ],
      },
      {
        humanTitle: "Safari window",
        id: "safari_window",
        resolution: "1050x610",
        options: [
          {
            id: "clay",
            color: "#A3A3A3",
            fileName: "safari_clay.png",
          },
          {
            id: "dark",
            color: "#303030",
            fileName: "safari_dark.png",
          },
        ],
      },

      {
        humanTitle: "Apple iMac",
        id: "apple_imac",
        resolution: "964x544",
        options: [
          {
            id: "silver",
            color: "#A3A3A3",
            fileName: "apple_imac.png",
          },
        ],
      },
      {
        humanTitle: "Apple iMac Pro",
        id: "apple_imac_pro",
        resolution: "964x544",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "apple_imac_pro.png",
          },
        ],
      },
      {
        humanTitle: "Dell XPS 15",
        id: "dell_xps_15",
        resolution: "776x437",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "dell_xps_15.png",
          },
        ],
      },

      {
        humanTitle: "Microsoft Surface Book",
        id: "microsoft_surface_book",
        resolution: "761x507",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "microsoft_surface_book.png",
          },
        ],
      },

      {
        humanTitle: "Apple Thunderbolt Display",
        id: "apple_thunderbolt_display",
        resolution: "966x543",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "apple_thunderbolt_display.png",
          },
        ],
      },

      {
        humanTitle: "Dell Ultrasharp 27",
        id: "dell_ultrasharp_27",
        resolution: "1024x576",
        options: [
          {
            id: "black",
            color: "#303030",
            fileName: "dell_ultrasharp_27.png",
          },
        ],
      },
      {
        humanTitle: "Empty",
        id: "Empty",
        options: [
          {
            id: "empty",
            color: "empty",
            fileName: "empty.png",
          },
        ],
      }
    ],
    tablets: [],
  },
};
