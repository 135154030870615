import React, { useEffect } from "react";
import { GetCurrentStoreData, WebsitesState } from "../../store/websites/types";
import { connect } from "react-redux";
import { getCurrentStoreData } from "../../store/websites/thunks";
import {
  ChangePageItemGlobalData,
  WebsitePagesState,
} from "../../store/websitePages/types";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import "./GooglePreview.scss";
import trimStringTo from "../../helpers/strings/trimStringTo";
import { Tooltip } from "antd";
import _ from "lodash";
import { getMetaDataFromComponentsArray } from "../../helpers/getMetaDataFromComponentsArray";
import { changePageItemGlobalData } from "../../store/websitePages/actions";
import { formatToURLPath } from "../../helpers/strings/formatToURLPath";

interface Props {
  websites: WebsitesState;
  websitesPages: WebsitePagesState;
  getCurrentStoreData: GetCurrentStoreData;
  changePageItemGlobalData: ChangePageItemGlobalData;

  type: "page" | "blog" | "post";
  urlsArray?: string[];
  overrideTitle?: string;
  overrideDescription?: string;
  disableLiveUpdate?: boolean;
}

const GooglePreview = (props: Props): JSX.Element => {
  const {
    getCurrentStoreData,
    websitesPages,
    websites,
    overrideTitle,
    overrideDescription,
    type,
    urlsArray,
    changePageItemGlobalData,
    disableLiveUpdate,
  } = props;

  const { currentWebsitePage, currentWebsite, currentBlogPost } =
    getCurrentStoreData();

  useEffect(() => {
    // Update data on load, otherwise the data won't be relevant unless the user presses "Save changes"
    if (disableLiveUpdate) return; // This isn't required in every case, so we can disable it for performance
    if (!currentWebsitePage) return;

    const pageComponentItems = currentWebsitePage.page_components;

    if (!pageComponentItems) return;
    const {
      first_page_title,
      first_page_subtitle,
      hero_image_url,
      hero_image_uuid,
    } = getMetaDataFromComponentsArray(pageComponentItems);

    changePageItemGlobalData({
      pageId: currentWebsitePage.id,
      data: {
        first_page_title,
        first_page_subtitle,
        hero_image_url,
        hero_image_uuid,
      },
    });
  }, []);

  const getCurrentTitle = () => {
    if (overrideTitle) return overrideTitle;

    if (type === "page") {
      const firstPageTitle = _.get(currentWebsitePage, "first_page_title");
      return firstPageTitle || "No title";
    }

    if (type === "blog") {
      const companyName = _.get(
        currentWebsite,
        "nav.componentData.navLogo.content.companyTitle.text"
      );
      if (companyName) return `Blog | ${companyName}`;
      return "Blog";
    }

    if (type === "post") {
      const actualPostTitle = _.get(currentBlogPost, "title");
      return actualPostTitle || "No title";
    }

    return "No title";
  };

  const getCurrentDescription = () => {
    if (overrideDescription) return overrideDescription;

    if (type === "page") {
      const firstPageSubtitle = _.get(
        currentWebsitePage,
        "first_page_subtitle"
      );
      return firstPageSubtitle || "No description";
    }

    if (type === "blog") {
      return "Explore a wide range of topics, insights, and ideas.";
    }

    if (type === "post") {
      const firstParagraph = _.get(currentBlogPost, "first_paragraph_text");
      return firstParagraph || "No description";
    }

    return "No description";
  };

  const getUrlString = () => {
    if (!urlsArray) return "";
    let urlString = formatToURLPath(urlsArray.filter((url) => !!url).join("/"));
    if (urlString) return " › " + urlString.replace(/\//g, " › ");
    return "";
  };

  const urlString = getUrlString().toLowerCase();

  const getTooltipText = () => {
    if (type === "page") {
      return "This is how your meta title and description will appear in search results. If not specified, we automatically set them from this page's content.";
    }

    if (type === "blog") {
      return "This is how your meta title and description will appear in search results. If not specified, we automatically set the default ones.";
    }

    if (type === "post") {
      return "This is how your meta title and description will appear in search results. If not specified, we automatically set them from this blog post's content.";
    }

    return "";
  };

  return (
    <Tooltip
      title={<div>{getTooltipText()}</div>}
      placement="left"
      overlayStyle={{ maxWidth: "370px" }}
    >
      <div className="google-preview">
        <div className="google-preview__url">
          <span>https://{getActiveDomain(currentWebsite)}</span>
          {urlString && <span>{urlString}</span>}
        </div>
        <h3 className="google-preview__title">
          {trimStringTo(getCurrentTitle(), 60)}
        </h3>
        <div className="google-preview__description">
          {trimStringTo(getCurrentDescription(), 158)}
        </div>
      </div>
    </Tooltip>
  );
};

const mapStateToProps = (state: any) => ({
  websitesPages: state.websitesPages,
  websites: state.websites,
});
export default connect(mapStateToProps, {
  getCurrentStoreData,
  changePageItemGlobalData,
})(GooglePreview);
