function checkIfNewUser(userObj:any): boolean {
    let isNew = false;

    if(userObj.dataFetched){
        let now = new Date().getTime();

        let created = new Date(userObj.data.created).getTime();

        let diffHours = (now - created)/1000/60/60;

        if(diffHours < 2){
            isNew = true;
        }else{
            isNew = false;
        }
    }

    return isNew;
}

export default checkIfNewUser;