import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {pressLogos, publicUrl} from "../../data/urls";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteLogo from "../component_molecules/WebsiteLogo";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Press02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: ""}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: ''}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,
                fieldTitle: {default: "Press mentions"},
                label: {default: "Edit the mentions:"},
                labelTooltip: {default: "Add a logotype of a blog or a magazine that has mentioned you in a post."},
                fieldInfo: {default: ""},
                maxItems: 16,
                hasLogo: true,
                hasThumb: false,
                hasSubtitle: false,
                items: [
                    this.generator.createPostsContentObject({
                        href: '#',
                        title: "\"A falsis, decor fatalis triticum. Cum adgium accelerare, omnes quadraes carpseris.\"",
                        logo: this.generator.createLogoContentObject({
                            uploadedSrc: publicUrl + pressLogos.prefix + 'the-verge.svg', height: 40,

                        }),
                        text: "Ferox castor aliquando dignuss luba est. Cum acipenser nocere, omnes cursuses quaestio fidelis, mirabilis compateres. Tatas credere, tanquam mirabilis homo."
                    }),
                    this.generator.createPostsContentObject({
                        href: '#',
                        title: "\"A falsis, decor fatalis triticum. Cum adgium accelerare, omnes quadraes carpseris.\"",
                        logo: this.generator.createLogoContentObject({
                            uploadedSrc: publicUrl + pressLogos.prefix + 'the-next-web.svg',

                        }),
                        text: "Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scelerisque nec pharetra id, tempor a tortor."
                    })
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the component to show more press mentions.",
                    default: "Add a button to the bottom of the component to show more press mentions.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        return (
            <section>
                <div className="press-02">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container container--premid">
                        <ul className="press-02__list">
                            {posts && posts.map((item:any, key:number) => {
                                let logoItemContent;

                                if(item.href && item.href !== ''){
                                    logoItemContent =
                                        (<a className="press-02__link press-02__text" href={returnHref(item.href)}>
                                            <p className={"content-text def-20 press-02__text " + (this.props.isDarkBg ? ' text-white ' : ' ')} dangerouslySetInnerHTML={{__html: item.title}}/>
                                            {item.logo.type !== '' && item.logo.uploadedSrc !== '' && <WebsiteLogo logoObject={item.logo} className='press-02__logo' />}
                                        </a>)
                                }else{
                                    logoItemContent =
                                        (<span className="press-02__text">
                                            <p className={"content-text def-20 press-02__text " + (this.props.isDarkBg ? ' text-white ' : ' ')} dangerouslySetInnerHTML={{__html: item.title}}/>
                                            {item.logo.type !== '' && item.logo.uploadedSrc !== '' && <WebsiteLogo logoObject={item.logo} className='press-02__logo' />}
                                        </span>)
                                }

                                if(item !== undefined){
                                    return (
                                        <li className="press-02__item" key={'press-02-' + key}>
                                            {logoItemContent}
                                        </li>
                                    )

                                }else{
                                    return (null);
                                }
                            })}
                        </ul>
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Press02;
