import React, { Component } from "react";
import { componentsBackgroundColorsOptions, deprecatedBackgroundColorOptions } from "../../../data/componentsBackgroundColorsOptions";
import { checkIfWebsiteHasBlackPalette } from "../../../helpers/editor/checkIfWebsiteHasBlackPalette";
import { applyCustomColorsClassnames } from "../../../helpers/editor/applyCustomColorsClassnames";
import "./BackgroundColorPicker.css";
import CustomBackgroundColorButton from "./CustomBackgroundColorButton";
import { ColorOption } from "../../../helpers/types/globalTypes";

interface Props {
  componentItem?: any;
  currentWebsite: any;
  currentComponentBackgroundOptions: any;
  saveComponentBackgroundColorInStore?: any;
  toggleNewEditionsDetected?: any;
  footerObject?: any;
  isFooter: boolean;
}

class BackgroundColorPicker extends Component<Props> {
  componentDidMount(): void {}

  render() {
    //First, get the initial values.

    // The default value is got differently for footer and all other components:
    let currentBackgroundColor: ColorOption = "";
    if (this.props.isFooter) {
      let footerObject = this.props.footerObject;
      let currentBgOption = footerObject.settings.bg_color;

      if (currentBgOption) {
        currentBackgroundColor = currentBgOption;
      } else {
        //different footer schemes have different defatul color options
        let footerScheme = footerObject.settings.scheme;

        if (footerScheme === 1) {
          currentBackgroundColor = "black";
        } else if (footerScheme === 2) {
          currentBackgroundColor = "light_gray";
        } else {
          currentBackgroundColor = "black";
        }
      }
    } else {
      currentBackgroundColor = "white";
      if (
        this.props.componentItem &&
        this.props.componentItem.settings &&
        this.props.componentItem.settings.background
      ) {
        if (this.props.componentItem.settings.background.color) {
          currentBackgroundColor = this.props.componentItem.settings.background
            .color;
        }
      }
    }

    let shouldDisplayBlackBackgroundOptions =
      checkIfWebsiteHasBlackPalette(this.props.currentWebsite) === false;

    const handleOnClick = (colorTitle: string) => {
      this.props.saveComponentBackgroundColorInStore(colorTitle);
      this.props.toggleNewEditionsDetected(true);
    };

    let backgroundColorOptions = [] as any[];
    Object.entries(componentsBackgroundColorsOptions).forEach((entry) => {
      let colorTitle = entry[0] as ColorOption; //e.g. 'black'
      let colorClassname = entry[1]; // e.g. 'bg-black-color'
      if (deprecatedBackgroundColorOptions.includes(colorTitle)) {
        // Deprecated color option are hidden from users.
        return;
      }

      const {
        isFooter,
        footerObject,
        componentItem,
        saveComponentBackgroundColorInStore,
        toggleNewEditionsDetected,
      } = this.props;

      let ifItemInTheAvailableColorList = false;
      let currentComponentBackgroundOptionsCount = this.props
        .currentComponentBackgroundOptions.length;
      for (let i = 0; i < currentComponentBackgroundOptionsCount; i++) {
        if (this.props.currentComponentBackgroundOptions[i] === colorTitle) {
          ifItemInTheAvailableColorList = true;
          break;
        }
      }

      if (
        // The color item must be in the available color list (see componentsBackgroundOptionsExceptions.ts) OR the color list contains only one item - 'color' means ALL options are available.
        (ifItemInTheAvailableColorList ||
          (this.props.currentComponentBackgroundOptions !== undefined &&
            this.props.currentComponentBackgroundOptions[0] === "color")) &&
        // do not display these colors if we should display them (if shouldDisplayBlackBackgroundOptions === false):
        ((shouldDisplayBlackBackgroundOptions === false &&
          colorTitle !== "black" &&
          shouldDisplayBlackBackgroundOptions === false &&
          colorTitle !== "light_gray") ||
          shouldDisplayBlackBackgroundOptions === true)
      ) {
        let colorOptionClassname =
          colorClassname + " background-color-picker__item ";
        if (currentBackgroundColor === colorTitle) {
          colorOptionClassname += " background-color-picker__item--small ";
        }
        if (colorTitle === "white") {
          colorOptionClassname += " background-color-picker__item--border ";
        }

        if (colorTitle === "custom") {
          backgroundColorOptions.push(
            <CustomBackgroundColorButton
              key="custom"
              currentBackgroundColor={currentBackgroundColor}
              saveComponentBackgroundColorInStore={
                saveComponentBackgroundColorInStore
              }
              toggleNewEditionsDetected={toggleNewEditionsDetected}
              componentItem={isFooter ? footerObject : componentItem}
              isFooter={isFooter}
            />
          );
          return;
        }

        backgroundColorOptions.push(
          <button
            key={colorTitle}
            className={colorOptionClassname}
            onClick={() => {
              handleOnClick(colorTitle);
            }}
          >
            <span className="background-color-picker__circle"></span>
          </button>
        );
      }
    });

    let customColorsActiveClassnames = applyCustomColorsClassnames(
      this.props.currentWebsite
    );

    return (
      <div>
        <div
          className={
            "checkered-bg background-color-picker " +
            this.props.currentWebsite.color_classname +
            " " +
            customColorsActiveClassnames
          }
        >
          {backgroundColorOptions}
        </div>
      </div>
    );
  }
}

export default BackgroundColorPicker;
