import axios, { AxiosResponse } from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import { BlogPostItem } from "../../store/blogPosts/types";

type UpdatePostAsync = (payload: {
  accessToken: string;
  subdomain: string;
  postUrl: string;
  data: Partial<BlogPostItem>;
}) => Promise<AxiosResponse<any>>;

export const updatePostAsync: UpdatePostAsync = ({
  accessToken,
  subdomain,
  data,
  postUrl,
}) => {
  return new Promise((resolve, reject) => {
    const apiUrl =
      api.blogPosts.updateBlogPost.prefix +
      subdomain +
      "/" +
      postUrl +
      api.blogPosts.updateBlogPost.postfix;

    axios
      .patch(
        apiUrl,
        { ...data, subdomain },
        { ...returnAuthHeaderForAJAX(accessToken) }
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
