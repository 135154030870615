import React from "react";
import trimStringTo from "../../../../helpers/strings/trimStringTo";
import NoTitleWarning from "../../NoTitleWarning";

interface Props {
  item: any;
  blogHomePageURL?: string;
}

const PageTitle = (props: Props): JSX.Element => {
  const { item, blogHomePageURL } = props;
  let prefix;
  
  if (item.url === "") {
    prefix = (
      <span className="html-export__page_title_prefix">Home page: </span>
    );
  } else if (item.url === "blog") {
    prefix = (
      <span className="html-export__page_title_prefix">Blog home page: </span>
    );
  } else {
    let pageUrl = item.url;
    if (blogHomePageURL && blogHomePageURL !== "") {
      pageUrl = blogHomePageURL + "/" + pageUrl;
    }
    prefix = (
      <span className="html-export__page_title_prefix">
        /{trimStringTo(pageUrl, 20)}
      </span>
    );
  }

  if (item.meta_title.length > 0) {
    let formattedTitle = item.meta_title;
    let isTitleTooLong = false;
    if (item.meta_title.length > 50) {
      formattedTitle = formattedTitle.slice(0, 47);
      isTitleTooLong = true;
    }

    return (
      <span>
        {prefix}{" "}
        <span className={isTitleTooLong ? "html-export__big_title" : ""}>
          {formattedTitle}
        </span>
      </span>
    );
  } else {
    return <NoTitleWarning prefix={prefix} />;
  }
};

export default PageTitle;
