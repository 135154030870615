import { Components } from "../enums/Components";

export const getActualComponentName = (component) => {
  const { actualComponentName } = component;
  if (actualComponentName) {
    return actualComponentName.toLowerCase() as Components;
  }
  return component.id
    .replace("#", "")
    .toLowerCase()
    .split("-")
    .slice(0, 2)
    .join("-") as Components;
};
