import {companyLogos, publicUrl} from "../../data/urls";

let getIntegrationLogoSourceFromTitle = (integrationTitle:string|undefined) => {
    let integrationLogoPreifx = publicUrl + companyLogos.prefix;

    switch (integrationTitle) {
        case "mailchimp":
            return integrationLogoPreifx + 'mailchimp-small.svg';
        case "zapier":
            return integrationLogoPreifx + 'zapier-small.svg';
        case "salesforce":
            return integrationLogoPreifx + 'salesforce.svg';
        case "ifttt":
            return integrationLogoPreifx + 'ifttt-small.svg';
        case "pipedrive":
            return integrationLogoPreifx + 'pipedrive-small.png';
        case "slack":
            return integrationLogoPreifx + 'slack-small.svg';
        case "telegram":
            return integrationLogoPreifx + 'telegram-small.svg';
        case "airtable":
            return integrationLogoPreifx + 'airtable-small.svg';
        case "hubspot":
            return integrationLogoPreifx + 'hubspot.svg';
        case "mailerlite":
            return integrationLogoPreifx + 'mailerlite.svg';
        case "google-sheet":
            return integrationLogoPreifx + 'google-sheets-small.svg';
        case "webhook":
            return integrationLogoPreifx + 'webhook.svg';
        case "email":
            return integrationLogoPreifx + 'email.svg';
        default:
            return integrationLogoPreifx + 'brick.svg';
    }
};

export default getIntegrationLogoSourceFromTitle;
