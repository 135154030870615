import React, { Component } from "react";
import "./CreateNewWebsiteModal.css";
import { Modal } from "antd";
import TemplateSelect from "./TemplateSelect";
import { UserState } from "../../store/user/types";
import { connect } from "react-redux";

interface Props {
  websites: any;
  templates: any;
  user: UserState;

  onSubmit: any;
  isFormVisible: boolean;
  hideForm: any;
  title: string;
  name: string;
  labelText: string;
  placeholder: string;
  errors: string;
  isLoading: boolean;
  addonAfter?: any;
  addonBefore?: any;
  classNameForCreateButton?: string;
}
type State = {
  subdomainUrlValue: string;
  activeTemplate: string;
};

class CreateNewWebsiteModal extends Component<Props, State> {
  componentWillMount(): void {
    // We want to set up an initial value of a website custom_domain.
    this.setState({});
  }

  componentDidMount(): void {
    this.setState({});
  }

  changeActiveTemplate = (templateTitle) => {
    this.setState({
      activeTemplate: templateTitle,
    });
  };

  render() {
    return (
      <div>
        <Modal
          title={this.props.title}
          onCancel={this.props.hideForm}
          visible={this.props.isFormVisible}
          footer={null}
          className={"js-tour__create-new-modal create-new-website-modal"}
        >
          <div className="create-new-website-modal__box">
            <form className="create-new-website-modal__form">
              <div className="create-new-website-modal__form-body">
                <div className="create-new-website-modal__input_box">
                  <div>
                    <TemplateSelect
                      templates={this.props.templates}
                      activeTemplate={this.state.activeTemplate}
                      changeActiveTemplate={this.changeActiveTemplate}
                      onSubmit={this.props.onSubmit}
                      classNameForCreateButton={
                        this.props.classNameForCreateButton
                      }
                      isLoading={this.props.isLoading}
                      websites={this.props.websites}
                      user={this.props.user}
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    websites: state.websites,
    templates: state.templates,
    user: state.user,
  };
};
export default connect(mapStateToProps, {})(CreateNewWebsiteModal);
