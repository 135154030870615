import {
  EditInputData,
  HandleOnCompletionFinished,
} from "../../../store/gpt/types";
import _ from "lodash";
import { QueueItem, StopCompletion } from "./types";

export async function callOpenAiApi(
  setValue: (newCompletionJson5: string) => void,
  handleOnCompletionFinished: HandleOnCompletionFinished,
  url: string,
  accessToken: string,
  data: {
    userPrompt: string;
    component: any;
  },
  forceStopFlagRef: React.MutableRefObject<string[]>,
  componentId: string,
  forceStopAllFlagRef: React.MutableRefObject<boolean>,
  pageId: number,
  queueDataRef: React.MutableRefObject<QueueItem[]>,
  abortController: AbortController,
  stopCompletion: StopCompletion,
  editInputData: EditInputData
) {
  const setValueDebounced = setValue; // _.debounce(setValue, 50);
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "Token " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      signal: abortController.signal,
    });
    if (!response.status.toString().startsWith("2")) {
      handleOnCompletionFinished({
        hasErrors: true,
        pageId,
        componentId,
        queueDataRef,
      });
      let responseData = { error: "" };
      try {
        responseData = await response.json();
      } catch (e) {
        console.error(e);
      }
      stopCompletion(componentId);
      editInputData({
        componentId,
        newData: {
          isLoading: false,
          reasoning:
            responseData.error ||
            "Sorry, there was an error. Please try again.",
        },
      });
      return;
    }

    let responseText = "";

    const reader = response.body.getReader();
    return new ReadableStream({
      start(controller) {
        const pump = () => {
          return reader.read().then(({ done, value }) => {
            try {
              // When no more data needs to be consumed, close the stream
              if (done) {
                console.log("FINISH REASON: if (done) === true");
                handleOnCompletionFinished({
                  hasErrors: !responseText,
                  pageId,
                  componentId,
                  queueDataRef,
                });
                controller.close();
                return;
              }

              if (forceStopFlagRef.current.includes(componentId)) {
                console.log("FINISH REASON: if (forceStopFlagRef.current)");
                handleOnCompletionFinished({
                  hasErrors: !responseText,
                  pageId,
                  componentId,
                  queueDataRef,
                });
                controller.close();
                return;
              }

              if (forceStopAllFlagRef.current) {
                console.log("FINISH REASON: if (forceStopAllFlagRef.current)");
                handleOnCompletionFinished({
                  hasErrors: !responseText,
                  pageId,
                  componentId,
                  queueDataRef,
                });
                controller.close();
                return;
              }

              // Enqueue the next data chunk into our target stream

              const textDecoder = new TextDecoder();
              const chunkText = textDecoder.decode(value);

              if (chunkText === "[DONE]\n\n") {
                console.log(
                  "FINISH REASON: if (chunkText === '[DONE]\\n\\n') === true"
                );
                handleOnCompletionFinished({
                  hasErrors: false,
                  pageId,
                  componentId,
                  queueDataRef,
                });
                return;
              }

              responseText += chunkText;
              try {
                setValueDebounced(responseText.trim());
              } catch (e) {
                console.log("FINISH REASON: error in setValueDebounced");
                console.error(e);
                handleOnCompletionFinished({
                  hasErrors: true,
                  pageId,
                  componentId,
                  queueDataRef,
                });
                controller.close();
                return;
              }
              controller.enqueue(value);
              return pump();
            } catch (e) {
              console.log("FINISH REASON: error everywhere");
              console.error(e);
              handleOnCompletionFinished({
                hasErrors: true,
                pageId,
                componentId,
                queueDataRef,
              });
              controller.close();
              return;
            }
          });
        };

        return pump();
      },
    });
  } catch (e) {
    console.log("FINISH REASON: catch (e)");
    console.error(e);
    handleOnCompletionFinished({
      hasErrors: true,
      pageId,
      componentId,
      queueDataRef,
    });
  }
}
