import _ from "lodash";
import { WebsiteDataStatus } from "../enums/WebsiteDataStatus";
import { getCurrentWebsite } from "./getCurrentWebsite";

export const getWebsiteDataStatus = (
  websites: any,
  websitePages: any
): WebsiteDataStatus => {
  
  const isWebsiteDataFetched = _.get(websites, "dataFetched");
  const isPageDataFetched = _.get(
    websitePages,
    "pagesWithoutComponentsFetched"
  );

  if (!isWebsiteDataFetched || !isPageDataFetched)
    return WebsiteDataStatus.noData;

  const websiteItems = _.get(websites, "items");
  const loadedWebsites = _.get(websites, "loadedWebsites");
  const currentWebsite = getCurrentWebsite(websiteItems);
  const websiteId = _.get(currentWebsite, "id");

  const currentWebsiteDataStatus = _.find(loadedWebsites, { websiteId });

  return _.get(currentWebsiteDataStatus, "status", WebsiteDataStatus.notLoaded);
};
