const actionsNames = {
  PROCESSING_SYSTEM_READY: "PROCESSING_SYSTEM_READY" as string,
};

export const toggleProcessingSystemReady = (
  isProcessingSystemReady: boolean
) => {
  return {
    type: actionsNames.PROCESSING_SYSTEM_READY as string,
    payload: isProcessingSystemReady as boolean,
  };
};

export default actionsNames;
