export const applyCustomColorsClassnames = (currentWebsite:any) => {
    // This function checks these things:
    // 1. If custom colors (color palette override) is enabled
    // 2. If Primary button has a bright custom color.
    // 3. If secondary button has a bright custom color.
    // 4. If accent color is a bright color.

    // After the check, the function returns all needed classnames which are used for colors overriding.

    let className = ' ';

    if(currentWebsite && currentWebsite.custom_color_palette){
        if(currentWebsite.custom_color_palette.isCustomPaletteActivated === true){
            className += ' custom-colors-enabled ';

            if(currentWebsite.custom_color_palette.isPrimaryButtonColorBright === true){
                className += ' custom-primary-button-color-bright ';
            }

            if(currentWebsite.custom_color_palette.isSecondaryButtonColorBright === true){
                className += ' custom-secondary-button-color-bright ';
            }

            if(currentWebsite.custom_color_palette.isAccentColorBright === true){
                className += ' custom-accent-color-bright ';
            }

        }
    }

    return (className);
};