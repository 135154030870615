export const appendScript = (scriptToAppend, callback) => {
  const script = document.createElement("script");

  script.src = scriptToAppend;
  script.async = true;
  script.defer = true;
  script.onreadystatechange = "if (this.readyState === 'complete') this.onload()";
  script.onload = () => {
    callback()
  };

  document.body.appendChild(script);
}