import React, {Component} from "react";
import './ColorPaletteItem.css'
import {Radio} from "antd";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {buttonStyles} from "../../data/buttonStyles";
import SpinnerBox from "./SpinnerBox";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {projectUrl} from "../../data/constants";
import {openNotification} from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getWebsiteProtocol} from "../../helpers/getWebsiteProtocol";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import AbstractIcon from "../../pageComponents/component_molecules/AbstractIcon";
import WebsiteButton from "../../pageComponents/component_molecules/WebsiteButton";
import _ from "lodash";

interface Props {
    styleClassname: string,
    styleTitle: string,
    auth: any,
    currentWebsite: any,
    saveWebsiteDataInStore: any,
    websites: any,
    toggleWaitingForResponseOnColorPaletteChange: any,
}

class ColorPaletteItem extends Component<Props>{
    state = {
        beingActivated: false,
    };

    applyNewStyle = (styleClassname:string) => {
        this.props.toggleWaitingForResponseOnColorPaletteChange(true);
        this.setState({beingActivated: true});

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.websites.updateWebsite.prefix + this.props.currentWebsite.subdomain + api.websites.updateWebsite.postfix;
        axios.patch(apiUrl, {color_classname: styleClassname}, {...returnAuthHeaderForAJAX(accessToken)})
            .then(response => {
                const dataToStore = {
                  data: {
                    ...this.props.currentWebsite,
                    color_classname: _.get(response, "data.color_classname")
                  }
                }
                this.props.saveWebsiteDataInStore(dataToStore, this.props.currentWebsite.subdomain);

                // The server returns the full website object.
                let updatedWebsiteData = response.data;

                // Tell a user that the operation is successful.
                let fullWebsiteUrl = 'https://' + this.props.currentWebsite.subdomain + '.' + projectUrl;

                if(updatedWebsiteData.is_custom_domain_active){
                    let websiteProtocol = getWebsiteProtocol(updatedWebsiteData);
                    fullWebsiteUrl = websiteProtocol + updatedWebsiteData["custom_domain"];
                }
                let newColorMessage = (<span>Successfully changed the color palette of your website: <a target="_blank" href={fullWebsiteUrl}>{fullWebsiteUrl}</a></span>);

                fireAnalyticsEvent.fireCrisp(CrispEvents.changeColorPalette, {
                  subdomain: response.data.subdomain,
                  new_color_palette: response.data.color_classname,
                });
            })
            .catch(error => {
                if(error.response){
                    let errorData = error.response.data;
                    if (errorData.detail !== undefined){
                        let message = errorData.detail;

                        let errorObjectKey = getErrorKey(errorData);
                        let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                        fireAnalyticsEvent.fireCrisp(CrispEvents.changeColorPaletteError, {
                          error_type: errorObjectKey,
                          error_message: errorObjectValue,
                          subdomain: this.props.currentWebsite.subdomain,
                          new_color_palette: styleClassname,
                        }, true);

                        // This happens when user tries to change a website which doesn't exist or doesn't belog to the user.
                        openNotification('Server error', 'Error message: "' + message + '". This should not have happened. Please contact us.', 'OK', 'error');
                    }

                    if (errorData.locked !== undefined){
                        let message = errorData.locked;
                        openNotification('Denied', message, 'OK', 'warn');
                    }

                    if (errorData.color_classname !== undefined){
                        let message = errorData.subdomain.join(' ');
                        openNotification('Denied', message, 'OK', 'warn');
                    }
                }
            })
            .then(response => {
                // always executed
                this.props.toggleWaitingForResponseOnColorPaletteChange(false);
                this.setState({beingActivated: false});
            });
    };

    render(){
        let isActive = this.props.currentWebsite.color_classname === this.props.styleClassname;
        let isLoading = this.props.websites.isWaitingForChangeColorPaletteServerResponse;

        let overlayClassName = ' color-palette-item__card_overlay ';
        if(this.state.beingActivated){
            overlayClassName += ' color-palette-item__card_overlay--spinner-visible ';
        }else if(isLoading){
            overlayClassName += ' color-palette-item__card_overlay--visible ';
        }

        return (
            <div
                onClick={() => {
                    this.applyNewStyle(this.props.styleClassname)
                }}
                className={isActive ? "color-palette-item color-palette-item--active" : "color-palette-item"}>
                <div className="comps">
                    <div className={this.props.styleClassname}>
                        <div className="color-palette-item__card">

                            <div className={overlayClassName}>
                                <div className="color-palette-item__spinner">
                                    <SpinnerBox text=" "/>
                                </div>
                            </div>

                            <div className="color-palette-item__card__example_piece">
                                <WebsiteButton 
                                  config={{
                                    buttonType: linkOrButtonOptions.link,
                                    buttonTitle: "Learn more",
                                    buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration

                                    disableMessageOnClick: true,

                                    buttonStyleType: buttonStyles.regular.typeTitle,
                                    buttonColor: buttonStyles.regular.accentBg,
                                }}
                                />
                            </div>

                            <div className="color-palette-item__card__example_piece">
                                <WebsiteButton 
                                  config={{
                                    buttonType: linkOrButtonOptions.link,
                                    buttonTitle: "Sign up",
                                    buttonHref: "javascript:void(0);", // we do not want any action, this button is only for decoration

                                    disableMessageOnClick: true,

                                    buttonStyleType: buttonStyles.regular.typeTitle,
                                    buttonColor: buttonStyles.regular.accentOutline,
                                }}
                                />
                            </div>

                            <div className="color-palette-item__card__example_piece">
                                <button className="tab__button  state-active-tab" type="button">Activate</button>
                                <AbstractIcon id='5'/>
                            </div>

                        </div>
                        <div className="color-palette-item__button_box">
                            <div className="color-palette-item__button">{this.props.styleTitle}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ColorPaletteItem;
