import _ from "lodash";
import { getPageArray } from "../../../components/editor/ai2/getPageArray";
import {
  saveWebsiteFooterInState,
  saveWebsiteNavInState,
  toggleWebsiteFooterEditionsDetected,
  toggleWebsiteNavEditionsDetected,
} from "../../websites/actions";
import { setChangeHistoryData } from "../actions";
import { getChangeHistoryItem } from "./getChangeHistoryItem";
import {
  changeWebsitePageComponentContent,
  toggleNewWebsitePageEditionsDetected,
} from "../../websitePages/actions";
import { HandleRedo } from "../types";

export const handleRedo: HandleRedo = ({
  componentId,
  currentWebsitePage,
  currentWebsite,
  currentPageIndex,
}) => {
  return (dispatch) => {
    const changeHistoryItem = dispatch(getChangeHistoryItem(componentId));
    if (
      changeHistoryItem.availableAction !== "redo" ||
      _.isEmpty(changeHistoryItem.after)
    )
      return;
    const pageArray = getPageArray(currentWebsitePage, currentWebsite);
    const component = pageArray.find((pageComponent) => {
      return pageComponent.id === componentId;
    });
    const componentIndex = pageArray.indexOf(component) - 1;
    dispatch(
      setChangeHistoryData({
        componentId,
        before: component.componentData,
        availableAction: "undo",
      })
    );
    if (component.id === "navigation") {
      dispatch(
        saveWebsiteNavInState({
          navObject: {
            ...currentWebsite.nav,
            componentData: _.cloneDeep(changeHistoryItem.after),
          },
          currentWebsiteSubdomain: currentWebsite.subdomain,
        })
      );
      dispatch(toggleWebsiteNavEditionsDetected(true));
    }
    if (component.id === "footer") {
      dispatch(
        saveWebsiteFooterInState({
          footerObject: {
            ...currentWebsite.footer,
            componentData: _.cloneDeep(changeHistoryItem.after),
          },
          currentWebsiteSubdomain: currentWebsite.subdomain,
        })
      );
      dispatch(toggleWebsiteFooterEditionsDetected(true));
    }
    if (component.id !== "navigation" && component.id !== "footer") {
      dispatch(
        changeWebsitePageComponentContent({
          currentPageIndex,
          componentKey: componentIndex,
          componentData: _.cloneDeep(changeHistoryItem.after),
        })
      );
      dispatch(toggleNewWebsitePageEditionsDetected(true));
    }
  };
};
