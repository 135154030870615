import React from "react";
import { draftJsEditorTitle } from "../../../../data/constants";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import DebouncedTextarea from "../../../DebouncedTextarea";
import MyRichTextEditor from "../../../MyRichTextEditor";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  item: any;
  keyProp: number;
  textEditorType: string;
  revertToPlainHTML: () => void;
  saveContentInStore: any;
  generator: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  toggleNewEditionsDetected: (value: boolean) => void;
}

const StepDescriptionField = (props: Props): JSX.Element => {
  const {
    textEditorType,
    revertToPlainHTML,
    item,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const onChangeStepTextHandler = (value: any) => {
    saveContentInStore(
      generator.changeStepText(
        { [contentType]: currentInputDataRef.current },
        keyProp,
        value
      )
    );
    toggleNewEditionsDetected(true);
  };

  if (!currentInputDataRef.current.hasText) return null;

  if (textEditorType && textEditorType === draftJsEditorTitle) {
    return (
      <div className="content-input__input">
        <MyRichTextEditor
          revertToPlainHTML={revertToPlainHTML}
          placeholder="Enter step description"
          defaultValue={item.text}
          onChange={onChangeStepTextHandler}
        />
      </div>
    );
  }

  return (
    <DebouncedTextarea
      className="content-input__input"
      onChange={onChangeStepTextHandler}
      defaultValue={item.text}
      autosize={{ minRows: 1, maxRows: 6 }}
      placeholder="Enter step description"
    />
  );
};

export default StepDescriptionField;
