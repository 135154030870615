import _ from "lodash";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { addTemporaryIds } from "./updaterFunctions.ts/addTemporaryIds";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { deleteListItems } from "./updaterFunctions.ts/deleteListItems";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { moveListItems } from "./updaterFunctions.ts/moveListitems";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemTitle = stringifyObjectsAndNumbers(modifiedItem.TITLE) || "";
  return {
    itemId,
    itemPosition,
    itemTitle,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemTitle
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.list.content.groups.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.list.content.groups, item);
      }
    });
  }
  if ("TITLE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
};

export const updateListGroups = (componentData, completionContent) => {
  if (!("GROUPS" in completionContent)) {
    return;
  }
  if (!Array.isArray(completionContent.GROUPS)) {
    if (completionContent.GROUPS === null) {
      componentData.list.content.items = [];
      componentData.list.content.groups = [];
    }
    return;
  }

  completionContent.GROUPS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const { itemId, itemPosition, itemTitle } = particleGetModifiedValues(
      modifiedItem
    );

    if (itemPosition === null) {
      addTemporaryIds(componentData.list.content.groups);
      componentData.list.content.groups = deleteItem(
        componentData.list.content.groups,
        itemId
      );
      componentData.list.content.items = deleteListItems(componentData);
      moveListItems(componentData);
      return;
    }

    const isItemNew = getIsItemNew(componentData.list.content.groups, itemId);

    if (isItemNew) {
      const newItem = generator.createGroupContentObject();
      newItem.title = "";
      newItem.subtitle = "";
      addNewItem(
        componentData.list.content.groups,
        newItem,
        itemId,
        itemPosition
      );
    }

    const originalItem = getOriginalItem(
      componentData.list.content.groups,
      itemId
    );
    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemTitle
    );
  });

  addTemporaryIds(componentData.list.content.groups);
  sortItemsByPosition(componentData.list.content.groups);
  moveListItems(componentData);
};
