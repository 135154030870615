import React, { useEffect } from "react";
import { FC } from "react";
import { InputProps } from "./interfaces";
import _ from "lodash";
import { connect } from "react-redux";
import { Button } from "antd";
import { useCurrentInputData } from "./useCurrentInputData";
import { openNotification } from "../../../helpers/openNotification";
import {
  DEFAULT_ERROR_MESSAGE,
  notificationDuration,
} from "../../../data/constants";
import "./RapidForms.scss";

interface Props extends InputProps {}

const RapidForms: FC<any> = (props: Props) => {
  const {
    generator,
    saveContentInStore,
    contentType,
    currentInputData,
    toggleNewEditionsDetected,
  } = props;

  const currentInputDataRef = useCurrentInputData(currentInputData);

  const getWidget = () => {
    if (!(window as any).RapidformsIntegration) {
      openNotification(
        "Error: Rapidforms integration not found",
        DEFAULT_ERROR_MESSAGE,
        "OK",
        "error",
        notificationDuration.medium
      );
      return;
    }
    const id = (window as any).RapidformsIntegration.getFormId();
    if (!id) {
      return;
    }
    const newSrc = `https://app.rapidforms.co/embed/${id}`;
    saveContentInStore(
      generator.changeRapidFormsSrc(
        { [contentType]: currentInputDataRef.current },
        newSrc
      )
    );
    toggleNewEditionsDetected(true);
  };

  return (
    <Button type="primary" onClick={getWidget} id="rapidforms-add-btn">
      Select form
    </Button>
  );
};

export const mapStateToProps = (state: any) => {
  return {
    websitesPages: state.websitesPages,
  };
};
export default connect(null, {})(RapidForms);
