import React, { useEffect } from "react";
import { useRef, useState } from "react";
import _ from "lodash";
import { Button, Dropdown, Icon, Menu, Tooltip } from "antd";
import DebouncedInputNumber from "../../../DebouncedInputNumber";
import capitalizeString from "../../../../helpers/strings/capitalizeString";
import { StyleMenuProps } from "./types";

interface ItemProps extends StyleMenuProps {
  children: React.ReactNode;
  placeholder?: string;
  customStyleProperty?: string;
  inputType?: "number" | "dropdown";
  max?: number;
  tooltip?: string;
  presets?: any[];
  presetSuffix?: string;
  placement?: string;
  isCardShadow?: boolean;
}
const Item = (props: ItemProps): JSX.Element => {
  const {
    children,
    placeholder,
    customStyleProperty,
    editCustomStyles,
    getCurrentStoreData,
    inputType,
    websites,
    max,
    tooltip,
    presets,
    presetSuffix,
    changeWebsiteItemGlobalData,
    placement,
    isCardShadow,
  } = props;
  const { currentWebsite } = getCurrentStoreData();
  const tooltipContainerRef = useRef(null);
  const dropdownContainerRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [currentInputValue, setCurrentInputValue] = useState(
    _.get(currentWebsite, ["custom_styles", customStyleProperty], null)
  );

  const handleOnNumberBlur = (value) => {
    const { currentWebsite } = getCurrentStoreData();
    let currentNumber = value;
    if (currentNumber > (max || 999)) {
      currentNumber = max || 999;
    }
    if (currentNumber < 0) {
      currentNumber = 0;
    }
    currentNumber = parseInt(currentNumber as any);
    if (isNaN(currentNumber)) {
      currentNumber = null;
    }
    const savedNumber = _.get(
      currentWebsite,
      ["custom_styles", customStyleProperty],
      null
    );
    if (currentNumber === savedNumber) {
      return;
    }
    editCustomStyles({
      websiteId: currentWebsite.id,
      newData: {
        [customStyleProperty]: currentNumber,
      },
    });
    changeWebsiteItemGlobalData({
      websiteId: currentWebsite.id,
      data: {
        hasUnsavedChanges: true,
      },
    });
  };

  const handleOnMenuClick = (e) => {
    editCustomStyles({
      websiteId: currentWebsite.id,
      newData: {
        [customStyleProperty]: e.key,
      },
    });
    changeWebsiteItemGlobalData({
      websiteId: currentWebsite.id,
      data: {
        hasUnsavedChanges: true,
      },
    });
  };

  const menu = (
    <Menu onClick={handleOnMenuClick}>
      <Menu.Item key="none">None</Menu.Item>
      {isCardShadow && <Menu.Item key="borderThin">Thin border</Menu.Item>}
      {isCardShadow && <Menu.Item key="borderThick">Thick border</Menu.Item>}
      <Menu.Item key="small">Small</Menu.Item>
      <Menu.Item key="default">Default</Menu.Item>
      <Menu.Item key="large">Large</Menu.Item>
    </Menu>
  );

  const handleOnPresetClick = (e) => {
    const val = e.key;
    editCustomStyles({
      websiteId: currentWebsite.id,
      newData: {
        [customStyleProperty]: val,
      },
    });
    changeWebsiteItemGlobalData({
      websiteId: currentWebsite.id,
      data: {
        hasUnsavedChanges: true,
      },
    });
  };

  const presetMenu = (
    <Menu>
      {(presets || []).map((preset, i) => {
        return (
          <Menu.Item key={preset.value} onClick={handleOnPresetClick}>
            <span>
              <span>{preset.value}</span>{" "}
              <span style={{ color: "rgba(0,0,0,0.40)" }}>
                ({preset.description})
              </span>
            </span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  if (inputType === "number") {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: 350,
          position: "relative",
        }}
        ref={dropdownContainerRef}
      >
        <div
          style={{ display: "flex", alignItems: "center", gap: 6 }}
          ref={tooltipContainerRef}
        >
          <Tooltip
            getPopupContainer={() => {
              return tooltipContainerRef.current;
            }}
            title={tooltip}
            placement="topLeft"
            mouseLeaveDelay={0}
            overlayStyle={{ width: "auto", maxWidth: 300 }}
            align={{ offset: [-13, 0] }}
          >
            <Icon type="info-circle" style={{ position: "relative", top: 1 }} />
          </Tooltip>
          <span>{children}</span>
        </div>
        <Dropdown
          overlay={presetMenu}
          trigger={["click"]}
          getPopupContainer={() => dropdownContainerRef.current}
          placement={(placement as any) || "bottomCenter"}
        >
          <div>
            <DebouncedInputNumber
              placeholder={placeholder}
              style={{ width: 150 }}
              onChange={handleOnNumberBlur}
              defaultValue={_.get(
                currentWebsite,
                ["custom_styles", customStyleProperty],
                null
              )}
              min={0}
              max={max || 999}
              disabled={websites.isCustomStylesWaitingForServer}
              allowEmpty
              onFocus={() => {
                setIsDropdownVisible(true);
              }}
              onBlur={() => {
                setIsDropdownVisible(false);
              }}
              setCurrentInputValue={setCurrentInputValue}
            ></DebouncedInputNumber>
          </div>
        </Dropdown>
      </div>
    );
  }

  const presetTitleMap = {
    none: "None",
    borderThin: "Thin border",
    borderThick: "Thick border",
    small: "Small",
    default: "Default",
    large: "Large",
  };

  const presetTitle =
    presetTitleMap[
      _.get(currentWebsite, ["custom_styles", customStyleProperty]) || "default"
    ];

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        maxWidth: 350,
      }}
      ref={dropdownContainerRef}
    >
      <div
        style={{ display: "flex", alignItems: "center", gap: 6 }}
        ref={tooltipContainerRef}
      >
        <Tooltip
          getPopupContainer={() => {
            return tooltipContainerRef.current;
          }}
          title={tooltip}
          placement="topLeft"
          mouseLeaveDelay={0}
          overlayStyle={{ width: "auto", maxWidth: 300 }}
          align={{ offset: [-13, 0] }}
        >
          <Icon type="info-circle" style={{ position: "relative", top: 1 }} />
        </Tooltip>
        <span>{children}</span>
      </div>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        getPopupContainer={() => dropdownContainerRef.current}
      >
        <Button
          style={{
            width: 150,
            paddingLeft: 11,
            paddingRight: 11,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {presetTitle}{" "}
          <Icon type="down" style={{ position: "relative", top: 1 }} />
        </Button>
      </Dropdown>
    </div>
  );
};

export default Item;
