import { ZapierIntegrationStatus } from './../../enums/ZapierIntegrationStatus';
import generateRandomNumerousId from "../generateRandomNumerousId";
import {api} from "../../data/urls";

class generateFormIntegrationDataObject{
    private generateBase = (integrationTitle: string):any => {
        return {
            integrationTitle: integrationTitle,
            connectedOn: Date.now(),
            id: integrationTitle + '-' + (generateRandomNumerousId()).toString(), // e.g.: "mailchimp-8142"
            data: {}
        }
    };

    static mailerlite = (integrationTitle: string, action: string) => {
        const gen = new generateFormIntegrationDataObject();

        const integrationObject = gen.generateBase(integrationTitle);
        integrationObject.data.action = action;

        return integrationObject;
    };

    static mailchimp = (integrationTitle:string, action:string) => {
        let gen = new generateFormIntegrationDataObject();

        let integrationObject = gen.generateBase(integrationTitle);
        integrationObject["data"]["action"] = action;

        return integrationObject;
    };


    static zapier = (integrationTitle:string, websiteId: number) => {
        let gen = new generateFormIntegrationDataObject();

        let integrationObject = gen.generateBase(integrationTitle);
        integrationObject["data"]["status"] = ZapierIntegrationStatus.notActive;
        integrationObject["data"]["action"] = `${api.websites.passFormDataToZapier}/${websiteId}/${integrationObject.id}`;
        integrationObject["data"]["hookUrl"] = "";

        return integrationObject;
    };

    static googleSheet = (integrationTitle:string, sheetName:string, sheetId: string, spreadsheetUrl: string, action: string) => {
        let gen = new generateFormIntegrationDataObject();

        let integrationObject = gen.generateBase(integrationTitle);
        integrationObject["data"]["sheetName"] = sheetName;
        integrationObject["data"]["sheetId"] = sheetId;
        integrationObject["data"]["spreadsheetUrl"] = spreadsheetUrl;
        integrationObject["data"]["action"] = action;

        return integrationObject;
    };

    static webhook = (integrationTitle:string, action:string, method: string) => {
        let gen = new generateFormIntegrationDataObject();

        let integrationObject = gen.generateBase(integrationTitle);
        integrationObject["data"]["action"] = action;
        integrationObject["data"]["method"] = method;

        return integrationObject;
    };

    static email = (integrationTitle:string, websiteId: string, emails:any) => {
        let gen = new generateFormIntegrationDataObject();

        let integrationObject = gen.generateBase(integrationTitle);
        integrationObject["data"]["action"] = api.websites.passFormDataToEmail + '/' + websiteId + '/' + integrationObject.id;
        integrationObject["data"]["emails"] = emails;

        return integrationObject;
    };
}

export default generateFormIntegrationDataObject;
