import React, { Component } from "react";
import { Alert, Icon, Tooltip } from "antd";
import ExportScreenshot from "../../../img/other/export_folder_demo.png";
import DownloadAssetsButton from "./components/DownloadAssetsButton";
import ExportNotAvailable from "./components/ExportNotAvailable";
import DownloadButtons from "./components/DownloadButtons";
import "./HtmlExport.css";
import { ActiveMenu } from "../SettingsDrawerContent";

interface Props {
  auth: any;
  currentWebsite: any;
  currentWebsitePages: any;
  websitesPages: any;
  websites: any;
  blogs: any;
  blogPosts: any;
  user: any;
  history: any;
  doesCurrentUserHaveAccessToPaidFeatures: boolean;
  setActiveMenu: React.Dispatch<React.SetStateAction<ActiveMenu>>;
}

class HtmlExport extends Component<Props> {
  render() {
    if (this.props.doesCurrentUserHaveAccessToPaidFeatures === false) {
      return (
        <ExportNotAvailable
          history={this.props.history}
          setActiveMenu={this.props.setActiveMenu}
        />
      );
    }

    return (
      <div className="html-export">
        <Alert
          style={{ marginBottom: "20px" }}
          message="You need to upload your images and videos to your own static files server (AWS S3, Google Firebase etc)."
          type="warning"
        />
        <div className="html-export__step">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "8px",
            }}
          >
            <Tooltip title="Download assets (CSS, JS and images). Unzip the archive.">
              <Icon type="info-circle" />
            </Tooltip>
            <div>Step 1:</div>
          </div>
          <DownloadAssetsButton />
        </div>
        <div className="html-export__step">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              marginBottom: "8px",
            }}
          >
            <Tooltip
              title={
                <>
                  Download an HTML file of the page you want to export. <br />{" "}
                  Save this file in the folder with the CSS/JS assets.
                  <div>
                    <img
                      style={{
                        width: "100%",
                        marginTop: "8px",
                        borderRadius: "4px",
                      }}
                      src={ExportScreenshot}
                      alt="Export folder screenshot"
                    />
                  </div>
                </>
              }
            >
              <Icon type="info-circle" />
            </Tooltip>
            <div>Step 2:</div>
          </div>
          <ul className="html-export__buttons_list">
            <DownloadButtons
              currentWebsitePages={this.props.currentWebsitePages}
              currentWebsite={this.props.currentWebsite}
              websitesPages={this.props.websitesPages}
              blogs={this.props.blogs}
              blogPosts={this.props.blogPosts}
            />
          </ul>
        </div>
      </div>
    );
  }
}

export default HtmlExport;
