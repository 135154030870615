import actionsNames from "./actions";
import { TemplatesState } from "./types";

let initialState: TemplatesState = {
  items: [],
  dataFetched: false,
};

export const templatesReducer = (
  state: TemplatesState = initialState,
  action: any
) => {
  switch (action.type) {
    case actionsNames.SAVE_TEMPLATES_DATA:
      return {
        ...state,
        items: [...state.items, ...action.payload],
      };

    case actionsNames.TEMPLATES_DATA_FETCHED:
      return {
        ...state,
        dataFetched: action.payload,
      };

    default:
      return state;
  }
};
