import React from "react";
import { connect } from "react-redux";
import {
  getCurrentStoreData,
  savePartialWebsiteData,
} from "../../../store/websites/thunks";
import {
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
  editCustomStyles,
} from "../../../store/websites/actions";
import _ from "lodash";
import { StyleMenuProps } from "./components/types";
import Item from "./components/Item";
import "./StyleMenu.scss";

const Example = (): JSX.Element => {
  return (
    <div className="comps">
      <div
        className="style-blue-1 checkered-bg checkered-bg--no-hover has-opacity style-menu__preview"
        style={{
          padding: 20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: 350,
        }}
      >
        <div style={{ width: "100%", zIndex: 100 }}>
          <div className="form__input__label_box">
            <label className="form__input__label" htmlFor={"EXAMPLE"}>
              Label
            </label>
          </div>
          <input
            className="text-input js-form-input "
            name="EXAMPLE"
            placeholder="Placeholder"
          />
        </div>
      </div>
    </div>
  );
};

const InputStyleMenu = (props: StyleMenuProps): JSX.Element => {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <Example />
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        <Item
          inputType="number"
          placeholder="4"
          customStyleProperty="inputRadius"
          tooltip="Change the border radius of each input field."
          presets={[
            { value: "0", description: "Sharp" },
            { value: "4", description: "Default" },
            { value: "10", description: "Round" },
            { value: "999", description: "Circle" },
          ]}
          {...props}
        >
          Radius
        </Item>
        <Item
          inputType="number"
          customStyleProperty="inputBorderSize"
          placeholder="2"
          max={99}
          tooltip="Change the border thickness of each input field."
          presets={[
            { value: "0", description: "No border" },
            { value: "1", description: "Thin" },
            { value: "2", description: "Default" },
            { value: "3", description: "Thick" },
          ]}
          {...props}
        >
          Border size
        </Item>
        <Item
          inputType="number"
          placeholder="20"
          customStyleProperty="inputHorizontalPadding"
          max={99}
          tooltip="Change the horizontal padding of each input field."
          presets={[
            { value: "8", description: "Narrow" },
            { value: "20", description: "Default" },
            { value: "32", description: "Wide" },
          ]}
          {...props}
        >
          Horizontal padding
        </Item>
        <Item
          inputType="number"
          placeholder="15"
          customStyleProperty="inputVerticalPadding"
          max={99}
          tooltip="Change the vertical padding of each input field."
          presets={[
            { value: "7", description: "Short" },
            { value: "15", description: "Default" },
            { value: "19", description: "Tall" },
          ]}
          {...props}
        >
          Vertical padding
        </Item>
        <Item
          inputType="number"
          placeholder="12"
          customStyleProperty="inputLabelFontSize"
          max={99}
          tooltip="Change the font size of the input field labels."
          presets={[
            { value: "10", description: "Small" },
            { value: "12", description: "Default" },
            { value: "14", description: "Large" },
          ]}
          {...props}
        >
          Label font size
        </Item>
        <Item
          inputType="number"
          placeholder="400"
          customStyleProperty="inputLabelFontWeight"
          max={999}
          tooltip="Change the font weight of the input field labels."
          presets={[
            { value: "400", description: "Default" },
            { value: "700", description: "Bold" },
            { value: "900", description: "Heavy" },
          ]}
          {...props}
        >
          Label font weight
        </Item>
        <Item
          inputType="number"
          placeholder="5"
          customStyleProperty="inputLabelMarginBottom"
          max={99}
          tooltip="Change the margin between the label and the input field."
          presets={[
            { value: "2", description: "Small" },
            { value: "5", description: "Default" },
            { value: "10", description: "Large" },
            
          ]}
          {...props}
        >
          Label bottom margin
        </Item>
        <Item
          inputType="number"
          placeholder="14"
          customStyleProperty="inputFontSize"
          max={99}
          tooltip="Change the font size of the input field placeholders and values."
          presets={[
            { value: "12", description: "Small" },
            { value: "14", description: "Default" },
            { value: "16", description: "Large" },
          ]}
          {...props}
        >
          Input font size
        </Item>
        <Item
          inputType="number"
          placeholder="400"
          customStyleProperty="inputFontWeight"
          max={999}
          tooltip="Change the font weight of the input field placeholders and values."
          presets={[
            { value: "400", description: "Default" },
            { value: "700", description: "Bold" },
            { value: "900", description: "Heavy" },
          ]}
          {...props}
        >
          Input font weight
        </Item>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    websites: state.websites,
  };
};
export default connect(mapStateToProps, {
  getCurrentStoreData,
  editCustomStyles,
  savePartialWebsiteData,
  changeWebsitesStateGlobalData,
  changeWebsiteItemGlobalData,
})(InputStyleMenu);
