import { addPromptHistoryItem } from "../actions";
import { AddPromptHistoryItemPayload, GptState } from "../types";

export const addPromptHistoryItemToStoreAndLocalStorage = (
  payload: AddPromptHistoryItemPayload
) => {
  return (dispatch, getState) => {
    dispatch(addPromptHistoryItem(payload));
    const gptState: GptState = getState().gpt;
    const { promptHistory } = gptState;
    localStorage.setItem("promptHistory", JSON.stringify(promptHistory));
  };
};
