import axios from "axios";
import { api } from "../../data/urls";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";

const actionsNames = {
  SAVE_PAGE_COMPONENT_CATEGORIES_DATA: "SAVE_PAGE_COMPONENT_CATEGORIES_DATA" as string,
  PAGE_COMPONENT_CATEGORIES_DATA_FETCHED: "PAGE_COMPONENT_CATEGORIES_DATA_FETCHED" as string,
};

export const fetchPageComponentCategories = () => {
  return (dispatch: any, getState: any) => {
    const cacheParam = getState().meta.cache;
    const url = `${api.pageComponentCategories.fetch}?v=${cacheParam}`;
    axios
      .get(url)
      .then((response) => {
        dispatch(savePageComponentCategoriesInState(response.data));
        dispatch(togglePageComponentCategoriesFetched(true));
      })
      .catch((error) => {})
      .then((response) => {});
  };
};

export const savePageComponentCategoriesInState = (data: string) => {
  // get data and store it
  return {
    type: actionsNames.SAVE_PAGE_COMPONENT_CATEGORIES_DATA as string,
    payload: data as string,
  };
};

export const togglePageComponentCategoriesFetched = (
  isDataFetched: boolean
) => {
  return {
    type: actionsNames.PAGE_COMPONENT_CATEGORIES_DATA_FETCHED as string,
    payload: isDataFetched as boolean,
  };
};

export default actionsNames;
