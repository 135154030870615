import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import _ from "lodash";
import GraphicsJSX from "../GraphicsJSX";
import { displayImageUrl } from "../../helpers/content_displayers/displayImageUrl";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Header23 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty,defaultCtaOption } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent(this.props.componentItem)) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;
      this.props.saveContentInStore(
        this.generator.setUpGraphics({
          activeOption: GraphicsOptions.image,
          userCanAddItems: false,
          maxItems: 1,
          fieldInfo: {
            [GraphicsOptions.image]: "Recommended size: 1440x900px",
            [GraphicsOptions.video]: "",
            default: "",
          },
          items: [
            this.generator.createGraphicsContentObject({
              imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CALENDAR,
              videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
              width: 1280,
              height: 800,
            }),
          ],
        })
      );
      this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty}));
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
      this.props.saveContentInStore(
        this.generator.setUpCta({
          activeOption: defaultCtaOption || callToActionOptions.buttons,
          isDefaultEmpty
        })
      );
    }
  }

  render() {
    this.props.connectDefaultFormIntegration(this.props.componentItem);
    const currentActiveOption: GraphicsOptions = _.get(
      this.props.componentItem,
      ["componentData", "graphics", "activeOption"]
    );

    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );
    let imageUrl = displayImageUrl(
      this.props.componentItem.componentData.graphics.content.items[0][
        GraphicsOptions.image
      ]
    ); //This component has exactly one item and more can not be added (see createGraphicsContentObject() above).
    const videoUrl: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.video,
      "youtube",
      "url",
    ]);
    const codeSnippet: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.HTML,
      "codeSnippet",
    ]);

    return (
      <header
        className={`header-23 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.image}`}
      >
        <div className="container container--large header-23__container">
          <div className="header-23__left">
            <div className="header-23__left_content">
              {title.length > 0 && (
                <h1
                  className={
                    "title-text heading heading--accent header-23__heading " +
                    (this.props.isDarkBg ? "text-white" : "")
                  }
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}

              {subtitleText.length > 0 && (
                <p
                  className={
                    "subtitle-text header-23__text content_box " +
                    (this.props.isDarkBg ? "text-white" : "")
                  }
                  dangerouslySetInnerHTML={{ __html: subtitleText }}
                />
              )}

              <div className="header-23__cta_box">
                <WebsiteCTA
                  componentItem={this.props.componentItem}
                  isDarkBg={this.props.isDarkBg}
                />
              </div>
            </div>
          </div>
          <div className="header-23__right">
            <GraphicsJSX
              currentActiveOption={currentActiveOption}
              defaultGraphicsOption={GraphicsOptions.image}
              isDarkBg={this.props.isDarkBg}
              imageUrl={imageUrl}
              videoUrl={videoUrl}
              codeSnippet={codeSnippet}
              classNames={{
                image: "header-23__img",
                video: "header-23__video",
              }}
            />
          </div>
        </div>
      </header>
    );
  }
}

export default Header23;
