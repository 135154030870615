import React, { Component } from "react";
import { Button, Icon, Tag, Tooltip } from "antd";
import { getCurrentWebsiteLiveUrl } from "../../../helpers/getCurrentWebsiteLiveUrl";
import { checkIfObjectEmpty } from "../../../helpers/checkIfObjectEmpty";
import { emojiPath, publicUrl } from "../../../data/urls";
import { getLanguageTitleAndEmojiFromCode } from "../../../helpers/editor/getLanguageTitleAndEmojiFromCode";

interface Props {
  currentWebsite: any;
  currentWebsiteWidgetsObject: any;
  isSimpleCookieWidgetVisible: any;
  formInitialWidgetsObjectIfEmpty: any;
  isCookieWidgetActive: boolean;
  isLanguagesWidgetActive: boolean;
}

class WidgetsPreview extends Component<Props> {
  returnCookieWidgetMarkup = () => {
    let cookieWidgetObject: any;

    // let shouldDisplayDefaultData = false;
    // if (checkIfObjectEmpty(this.props.currentWebsiteWidgetsObject)) {
    //     // if none widgets added to the website so far and the currentWebsiteWidgetsObject is {}
    //     shouldDisplayDefaultData = true
    // } else {
    //     // if has other widgets, but not simple_cookie yet
    //     if (this.props.currentWebsiteWidgetsObject['simple_cookie'] === undefined) {
    //         shouldDisplayDefaultData = true
    //     }
    // }

    // if (shouldDisplayDefaultData) {
    //     cookieWidgetObject = this.props.formInitialWidgetsObjectIfEmpty(this.props.currentWebsiteWidgetsObject)['items']['simple_cookie'];
    // } else {
    cookieWidgetObject = this.props.currentWebsiteWidgetsObject["items"][
      "simple_cookie"
    ];
    // }
    let iconClass = "";
    if (cookieWidgetObject["content"]["has_icon"] === false) {
      iconClass = " simple-cookie-widget--no-icon ";
    }

    // bottom_left, top_left, bottom_right, top_right
    let placementClass =
      " simple-cookie-widget--" + cookieWidgetObject["placement"] + " ";

    // white, black, gray, midnight
    let styleClass =
      " simple-cookie-widget--" + cookieWidgetObject["style"] + " ";

    return (
      <div className={"comps custom_fonts "}>
        <div
          className={
            "simple-cookie-widget " +
            (this.props.isSimpleCookieWidgetVisible ? " state-visible " : " ") +
            iconClass +
            styleClass +
            this.props.currentWebsite.active_font_classname
          }
          style={{bottom: "15px"}}
        >
          <div className="simple-cookie-widget__wrapper">
            <div className="simple-cookie-widget__container container">
              <div className="simple-cookie-widget__message">
                <div className="simple-cookie-widget__col">
                  <img
                    className="simple-cookie-widget__icon"
                    src={
                      publicUrl + emojiPath.prefix + "1f36a.svg"
                    }
                    alt="🍪"
                  />
                  <div className="simple-cookie-widget__col">
                    <span
                      className="simple-cookie-widget__text"
                      dangerouslySetInnerHTML={{
                        __html:
                          cookieWidgetObject["content"]["messageText"][
                            "default"
                          ],
                      }}
                    />
                  </div>
                </div>

                <div className="simple-cookie-widget__button_box">
                  <button
                    onClick={() =>
                      this.setState({ isSimpleCookieWidgetVisible: false })
                    }
                    className="simple-cookie-widget__button button button--white-bg"
                  >
                    <span className="button__text">
                      {cookieWidgetObject["content"]["buttonText"]["default"]}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  returnLangWidgetMarkup = () => {
    let langWidgetObject: any;

    // let shouldDisplayDefaultData = false;
    // if (checkIfObjectEmpty(this.props.currentWebsiteWidgetsObject)) {
    //     // if none widgets added to the website so far and the currentWebsiteWidgetsObject is {}
    //     shouldDisplayDefaultData = true
    // } else {
    //     // if has other widgets, but not simple_cookie yet
    //     if (this.props.currentWebsiteWidgetsObject['simple_cookie'] === undefined) {
    //         shouldDisplayDefaultData = true
    //     }
    // }

    // if (shouldDisplayDefaultData) {
    //     langWidgetObject = this.props.formInitialWidgetsObjectIfEmpty(this.props.currentWebsiteWidgetsObject)['items']['simple_cookie'];
    // } else {
    langWidgetObject = this.props.currentWebsiteWidgetsObject["items"]["lang"];
    // }
    // let iconClass = ''
    // if (langWidgetObject['content']['show_flags'] === false) {
    //     iconClass = ' simple-cookie-widget--no-icon '
    // }

    // bottom_left, top_left, bottom_right, top_right
    let placementClass = " lang-widget--" + langWidgetObject["placement"] + " ";

    // white, black, gray, midnight
    let styleClass = " lang-widget--" + langWidgetObject["style"] + " ";
    let returnWebsiteLanguageOptions = () => {
      if (
        langWidgetObject["content"] &&
        langWidgetObject["content"]["options"] &&
        langWidgetObject["content"]["options"].length > 0
      ) {
        let optionsList = langWidgetObject["content"]["options"];
        let optionsJXS = [];

        for (let i = 0; i < optionsList.length; i++) {
          if (
            optionsList[i]["websiteHomePageURL"] &&
            optionsList[i]["websiteHomePageURL"] !== ""
          ) {
            optionsJXS.push(
              <li
                className="lang-widget__item"
                key={optionsList[i]["code"] + "-" + i}
              >
                <span className={"lang-widget__link link"}>
                  <span className="lang-widget__title">
                    {getLanguageTitleAndEmojiFromCode(optionsList[i]["code"])}
                  </span>
                </span>
              </li>
            );
          }
        }

        if (optionsJXS.length > 0) {
          return optionsJXS;
        } else {
          return null;
        }
      }
    };
    return (
      <div className={"comps custom_fonts "}>
        <div
          className={
            "lang-widget " +
            placementClass +
            styleClass +
            this.props.currentWebsite.active_font_classname
          }
        >
          <div className="lang-widget__wrapper">
            <ul className="lang-widget__list">
              {returnWebsiteLanguageOptions()}
            </ul>
            <div className="lang-widget__current">
              <span className="lang-widget__title">
                {getLanguageTitleAndEmojiFromCode(
                  langWidgetObject["content"]["thisWebsiteCode"]
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="website_widgets__preview">
        <div
          className="website_widgets__preview_box"
          style={{
            backgroundImage:
              "url(" +
              publicUrl +
              "/img/screenshots/website_screenshot_background.jpg" +
              ")",
          }}
        >
          {this.props.isCookieWidgetActive && this.returnCookieWidgetMarkup()}
          {this.props.isLanguagesWidgetActive && this.returnLangWidgetMarkup()}
          {this.props.isSimpleCookieWidgetVisible === false && (
            <Button
              className="website_widgets__redo_button"
              type="primary"
              onClick={() =>
                this.setState({ isSimpleCookieWidgetVisible: true })
              }
            >
              Show widget
              <Icon type="redo" />
            </Button>
          )}
        </div>
        {(this.props.isCookieWidgetActive ||
          this.props.isLanguagesWidgetActive) && (
          <div className="website_widgets__preview_link">
            <a
              target="_blank"
              href={getCurrentWebsiteLiveUrl(this.props.currentWebsite, true)}
            >
              See live website <Icon type="link" />
            </a>
          </div>
        )}
      </div>
    );
  }
}

export default WidgetsPreview;
