import React from "react";
import { GraphicsOptions } from "./editor/component_graphics/data/componentGraphicsData";
import getMockupDeviceRenderImageFullFilepath from "../data/urls";
import GraphicsJSX from "../pageComponents/GraphicsJSX";
import _ from "lodash";
import { displayImageUrl } from "../helpers/content_displayers/displayImageUrl";

interface Props {
  mockupObject: any;
  graphicsObject: any;

  isDarkBg?: boolean;
  currentActiveOption?: GraphicsOptions;
  videoClassNames?: string;
  HTMLClassNames?: string;
  setTabsHeightOnChange?: boolean;
}

const MockupWithContent = (props: Props): JSX.Element => {
  const {
    mockupObject,
    graphicsObject,
    currentActiveOption,
    isDarkBg,
    videoClassNames,
    HTMLClassNames,
    setTabsHeightOnChange,
  } = props;

  const fullFilePath = getMockupDeviceRenderImageFullFilepath(mockupObject);
  const { deviceId } = mockupObject;
  const imgSrc = displayImageUrl(graphicsObject[GraphicsOptions.image]);
  const videoUrl: string = _.get(graphicsObject, [
    GraphicsOptions.video,
    "youtube",
    "url",
  ]);
  const codeSnippet: string = _.get(graphicsObject, [
    GraphicsOptions.HTML,
    "codeSnippet",
  ]);

  const containerClasses = [
    "mockup_v2",
    `mockup_v2--${deviceId ? deviceId : ""}`,
  ].join(" ");

  const handleOnLoad = () => {
    if (setTabsHeightOnChange) {
      window.unicornplatform.tabs.setAll();
    }
  };

  return (
    <div className={containerClasses}>
      <div className="mockup_v2__img_box">
        <div
          className="mockup_v2__screenshot mockup_v2__area mockup_v2__area--editor"
          style={{ position: deviceId === "Empty" ? "relative" : "absolute" }}
        >
          <GraphicsJSX
            defaultGraphicsOption={GraphicsOptions.image}
            currentActiveOption={currentActiveOption}
            isDarkBg={isDarkBg}
            imageUrl={imgSrc}
            videoUrl={videoUrl}
            codeSnippet={codeSnippet}
            classNames={{
              image: "mockup_v2__screenshot_img",
              video: videoClassNames,
              HTML: HTMLClassNames,
            }}
            alt="content"
            permanentSpinnerColor
            setTabsHeightOnChange={setTabsHeightOnChange}
          />
        </div>
        <img
          className="mockup_v2__device_img"
          src={fullFilePath}
          alt="device"
          style={{ display: deviceId === "Empty" ? "none" : "initial" }}
          onLoad={handleOnLoad}
        />
      </div>
    </div>
  );
};

export default MockupWithContent;
