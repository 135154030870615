import { ActionNames } from "./actions";
import {
  AccessToken,
  Action,
  Oauth2State,
  SetIsAccessTokenRevocationInProgressPayload,
} from "./types";

const initialState: Oauth2State = {
  accessTokens: [],
  isAccessTokensDataFetched: false,
  accessTokenRevocationInProgress: [],
};

export const oauth2Reducer = (
  state = initialState,
  action: Action<unknown>
) => {
  switch (action.type) {
    case ActionNames.SAVE_OAUTH2_ACCESS_TOKENS_IN_STATE: {
      const { payload } = action as Action<AccessToken[]>;
      return {
        ...state,
        accessTokens: [...payload],
      };
    }
    case ActionNames.SET_IS_ACCESS_TOKEN_DATA_FETCHED: {
      const { payload } = action as Action<boolean>;
      return {
        ...state,
        isAccessTokensDataFetched: payload,
      };
    }
    case ActionNames.DELETE_OAUTH2_ACCESS_TOKEN: {
      const tokenId = action.payload as number;
      return {
        ...state,
        accessTokens: [
          ...state.accessTokens.filter((token) => {
            return token.id !== tokenId;
          }),
        ],
      };
    }
    case ActionNames.SET_IS_ACCESS_TOKEN_REVOCATION_IN_PROGRESS: {
      const {
        tokenId,
        isInProgress,
      } = action.payload as SetIsAccessTokenRevocationInProgressPayload;
      if (isInProgress)
        return {
          ...state,
          accessTokenRevocationInProgress: [
            ...state.accessTokenRevocationInProgress,
            tokenId,
          ],
        };
      return {
        ...state,
        accessTokenRevocationInProgress: [
          ...state.accessTokenRevocationInProgress.filter((currentTokenId) => {
            return currentTokenId !== tokenId;
          }),
        ],
      };
    }
    default: {
      return state;
    }
  }
};
