import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {pictogramOptions} from "../../data/pictogramOptions";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Text06 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const { isDefaultEmpty } = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: ''}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: ''}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,
                maxItems: 16,
                hasPictogram: true,
                hasSubtitle: true,
                hasThumb: false,
                items: [
                    this.generator.createPostsContentObject({
                        title: "Cloud-based infrastructure",
                        subtitle: 'It is always sunny in the Unicorn Platform land because of the scalable cloud infrastructure.',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '2601.svg'
                        })
                    }),
                    this.generator.createPostsContentObject({
                        title: "The friendliest support",
                        subtitle: 'Our lovely support team is always happy to talk to you and help with any issues.',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '2764.svg'
                        })
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the component.",
                    default: "Add a button to the bottom of the component.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'See also'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        return (
            <section>
                <div className="text--06">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />


                    <div className="container container--small">
                        <ul className="text--06__list">
                            {posts && posts.map((item:any, key:number) => {
                                if(item !== undefined){
                                    return (
                                        <li className="text--06__item" key={'text-06-' + key}>
                                            {item.href !== '' && <a className="text--06__link bg-medium-hover" href={returnHref(item.href)}>
												<div className="text--06__img">
                                                    <Pictogram 
                                                      pictogramObject={item.pictogram}
                                                      className=''
                                                    />
												</div>
												<div className="text--06__text">
                                                    <div className={"content_box " + (this.props.isDarkBg ? 'text-white' : '')}>
                                                        <h2 className={"def-22_4 title-text--inner " + (this.props.isDarkBg ? 'text-white' : '')}>{item.title}</h2>
                                                        <p className={"content-text " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: item.subtitle}}/>
                                                    </div>
												</div>
											</a>}

                                            {item.href === '' && <div className="text--06__box">
												<div className="text--06__img">
                                                    <Pictogram 
                                                      pictogramObject={item.pictogram}
                                                      className=''
                                                    />
												</div>
												<div className="text--06__text">
                                                    <div className={"content_box " + (this.props.isDarkBg ? 'text-white' : '')}>
                                                        <h2 className={"def-22_4 title-text--inner " + (this.props.isDarkBg ? 'text-white' : '')}>{item.title}</h2>
                                                        <p className={"content-text " + (this.props.isDarkBg ? 'text-white' : '')} dangerouslySetInnerHTML={{__html: item.subtitle}}/>
                                                    </div>
												</div>
											</div>}
                                        </li>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </ul>
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Text06;
