import { WebsitePagesState } from "../../store/websitePages/types";
import { WebsitesItem } from "../../store/websites/types";
import { sortByDateFn } from "../sortByDateFn";

interface Payload {
  currentWebsite: WebsitesItem;
  websitesPages: WebsitePagesState;
}
export const redirectPages = ({ currentWebsite, websitesPages }: Payload) => {
  if (!currentWebsite) return null;

  const { subdomain } = currentWebsite;

  const pages = [...websitesPages.items]
    .sort(sortByDateFn)
    .sort((a, b) => {
      if (a.url === "") return -1;
      if (b.url === "") return 1;
      return 0;
    })
    .filter((page) => {
      return page.website === currentWebsite.id;
    });

  const firstPage = pages[0];
  if (firstPage) {
    return `/${subdomain}/pages/${firstPage.id}`;
  }

  return null;
};
