import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import Slider from "react-slick";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteLogo from "../component_molecules/WebsiteLogo";
import { publicUrl } from "../../data/urls";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Testimonials02 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: ""}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: ''}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,
                maxItems: 36,
                hasTitle: false,
                hasThumb: false,
                hasText: true,
                hasLogo: true,
                hasButton: true,
                fieldTitle: {default: "Testimonials"},
                label: {default: "Edit the testimonials:"},
                labelTooltip: {default: "Showcase testimonials of your happy clients."},
                buttonTypes: [buttonStyles.ghost.typeTitle],

                items: [
                    this.generator.createPostsContentObject({
                        subtitle: 'John Alen, Co-founder & CMO',
                        text: '"Lotus nixs ducunt ad domina. A falsis, orgia festus castor. Sunt brodiumes convertam superbus, fortis galataees. Pol, historia! Nobilis sagas ducunt ad sectam. Eheu. Nunquam quaestio medicina. Acipensers ire! Cur era messis? Cum accentor velum, omnes demolitionees experientia talis, neuter finises. Lotus brabeuta callide anhelares messor est, grandis nuclear vexatum iacere."',
                        logo: this.generator.createLogoContentObject({
                            height: 30,
                            uploadedSrc: publicUrl + '/img/logos/companies/' + 'intercom.svg'
                        }),
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "", buttonHref: '', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline, buttonIsTargetBlank: true}),
                    }),
                    this.generator.createPostsContentObject({
                        subtitle: 'Bob Musk, Chief Designer',
                        text: '"Hippotoxotas crescere! Burgus de flavum omnia, quaestio mons! Audax accentor unus experientias xiphias est. A falsis, rumor lotus finis. Ignigena de secundus exsul, locus idoleum! Nunquam locus torquis. Sunt absolutioes amor neuter, castus plasmatores. A falsis, lura lotus luna. Ferox, placidus caesiums acceleratrix captis de fatalis."',
                        logo: this.generator.createLogoContentObject({
                            height: 30,
                            uploadedSrc: publicUrl + '/img/logos/companies/' + 'instacart.svg'
                        }),
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "", buttonHref: '', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline, buttonIsTargetBlank: true}),
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the posts component to invite users to read your blog.",
                    default: "Add a button to the bottom of the posts component to invite users to read your blog.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        return (
            <section>
                <div className="testimonials-02">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--large testimonials-02__wrapper">
                        {posts && posts.map((item:any, key:number) => {
                            if(item !== undefined){
                                return (
                                    <div className="sh-4 card-container testimonials-02__item" key={"testimonials-02-item-" + key}>
                                        {item.logo.type !== '' && item.logo.uploadedSrc !== '' && <WebsiteLogo logoObject={item.logo} className='testimonials-02__logo' />}
                                        <div className="content_box ">
                                            <div className="testimonials-02__text content-text" dangerouslySetInnerHTML={{__html: item.text}}/>
                                        </div>
                                        <div className="content-text def-12 testimonials-02__author" dangerouslySetInnerHTML={{__html: item.subtitle}}/>
                                        <div className="testimonials-02__box">
                                            {item.button && item.button.title !== '' && item.button.href !== '' && <div className="feature__button_box">
                                                <WebsiteButton config={{
                                                    buttonStyleType: item.button.settings.appearance.styleType,
                                                    buttonColor: item.button.settings.appearance.color,
                                                    buttonType: linkOrButtonOptions.link,
                                                    buttonTitle: item.button.title,
                                                    buttonHref: item.button.href,
                                                    buttonIsTargetBlank: item.button.isTargetBlank,
                                                    buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                                    pillPrefix: item.button.pillPrefix,
                                                    pillColor: item.button.settings.appearance.pillColor,
                                                    ghostColor: item.button.settings.appearance.ghostColor,
                                                }} />
											</div>}
                                        </div>
                                    </div>
                                )
                            }else{
                                return (null);
                            }
                        })}
                    </div>
                    <div className="bottom_cta container">
                        {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                    </div>
                </div>
            </section>
        )
    }
}

export default Testimonials02;
