import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {pictogramOptions} from "../../data/pictogramOptions";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Pictogram from "../component_molecules/Pictogram";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Posts04 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({titleText: "Brand Assets",isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: 'Seculas unda in festus brema! Lotus, azureus lanistas mechanice desiderium de regius, castus particula.'}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,
                maxItems: 4,
                hasPictogram: true,
                hasThumb: false,
                items: [
                    this.generator.createPostsContentObject({
                        title: "Logos",
                        href: '#',
                        subtitle: 'Download our logos in SVG.',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f30e.svg'
                        }),
                    }),
                    this.generator.createPostsContentObject({
                        title: "Bio",
                        href: '#',
                        subtitle: 'Read the history and the team',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f36a.svg'
                        }),
                    }),
                    this.generator.createPostsContentObject({
                        title: "Style Guides",
                        href: '#',
                        subtitle: 'Get our brand style guides',
                        pictogram: this.generator.createPictogramContentObject({
                            type: pictogramOptions.emoji.title,
                            emojiSrc: '1f984.svg'
                        }),
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the component to offer users to download more assets.",
                    default: "Add a button to the bottom of the component to offer users to download more assets.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        return (
            <section>
                <div className="posts-04">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container container--mid">
                        <div className="sh-4 card-container posts-04__wrapper">
                            {posts && posts.map((item:any, key:number) => {
                                if(item !== undefined && item.href !== ''){
                                    return (
                                        <a className="posts-04__item" key={'posts-04-' + key} href={returnHref(item.href)}>
                                            { item.pictogram.type && item.pictogram.type !== '' && <Pictogram pictogramObject={item.pictogram} className='posts-04__img' />}

                                            <h2 className="title-text--inner posts-04__title">{ item.title }</h2><span className="content-text def-14 posts-04__text">{ item.subtitle }</span>
                                        </a>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Posts04;
