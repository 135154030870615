export const websiteColors = [
    {
        styleClassname: 'style-blue-1',
        styleTitle: 'Acid blue',

        // User input
        primaryButton: '#4D61FC',
        secondaryButton: '#F90473',
        accent: '#4D61FC',
    },
    {
        styleClassname: 'style-blue-2',
        styleTitle: 'Magic blue',

        // User input
        primaryButton: '#5041bb',
        secondaryButton: '#F90473',
        accent: '#5041bb',
    },
    {
        styleClassname: 'style-blue-3',
        styleTitle: 'Calm blue',

        // User input
        primaryButton: '#508de8',
        secondaryButton: '#F90473',
        accent: '#508de8',
    },
    {
        styleClassname: 'style-blue-4',
        styleTitle: 'Mature blue',

        // User input
        primaryButton: '#164085',
        secondaryButton: '#F90473',
        accent: '#164085',
    },

    {
        styleClassname: 'style-green-1',
        styleTitle: 'Azure',

        // User input
        primaryButton: '#4ADDB4',
        secondaryButton: '#F90473',
        accent: '#4ADDB4',
    },
    {
        styleClassname: 'style-green-2',
        styleTitle: 'Natural green',

        // User input
        primaryButton: '#29c853',
        secondaryButton: '#F90473',
        accent: '#29c853',
    },


    {
        styleClassname: 'style-purple-1',
        styleTitle: 'Sour purple',

        // User input
        primaryButton: '#943bdc',
        secondaryButton: '#F90473',
        accent: '#943bdc',
    },
    {
        styleClassname: 'style-purple-2',
        styleTitle: 'Serene purple',

        // User input
        primaryButton: '#643995',
        secondaryButton: '#F90473',
        accent: '#643995',
    },

    {
        styleClassname: 'style-orange-1',
        styleTitle: 'Risky orange',

        // User input
        primaryButton: '#fd3f0f',
        secondaryButton: '#4D61FC',
        accent: '#fd3f0f',
    },
    {
        styleClassname: 'style-orange-2',
        styleTitle: 'Mild orange',

        // User input
        primaryButton: '#d13a35',
        secondaryButton: '#4D61FC',
        accent: '#d13a35',
    },
    {
        styleClassname: 'style-orange-3',
        styleTitle: 'Cool orange',

        // User input
        primaryButton: '#fb5168',
        secondaryButton: '#4D61FC',
        accent: '#fb5168',
    },

    {
        styleClassname: 'style-yellow-1',
        styleTitle: 'Catchy lemon',

        // User input
        primaryButton: '#fbd92d',
        secondaryButton: '#4D61FC',
        accent: '#fbd92d',
    },

    {
        styleClassname: 'style-black-1',
        styleTitle: 'Bold black',

        // User input
        primaryButton: '#303030',
        secondaryButton: '#4D61FC',
        accent: '#303030',
    },
];