import returnAuthHeaderForAJAX from "../auth/returnAuthHeaderForAJAX";
import { UserEventObject } from "../types/globalTypes";
import { api } from "../../data/urls";
import axios, { AxiosResponse } from "axios";
import _ from "lodash";

export const setUserEventsAsync = (
  eventObject: UserEventObject,
  accessToken: string,
  currentEventsObject: UserEventObject,
  overrideExistingEvents?: boolean
): Promise<AxiosResponse<UserEventObject>> => {
  let dataToServerObject: UserEventObject = {
    ...eventObject,
    ...currentEventsObject,
  };
  if (overrideExistingEvents) {
    dataToServerObject = {
      ...eventObject,
    };
  }

  return new Promise((resolve, reject) => {
    if (_.isEqual(dataToServerObject, currentEventsObject)) {
      resolve(null);
      return;
    }
    
    axios
      .post(api.account.setUserEvents, dataToServerObject, {
        ...returnAuthHeaderForAJAX(accessToken),
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
