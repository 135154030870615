import {
  CrispEvents,
  FacebookEvents,
  GoogleTagEvents,
} from "../../enums/AnalyticsEventsEnums";
import _ from "lodash";

class fireAnalyticsEvent {
  static fireCrisp = (
    eventType: CrispEvents,
    metaData?: object,
    isError?: boolean
  ) => {
    (window as any).$crisp.push([
      "set",
      "session:event",
      [
        [
          [
            eventType ? eventType : CrispEvents.undefined,
            _.omitBy(metaData, _.isNil), // Crisp won't accept properties that are null or undefined.
            isError ? "red" : metaData ? "black" : "blue",
          ],
        ],
      ],
    ]);
  };

  static fireFacebook = (eventType: FacebookEvents, ...args) => {
    (window as any).fbq("track", eventType, ...args);
  };

  static fireGoogleTag = (eventType: GoogleTagEvents, data?: object) => {
    (window as any).dataLayer.push({ event: eventType, ...data });
  };
}

export default fireAnalyticsEvent;
