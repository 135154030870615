import React from "react";
import { Checkbox } from "antd";
import { buttonStyles } from "../../../../data/buttonStyles";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { getFullWebsiteUrl } from "../../../../helpers/editor/getFullWebsiteUrl";
import DebouncedInput from "../../../DebouncedInput";
import checkIsButtonHrefDisabled from "../../../../helpers/editor/checkIsButtonHrefDisabled";
import { useCurrentInputData } from "../useCurrentInputData";

interface Props {
  item: any;
  keyProp: number;
  saveContentInStore: any;
  generator: any;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;
  toggleNewEditionsDetected: (value: boolean) => void;
  displayButtonStyleEditorOpener: (
    currentButtonObject: any,
    handler: any,
    shouldDisplayForcedWhiteButtonsWarning?: boolean
  ) => JSX.Element;
  state: any;
  setState: (newState: any) => void;
  currentWebsite: any;
  displayButtonPluginsDrawerOpener: (
    item: any,
    itemIndex: number,
    galleryItemIndex?: number,
    isYearlyPricingButtonPassed?: boolean
  ) => JSX.Element;
}

const StepButtonSection = (props: Props): JSX.Element => {
  const {
    item,
    saveContentInStore,
    generator,
    contentType,
    currentInputData,
    keyProp,
    toggleNewEditionsDetected,
    displayButtonStyleEditorOpener,
    state,
    setState,
    currentWebsite,
    displayButtonPluginsDrawerOpener,
  } = props;
  const currentInputDataRef = useCurrentInputData(currentInputData);

  const doesButtonHavePluginActionInsteadOfHref = checkIsButtonHrefDisabled(
    item.button,
    currentWebsite
  );

  const onChangeStepButtonPillPrefixHandler = (value: any) => {
    saveContentInStore(
      generator.changeStepButtonPillPrefix(
        { [contentType]: currentInputDataRef.current },
        value,
        keyProp
      )
    );
    toggleNewEditionsDetected(true);
  };
  const onChangeStepButtonTitleHandler = (value: any) => {
    saveContentInStore(
      generator.changeStepButtonTitle(
        { [contentType]: currentInputDataRef.current },
        keyProp,
        value
      )
    );
    toggleNewEditionsDetected(true);
  };
  const onChangeStepButtonHrefHandler = (value: any) => {
    saveContentInStore(
      generator.changeStepButtonHref(
        { [contentType]: currentInputDataRef.current },
        keyProp,
        value
      )
    );
    toggleNewEditionsDetected(true);
  };

  if (!currentInputDataRef.current.hasButton) return null;

  return (
    <div className="content-input__group">
      {item.button.title !== "" &&
        displayButtonStyleEditorOpener(item.button, () => {
          setState({
            ...state,
            currentStepsItemIndex: keyProp, //we need to know the index of a step to edit it's button. Same for any other countable elements with buttons or pictograms.
            buttonStylesOptions: currentInputDataRef.current.buttonTypes,
            currentButtonObject: item.button,
          });
        })}

      {/*If this button has an active styleType === 'pill' we show additional field - for prefix.*/}
      {item.button.title !== "" &&
        item.button.settings.appearance.styleType ===
          buttonStyles.pill.typeTitle && (
          <DebouncedInput
            onChange={onChangeStepButtonPillPrefixHandler}
            defaultValue={item.button.pillPrefix}
            placeholder="Learn"
            className="content-input__input"
          />
        )}

      <DebouncedInput
        className="content-input__input"
        onChange={onChangeStepButtonTitleHandler}
        defaultValue={item.button.title}
        placeholder="Read more info"
      />
      <div className="content-input__input">
        <DebouncedInput
          className=""
          onChange={onChangeStepButtonHrefHandler}
          defaultValue={item.button.href}
          disabled={doesButtonHavePluginActionInsteadOfHref}
          addonAfter={
            // Adds plugins (payment, UTM relay etc) to a button.
            displayButtonPluginsDrawerOpener(item, keyProp)
          }
          placeholder={
            getFullWebsiteUrl(currentWebsite) +
            "/tutorial-" +
            Math.floor(Math.random() * 20 + 10)
          } //e.g. https://stripe.com/tutorial-12
        />
      </div>
      {doesButtonHavePluginActionInsteadOfHref === false &&
        item.button.href !== "" &&
        item.button.title !== "" && (
          <Checkbox
            className="content-input__input"
            checked={item.button.isTargetBlank}
            onChange={(event: any) => {
              saveContentInStore(
                generator.changeStepButtonIsTargetBlank(
                  {
                    [contentType]: currentInputDataRef.current,
                  },
                  keyProp,
                  event.target.checked
                )
              );
              toggleNewEditionsDetected(true);
            }}
          >
            Open in a new tab
          </Checkbox>
        )}
    </div>
  );
};

export default StepButtonSection;
