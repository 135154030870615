import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import Slider from "react-slick";
import NextArrow from "../slider/NextArrow";
import PrevArrow from "../slider/PrevArrow";
import {DEFAULT_IMAGES_CDN, pressLogos, publicUrl} from "../../data/urls";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import WebsiteLogo from "../component_molecules/WebsiteLogo";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Posts03 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({titleText: "",isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({subtitleText: '',isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,
                maxItems: 36,
                hasButton: true,
                hasText: true,
                hasLogo: true,
                labelTooltip: {default: "Showcase your best cases or testimonials."},
                hasTags: true,
                buttonTypes: [buttonStyles.ghost.typeTitle],

                items: [
                    this.generator.createPostsContentObject({
                        title: "Discover how ACME Inc increased their sales by 15% with Unicorn Platform",
                        subtitle: 'Mich Douglas, Co-founder & CEO',
                        text: '"Cras quis nulla commodo, aliquam lectus sed, blandit augue. Cras ullamcorper bibendum bibendum. Duis tincidunt urna non pretium porta. Nam condimentum vitae ligula vel ornare. Phasellus at semper turpis. Nunc eu tellus tortor. Etiam at condimentum nisl, vitae sagittis orci. Donec id dignissim nunc."',
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.PHOTO_06,
                            width: 4000,
                            height: 6000
                        }),
                        logo: this.generator.createLogoContentObject({
                            height: 30,
                            uploadedSrc: publicUrl + '/img/logos/companies/' + 'intercom.svg'
                        }),
                        button: this.generator.createButtonContentObject({buttonType: linkOrButtonOptions.link, buttonTitle: "Learn more", buttonHref: '#', buttonStyleType: buttonStyles.ghost.typeTitle, ghostColor: buttonStyles.ghost.accentOutline, buttonIsTargetBlank: true}),
                    }),

                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the posts component to invite users to read your blog.",
                    default: "Add a button to the bottom of the posts component to invite users to read your blog.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        let settings = {
            dots: true,
            infinite: true,
            speed: 300,
            fade: false,
            cssEase: 'ease',
            adaptiveHeight: false,
            // lazyLoad: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
        };

        return (
            <section>
                <div className={"posts-03 " + (posts.length > 1 ? 'slider_with_dots_inside' : '')}>
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />
                    <div className="container">
                        <div className="slider">
                            <Slider {...settings}>
                                {posts && posts.map((item:any, key:number) => {
                                    if(item !== undefined){
                                        let hasThumb = item.thumb && item.thumb.image && item.thumb.image.url !== '';
                                        return (
                                            <div className="posts-03__item" key={"post-03-slider-item-" + key}>
                                                <div className={"sh-4 card-container posts-03__wrapper " + ((hasThumb === false) ? 'posts-03__wrapper--small ' : '')}>
                                                    {hasThumb && <div className="posts-03__img_box"><img className="posts-03__img" src={formatImageUrl(item.thumb.image.url)} alt={item.thumb.image.alt}/><img className="posts-03__bg_img" src={formatImageUrl(item.thumb.image.url)} alt={item.thumb.image.alt}/></div>}
                                                    <div className={"posts-03__text_box " + ((hasThumb === false) ? 'posts-03__text_box--no-thumb' : '')}>
                                                        <div className="posts-03__body">
                                                            {item.logo.type !== '' && item.logo.uploadedSrc !== '' && <WebsiteLogo logoObject={item.logo} className='posts-03__logo' />}
                                                            {item.title && item.title !== '' && <h3 className="def-18_72 title-text--inner posts-03__title" dangerouslySetInnerHTML={{__html: item.title}}/>}
                                                            <div className="content_box ">
                                                                {item.text && item.text !== '' && <div className="content-text posts-03__text" dangerouslySetInnerHTML={{__html: item.text}}/>}
                                                            </div>
                                                            {item.subtitle && item.subtitle !== '' && <span className="content-text def-12 posts-03__author" dangerouslySetInnerHTML={{__html: item.subtitle}}/>}
                                                        </div>
                                                        {item.button && item.button.title !== '' && <div className="feature__button_box">

                                                                <WebsiteButton config={{
                                                                    buttonStyleType: item.button.settings.appearance.styleType,
                                                                    buttonColor: item.button.settings.appearance.color,
                                                                    buttonType: linkOrButtonOptions.link,
                                                                    buttonTitle: item.button.title,
                                                                    buttonHref: item.button.href,
                                                                    buttonIsTargetBlank: item.button.isTargetBlank,
                                                                    buttonMobileAppStoreType: item.button.settings.appearance.mobileAppStoreType,
                                                                    pillPrefix: item.button.pillPrefix,
                                                                    pillColor: item.button.settings.appearance.pillColor,
                                                                    ghostColor: item.button.settings.appearance.ghostColor,
                                                                }} />

                                                        </div>}
                                                        {/*<ul className="posts-03__tags_box">*/}
                                                        {/*<li className="posts-03__tag"><span className="tag   tag--blue"><span*/}
                                                        {/*className="tag__text">case</span></span>*/}
                                                        {/*</li>*/}
                                                        {/*<li className="posts-03__tag"><span className="tag   tag--acid"><span*/}
                                                        {/*className="tag__text">marketing</span></span>*/}
                                                        {/*</li>*/}
                                                        {/*</ul>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }else{
                                        return (null);
                                    }
                                })}
                            </Slider>
                        </div>

                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>

                    </div>
                </div>
            </section>
        )
    }
}

export default Posts03;
