import React from "react";
import { Button } from "antd";
import {
  ChangeWebsiteItemGlobalData,
  ChangeWebsitesStateGlobalData,
  GetCurrentStoreData,
  SaveWebsiteBackup,
  WebsitesState,
} from "../../store/websites/types";
import { connect } from "react-redux";
import {
  changeWebsiteItemGlobalData,
  changeWebsitesStateGlobalData,
} from "../../store/websites/actions";
import _ from "lodash";
import { getCurrentStoreData, saveWebsiteBackup } from "../../store/websites/thunks";
import { updateWebsiteAsync } from "../../helpers/async/updateWebsiteAsync";
import { AuthState } from "../../store/auth/types";

interface Props {
  auth: AuthState;
  websites: WebsitesState;
  changeWebsitesStateGlobalData: ChangeWebsitesStateGlobalData;
  getCurrentStoreData: GetCurrentStoreData;
  changeWebsiteItemGlobalData: ChangeWebsiteItemGlobalData;
  saveWebsiteBackup: SaveWebsiteBackup;

  onDiscard: () => void;
}

const UnsavedChangesModal = (props: Props): JSX.Element => {
  const {
    onDiscard,
    websites,
    changeWebsitesStateGlobalData,
    getCurrentStoreData,
    auth,
    changeWebsiteItemGlobalData,
    saveWebsiteBackup,
  } = props;

  const saveChanges = async () => {
    const { currentWebsite } = getCurrentStoreData();
    changeWebsitesStateGlobalData({
      isCustomStylesWaitingForServer: true,
    });
    try {
      await saveWebsiteBackup({has_styles: true});
    } catch(e) {
      console.error(_.get(e, "response.data"));
    }
    const custom_styles = _.get(currentWebsite, "custom_styles", {});
    updateWebsiteAsync({
      accessToken: auth.accessToken,
      subdomain: currentWebsite.subdomain,
      data: { custom_styles },
    })
      .then(({ data }) => {
        
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      })
      .finally(() => {
        changeWebsiteItemGlobalData({
          websiteId: currentWebsite.id,
          data: {
            hasUnsavedChanges: false,
          },
        });
        changeWebsitesStateGlobalData({
          isCustomStylesWaitingForServer: false,
        });
        onDiscard();
      });
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <Button
          type="primary"
          onClick={saveChanges}
          loading={websites.isCustomStylesWaitingForServer}
        >
          Save changes
        </Button>
        <Button
          disabled={websites.isCustomStylesWaitingForServer}
          onClick={onDiscard}
        >
          Discard
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  websites: state.websites,
  auth: state.auth,
});
export default connect(mapStateToProps, {
  changeWebsitesStateGlobalData,
  getCurrentStoreData,
  changeWebsiteItemGlobalData,
  saveWebsiteBackup,
})(UnsavedChangesModal);
