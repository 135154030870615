import React from "react";
import { Button } from "antd";
import { urls } from "../../../../data/urls";

const DownloadAssetsButton = (): JSX.Element => {
  return (
    <div className="html-export__assets">
      <Button
        type="primary"
        icon={"download"}
        href={urls.download.assetsArchive}
      >
        Download assets
      </Button>
    </div>
  );
};

export default DownloadAssetsButton;
