import { WebsitePagesState } from "../store/websitePages/types";
import { WebsitesItem } from "../store/websites/types";

export const getCurrentWebsitesDynamicPages = (
  websitesPages: WebsitePagesState,
  currentWebsite: WebsitesItem
) => {
  return websitesPages.items.filter((item) => {
    return (
      item.website === currentWebsite.id &&
      !item.is_deleted &&
      !!item.api_source_url
    );
  });
};
