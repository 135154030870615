// Sort in alphabetical order if the values are strings, or in numerical order if the values are numbers.
import { convertToNumber } from "./convertToNumber";

export const sortPrimitivesFn = (a: string | number, b: string | number) => {
  const isNumbers = !isNaN(convertToNumber(a)) && !isNaN(convertToNumber(b));
  if (isNumbers) {
    a = convertToNumber(a);
    b = convertToNumber(b);
    return a - b;
  }
  return a.toString().toLowerCase().localeCompare(b.toString().toLowerCase());
};
