export const convertAttributeStringToObject = (attributeString) => {
  if (!attributeString) return {};
  try {
    return attributeString.split(" ").reduce((acc, attr) => {
      const [key, value] = attr.split("=");
      acc[key] = value
        ? value.replace(/(^\'|\'$)/g, '"').replace(/(^\"|\"$)/g, "")
        : "true";
      return acc;
    }, {});
  } catch (error) {
    console.log(error);
    return {};
  }
};
