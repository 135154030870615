import {
  BUNNY_CDN,
  BUNNY_S3_CDN,
  S3_BLOG,
  UCARE_CDN,
} from "./../../data/constants";

export const formatImageUrl = (url: string) => {
  if (!url) return url;
  if (url.includes(UCARE_CDN)) {
    return url.replace(UCARE_CDN, BUNNY_CDN);
  }
  if (url.includes(S3_BLOG)) {
    return url.replace(S3_BLOG, BUNNY_S3_CDN);
  }
  return url;
};
