import React from "react";
import { Radio } from "antd";
import { getAvailableGraphicsOptions } from "../helpers/getAvailableGraphicsOptions";
import capitalizeString from "../../../../helpers/strings/capitalizeString";
import {
  contentTypesWithGraphicsOptions,
  GraphicsOptions,
} from "../data/componentGraphicsData";
import ContentInputsTypesEnum from "../../../../enums/ContentInputsTypesEnum";
import { RadioChangeEvent } from "antd/lib/radio";
import { setGraphicsActiveOption } from "../helpers/setGraphicsActiveOption";
import _ from "lodash";
import fireAnalyticsEvent from "../../../../helpers/editor/fireAnalyticsEvent";
import { CrispEvents } from "../../../../enums/AnalyticsEventsEnums";

interface Props {
  componentType: string;
  componentTitle: string;
  contentType: ContentInputsTypesEnum;
  currentInputData: any;

  saveContentInStore: (contentObj: object) => void;
  toggleNewEditionsDetected: (payload: boolean) => void;

  setActiveOptionInState: (activeOption: GraphicsOptions) => void;
}

const GraphicsPicker = (props: Props): JSX.Element => {
  const {
    componentType,
    componentTitle,
    contentType,
    currentInputData,
    saveContentInStore,
    toggleNewEditionsDetected,
    setActiveOptionInState,
  } = props;

  const availableOptions = getAvailableGraphicsOptions(
    componentType,
    componentTitle
  );

  if (
    !availableOptions ||
    !contentTypesWithGraphicsOptions.includes(contentType)
  ) {
    return null;
  }

  const activeOptionDefaultValue: GraphicsOptions = _.get(
    currentInputData,
    "activeOption",
    GraphicsOptions.image
  );

  const handleOnChange = (event: RadioChangeEvent) => {
    saveContentInStore(
      setGraphicsActiveOption(
        { [contentType]: currentInputData },
        contentType,
        event.target.value
      )
    );
    setActiveOptionInState(event.target.value);
    toggleNewEditionsDetected(true);
    
    fireAnalyticsEvent.fireCrisp(CrispEvents.changeGraphicsOption, {
      component: `${componentType}-${componentTitle}`,
      new_option: event.target.value,
    });
  };

  const displayAvailableOptions = () => {
    return availableOptions.map((option, index) => {
      return (
        <Radio.Button value={option} key={index}>
          <span className="content-input__button-text">
            {capitalizeString(option)}
          </span>
        </Radio.Button>
      );
    });
  };

  return (
    <Radio.Group
      className="content-input__active_type"
      defaultValue={activeOptionDefaultValue}
      size="small"
      onChange={handleOnChange}
    >
      {displayAvailableOptions()}
    </Radio.Group>
  );
};

export default GraphicsPicker;
