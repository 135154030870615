import _ from "lodash";
import { getActualComponentName } from "./getActualComponentName";
import { galleryComponents, headersWithoutGraphics } from "../data/componentExceptions";
import trimStringTo from "./strings/trimStringTo";
import { formatImageUrl } from "./strings/formatImageUrl";

export const getMetaDataFromComponentsArray = (
  pageComponentsItems
) => {
  const componentsArray = _.get(pageComponentsItems, "data.schema", []);

  let firstTitle: string = null;
  let firstSubtitle: string = null;
  let heroImageUrl: string = null;
  let heroImageUuid: string = null;

  componentsArray.forEach((component) => {
    if (firstTitle === null) {
      const title = _.get(component, "componentData.title.content.markup");
      if (title) {
        const parser = new DOMParser();
        firstTitle = parser.parseFromString(title, "text/html").body
          .textContent;
      }
    }
    if (firstSubtitle === null) {
      const subtitle = _.get(
        component,
        "componentData.subtitleText.content.markup"
      );
      if (subtitle) {
        const parser = new DOMParser();
        firstSubtitle = parser.parseFromString(subtitle, "text/html").body
          .textContent;
      }
    }
    if (heroImageUrl === null) {
      const componentName = getActualComponentName(component);
      let image = _.get(
        component,
        "componentData.graphics.content.items.0.image",
        {}
      );
      let activeOption = _.get(
        component,
        "componentData.graphics.activeOption"
      );
      if (galleryComponents.includes(componentName)) {
        image = _.get(
          component,
          "componentData.gallery.content.items.0.contentGraphic.0.image",
          {}
        );
        activeOption = _.get(
          component,
          "componentData.gallery.activeOption"
        );
      }
      if (image.url && activeOption !== "video" && activeOption !== "HTML") {
        if (!headersWithoutGraphics.includes(componentName)) {
          heroImageUrl = image.url;
          heroImageUuid = image.uuid;
        }
      }
    }
  });
  const first_page_title = trimStringTo((firstTitle || "").trim().replace(/\n/g, ' '), 200);
  const first_page_subtitle = trimStringTo((firstSubtitle || "").trim().replace(/\n/g, ' '), 500);
  const hero_image_url = formatImageUrl(heroImageUrl || "");
  const hero_image_uuid = heroImageUuid || "";

  return {
    first_page_title,
    first_page_subtitle,
    hero_image_url,
    hero_image_uuid,
  };
};
