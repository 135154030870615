import _ from "lodash";
import { ActionNames } from "./actions";
import {
  Action,
  AddComponentIdPayload,
  AddChatMessagePayload,
  AddModifiedComponentPayload,
  DeleteCompletionProgressPayload,
  DeleteModifiedComponentPayload,
  GptState,
  SetChangeHistoryDataPayload,
  SetCompletionStatusPayload,
  SetIsLoadingPayload,
  SetIsOpenedOncePayload,
  SetIsTypingPayload,
  EditChatMessagePayload,
  SetModePayload,
  EditInputDataPayload,
  AddPromptHistoryItemPayload,
  DeletePromptHistoryItemPayload,
  PromptHistoryItem,
  AddPromptHistoryForAiItemPayload,
} from "./types";
import { GPT_PROMPT_HISTORY_MAX_LENGTH } from "../../data/constants";

let initialPromptHistory: PromptHistoryItem[] = [];
if (localStorage.getItem("promptHistory")) {
  try {
    const historyFromLocalStorage = JSON.parse(
      localStorage.getItem("promptHistory")
    );
    if (Array.isArray(historyFromLocalStorage)) {
      initialPromptHistory = historyFromLocalStorage;
    }
  } catch (error) {}
}

const initialState: GptState = {
  chats: [],
  currentlyModifiedComponents: [],
  changeHistory: [],
  contextualInputs: [],
  promptHistory: initialPromptHistory,
  promptHistoryForAi: [],
};

export const gptReducer = (state = initialState, action: Action<unknown>) => {
  switch (action.type) {
    case ActionNames.ADD_CHAT_MESSAGE: {
      const { pageId, chatMessage } = action.payload as AddChatMessagePayload;
      let currentChatsArray = [...state.chats];
      const currentChat = currentChatsArray.find(
        (chat) => chat.pageId === pageId
      );
      if (currentChat) {
        currentChat.chatMessages.push({
          componentIds: [],
          ...chatMessage,
          messageId: currentChat.totalMessagesCreated,
        });
        currentChat.totalMessagesCreated += 1;
        return {
          ...state,
          chats: currentChatsArray,
        };
      }
      currentChatsArray.push({
        pageId,
        chatMessages: [
          {
            ...chatMessage,
            messageId: 0,
          },
        ],
        isTyping: "none",
        isOpenedOnce: false,
        totalMessagesCreated: 1,
        isLoading: "none",
        mode: "edit",
      });
      return {
        ...state,
        chats: currentChatsArray,
      };
    }

    case ActionNames.SET_IS_TYPING: {
      const { pageId, isTyping } = action.payload as SetIsTypingPayload;
      let currentChatsArray = [...state.chats];
      const currentChat = currentChatsArray.find(
        (chat) => chat.pageId === pageId
      );
      if (currentChat) {
        currentChat.isTyping = isTyping;
        return {
          ...state,
          chats: currentChatsArray,
        };
      }
      currentChatsArray.push({
        pageId,
        chatMessages: [],
        isTyping,
        isOpenedOnce: false,
        totalMessagesCreated: 0,
        isLoading: "none",
        mode: "edit",
      });
      return {
        ...state,
        chats: currentChatsArray,
      };
    }

    case ActionNames.SET_IS_OPENED_ONCE: {
      const { pageId, isOpenedOnce } = action.payload as SetIsOpenedOncePayload;
      let currentChatsArray = [...state.chats];
      const currentChat = currentChatsArray.find(
        (chat) => chat.pageId === pageId
      );
      if (currentChat) {
        currentChat.isOpenedOnce = isOpenedOnce;
        return {
          ...state,
          chats: currentChatsArray,
        };
      }
      currentChatsArray.push({
        pageId,
        chatMessages: [],
        isTyping: "none",
        isOpenedOnce,
        totalMessagesCreated: 0,
        isLoading: "none",
        mode: "edit",
      });
      return {
        ...state,
        chats: currentChatsArray,
      };
    }

    case ActionNames.SET_IS_LOADING: {
      const { pageId, isLoading } = action.payload as SetIsLoadingPayload;
      let currentChatsArray = [...state.chats];
      const currentChat = currentChatsArray.find(
        (chat) => chat.pageId === pageId
      );
      if (currentChat) {
        currentChat.isLoading = isLoading;
        return {
          ...state,
          chats: currentChatsArray,
        };
      }
      currentChatsArray.push({
        pageId,
        chatMessages: [],
        isTyping: "none",
        isOpenedOnce: false,
        totalMessagesCreated: 0,
        isLoading,
        mode: "edit",
      });
      return {
        ...state,
        chats: currentChatsArray,
      };
    }

    case ActionNames.SET_COMPLETION_STATUS: {
      const {
        pageId,
        status,
        componentId,
      } = action.payload as SetCompletionStatusPayload;
      let currentChatsArray = [...state.chats];
      const currentChat = currentChatsArray.find(
        (chat) => chat.pageId === pageId
      );
      if (!currentChat) {
        return state;
      }
      const currentCompletionMessage = currentChat.chatMessages.find(
        (chatMessage) =>
          chatMessage.type === "completionProgress" &&
          chatMessage.componentIds.includes(componentId)
      );
      if (!currentCompletionMessage) {
        return state;
      }
      if (
        currentCompletionMessage.status === "error" ||
        currentCompletionMessage.status === "finished"
      ) {
        return state;
      }
      currentCompletionMessage.status = status;
      return {
        ...state,
        chats: currentChatsArray,
      };
    }

    case ActionNames.DELETE_COMPLETION_PROGRESS: {
      const {
        pageId,
        componentId,
      } = action.payload as DeleteCompletionProgressPayload;
      let currentChatsArray = [...state.chats];
      const currentChat = currentChatsArray.find(
        (chat) => chat.pageId === pageId
      );
      if (!currentChat) {
        return state;
      }
      currentChat.chatMessages = currentChat.chatMessages.filter(
        (chatMessage) =>
          chatMessage.type !== "completionProgress" ||
          !chatMessage.componentIds.includes(componentId)
      );
      return {
        ...state,
        chats: currentChatsArray,
      };
    }

    case ActionNames.ADD_MODIFIED_COMPONENT: {
      const { componentId } = action.payload as AddModifiedComponentPayload;
      if (state.currentlyModifiedComponents.includes(componentId)) {
        return state;
      }
      return {
        ...state,
        currentlyModifiedComponents: [
          ...state.currentlyModifiedComponents,
          componentId,
        ],
      };
    }

    case ActionNames.DELETE_MODIFIED_COMPONENT: {
      const { componentId } = action.payload as DeleteModifiedComponentPayload;
      return {
        ...state,
        currentlyModifiedComponents: state.currentlyModifiedComponents.filter(
          (id) => id !== componentId
        ),
      };
    }

    case ActionNames.SET_CHANGE_HISTORY_DATA: {
      const {
        componentId,
        before,
        after,
        availableAction,
      } = action.payload as SetChangeHistoryDataPayload;
      const currentChangeHistoryArray = _.cloneDeep(state.changeHistory);
      const currentChangeHistory = currentChangeHistoryArray.find(
        (changeHistory) => changeHistory.componentId === componentId
      );
      if (currentChangeHistory) {
        currentChangeHistory.before =
          _.cloneDeep(before) || currentChangeHistory.before;
        currentChangeHistory.after =
          _.cloneDeep(after) || currentChangeHistory.after;
        currentChangeHistory.availableAction =
          availableAction || currentChangeHistory.availableAction;
        return {
          ...state,
          changeHistory: currentChangeHistoryArray,
        };
      }
      currentChangeHistoryArray.push({
        componentId,
        before: _.cloneDeep(before) || {},
        after: _.cloneDeep(after) || {},
        availableAction: availableAction || "undo",
      });
      return {
        ...state,
        changeHistory: currentChangeHistoryArray,
      };
    }

    case ActionNames.ADD_COMPONENT_ID: {
      const {
        componentId,
        pageId,
        messageId,
      } = action.payload as AddComponentIdPayload;
      let currentChatsArray = [...state.chats];
      const currentChat = currentChatsArray.find(
        (chat) => chat.pageId === pageId
      );
      if (!currentChat) {
        return state;
      }
      const currentUserMessage = currentChat.chatMessages.find(
        (chatMessage) => chatMessage.messageId === messageId
      );
      if (!currentUserMessage) {
        return state;
      }
      currentUserMessage.componentIds.push(componentId);
      return {
        ...state,
        chats: currentChatsArray,
      };
    }

    case ActionNames.EDIT_CHAT_MESSAGE: {
      const {
        pageId,
        messageId,
        chatMessageData,
      } = action.payload as EditChatMessagePayload;
      let currentChatsArray = [...state.chats];
      const currentChat = currentChatsArray.find(
        (chat) => chat.pageId === pageId
      );
      if (!currentChat) {
        return state;
      }
      const currentUserMessage = currentChat.chatMessages.find(
        (chatMessage) => chatMessage.messageId === messageId
      );
      if (!currentUserMessage) {
        return state;
      }
      Object.assign(currentUserMessage, chatMessageData);
      return {
        ...state,
        chats: currentChatsArray,
      };
    }

    case ActionNames.SET_MODE: {
      const { pageId, mode } = action.payload as SetModePayload;
      let currentChatsArray = [...state.chats];
      const currentChat = currentChatsArray.find(
        (chat) => chat.pageId === pageId
      );
      if (currentChat.isLoading !== "none") {
        return state;
      }
      if (currentChat) {
        currentChat.mode = mode;
        return {
          ...state,
          chats: currentChatsArray,
        };
      }
      currentChatsArray.push({
        pageId,
        chatMessages: [],
        isTyping: "none",
        isOpenedOnce: false,
        totalMessagesCreated: 0,
        isLoading: "none",
        mode,
      });
      return {
        ...state,
        chats: currentChatsArray,
      };
    }

    // Contextual inputs
    case ActionNames.EDIT_INPUT_DATA: {
      const { componentId, newData } = action.payload as EditInputDataPayload;
      let inputsArray = [...state.contextualInputs];
      const currentInput = inputsArray.find(
        (input) => input.componentId === componentId
      );
      if (currentInput) {
        Object.assign(currentInput, newData);
        return {
          ...state,
          contextualInputs: inputsArray,
        };
      } else {
        inputsArray.push({
          componentId,
          inputValue: "",
          isLoading: false,
          status: "none",
          reasoning: "",
          completionResultData: {
            isSessionStarted: false,
            hasChanges: false,
            prompt: "",
            presetName: null,
          },
          ...newData,
        });
      }
      return {
        ...state,
        contextualInputs: inputsArray,
      };
    }

    case ActionNames.ADD_PROMPT_HISTORY_ITEM: {
      const { prompt } = action.payload as AddPromptHistoryItemPayload;
      const promptHistoryArray = [...state.promptHistory].filter(
        (promptHistoryItem) => promptHistoryItem.prompt !== prompt
      );
      promptHistoryArray.unshift({
        prompt,
      });
      if (promptHistoryArray.length > GPT_PROMPT_HISTORY_MAX_LENGTH) {
        promptHistoryArray.pop();
      }
      return {
        ...state,
        promptHistory: promptHistoryArray,
      };
    }

    case ActionNames.DELETE_PROMPT_HISTORY_ITEM: {
      const { prompt } = action.payload as DeletePromptHistoryItemPayload;
      const promptHistoryArray = [...state.promptHistory];
      return {
        ...state,
        promptHistory: promptHistoryArray.filter(
          (promptHistoryItem) => promptHistoryItem.prompt !== prompt
        ),
      };
    }

    case ActionNames.ADD_PROMPT_HISTORY_FOR_AI_ITEM: {
      const { prompt } = action.payload as AddPromptHistoryForAiItemPayload;
      const promptHistoryForAiArray = [...state.promptHistoryForAi];
      promptHistoryForAiArray.unshift({
        prompt,
      });
      if (promptHistoryForAiArray.length > GPT_PROMPT_HISTORY_MAX_LENGTH) {
        promptHistoryForAiArray.pop();
      }
      return {
        ...state,
        promptHistoryForAi: promptHistoryForAiArray,
      };
    }

    default: {
      return state;
    }
  }
};
