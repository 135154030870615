import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { buttonStyles } from "../../data/buttonStyles";
import { linkOrButtonOptions } from "../../data/linkOrButtonOptions";
import { pictogramOptions } from "../../data/pictogramOptions";
import { pricingItemDetailIconOptions } from "../../data/pricingItemDetailIconOptions";
import isNumericOrDashOrPlus from "../../helpers/editor/isNumericOrDashOrPlus";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import Emoji from "../component_molecules/Emoji";
import WebsiteButton from "../component_molecules/WebsiteButton";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import PricingDetailsIconSet from "../component_molecules/PricingDetailsIconSet";
import TitleBox from "../component_molecules/TitleBox";
import { checkIfPricingTabsAreHidden } from "../../helpers/pricing/checkIfPricingTabsAreHidden";
import { checkIfPricingTabHasCustomText } from "../../helpers/pricing/checkIfPricingTabHasCustomText";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Pricing04 extends Component<Props> {
  generator = new generateContentDataObject();
  randomIndex = Math.floor(Math.random() * 9999); //because each Pricing component needs a unique index to switch the 'monthly/yearly' tabs.

  componentWillMount(): void {
    const { isDefaultEmpty } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent()) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpTitle({
          titleText: "Affordable Pricing for Everyone",
          isDefaultEmpty
        })
      );
      this.props.saveContentInStore(
        this.generator.setUpSubtitleText({
          subtitleText:
            "No nickel & dime. Raptus silva una contactuss mortem est. Cum assimilatio crescere, omnes absolutioes reperire rusticus, ferox burguses.",
            isDefaultEmpty
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpCta({
          isDefaultEmpty,
          activeOption: callToActionOptions.buttons,
          fieldTitle: {
            [callToActionOptions.buttons]: "Bottom buttons",
            default: "Bottom buttons",
          },
          label: {
            [callToActionOptions.buttons]: "Edit the buttons:",
            default: "Edit the buttons:",
          },
          labelTooltip: {
            [callToActionOptions.buttons]:
              "Add a CTA (call to action) to the bottom of the pricing component to offer more ways to get your product. E.g. a sales contact link.",
            default:
              "Add a CTA (call to action) to the bottom of the pricing component to offer more ways to get your product. E.g. a sales contact link.",
          },

          buttons: [
            this.generator.createButtonContentObject({
              buttonType: linkOrButtonOptions.link,
              buttonTitle: "",
              buttonHref: "",
              buttonStyleType: buttonStyles.pill.typeTitle,
              pillPrefix: "Also",
            }),
          ],

          priority: 10,
        })
      );

      this.props.saveContentInStore(
        this.generator.setUpPricing({
          isDefaultEmpty,
          maxItems: 64,
          hasHighlight: true,
          hasSecondaryInfo: true,
          hasPictogram: false,
          hasDetailsSet: true,
          items: [
            this.generator.createPricingContentObject({
              title: "Freelancer",
              primaryInfo: "Perfect for beginners",
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f6f4.svg",
              }),
              monthlyPrice: 9,
              button: this.generator.createButtonContentObject({
                buttonType: linkOrButtonOptions.link,
                buttonTitle: "Start now",
                buttonHref: "#",
                buttonStyleType: buttonStyles.regular.typeTitle,
                buttonColor: buttonStyles.regular.accentOutline,
                buttonIsTargetBlank: true,
              }),
              detailsSet: [
                this.generator.createPricingItemDetailContentObject({
                  title: "1 workspace",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),
                this.generator.createPricingItemDetailContentObject({
                  title: "1 agent",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),
                this.generator.createPricingItemDetailContentObject({
                  title: "All integrations",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),

                this.generator.createPricingItemDetailContentObject({
                  title: "Mobile apps",
                  icon: pricingItemDetailIconOptions.redCross,
                  hoverInfo: "",
                }),
                this.generator.createPricingItemDetailContentObject({
                  title: "Premium support",
                  icon: pricingItemDetailIconOptions.redCross,
                  hoverInfo: "",
                }),

                this.generator.createPricingItemDetailContentObject({
                  title: "",
                }), //ghost item
              ],
            }),
            this.generator.createPricingContentObject({
              title: "Team",
              isHighlighted: true,
              primaryInfo: "For small teams",
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f5a5.svg",
              }),
              monthlyPrice: 19,
              button: this.generator.createButtonContentObject({
                buttonType: linkOrButtonOptions.link,
                buttonTitle: "Start now",
                buttonHref: "#",
                buttonStyleType: buttonStyles.regular.typeTitle,
                buttonColor: buttonStyles.regular.accentBg,
                buttonIsTargetBlank: true,
              }),
              detailsSet: [
                this.generator.createPricingItemDetailContentObject({
                  title: "5 workspaces",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),
                this.generator.createPricingItemDetailContentObject({
                  title: "5 agents",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),
                this.generator.createPricingItemDetailContentObject({
                  title: "All integrations",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),

                this.generator.createPricingItemDetailContentObject({
                  title: "Mobile apps",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),
                this.generator.createPricingItemDetailContentObject({
                  title: "Premium support",
                  icon: pricingItemDetailIconOptions.redCross,
                  hoverInfo: "",
                }),

                this.generator.createPricingItemDetailContentObject({
                  title: "",
                }), //ghost item
              ],
            }),

            this.generator.createPricingContentObject({
              title: "Business",
              primaryInfo: "For huge companies",
              pictogram: this.generator.createPictogramContentObject({
                type: pictogramOptions.emoji.title,
                emojiSrc: "1f4bc.svg",
              }),
              monthlyPrice: 49,
              button: this.generator.createButtonContentObject({
                buttonType: linkOrButtonOptions.link,
                buttonTitle: "Start now",
                buttonHref: "#",
                buttonStyleType: buttonStyles.regular.typeTitle,
                buttonColor: buttonStyles.regular.accentOutline,
                buttonIsTargetBlank: true,
              }),
              detailsSet: [
                this.generator.createPricingItemDetailContentObject({
                  title: "90 workspaces",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),
                this.generator.createPricingItemDetailContentObject({
                  title: "60 agents",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),
                this.generator.createPricingItemDetailContentObject({
                  title: "All integrations",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),

                this.generator.createPricingItemDetailContentObject({
                  title: "Mobile apps",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),
                this.generator.createPricingItemDetailContentObject({
                  title: "Premium support",
                  icon: pricingItemDetailIconOptions.greenTick,
                  hoverInfo: "",
                }),

                this.generator.createPricingItemDetailContentObject({
                  title: "",
                }), //ghost item
              ],
            }),
          ],
        })
      );
    }
  }

  render() {
    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let pricing = this.props.componentItem.componentData.pricing;

    const { randomIndex } = this;

    let isYearlyTabActiveByDefault = pricing.isYearlyActiveByDefault;
    // we added this property on 11 Sep 2020, so it if undefined for old components
    if (isYearlyTabActiveByDefault === undefined) {
      isYearlyTabActiveByDefault = false;
    }

    return (
      <section>
        <div className="pricing-04">
          <TitleBox
            title={title}
            subtitleText={subtitleText}
            isDarkBg={this.props.isDarkBg}
          />
          <div className="container">
            {pricing.content.items[0] &&
            pricing.content.items[0].yearlyPrice !== "" &&
            !checkIfPricingTabsAreHidden(pricing) && ( //if the first pricing item has a yearly price, then display the switcher.
                <div className="pricing-04__buttons">
                  <ul className="pricing-04__buttons_list">
                    <li className="tab">
                      <button
                        className={
                          "content-text def-12 tab__button js-open-tab " +
                          (isYearlyTabActiveByDefault === false
                            ? " state-active-tab"
                            : "")
                        }
                        type="button"
                        data-index="monthly-price-1"
                        data-group={"my-pricing-group-" + randomIndex}
                      >
                        {checkIfPricingTabHasCustomText(pricing, "left")
                          ? pricing.leftTabText
                          : "Monthly"}
                      </button>
                    </li>
                    <li className="tab">
                      <button
                        className={
                          "content-text def-12 tab__button js-open-tab " +
                          (isYearlyTabActiveByDefault
                            ? " state-active-tab"
                            : "")
                        }
                        type="button"
                        data-index="yearly-price-1"
                        data-group={"my-pricing-group-" + randomIndex}
                      >
                        {checkIfPricingTabHasCustomText(pricing, "right")
                          ? pricing.rightTabText
                          : "Yearly"}
                      </button>
                    </li>
                  </ul>
                  <div
                    className={
                      "pricing-04__badge js-tab-content " +
                      (isYearlyTabActiveByDefault ? " state-active-tab" : "")
                    }
                    data-index="yearly-price-1"
                    data-group={"my-pricing-group-" + randomIndex}
                  >
                    {pricing.yearlyBonusText && pricing.yearlyBonusText !== "" && (
                      <span className="pointed-badge   pointed-badge--acid-bg pointed-badge--left">
                        <span className="pointed-badge__text">
                          {pricing.yearlyBonusText}
                        </span>
                        <Emoji
                          emojiSrc="1f381.svg"
                          className="emoji"
                          alt="Emoji Gift"
                        />
                      </span>
                    )}
                  </div>
                </div>
              )}
          </div>
          <div className="container container--max">
            <div className="pricing-04__cards_container">
              <ul className="pricing-04__cards_list">
                {pricing.content.items &&
                  pricing.content.items.map((item: any, key: number) => {
                    let displayDetailsSet = (pricingObject: any) => {
                      let detailsSet = pricingObject.detailsSet;

                      if (detailsSet.length === 0) {
                        return null;
                      } else {
                        const detailsItemsJSX = detailsSet.map(
                          (detailItem: any, detailKey: any) => {
                            if (detailItem.title && detailItem.title !== "") {
                              return (
                                <li
                                  className="pricing-04__item"
                                  key={"details-item_pricing-04-" + detailKey}
                                >
                                  <PricingDetailsIconSet
                                    detailObj={detailItem}
                                    iconClassname="pricing-04__item_icon"
                                  />

                                  <div
                                    className={
                                      "content-text pricing-04__item_text js-hover-to-show-sibling " +
                                      (this.props.isDarkBg ? "text-white" : "")
                                    }
                                  >
                                    {detailItem.title}
                                  </div>
                                  {detailItem.hoverInfo &&
                                    detailItem.hoverInfo !== "" && (
                                      <div className="content-text pricing-04__hover js-content-to-show">
                                        {detailItem.hoverInfo}
                                      </div>
                                    )}
                                </li>
                              );
                            } else {
                              return null;
                            }
                          }
                        );

                        return (
                          <ul className="pricing-04__items">
                            {detailsItemsJSX}
                          </ul>
                        );
                      }
                    };

                    if (
                      item.monthlyPrice !== undefined &&
                      item.monthlyPrice !== ""
                    ) {
                      //Empty string ('') when no price is entered by a user.
                      let isEnteredMonthlyAmountNumeric = isNumericOrDashOrPlus(
                        item.monthlyPrice
                      ); // we hide the currency symbol and period for non-numeric values (e.g. "contact us")
                      let monthlyPriceClass = " ";
                      let monthlyPriceStringLength = item.monthlyPrice.toString()
                        .length;
                      if (monthlyPriceStringLength === 3) {
                        monthlyPriceClass =
                          " pricing-04__price_text smaller-text-09 ";
                      } else if (monthlyPriceStringLength === 4) {
                        monthlyPriceClass =
                          " pricing-04__price_text smaller-text-08 ";
                      } else if (monthlyPriceStringLength === 5) {
                        monthlyPriceClass =
                          " pricing-04__price_text smaller-text-07 ";
                      } else if (monthlyPriceStringLength === 6) {
                        monthlyPriceClass =
                          " pricing-04__price_text smaller-text-06 ";
                      } else if (monthlyPriceStringLength === 7) {
                        monthlyPriceClass =
                          " pricing-04__price_text smaller-text-05 ";
                      } else if (monthlyPriceStringLength === 8) {
                        monthlyPriceClass =
                          " pricing-04__price_text smaller-text-04 ";
                      } else if (monthlyPriceStringLength > 8) {
                        monthlyPriceClass =
                          " pricing-04__price_text smaller-text-04 ";
                      }

                      let yearlyPriceClass = " ";
                      let yearlyPriceStringLength = item.yearlyPrice.toString()
                        .length;
                      if (yearlyPriceStringLength === 3) {
                        yearlyPriceClass =
                          " pricing-02__price_text smaller-text-09 ";
                      } else if (yearlyPriceStringLength === 4) {
                        yearlyPriceClass =
                          " pricing-02__price_text smaller-text-08 ";
                      } else if (yearlyPriceStringLength === 5) {
                        yearlyPriceClass =
                          " pricing-02__price_text smaller-text-07 ";
                      } else if (yearlyPriceStringLength === 6) {
                        yearlyPriceClass =
                          " pricing-02__price_text smaller-text-06 ";
                      } else if (yearlyPriceStringLength === 7) {
                        yearlyPriceClass =
                          " pricing-02__price_text smaller-text-05 ";
                      } else if (yearlyPriceStringLength === 8) {
                        yearlyPriceClass =
                          " pricing-02__price_text smaller-text-04 ";
                      } else if (yearlyPriceStringLength > 8) {
                        yearlyPriceClass =
                          " pricing-02__price_text smaller-text-04 ";
                      }

                      let symbolClass = " ";
                      if (item.currencySymbol.length > 1) {
                        symbolClass = " pricing-04__price_symbol--bigger ";
                      }

                      const displaySecondaryInfo = () => {
                        if (
                          item.hasIndividualInfo &&
                          pricing.content.items[0] &&
                          pricing.content.items[0].yearlyPrice !== ""
                        ) {
                          return (
                            <>
                              {item.secondaryInfo && item.secondaryInfo !== "" && (
                                <div
                                  className={`content-text def-14 pricing-04__card_text pricing-04__individual-text js-tab-content ${
                                    isYearlyTabActiveByDefault === false
                                      ? "state-active-tab"
                                      : ""
                                  }`}
                                  data-index="monthly-price-1"
                                  data-group={"my-pricing-group-" + randomIndex}
                                >
                                  {item.secondaryInfo}
                                </div>
                              )}
                              {item.secondaryInfoYearly &&
                                item.secondaryInfoYearly !== "" && (
                                  <div
                                    className={`content-text def-14 pricing-04__card_text pricing-04__individual-text js-tab-content ${
                                      isYearlyTabActiveByDefault
                                        ? "state-active-tab"
                                        : ""
                                    }`}
                                    data-index="yearly-price-1"
                                    data-group={
                                      "my-pricing-group-" + randomIndex
                                    }
                                  >
                                    {item.secondaryInfoYearly}
                                  </div>
                                )}
                            </>
                          );
                        }
                        return (
                          item.secondaryInfo &&
                          item.secondaryInfo !== "" && (
                            <div className="content-text def-14 pricing-04__card_text">
                              {item.secondaryInfo}
                            </div>
                          )
                        );
                      };

                      return (
                        <li
                          className={"pricing-04__card_box "}
                          key={
                            "pricing-table-item-4-" + key + "-" + randomIndex
                          }
                        >
                          <div
                            className={
                              "sh-4 pricing-04__card card-container " +
                              (item.isHighlighted
                                ? " pricing-04__card--accent "
                                : "")
                            }
                          >
                            {item.title && item.title !== "" && (
                              <h3 className="def-22 title-text--inner pricing-04__card_title">
                                {item.title}
                              </h3>
                            )}
                            {item.primaryInfo && item.primaryInfo !== "" && (
                              <div className="pricing-04__card_main_text content-text def-14">
                                {item.primaryInfo}
                              </div>
                            )}
                            <div className="pricing-04__price_box">
                              <div
                                className={
                                  "pricing-04__price_item js-tab-content " +
                                  (isYearlyTabActiveByDefault === false
                                    ? " state-active-tab"
                                    : "")
                                }
                                data-index="monthly-price-1"
                                data-group={"my-pricing-group-" + randomIndex}
                              >
                                {isEnteredMonthlyAmountNumeric && (
                                  <div
                                    className={
                                      "content-text def-14 pricing-04__price_symbol " + symbolClass
                                    }
                                  >
                                    {item.currencySymbol}
                                  </div>
                                )}
                                <div className="content-text def-64 pricing-04__price">
                                  <span
                                    className={monthlyPriceClass}
                                    dangerouslySetInnerHTML={{
                                      __html: item.monthlyPrice,
                                    }}
                                  />
                                </div>
                                {isEnteredMonthlyAmountNumeric && (
                                  <div
                                    className="content-text def-14 pricing-04__period"
                                    dangerouslySetInnerHTML={{
                                      __html: item.monthlyPeriod,
                                    }}
                                  />
                                )}
                              </div>
                              <div
                                className={
                                  "pricing-04__price_item js-tab-content " +
                                  (isYearlyTabActiveByDefault
                                    ? " state-active-tab"
                                    : "")
                                }
                                data-index="yearly-price-1"
                                data-group={"my-pricing-group-" + randomIndex}
                              >
                                {isEnteredMonthlyAmountNumeric && (
                                  <div
                                    className={
                                      "content-text def-14 pricing-04__price_symbol " + symbolClass
                                    }
                                  >
                                    {item.currencySymbol}
                                  </div>
                                )}
                                <div className="content-text def-64 pricing-04__price">
                                  <span
                                    className={yearlyPriceClass}
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        item.yearlyPrice === ""
                                          ? "?"
                                          : item.yearlyPrice,
                                    }}
                                  />
                                </div>
                                {isEnteredMonthlyAmountNumeric && (
                                  <div
                                    className="content-text def-14 pricing-04__period"
                                    dangerouslySetInnerHTML={{
                                      __html: item.yearlyPeriod,
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                            {displaySecondaryInfo()}
                            <div className="pricing-04__card_button">
                              {item.button.title !== "" && (
                                <WebsiteButton
                                  config={{
                                    buttonStyleType:
                                      item.button.settings.appearance.styleType,
                                    buttonColor:
                                      item.button.settings.appearance.color,
                                    buttonType: linkOrButtonOptions.link,
                                    buttonTitle: item.button.title,
                                    buttonHref: item.button.href,
                                    buttonIsTargetBlank:
                                      item.button.isTargetBlank,
                                    pillPrefix: item.button.pillPrefix,
                                    pillColor:
                                      item.button.settings.appearance.pillColor,
                                    ghostColor:
                                      item.button.settings.appearance
                                        .ghostColor,
                                    buttonObject: item.button,
                                  }}
                                />
                              )}
                            </div>
                          </div>

                          {displayDetailsSet(item)}
                        </li>
                      );
                    }
                  })}
              </ul>
            </div>
          </div>

          <div className="bottom_cta">
            {this.props.componentItem.componentData.cta && (
              <WebsiteCTA componentItem={this.props.componentItem} />
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default Pricing04;
