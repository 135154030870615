import { formFields } from "../../../../data/formFields";
import generateContentDataObject from "../../../../helpers/editor/generateContentDataObject";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { addNewItem } from "./updaterFunctions.ts/addNewItem";
import { deleteItem } from "./updaterFunctions.ts/deleteItem";
import { getArrayOfStrings } from "./updaterFunctions.ts/getArrayOfStrings";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getIsItemNew } from "./updaterFunctions.ts/getIsItemNew";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const generator = new generateContentDataObject();

const particleGetModifiedValues = (modifiedItem) => {
  const [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  const itemLabel = stringifyObjectsAndNumbers(modifiedItem.FIELD_LABEL) || "";
  const itemPlaceholder =
    stringifyObjectsAndNumbers(modifiedItem.FIELD_PLACEHOLDER) || "";
  const itemName = stringifyObjectsAndNumbers(modifiedItem.FIELD_NAME) || "";
  const isItemRequired = !!stringifyObjectsAndNumbers(
    modifiedItem.IS_FIELD_REQUIRED
  );
  let itemTitle =
    stringifyObjectsAndNumbers(modifiedItem.FIELD_TYPE) ||
    formFields.other_short_text.title;
  if (!(itemTitle in formFields)) {
    itemTitle = formFields.other_short_text.title;
  }
  const itemDropdownOptions = getArrayOfStrings(
    modifiedItem.DROPDOWN_OPTIONS_ARRAY_OF_STRINGS
  );
  return {
    itemId,
    itemPosition,
    itemLabel,
    itemPlaceholder,
    itemName,
    isItemRequired,
    itemTitle,
    itemDropdownOptions,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemLabel,
  itemPlaceholder,
  itemName,
  isItemRequired,
  itemTitle,
  itemDropdownOptions
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.cta.content.form.fields.items.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.cta.content.form.fields.items, item);
      }
    });
  }
  if ("FIELD_LABEL" in modifiedItem) {
    originalItem.label = itemLabel;
  }
  if ("FIELD_PLACEHOLDER" in modifiedItem) {
    originalItem.placeholder = itemPlaceholder;
  }
  if ("FIELD_NAME" in modifiedItem) {
    originalItem.name = itemName;
  }
  if ("IS_FIELD_REQUIRED" in modifiedItem) {
    originalItem.required = isItemRequired;
  }
  if ("FIELD_TYPE" in modifiedItem) {
    originalItem.title = itemTitle;
  }
  if ("DROPDOWN_OPTIONS_ARRAY_OF_STRINGS" in modifiedItem) {
    originalItem.dropdownItems = itemDropdownOptions;
    originalItem.placeholder = "";
  }
};

export const updateCtaForm = (componentData, completionContent) => {
  if (!("CTA" in completionContent)) {
    return;
  }

  if (!isKeyValue(completionContent.CTA)) {
    if (completionContent.CTA === null) {
      componentData.cta.content.form.fields.items = [];
      componentData.cta.content.form.button.title = "";
    }
    return;
  }

  if (!("CTA_FORM" in completionContent.CTA)) {
    return;
  }

  if (!isKeyValue(completionContent.CTA.CTA_FORM)) {
    if (completionContent.CTA.CTA_FORM === null) {
      componentData.cta.content.form.fields.items = [];
      componentData.cta.content.form.button.title = "";
    }
    return;
  }

  const submitButtonText =
    stringifyObjectsAndNumbers(
      completionContent.CTA.CTA_FORM.SUBMIT_BUTTON_TEXT
    ) || "";
  if ("SUBMIT_BUTTON_TEXT" in completionContent.CTA.CTA_FORM) {
    componentData.cta.content.form.button.title = submitButtonText;
  }

  if (!("FORM_FIELDS" in completionContent.CTA.CTA_FORM)) {
    return;
  }

  if (!Array.isArray(completionContent.CTA.CTA_FORM.FORM_FIELDS)) {
    if (completionContent.CTA.CTA_FORM.FORM_FIELDS === null) {
      componentData.cta.content.form.fields.items = [];
    }
    return;
  }

  addDefaultValuesToItems(componentData.cta.content.form.fields.items);

  completionContent.CTA.CTA_FORM.FORM_FIELDS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }

    const {
      itemId,
      itemPosition,
      itemLabel,
      itemPlaceholder,
      itemName,
      isItemRequired,
      itemTitle,
      itemDropdownOptions,
    } = particleGetModifiedValues(modifiedItem);

    if (itemPosition === null) {
      componentData.cta.content.form.fields.items = deleteItem(
        componentData.cta.content.form.fields.items,
        itemId
      );
      return;
    }

    const isItemNew = getIsItemNew(
      componentData.cta.content.form.fields.items,
      itemId
    );

    if (isItemNew) {
      const newItem = generator.createFormFieldContentObject();
      newItem.label = "";
      newItem.placeholder = "";
      newItem.name = "";
      newItem.required = false;
      newItem.title = formFields.other_short_text.title;
      newItem.dropdownItems = [];
      addNewItem(
        componentData.cta.content.form.fields.items,
        newItem,
        itemId,
        itemPosition
      );
    }

    const originalItem = getOriginalItem(
      componentData.cta.content.form.fields.items,
      itemId
    );

    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemLabel,
      itemPlaceholder,
      itemName,
      isItemRequired,
      itemTitle,
      itemDropdownOptions
    );
  });

  sortItemsByPosition(componentData.cta.content.form.fields.items);
};
