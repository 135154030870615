import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {DEFAULT_IMAGES_CDN, otherImgPath, publicUrl} from "../../data/urls";
import {monthNames} from "../../data/constants";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Links01 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "Meet Our Sexy Video Guides"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,
                maxItems: 24,
                fieldInfo: {default: 'Any size or 312x206 for a perfect fit.'},
                hasSubtitle: true,
                hasHighlight: true,
                hasButton: false, //if false then the whole item is clickable
                items: [
                    this.generator.createPostsContentObject({
                        title: "Lean Startup Explained",
                        subtitle: 'Feb 15, ' + new Date().getFullYear(),
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: DEFAULT_IMAGES_CDN.ABSTRACT_01,
                            width: 400,
                            height: 496
                        }),
                        href: '#'
                    }),

                    this.generator.createPostsContentObject({
                        title: "How to Choose a Right Button Color? A Friendly Guide for Beginning Designers.",
                        isHighlighted: true,
                        subtitle: 'Feb 07, ' + new Date().getFullYear(),
                        thumb: this.generator.createGraphicsContentObject({
                          imageUrl: DEFAULT_IMAGES_CDN.ABSTRACT_02,
                          width: 400,
                          height: 267
                        }),
                        href: '#'
                    }),

                    this.generator.createPostsContentObject({
                        title: "10 Ultimate Advices for ReactJS Developers",
                        subtitle: 'Jan 25, ' + new Date().getFullYear(),
                        thumb: this.generator.createGraphicsContentObject({
                          imageUrl: DEFAULT_IMAGES_CDN.ABSTRACT_03,
                          width: 400,
                          height: 711
                        }),
                        href: '#'
                    }),

                    this.generator.createPostsContentObject({
                        title: "How NOT to Kill Your Side-project",
                        subtitle: 'Jan 14, ' + new Date().getFullYear(),
                        thumb: this.generator.createGraphicsContentObject({
                          imageUrl: DEFAULT_IMAGES_CDN.ABSTRACT_04,
                          width: 400,
                          height: 665
                        }),
                        href: '#'
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the links list component to invite users to read your blog.",
                    default: "Add a button to the bottom of the links list component to invite users to read your blog.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        return (
            <section>
                <div className="links-01">
                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container container--small">
                        <ul className="links-01__list">
                            {posts && posts.map((item:any, key:number) => {
                                if(item !== undefined && item.href !== ''){
                                    return (
                                        <li className="links-01__item" key={'links-01-' + key}>
                                            <a className="sh-4 sh-has-hover card-container links-01__link" href={returnHref(item.href)}>
                                                <div className="links-01__img_box">
                                                    <img src={formatImageUrl(item.thumb.image.url)} alt={item.thumb.image.alt} className="links-01__img"/>
                                                    {item.isHighlighted && <img className="links-01__img_badge" src={publicUrl + otherImgPath.prefix + 'badge_top.svg'}/>}
                                                </div>
                                                <div className={"links-01__text_box " + (item.thumb.image.url === '' ? ' links-01__text_box--no-margin' : '' )}>
                                                    <div className="title-text--inner links-01__text_title def-16">
                                                        { item.title }
                                                    </div>
                                                    <div className="content-text def-14 links-01__text_date">{ item.subtitle }</div>
                                                </div>
                                                <div className="links-01__pseudo-button bg-light">
                                                    <span className="icon">
                                                        <svg viewBox="0 0 13 10"
                                                             xmlns="http://www.w3.org/2000/svg"><path
	                                                        d="M12.823 4.164L8.954.182a.592.592 0 0 0-.854 0 .635.635 0 0 0 0 .88l2.836 2.92H.604A.614.614 0 0 0 0 4.604c0 .344.27.622.604.622h10.332L8.1 8.146a.635.635 0 0 0 0 .88.594.594 0 0 0 .854 0l3.869-3.982a.635.635 0 0 0 0-.88z"
	                                                        fillRule="nonzero" fill="#00396B" className="fill-main"/>
                                                    </svg>
                                                    </span>
												</div>
                                            </a>
                                        </li>
                                    )
                                }else{
                                    return (null);
                                }
                            })}
                        </ul>
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Links01;
