import React, {Component} from "react";
import {BrowserRouter as Router, Route, Link, Switch, Redirect} from "react-router-dom";
import {Button, Form, Icon, Input} from "antd";
import setInputStatus from "../../helpers/setInputStatus";
import {api} from "../../data/urls";
import {getCurrentWebsiteSubdomain} from "../../helpers/getCurrentWebsiteSubdomain";
import axios from "axios";
import returnAuthHeaderForAJAX from "../../helpers/auth/returnAuthHeaderForAJAX";
import {defaultBlogUrl, projectUrl} from "../../data/constants";
import {openNotification} from "../../helpers/openNotification";
import fireAnalyticsEvent from "../../helpers/editor/fireAnalyticsEvent";
import {getErrorValue} from "../../helpers/editor/getErrorValue";
import {getErrorKey} from "../../helpers/editor/getErrorKey";
import {getCurrentBlog} from "../../helpers/blog/getCurrentBlog";
import {getCurrentWebsite} from "../../helpers/getCurrentWebsite";
import { getCurrentWebsitePage } from "../../helpers/getCurrentWebsitePage";
import { CrispEvents } from "../../enums/AnalyticsEventsEnums";
import { getCurrentBlogPostUrl } from "../../helpers/blog/getCurrentBlogPostUrl";
import { getActiveDomain } from "../../helpers/websites/getActiveDomain";
import _ from "lodash";
import { formatToURLPath } from "../../helpers/strings/formatToURLPath";

interface Props {
    currentBlogUrl: string,
    websites: any,
    blogs: any,
    blogPosts: any;
    history: any,
    clearUpdateBlogUrlInput: any,
    auth: any,
    urlUpdateWaitingForServer: any,
    urlUpdateToggleInvalidAttempt: any,
    urlUpdateErrorMessage: any,
    saveBlogDataInStore: any,
    blog_url: string,
    setUrl: (url: string) => void,
}
type MyState = {};
class UpdateBlogURLForm extends Component<Props, MyState>{
    componentWillReceiveProps(nextProps: Readonly<Props>, nextContext: any): void {
        // We set the new state only when we change a settings of an active blog.
        // We want to set up an initial value of a new picked blog.
        if(this.props.currentBlogUrl !== nextProps.currentBlogUrl){
            this.props.setUrl(nextProps.currentBlogUrl);
            // We also clear errors when switch a website.
            this.props.clearUpdateBlogUrlInput();
        }
    }

    handleURLUpdate = (e:any):void => {
        e.preventDefault();

        this.props.urlUpdateWaitingForServer(true);
        
        //We want to remove all errors from inputs on a new request.
        this.props.clearUpdateBlogUrlInput();

        let newBlogUrl = formatToURLPath(this.props.blog_url);
        this.props.setUrl(newBlogUrl);

        let currentWebsite = getCurrentWebsite(this.props.websites.items);
        let currentBlog = getCurrentBlog(this.props.blogs.items, currentWebsite);

        let accessToken = this.props.auth.accessToken;
        let apiUrl = api.blogs.updateBlog.prefix + currentBlog.id + api.blogs.updateBlog.postfix;

        let data = {url: newBlogUrl} as any;
        if(newBlogUrl === ''){
            data = {...data}
        }
        
        axios.patch(apiUrl, data, {...returnAuthHeaderForAJAX(accessToken)})
            .then(response => {
                  const dataToStore = {
                    data: {
                      ...currentBlog,
                      url: _.get(response, "data.url")
                    }
                  }
                  this.props.saveBlogDataInStore(dataToStore, currentBlog.id);

                  fireAnalyticsEvent.fireCrisp(CrispEvents.changeBlogURL, {
                    url: response.data.url,
                  });

                  let newBlogUrlFromServer;
                  if (response.data.url === '') {
                      newBlogUrlFromServer = defaultBlogUrl;
                  } else {
                      newBlogUrlFromServer = response.data.url;
                  }

                  let currentWebsiteUrl = 'https://';
                  if(currentWebsite.is_custom_domain_active){
                      currentWebsiteUrl = currentWebsiteUrl + currentWebsite.custom_domain;
                  }else{
                      currentWebsiteUrl = currentWebsiteUrl + currentWebsite.subdomain + '.' + projectUrl;
                  }
                  let subdomainUrl = currentWebsiteUrl;
              })
            .catch(error => {
                  // handle error
                  if(error.response){
                      let message = '';
                      let errorData = error.response.data;
                      let errorObjectKey = getErrorKey(errorData);
                      let errorObjectValue = getErrorValue(errorData, errorObjectKey);

                      fireAnalyticsEvent.fireCrisp(CrispEvents.changeBlogURLError, {
                        error_type: errorObjectKey,
                        error_message: errorObjectValue,
                        subdomain: getCurrentWebsiteSubdomain(this.props.websites.items),
                        new_blog_url: newBlogUrl,
                      }, true);

                      if (errorData.url !== undefined){
                          message = errorData.url.join(' ');
                          this.props.urlUpdateErrorMessage(message);
                          this.props.urlUpdateToggleInvalidAttempt(true);
                      }

                      openNotification('Denied', message, 'OK', 'warn');
                  }
              })
            .then(response => {
                  // always executed
                  this.props.urlUpdateWaitingForServer(false);
            });
    };

    handleInputChange = (e:any) => {
        let inputValue = e.target.value;
        this.props.setUrl(inputValue);
    };

    render(){
        let currentWebsite = getCurrentWebsite(this.props.websites.items);

        let currentWebsiteUrl = 'https://';
        if(currentWebsite.is_custom_domain_active){
            currentWebsiteUrl = currentWebsiteUrl + currentWebsite.custom_domain;
        }else{
            currentWebsiteUrl = currentWebsiteUrl + currentWebsite.subdomain + '.' + projectUrl;
        }

        let returnURLField = () => {
          // Only pro users can manage custom domains
              const displayedDomain = getActiveDomain(currentWebsite);
              return (
                  <div className="settings-box__input_box full-width">
                      <Input name="blog_url"
                              id="blog_url"
                              required={false}
                              placeholder="Blog URL"
                              type="text"
                              value={this.props.blog_url}
                              onChange={this.handleInputChange}
                              addonBefore={<div style={{
                                maxWidth: "150px",
                                direction: "rtl",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                               }}>{displayedDomain}/&lrm;</div>}
                      />
                      <div className="settings-box__error_message" style={{opacity: this.props.blogs.urlUpdateErrorMessage === undefined ? 0 : 1}}>
                          {this.props.blogs.URLUpdateErrorMessage}
                      </div>
                  </div>
              )
        };

        return (
            <form onSubmit={this.handleURLUpdate} className="settings-box__form">
                {returnURLField()}
                <Button
                    className="settings-box__form_save"
                    icon='save'
                    type="primary"
                    loading={this.props.blogs.isWaitingForURLUpdateResponse}
                    disabled={this.props.blogs.isWaitingForURLUpdateResponse}
                    htmlType="submit"
                    hidden={(this.props.currentBlogUrl === this.props.blog_url.toLowerCase())}
                />
            </form>
        )
    }
}

// export default Form.create({} )(UpdateCustomDomainForm);
export default UpdateBlogURLForm;
