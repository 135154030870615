import React from "react";
import {
  deleteOauth2AccessToken,
  setAccessTokenRevocationInProgress,
} from "../../../../store/oauth2/actions";
import {
  AccessToken,
  DeleteOauth2AccessToken,
  Oauth2State,
  SetAccessTokenRevocationInProgress,
} from "../../../../store/oauth2/types";
import { deleteOauth2AccessTokenAsync } from "../../../../helpers/auth/deleteOauth2AccessTokenAsync";
import { connect } from "react-redux";
import { openNotification } from "../../../../helpers/openNotification";
import { notificationDuration } from "../../../../data/constants";
import fireAnalyticsEvent from "../../../../helpers/editor/fireAnalyticsEvent";
import { CrispEvents } from "../../../../enums/AnalyticsEventsEnums";
import trimStringTo from "../../../../helpers/strings/trimStringTo";
import { Button, Popconfirm } from "antd";

interface Props {
  token: AccessToken;
  children?: React.ReactChild;

  auth: any;
  oauth2: Oauth2State;
  setAccessTokenRevocationInProgress: SetAccessTokenRevocationInProgress;
  deleteOauth2AccessToken: DeleteOauth2AccessToken;
}

const DeleteOauth2TokenButton = (props: Props): JSX.Element => {
  const { token, auth, oauth2, children } = props;

  const isCurrentTokenRevoking = !!oauth2.accessTokenRevocationInProgress.find(
    (tokenId) => {
      return tokenId === token.id;
    }
  );
  const tokenId = token.id;
  const app = token.application;

  const handleDelete = () => {
    props.setAccessTokenRevocationInProgress({
      tokenId,
      isInProgress: true,
    });
    deleteOauth2AccessTokenAsync(auth.accessToken, { id: tokenId })
      .then(() => {
        props.deleteOauth2AccessToken(tokenId);
        openNotification(
          "Done",
          `You have successfully revoked ${app}'s access to your account.`,
          "Close",
          "success",
          notificationDuration.short
        );
        fireAnalyticsEvent.fireCrisp(CrispEvents.revokeAccess, {
          app,
        });
      })
      .catch((error) => {
        console.error(error.response.data);
        openNotification(
          "Something went wrong...",
          "An error has occurred. Please reload the page and try again. If it doesn't help, contact us.",
          "OK",
          "error"
        );
        fireAnalyticsEvent.fireCrisp(
          CrispEvents.revokeAccessError,
          {
            app,
            status: error.response.status,
            message: trimStringTo(error.response.data, 120),
          },
          true
        );
      })
      .finally(() => {
        props.setAccessTokenRevocationInProgress({
          tokenId,
          isInProgress: false,
        });
      });
  };

  return (
    <Popconfirm
      title={
        <div className="application_menu__confirm">
          Revoke {token.application}'s access?
        </div>
      }
      onConfirm={handleDelete}
      okText="Revoke"
    >
      <Button
        type="danger"
        ghost
        icon="delete"
        loading={isCurrentTokenRevoking}
      >
        {children}
      </Button>
    </Popconfirm>
  );
};

const mapStateToProps = ({ auth, oauth2 }) => {
  return {
    auth,
    oauth2,
  };
};
const mapActionsToProps = {
  setAccessTokenRevocationInProgress,
  deleteOauth2AccessToken,
};
export default connect(
  mapStateToProps,
  mapActionsToProps
)(DeleteOauth2TokenButton);
