import React, { useEffect } from "react";
import { focusTextArea } from "./focusTextArea";
import { ContextualInput } from "../../../store/gpt/types";

interface Props {
  handleOnChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleOnPressEnter: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  mode: string;
  className?: string;
  inputData: ContextualInput;
}

const GptInput = (props: Props): JSX.Element => {
  const {
    handleOnChange,
    handleOnPressEnter,
    textAreaRef,
    mode,
    className,
    inputData,
  } = props;
  const { isLoading, inputValue, completionResultData } = inputData;

  useEffect(() => {
    setTimeout(() => {
      focusTextArea(textAreaRef);
    }, 0);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      focusTextArea(textAreaRef);
    }
  }, [isLoading]);

  return (
    <textarea
      className={className}
      value={inputValue}
      onChange={handleOnChange}
      disabled={isLoading}
      placeholder={
        isLoading
          ? ""
          : completionResultData.isSessionStarted
          ? "Tell AI what to edit next..."
          : "Ask AI to edit something..."
      }
      rows={1}
      ref={textAreaRef}
      onKeyDown={handleOnPressEnter}
    />
  );
};

export default GptInput;
