export const addDefaultValuesToItems = (itemsArray: any[]) => {
  itemsArray.forEach((item, index) => {
    if (!item.IS_BEING_MODIFIED_BY_AI) {
      item.IS_BEING_MODIFIED_BY_AI = true;
    }
    if (!("READONLY_ID" in item)) {
      item.READONLY_ID = index;
    }
    if (!("POSITION_IN_ARRAY" in item)) {
      item.POSITION_IN_ARRAY = index;
    }
  });
};
