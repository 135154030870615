// Removes all extra #
// Trims to 6 characters
// Turns 3-digit hex to 6-digit hex

export const formatHex = (hexValue: string, prependHash?: boolean): string => {
  let returnedHexValue = hexValue.replace(/#/g, "").substring(0, 6);

  if (returnedHexValue.length === 1) {
    returnedHexValue = returnedHexValue.repeat(6);
  }

  if (returnedHexValue.length === 2) {
    returnedHexValue = returnedHexValue.repeat(3);
  }

  if (
    returnedHexValue.length === 3 ||
    returnedHexValue.length === 4 ||
    returnedHexValue.length === 5
  ) {
    returnedHexValue = returnedHexValue
      .substring(0, 3)
      .split("")
      .map((digit) => {
        return digit + digit;
      })
      .join("");
  }

  if (prependHash) {
    returnedHexValue = `#${returnedHexValue}`;
  }

  return returnedHexValue.toUpperCase();
};
