export const getCurrentWebsiteSubdomain = (websitesArray: any) => {
    // This function checks if it's a subdomain of a real website of just a utility page (e.g. '/settings' or '/account').
    // Please pass the websitesArray array in the arguments to validate.

    let pathArray = location.pathname.split('/');
    let websiteSubdomain = pathArray[1].toLowerCase();

    // See if this subdomain belongs to a real website.
    let websiteWithThisSubdomain = websitesArray.find((item:any) => {
        return (item.subdomain === websiteSubdomain)
    });

    if(websiteWithThisSubdomain === undefined){
        return undefined
    }else{
        return websiteWithThisSubdomain.subdomain;
    }
};