import React, { useState } from "react";
import { WebsitePagesItem } from "../../../../store/websitePages/types";
import { WebsitesItem } from "../../../../store/websites/types";
import GptContextualInput from "./GptContextualInput";
import { Dropdown } from "antd";
import { focusTextArea } from "../focusTextArea";
import { connect } from "react-redux";
import { getCurrentInputData } from "../../../../store/gpt/thunks/getCurrentInputData";
import { GetCurrentInputData } from "../../../../store/gpt/types";
import { AbortControllersRef, DropdownContainerRef, QueueItem } from "../types";

interface Props {
  currentWebsitePage: WebsitePagesItem;
  currentWebsite: WebsitesItem;
  currentPageIndex: number;
  componentIds: string[];
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  children: React.ReactNode;
  isFooter?: boolean;
  queueDataRef: React.MutableRefObject<QueueItem[]>;
  intervalRef: React.MutableRefObject<NodeJS.Timeout>;
  dropdownContainerRef: DropdownContainerRef;
  forceStopAllFlagRef: React.MutableRefObject<boolean>;
  abortControllersRef: AbortControllersRef;

  isDropdownVisible: boolean;
  setIsDropdownVisible: any;

  // redux
  getCurrentInputData: GetCurrentInputData;
  // redux
}

const InputDropdown = (props: Props): JSX.Element => {
  const {
    currentWebsitePage,
    currentWebsite,
    currentPageIndex,
    componentIds,
    textAreaRef,
    children,
    getCurrentInputData,
    isFooter,
    queueDataRef,
    intervalRef,
    dropdownContainerRef,

    isDropdownVisible,
    setIsDropdownVisible,
    forceStopAllFlagRef,
    abortControllersRef
  } = props;

  const currentInput = getCurrentInputData(componentIds[0]);

  const handleOnVisibleChange = (visible: boolean) => {
    if (currentInput.isLoading) return;
    setIsDropdownVisible(visible);
    if (visible) {
      setTimeout(() => {
        focusTextArea(textAreaRef);
      }, 0);
    }
  };

  const overlay = (
    <GptContextualInput
      currentWebsitePage={currentWebsitePage}
      currentPageIndex={currentPageIndex}
      currentWebsite={currentWebsite}
      componentIds={componentIds}
      textAreaRef={textAreaRef}
      isInputDropdownVisible={isDropdownVisible}
      queueDataRef={queueDataRef}
      intervalRef={intervalRef}
      dropdownContainerRef={dropdownContainerRef}
      forceStopAllFlagRef={forceStopAllFlagRef}
      abortControllersRef={abortControllersRef}
    />
  );

  return (
    <Dropdown
      overlay={overlay}
      trigger={["click"]}
      visible={isDropdownVisible || currentInput.isLoading}
      onVisibleChange={handleOnVisibleChange}
      placement={"bottomCenter"}
      getPopupContainer={() => dropdownContainerRef.current}
    >
      {children}
    </Dropdown>
  );
};

const mapActionsToProps = {
  getCurrentInputData,
};
export default connect(null, mapActionsToProps)(InputDropdown);
