import React, {Component} from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import {callToActionOptions} from "../../data/callToActionOptions";
import {buttonStyles} from "../../data/buttonStyles";
import {linkOrButtonOptions} from "../../data/linkOrButtonOptions";
import {pressLogos, publicUrl} from "../../data/urls";
import { returnHref } from "../../helpers/content_displayers/returnHref";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import TitleBox from "../component_molecules/TitleBox";
import { formatImageUrl } from "../../helpers/strings/formatImageUrl";

interface Props {
    componentItem: any,
    checkIsEmptyContent: any,
    saveContentInStore: any,
    connectDefaultFormIntegration: any, //we need this to connect an integration to forms in CTAs
    isDarkBg: boolean,
}

class Press03 extends Component<Props>{
    generator = new generateContentDataObject();

    componentWillMount(): void {
        const {isDefaultEmpty} = this.props.componentItem;
        //check if the component has not content (when just added). if so, generate some default dummy content.
        // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
        if(this.props.checkIsEmptyContent()){
            //generate defaultContent{}. Use special methods of the generateContentDataObject class;


            this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty,titleText: "In the Wild"}));
            this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty,subtitleText: ''}));
            this.props.saveContentInStore(this.generator.setUpPosts({
              isDefaultEmpty,
                fieldTitle: {default: "Press mentions"},
                label: {default: "Edit the mentions:"},
                labelTooltip: {default: "Add a square logotype of a blog or a magazine that has mentioned you in a post. Also try to use same size quotes."},
                fieldInfo: {default: "Recommended logo size: 60x60 px"},
                maxItems: 16,
                hasLogo: false,
                hasThumb: true,
                hasSubtitle: false,
                hasText: true,
                items: [
                    this.generator.createPostsContentObject({
                        title: "A falsis, compater gratis zeta",
                        text: 'Ferox castor aliquando dignuss luba est. Cum acipenser nocere, omnes cursuses quaestio fidelis, mirabilis compateres. Tatas credere, tanquam mirabilis homo.',
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: publicUrl + "/img/logos/press/cnn.svg",
                        }),
                    }),
                    this.generator.createPostsContentObject({
                        title: "Indictio studeres, tanquam lotus fraticinida",
                        text: 'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus, scelerisque nec pharetra id, tempor a tortor.',
                        thumb: this.generator.createGraphicsContentObject({
                            imageUrl: publicUrl + "/img/logos/press/black/tech-crunch.svg",
                        }),
                    }),
                ]
            }));

            this.props.saveContentInStore(this.generator.setUpCta({
              isDefaultEmpty,
                activeOption: callToActionOptions.buttons,
                fieldTitle: {
                    [callToActionOptions.buttons]: 'Bottom buttons',
                    default: 'Bottom buttons',
                },
                label: {
                    [callToActionOptions.buttons]: 'Edit the buttons:',
                    default: 'Edit the buttons:',
                },
                labelTooltip: {
                    [callToActionOptions.buttons]: "Add a button to the bottom of the component to show more press mentions.",
                    default: "Add a button to the bottom of the component to show more press mentions.",
                },

                buttons: [
                    this.generator.createButtonContentObject({
                        buttonType: linkOrButtonOptions.link,
                        buttonTitle: '',
                        buttonHref: '',
                        buttonStyleType: buttonStyles.pill.typeTitle,
                        pillPrefix: 'Read more'
                    }),
                ],

                priority: 10
            }));
        }
    }

    render(){
        // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
        // see the content schema in the helpers/editor/generateContentDataObject.ts
        let title = displayTitleMarkupText(this.props.componentItem.componentData.title.content.markup);
        let subtitleText = displaySubtitleMarkupText(this.props.componentItem.componentData.subtitleText.content.markup);

        let posts = this.props.componentItem.componentData.posts.content.items;

        return (
            <section>

                <div className="press-03">

                    <TitleBox 
                      title={title}
                      subtitleText={subtitleText}
                      isDarkBg={this.props.isDarkBg}
                    />

                    <div className="container container--mid">
                        <ul className="press-03__list">
                            {posts && posts.map((item:any, key:number) => {
                                let logoItemContent;

                                const innerContentJSX = (
                                    <div>
                                        <div className="press-03__image-box">
                                            <img className="press-03__logo" src={formatImageUrl(item.thumb.image.url)}/>
                                        </div>
                                        <h3 className={"def-16 title-text--inner press-03__heading "}>{item.title}</h3>
                                        <p className={"content-text press-03__info content_box "} dangerouslySetInnerHTML={{__html: item.text}}/>
                                    </div>
                                );

                                if(item.href && item.href !== ''){
                                    logoItemContent =
                                        (<a className={"press-03__link " + (this.props.isDarkBg ? ' text-white ' : ' ')} href={returnHref(item.href)}>
                                            <span className="press-03__text">
                                                {innerContentJSX}
                                            </span>
                                        </a>)
                                }else{
                                    logoItemContent =
                                        (<span className={"press-03__text " + (this.props.isDarkBg ? ' text-white ' : ' ')}>
                                            {innerContentJSX}
                                        </span>)
                                }

                                if(item !== undefined){
                                    return (
                                        <li className="press-03__item" key={'press-03-' + key}>
                                            {logoItemContent}
                                        </li>
                                    )

                                }else{
                                    return (null);
                                }
                            })}
                        </ul>
                        <div className="bottom_cta">
                            {this.props.componentItem.componentData.cta && <WebsiteCTA componentItem={this.props.componentItem} />}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Press03;
