import React from "react";
import { Typography } from "antd";
import FillTemplateIcon from "./FillTemplateIcon";
import classNames from "classnames";

const { Text } = Typography;

interface Props {
  type?: "default" | "ai";
  title?: string;
  subtitle?: string;
  onClick?: () => void;
}

const FillTemplateItem = (props: Props): JSX.Element => {
  const { type = "default", title, subtitle, onClick } = props;

  return (
    <button
      className={classNames("fill-template-modal__item", {
        "fill-template-modal__item--ai": type === "ai",
      })}
      onClick={onClick}
    >
      <FillTemplateIcon type={type} />
      <div className="fill-template-modal__text-wrapper">
        <Text strong ellipsis={false} className="fill-template-modal__title">
          {title}
        </Text>
        <Text
          type="secondary"
          ellipsis={false}
          className="fill-template-modal__subtitle"
        >
          {subtitle}
        </Text>
      </div>
    </button>
  );
};

export default FillTemplateItem;
