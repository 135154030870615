import { Text } from 'slate'
import escapeHtml from 'escape-html'
import generateImageAltFromFileName from "../../../helpers/editor/generateImageAltFromFileName";

const serializeNodeToHtml = node => {
  // The serialize from JSON post (object) to HTML post (string)

  if (Text.isText(node)) {
    if (node.bold) {
      return `<strong>${node.text}</strong>`
    }
  
    if (node.code) {
      return `<code>${escapeHtml(node.text)}</code>`
    }
  
    if (node.italic) {
      return `<em>${node.text}</em>`
    }
  
    if (node.underline) {
      return `<u>${node.text}</u>`
    }

    return escapeHtml(node.text)
  }

  const children = node.children.map(n => serializeNodeToHtml(n)).join('')
  
  switch (node.type) {
    case 'heading-two':
      return `<h2>${children}</h2>`
    case 'heading-three':
      return `<h3>${children}</h3>`
    case 'heading-four':
      return `<h4>${children}</h4>`
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`
    case 'code':
      return `<code>${escapeHtml(children)}</code>`
    case 'bulleted-list':
      return `<ul>${children}</ul>`
    case 'numbered-list':
      return `<ol>${children}</ol>`
    case 'list-item': 
      return `<li>${children}</li>`
    case 'paragraph':
      return `<p>${children}</p>`
    case 'link':
      return `<a target="_blank" href="${escapeHtml(node.url)}">${children}</a>`
    case 'embed':
      return `<iframe loading="lazy" height="${escapeHtml(node.height)}" width="${escapeHtml(node.width)}" src="${escapeHtml(node.src)}" frameborder="0" allowfullscreen>${children}</iframe>`
    case 'image':
      return `
        <figure {...attributes}>
          <img src="${escapeHtml(node.url)}" alt="${node.fileName ? generateImageAltFromFileName(node.fileName) : ''}"/>
          <figcaption>
            ${children}
          </figcaption>
        </figure>
      `
    default:
      return `<p>${children}<p>`
  }
}

export default serializeNodeToHtml