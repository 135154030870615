import React from "react";
import { ZapierIntegrationStatus } from "../../../../enums/ZapierIntegrationStatus";
import { Icon } from "antd";

interface Props {
  integrationTitle: string;
  zapierStatus: ZapierIntegrationStatus;
  zapierStatusLoaded: boolean;
  item: any;
}

const IntegrationItemValue = (props: Props): JSX.Element => {
  const { integrationTitle, zapierStatus, zapierStatusLoaded, item } = props;

  if (integrationTitle === "mailchimp") {
    return <>{item.data.action}</>;
  }

  if (integrationTitle === "mailerlite") {
    return <>{item.data.action}</>;
  }

  if (integrationTitle === "webhook") {
    return <>{item.data.action}</>;
  }

  if (integrationTitle === "email") {
    return <>{item.data.emails.join(", ")}</>; // the list of emails to send data to
  }

  if (integrationTitle === "google-sheet") {
    return <>{item.data.sheetName}</>;
  }

  if (integrationTitle === "zapier") {
    if (!item.data.status) {
      // For older version of Zapier integration. The status property exists only in the new version (Oct, 2022)
      return <>{item.data.action}</>;
    }
    if (!zapierStatusLoaded) return <Icon type="loading" />;
    return <>{zapierStatus}</>;
  }

  return null;
};

export default IntegrationItemValue;
