// Stringifies objects, numbers, and arrays. Returns other types as is. Used primarily on AI generated data because AI generates unpredictable data types.
export const stringifyObjectsAndNumbers = (value) => {
  if (
    (typeof value === "object" || typeof value === "number") &&
    value !== null
  ) {
    return JSON.stringify(value);
  }
  return value;
};
