import React, { Component } from "react";
import { Icon } from "antd";
import './IllustratedSpinnerBox.css'
import LevitatingFellow from "../../img/other/levitating_fellow.png";

interface Props {
  text?: string
}

class IllustratedSpinnerBox extends Component<Props>{
  componentDidMount(): void {
  }

  displayText() {
    if (this.props.text && this.props.text.length > 0) {
      return this.props.text;
    } else {
      return 'Loading...'
    }
  }

  render() {
    return (
      <div className="illustrated_spinner_box">
        <div className="illustrated_spinner_box__content">
          <img className="illustrated_spinner_box__image" src={LevitatingFellow} alt="Levitating fellow" />
          <div className="illustrated_spinner_box__text">
            <Icon type="loading" style={{ color: 'var(--ocean)', fontSize: '20px', marginRight: '10px' }} />
            {this.displayText()}
          </div>
        </div>
      </div>
    )
  }
}

export default IllustratedSpinnerBox;