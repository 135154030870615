import React, { Component } from "react";
import generateContentDataObject from "../../helpers/editor/generateContentDataObject";
import { callToActionOptions } from "../../data/callToActionOptions";
import { GraphicsOptions } from "../../components/editor/component_graphics/data/componentGraphicsData";
import _ from "lodash";
import GraphicsJSX from "../GraphicsJSX";
import { displayTitleMarkupText } from "../../helpers/content_displayers/displayTitleMarkupText";
import { displaySubtitleMarkupText } from "../../helpers/content_displayers/displaySubtitleMarkupText";
import WebsiteCTA from "../component_molecules/WebsiteCTA";
import { DEFAULT_IMAGES_CDN } from "../../data/urls";

interface Props {
  componentItem: any;
  checkIsEmptyContent: any;
  saveContentInStore: any;
  connectDefaultFormIntegration: any; //we need this to connect an integration to forms in CTAs
  isDarkBg: boolean;
}

class Header39 extends Component<Props> {
  generator = new generateContentDataObject();

  componentWillMount(): void {
    const { isDefaultEmpty, defaultCtaOption } = this.props.componentItem;
    //check if the component has not content (when just added). if so, generate some default dummy content.
    // also change inputs tooltips, placeholders text if needed. E.g. when a component needs an image of a particular size.
    if (this.props.checkIsEmptyContent(this.props.componentItem)) {
      //generate defaultContent{}. Use special methods of the generateContentDataObject class;

      this.props.saveContentInStore(
        this.generator.setUpGraphics({
          activeOption: GraphicsOptions.video,
          userCanAddItems: false,
          maxItems: 1,
          items: [
            this.generator.createGraphicsContentObject({
              imageUrl: DEFAULT_IMAGES_CDN.UI_DESKTOP_CALENDAR,
              videoUrl: "https://www.youtube.com/watch?v=tw4jkyfY4HE",
              width: 1280,
              height: 800,
            }),
          ],
        })
      );

      this.props.saveContentInStore(this.generator.setUpTitle({isDefaultEmpty}));
      this.props.saveContentInStore(this.generator.setUpSubtitleText({isDefaultEmpty}));
      this.props.saveContentInStore(
        this.generator.setUpCta({
          activeOption: defaultCtaOption || callToActionOptions.buttons,
          isDefaultEmpty
        })
      );
    }
  }

  render() {
    this.props.connectDefaultFormIntegration(this.props.componentItem);

    // these variables contain content. we need to wait a little before content schemes with default data will be populated (it happens on the componentWillMount even, each time we launch generator).
    // see the content schema in the helpers/editor/generateContentDataObject.ts
    let title = displayTitleMarkupText(
      this.props.componentItem.componentData.title.content.markup
    );
    let subtitleText = displaySubtitleMarkupText(
      this.props.componentItem.componentData.subtitleText.content.markup
    );

    let youtubeVideoUrl = this.props.componentItem.componentData.graphics
      .content.items[0][GraphicsOptions.video].youtube.url; // This component has exactly one item and more can not be added (see createGraphicsContentObject() above).

    const currentActiveOption: GraphicsOptions = _.get(
      this.props.componentItem,
      ["componentData", "graphics", "activeOption"]
    );
    const imageUrl: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.image,
      "url",
    ]);
    const codeSnippet: string = _.get(this.props.componentItem, [
      "componentData",
      "graphics",
      "content",
      "items",
      "0",
      GraphicsOptions.HTML,
      "codeSnippet",
    ]);

    return (
      <header
        className={`header-39 graphics-${currentActiveOption} default-graphics-${GraphicsOptions.video}`}
      >
        <div className="container container--mid header-39__container">
          <div className="header-39__text_box">
            {title.length > 0 && (
              <h1
                className={
                  "title-text heading " + (this.props.isDarkBg ? "text-white" : "")
                }
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}

            {subtitleText.length > 0 && (
              <p
                className={
                  "subtitle-text content_box " + (this.props.isDarkBg ? "text-white" : "")
                }
                dangerouslySetInnerHTML={{ __html: subtitleText }}
              />
            )}
          </div>
          <div className="header-39__buttons">
            <WebsiteCTA componentItem={this.props.componentItem} isDarkBg={this.props.isDarkBg} />
          </div>
          <GraphicsJSX
            currentActiveOption={currentActiveOption}
            defaultGraphicsOption={GraphicsOptions.video}
            isDarkBg={this.props.isDarkBg}
            permanentSpinnerColor
            imageUrl={imageUrl}
            videoUrl={youtubeVideoUrl}
            codeSnippet={codeSnippet}
            classNames={{
              image: "header-39__image",
              video: "header-39__video",
              HTML: "header-39__HTML",
            }}
          />
        </div>
      </header>
    );
  }
}

export default Header39;
