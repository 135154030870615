import _ from "lodash";
import { pricingItemDetailIconOptions } from "../../../../data/pricingItemDetailIconOptions";
import { isKeyValue } from "../../../../helpers/isKeyValue";
import { stringifyObjectsAndNumbers } from "../stringifyObjectsAndNumbers";
import { addDefaultValuesToItems } from "./updaterFunctions.ts/addDefaultValuesToItems";
import { getIdAndPosition } from "./updaterFunctions.ts/getIdAndPosition";
import { getOriginalItem } from "./updaterFunctions.ts/getOriginalItem";
import { pushFromItem } from "./updaterFunctions.ts/pushFromItem";
import { sortItemsByPosition } from "./updaterFunctions.ts/sortItemsByPosition";

const particleGetModifiedValues = (modifiedItem) => {
  let [itemId, itemPosition] = getIdAndPosition(modifiedItem);
  if (itemPosition === null) {
    itemPosition = itemId;
  }
  const itemText = stringifyObjectsAndNumbers(modifiedItem.TEXT) || "";
  let itemIcon = stringifyObjectsAndNumbers(modifiedItem.ICON);
  if (
    itemIcon !== pricingItemDetailIconOptions.none &&
    itemIcon !== pricingItemDetailIconOptions.greenTick &&
    itemIcon !== pricingItemDetailIconOptions.redCross
  ) {
    itemIcon = pricingItemDetailIconOptions.none;
  }
  return {
    itemId,
    itemPosition,
    itemText,
    itemIcon,
  };
};

const particleModifyOriginalItem = (
  componentData,
  originalItem,
  modifiedItem,
  itemPosition,
  itemText,
  itemIcon
) => {
  if ("POSITION_IN_ARRAY" in modifiedItem) {
    originalItem.POSITION_IN_ARRAY = itemPosition;
    componentData.comparisionTableDetailsSet.forEach((item) => {
      if (
        item.POSITION_IN_ARRAY === itemPosition &&
        item.READONLY_ID !== originalItem.READONLY_ID
      ) {
        pushFromItem(componentData.comparisionTableDetailsSet, item);
      }
    });
  }
  if ("TEXT" in modifiedItem) {
    originalItem.title = itemText;
  }
  if ("ICON" in modifiedItem) {
    originalItem.icon = itemIcon;
  }
};

export const updatePricingTableRowItems = (
  componentData,
  completionContent
) => {
  if (
    !("ROW_ITEMS" in completionContent) ||
    !Array.isArray(completionContent.ROW_ITEMS)
  ) {
    return;
  }
  addDefaultValuesToItems(componentData.comparisionTableDetailsSet);
  completionContent.ROW_ITEMS.forEach((modifiedItem) => {
    if (!isKeyValue(modifiedItem) || !("READONLY_ID" in modifiedItem)) {
      return;
    }
    const {
      itemId,
      itemPosition,
      itemText,
      itemIcon,
    } = particleGetModifiedValues(modifiedItem);
    const originalItem = getOriginalItem(
      componentData.comparisionTableDetailsSet,
      itemId
    );
    particleModifyOriginalItem(
      componentData,
      originalItem,
      modifiedItem,
      itemPosition,
      itemText,
      itemIcon
    );
  });
  sortItemsByPosition(componentData.comparisionTableDetailsSet);
};
