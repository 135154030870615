import React from "react";
import { Icon, Tooltip } from "antd";
import { urls } from "../../../../data/urls";
import { toggleNewWebsitePageEditionsDetected } from "../../../../store/websitePages/actions";
import { saveWebsiteNewFormIntegrationInState } from "../../../../store/websites/actions";
import { connect } from "react-redux";
import { GoogleSheetsConnectButton } from "../GoogleSheetsConnectButton";
import UpgradeToUnlockMessage from "../../UpgradeToUnlockMessage";
import { SaveConnectedIntegrationsInServer } from "../../../../helpers/types/globalTypes";
import { UserEvents } from "../../../../enums/UserEvents";

interface Props {
  currentFormIntegrationTitle: string;
  currentFormIntegrationHumanTitle: string;
  currentWebsite: any;
  componentItem: any;
  user: any;

  saveConnectedIntegrationsInServer: SaveConnectedIntegrationsInServer;

  toggleNewWebsitePageEditionsDetected: (payload: boolean) => void;
  saveWebsiteNewFormIntegrationInState: (payload: any) => void;
}

const GoogleSheetInputs = (props: Props): JSX.Element => {
  const {
    currentFormIntegrationTitle,
    currentWebsite,
    saveConnectedIntegrationsInServer,
    componentItem,
    user,
    currentFormIntegrationHumanTitle
  } = props;

  const displayButton = () => {
    if (user.isPro) {
      return (
        <GoogleSheetsConnectButton
          componentItem={componentItem}
          currentWebsite={currentWebsite}
          saveWebsiteNewFormIntegrationInState={
            props.saveWebsiteNewFormIntegrationInState
          }
          toggleNewWebsitePageEditionsDetected={
            props.toggleNewWebsitePageEditionsDetected
          }
          saveConnectedIntegrationsInServer={saveConnectedIntegrationsInServer}
          currentFormIntegrationTitle={currentFormIntegrationTitle}
        />
      );
    }
    return (
      <UpgradeToUnlockMessage
        title="Hold on, cowboy"
        description={
          <span>
            The <b>"{currentFormIntegrationHumanTitle}"</b> integration is available
            on all paid plans. Upgrade your account to get it.
          </span>
        }
        userEventOnMount={UserEvents.GoogleSheetNotAvailable}
      />
    );
  };

  return (
    <li className="content-input">
      <div className="content-input__label_box">
        <div className="content-input__tooltip_box">
          <Tooltip
            placement="leftTop"
            title={
              <div>
                <span style={{ margin: "0 4px 0 0" }}>Read this</span>
                <a
                  href={urls.guides.googleSheetsIntegration}
                  target="_blank"
                  style={{
                    margin: "0 4px 0 0",
                    color: "#fff",
                    textDecoration: "underline",
                  }}
                >
                  simple guide <Icon type="link" />
                </a>
                <span>to learn about the Google Sheets integration.</span>
              </div>
            }
          >
            <Icon className="content-input__tooltip_icon" type="info-circle" />
          </Tooltip>
          <label
            htmlFor={currentFormIntegrationTitle}
            className="content-input__label"
          >
            Click the button to create a new spreadsheet:
          </label>
        </div>
      </div>
      <div
        className="content-input__input_box"
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {displayButton()}
      </div>
      <div className="content-input__input_box"></div>
    </li>
  );
};

const mapActionsToProps = {
  toggleNewWebsitePageEditionsDetected,
  saveWebsiteNewFormIntegrationInState,
};
export default connect(null, mapActionsToProps)(GoogleSheetInputs);
